import React, { Component } from 'react';

import SMSButton from '../../../components/02Members/SMSButton';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as MembersActionCreators from "../../../actions/members";

class MemberMobileVerification extends Component {

    constructor(props) {

        super(props);

        const { dispatch } = props;

        // DISPATCH TO ACTIONS
        // this.MobileAreaCode = bindActionCreators(MembersActionCreators.MobileAreaCode, dispatch);
        // this.MobileNumber = bindActionCreators(MembersActionCreators.MobileNumber, dispatch);
        // this.SmsCode = bindActionCreators(MembersActionCreators.SmsCode, dispatch);
        this.MobileVerification = bindActionCreators(MembersActionCreators.MobileVerification, dispatch);

        this.state = {
            countryCode: '853',
            mobileNumber: '',
            smsVerificationCode: ''
        }
    }

    changeStateLevelOne = (data1, value) => {
        this.setState({
            [data1]: value
        })
    }

    sendSMS = (e) => {
        e.preventDefault();
    }

    formSubmit = (e) => {
        e.preventDefault();
        let data = this.state;
        this.MobileVerification(data);
    };

    render() {

        return (
            <div className="wrapper-container memberCreateAccount">

                {/* <div className="page-title"><h2>Contact Us</h2></div>

                    <div className="sep-0" /> */}

                <div className="containerMain borderTop">
                    <div className="content clearfix">
                        <div className="full left align-center">
                            <span className="door"></span>
                            <h2>Mobile Number Verification</h2>
                        </div>

                        <div className="sep-20" />

                        <dl className="generalForm clearfix">
                            <div>
                                <dt><h4>Mobile Number</h4></dt>
                                <dd>
                                    <div className="wrap clearfix">
                                        <div className="thirty">
                                            <div>
                                                <select className="title"
                                                    onChange={(e) => this.props.changeStateLevelOne('countryCode', e.target.value)}
                                                    value={this.state.countryCode}
                                                >
                                                    <option value="852">852</option>
                                                    <option value="853">853</option>
                                                    <option value="86">86</option>
                                                </select>
                                                <span className="errorMessage"></span>
                                            </div>
                                        </div>
                                        <div className="seventy">
                                            <div>
                                                <input type="text" placeholder="" className="" maxLength="8"
                                                    onChange={(e) => this.changeStateLevelOne('mobileNumber', e.target.value)}
                                                    value={this.state.mobileNumber}
                                                />
                                                <span className="errorMessage"></span>
                                            </div>
                                        </div>
                                    </div>
                                </dd>
                            </div>

                            <div className="sep-25" />

                            <div>
                                <dt></dt>
                                <dd>
                                    <div className="wrap clearfix">
                                        <div className="fifty">
                                            <div>
                                                <input type="text" placeholder="SMS Verification Code"
                                                    onChange={(e) => this.props.changeStateLevelOne('smsVerificationCode', e.target.value)}
                                                    value={this.state.smsVerificationCode}
                                                />
                                                <span className="errorMessage"></span>
                                            </div>
                                        </div>
                                        <div className="fifty">
                                            <SMSButton />
                                        </div>
                                    </div>
                                </dd>
                            </div>
                        </dl>

                        <div className="sep-25" />
                        <div className="sep-25" />

                        <div className="full left align-center">
                            <div>
                                <input type="submit" className="button" value="Comfirm"
                                    onClick={(e) => this.formSubmit(e)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {
        members: state.members
    }
);

export default connect(mapStateToProps)(MemberMobileVerification);
