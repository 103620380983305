import React, {Component} from 'react';
import {Redirect} from 'react-router';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

function UpdatePaymentComplete(props) {

    const {t, i18n} = props;

    if (props.members.Login.length === 0) {
        return <Redirect to={"/" + i18n.language + "/login"}/>;
    }

    let orderID = "";
    if (typeof (props.params.orderID) !== "undefined")
        orderID = props.params.orderID;

    return (
        <div className="wrapper-container confirmation padding">
            <div className="containerMain">
                <div className="content clearfix">
                    <h3 className="align-center preline">{t("updatePayment:success:part1", {orderID: orderID})}</h3>

                    <p className="align-center preline">{t("updatePayment:success:part2")}</p>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => (
    {
        members: state.members,
    }
);

export default withTranslation()(connect(mapStateToProps)(UpdatePaymentComplete));
