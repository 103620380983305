import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

function N5(props) {
    const {
        t,
        i18n,
        time = "",
        sid = "",
        address = "",
        success = false
    } = props;

    if (success) {
        return (
            <div>
                <h3>{t("messenger:notifications.N5.title")}</h3>
                <div className="sep-15" />
                <span className="dateTime">{time}</span>
                <div className="sep-10" />
                <div>
                    <div>{t("messenger:notifications.N5.msg")}</div>
                    {/*<div className="sep-20" />*/}
                    {/*<div>{t("messenger:general.space_id")} - {sid}</div>*/}
                    <div className="sep-10" />
                    <div>{t("messenger:general.address")} - {address}</div>
                    <div className="sep-20" />
                    <div>
                        <Link to={"/" + i18n.language + "/memberListedSpaces"} className="button" >{t("messenger:general.go_to_listings")}</Link>
                    </div>
                    <div className="sep-20" />
                    {t("messenger:general.thank_you_3")}
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <h3>{t("messenger:notifications.N5.fail_title")}</h3>
                <div className="sep-15" />
                <span className="dateTime">{time}</span>
                <div className="sep-10" />
                <div>
                    <div>{t("messenger:notifications.N5.fail_msg")}</div>
                    <div className="sep-20" />
                    <div>{t("messenger:general.thank_you_1")}</div>
                    <div className="sep-20" />
                    <div>
                      <Link to={"/" + i18n.language + "/relist-space/" + sid} className="button" >{t("messenger:general.edit_now")}</Link>
                    </div>
                </div>
            </div>
        )
    }

}
export default withTranslation()(N5);
