import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import {RegisterTnc, RegisterToLogin} from "../../../components/02Members/MemberRegister";
import ReactPixel from "react-facebook-pixel";

function MemberCreateAccountType(props) {
    const {
        t,
        i18n
    } = props;

    ReactPixel.trackCustom('Register', {category_type: 'ChooseAccountType'});

    return (
        <div className="wrapper-container memberCreateAccountType padding" >

            <div className="containerMain borderTop">
                <div className="content clearfix">
                    <div className="full left align-center">
                        <span className="door"></span>
                        <h2>{t("registerStart:start")}</h2>
                    </div>

                    <div className="sep-20" />

                    <dl className="generalForm clearfix">
                        <div className="registerTypes">
                            <div className="fifty left" >
                                <Link to={"/" + i18n.language + "/register-individual"} className="registerTypeBtn">
                                    <span><img src={require('../../../images/mobile/02_membership/00_signin/employee@2x.png')} alt="" style={{width: "100%"}}/></span>
                                    <br />
                                    {t("registerStart:individual")}
                                </Link>
                            </div>

                            <div className="fifty left">
                                <Link to={"/" + i18n.language + "/register-corporate"} className="registerTypeBtn corporate">
                                    <span><img src={require('../../../images/mobile/02_membership/00_signin/enterprise@2x.png')} alt="" style={{width: "100%"}} /></span>
                                    <br />
                                    {t("registerStart:corporate")}
                                </Link>
                            </div>
                        </div>

                        <RegisterTnc t={t} i18n={i18n} />

                        <RegisterToLogin t={t} i18n={i18n} />
                    </dl>
                </div>
            </div>
        </div >
    );
}

const mapStateToProps = (state) => (
    {
        members: state.members
    }
);

export default withTranslation()(connect(mapStateToProps)(MemberCreateAccountType));
