import React from 'react';
import {withTranslation} from 'react-i18next';

function Loading(props) {
    const {t} = props;
    return (
        <div className="wrapper-container">
            <div className="containerMain">
                {t("common:Loading")}
            </div>
        </div>
    );
}

export default withTranslation()(Loading);
