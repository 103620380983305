import {
    apiFetch,
    // apiPost,
} from "./General";

export const getHoliday = (callback) => {
    apiFetch('holiday', null, callback);
}

// export const setHoliday = (name, date, callback, errorCallback) => {
//     let body = {
//         name: name,
//         date: date,
//     }
//     apiPost('holiday', body, callback, errorCallback);
// }
