import React from 'react';
import { withTranslation } from 'react-i18next';

function ShowMore(props) {
    const {t} = props;
    return (
        <div className="wrapper-show-more">
            <div className="show-more">{t("Show More")}</div>
        </div>
    )
}
export default withTranslation()(ShowMore);
