import React, {Component} from 'react';
// import {Redirect} from 'react-router';
import {withTranslation} from 'react-i18next';

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as MembersActionCreators from "../../../actions/members";
// import * as UserApi from "../../../utils/04Api/User";

import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';
import ErrorMessage from '../../../components/103Parts/ErrorMessage';
import {updateUserInfo} from "../../../utils/04Api/User";
import {saveCreditCard} from "../../../utils/04Api/Payment";
import {findIndex} from "lodash";

class MemberSettingsPayment extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            deleteCardPopup: false,
            deleteCardID: '',
            deleteCardNum: '',
        }

        // Form Validation
        this.schema = Yup.object().shape({
            number: Yup.string()
                .required("payment:errors.cardNumber")
                .matches(/^\d{13,16}$/, "payment:errors.cardNumber"),
            name: Yup.string()
                .required("payment:errors.cardHolder"),
            yy: Yup.string()
                .required("myAccount:errors.setting.payment.yy")
                .matches(/^([1][9]|[2-9]\d)$/, "myAccount:errors.setting.payment.yy"),
            mm: Yup.string()
                .required("myAccount:errors.setting.payment.mm")
                .matches(/^(\d|[0]\d|[1][012])$/, "myAccount:errors.setting.payment.mm"),
            cvc: Yup.string()
                .required("payment:errors.cvc")
                .matches(/^\d{3}$/, "payment:errors.cvc"),
        });
    }

    setSubmitting = (submitting, cb) => {
        if (typeof (cb) !== "function")
            cb = () => {
            };

        this.setState({
            ...this.state,
            submitting: submitting,
        }, cb)
    }

    deleteCardPopup = (id, num) => {

        this.setState({
            ...this.state,
            deleteCardPopup: !this.state.deleteCardPopup,
            deleteCardID: id,
            deleteCardNum: num,
        });
    }

    form = ({errors, touched}) => {
        const {t} = this.props;

        return (
            <Form>
                <div className="fifty mobile-full left">
                    <div className="wrap clearfix">
                        <div className="full">
                            <div>
                                <h5>{t("myAccount:tabSettings.payment.creditCard")}</h5>
                                <Field type="text" name="number" maxLength="16"/>
                                {errors.number && touched.number ?
                                    <ErrorMessage message={t(errors.number)}/> : null}
                            </div>
                        </div>
                    </div>

                    <div className="wrap clearfix">
                        <div className="full">
                            <div>
                                <h5>{t("payment:payment.cardHolder")}</h5>
                                <Field type="text" name="name" maxLength="50"/>
                                {errors.name && touched.name ?
                                    <ErrorMessage message={t(errors.name)}/> : null}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fifty  mobile-full right">
                    <div className="wrap clearfix">
                        <div className="full">
                            <div>
                                <h5>CVC</h5>
                                <Field type="text" name="cvc" maxLength="3"/>
                                {errors.cvc && touched.cvc ?
                                    <ErrorMessage message={t(errors.cvc)}/> : null}
                            </div>
                        </div>
                    </div>

                    <div className="wrap clearfix">
                        <div className="fifty">
                            <div>
                                <h5>{t("myAccount:tabSettings.payment.expiryDate")}</h5>
                                <Field type="text" placeholder={t("payment:payment.expiryDate.yy")} name="yy"
                                       maxLength="2"/>
                                {errors.yy && touched.yy ?
                                    <ErrorMessage message={t(errors.yy)}/> : null}
                            </div>
                        </div>
                        <div className="fifty">
                            <div>
                                <h5 className="spacer">&nbsp;</h5>
                                <Field type="text" placeholder={t("payment:payment.expiryDate.mm")} name="mm"
                                       maxLength="2"/>
                                {errors.mm && touched.mm ?
                                    <ErrorMessage message={t(errors.mm)}/> : null}
                            </div>
                        </div>
                    </div>


                    {errors.general ? <div className="wrap clearfix text-right">
                        <ErrorMessage message={t(errors.general)}/>
                        <div className="sep-20"/>
                        &nbsp;
                    </div> : null}

                    <div className="wrap clearfix">
                        <div className="fifty">
                        </div>
                        <div className="fifty">
                            <div>
                                <div className="sep-20"/>
                                <button type="submit" className="button bookNow">{t("myAccount:update")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        )
    }

    handleSubmit = (values, {setFieldError, resetForm}) => {
        if (!this.state.submitting) {
            const {t} = this.props;

            // console.log(values);
            //

            this.setSubmitting(true, () => {
                const body = {
                    expiryDateMM: values.mm,
                    expiryDateYY: values.yy,
                    cardNumber: values.number,
                    CVC: values.cvc,
                }
                saveCreditCard(this.props.accessToken, body, this.props.userId, true, (res) => {
                    //
                    console.log("saveCreditCard success", res);
                    if (res.body.status === 1) {
                        // console.log("token id", res.body.paymentID);
                        this.props.getMe(() => {
                            resetForm();
                            this.setSubmitting(false);
                        });
                    } else {
                        // error
                        // console.log("saveCreditCard res status not 1", res);
                        setFieldError('general', t(res.body.msg));
                        this.setSubmitting(false)
                    }
                }, (res) => {
                    console.log("saveCreditCard error", res);
                    setFieldError('general', t('Cannot save credit card.'));
                    this.setSubmitting(false)
                })
            })
        }
    }

    handlesDelete = (id) => {
        if (!this.state.submitting) {
            let handledNumber = '';
            let payment = this.props.payments.map((o) => {
                if (o._id === id) {
                    o.display = false;
                    o.default = false;
                    handledNumber = o.number;
                }
                return o;
            })

            // const payment = filter(this.props.payments, (o) => {
            //     return o._id !== id;
            // });

            if (payment.length) {
                // check if there is any default
                const hasDefault = findIndex(payment, (o) => {
                    return o.default;
                })

                // no default after delete
                if (hasDefault === -1) {
                    let foundDefault = false;
                    payment = payment.map((o) => {
                        if (!foundDefault && typeof (o.display) !== "undefined" && o.display)
                            o.default = true;
                        return o;
                    })
                }
            }

            const data = {
                payment: payment,
                card: "N2",
                cardData: {
                    action: "remove",
                    number: handledNumber,
                }
            }

            // console.log(data);
            // return;

            updateUserInfo(this.props.accessToken, data, this.props.getMe);

            this.setState({
                ...this.state,
                deleteCardPopup: false,
                deleteCardID: '',
                deleteCardNum: '',
            });
        }
    }

    handleSetAsDefault = (id) => {
        if (!this.state.submitting) {
            let handledNumber = '';
            const payment = this.props.payments.map((o) => {
                if (o._id === id) {
                    o.default = true;
                    handledNumber = o.number;
                } else
                    o.default = false;
                return o;
            })

            const data = {
                payment: payment,
                card: "N2",
                cardData: {
                    action: "default",
                    number: handledNumber,
                }
            }

            // console.log(data);
            // return;

            updateUserInfo(this.props.accessToken, data, this.props.getMe);
        }
    }

    render() {
        const {
            t,
            // i18n,
            payments,
        } = this.props;

        let cardCounter = 0;

        return (
            <div className="memberPayment clearfix">
                <div>
                    <h4>{t("myAccount:tabSettings.payment.slogun")}</h4>

                    <div className="border-box clearfix">
                        <div className="wrapper">
                            <div className="card_list">
                                <table>
                                    <tbody>
                                    {Object.keys(payments).map(i => {
                                        const payment = payments[i];
                                        if (typeof (payment.display) !== "undefined" && payment.display) {
                                            cardCounter = cardCounter + 1;
                                            return (
                                                <tr key={i}>
                                                    <td><i className="numbering">{cardCounter}</i></td>
                                                    <td>{payment.number}</td>
                                                    <td>
                                                        <button className="delete" onClick={() => {
                                                            this.deleteCardPopup(payment._id, payment.number)
                                                        }}>X
                                                        </button>
                                                    </td>
                                                    <td>
                                                        {payment.default ?
                                                            <span>&nbsp;&nbsp;&nbsp;*{t("myAccount:tabSettings.payment.defaultTitle1")}</span>
                                                            :
                                                            <span>
                                                                    <button className="default" onClick={() => {
                                                                        this.handleSetAsDefault(payment._id);
                                                                    }}>{t("myAccount:tabSettings.payment.defaultTitle2")}</button>
                                                                </span>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        } else
                                            return null;
                                    })}
                                    </tbody>
                                </table>

                                <div className={"overlay " + (this.state.deleteCardPopup ? 'active' : '')}></div>
                                <div
                                    className={"deleteCard " + (this.state.deleteCardPopup ? 'active' : '')}>
                                    <div className="cancel"
                                         onClick={() => {
                                             this.deleteCardPopup('', '')
                                         }}
                                    >X
                                    </div>

                                    <div className="sep-20"/>

                                    <div>{t("myAccount:tabSettings.payment.confirmDeleteCard", {"card": this.state.deleteCardNum})}</div>

                                    <div className="sep-20"/>

                                    <div className="submit button message"
                                         onClick={() => {
                                             this.handlesDelete(this.state.deleteCardID);
                                         }}
                                    >
                                        {t("myAccount:tabSettings.payment.delete")}
                                    </div>
                                </div>
                            </div>

                            <div className="form_wrapper">
                                <h4>{t("myAccount:tabSettings.payment.addCard")}</h4>

                                <Formik
                                    initialValues={{
                                        name: '',
                                        number: '',
                                        cvc: '',
                                        yy: '',
                                        mm: '',
                                    }}
                                    validationSchema={this.schema}
                                    onSubmit={this.handleSubmit}
                                    component={this.form}
                                />
                            </div>

                        </div>
                    </div>

                </div>

                {/*<br />*/}
                {/*<br />*/}

                {/*<div className="fifty left">*/}
                {/*    <div>*/}
                {/*        <div className="border-box clearfix">*/}
                {/*            <div className="wrapper">*/}
                {/*                <div className="cardIcons">*/}
                {/*                    <span><img*/}
                {/*                        src={require('../../../images/mobile/03_find_parking_space/icon_visa.png')}*/}
                {/*                        alt=""/></span>*/}
                {/*                    /!* <span><img src={require('../../images/mobile/03_find_parking_space/icon_master.png')} alt="" /></span> *!/*/}
                {/*                </div>*/}

                {/*                <h4 className="special">*/}
                {/*                    <span>1</span> * {t("myAccount:tabSettings.payment.defaultTitle1")}</h4>*/}

                {/*                <div className="wrap clearfix">*/}
                {/*                    <div className="full">*/}
                {/*                        <div>*/}
                {/*                            <h5>{t("myAccount:tabSettings.payment.creditCard")}</h5>*/}
                {/*                            <input type="text"/>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}

                {/*                <div className="wrap clearfix">*/}
                {/*                    <div className="full">*/}
                {/*                        <div>*/}
                {/*                            <h5>{t("myAccount:tabSettings.payment.name")}</h5>*/}
                {/*                            <input type="text"/>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    /!*<div className="fifty">*!/*/}
                {/*                    /!*    <div>*!/*/}
                {/*                    /!*        <h5>{t("myAccount:")}</h5>*!/*/}
                {/*                    /!*        <input type="text" />*!/*/}
                {/*                    /!*    </div>*!/*/}
                {/*                    /!*</div>*!/*/}
                {/*                </div>*/}

                {/*                <div className="wrap clearfix">*/}
                {/*                    <div className="fifty">*/}
                {/*                        <div>*/}
                {/*                            <h5>{t("myAccount:tabSettings.payment.expiryDate")}</h5>*/}
                {/*                            <input type="text" placeholder="YY"/>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className="fifty">*/}
                {/*                        <div>*/}
                {/*                            <h5 className="spacer">&nbsp;</h5>*/}
                {/*                            <input type="text" placeholder="MM"/>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}

                {/*                <div className="wrap clearfix">*/}
                {/*                    <div className="full">*/}
                {/*                        <div>*/}
                {/*                            <h5>CVC</h5>*/}
                {/*                            <input type="text"/>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}

                {/*                <div className="wrap clearfix">*/}
                {/*                    <div className="fifty">*/}
                {/*                        <div>*/}
                {/*                            <a href="/" className="button bookNow">{t("myAccount:delete")}</a>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className="fifty">*/}
                {/*                        <div>*/}
                {/*                            <a href="/" className="button bookNow">{t("myAccount:update")}</a>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="fifty left">*/}
                {/*    <div>*/}
                {/*        <div className="border-box clearfix">*/}
                {/*            <div className="wrapper">*/}
                {/*                <div className="cardIcons">*/}
                {/*                    <span><img*/}
                {/*                        src={require('../../../images/mobile/03_find_parking_space/icon_visa.png')}*/}
                {/*                        alt=""/></span>*/}
                {/*                    <span><img*/}
                {/*                        src={require('../../../images/mobile/03_find_parking_space/icon_master.png')}*/}
                {/*                        alt=""/></span>*/}
                {/*                </div>*/}

                {/*                <h4 className="special">*/}
                {/*                    <span>2</span> {t("myAccount:tabSettings.payment.defaultTitle2")}</h4>*/}

                {/*                <div className="wrap clearfix">*/}
                {/*                    <div className="full">*/}
                {/*                        <div>*/}
                {/*                            <h5>{t("myAccount:tabSettings.payment.creditCard")}</h5>*/}
                {/*                            <input type="text"/>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}

                {/*                <div className="wrap clearfix">*/}
                {/*                    <div className="full">*/}
                {/*                        <div>*/}
                {/*                            <h5>{t("myAccount:tabSettings.payment.name")}</h5>*/}
                {/*                            <input type="text"/>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    /!*<div className="fifty">*!/*/}
                {/*                    /!*    <div>*!/*/}
                {/*                    /!*        <h5>Last Name</h5>*!/*/}
                {/*                    /!*        <input type="text" />*!/*/}
                {/*                    /!*    </div>*!/*/}
                {/*                    /!*</div>*!/*/}
                {/*                </div>*/}

                {/*                <div className="wrap clearfix">*/}
                {/*                    <div className="fifty">*/}
                {/*                        <div>*/}
                {/*                            <h5>{t("myAccount:tabSettings.payment.expiryDate")}</h5>*/}
                {/*                            <input type="text" placeholder="YY"/>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className="fifty">*/}
                {/*                        <div>*/}
                {/*                            <h5 className="spacer">&nbsp;</h5>*/}
                {/*                            <input type="text" placeholder="MM"/>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}

                {/*                <div className="wrap clearfix">*/}
                {/*                    <div className="full">*/}
                {/*                        <div>*/}
                {/*                            <h5>CVC</h5>*/}
                {/*                            <input type="text"/>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}

                {/*                <div className="wrap clearfix">*/}
                {/*                    <div className="fifty">*/}
                {/*                        <div>*/}
                {/*                            <a href="/" className="button bookNow">{t("myAccount:delete")}</a>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className="fifty">*/}
                {/*                        <div>*/}
                {/*                            <a href="/" className="button bookNow">{t("myAccount:update")}</a>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}

                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        );
    }
}

export default withTranslation()(MemberSettingsPayment);
