import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import * as Config from '../../config';

class SMSButton extends Component {

    constructor() {
        super();
        this.state = {
            time: Config.SMS_WAIT_SECONDS,
            isRunning: false
        };

        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }

    startTimer() {
        this.timer = setInterval(this.countDown, 1000);
        this.setState({
            isRunning: true
        });
    }

    countDown() {
        let newTime = this.state.time - 1;
        this.setState({
            time: newTime,
        });

        if (this.state.time === 0) {
            clearInterval(this.timer);
            this.setState({
                time: Config.SMS_WAIT_SECONDS,
                isRunning: false
            });
        }
    }

    render() {
        // const {t} = this.props;
        return (
            <div>
                {this.state.isRunning === false ?
                    <button
                        className="button"
                        onClick={this.startTimer}
                    >Send SMS Verification code
                    </button>
                    : <button
                        className="button grey"
                    >Please request again after {this.state.time} second{this.state.time !== 1 ? 's' : ''}
                    </button>
                }

            </div>
        );
    }
}

export default withTranslation()(SMSButton);
