import React, {Component} from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';

import {Redirect} from 'react-router';

import MemberMenuDesktop from '../../../components/02Members/MemberMenuDesktop';
import MemberMenuMobile from '../../../components/02Members/MemberMenuMobile';
import Popup from '../../../components/101Popup/Popup';

import {getSpaceDetail} from '../../../utils/04Api/Space';
import {getMyBookings, updateRegistrationMark} from '../../../utils/04Api/Booking';

// import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
import SpaceWBooking from "../../../components/02Members/SpaceWBooking";
// import * as MembersActionCreators from '../../actions/members';

import {filter, uniq} from 'lodash';
import moment from "moment";

class MemberBookings extends Component {
    constructor(props) {
        super(props);

        let logined = false,
            myId = '';
        if (this.props.members.Login.length !== 0) {
            logined = true;
            myId = props.members.Login.user._id;
        }

        this.state = {
            logined: logined,
            myId: myId,
            bookings: null,
            pastBookings: [],
            currentBookings: [],
            spaces: [],
            loading: true,
            filterShow: false,
            filter1: {
                address: "",
                showAll: true,
                hourly: false,
                monthly: false,
                timeShare: false,
                pending: true,
                confirmed: true,
            },
            filter2: {
                address: "",
                showAll: true,
                hourly: false,
                monthly: false,
                timeShare: false,
                finished: true,
                declined: true,
                cancelled: true,
                expired: true,
            },
            activeVehicleRegMarkBlock: false,
            VehicleRegMarkBlockExistCarplate: '',
            VehicleRegMarkBookingID: '',
            VehicleRegMarkCarplateID: '',
            VehicleRegMarkBlockCarplate: '',
        }

        this.getBooking = this.getBooking.bind(this);
    }

    componentDidMount = () => {
        // this.ApiSpaces();

        getMyBookings(this.props.members.Login.userToken, (r) => {
            // console.log("my bookings", r.body);
            this.setState({
                ...this.state,
                bookings: r.body
            }, () => {
                // console.log(this.state.bookings.length)
                if (!this.state.bookings.length) {
                    // console.log("no booking")
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                } else {
                    let sids = this.state.bookings.map((booking) => {
                        return booking.space._id;
                    })

                    sids = uniq(sids);
                    let total = sids.length;
                    let pastBookings = [];
                    let currentBookings = [];

                    sids.forEach((sid) => {
                        getSpaceDetail(sid, (res) => {
                            const space = res.body;
                            // console.log("getspace", sid, space);


                            const isCurrentBooking = processCurrentBooking(space);
                            if (isCurrentBooking)
                                currentBookings.push(isCurrentBooking);

                            const isPastBooking = processPastBooking(space);
                            if (isPastBooking)
                                pastBookings.push(isPastBooking);

                            // console.log("returned", pastBookings, currentBookings);

                            this.setState({
                                ...this.state,
                                pastBookings: pastBookings,
                                currentBookings: currentBookings,
                            }, () => {
                                total--;
                                // console.log(total);
                                if (total <= 0) {
                                    this.setState({
                                        ...this.state,
                                        loading: false,
                                    })
                                }
                            })
                        })
                    })
                }
            })
        });

        const processCurrentBooking = (space) => {
            // current bookings
            const now = moment().utcOffset(8);
            const bookings = this.state.bookings;

            let filteredBookings = filter(bookings, (booking) => {
                // console.log(booking.space._id, space._id)

                //2019-07-23 samson - using array to manage the status
                let status_for_finished = ['depositReleasing', 'complete', 'holdDeposit', 'completeWithDeposit'],
                    status_for_declined = ['rejected', 'rejectedBooker'],
                    status_for_cancelled = ['cancelled'],
                    status_for_expired = ['expired'];
                if (status_for_finished.includes(booking.status) || status_for_declined.includes(booking.status) || status_for_cancelled.includes(booking.status) || status_for_expired.includes(booking.status)
                ) {
                // if (booking.status === 'rejected' || booking.status === 'expired' ||
                //     booking.status === 'depositReleasing' || booking.status === 'complete' || booking.status === 'completeWithDeposit' ||
                //     booking.status === 'cancelled' || booking.status === 'holdDeposit'
                // ) {
                    return false;
                }

                if (booking.space._id === space._id) {
                    const booking_time = moment(booking.to).utcOffset(8);
                    if (!booking_time.isBefore(now))
                        return true;
                }

                return false;
            })

            // console.log(space);

            if (filteredBookings.length) {
                const s = {
                    bookings: filteredBookings,
                    space: space,
                }
                // console.log("return space", space._id, space);
                return s;
            }
            return false;
        }

        const processPastBooking = (space) => {
            // past booking
            const now = moment().utcOffset(8);
            const bookings = this.state.bookings;

            const filteredBookings = filter(bookings, (booking) => {
                //2019-07-23 samson - using array to manage the status
                let status_for_finished = ['depositReleasing', 'complete', 'holdDeposit', 'completeWithDeposit'],
                    status_for_declined = ['rejected', 'rejectedBooker'],
                    status_for_cancelled = ['cancelled'],
                    status_for_expired = ['expired'];
                if (status_for_finished.includes(booking.status) || status_for_declined.includes(booking.status) || status_for_cancelled.includes(booking.status) || status_for_expired.includes(booking.status)
                ) {
                // if (booking.status === 'rejected' || booking.status === 'expired' ||
                //     booking.status === 'depositReleasing' || booking.status === 'complete' || booking.status === 'completeWithDeposit' ||
                //     booking.status === 'cancelled' || booking.status === 'holdDeposit'
                // ) {
                    return true;
                }

                if (booking.space._id === space._id) {
                    const booking_time = moment(booking.to).utcOffset(8);
                    if (booking_time.isBefore(now)) {
                        // console.log(space._id, booking.space._id, booking._id, booking.status);
                        return true;
                    }
                }
                return false;
            })

            if (filteredBookings.length) {
                const s = {
                    bookings: filteredBookings,
                    space: space,
                }
                // space.bookings = filteredBookings;
                // console.log("return space", space._id, space);
                return s;
            }
            return false;
        }
        //
        //
        // const finishedLoading = ()=> {
        //     console.log("finished");
        //     const spaces = this.state.spaces;
        //     const bookings = this.state.bookings;
        //
        //     console.log("spaces", spaces);
        //     console.log("bookings", bookings);
        //     let pastBookings = spaces.map((space, i) => {
        //         console.log('baskbooking spce', space);
        //         let s = space;
        //
        //
        //         return null;
        //     })
        //     pastBookings = filter(pastBookings, (o) => { return o });
        //     console.log('pastBookings', pastBookings);
        //
        //     let currentBookings = spaces.map((space, i) => {
        //         let bookings = filter(this.state.bookings, (booking)=> {
        //             if (booking.space._id === space._id) {
        //                 const booking_time = moment(booking.to).utcOffset(8);
        //                 if (!booking_time.isBefore(now))
        //                     return true;
        //             }
        //             return false;
        //         })
        //
        //         if (bookings.length) {
        //             space.bookings = bookings;
        //             return space;
        //         }
        //         return null;
        //     })
        //     currentBookings = filter(currentBookings, (o) => { return o });
        //     console.log('currentBookings', currentBookings);
        //
        //     this.setState({
        //         ...this.state,
        //         pastBookings: pastBookings,
        //         currentBookings: currentBookings,
        //         loading: false,
        //     })
        // }
    }

    changeVehicleRegMark = (bookingID, carplateID, existCarplate) => {

        this.setState({
            ...this.state,
            activeVehicleRegMarkBlock: !this.state.activeVehicleRegMarkBlock,
            VehicleRegMarkBookingID: bookingID,
            VehicleRegMarkCarplateID: carplateID,
            VehicleRegMarkBlockExistCarplate: existCarplate,
        });
    }

    updateVehicleRegMark = () => {
        const access_token = this.props.members.Login.userToken,
            bookingID = this.state.VehicleRegMarkBookingID;

        let cb = () => {
            alert("Carplate updated");
            window.location.reload();
        };

        updateRegistrationMark(access_token, bookingID, this.state.VehicleRegMarkBlockCarplate, cb);
    }

    changeStateLevelOne = (data1, value) => {

        this.setState({
            [data1]: value
        });
    }

    changeStateLevelTwo = (data1, data2, value, cb) => {

        if (data2 === "showAll") {

            if (!this.state[data1].hourly && !this.state[data1].monthly && !this.state[data1].timeShare) {
                value = true;
            }

            this.setState({
                [data1]: {
                    ...this.state[data1],
                    showAll: value,
                    hourly: false,
                    monthly: false,
                    timeShare: false,
                }
            }, () => {
                if (typeof (cb) === "function")
                    cb();
            });
        } else {
            this.setState({
                [data1]: {
                    ...this.state[data1],
                    [data2]: value,
                    showAll: false,
                }
            }, () => {
                if (typeof (cb) === "function")
                    cb();

                if (!this.state[data1].hourly && !this.state[data1].monthly && !this.state[data1].timeShare) {

                    this.setState({
                        [data1]: {
                            ...this.state[data1],
                            showAll: true,
                            hourly: false,
                            monthly: false,
                            timeShare: false,
                        }
                    });
                }
            });
        }
    }

    getBooking = (booking_id) => {
        const booking = filter(this.state.bookings, (o) => {
            return o._id === booking_id;
        })
        if (booking.length)
            return booking[0];
        return null;
    }

    isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    render() {
        const {t, i18n} = this.props;

        const loading = this.state.loading;

        if (!this.state.logined)
            return <Redirect to={"/" + i18n.language + "/login"}/>;

      // console.log(this.state);

        return (
            <div className="wrapper-container memberBookings">
                <MemberMenuMobile/>
                <Popup content={this.state.PopupContent}/>
                <div className="containerMain paddingFix">
                    <MemberMenuDesktop/>

                    <div className="content clearfix">
                        <div className="wrapper-tabs clearfix">
                            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                                <Tab eventKey={1} title={t("myAccount:current") + t("myAccount:bookings")}>
                                    <div style={{minHeight: "600px"}}>
                                        {loading && <div>{t("common:Loading")}</div>}

                                        {!loading && this.state.currentBookings.length === 0 &&
                                        <div>{t("myAccount:tabBookings.tab.noCurrentBooking")}</div>
                                        }

                                        {!loading && this.state.currentBookings.length > 0 &&
                                        <div>
                                            <div className="profileFilter">
                                                {/*<input placeholder={t("myAccount:filter.address")} type="text"*/}
                                                {/*       value={this.state.filter1.address}*/}
                                                {/*       onChange={(e) => this.changeStateLevelTwo("filter1", "address", e.target.value)}*/}
                                                {/*/>*/}

                                                {/*<div className="sep-25"/>*/}

                                                <div className="filterTitleBlock"
                                                     onClick={() => this.changeStateLevelOne("filterShow", !this.state.filterShow)}
                                                >
                                                    <div
                                                        className="filterTitle">{this.state.filterShow ? t("myAccount:filter.showFilters.off") : t("myAccount:filter.showFilters.on")}</div>
                                                    <div className="filterTitleArrow"></div>
                                                </div>

                                                <div className="sep-25"/>

                                                <div
                                                    className={"filterBlock " + (this.state.filterShow ? "active" : '')}>
                                                    <div className="wrap clearfix">
                                                        <div className="twenty left mobile-full">
                                                            {t("myAccount:filter.bookType.title")}
                                                        </div>
                                                        <div className="eighty left mobile-full">
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="showAll1" name="showAll1"
                                                                           checked={this.state.filter1.showAll}
                                                                           onChange={() => this.changeStateLevelTwo("filter1", "showAll", !this.state.filter1.showAll)}
                                                                    />
                                                                    <label htmlFor="showAll1"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="showAll1">{t("myAccount:filter.bookType.options.showAll")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="hourly1" name="hourly1"
                                                                           checked={this.state.filter1.hourly}
                                                                           onChange={() => this.changeStateLevelTwo("filter1", "hourly", !this.state.filter1.hourly)}
                                                                    />
                                                                    <label htmlFor="hourly1"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="hourly1">{t("myAccount:filter.bookType.options.hourly")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="monthly1" name="monthly1"
                                                                           checked={this.state.filter1.monthly}
                                                                           onChange={() => this.changeStateLevelTwo("filter1", "monthly", !this.state.filter1.monthly)}
                                                                    />
                                                                    <label htmlFor="monthly1"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="monthly1">{t("myAccount:filter.bookType.options.monthly")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="timeShare1"
                                                                           name="timeShare1"
                                                                           checked={this.state.filter1.timeShare}
                                                                           onChange={() => this.changeStateLevelTwo("filter1", "timeShare", !this.state.filter1.timeShare)}
                                                                    />
                                                                    <label htmlFor="timeShare1"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="timeShare1">{t("myAccount:filter.bookType.options.timeShare")}</label>
                                                            </div>
                                                        </div>

                                                        <div className="sep-25"/>

                                                        <div className="twenty left mobile-full">
                                                            {t("myAccount:filter.booking.title")}
                                                        </div>
                                                        <div className="eighty left mobile-full">
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="pending1" name="pending1"
                                                                           checked={this.state.filter1.pending}
                                                                           onChange={() => this.changeStateLevelTwo("filter1", "pending", !this.state.filter1.pending)}
                                                                    />
                                                                    <label htmlFor="pending1"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="pending1">{t("myAccount:filter.booking.options.pending")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="confirmed1"
                                                                           name="confirmed1"
                                                                           checked={this.state.filter1.confirmed}
                                                                           onChange={() => this.changeStateLevelTwo("filter1", "confirmed", !this.state.filter1.confirmed)}
                                                                    />
                                                                    <label htmlFor="confirmed1"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="confirmed1">{t("myAccount:filter.booking.options.confirmed")}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sep-25"/>
                                        </div>
                                        }

                                        {!loading && this.state.currentBookings.length > 0 &&
                                        (this.state.currentBookings.map((s, i) => {

                                                let filteredBookings = {}, row = 0;

                                                let filter = this.state.filter1;

                                                s.bookings.forEach((value, key) => {

                                                    let display = false;

                                                    //2019-07-19 samson - using array to manage status
                                                    let status_for_pending = ['pending', 'pendingBooker'],
                                                        status_for_confirmed = ['pendingStart', 'processing'];

                                                    if(filter.showAll) {
                                                        if(filter.pending && status_for_pending.includes(value.status))
                                                            display = true;

                                                        if(filter.confirmed && status_for_confirmed.includes(value.status))
                                                            display= true;
                                                    } else {
                                                        if(
                                                            (filter.hourly && value.type === "hourly") ||
                                                            (filter.monthly && value.type === "monthly") ||
                                                            (filter.timeshare && value.type === "timeshare")
                                                        ) {
                                                            if(filter.pending && status_for_pending.includes(value.status))
                                                                display = true;

                                                            if(filter.confirmed && status_for_confirmed.includes(value.status))
                                                                display= true;
                                                        }
                                                    }

                                                    // if (filter.showAll && (filter.pending && value.status === "pending")) {
                                                    //     display = true;
                                                    // } else if (filter.showAll && (filter.confirmed && value.status === "approved")) {
                                                    //     display = true;
                                                    // } else if (
                                                    //     (
                                                    //         (!filter.showAll && filter.hourly && value.type === "hourly") ||
                                                    //         (!filter.showAll && filter.monthly && value.type === "monthly") ||
                                                    //         (!filter.showAll && filter.timeshare && value.type === "timeshare")
                                                    //     ) && (filter.pending && value.status === "pending")
                                                    // ) {
                                                    //     display = true;
                                                    // } else if (
                                                    //     (
                                                    //         (!filter.showAll && filter.hourly && value.type === "hourly") ||
                                                    //         (!filter.showAll && filter.monthly && value.type === "monthly") ||
                                                    //         (!filter.showAll && filter.timeshare && value.type === "timeshare")
                                                    //     ) && (filter.confirmed && value.status === "approved")
                                                    // ) {
                                                    //     display = true;
                                                    // }




                                                    if (display) {

                                                        filteredBookings[row] = value;

                                                        row++;
                                                    }
                                                });

                                                if (this.isEmpty(filteredBookings)) {
                                                    return null;
                                                } else {
                                                    //2019-07-24 samson - check has record before show the spaceWBooking
                                                    let hasRecord =false;
                                                    Object.keys(filteredBookings).forEach((key,idx) => {
                                                        if(filteredBookings[key].space._id === s.space._id) {
                                                            hasRecord = true;
                                                            return false;
                                                        }
                                                    });
                                                    if(hasRecord) {
                                                        return (
                                                            <SpaceWBooking
                                                                // space={space}
                                                                // key={i}
                                                                // isOwner={true}
                                                                key={i}
                                                                id={s.space._id}
                                                                count={i}
                                                                space={{data: s.space}}
                                                                bookings={filteredBookings}
                                                                lowestPrice="$0"
                                                                type="my_booking"
                                                                getBooking={this.getBooking}
                                                                isOwner={false}
                                                                myId={this.state.myId}
                                                                showBooking={true}
                                                                passBooking={false}
                                                                activeVehicleRegMarkBlock={this.state.activeVehicleRegMarkBlock}
                                                                VehicleRegMarkBlockExistCarplate={this.state.VehicleRegMarkBlockExistCarplate}
                                                                changeVehicleRegMark={this.changeVehicleRegMark}
                                                                updateVehicleRegMark={this.updateVehicleRegMark}
                                                                changeStateLevelOne={this.changeStateLevelOne}
                                                                filterType="current_booking"
                                                            />
                                                        )
                                                    } else
                                                        return null;
                                                }
                                            }
                                        ))
                                        }
                                    </div>
                                </Tab>
                                <Tab eventKey={2} title={t("myAccount:past") + " " + t("myAccount:bookings")}>
                                    <div style={{minHeight: "600px"}}>
                                        {loading && <div>{t("common:Loading")}</div>}

                                        {!loading && this.state.pastBookings.length === 0 &&
                                        <div>{t("myAccount:tabBookings.tab.noPastBooking")}</div>
                                        }

                                        {!loading && this.state.pastBookings.length > 0 &&
                                        <div>
                                            <div className="profileFilter">
                                                {/*<input placeholder="Address" type="text"*/}
                                                {/*       value={this.state.filter2.address}*/}
                                                {/*       onChange={(e) => this.changeStateLevelTwo("filter2", "address", e.target.value)}*/}
                                                {/*/>*/}

                                                {/*<div className="sep-25"/>*/}

                                                <div className="filterTitleBlock"
                                                     onClick={(e) => this.changeStateLevelOne("filterShow", !this.state.filterShow)}
                                                >
                                                    <div
                                                        className="filterTitle">{this.state.filterShow ? t("myAccount:filter.showFilters.off") : t("myAccount:filter.showFilters.on")}</div>
                                                    <div className="filterTitleArrow"></div>
                                                </div>

                                                <div className="sep-25"/>

                                                <div
                                                    className={"filterBlock " + (this.state.filterShow ? "active" : '')}>
                                                    <div className="wrap clearfix">
                                                        <div
                                                            className="twenty left mobile-full">{t("myAccount:filter.bookType.title")}</div>
                                                        <div className="eighty left mobile-full">
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="showAll2" name="showAll2"
                                                                           checked={this.state.filter2.showAll}
                                                                           onChange={() => this.changeStateLevelTwo("filter2", "showAll", !this.state.filter2.showAll)}
                                                                    />
                                                                    <label htmlFor="showAll2"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="showAll2">{t("myAccount:filter.bookType.options.showAll")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="hourly2" name="hourly2"
                                                                           checked={this.state.filter2.hourly}
                                                                           onChange={() => this.changeStateLevelTwo("filter2", "hourly", !this.state.filter2.hourly)}
                                                                    />
                                                                    <label htmlFor="hourly2"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="hourly2">{t("myAccount:filter.bookType.options.hourly")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="monthly2" name="monthly2"
                                                                           checked={this.state.filter2.monthly}
                                                                           onChange={() => this.changeStateLevelTwo("filter2", "monthly", !this.state.filter2.monthly)}
                                                                    />
                                                                    <label htmlFor="monthly2"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="monthly2">{t("myAccount:filter.bookType.options.monthly")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="timeShare2"
                                                                           name="timeShare2"
                                                                           checked={this.state.filter2.timeShare}
                                                                           onChange={() => this.changeStateLevelTwo("filter2", "timeShare", !this.state.filter2.timeShare)}
                                                                    />
                                                                    <label htmlFor="timeShare2"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="timeShare2">{t("myAccount:filter.bookType.options.timeShare")}</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="sep-25"/>

                                                    <div className="wrap clearfix">
                                                        <div
                                                            className="twenty left mobile-full">{t("myAccount:filter.status.title")}</div>
                                                        <div className="eighty left mobile-full">
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="bookingFinished"
                                                                           checked={this.state.filter2.finished}
                                                                           onChange={() => this.changeStateLevelTwo("filter2", "finished", !this.state.filter2.finished)}
                                                                           name="bookingFinished"/>
                                                                    <label htmlFor="bookingFinished"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="bookingFinished">{t("myAccount:filter.status.options.finished")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="requestDeclined"
                                                                           checked={this.state.filter2.declined}
                                                                           onChange={() => this.changeStateLevelTwo("filter2", "declined", !this.state.filter2.declined)}
                                                                           name="requestDeclined"/>
                                                                    <label htmlFor="requestDeclined"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="requestDeclined">{t("myAccount:filter.status.options.declined")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="requestCancelled"
                                                                           checked={this.state.filter2.cancelled}
                                                                           onChange={() => this.changeStateLevelTwo("filter2", "cancelled", !this.state.filter2.cancelled)}
                                                                           name="requestCancelled"/>
                                                                    <label htmlFor="requestCancelled"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="requestCancelled">{t("myAccount:filter.status.options.cancelled")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="requestExpired"
                                                                           checked={this.state.filter2.expired}
                                                                           onChange={() => this.changeStateLevelTwo("filter2", "expired", !this.state.filter2.expired)}
                                                                           name="requestExpired"/>
                                                                    <label htmlFor="requestExpired"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="requestExpired">{t("myAccount:filter.status.options.expired")}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="sep-25"/>
                                        </div>
                                        }

                                        {!loading && this.state.pastBookings.length > 0 &&
                                        (this.state.pastBookings.map((s, i) => {

                                                let filteredBookings = {}, row = 0;

                                                let filter = this.state.filter2;

                                                s.bookings.forEach((value, key) => {
                                                    let display = false;

                                                    //2019-07-19 samson - using array to manage status
                                                    let status_for_finished = ['depositReleasing', 'complete', 'holdDeposit', 'completeWithDeposit', 'processing'],
                                                        status_for_declined = ['rejected', 'rejectedBooker'],
                                                        status_for_cancelled = ['cancelled'],
                                                        status_for_expired = ['expired'];

                                                    if(filter.showAll) {
                                                        if(filter.finished && status_for_finished.includes(value.status))
                                                            display = true;

                                                        if(filter.declined && status_for_declined.includes(value.status))
                                                            display= true;

                                                        if(filter.cancelled && status_for_cancelled.includes(value.status))
                                                            display = true;

                                                        if(filter.expired && status_for_expired.includes(value.status))
                                                            display= true;
                                                    } else {
                                                        if(
                                                            (filter.hourly && value.type === "hourly") ||
                                                            (filter.monthly && value.type === "monthly") ||
                                                            (filter.timeshare && value.type === "timeshare")
                                                        ) {
                                                            if(filter.finished && status_for_finished.includes(value.status))
                                                                display = true;

                                                            if(filter.declined && status_for_declined.includes(value.status))
                                                                display= true;

                                                            if(filter.cancelled && status_for_cancelled.includes(value.status))
                                                                display = true;

                                                            if(filter.expired && status_for_expired.includes(value.status))
                                                                display= true;
                                                        }
                                                    }

                                                    if (display) {

                                                        filteredBookings[row] = value;

                                                        row++;
                                                    }
                                                });
                                                if (this.isEmpty(filteredBookings)) {
                                                    return null;
                                                } else {
                                                    //2019-07-24 samson - check has record before show the spaceWBooking
                                                    let hasRecord =false;
                                                    Object.keys(filteredBookings).forEach((key,idx) => {
                                                        if(filteredBookings[key].space._id === s.space._id) {
                                                            hasRecord = true;
                                                            return false;
                                                        }
                                                    });
                                                    if(hasRecord) {
                                                        return (
                                                            <SpaceWBooking
                                                                // space={space}
                                                                // key={i}
                                                                // isOwner={true}
                                                                key={i}
                                                                id={s.space._id}
                                                                count={i}
                                                                space={{data: s.space}}
                                                                bookings={filteredBookings}
                                                                lowestPrice="$0"
                                                                type="my_booking"
                                                                getBooking={this.getBooking}
                                                                isOwner={false}
                                                                myId={this.state.myId}
                                                                showBooking={true}
                                                                passBooking={true}
                                                                activeVehicleRegMarkBlock={this.state.activeVehicleRegMarkBlock}
                                                                VehicleRegMarkBlockExistCarplate={this.state.VehicleRegMarkBlockExistCarplate}
                                                                changeVehicleRegMark={this.changeVehicleRegMark}
                                                                updateVehicleRegMark={this.updateVehicleRegMark}
                                                                changeStateLevelOne={this.changeStateLevelOne}
                                                                filterType="past_booking"
                                                            />
                                                        )
                                                    } else
                                                        return null;
                                                }
                                            }
                                        ))
                                        }
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {
        members: state.members
    }
);

export default withTranslation()(connect(mapStateToProps)(MemberBookings));
