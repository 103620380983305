import React, { useState, useEffect } from 'react';
import * as UserApi from "../04Api/User";
import * as WishListApi from "../04Api/WishList";
import * as RenewalApi from "../04Api/Renewal";
import {bindActionCreators} from "redux";
import * as MembersActionCreators from "../../actions/members";
import Cookies from "universal-cookie";

const cookies = new Cookies();

function useTokenLogin(token) {
  const [loginResult, setLoginResult] = useState(null);

  useEffect(() => {
    if (token){
      UserApi.getMe(token, (obj) => {

        if (obj.status === 200) {
          const postData = {
            userToken: token,
            userId: obj.body._id,
            user: obj.body,
            userWishList: []
          }

          WishListApi.getWishList(token, (obj) => {
            if (obj.status === 200) {
              obj.body.map((record, index) => {
                postData['userWishList'].push(record.space._id);
                return null;
              })

              setLoginResult({success: true, response: obj.body, postData});
            } else {
              setLoginResult({success: false, response: obj.body});
            }
          })

          RenewalApi.getEnterpriseToken(token,
            (response) => {
              if (response.body.msg === 'success') {
                cookies.set('enterprise_token', response.body.data.token, { path: '/'});
                cookies.set('aggregator_token', token, { path: '/' });
              }
            }, (error) => {
              console.log(error)
            })

        } else {
          setLoginResult({success: false, response: obj.body});
        }
      });
    }
  }, [])

  return loginResult;
}

function withTokenLogin(Component) {
  return function WrappedComponent(props) {
    const { dispatch, i18n, history, params } = props;
    const toLogin = bindActionCreators(MembersActionCreators.toLogin, dispatch);
    const [loginResult, setLoginResult] = useState(null);
    useEffect(() => {
      let token = params ? params.token : null;
      if (token){
        UserApi.getMe(token, (obj) => {

          if (obj.status === 200) {
            const postData = {
              userToken: token,
              userId: obj.body._id,
              user: obj.body,
              userWishList: []
            }

            WishListApi.getWishList(token, (obj) => {
              if (obj.status === 200) {
                obj.body.map((record, index) => {
                  postData['userWishList'].push(record.space._id);
                  return null;
                })
                RenewalApi.getEnterpriseToken(token,
                  (response) => {
                    if (response.body.msg === 'success') {
                      cookies.set('enterprise_token', response.body.data.token, { path: '/'});
                      cookies.set('aggregator_token', token, { path: '/' });

                      setLoginResult({success: true, response: obj.body, postData});
                    }
                  }, (error) => {
                    console.log(error)
                  })
              } else {
                setLoginResult({success: false, response: obj.body});
              }
            })

          } else {
            setLoginResult({success: false, response: obj.body});
          }
        }, (error) => {
          setLoginResult({success: false, response: error});
        });
      }
    }, []);

    useEffect(() => {
      if (!loginResult) return;
      if (!loginResult.success) {
        history.push(`/${i18n.language}/login`);
        return;
      }
      toLogin(loginResult.postData);
      let currentPath = window.location.pathname;
      console.log({currentPath})
      window.location.href = currentPath
    }, [loginResult]);

    return <Component {...props} />;
  }
}

export {
  withTokenLogin
};
