import * as ListParkingSpaceActionTypes from '../actiontypes/listParkingSpace';

/* STEP ONE */
export const SubmitStep1 = (data) => {
    return {
        type: ListParkingSpaceActionTypes.SubmitStep1,
        data
    };
};

/* STEP TWO */
export const SubmitStep2 = (data) => {
    return {
        type: ListParkingSpaceActionTypes.SubmitStep2,
        data
    };
};

/* STEP THREE */
export const SubmitStep3 = (data) => {
    return {
        type: ListParkingSpaceActionTypes.SubmitStep3,
        data
    };
};

/* ClearListParkingSpace */
export const ClearListParkingSpace = () => {
    return {
        type: ListParkingSpaceActionTypes.ClearListParkingSpace
    };
}

/* Contact */
export const Contact = () => {
    return {
        type: ListParkingSpaceActionTypes.Contact,
    };
}

/* Payment */
export const Payment = () => {
    return {
        type: ListParkingSpaceActionTypes.Payment,
    };
}