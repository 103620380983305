import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

import LicenseAgreementZHHK from "../../components/10General/LicenseAgreement_zh_HK";
import LicenseAgreementENUS from "../../components/10General/LicenseAgreement_en_US";
import {getTnc} from "../../utils/04Api/Tnc";

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as InviteeActionCreators from '../actions/invitee';

class LicenseAgreement extends Component {

    constructor(props) {
        super(props);

        this.state = {
            licenseAgreement: null,
        }
    }

    componentDidMount() {

        this.ApiGetlicenseAgreement(this.ApiGetlicenseAgreement);
    }

    ApiGetlicenseAgreement = (errorCallback) => {

        let callback = (res) => {

            if (res.status === 200) {

                // console.log(res);

                this.setState({
                    ...this.state,
                    licenseAgreement: res.body
                })
            }
        }

        getTnc('license_agreement', callback, errorCallback);
    }

    render() {

        const {
            t,
            i18n,
        } = this.props;

        return (
            <div className="wrapper-container media">

                <div className="page-title">
                    <h2>
                        {t("Menu.LicenseAgreement")}
                    </h2>
                </div>

                <div className="sep-0"/>

                <div className="containerMain">
                    <div className="content clearfix">
                        {i18n.language === 'zh-HK'
                            ? this.state.licenseAgreement !== null ?
                                <LicenseAgreementZHHK
                                    content={this.state.licenseAgreement['zh-hk'].license_agreement_content_chi.value}/> : ''
                            : this.state.licenseAgreement !== null ?
                                <LicenseAgreementENUS
                                    content={this.state.licenseAgreement['en-us'].license_agreement_content_eng.value}/> : ''
                        }
                        {/*{i18n.language === 'zh-HK'*/}
                        {/*    ? <LicenseAgreementZHHK/>*/}
                        {/*    : <LicenseAgreementENUS/>*/}
                        {/*}*/}
                    </div>
                </div>
            </div>
        );
    }
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default withTranslation()(LicenseAgreement);
