import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import './css/App.scss';
import {withTranslation} from 'react-i18next';

import * as Config from './config';

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import {find} from 'lodash';

// import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
// import * as AnimationsActionCreators from './actions/animations';

import querySearch from "stringquery";

import MobileMenu from './components/100Include/mobileMenu';
import Header from './components/100Include/header';
import Footer from './components/100Include/footer';

import * as HelperDesktopHandle from './utils/00General/DesktopHandle';
import * as HelperMobileHandle from './utils/00General/MobileHandle';
import * as HelperPopup from './utils/00General/Popup';
import * as HelperMapviewHandle from './utils/03FindParkingSpace/MapviewHandle';
// import * as HelperProfileFilter from './utils/02Members/profileFilter';
// import * as FacebookSDK from './utils/00General/FacebookSDK';

import Home from './containers/00Home/Home';
import About from './containers/00Home/About';

import HotSpaces from './containers/01HotSpaces/HotSpaces';

// import MemberGetStarted from './containers/02Members/Login/MemberGetStarted';
import MemberLogin from './containers/02Members/Login/MemberLogin';
import MemberLogout from './containers/02Members/Logout/MemberLogout';
import MemberCreateAccountType from './containers/02Members/Register/MemberCreateAccountType';
import MemberCreateAccountStart from './containers/02Members/Register/MemberCreateAccountStart';
import MemberCreateAccount from './containers/02Members/Register/MemberCreateAccount';
import MemberCreateAccountComplete from './containers/02Members/Register/MemberCreateAccountComplete';
import MemberCreateAccountCorporateComplete from './containers/02Members/Register/MemberCreateAccountCorporateComplete';
import MemberMobileVerification from './containers/02Members/Verification/MemberMobileVerification';
import MemberForgetPassword from './containers/02Members/ForgetPassword/MemberForgetPassword';
import MemberForgetPasswordConfirm from './containers/02Members/ForgetPassword/MemberForgetPasswordConfirm';
import MemberResetPassword from './containers/02Members/ForgetPassword/MemberResetPassword';
import MemberEmailValiation from './containers/02Members/Verification/MemberEmailVerification';
import MemberCreateAccountCorporate from './containers/02Members/Register/MemberCreateAccountCorporate';
import UpdatePayment from './containers/02Members/UpdateBookingPayment/UpdatePayment';
import UpdatePaymentComplete from './containers/02Members/UpdateBookingPayment/UpdatePaymentComplete';

import MemberWishlist from './containers/02Members/Details/MemberWishlist';
import MemberBookings from './containers/02Members/Details/MemberBookings';
import MemberListedSpaces from './containers/02Members/Details/MemberListedSpaces';
import MemberHistory from './containers/02Members/Details/MemberHistory';
import MemberProfile from './containers/02Members/Details/MemberProfile';
import MemberCredit from './containers/02Members/Details/MemberCredit';
import MemberSettings from './containers/02Members/Details/MemberSettings';

// import SpaceMap from './containers/03FindParkingSpace/SpaceMap';
// import ClusterMapPage from './containers/03FindParkingSpace/ClusterMapPage';
import FindYourSpace from './containers/03FindParkingSpace/FindYourSpace';
import SpaceOne from './containers/03FindParkingSpace/SpaceOne';
// import SpaceTwo from './containers/03FindParkingSpace/SpaceTwo';
// import SpaceThree from './containers/03FindParkingSpace/SpaceThree';
import DetailMonthly from './containers/03FindParkingSpace/DetailMonthly';
import DetailHourly from './containers/03FindParkingSpace/DetailHourly';
import DetailTimeshare from './containers/03FindParkingSpace/DetailTimeshare';
import Payment from './containers/03FindParkingSpace/Payment';
import LandlordProfile from './containers/03FindParkingSpace/LandlordProfile';
// import DetailShare from './containers/03FindParkingSpace/DetailShare';
import FindYourSpaceConfirm from './containers/03FindParkingSpace/FindYourSpaceConfirm';
import FindYourSpacePending from './containers/03FindParkingSpace/FindYourSpacePending';

import StepOne from './containers/04ListASpace/StepOne';
import StepTwo from './containers/04ListASpace/StepTwo';
import StepThree from './containers/04ListASpace/StepThree';
import ListASpaceConfirm from './containers/04ListASpace/ListASpaceConfirm';

import Preview from './containers/04ListASpace/Preview';

import Review from './containers/12Review/Review';

import Inbox from './containers/05Messages/Inbox';
import Chat from './containers/05Messages/Chat';
import ContactOwner from './containers/05Messages/ContactOwner';

import Contact from './containers/07Contact/Contact';
import HelpCenter from './containers/11HelpCenter/HelpCenter';

import Discover from './containers/06Discover/Discover';
import Benefits from './containers/06Discover/Benefits';

import Media from './containers/08Media/Media';
import MediaDetail from './containers/08Media/MediaDetail';
import Maintenance from './containers/10General/Maintenance';
// import Results from './containers/09Results/Results';

import Privacy from './containers/10General/Privacy';
import Terms from './containers/10General/Terms';
import Disclaimer from './containers/10General/Disclaimer';
import Cancellation from './containers/10General/Cancellation';
import LicenseAgreement from './containers/10General/LicenseAgreement';
import ParkerProtectionGuarantee from './containers/10General/ParkerProtectionGuarantee';

import PageNotFound from './containers/PageNotFound';

import EditSpace from './containers/02Members/EditSpace/EditSpace';
import EditSpaceConfirm from './containers/02Members/EditSpace/EditSpaceConfirm';

import Helmet from 'react-helmet';
import ProfileEmailValidation from "./containers/02Members/Verification/ProfileEmailValidation";
import Partners from "./containers/10General/Partners";
import MemberRenewal from "./containers/02Members/Details/MemberRenewal";

import Cookies from 'universal-cookie';
import moment from "moment";
import {fr} from "faker/lib/locales";

const cookies = new Cookies();

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            ready: false,
            maintenance: false,
        }

        ReactGA.initialize('UA-145408228-1', {
            debug: false,
            titleCase: false,
        });

        ReactPixel.init('337987423774322', {
            autoConfig: true, 	// set pixel's autoConfig
            debug: false, 		// enable logs
        });
    }

    ready() {
        this.setState({
            ...this.state,
            ready: true
        }, () => {
            HelperDesktopHandle.DesktopHandle.init();
            HelperMobileHandle.MobileHandle.init();
            HelperMobileHandle.MobileHandle.containersSize();
            HelperPopup.Popup.init();
            HelperPopup.Popup.containersSize();
            window.addEventListener("resize", this.windowResize);

            this.titleTranlate();

            this.renderSwitch(this.props.route);

            // console.log('componentDidMount', this.props.route.location.pathname);
            ReactGA.pageview(this.props.route.location.pathname);

            ReactPixel.pageView(this.props.route.location.pathname);

            // HelperProfileFilter.profileFilter.init();

            // FacebookSDK.initFacebook();
        })
    }


    componentDidMount() {
        // check if under maintenance
        // if yes then check whitelist ips
        // console.log(Config.MAINTENANCE);
        if (!this.isAppView() && cookies.get('isAppView') === 'true'){
            if (performance.navigation.type === 1) {
                cookies.set('isAppView', false, { path: '/' });
            }
        }

        if (this.isAppView()){
            cookies.set('isAppView', true, { path: '/' });
        }

        if (String(Config.MAINTENANCE) === '1') {
            const whitelist = [
                '180.131.182.14',
                '218.255.104.110',
            ];

            fetch(Config.API_URL + "auth/ip")
                .then(res => res.json())
                .then(res => {
                    // console.log('ip', res.ip);
                    if (find(whitelist, (o) => o === res.ip))
                        this.ready();
                    else {
                        //window.location.href='/discover.html';

                        this.setState({
                            ...this.state,
                            maintenance: true,
                        });
                    }
                });


        } else {
            this.ready();
        }
    }

    componentDidUpdate(p) {
        window.scrollTo(0, 0);
        HelperPopup.Popup.init();

        // console.log('componentDidUpdate', p.route.location.pathname);
        if (!p.route.location.pathname || p.route.location.pathname !== this.props.route.location.pathname) {
            // console.log("path updated", this.props.route.location.pathname);

            ReactGA.pageview(this.props.route.location.pathname);
        }
    }

    windowResize() {
        HelperDesktopHandle.DesktopHandle.resetDesktopMenu();
        HelperDesktopHandle.DesktopHandle.maxHeightDesktopMenu();
        HelperMobileHandle.MobileHandle.containersSize();
        HelperPopup.Popup.containersSize();
        HelperMapviewHandle.MapviewHandle.containersSize();
    }

    titleTranlate = () => {

        this.setState({
            ...this.state,
            loading: false,
        });
    }

    // change URL
    renderSwitch(route) {
        let pathname = route.location.pathname,
            search = route.location.search,
            urlArray = pathname.split("/"),
            params = null;

        if (search !== "")
            params = querySearch(search);

        const metas = this.getMetaTag(urlArray, params);

        return (
            <div>
                <Helmet>
                    <title>Let's Park</title>
                    {/*<title>{t("General.LetsPark")} {metas.title !== "" ? " - " + metas.title : ""}</title>*/}
                    <meta name="description" content={metas.description}/>
                </Helmet>

                {this.getComponent(urlArray, params)}
            </div>
        )
    }

    getMetaTag(urlArray, params) {
        const component = urlArray[2];

        let metas = {
            title: "",
            description: "Let’s Park是一個嶄新的電子泊車平台，提倡全新共享概念「車位矜貴 吾好浪費亅，為香港車主及車位業主發掘閒置的泊車資源，提升車位回報，與大家一起開展香港智慧出行的新旅程。",
        };

        if (component) {
            switch (component) {
                case 'about':
                    metas['title'] = "About Us";
                    break;

                case 'hotSpaces':
                    metas['title'] = 'Hot Spaces';
                    break;

                case 'login':
                case 'memberGetStarted':
                    metas['title'] = 'Login';
                    break;

                case 'logout':
                    metas['title'] = 'Logout';
                    break;

                case 'register':
                case 'register-individual':
                case 'memberCreateAccount':
                case 'register-success':
                case 'corporate-register-success':
                    metas['title'] = 'Register';
                    break;

                default:
                    metas['title'] = '';
            }
        }
        return metas;
    }

    getComponent(urlArray, params) {
        if (this.state.maintenance)
            return <Maintenance/>;

        let language = urlArray[1],
            component = urlArray[2];

        switch (component) {
            case 'findYourSpace':
                document.body.classList.add('hide_add_this')
                break;
            default:
                document.body.classList.remove('hide_add_this')
        }

        if (component) {
            switch (component) {
                case 'home': {

                    return <Home/>;
                }
                case 'about': {
                    return (
                        <About/>
                    );
                }
                case 'hotSpaces': {
                    return <HotSpaces/>;
                }

                // members
                case 'memberGetStarted': {

                    return <MemberLogin
                        params={params}
                    />;
                }
                case 'login': {

                    return <MemberLogin/>;
                }
                case 'logout': {
                    return <MemberLogout/>;
                }

                case 'register': {

                    return <MemberCreateAccountType/>;
                }

                case 'register-individual': {

                    return <MemberCreateAccountStart/>;
                }

                case 'memberCreateAccount': {

                    return <MemberCreateAccount
                        params={params}
                    />;
                }
                case 'register-success': {

                    return <MemberCreateAccountComplete
                        params={params}
                    />;
                }
                case 'corporate-register-success': {

                    return <MemberCreateAccountCorporateComplete
                        params={params}
                    />;
                }
                case 'memberMobileVerification': {
                    return <MemberMobileVerification/>;
                }
                case 'memberForgetPassword': {
                    return <MemberForgetPassword/>;
                }
                case 'forgetPasswordConfirm': {
                    return <MemberForgetPasswordConfirm
                        params={params}
                    />;
                }

                case 'memberEmailValiation': {
                    return <MemberEmailValiation
                        params={params}
                    />;
                }

                //2019-08-08 Samson- for profile email modification
                case 'profileEmailValidation': {
                    return <ProfileEmailValidation
                        params={params}
                    />;
                }

                case 'register-corporate': {
                    return <MemberCreateAccountCorporate/>;
                }

                case 'memberResetPassword': {
                    return <MemberResetPassword params={params}/>;
                }

                case 'memberWishlist': {
                    return <MemberWishlist/>;
                }
                case 'memberBookings': {
                    return <MemberBookings/>;
                }
                case 'memberListedSpaces': {
                    return <MemberListedSpaces/>;
                }
                case 'memberHistory': {
                    return <MemberHistory/>;
                }
                case 'memberProfile': {
                    return <MemberProfile/>;
                }
                case 'memberCredit': {
                    return <MemberCredit/>;
                }
                case 'memberRenewal': {
                    return <MemberRenewal params={params}/>;
                }
                case 'settings': {
                    return <MemberSettings/>;
                }

                // list a space
                case 'stepOne': {
                    return <StepOne/>;
                }
                case 'stepTwo': {
                    return <StepTwo/>;
                }
                case 'stepThree': {
                    return <StepThree/>;
                }
                case 'preview': {
                    return <Preview/>;
                }
                case 'listASpaceConfirm': {
                    return <ListASpaceConfirm
                        params={params}
                    />;
                }

                // find a space
                case 'findYourSpace': {
                    return <FindYourSpace
                        search_params={params}
                    />;
                }
                case 'spaces':
                case 'space': {
                    let space_id = urlArray[3];
                    if (!space_id) {
                        // TODO: add checking for valid space_id
                        return <Redirect to={"/" + language + "/home"}/>;
                    }
                    return <SpaceOne
                        params={params}
                        space_id={space_id}
                    />;
                }
                case 'detailMonthly': {
                    return <DetailMonthly/>;
                }
                case 'detailHourly': {
                    return <DetailHourly/>;
                }
                case 'detailTimeshare': {
                    return <DetailTimeshare/>;
                }
                case 'payment': {
                    let space_id = urlArray[3];
                    if (!space_id) {
                        // TODO: add checking for valid space_id
                        return <Redirect to={"/" + language + "/home"}/>;
                    }

                    if (typeof (params) === "undefined" || params === null)
                        return <Redirect to={"/" + language + "/space/" + space_id}/>;

                    const querys = ['from', 'to', 'booking_type'];
                    querys.forEach((itm, idx) => {
                        if (!params.hasOwnProperty(itm))
                            return <Redirect to={"/" + language + "/space/" + space_id}/>;

                    });

                    if ((params.booking_type === 'hourly' && (!params.from_time || !params.to_time)))
                        return <Redirect to={`/${language}/space/${space_id}?booking_type=hourly`}/>;

                    const fromMoment = params.booking_type === 'hourly' ? moment(`${params.from}T${params.from_time}+08:00`) : moment(`${params.from}T00:00+08:00`);
                    const toMoment = params.booking_type === 'hourly' ? moment(`${params.to}T${params.to_time}+08:00`) : moment(`${params.to}T23:59+08:00`);

                    if (params.booking_type !== 'hourly' && fromMoment.isSameOrBefore(moment(), 'day')) {
                        return <Redirect to={`/${language}/space/${space_id}?booking_type=${params.booking_type || 'monthly'}`}/>;
                    }

                    if (params.booking_type !== 'hourly' && toMoment.format("YYYY-MM-DD") !== toMoment.clone().endOf('month').format("YYYY-MM-DD")) {
                        return <Redirect to={`/${language}/space/${space_id}?booking_type=${params.booking_type || 'monthly'}`}/>;
                    }

                    if (params.booking_type === 'hourly' && fromMoment.isBefore(moment())) {
                        return <Redirect to={`/${language}/space/${space_id}?booking_type=hourly`}/>;
                    }

                    return <Payment
                        space_id={space_id}
                        params={params}
                    />;
                }
                case 'landlordProfile': {

                    let ownerID = urlArray[3];

                    return <LandlordProfile
                        ownerID={ownerID}
                    />;
                }

                // case 'detailShare': {
                //     return <DetailShare/>;
                // }

                case 'update-payment-success': {
                    return <UpdatePaymentComplete
                        params={params}
                    />;
                }

                case 'booking-success': {
                    return <FindYourSpaceConfirm
                        params={params}
                    />;
                }

                case 'booking-pending': {
                    return <FindYourSpacePending
                        params={params}
                    />;
                }

                // messenger
                case 'inbox': {
                    return <Inbox/>;
                }
                case 'chat': {
                    let space_id = urlArray[3];
                    let user_id = urlArray[4];
                    if (!space_id || !user_id) {
                        return <Redirect to={"/" + language + "/home"}/>;
                    }
                    return <Chat
                        space_id={space_id}
                        user_id={user_id}
                    />;
                }
                case 'contact-owner': {
                    let space_id = urlArray[3];
                    if (!space_id) {
                        // TODO: add checking for valid space_id
                        return <Redirect to={"/" + language + "/home"}/>;
                    }
                    return <ContactOwner
                        space_id={space_id}
                    />;
                }

                // others
                case 'discover': {
                    return <Discover/>;
                }
                case 'benefits': {
                    return <Benefits/>;
                }
                case 'contact': {
                    return <Contact/>;
                }
                case 'helpCenter': {
                    return <HelpCenter/>;
                }
                case 'media': {
                    let media_id = urlArray[3];
                    if (!media_id) {
                        // TODO: add checking for valid space_id
                        return <Media/>;
                    }
                    return <MediaDetail
                        media_id={media_id}
                    />

                }
                // case 'mediaDetail': {

                // }

                // Results
                // case 'results': {
                //     return <Results/>
                // }
                // General
                case 'terms': {
                    return <Terms/>
                }
                case 'privacy': {
                    return <Privacy/>
                }
                case 'disclaimer': {
                    return <Disclaimer/>
                }
                case 'cancellation': {
                    return <Cancellation
                        t={this.props.t}
                        i18n={this.props.i18n}
                    />
                }
                case 'licenseAgreement': {
                    return <LicenseAgreement
                        t={this.props.t}
                        i18n={this.props.i18n}
                    />
                }
                case 'parkerProtectionGuarantee': {
                    return <ParkerProtectionGuarantee
                        t={this.props.t}
                        i18n={this.props.i18n}
                    />
                }
                case 'relist-space':
                case 'edit-space': {
                    let space_id = urlArray[3];
                    if (!space_id) {
                        // TODO: add checking for valid booking_id
                        return <Redirect to={"/" + language + "/home"}/>;
                    }
                    let newSpace = (component === 'relist-space');
                    return <EditSpace
                        space_id={space_id}
                        newSpace={newSpace}
                    />
                }
                case 'editSpaceConfirm': {
                    return <EditSpaceConfirm/>
                }

                case 'update-payment': {
                    let booking_id = urlArray[3];
                    if (!booking_id) {
                        // TODO: add checking for valid space_id
                        return <Redirect to={"/" + language + "/home"}/>;
                    }
                    if (!params)
                        params = {};
                    return <UpdatePayment
                        booking_id={booking_id}
                        params={params}
                    />
                }

                case 'review': {

                    let bookingID = urlArray[3];

                    return <Review
                        bookingID={bookingID}
                    />
                }

                case 'partners': {
                    return <Partners/>
                }

                default: {
                    return <PageNotFound/>;
                }
            }
        } else {

            return <Home/>
        }
    }

    isAppView(){
        const {
            route,
            // animations
        } = this.props;

        let search = route.location.search;
        let params = null;
        if (search !== "") params = querySearch(search);
        return params && params.isAppView;
    }

    render() {

        if (!this.state.ready)
            return null;

        const {
            route,
            // animations
        } = this.props;

        if (this.isAppView() || cookies.get('isAppView') === 'true') {
            return (
              <div>
                  <div className="blackPlane"></div>

                  {this.renderSwitch(route)}
              </div>
            );
        }

        return (
            <div>
                <MobileMenu/>

                <div id="wrap">
                    <Header/>

                    <div className="blackPlane"></div>

                    {this.renderSwitch(route)}

                    <Footer/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {
        route: state.router
    }
);

export default withTranslation()(connect(mapStateToProps)(App));
