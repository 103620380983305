import React from 'react';
import Card from "./Card";
import Message from "./Message";
import {find} from "lodash";

function Messages(props) {
    const {
        myId,
        messages,
        space,
        lastMsgRef,
        bookings,
        toUserName,
        toUser,
        user
    } = props;

    const isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    return (
        <div>
            {messages.map((message, index) => {
                // console.log(message.senderID, myId);
                const ref = index === (messages.length - 1) ? lastMsgRef : null;

                // console.log('message', isEmpty(message.data.booking_id));

                if (typeof (message.card) === "undefined" && isEmpty(message.data)) {
                    // normal message
                    // console.log('msg', message);
                    return (
                        <div ref={ref} key={index}>
                            <Message
                                message={message}
                                key={index}
                                myId={myId}
                                toUserName={toUserName}
                            />
                        </div>
                    )
                } else {
                    // card
                    // console.log('card', message);

                    const booking_id = message.data.booking_id;
                    const booking = find(bookings, (o) => {
                        return o._id === booking_id;
                    })

                    // console.log("msg booking", booking);

                    return (
                        <div ref={ref} key={index}>
                            {/*<div>{message.card}</div>*/}
                            <Card
                                message={message}
                                key={index}
                                myId={myId}
                                space={space}
                                booking={booking}
                                toUserName={toUserName}
                                toUser={toUser}
                                user={user}
                            />
                        </div>
                    )
                }
            })}
        </div>
    )
}

export default Messages;
