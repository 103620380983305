import React from 'react';
import { withTranslation } from 'react-i18next';

function GridRedeem({ t }) {
    return (
        <div className="GridRedeem">
            <div className="image"></div>
            <div className="text">
                <h3>Cafe coupon A</h3>

                <div className="creditPoints"><span>120</span> points</div>
            </div>
        </div>
    )
}
export default withTranslation()(GridRedeem);
