export const ENV = process.env.REACT_APP_ENV;
export const MAINTENANCE = process.env.REACT_APP_MAINTENANCE;

export const SMS_WAIT_SECONDS = 10;

// console.log(process.env);

// API SERVER
export const API_URL_BASE = process.env.REACT_APP_API_URL;
export const API_URL = API_URL_BASE + 'api/';

// bCODE API
export const BCODE_API_URL_BASE = process.env.REACT_APP_BCODE_URL;
export const BCODE_API_URL = BCODE_API_URL_BASE + 'api/';

// ENTERPRISE SERVER
export const ENTERPRISE_URL_BASE = process.env.REACT_APP_ENTERPRISE_URL;
export const ENTERPRISE_URL = ENTERPRISE_URL_BASE + 'api/';

// ROOT PATH
export const ROOT_PATH = process.env.REACT_APP_ROOT_PATH;

export const COMMISION_PERCENTAGE = 0.1;

// GOOGLE MAP KEY
export const UAT_GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;

// SOCKET SERVER INFO
export const SOCKET_SERVER = process.env.REACT_APP_SOCKET_URL;

//export const VEHICLE_TYPES = ['Privatecar', 'Van', 'Motorcycle', 'Lorry', 'Others'];

// SECRET
export const SECRET = process.env.REACT_APP_SECRET;

// WIL
export const WIL_REGISTER_URL = `${process.env.REACT_APP_WIL_DOMAIN}register?token={{token}}&timestamp={{timestamp}}&hash={{hash:worhashing256.\${token}|\${timestamp}:endhash}}&source=lsp_web`;
