import React, {Component} from 'react';

class Privacy_en_US extends Component {
    render() {
        return (
            <div className="privacy" dangerouslySetInnerHTML={{__html: this.props.content}}/>
            // <div className="privacy">
            //
            //     <p className="title"><strong>Information Regarding your Personal Information</strong></p>
            //
            //     <p><strong>Part 1: Introduction</strong></p>
            //
            //     <p>This document includes our Personal Information Collection Statement which sets out various
            //         information we are legally required to provide to you on or before we collect your information that
            //         can be used to identify you (your &ldquo;Personal Data&rdquo;). This is also our Privacy Policy
            //         Statement which sets out our policies and practises in relation to your Personal Data.</p>
            //
            //     <p>In this document, the &ldquo;Platform&rdquo; means collectively the website <a
            //         style={{"textDecoration":"underline"}}
            //         href="http://www.letspark.com.hk">www.letspark.com.hk</a>, and
            //         [the mobile app], &ldquo;we&rdquo;, &ldquo;our&rdquo;, or &ldquo;us&rdquo; mean Let&rsquo;s Park
            //         Company (Hong Kong) Limited which is a company incorporated in Hong Kong with limited
            //         liability. &ldquo;you&rdquo; or &ldquo;yours&rdquo; means the person who are accessing the Platform,
            //         registering as an user and/or using the services as provide by us through the Platform (&ldquo;our
            //         Services&rdquo;).&nbsp; Any capitalised terms not specifically defined in this document will have
            //         the same meaning as set out in the Terms and Conditions for the use of the Platform as published by
            //         us from time to time.&nbsp;</p>
            //
            //     <p><strong>You should read this document carefully before providing us with your Personal
            //         Data. </strong></p>
            //
            //     <p><strong>Part 2: Personal Information Collection Statement</strong></p>
            //     <p><strong>Collection of Personal Data</strong></p>
            //
            //     <p>If you visit the Platform but do not register as a User, no Personal Data will be collected from you.
            //         However, the IP address of the computer you used to visit our Platform may be recorded as
            //         a &lsquo;hit&rsquo; and we may collect the browser you use to view the Platform, your screen
            //         resolution and other technical details of your computer but no personal data will be collected. The
            //         record of the hits is used only for preparing general statistics on the usage of our Platform and
            //         the other technical details are to enhance your viewing experience.</p>
            //
            //     <p>You will be required to provide us with certain Personal Data if you wish to apply to register an
            //         Account on the Platform to become a User. After you have registered an Account, we will also ask you
            //         for additional Personal Data when you wish to use our Services, for example, when you wish to list a
            //         Parking Space or when you wish to book a Parking Space or any other Services which we may be
            //         providing. Whenever we ask you to provide us with your Personal Data, we will indicate the mandatory
            //         ones and if you do not wish to provide us with those Personal Data, we will not be able to provide
            //         you with an Account or the Services you wish to obtain.&nbsp; Your provision of other Personal Data
            //         is optional.</p>
            //
            //     <p><strong>Use of Personal Data</strong></p>
            //
            //     <p>Your Personal Data collected by us may be used for the following purposes:-</p>
            //
            //     <ol>
            //         <li>Registering and maintaining your Account</li>
            //         <li>Operating and managing the Platform</li>
            //         <li>Provision of our Services to you</li>
            //         <li>Communicating with you in respect of matters related to or arising from the provision of our
            //             Services or your Account
            //         </li>
            //         <li>Handling and following up your enquiries, suggestions, complaints</li>
            //         <li>Verifying the ownership of the Parking Space</li>
            //         <li>Identifying and verifying your identity</li>
            //         <li>Conducting service surveys to better understand your characteristics to improve and enhance our
            //             services to you and to design and develop new services for you
            //         </li>
            //         <li>Credit control and collection of debt from you</li>
            //         <li>Prevention or detection of crime</li>
            //         <li>Monitoring use of the Platform to help its further development</li>
            //     </ol>
            //
            //     <p>Furthermore, recordings of telephone conversations between you and our customer service hotlines or
            //         staff may be used for quality control, staff appraisal, and training.</p>
            //
            //     <p><strong>Transfer of Personal Data</strong></p>
            //
            //     <p>We do not share your Personal Data with or transfer your Personal Data to any party except as set out
            //         below:-</p>
            //
            //     <ol>
            //         <li>If you are a Parker or an Owner of a Booking, your name and contact details may be sent to the
            //             other party to the same Booking for the purpose of managing such Booking
            //         </li>
            //         <li>If you are a party to a Booking, your relevant Personal Data and banking details and/or credit
            //             card number may be provided to our agent or bankers for the purpose of effecting payment to or
            //             from you
            //         </li>
            //         <li>We may transfer your Personal data to third parties (e.g. printer, production house or providers
            //             of mailing services) engaged by us to process or handle your Personal Data on our behalf or to
            //             host and/or operate the Platform
            //         </li>
            //         <li>Any person to whom we are compelled to make disclosure under the requirements of any applicable
            //             law
            //         </li>
            //         <li>Any third party we may engage to manage the Bookings, e.g. towing company</li>
            //         <li>Any person with your express consent</li>
            //         <li>Debt collection agencies to collect any amount due from you</li>
            //         <li>Any actual or proposed assignee of our business of providing the services to which you have
            //             subscribed.
            //         </li>
            //     </ol>
            //
            //     <p><strong>Rights of Access and Correction to Data Collected</strong></p>
            //
            //     <p>You have a right to request access to, and to request correction of your Personal Data held by
            //         us.&nbsp; If you wish to exercise those rights, please contact our Customer Officer by email at
            //         [<a style={{"textDecoration":"underline"}} href="mailto:info@letspark.com.hk">info@letspark.com.hk</a>*] or by post to [Room 2901, 29/F, Metroplaza Tower 1, Hing Fong Road, Kwai
            //         Chung, N.T, Hong Kong *] .</p>
            //
            //     <p><strong>Direct Marketing</strong></p>
            //
            //     <p>We intend to use your name and contact details provided by you to market our Services or the
            //         availability of Parking Spaces to you but we will not use such Personal Data for this purpose
            //         without your indication of no objection or your consent.</p>
            //
            //     <p>If you are not a User but you have provided us with your contact details in the Platform to receive
            //         further details of our Services or details of Parking Spaces available, you have given us your
            //         consent for direct marketing.&nbsp;&nbsp; If you are a User but you do not wish use to use your
            //         Personal Data for the purpose of direct marketing of our Services to you, please elect to opt out by
            //         ticking the appropriate box during the registration process to opt out from our direct marketing.
            //         You can also opt out from our direct marketing at day time through the Platform or by contacting us
            //         by email at <a style={{"textDecoration":"underline"}} href="mailto:info@letspark.com.hk">info@letspark.com.hk</a>.</p>
            //
            //     <p>We will not provide your Personal Data to any third party for direct marketing purposes.</p>
            //
            //     <p><strong>Part 3: Privacy Policy Statement</strong></p>
            //
            //     <p><strong>Our Commitment</strong></p>
            //
            //     <p>We are committed to protecting your Personal Data by complying with the requirements of the Personal
            //         Data (Privacy) Ordinance with respect to the management of your Personal Data.&nbsp; We are equally
            //         committed to ensuring that all our employees and agents uphold these obligations.</p>
            //
            //     <p><strong>Use of Cookies</strong></p>
            //
            //     <p>We use cookies across our Platform in order to improve its performance and enhance your online
            //         experience. Session cookies are used to enable you to move around the Platform and use essential
            //         functions on our Platform, such as maintaining log-in details for the session such that the need to
            //         transfer information across the internet may be reduced. Google Analytics are used to recognize
            //         repeat visitors to our Platform in order to enable us to better understand how our users browse to
            //         and through our Platform, the time they spend and how often they visit our Platform, relying on
            //         which we can improve our Platform. We will not collect any personal data from you when the cookies
            //         are being used.</p>
            //
            //     <p><strong>Retention of your Personal Data</strong></p>
            //
            //     <p>Let&rsquo;s Park has taken all practicable steps to ensure that your Personal Data is not kept longer
            //         than is necessary for the fulfillment of the purposes (including any directly related purpose) for
            //         which your Personal Data is or is to be used.&nbsp; Your Personal Data will be deleted permanently
            //         from our system and any hard copies containing your Personal Data will be destroyed in a secure and
            //         appropriate manner six months after you closed your Account except details of your Bookings which
            //         forms part of our accounting records will be kept for the duration required by law.</p>
            //
            //     <p><strong>Security of your Personal Data</strong></p>
            //
            //     <p>Your Personal Data is stored in our system or an exclusive dedicated server of our service provider
            //         and is encrypted. We have also implemented appropriate technical and administrative measure to
            //         prevent unauthorised access to or accidental disclosure of your Personal Data.&nbsp; Only trained,
            //         authorised staff shall have access to your Personal data on a &ldquo;need-to-know&rdquo; basis.</p>
            //
            //     <p><strong>Outsourcing</strong></p>
            //
            //     <p>We may from time to time engage certain service provider to perform certain tasks in relation to the
            //         operation and/or administration of the Platform which may involve the handling of your Personal
            //         Data.&nbsp; If this arises, we shall ensure the service provider is aware of the nature of your
            //         Personal Data and will be contractually bound to keep your Personal Data secure and
            //         confidential.</p>
            //
            //     <p><strong>Link</strong></p>
            //
            //     <p>The Platform may provide links to another website or payment gateway.&nbsp; Please note that your
            //         visit to those websites will be subject to the personal privacy policy and personal information
            //         collection statement of these third party websites.&nbsp; They are not covered by our policies,
            //         practices and statements.&nbsp;</p>
            //
            //
            //     <p><strong>Further Enquiries</strong></p>
            //
            //     <p>If you have question or query in relation to the practises and policies as set out in this document
            //         or our personal data collection statement, please contact our Customer Officer by email at
            // {/*<a style={{"textDecoration":"underline"}} href="mailto:info@letspark.com.hk">info@letspark.com.hk</a> or by post to Room 2901, 29/F, Metroplaza Tower 1, Hing Fong Road, Kwai Chung,*/}
            // N.T, Hong Kong .</p>
            // {/*</div>*/}
        );
    }
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default Privacy_en_US;
