import React, {Component} from 'react';
import {Redirect} from 'react-router';
import {withTranslation} from 'react-i18next';

import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';

import ErrorMessage from '../../../components/103Parts/ErrorMessage';

import {connect} from 'react-redux';
import {forgetPasswordTokenValidate, resetPassword} from "../../../utils/04Api/User";
import {NavLink} from "react-router-dom";

class MemberResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formSubmitted: false,
            redirect: false,
            resetPasswordSuccess: false,
            errors: []
        }
    }

    componentWillMount() {
        // const {i18n}=this.props;

        console.log("Component Will Mount");

        if (this.props.params !== null) {
            console.log("Not Null Params");

            if (!this.props.params.hasOwnProperty('uid')) {
                console.log("No User ID");

                this.setState({
                    ...this.state,
                    redirect: true,
                })
            } else if (!this.props.params.hasOwnProperty('token')) {
                console.log("No Token");

                this.setState({
                    ...this.state,
                    redirect: true,
                })
            } else {
                let successCallback = response => {
                    console.log("Response Success");
                    console.log(response);

                    if (response.body.status === 0) {
                        this.setState({
                            ...this.state,
                            redirect: true,
                        })
                    }
                };

                let errorCallback = response => {
                    console.log("Response Error");
                    console.log(response);
                };

                forgetPasswordTokenValidate(this.props.params.uid, this.props.params.token, successCallback, errorCallback);
            }
        } else {
            console.log("No Param");

            this.setState({
                ...this.state,
                redirect: true,
            })
        }
    }

    handleSubmit = (values, {setFieldError}) => {
        if (!this.state.submitting) {
            this.setState({
                ...this.state,
                submitting: true,
            })
            console.log("submiting", values);

            let params = this.props.params;

            // TODO: Add api code here

            let successCallback = response => {
                console.log("Response Success");
                console.log(response);

                if (response.status === 400) {
                    setFieldError('password', response.body.msg);
                } else {
                    if (response.body.status === 1) {
                        this.setState({
                            ...this.state,
                            resetPasswordSuccess: true,
                        })
                    }
                }
            };

            let errorCallback = response => {
                console.log("Response Error");
                console.log(response);
            };

            resetPassword(params, values.password, successCallback, errorCallback);

        }
    }

    form = ({values, handleSubmit, errors, touched}) => {

        const {
            t,
        } = this.props;

        return (
            <Form>
                <dl className="generalForm clearfix">
                    {/*<div>
                        <dt><h4>Reset Password</h4></dt>
                        <dd>
                            <div className="wrap clearfix">
                                <div className="full">
                                    <div>
                                        <Field name="email" type="email" />
                                        {errors.email && touched.email ? <ErrorMessage message={errors.email} /> : null}
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </div>*/}
                    <div>
                        <dt><h4>{t("forgotPassword:password")}</h4></dt>
                        <dd>
                            <Field name="password" type="password"/>
                            {errors.password && touched.password ?
                                <ErrorMessage message={errors.password}/> : null}
                        </dd>
                    </div>

                    <div className="sep-25"/>

                    <div>
                        <dt><h4>{t("forgotPassword:confirmPassword")}</h4></dt>
                        <dd>
                            <Field name="confirmPassword" type="password"/>
                            {errors.confirmPassword && touched.confirmPassword ?
                                <ErrorMessage message={errors.confirmPassword}/> : null}
                        </dd>
                    </div>
                </dl>

                <div className="sep-25"/>
                <div className="sep-25"/>

                <div className="full left align-center">
                    <div>
                        <button type="submit" className="button">{t("forgotPassword:send")}</button>
                    </div>
                </div>
            </Form>
        )
    }

    schema = Yup.object().shape({
        password: Yup.string()
            .required("registerCorporate:errors.input.password")
            .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/, 'registerCorporate:errors.input.passwordFormat'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], "registerCorporate:errors.input.confirmPassword"),
    });

    render() {
        const {
            t,
            i18n
        } = this.props;

        if (this.state.resetPasswordSuccess === true) {
            return (
                <div className="wrapper-container memberResetPassword padding">

                    <div className="containerMain borderTop">
                        <div className="centerBox clearfix">

                            <div className="sep-20"/>
                            <div className="sep-20"/>
                            <div className="sep-20"/>
                            <div className="sep-20"/>

                            <h1>{t("forgotPassword:congratulations")}</h1>
                            <h4>{t("forgotPassword:changedPasswordText")}</h4>
                            <div className="sep-20"/>
                            <NavLink
                                className="button"
                                to={"/" + i18n.language}
                            >
                                {t("forgotPassword:backtoHome")}
                            </NavLink>

                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.redirect) {
            return <Redirect to={"/" + i18n.language}/>;
        }

        return (
            <div className="wrapper-container memberCreateAccount padding">

                {/* <div className="page-title"><h2>Contact Us</h2></div>

                    <div className="sep-0" /> */}

                <div className="containerMain borderTop">
                    <div className="content clearfix">
                        <div className="full left align-center">
                            <span className="door"/>
                            <h2>{t("forgotPassword:resetPassword")}</h2>
                        </div>

                        <div className="sep-20"/>

                        <Formik
                            validationSchema={this.schema}
                            onSubmit={this.handleSubmit}
                            component={this.form}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {
        members: state.members
    }
);

export default withTranslation()(connect(mapStateToProps)(MemberResetPassword));
