import React, {Component} from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';

import moment from 'moment';

import ViewChangeWithSlider from "../../../components/03FindParkingSpace/ViewChangeWithSlider";
// import { lowestPrice } from "../../utils/03FindParkingSpace/LowestHourlyPrice";

import Tags from '../../../components/103Parts/Tags';
import SpaceSuitableFor from '../../../components/103Parts/SpaceSuitableFor';
import Features from '../../../components/103Parts/Features';
import Restrictions from '../../../components/103Parts/Restrictions';
import AccessType from '../../../components/103Parts/AccessType';
import PrevNext from '../../../components/04ListASpace/PrevNext';

// import * as Config from '../../../config';

import {mongoIdTimestamp} from "../../../utils/00General/Helpers";
import DetailHourly from "../../03FindParkingSpace/DetailHourly";
import DetailMonthly from "../../03FindParkingSpace/DetailMonthly";
import DetailTimeshare from "../../03FindParkingSpace/DetailTimeshare";
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle} from "react-accessible-accordion";
import InstantBooking from "../../../components/103Parts/InstantBooking";

function BookingTypeBlock(props) {

    //console.log(props);
    let data = props.data;

    let available = '',
        unavailable = '',
        minimum = data.minbooking,
        deposit = data.deposit;

    let pricing = null, //20190802 samson - added pricing object
        always_available = null, //20190802 samson - added always_available object for hourly
        selected_daystime = null; //20190802 samson - added selected_daystime object for hourly, timeshare

    switch (props.bookingType) {
        case "hourly":
            available = data.from + ' - ' + data.to;
            unavailable = data.blocked.length > 0 ? data.blocked.from + ' - ' + data.blocked.to : 'N/A';
            if (typeof (minimum) === 'undefined')
                minimum = 'N/A';
            else
                minimum += props.t("space:tabBookingType.minOptions.hourly");

            always_available = data.always?
                <p>{props.t("space:tabBookingType.preview.always_available", {"boolean": props.t("listASpace:stepTwo.availability.alwaysAvailable.yes")})}</p>
                :
                <p>{props.t("space:tabBookingType.preview.always_available", {"boolean": props.t("listASpace:stepTwo.availability.alwaysAvailable.no")})}</p>;

            if (!data.always) {
                selected_daystime =
                    <div>
                        <p>{props.t("space:tabBookingType.preview.selected_daystime")}</p>
                        <table>
                            <tbody>
                            {
                                data.available.map((itm, idx) => {
                                    return <tr key={idx}>
                                        <td style={{"float": "left"}}>{itm.period}:&nbsp;</td>
                                        <td style={{"float": "left"}}>{itm.stime} - {itm.etime}</td>
                                    </tr>;
                                })
                            }
                            </tbody>
                        </table>
                        <div className="sep-15"/>
                    </div>;

               if(data.type.includes("Custom")) {
                      pricing = <p>{props.t("space:tabBookingType.preview.rent_per_hour", {"rent": "Custom", "price": (typeof(data.available[0]) !== "undefined"? data.available[0].price:-1)})}</p>;
               } else {
                   pricing =
                       <div>
                           <table>
                               <tbody>
                               {
                                   data.available.map((itm, idx) => {
                                       return <tr key={idx}>
                                           <td><p>{props.t("space:tabBookingType.preview.rent_per_hour", {
                                               "rent": itm.period,
                                               "price": itm.price
                                           })}</p></td>
                                       </tr>;
                                   })
                               }
                               </tbody>
                           </table>
                       </div>;
               }
            }
            break;
        case "monthly":
            available = data.from + ' - ' + data.to;
            unavailable = data.blocked.length > 0 ? data.blocked.from + ' - ' + data.blocked.to : 'N/A';
            if (typeof (minimum) === 'undefined')
                minimum = 'N/A';
            else
                minimum += props.t("space:tabBookingType.minOptions.monthly");

            pricing = <p>{props.t("space:tabBookingType.preview.rent_per_month", {"price": data.price})}</p>;
            break;
        case "timeshare":
            available = data.from + ' - ' + data.to;
            unavailable = data.blocked.length > 0 ? data.blocked.from + ' - ' + data.blocked.to : 'N/A';
            if (typeof (minimum) === 'undefined')
                minimum = 'N/A';
            else
                minimum += props.t("space:tabBookingType.minOptions.monthly");

            selected_daystime =
                <div>
                    <p>{props.t("space:tabBookingType.preview.selected_daystime")}</p>
                    <table>
                        <tbody>
                        {
                            data.available.map((itm, idx) => {
                                return <tr key={idx}>
                                    <td style={{"float": "left"}}>{itm.period}:&nbsp;</td>
                                    <td style={{"float": "left"}}>{itm.stime} - {itm.etime}</td>
                                </tr>;
                            })
                        }
                        </tbody>
                    </table>
                    <div className="sep-15"/>
                </div>;

            pricing = <p>{props.t("space:tabBookingType.preview.rent_per_month", {"price": data.price})}</p>;
            break;
        default:
            break;
    }

    return (
        <AccordionItem
            expanded={props.expanded}>
            <AccordionItemTitle>
                <h3>{props.t("space:tabBookingType.options." + props.bookingType)}</h3>
            </AccordionItemTitle>
            <AccordionItemBody>
                <h5><b>{props.t("space:tabBookingType.preview.availability")}</b></h5>
                <p>{props.t("space:tabBookingType.preview.available", {"period": available})}</p>
                {selected_daystime}
                {data.blocked.length > 0 &&
                <table>
                    <tbody>
                    <tr>
                        <td style={{"float": "left"}}>
                            {props.t("space:tabBookingType.preview.unavailable")}&nbsp;
                        </td>
                        <td style={{"float": "left"}}>
                            {Object.keys(data.blocked).map(key => {

                                return (
                                    <div key={key}>
                                        {data.blocked[key].from + ' - ' + data.blocked[key].to}
                                    </div>
                                )
                            })}
                        </td>
                    </tr>
                    </tbody>
                </table>
                }
                {data.blocked.length <= 0 &&
                <p>{props.t("space:tabBookingType.preview.unavailable", {"period": unavailable})}</p>
                }
                {always_available}
                <div className="sep-20"/>
                <h5><b>{props.t("space:tabBookingType.preview.pricing")}</b></h5>
                {pricing}
                <p>{props.t("space:tabBookingType.preview.minimum", {"period": minimum})}</p>
                {data.accesstype !== "No" && //20190722 samson
                <p>{props.t("space:tabBookingType.preview.deposit", {"deposit": deposit})}</p>
                }
            </AccordionItemBody>
        </AccordionItem>
    )
}

class Preview extends Component {
    constructor(props) {
        super(props);

        this.state = ({
            view: 'photo',
            formSubmitted: false,
            wishlist: {
                1: true
            },
            data: {
                accesstype: [],
                address: "",
                booked: [],
                cartype: [],
                description: [],
                features: [],
                floor: "",
                height: "",
                hourly: {
                    always: true,
                    available: [],
                    avatype: [],
                    blocked: [],
                    days: [],
                    deposit: 0,
                    from: "",
                    minbooking: 1,
                    to: ""
                },
                images: [],
                instant: true,
                lat: "",
                lng: "",
                monthly: {
                    blocked: [],
                    from: "",
                    minbooking: 1,
                    price: 0,
                    to: "",
                },
                owner: [],
                restrictions: [],
                spaceno: "",
                timeshare: {
                    available: [],
                    avatype: [],
                    blocked: [],
                    from: "",
                    minbooking: 1,
                    price: 0,
                    to: "",
                },
                title: "",
            },
            BookingType: '',
            initSupportTypes : '',
        })
    }

    componentDidMount = () => {
        this.GetPreviousData();
    }

    // General
    handleChangeView = (e, data1, data2) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            [data1]: data2
        })
    }

    handleWishlist = (id) => {
        this.setState({
            wishlist: {
                ...this.state.wishlist,
                [id]: !this.state.wishlist[id]
            }
        });
    }

    GetPreviousData = () => {
        var stepOne = this.props.data[1],
            stepTwo = this.props.data[2],
            stepThree = this.props.data[3];

        var Body = {
            "title": stepOne.addressTitle,
            "title_tc": stepOne.addressTcTitle,
            "address": stepOne.address,
            "floor": stepOne.floor,
            "spaceno": stepOne.spaceNo,
            "lng": stepOne.lng,
            "lat": stepOne.lat,
            "cartype": [],
            "features": [stepOne.Feature],
            "nearblock": stepOne.DetailedDescription.NearBlockText,
            "height": "",
            "restrictions": stepOne.Restrictions,
            "description": [],
            "accesstype": [stepOne.AccessType],
            "instant": stepTwo.InstantBooking !== "NotAccept",
            "images": [],
            "owners": [stepThree.ownerName],
            "monthly": {},
            "timeshare": {},
            "hourly": {},
            "reserved": stepOne.reserved,
            "isCorp": stepOne.isCorp,
            "status": this.props.space.status
        };

        // console.log("stepTwo", stepTwo);
        // console.log("Body", Body);



        let BookingType = '';
        let initSupportTypes = '';

        if (stepThree.ownerName2 !== "")
            Body.owners.push(stepThree.ownerName2);

        // console.log(stepThree);
        if (typeof (stepThree.file) !== "undefined") {
            // console.log(stepThree.file);

            for (let i = 1; i <= 3; i++) {
                if (typeof (stepThree.file[i]) !== "undefined") {
                    // console.log(stepThree.file[i]);
                    if (typeof(stepThree.file[i].img) !== "undefined" && stepThree.file[i].img)
                        Body.images.push(stepThree.file[i].img);
                    else
                        Body.images.push(stepThree.file[i].src);
                }
            }
        }

        var carTypes = ["PrivateCar", "Van", "Motorcycle", "Lorry", "Others"];
        for (let i = 0; i < carTypes.length; i++) {
            let carType = carTypes[i];
            if (stepOne.SpaceSuitable[carType])
                Body.cartype.push(carType);
        }

        if (stepOne.FeatureEV.yes)
            Body.features.push("EV");

        if (stepOne.FeatureHeadRoom.yes) {
            Body.features.push("HeadRoom");
            Body.height = stepOne.FeatureHeadRoom.Height;
        }

        var descriptions = ["NearEntrance", "WideLoad", "NearLift", "NearStairs", "NearBlock", "NearBlockText", "CorneredSpace"];
        for (let i = 0; i < descriptions.length; i++) {
            let description = descriptions[i];
            if (stepOne.DetailedDescription[description])
                Body.description.push(description);
        }

        // Hourly Data
        if (stepTwo.BookingType.Hourly) {
            // support hourly
            if (BookingType === '') {
                BookingType = 'hourly';
                initSupportTypes = 'hourly';
            }

            let tab = stepTwo.HourlyTab,
                availability = tab.Availability;
            let booking_type_data = {
                "accesstype": stepOne.AccessType,//20190722  samson
                "from": moment(availability.SelectedDateRange.DateStart).utcOffset(8).format('YYYY-MM-DD'),
                "to": moment(availability.SelectedDateRange.DateEnd).utcOffset(8).format('YYYY-MM-DD'),
                "minbooking": tab.Pricing.minBooking,
                "type": [],
                "always": availability.AlwaysAvailable !== "No",
                "deposit": 0,
                "days": [],
                "available": [],
                "blocked": [],
                "avatype": [],
            };

            if (stepOne.AccessType !== "No") {
                booking_type_data.deposit = tab.Pricing.deposit;
            }


            for (let i = 0; i < availability.BlockedDateRange.length; i++) {
                let range = availability.BlockedDateRange[i];
                if (range.DateStart !== "" && range.DateEnd !== "") {
                    booking_type_data.blocked.push({
                        "from": moment(range.DateStart).utcOffset(8).format('YYYY-MM-DD'),
                        "to": moment(range.DateEnd).utcOffset(8).format('YYYY-MM-DD'),
                    });
                }
            }

            if (availability.AlwaysAvailable !== "No") {
                // always available
                let price = availability.Price[0],
                    days = [1, 2, 3, 4, 5, 6, 7, 8];

                booking_type_data.available.push({
                    "period": "MonSunPH",
                    "stime": "00:00",
                    "etime": "24:00",
                    "price": price[0],
                });

                for (let i = 0; i < days.length; i++) {
                    let day = days[i];
                    booking_type_data.days.push({
                        "day": day,
                        "stime": "00:00",
                        "etime": "24:00",
                        "price": price[0],
                    });
                }
            } else {
                for (let i = 0; i < availability.AvailableDayTime.length; i++) {
                    let availableTime = availability.AvailableDayTime[i];
                    booking_type_data.type.push(availableTime.SelectedDayOfWeek);
                    booking_type_data.avatype.push(availableTime.SelectedDayOfWeek);
                    let price = availability.Price[i];

                    switch (availableTime.SelectedDayOfWeek) {
                        case "Custom":
                            let days = availableTime.CustomDates;
                            for (let j = 0; j < days.length; j++) {
                                let day = days[j];
                                booking_type_data.available.push({
                                    "period": day,
                                    "stime": availableTime.SelectedTimeRange.TimeStart,
                                    "etime": availableTime.SelectedTimeRange.TimeEnd,
                                    "price": price[0],
                                    "maxbooking": availableTime.SelectedTimeRange.MaxHour || undefined,
                                });

                                let theday;
                                switch (day) {
                                    case "Mon":
                                        theday = 1;
                                        break;
                                    case "Tue":
                                        theday = 2;
                                        break;
                                    case "Wed":
                                        theday = 3;
                                        break;
                                    case "Thu":
                                        theday = 4;
                                        break;
                                    case "Fri":
                                        theday = 5;
                                        break;
                                    case "Sat":
                                        theday = 6;
                                        break;
                                    case "Sun":
                                        theday = 7;
                                        break;
                                    default:
                                        theday = 8;
                                        break;
                                }

                                booking_type_data.days.push({
                                    "day": theday,
                                    "stime": availableTime.SelectedTimeRange.TimeStart,
                                    "etime": availableTime.SelectedTimeRange.TimeEnd,
                                    "price": price[0],
                                    "maxbooking": availableTime.SelectedTimeRange.MaxHour || undefined,
                                });
                            }
                            break;
                        default:
                            let availableData = {
                                "period": availableTime.SelectedDayOfWeek,
                                "stime": availableTime.SelectedTimeRange.TimeStart,
                                "etime": availableTime.SelectedTimeRange.TimeEnd,
                                "price": price[0],
                                "maxbooking": availableTime.SelectedTimeRange.MaxHour || undefined,
                            };
                            booking_type_data.available.push(availableData);

                            if (availableTime.SelectedTimeRange2) {
                                // corp and has 2nd time range
                                booking_type_data.available.push({
                                    "period": availableTime.SelectedDayOfWeek,
                                    "stime": availableTime.SelectedTimeRange2.TimeStart,
                                    "etime": availableTime.SelectedTimeRange2.TimeEnd,
                                    "price": price[1],
                                    "maxbooking": availableTime.SelectedTimeRange2.MaxHour || undefined,
                                });
                            }

                            let availDays;
                            switch (availableTime.SelectedDayOfWeek) {
                                case "SatSunPH":
                                    availDays = [6, 7, 8];
                                    break;
                                case "MonFri":
                                    availDays = [1, 2, 3, 4, 5];
                                    break;
                                default:
                                    availDays = [1, 2, 3, 4, 5, 6, 7, 8];
                                    break;
                            }

                            for (let i = 0; i < availDays.length; i++) {
                                let day = availDays[i];
                                booking_type_data.days.push({
                                    "day": day,
                                    "stime": availableTime.SelectedTimeRange.TimeStart,
                                    "etime": availableTime.SelectedTimeRange.TimeEnd,
                                    "price": price[0],
                                    "maxbooking": availableTime.SelectedTimeRange.MaxHour || undefined,
                                });

                                if (availableTime.SelectedTimeRange2) {
                                    // corp and has 2nd time range
                                    booking_type_data.days.push({
                                        "day": day,
                                        "stime": availableTime.SelectedTimeRange2.TimeStart,
                                        "etime": availableTime.SelectedTimeRange2.TimeEnd,
                                        "price": price[1],
                                        "maxbooking": availableTime.SelectedTimeRange2.MaxHour || undefined,
                                    });
                                }
                            }

                            // booking_type_data.available.push({
                            //     "period": availableTime.SelectedDayOfWeek,
                            //     "stime": availableTime.SelectedTimeRange.TimeStart,
                            //     "etime": availableTime.SelectedTimeRange.TimeEnd,
                            //     "price": price[0],
                            // });
                            //
                            // let availDays;
                            // switch (availableTime.SelectedDayOfWeek) {
                            //     case "SatSunPH":
                            //         availDays = [6, 7, 8];
                            //         break;
                            //     case "MonFri":
                            //         availDays = [1, 2, 3, 4, 5];
                            //         break;
                            //     default:
                            //         availDays = [1, 2, 3, 4, 5, 6, 7, 8];
                            //         break;
                            // }
                            // for (let i = 0; i < availDays.length; i++) {
                            //     let day = availDays[i];
                            //     booking_type_data.days.push({
                            //         "day": day,
                            //         "stime": availableTime.SelectedTimeRange.TimeStart,
                            //         "etime": availableTime.SelectedTimeRange.TimeEnd,
                            //         "price": price,
                            //     });
                            // }
                    }
                }
            }

            for (let i = 0; i < tab.CalendarData.CustomDates.length; i++) {
                let d = tab.CalendarData.CustomDates[i];
                if (!d.Available) {
                    const theDate = moment(d.Date).utcOffset(8).format('YYYY-MM-DD');
                    booking_type_data.blocked.push({
                        "from": theDate,
                        "to": theDate,
                    });
                }
            }

            Body.hourly = booking_type_data;
        }

        // Monthly Data
        if (stepTwo.BookingType.Monthly) {
            if (BookingType === '') {
                BookingType = 'monthly';
                initSupportTypes = 'monthly';
            }

            // supports Monthly
            let tab = stepTwo.MonthlyTab,
                availability = tab.Availability;
            let booking_type_data = {
                // "accesstype": stepOne.AccessType,
                "mfrom": moment(availability.SelectedDateRange.DateStart).utcOffset(8).format('YYYY-MM-DD'),
                "mto": moment(availability.SelectedDateRange.DateEnd).utcOffset(8).format('YYYY-MM-DD'),
                "min": tab.Pricing.minBooking,
                "mprice": tab.Pricing.price,

                "accesstype": stepOne.AccessType,//20190722  samson
                "from": moment(availability.SelectedDateRange.DateStart).utcOffset(8).format('YYYY-MM-DD'),
                "to": moment(availability.SelectedDateRange.DateEnd).utcOffset(8).format('YYYY-MM-DD'),
                "minbooking": tab.Pricing.minBooking,
                "blocked": [],
                "price": tab.Pricing.price,
                "deposit": tab.Pricing.deposit,
            };

            for (let i = 0; i < availability.BlockedDateRange.length; i++) {
                let range = availability.BlockedDateRange[i];
                if (range.DateStart !== "" && range.DateEnd !== "") {
                    booking_type_data.blocked.push({
                        "from": moment(range.DateStart).utcOffset(8).format('YYYY-MM-DD'),
                        "to": moment(range.DateEnd).utcOffset(8).format('YYYY-MM-DD'),
                    });
                }
            }
            Body.monthly = booking_type_data;
        }

        // Timeshare Data
        if (stepTwo.BookingType.Timeshare) {
            // support Timeshare
            if (BookingType === '') {
                BookingType = 'timeshare';
                initSupportTypes = 'timeshare';
            }

            let tab = stepTwo.TimeshareTab,
                availability = tab.Availability;
            let booking_type_data = {
                "accesstype": stepOne.AccessType,//20190722  samson
                "from": moment(availability.SelectedDateRange.DateStart).utcOffset(8).format('YYYY-MM-DD'),
                "to": moment(availability.SelectedDateRange.DateEnd).utcOffset(8).format('YYYY-MM-DD'),
                "minbooking": tab.Pricing.minBooking,
                "type": [],
                "available": [],
                "blocked": [],
                "price": tab.Pricing.price,
                "deposit": tab.Pricing.deposit,
                "avatype": [],
            };

            for (let i = 0; i < availability.BlockedDateRange.length; i++) {
                let range = availability.BlockedDateRange[i];
                if (range.DateStart !== "" && range.DateEnd !== "") {
                    booking_type_data.blocked.push({
                        "from": moment(range.DateStart).utcOffset(8).format('YYYY-MM-DD'),
                        "to": moment(range.DateEnd).utcOffset(8).format('YYYY-MM-DD'),
                    });
                }
            }

            for (let i = 0; i < availability.AvailableDayTime.length; i++) {
                let availableTime = availability.AvailableDayTime[i];
                booking_type_data.type.push(availableTime.SelectedDayOfWeek);
                booking_type_data.avatype.push(availableTime.SelectedDayOfWeek);
                switch (availableTime.SelectedDayOfWeek) {
                    case "Custom":
                        let days = availableTime.CustomDates;
                        for (let j = 0; j < days.length; j++) {
                            let day = days[j];
                            booking_type_data.available.push({
                                "period": day,
                                "stime": availableTime.SelectedTimeRange.TimeStart,
                                "etime": availableTime.SelectedTimeRange.TimeEnd,
                            });
                        }
                        break;
                    default:
                        booking_type_data.available.push({
                            "period": availableTime.SelectedDayOfWeek,
                            "stime": availableTime.SelectedTimeRange.TimeStart,
                            "etime": availableTime.SelectedTimeRange.TimeEnd,
                        });
                }
            }

            Body.timeshare = booking_type_data;
        }
        //
        // console.log("This is Body");
        // console.log(Body);

        this.props.setBody(Body, ()=> {
            this.setState({
                ...this.state,
                data: Body,
                BookingType: BookingType,
                initSupportTypes: initSupportTypes,
            });
        });
    }

    submitForm = (e) => {
        e.preventDefault();

        this.props.ApiUpdateSpace();
    };

    // ApiListYourSpace = () => {
    //     let access_token = this.props.members.Login.userToken;
    //
    //     fetch(Config.API_URL + 'space?access_token=' + access_token, {
    //         method: 'post',
    //         headers: new Headers({
    //             //'Authorization': 'Basic ' + btoa(this.props.members.Login.userToken + ':' + Config.UAT_FEED_PASSWORD),
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         }),
    //         body: JSON.stringify(this.state.data)
    //     })
    //     // .then(r => r.json().then(data => ({ status: r.status, body: data })
    //     // ))
    //         .then(obj => {
    //             if (obj.status === 200) {
    //                 // this.goToConfirm(this.state.data);
    //             } else if (obj.status === 400) {
    //                 this.setState({
    //                     errors: obj.body.messages
    //                 });
    //             }
    //         }).catch(error => {
    //         // console.log("error");
    //         // console.log(error)
    //     });
    // }

    isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    dummyFunction = () => {
        return false;
    }

    render() {
        const {
            t,
            i18n,
        } = this.props;

        const booking_types = {
            'hourly': 'Hourly',
            'monthly': 'Monthly',
            'timeshare': 'Timeshare',
        }

        let supportTypes = [];

        if (!this.isEmpty(this.state.data.hourly))
            supportTypes.push('hourly');

        if (!this.isEmpty(this.state.data.monthly))
            supportTypes.push('monthly');

        if (!this.isEmpty(this.state.data.timeshare))
            supportTypes.push('timeshare');

        let ownername = '';
        if(typeof(this.state.data.owners) !== "undefined") {
            this.state.data.owners.forEach((itm,idx)=> {
                if(typeof(itm) !== "undefined")
                    ownername += itm + ", ";
            });
            ownername = ownername.slice(0, -2);
        }


        return (
            <div className="wrapper-container detailShare" ref={this.props.pageTop}>
                <div className="containerMain">
                    <div className="content clearfix">
                        <div className="previewBg">
                            <div className="information left">
                                <div className="text">
                                    <h3>{this.state.data.title}</h3>
                                    <h4>{this.state.data.address}</h4>

                                    <div className="sep-15"/>

                                    <Tags
                                        tags={this.state.data.description}
                                        nearBlockText={this.state.data.nearblock}
                                    />

                                    <div className="sep-20"/>
                                </div>
                                <ViewChangeWithSlider
                                    images={this.state.data.images}
                                    view={this.state.view}
                                    handleChangeView={this.handleChangeView}
                                    wishlist={this.state.wishlist}
                                    handleWishlist={this.handleWishlist}
                                    lng={this.state.data.lng}
                                    lat={this.state.data.lat}
                                />
                                <div className="sep-40"/>

                                <div className="landlord clearfix">

                                    <div className="fifty clearfix">

                                        <div className="image">
                                            <img
                                                src={ this.props.members.Login.user.image ?  this.props.members.Login.user.image : require('../../../images/blank.png')}
                                                alt=""/>
                                        </div>
                                        <div className="text">
                                            <h3>{this.props.members.Login.user.name}</h3>
                                            <div className="memberSince">
                                                {t("space:memberSince")}<span>{mongoIdTimestamp(this.props.members.Login.user._id, "YYYY-MM-DD")}</span>{t("space:memberAt")}
                                            </div>
                                            <div className="sep-10"/>
                                            <a href="/" className="button"
                                               onClick={(e) => e.preventDefault()}>{t("space:contactOwner")}</a>
                                        </div>
                                    </div>

                                    <div className="fifty clearfix"></div>
                                </div>
                            </div>

                            {this.state.BookingType === 'hourly' &&
                            <DetailHourly
                                hourlyData={this.state.data.hourly}
                                BookingType="hourly"
                                handleBookingType={this.dummyFunction}
                                supportTypes={supportTypes}

                                startDate={""}
                                endDate={""}
                                startTime={""}
                                endTime={""}
                                updateState={this.dummyFunction}

                                calculation={{success: null}}
                                goToPayment={this.dummyFunction}

                                booking_types={booking_types}
                                booked={this.state.data.booked}

                                holiday={this.state.holiday}
                                isPublicHoliday={this.dummyFunction}

                                space={this.state.data}
                            />
                            }

                            {this.state.BookingType === 'monthly' &&
                            <DetailMonthly
                                monthlyData={this.state.data.monthly}
                                BookingType="monthly"
                                handleBookingType={this.dummyFunction}
                                supportTypes={["monthly"]}

                                typeDetail={this.state.data.monthly}
                                startDate={""}
                                endDate={""}
                                updateState={this.dummyFunction}

                                reserved={false}
                                handleReserved={this.dummyFunction}

                                calculation={{success: null}}
                                goToPayment={this.dummyFunction}

                                booking_types={booking_types}
                                booked={this.state.data.booked}

                                holiday={this.state.holiday}
                                isPublicHoliday={this.dummyFunction}
                                space={this.state.data}
                            />
                            }

                            {this.state.BookingType === 'timeshare' &&
                            <DetailTimeshare
                                timeshareData={this.state.data.timeshare}
                                BookingType="timeshare"
                                handleBookingType={this.dummyFunction}
                                supportTypes={["timeshare"]}

                                typeDetail={this.state.data.timeshare}
                                startDate={""}
                                endDate={""}
                                updateState={this.dummyFunction}

                                reserved={false}
                                handleReserved={this.dummyFunction}

                                calculation={{success: null}}
                                goToPayment={this.dummyFunction}

                                booking_types={booking_types}
                                booked={this.state.data.booked}

                                holiday={this.state.holiday}
                                isPublicHoliday={this.dummyFunction}
                                space={this.state.data}
                            />
                            }

                            <div className="sep-30"/>

                            <div className={"previewOverlay " + i18n.language}/>

                            <div className="wrapper-tabs">
                                <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                                    <Tab eventKey={1} title={t("space:tabDetails.title")}>
                                        <div className="inner">
                                            <h5>{t("space:tabDetails.suitable.title")}</h5>

                                            <SpaceSuitableFor
                                                cartype={this.state.data.cartype}
                                                tones={this.state.data.tones}
                                            />

                                            <div className="sep-25"/>

                                            <h5>{t("space:tabDetails.feature.title")}</h5>

                                            <Features
                                                features={this.state.data.features}
                                                height={this.state.data.height}
                                            />

                                            <div className="sep-25"/>

                                            <h5>{t("space:tabDetails.restrictions.title")}</h5>
                                            <Restrictions
                                                restrictions={this.state.data.restrictions}
                                            />

                                            <div className="sep-25"/>

                                            <h5>{t("space:tabDetails.accessType.title")}</h5>
                                            <AccessType
                                                accesstype={this.state.data.accesstype[0]}
                                            />

                                            <div className="sep-25"/>
                                            <h5>{t("space:tabDetails.instantbooking.title")}</h5>
                                            <InstantBooking
                                                instant={this.state.data.instant}
                                            />

                                            <div className="sep-25"/>
                                            <h5>{t("space:tabDetails.floor.title")}</h5>
                                            <p>{this.state.data.floor}</p>

                                            <div className="sep-25"/>
                                            <h5>{t("space:tabDetails.space.title")}</h5>
                                            <p>{this.state.data.spaceno}</p>

                                            <div className="sep-25"/>
                                            <h5>{t("space:tabDetails.ownerName.title")}</h5>
                                            <p>{ownername} </p>

                                        </div>
                                    </Tab>
                                    <Tab eventKey={2} title={t("space:tabReviews.title")}>
                                        <div className="inner">
                                            {t("space:tabReviews.noReviews")}
                                        </div>
                                    </Tab>
                                    <Tab eventKey={3} title={t("space:tabBookingType.title")}>
                                        <div className="inner">
                                            <Accordion>
                                                {Object.keys(supportTypes).map(key => {
                                                    let bookingType = supportTypes[key];

                                                    let expanded = this.state.initSupportTypes === bookingType;
                                                    // console.log(typeof(bookingType), bookingType);

                                                    return (
                                                        <BookingTypeBlock
                                                            key={key}
                                                            bookingType={bookingType}
                                                            data={this.state.data[bookingType]}
                                                            t={t}
                                                            expanded={expanded}
                                                        />
                                                    )
                                                })}
                                            </Accordion>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>

                            <div className="sep-25"/>
                        </div>

                        <PrevNext
                            prevHas={true}
                            prevURL={"#"}
                            prevText={t("listASpace:back")}
                            nextURL={"#"}
                            nextText={t("listASpace:update")}
                            progress="three"
                            value="3/3"
                            prevHandler={() => this.props.goToStep(3)}
                            nextHandler={(e) => this.submitForm(e)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Preview);
