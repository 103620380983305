import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';

import moment from 'moment';

// import * as Config from '../../config';
import FindYourSpaceListView from './FindYourSpaceListView';
import FindYourSpaceMapView from './FindYourSpaceMapView';

import SortbyFilterDesktop from '../../components/03FindParkingSpace/SortbyFilterDesktop';
import SortbyFilterMobile from '../../components/03FindParkingSpace/SortbyFilterMobile';

// import * as HelperFilter from '../../utils/03FindParkingSpace/Filter';
// import data from '../../data/FindYourSpaceData1';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as FindParkingSpaceActionCreators from '../../actions/findParkingSpace';
import {findYourSpace} from '../../utils/04Api/Space';
import ReactPixel from 'react-facebook-pixel';
import {getBCodeEnabledListings} from "../../utils/04Api/Bcode";

class FindYourSpace extends Component {
    constructor(props) {
        super(props);

        const {dispatch} = props;

        this.saveFilter = bindActionCreators(FindParkingSpaceActionCreators.saveFilter, dispatch);

        this.initState = {
            search: {
                from: '',
                to: '',
                address: '',
                lat: '',
                lng: '',
            },
            sortby: {
                price: false,
                rating: false,
                distance: true,
                review: false
            },
            filter: {
                cartype: "PrivateCar",
                features1: [],
                features2: [],
                height: "",
                bookingType: "hourly",
                instant: false,
                distance: 30,
                price: {
                    min: 0,
                    max: 200,
                },
                initPrice: {
                    min: 0,
                    max: 200,
                },
                defaultPrice: {
                    hourly: {
                        min: 0,
                        max: 200,
                    },
                    monthly: {
                        min: 0,
                        max: 20000,
                    },
                    timeshare: {
                        min: 0,
                        max: 20000,
                    },
                },
                pricingMaxValue: 200,
                bcodeOnly: false
            },
            carTypes: "Indoor",
            score: 3,
            verified: {
                0: true
            },
            wishlist: {
                0: true
            },
            errors: {
                address: false,
            }
        };

        let state = {
            ...this.initState,
            showView: 'list',
            desktopFilter: {
                showDesktopFilter: false,
                showSearch: true,
                showSortby: false,
                showFilter: false,
            },
            mobileFilter: {
                showSortby: false,
                showFilter: false,
            },
            results: 0,
            spaces: [],
            mapViewShowFilter: true,
            selectedSpace: null,
            mapZoom: 14,
            mapCenterLat: null,
            mapCenterLng: null,
            loading: true
        }

        // handle params
        if (typeof (props.search_params) !== "undefined" && props.search_params) {
            const keys = Object.keys(props.search_params);
            for (const k of keys) {
                let v = props.search_params[k];
                switch (k) {
                    case "booking_type":
                        if (['hourly', 'monthly', 'timeshare'].indexOf(v) > -1) {
                            state.filter.bookingType = v;

                            if (v !== 'hourly') {
                                state.filter.price.min = this.initState.filter.defaultPrice[v].min;
                                state.filter.price.max = this.initState.filter.defaultPrice[v].max;

                                state.filter.initPrice.min = this.initState.filter.defaultPrice[v].min;
                                state.filter.initPrice.max = this.initState.filter.defaultPrice[v].max;
                            }
                        }
                        break;
                    case "address":
                        state.search.address = decodeURIComponent(v);
                        break;
                    case "lat":
                        state.search.lat = v;
                        break;
                    case "lng":
                        state.search.lng = v;
                        break;
                    case "from":
                    case "to":
                        state.search[k] = moment(v);
                        break;
                    case "view":
                        if (v === 'map')
                            state.showView = 'map';
                        break;
                    default:
                }
            }
        }

        this.state = state;

        this.handleDesktopFilter = this.handleDesktopFilter.bind(this);
        this.handleMobileFilter = this.handleMobileFilter.bind(this);

        this.handleSearchInput = this.handleSearchInput.bind(this);
        this.handleSortbyOptions = this.handleSortbyOptions.bind(this);
        this.handleFilterOptions = this.handleFilterOptions.bind(this);
        this.handleFilterSelect = this.handleFilterSelect.bind(this);
        this.handleFilterRange = this.handleFilterRange.bind(this);
        this.handleFilterBoolean = this.handleFilterBoolean.bind(this);
        // this.handleFilterFeatures = this.handleFilterFeatures.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

        this.handleApplyFilter = this.handleApplyFilter.bind(this);
        this.ApiSearch = this.ApiSearch.bind(this);

        this.handleReset = this.handleReset.bind(this);
        this.selectSpace = this.selectSpace.bind(this);
    }

    componentDidMount = () => {
        // if (this.props.findParkingSpace.Filter.length !== 0) {
        // 	this.setState(this.props.findParkingSpace.Filter)
        // }
        //
        // console.log(this.props.params);

        ReactPixel.trackCustom('ViewContent', {booking_type: this.props.search_params.booking_type, content_type: 'product', content_id: 'findYourSpace'});

        this.ApiSearch();
    }

    closeFilters = (cb) => {
        if (typeof (cb) !== "function")
            cb = () => {
            }

        this.setState({
            ...this.state,
            desktopFilter: {
                ...this.state.desktopFilter,
                showDesktopFilter: false,
                showSearch: true,
                showSortby: false,
                showFilter: false,
            },
            mobileFilter: {
                ...this.state.mobileFilter,
                showSortby: false,
                showFilter: false,
            },
        }, cb)
    }


    handleReset = () => {
        // console.log(this.state);
        // console.log(this.initState);
        this.setState({
            ...this.state,
            ...this.initState,
        }, () => {
            // console.log(this.state);
            this.closeFilters(this.ApiSearch);
        })
    }

    // Desktop Filter
    handleDesktopFilter = (data, e) => {
        switch (data) {

            case 'toggleDesktopFilter':
                e.preventDefault();
                this.setState({
                    desktopFilter: {
                        ...this.state.desktopFilter,
                        showDesktopFilter: !this.state.desktopFilter.showDesktopFilter,
                    }
                })
                break;

            case 'search':

                if (this.state.desktopFilter.showSearch) {

                    this.setState({
                        desktopFilter: {
                            ...this.state.desktopFilter,
                            showSearch: false,
                            showFilter: false,
                            showSortby: false
                        }
                    })
                } else {
                    this.setState({
                        desktopFilter: {
                            ...this.state.desktopFilter,
                            showSearch: true,
                            showFilter: false,
                            showSortby: false
                        }
                    })
                }

                break;

            case 'sortBy':

                if (this.state.desktopFilter.showSortby) {
                    this.setState({
                        desktopFilter: {
                            ...this.state.desktopFilter,
                            showSearch: false,
                            showFilter: false,
                            showSortby: false
                        }
                    })
                } else {
                    this.setState({
                        desktopFilter: {
                            ...this.state.desktopFilter,
                            showSortby: true,
                            showFilter: false,
                            showSearch: false
                        }
                    })
                }

                break;

            case 'filter':

                if (this.state.desktopFilter.showFilter) {
                    this.setState({
                        desktopFilter: {
                            ...this.state.desktopFilter,
                            showSearch: false,
                            showFilter: false,
                            showSortby: false
                        }
                    })
                } else {
                    this.setState({
                        desktopFilter: {
                            ...this.state.desktopFilter,
                            showFilter: true,
                            showSearch: false,
                            showSortby: false
                        }
                    })
                }

                break;

            default:
                this.setState({
                    ...this.state
                })
                break;
        }
    }

    // Mobile Filter
    handleMobileFilter(data) {
        switch (data) {
            case 'sortBy':
                this.setState({
                    mobileFilter: {
                        showSortby: !this.state.mobileFilter.showSortby,
                        showFilter: false
                    }
                })
                break;

            case 'filter':
                this.setState({
                    mobileFilter: {
                        showFilter: !this.state.mobileFilter.showFilter,
                        showSortby: false
                    }
                })
                break;

            default:
                this.setState({
                    ...this.state
                })
                break;
        }
    }

    handleSearchInput = (data, value) => {
        if (data === 'from') {
            if (typeof(this.state.search.to) !== "undefined") {
                if (value.isAfter(this.state.search.to)) {
                    this.setState({
                        search: {
                            ...this.state.search,
                            [data]: value,
                            'to': value,
                        }
                    })
                    return;
                }
            }
        }

        this.setState({
            search: {
                ...this.state.search,
                [data]: value
            }
        }, () => {
            // console.log(this.state.search)
        })
    }

    // Sortby Desktop and Mobile
    handleSortbyOptions = (data) => {
        this.setState({
            sortby: {
                [data]: !this.state.sortby[data]
            }
        })
    }

    // Filter Desktop and Mobile
    handleFilterOptions(key, value) {
        let array = this.state.filter[key];
        let index = array.indexOf(value);

        if (index > -1) {
            array.splice(index, 1);
        } else {
            array.push(value);
        }

        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                [key]: array
            }
        })
    }

    handleFilterSelect(key, value) {

        if (key === 'bookingType') {
            this.setState({
                filter: {
                    ...this.state.filter,
                    [key]: value,
                    price: {
                        min: this.state.filter.defaultPrice[value].min,
                        max: this.state.filter.defaultPrice[value].max,
                    },
                    initPrice: {
                        min: this.state.filter.defaultPrice[value].min,
                        max: this.state.filter.defaultPrice[value].max,
                    },
                }
            })
        } else {
            this.setState({
                filter: {
                    ...this.state.filter,
                    [key]: value
                }
            })
        }
    }

    handleFilterRange(key, value) {
        let filter = this.state.filter;
        if (filter[key] !== undefined) {

            if (key === 'price') {
                if (value.min >= this.state.filter.initPrice.min && value.max <= this.state.filter.initPrice.max) {
                    filter[key] = value;
                }
            } else if (key === 'distance') {
                if (value >= 0 && value <= 30) {
                    filter[key] = value;
                }
            }
        }

        this.setState({
            filter: filter
        })
    }

    handleFilterBoolean(key) {
        let filter = this.state.filter;
        filter[key] = !filter[key];
        this.setState({
            filter: filter,
        })

        // bCODE Integration
        if (key === 'bcode'){
            this.ApiSearch();
        }
    }

    // special function for handling indoor / covers/ no covers
    // handleFilterFeatures(key, value) {
    // 	let array = this.state.filter[key];
    // 	let index = array.indexOf(value);
    //
    // 	if (index < 0) {
    // 		let features = ['Indoor', 'WithCovers', 'NoCovers'];
    // 		features.forEach((f) => {
    // 			if (f !== key) {
    // 				let i = array.indexOf(f);
    // 				if (i > -1)
    // 					array.splice(i, 1);
    // 			}
    // 		});
    // 		array.push(value);
    // 	}
    //
    // 	this.setState({
    // 		...this.state,
    // 		filter: {
    // 			...this.state.filter,
    // 			[key]: array
    // 		}
    // 	})
    // }

    // Verified and Wishlist
    handleVerified = (id) => {
        this.setState({
            verified: {
                ...this.state.verified,
                [id]: !this.state.verified[id]
            }
        });
    }

    handleWishlist = (id) => {
        this.setState({
            wishlist: {
                ...this.state.wishlist,
                [id]: !this.state.wishlist[id]
            }
        });
    }

    onDatepickerRef = (el) => {
        if (el && el.input) {
            el.input.readOnly = true;
        }
    }

    handleViewChange = (e, data) => {
        e.preventDefault();

        // const { history } = this.props;
        // if (history) {
        //     //history.push('/home');
        //     let search = history.location.search;
        //
        //     if (data === 'map') {
        //         search = search + "&view=map";
        //     } else {
        //         search = search.replace("&view=map", "");
        //     }
        //     history.push(history.location.pathname + search);
        // }

        if (data === 'map') {
            this.setState({
                ...this.state,
                showView: data,
                desktopFilter: {
                    ...this.state.desktopFilter,
                    showDesktopFilter: true,
                },
                mapViewShowFilter: true,
            })
        } else {
            this.setState({
                ...this.state,
                showView: data,
                desktopFilter: {
                    ...this.state.desktopFilter,
                    showDesktopFilter: false,
                }
            })
        }
    }

    handleApplyFilter = () => {
        // let data = this.state;
        // this.saveFilter(data);

        this.closeFilters(this.ApiSearch);
    }

    handleSearch = () => {
        this.setState({
            ...this.state,
            errors: {
                address: false,
            }
        }, () => {

            this.closeFilters(this.ApiSearch);
        });
        //
        // if (this.state.search.address === '') {
        //
        //     this.setState({
        //         ...this.state,
        //         errors: {
        //             address: true,
        //         }
        //     });
        // } else {
        //
        //     this.setState({
        //         ...this.state,
        //         errors: {
        //             address: false,
        //         }
        //     }, () => {
        //
        //         this.closeFilters(this.ApiSearch);
        //     });
        // }
    }

    ApiSearch = () => {
        let searchParams = {
            // type: 'hourly',
            // access_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1Yzk4NWI5NDFmNGRlNTI3N2JlZWQ0NTEiLCJpYXQiOjE1NTM2OTcwMjJ9.Qq1khpWO-c2hobwBsF_EtNT7xMlmzUkHoDxfYj4PiAo',
        };

        // console.log(this.state.search);
        // console.log(this.state.filter);

        if (this.state.search.lat !== "")
            searchParams['lat'] = this.state.search.lat;

        if (this.state.search.lng !== "")
            searchParams['lng'] = this.state.search.lng;

        if (typeof (this.state.search.from.format) === "function")
            searchParams['start'] = this.state.search.from.utcOffset(8).format("YYYY-MM-DD");

        if (typeof (this.state.search.to.format) === "function")
            searchParams['end'] = this.state.search.to.utcOffset(8).format("YYYY-MM-DD");

        if (this.state.filter.bookingType !== "")
            searchParams['type'] = this.state.filter.bookingType;

        if (this.state.filter.cartype !== "")
            searchParams['vehicle'] = this.state.filter.cartype;

        searchParams['feature'] = [];

        if (this.state.filter.features1.length !== 0)
            searchParams['feature'] = searchParams['feature'].concat(this.state.filter.features1);

        if (this.state.filter.features2.length !== 0)
            searchParams['feature'] = searchParams['feature'].concat(this.state.filter.features2);

        if (this.state.sortby.price)
            searchParams['sort'] = 'price';

        if (this.state.sortby.rating)
            searchParams['sort'] = 'rating';

        if (this.state.sortby.distance)
            searchParams['sort'] = 'distance';

        if (this.state.sortby.review)
            searchParams['sort'] = 'review';

        searchParams['instant'] = this.state.filter.instant ? '1' : null;
        searchParams['distance'] = this.state.filter.distance;
        searchParams['min_price'] = this.state.filter.price.min;
        searchParams['max_price'] = this.state.filter.price.max;
        searchParams['headroom'] = this.state.filter.height;

        let callback = (obj) => {
            if (obj.status === 200) {
                // console.log(obj.body);
                this.setState({
                    ...this.state,
                    spaces: obj.body,
                    results: obj.body.length,
                    result_booking_type: searchParams['type'],//2019-12-11 Samson
                    // loading: false
                }, () => {

                    // bCODE Integration

                    let callback = (obj) => {
                        if (obj.status === 200) {
                            // console.log(obj.body);
                            let data = this.state.spaces;
                            let bCODEListings = obj.body.responseDetail.map( item => item.aggregatorID);
                            let newData = data.map((listing) => {
                                listing.bcode = bCODEListings.indexOf(listing.data._id) !== -1;
                                return listing
                            });

                            if (this.state.filter.bcode) {
                                newData = data.filter(listing => listing.bcode);
                            }

                            this.setState({
                                ...this.state,
                                spaces: newData,
                                results: newData.length,
                                loading: false
                            });

                        } else {
                            console.log(obj);
                            this.setState({
                                errors: obj.body ? obj.body.messages : '',
                                loading: false
                            });
                        }
                    };

                    let errorCallback = (obj) => {
                        console.log(obj);
                        this.setState({
                            errors: obj.body ? obj.body.messages : '',
                            loading: false
                        });
                    }

                    this.setState({
                        loading: true
                    },()=> {
                        getBCodeEnabledListings(callback, errorCallback);
                    })
                });

            } else if (obj.status === 400) {

                this.setState({
                    errors: obj.body.messages,
                    loading: false
                });
            }
        }

        this.setState({
            ...this.state,
            loading: true
        },()=> {
            findYourSpace(searchParams, callback);
        })

        //
        //
        //
        // let queryString = Object.keys(searchParams).map(k => k + "=" + searchParams[k]).join("&");
        //
        // fetch(Config.API_URL + 'space?' + queryString
        //     //     , {
        //     //     method: 'get',
        //     //     headers: new Headers({
        //     //         // 'Authorization': 'Basic ' + btoa(Config.UAT_API_LOGIN + ':' + Config.UAT_API_PASSWORD),
        //     //         // 'Accept': 'application/json',
        //     //         // 'Content-Type': 'application/json'
        //     //     }),
        //     //     body: JSON.stringify(body)
        //     // }
        // ).then(r => r.json().then(data => ({ status: r.status, body: data })
        // )).then(obj => {
        //     if (obj.status === 200) {
        //         // console.log(obj.body);
        //         this.setState({
        //             ...this.state,
        //             spaces: obj.body,
        //             results: obj.body.length,
        //         });
        //
        //     } else if (obj.status === 400) {
        //
        //         this.setState({
        //             errors: obj.body.messages
        //         });
        //     }
        //
        // }).catch(error => {
        //     console.log(error)
        // });
    }

    selectSpace = (space_id, zoom) => {
        if (this.state.selectedSpace === space_id)
            space_id = null;

        this.setState({
            ...this.state,
            selectedSpace: space_id,
        }, () => {
            if (typeof (zoom) !== "undefined" && zoom) {
                // console.log(zoom);
                this.setState({
                    ...this.state,
                    mapZoom: 18,
                })
            }
        })
    }

    render() {
        // const {
        //     t,
        //     i18n,
        // } = this.props;

        // let filteredSpaces = HelperFilter.filterSpaces(this.state, this.spaces);
        // let results = filteredSpaces.length;

        //2019-07-17 Samson - added custom params for listype 2 component
        let params = {'booking_type': this.state.filter.bookingType};

        if (this.state.showView === 'map') {
            // map view
            return (
                <div
                    className={"wrapper-container find-your-space fullWidth mapView " + (this.state.mapViewShowFilter ? "" : "hideFilter")}>
                    <SortbyFilterDesktop
                        // showView={this.state.showView}
                        // desktopFilter={this.state.desktopFilter}
                        // search={this.state.search}
                        // sortby={this.state.sortby}
                        // filter={this.state.filter}
                        // spaces={this.state.spaces}
                        {...this.state}

                        handleDesktopFilter={this.handleDesktopFilter}
                        handleSearchInput={this.handleSearchInput}
                        handleSortbyOptions={this.handleSortbyOptions}
                        handleFilterOptions={this.handleFilterOptions}
                        handleFilterSelect={this.handleFilterSelect}
                        handleFilterRange={this.handleFilterRange}
                        handleFilterBoolean={this.handleFilterBoolean}
                        // handleFilterFeatures={this.handleFilterFeatures}
                        handleSearch={this.handleSearch}
                        handleReset={this.handleReset}
                        handleApplyFilter={this.handleApplyFilter}
                        selectSpace={this.selectSpace}
                        selectedSpace={this.state.selectedSpace}
                        mapZoom={this.state.mapZoom}
                        mapCenterLat={this.state.mapCenterLat}
                        mapCenterLng={this.state.mapCenterLng}
                    />

                    <FindYourSpaceMapView
                        // showView={this.state.showView}
                        // spaces={this.state.spaces}
                        {...this.state}

                        handleViewChange={this.handleViewChange}
                        handleFilterBoolean={this.handleFilterBoolean}
                        selectSpace={this.selectSpace}
                        selectedSpace={this.state.selectedSpace}
                    />

                    <SortbyFilterMobile
                        // mobileFilter={this.state.mobileFilter}
                        // sortby={this.state.sortby}
                        // filter={this.state.filter}
                        {...this.state}

                        handleMobileFilter={this.handleMobileFilter}
                        handleSortbyOptions={this.handleSortbyOptions}
                        handleFilterOptions={this.handleFilterOptions}
                        handleFilterSelect={this.handleFilterSelect}
                        handleFilterRange={this.handleFilterRange}
                        handleFilterBoolean={this.handleFilterBoolean}
                        // handleFilterFeatures={this.handleFilterFeatures}
                        handleApplyFilter={this.handleApplyFilter}
                    />
                </div>
            )
        } else {
            return (
                <div className="wrapper-container find-your-space">
                    <FindYourSpaceListView
                        // showView={this.state.showView}
                        // desktopFilter={this.state.desktopFilter}
                        // search={this.state.search}
                        // sortby={this.state.sortby}
                        // filter={this.state.filter}
                        // spaces={this.state.spaces}
                        {...this.state}

                        handleViewChange={this.handleViewChange}
                        handleDesktopFilter={this.handleDesktopFilter}
                        handleSearchInput={this.handleSearchInput}
                        handleSortbyOptions={this.handleSortbyOptions}
                        handleFilterOptions={this.handleFilterOptions}
                        handleFilterSelect={this.handleFilterSelect}
                        handleFilterRange={this.handleFilterRange}
                        handleFilterBoolean={this.handleFilterBoolean}
                        handleSearch={this.handleSearch}
                        handleReset={this.handleReset}
                        handleApplyFilter={this.handleApplyFilter}

                        params={params}
                    />

                    <SortbyFilterMobile
                        // mobileFilter={this.state.mobileFilter}
                        // sortby={this.state.sortby}
                        // filter={this.state.filter}
                        {...this.state}

                        handleMobileFilter={this.handleMobileFilter}
                        handleSortbyOptions={this.handleSortbyOptions}
                        handleFilterOptions={this.handleFilterOptions}
                        handleFilterSelect={this.handleFilterSelect}
                        handleFilterRange={this.handleFilterRange}
                        handleFilterBoolean={this.handleFilterBoolean}
                        handleApplyFilter={this.handleApplyFilter}
                    />
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => (
    {
        findParkingSpace: state.findParkingSpace
    }
);

export default withRouter(withTranslation()(connect(mapStateToProps)(FindYourSpace)));
