import React, { Component } from 'react';
import { Redirect } from 'react-router';

// import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import * as MembersActionCreators from "../../../actions/members";

import {logout} from "../../../utils/04Api/User";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

class MemberLogout extends Component {

	// constructor(props) {

	// 	super(props);

	// 	const { dispatch } = props;

	// 	// DISPATCH TO ACTIONS
	// 	this.toLogout = bindActionCreators(MembersActionCreators.toLogout, dispatch);
	// }
	state = {
		redirect: false
	}

	componentDidMount = () => {
		if (this.props.members.Login.length !== 0) {
			const cb = ()=>{
				this.props.dispatch({type: 'USER_LOGOUT'});

				this.setState({
					...this.state,
					redirect: true
				})
			}
			logout(this.props.members.Login.userToken, cb, cb);
			cookies.set('enterprise_token', '', { path: '/'});
		}
	}

	render() {
		if (this.state.redirect)
			return <Redirect push to='/' />;
		else
			return <div></div>;
	}
}

const mapStateToProps = (state) => (
	{
		members: state.members
	}
);

export default connect(mapStateToProps)(MemberLogout);
