import React, {Component} from 'react';
import {Redirect} from 'react-router';
import {withTranslation} from 'react-i18next';

import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';

import ErrorMessage from '../../../components/103Parts/ErrorMessage';

import {connect} from 'react-redux';
import {forgetPasswordEmailValidate} from "../../../utils/04Api/User";

class MemberForgetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            formSubmitted: false,
            errors: []
        }
    }

    ApiForget = (data) => {
        // var body = {
        //     email: data.EmailAddress
        // }

        // fetch(Config.API_URL + 'auth', {
        //     method: 'post',
        //     headers: new Headers({
        //         // 'Authorization': 'Basic ' + btoa(Config.UAT_API_LOGIN + ':' + Config.UAT_API_PASSWORD),
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     }),
        //     body: JSON.stringify(body)
        // }).then(r => r.json().then(data => ({ status: r.status, body: data })
        // )).then(obj => {

        // if (obj.status === 200) {

        //     const postData = {
        //         userToken: obj.body.token
        //     }

        // this.toLogin(postData);

        this.setState({
            ...this.state,
            submitting: false,
            formSubmitted: true,
            email: data.EmailAddress
        })

        // } else if (obj.status === 400) {

        //     this.setState({
        //         errors: obj.body.messages
        //     });
        // }

        // }).catch(error => {
        //     console.log(error)
        // });
    }


    handleSubmit = (values, {setFieldError}) => {

        const {i18n} = this.props;

        if (!this.state.submitting) {
            this.setState({
                ...this.state,
                submitting: true,
            });

            let language = 'english';
            if (typeof(i18n.language) !== "undefined" && i18n.language === 'zh-HK')
                language = 'chinese';

            // values.rurl = window.location.protocol + "//" + window.location.host + '/' + i18n.language + '/memberResetPassword';

            // TODO: Add api code here

            let successCallback = response => {
                // console.log("Response Success");
                // console.log(response);

                if (response.status === 200) {
                    // const postData={
                    //     userToken: response.body.token.token
                    // };

                    this.setState({
                        ...this.state,
                        formSubmitted: true,
                        email: values.email
                    })
                } else if (response.status === 400) {
                    if(response.body.msg === 'Invalid email'){
                        setFieldError('email', this.props.t("forgotPassword:errors.verifiedEmail"));
                    }

                    this.setState({
                        ...this.state,
                        submitting: false,
                    })
                }
            };

            let errorCallback = response => {
                // console.log("Response Error");
                // console.log(response);

                setFieldError('email', 'Cannot submit, please try again.');

                this.setState({
                    ...this.state,
                    submitting: false,
                })
            };

            forgetPasswordEmailValidate(values.email, language, successCallback, errorCallback);
        }
    };

    form = ({values, handleSubmit, errors, touched}) => {

        const {t} = this.props;

        return (
            <Form>
                <dl className="generalForm clearfix">
                    <div>
                        <dt><h4>{t("forgotPassword:email")}</h4></dt>
                        <dd>
                            <div className="wrap clearfix">
                                <div className="full">
                                    <div>
                                        <Field name="email" type="email"/>
                                        {errors.email && touched.email ? <ErrorMessage message={errors.email}/> : null}
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </div>
                </dl>

                <div className="sep-25"/>
                <div className="sep-25"/>

                <div className="full left align-center">
                    <div>
                        <button type="submit" className="button">{t("forgotPassword:send")}</button>
                    </div>
                </div>
            </Form>
        )
    }

    render() {
        const {
            t,
            i18n
        } = this.props;

        // Form Validation
        const Schema = Yup.object().shape({
            email: Yup.string()
                .email(t("forgotPassword:errors.requireEmail"))
                .required(t("forgotPassword:errors.requireEmail"))
        });

        if (this.state.formSubmitted === true) {
            return <Redirect to={"/" + i18n.language + "/forgetPasswordConfirm?email=" + this.state.email}/>;
        }

        return (
            <div className="wrapper-container memberCreateAccount padding">

                {/* <div className="page-title"><h2>Contact Us</h2></div>

                    <div className="sep-0" /> */}

                <div className="containerMain borderTop">
                    <div className="content clearfix">
                        <div className="full left align-center">
                            <span className="door"></span>
                            <h2>{t("forgotPassword:title")}</h2>
                        </div>

                        <div className="sep-20"/>

                        <Formik
                            initialValues={{
                                email: '',
                            }}
                            validationSchema={Schema}
                            onSubmit={this.handleSubmit}
                            component={this.form}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {
        members: state.members
    }
);

export default withTranslation()(connect(mapStateToProps)(MemberForgetPassword));
