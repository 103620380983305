import socketIO from 'socket.io-client';
import * as Config from '../config';
import {filter} from 'lodash';

export const io = socketIO.connect(Config.SOCKET_SERVER);

export const joinAlertRoom = (uid, cb, msgCb) => {
    io.emit('joinnotification', uid);

    io.on('joinnotificationsuccess', () => {
        cb();

        io.emit("getunreadmessages", uid)

        io.on("totalunreadmessage", (data) => {
            msgCb(data);
        })
    })
}

export const leaveAlertRoom = (uid, cb) => {
    io.emit('leaveroom', uid);

    io.on('leave', (leave_uid) => {
        if (uid === leave_uid)
            cb(leave_uid);
    })
}

export const postNotification = (uid, card, data, cb) => {
    io.emit("postnotification", {
        rid: uid,
        card: card,
        data: data
    });

    if (typeof (cb) === "function")
        cb();
}

export const getNotifications = (uid, cb) => {
    io.emit("joinnotification", uid);
    io.on("joinnotificationsuccess", (data) => {
        // console.log("joinnotification", data);

        io.emit("getmsg", {rid: uid, order: -1});
        io.on("messages", (data) => {
            // console.log(data);

            if (typeof (data.rid) !== "undefined" && data.rid === uid && typeof (data.msgs) !== "undefined") {
                io.emit('readRoom', {rid: uid, uid: uid});

                // io.on('readRoomResult', (data) => {
                //     // console.log('readRoomResult', data);
                // })

                cb(data.msgs);
            }
        })

    })
}

export const getRooms = (uid, getRoomCb) => {
    // io.on('action', (data) => {
    //     console.log("action", data);
    // });

    // console.log("getallroom from ", uid);
    io.emit("getallroom", uid);

    io.on('getallroom', (data) => {
        // console.log("getallroom", data);

        data = filter(data, (o) => {
            return o.messages.length;
        })

        getRoomCb(data);

        // console.log("getallroom after filter", data);
    })
}

export const sendCard = (rid, sender_id, receiver_id, card, data, message, cb) => {
    let submitData = {
        rid: rid,
        uid: sender_id,
        receiver_id: receiver_id,
    }

    if (card)
        submitData.card = card;

    if (data)
        submitData.data = data;

    if (message)
        submitData.msg = message;

    io.emit('postcard', submitData);

    // console.log(submitData);

    cb();
}

export const sendMessage = (rid, sender_id, receiver_id, message, pending, cb) => {
    const submitData = {
        rid: rid,
        uid: sender_id,
        receiver_id: receiver_id,
        msg: message,
        pending: pending,
    }
    io.emit('postmsg', submitData);

    if (typeof (cb) === "function")
        cb();
}

export default {
    io: io,
    getRooms: getRooms,
    sendCard: sendCard,
    joinAlertRoom: joinAlertRoom,
    leaveAlertRoom: leaveAlertRoom,
};

// const sendMessage = () => {
//     console.log('a');
// }

// export default {
//     io: io,
//     // sendMessage: sendMessage,
// }
