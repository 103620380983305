import React, {Component} from 'react';

import {withTranslation} from "react-i18next";

import {Field, Form, Formik} from "formik";
import ErrorMessage from "../../../components/103Parts/ErrorMessage";
// import {Link} from "react-router-dom";
import * as UserApi from "../../../utils/04Api/User";
import * as Yup from "yup";

class ProfileEmailValidation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            invalid_link: false,
            success: false,
            email: props.params !== null && typeof (props.params.email) !== "undefined" ? decodeURIComponent(props.params.email) : '',
            curemail: props.params !== null && typeof (props.params.curemail) !== "undefined" ? decodeURIComponent(props.params.curemail) : '',
            uid: props.params !== null && typeof (props.params.uid) !== "undefined" ? props.params.uid : '',
            token: props.params !== null && typeof (props.params.token) !== "undefined" ? props.params.token : '',
        }
    };

    componentDidMount = () => {
        this.checkLink();
    };

    checkLink = () => {
        let body = {
            uid: this.state.uid,
            email: this.state.email,
            checkLink: true,
            token: this.state.token,
        };

        const cb = (res) => {
            if (typeof (res.body) !== "undefined") {
                if (typeof (res.body.status) !== "undefined" && res.body.status === 1) {
                    this.setState({
                        ...this.state,
                        loading: false,
                    });
                } else if (typeof (res.body.msg) !== "undefined") {
                    this.setState({
                        ...this.state,
                        loading: false,
                        invalid_link: true,
                    });
                }
            }
        };

        const ecb = (res) => {
            this.setState({
                ...this.state,
                loading: false,
                invalid_link: true,
            });
        };
        UserApi.email_validate_update(body, cb, ecb);
    };

    emailForm = ({values, errors, touched, handleChange}) => {
        const {
            t,
            // i18n
        } = this.props;

        return (
            <Form>
                <div className="sep-25"/>
                <div>
                    <dt/>
                    <dd>
                        <Field name="curemail" type="email" placeholder={t("login:email")} maxLength="100" disabled/>
                    </dd>
                </div>
                <div className="sep-25"/>
                <div>
                    <dt/>
                    <dd>
                        <Field name="password" type="password" placeholder={t("login:password")} maxLength="30"/>
                        {errors.password && touched.password ? <ErrorMessage message={errors.password}/> : null}
                    </dd>
                </div>
                <div className="sep-25"/>
                <div>
                    {errors.general ? <ErrorMessage message={errors.general}/> : null}
                </div>

                <div className="sep-25"/>

                <Field name="email" type="hidden"/>
                <Field name="uid" type="hidden"/>
                <Field name="token" type="hidden"/>

                <div className="full left align-center">
                    <div>
                        <button type="submit" className="button">{t("login:login")}</button>
                    </div>
                </div>
            </Form>
        )
    };

    handleSubmit = (values, {setFieldError}) => {
        let body = {
            uid: values.uid,
            email: values.email,
            password: values.password,
            token: values.token,
        };

        const callback = (res) => {
            if (typeof (res.body) !== "undefined") {
                if (typeof (res.body.status) !== "undefined" && res.body.status === 1) {
                    this.setState({
                        ...this.state,
                        success: true,
                    });
                } else if (typeof (res.body.msg) !== "undefined") {
                    let msg = res.body.msg;
                    switch (msg) {
                        case "user_exist":
                            setFieldError('general', this.props.t("registerCorporate:errors.email"));
                            break;
                        case "invalid_password":
                            setFieldError('password', this.props.t("login:errors.invalidEmailPassword"));
                            break;
                        default:
                            this.setState({
                                ...this.state,
                                invalid_link: true,
                            });
                    }
                }
            }

        };

        UserApi.email_validate_update(body, callback);
    };

    schema = () => {
        // Form Validation
        let yupShape;
        yupShape = {
            // email: Yup.string()
            //     .required('login:errors.requireEmail')
            //     .email('login:errors.invalidEmail'),
            password: Yup.string()
                .required('login:errors.requirePassword'),
        };

        return Yup.object().shape(yupShape);
    };

    render() {
        const {
            t,
            i18n,
        } = this.props;

        if (this.state.loading) {
            return <div className="loading_box">{t("Loading")}</div>
        }

        if (this.state.invalid_link) {
            return (
                <div className="wrapper-container memberEmailVerification">
                    <div className="containerMain borderTop">
                        <div className="content clearfix">
                            <div className="full left align-center">
                                <h2>{t("myAccount:changeEmail.fail.sorry")}</h2>
                                <p>{t("myAccount:changeEmail.fail.invalid")}</p>
                                <a href={"/" + i18n.language}>{t("myAccount:changeEmail.toHomepage")}</a>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.success) {
            return (
                <div className="wrapper-container memberEmailVerification">
                    <div className="containerMain borderTop">
                        <div className="content clearfix">
                            <div className="full left align-center">
                                <h2>{t("myAccount:changeEmail.success.congratulations")}</h2>
                                <p>{t("myAccount:changeEmail.success.succeeded")}</p>
                                <a href={"/" + i18n.language}>{t("myAccount:changeEmail.toHomepage")}</a>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="wrapper-container memberEmailVerification">
                <div className="containerMain borderTop">
                    <div className="content clearfix">
                        <div className="full left align-center">
                            {/*<span className="door"/>*/}
                            <h2 className={"preline"}>{t("myAccount:changeEmail.title", {'email': this.state.email})}</h2>
                        </div>
                        <div className="sep-20"/>
                        <dl className="clearfix" style={{"margin": "0 auto", "maxWidth": "380px"}}>
                            <Formik
                                initialValues={{
                                    email: this.state.email,
                                    curemail: this.state.curemail,
                                    password: '',
                                    uid: this.state.uid,
                                    token: this.state.token,
                                }}
                                validationSchema={this.schema}
                                onSubmit={this.handleSubmit}
                                component={this.emailForm}
                            />
                        </dl>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ProfileEmailValidation);
