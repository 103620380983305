import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import moment from "moment";

import {Redirect} from 'react-router';
import {connect} from 'react-redux';
import ErrorMessage from '../../../components/103Parts/ErrorMessage';

import * as Helper from "../../../utils/03FindParkingSpace/Calculations";
import Verified from "../../../components/103Parts/Verified";
import Heart from "../../../components/103Parts/Heart";
import Reviews from "../../../components/103Parts/Reviews";

import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';

import * as UserApi from "../../../utils/04Api/User";
import {saveCreditCard} from "../../../utils/04Api/Payment";
import {getBookingById, updateBookingPayment} from "../../../utils/04Api/Booking";
import {Link} from "react-router-dom";
import * as Config from "../../../config";

class UpdatePayment extends Component {

    constructor(props) {
        super(props)

        let logined = false,
            uid = '';
        if (props.members.Login.length !== 0) {
            logined = true;
            uid = props.members.Login.user._id;
        }

        this.state = {
            bookingID: props.booking_id,
            type: null, //props.params.booking_type,
            startDate: null, //moment(props.params.from + "T" + (typeof (props.params.from_time) !== "undefined" ? props.params.from_time : "00:00") + ":00+0800"),
            endDate: null, //moment(props.params.to + "T" + (typeof (props.params.to_time) !== "undefined" ? props.params.to_time : "23:59") + ":00+0800"),
            startTime: null, //(typeof (props.params.from_time) !== "undefined" ? props.params.from_time : null),
            endTime: null, //(typeof (props.params.to_time) !== "undefined" ? props.params.to_time : null),

            uid: uid,
            space: null,
            total: null,
            redirect: false,
            logined: logined,
            wishlist: [],
            me: null,
            selectedCard: 'newCreditCard',
            newCard: true,
            paymentID: null,
            calculation: null,

            submitting: false,
            values: null,

            completed: false,
            isInstant: null,

            loading: true,
            defaultCreditCardID: '',
            defaultVehicle: '',

            ddd: null,
            // otp: null,
        }
    }

    componentWillMount = () => {
        // const token = obj.body.token;
        //
        this.ApiGetMe(this.ApiGetBooking);
    }

    changeStateLevelOne = (data1, value) => {
        this.setState({
            [data1]: value
        })
    }

    ApiGetMe = (cb) => {
        if (this.state.logined) {
            const token = this.props.members.Login.userToken;

            UserApi.getMe(token, (obj) => {
                if (obj.status === 200) {
                    const me = obj.body;
                    let newCard = true,
                        paymentID = null;
                    if (me.payment.length > 0) {
                        newCard = false;
                        paymentID = me.payment[0]._id;
                    }

                    let defaultCreditCardID = '';

                    if (obj.body.payment.length > 0) {

                        Object.keys(obj.body.payment).forEach(key => {
                            let payment = obj.body.payment[key];
                            if (payment.default) {
                                defaultCreditCardID = payment._id;
                            }
                        });

                        if (defaultCreditCardID === '') {
                            defaultCreditCardID = obj.body.payment[0]._id;
                        }
                    }

                    let defaultVehicleID = '';
                    if (obj.body.vehicles.length > 0) {
                        obj.body.vehicles.forEach((itm, idx) => {
                            if (itm.default) {
                                defaultVehicleID = itm._id;
                            }
                        });

                        if (defaultVehicleID === '')
                            defaultVehicleID = obj.body.vehicles[0]._id;
                    }

                    let defaultOctopusID = '';
                    //20190715 commented by samson as client requested no default octcard
                    // if (obj.body.octcard.length > 0) {
                    //     obj.body.octcard.forEach((itm, idx) =>  {
                    //         if(itm.default) {
                    //             defaultOctopusID = itm._id;
                    //         }
                    //     });
                    //
                    //     if (defaultOctopusID === '')
                    //         defaultOctopusID = obj.body.octcard[0]._id;
                    // }

                    this.setState({
                        ...this.state,
                        me: obj.body,
                        newCard: newCard,
                        paymentID: paymentID,
                        defaultCreditCardID: defaultCreditCardID,
                        defaultVehicleID: defaultVehicleID,
                        defaultOctopusID: defaultOctopusID,
                    }, cb)
                } else {
                    this.setState({
                        logined: false
                    });
                }
            })
        }
    }

    ApiGetBooking = () => {
        const access_token = this.props.members.Login.userToken;
        getBookingById(access_token, this.state.bookingID, (data)=> {
            const booking = data.body;

            if (booking.booker._id !== this.state.uid || booking.paymentFailed !== true) {
                this.setState({
                    redirect: true
                });
                return;
            }

            this.setState({
                ...this.state,
                type: booking.type, //props.params.booking_type,
                startDate: moment(booking.from + "+0800"), //moment(props.params.from + "T" + (typeof (props.params.from_time) !== "undefined" ? props.params.from_time : "00:00") + ":00+0800"),
                endDate: moment(booking.to + "+0800"), //moment(props.params.to + "T" + (typeof (props.params.to_time) !== "undefined" ? props.params.to_time : "23:59") + ":00+0800"),
                space: booking.space,
                total: Number(booking.cost),
                calculation: booking.calculation,
                loading: false,
            });
            // console.log(booking);
        }, (err)=> {
            console.log("err", err);
        });
    }

    handleVerified = (id) => {
        this.setState({
            verified: {
                ...this.state.verified,
                [id]: !this.state.verified[id]
            }
        });
    }

    handleWishlist = (id) => {
        this.setState({
            wishlist: {
                ...this.state.wishlist,
                [id]: !this.state.wishlist[id]
            }
        });
    }

    updateSubmitting = (submitting, cb) => {
        this.setState({
            ...this.state,
            submitting: submitting,
        }, cb)
    }

    setSubmitting = (cb) => {
        this.updateSubmitting(true, cb);
    }

    setNotSubmitting = (cb) => {
        // console.log("Final: ", this.state.values);

        this.updateSubmitting(false, cb);
    }

    handleToken = (cb) => {
        const values = this.state.values;
        const token = this.props.members.Login.userToken;

        let eCb = (res) => {
            console.log("save creditcard failed", res);
            this.setNotSubmitting();
        }

        // get / save credit card token
        saveCreditCard(
            token,
            values,
            this.props.members.Login.userId,
            false,
            cb,
            eCb
        );
    }

    handleSubmit = (values, {setFieldError}) => {
        if (!this.state.submitting) {
            // payment callback
            const paymentIDCallback = () => {
                updateBookingPayment(this.props.members.Login.userToken, this.state.bookingID, this.state.values.paymentID, (res)=>{
                    console.log('updateBookingPayment', res.body);

                    if (typeof (res.body.status) !== "undefined") {
                        if (res.body.status === 1) {
                            const url = Config.API_URL + "payment/3dsUpdate/" + res.body.id;
                            console.log('url', url);
                            window.location.href = url;
                            return;
                        }
                    }
                    setFieldError("general", "payment:gateway.PAYMENT_FAILED");
                    this.setNotSubmitting();
                },  (res) => {
                    setFieldError("general", "payment:gateway.PAYMENT_FAILED");
                    this.setNotSubmitting();
                })

                // window.location.href = Config.API_URL + "payment/3ds/" + ;
                // return;

                // all done, submit to book a space api
                // bookASpace(this.props.members.Login.userToken, this.state.spaceID, this.state.values, (res) => {
                //     if (typeof (res.body.status) !== "undefined") {
                //         if (res.body.status === 1) {
                //             window.location.href = Config.API_URL + "payment/3ds/" + res.body.id;
                //             return;
                //         }
                //     }
                //
                //     setFieldError("general", "payment:gateway.PAYMENT_FAILED");
                //     this.setNotSubmitting();
                // }, (res) => {
                //     setFieldError("general", "payment:gateway.PAYMENT_FAILED");
                //     this.setNotSubmitting();
                // })
            }

            const process = () => {
                // if add a new card, save to server
                this.handleToken((res) => {
                    console.log("handletoken done", res.body);

                    if (res.body.status === 1) {
                        // ok
                        // update paymentID
                        let values = this.state.values;
                        values.paymentID = res.body.paymentID;

                        this.setState({
                            ...this.state,
                            values: values
                        }, paymentIDCallback);
                    } else {
                        setFieldError("general", "payment:errors.credit_card_info");
                        this.setNotSubmitting();
                    }
                });
            };

            // set submitting and then run process
            this.setState({
                ...this.state,
                values: values
            }, () => {
                this.setSubmitting(process);
            })
        }
    }

    changeTips(data) {
        this.setState({
            ...this.state,
            [data]: !this.state[data]
        })
    }

    form = ({values, errors, touched, handleChange, handleBlur, setFieldValue}) => {
        const {t, i18n} = this.props;

        return (
            <div>
                <Form>
                    <div className="wrapper">
                        <div className="cardIcons">
                            <span>
                                <img src={require('../../../images/mobile/03_find_parking_space/icon_visa.png')}
                                     alt=""/>
                            </span>
                            <span>
                                <img src={require('../../../images/mobile/03_find_parking_space/icon_master.png')}
                                     alt=""/>
                            </span>
                        </div>

                        <h4><span>1</span>{t("updatePayment:payment.title")}</h4>

                        <div>
                            <div className="wrap clearfix">
                                <div className="full">
                                    <div>
                                        <h5>{t("payment:payment.creditCard")}</h5>
                                        <Field type="text" name="cardNumber" maxLength="16"/>
                                        {errors.cardNumber && touched.cardNumber ?
                                            <ErrorMessage message={errors.cardNumber}/> : null}
                                    </div>
                                </div>
                            </div>

                            <div className="wrap clearfix">
                                <div className="full">
                                    <div>
                                        <h5>{t("payment:payment.cardHolder")}</h5>
                                        <Field type="text" name="holderName" maxLength="50"/>
                                        {errors.holderName && touched.holderName ?
                                            <ErrorMessage message={errors.holderName}/> : null}
                                    </div>
                                </div>
                            </div>

                            <div className="wrap clearfix">
                                <div className="fifty">
                                    <div>
                                        <h5>{t("payment:payment.expiryDate.title")}</h5>
                                        <Field type="text" name="expiryDateMM"
                                               placeholder={t("payment:payment.expiryDate.mm")} maxLength="2"/>
                                        {(errors.expiryDateYY && touched.expiryDateYY) || (errors.expiryDateMM && touched.expiryDateMM) ?
                                            <ErrorMessage
                                                message={(errors.expiryDateMM ? errors.expiryDateMM : '\u00A0')}/> : null}
                                    </div>
                                </div>
                                <div className="fifty">
                                    <div>
                                        <h5>&nbsp;</h5>
                                        <Field type="text" name="expiryDateYY"
                                               placeholder={t("payment:payment.expiryDate.yy")} maxLength="2"/>
                                        {(errors.expiryDateYY && touched.expiryDateYY) || (errors.expiryDateMM && touched.expiryDateMM) ?
                                            <ErrorMessage
                                                message={(errors.expiryDateYY ? errors.expiryDateYY : '\u00A0')}/> : null}
                                    </div>
                                </div>
                            </div>

                            <div className="wrap clearfix">
                                <div className="full">
                                    <div>
                                        <h5>{t("payment:payment.expiryDate.cvc")}</h5>
                                        <Field type="text" name="CVC" maxLength="3"/>
                                        {errors.CVC && touched.CVC ? <ErrorMessage message={errors.CVC}/> : null}
                                    </div>
                                </div>
                            </div>

                            {/* <div className="secure">
                                    <span><img className="norton" src={require('../../../images/mobile/03_find_parking_space/icon_norton.png')} alt="" /></span>
                                    <span><img className="lock" src={require('../../../images/mobile/03_find_parking_space/icon_secure.png')} alt="" /> Secure payment</span>
                                </div> */}

                            <div className="sep-10"/>

                            <div className="checkboxFive">
                                <div>
                                    <Field type="checkbox" name="addCard" id="AddCreditcard" value="1"
                                           checked={values.addCard}/>
                                    <label htmlFor="AddCreditcard"/>
                                </div>
                                <label htmlFor="AddCreditcard">
                                    {t("payment:rememberCreditCard")}
                                </label>
                            </div>

                            <div className="sep-20"/>
                        </div>

                        <div className="checkboxFive">
                            <div>
                                <Field
                                    type="checkbox"
                                    id="Accept"
                                    name="agree"
                                    value={values.agree}
                                    checked={values.agree}
                                    onChange={handleChange}/>
                                <label htmlFor="Accept"/>
                            </div>
                            <label htmlFor="Accept">
                                <p>
                                    {t("payment:agreement1")}
                                    <Link target="_blank"
                                          to={"/" + i18n.language + "/terms"}>{t("registerStart:agreement.part2")}</Link>
                                    {t("registerStart:agreement.comma")}
                                    <Link target="_blank"
                                          to={"/" + i18n.language + "/licenseAgreement"}>{t("registerStart:agreement.part3")}</Link>
                                    {t("registerStart:agreement.comma")}
                                    <Link target="_blank"
                                          to={"/" + i18n.language + "/privacy"}>{t("registerStart:agreement.part4")}</Link>
                                    {t("registerStart:agreement.and")}
                                    <Link target="_blank"
                                          to={"/" + i18n.language + "/disclaimer"}>{t("registerStart:agreement.part5")}</Link>
                                    {t("payment:agreement4")}
                                </p>
                            </label>

                        </div>

                        {errors.agree && touched.agree ? <ErrorMessage message={errors.agree}/> : null}

                        <div className="sep-20"/>

                        <table className="verifiedGuarantee">
                            <tbody>
                            <tr>
                                <td><img
                                    src={require('../../../images/mobile/01_homepage/icon_01_02.png')}
                                    alt=""/></td>
                                <td>{t("payment:landSearch")}<span>{t("payment:verified")}</span></td>
                            </tr>
                            <tr>
                                <td><img
                                    src={require('../../../images/mobile/01_homepage/icon_01_03.png')}
                                    alt=""/></td>
                                <td>{t("space:enjoy")}
                                    <Link target="_blank"
                                          to={"/" + i18n.language + "/parkerProtectionGuarantee"}>{t("space:protection")}</Link>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        {errors.general ? <div>
                            <div className="sep-20"/>
                            <ErrorMessage message={errors.general}/></div> : null}

                        {typeof (this.props.params.err) !== "undefined" ? <div>
                            <div className="sep-20"/>
                            <ErrorMessage message={t("payment:gateway.PAYMENT_FAILED")}/></div> : null}

                        <div className="sep-20"/>

                        <input type="submit" className="button bookNow" value={t("payment:payNow")}/>
                    </div>
                </Form>
            </div>
        )
    }

    // Form Validation
    schema = Yup.object().shape({
        cardNumber: Yup.string()
        // .required(t("payment:errors.cardNumber"))
            .matches(/^\d{13,19}$/, "payment:errors.cardNumber"),
        // holderName: Yup.string()
        // .required(t("payment:errors.cardHolder")),
        expiryDateYY: Yup.string()
        // .required(t("payment:errors.expiryDate"))
            .matches(/^([1][9]|[2-9]\d)$/, "payment:errors.expiryDate"),
        expiryDateMM: Yup.string()
        // .required(t("payment:errors.expiryDate"))
            .matches(/^(\d|[0]\d|[1][012])$/, "payment:errors.expiryDate"),
        CVC: Yup.string()
        // .required(t("payment:errors.cvc"))
            .matches(/^\d{3}$/, "payment:errors.cvc"),
        agree: Yup.boolean()
            .oneOf([true], "payment:errors.tnc"),
    })

    render() {
        const {
            t,
            i18n
        } = this.props;

        if (this.state.redirect)
            return <Redirect to={"/" + i18n.language + "/home"}/>;

        if (!this.state.logined)
            return <Redirect to={"/" + i18n.language + "/login"}/>;

        if (this.state.completed) {

            // console.log("paymentComplete", this.state);

            if (this.state.isInstant)
                return <Redirect
                    to={"/" + i18n.language + "/booking-success?orderID=" + this.state.values.orderID + "&booking_type=" + this.state.type + "&value=" + this.state.Helper.numeralMoney(this.state.calculation.totalcost, false)}/>;
            else
                return <Redirect to={"/" + i18n.language + "/booking-pending?orderID=" + this.state.values.orderID + "&booking_type=" + this.state.type + "&value=" + this.state.Helper.numeralMoney(this.state.calculation.totalcost, false)}/>;
        }

        if (this.state.loading) {
            return <div className="loading_box">{t("Loading")}</div>
        }

        return (
            <div className="wrapper-container payment">

                <div className="containerMain">

                    {this.state.me && this.state.space &&
                    <div className="content clearfix">

                        <div className="full left">
                            <div>
                                <h2>{t("updatePayment:title")}</h2>
                            </div>
                        </div>

                        <div className="sep-0"/>

                        <div className="fifty left">
                            <div>
                                <div className="border-box clearfix">
                                    <div className="GridType5">
                                        <div className="image"
                                             style={{backgroundImage: "url(" + (typeof (this.state.space) !== "undefined" && this.state.space.images.length ? this.state.space.images[0] : require('../../../images/mobile/General/dummy-img/img_media.jpg')) + ")"}}>

                                            <Verified
                                                id={this.props.space_id}
                                                verified={this.state.verified}
                                                handleVerified={this.handleVerified}
                                            />
                                            <Heart
                                                id={this.props.space_id}
                                                wishlist={this.state.wishlist}
                                                handleWishlist={this.handleWishlist}
                                            />
                                        </div>
                                        <div className="text clearfix">
                                            <h3>{this.state.space ? this.state.space.title : ''}</h3>
                                            <h4>{this.state.space ? this.state.space.address : ''}</h4>
                                            <div className="sep-10"/>
                                            <Reviews score={0} reviews={0}/>
                                        </div>
                                    </div>

                                    <div className="sep-20"/>

                                    <hr/>

                                    <div className="sep-20"/>

                                    {this.state.type === "hourly" ?
                                        (
                                            <div className="payment_total">
                                                <dl className="clearfix">
                                                    <dt>{this.props.params.from} {this.props.params.from_time} - {this.props.params.to} {this.props.params.to_time}</dt>
                                                    <dd>{Helper.numeralMoney(this.state.calculation.cost)}</dd>
                                                </dl>

                                                {typeof (this.state.calculation.promotion) !== "undefined" &&
                                                <dl className="clearfix">
                                                    <dt>{t("space:price.discount")}</dt>
                                                    <dd>- {Helper.numeralMoney(this.state.calculation.discount)}</dd>
                                                </dl>
                                                }

                                                {this.state.calculation.deposit > 0 &&
                                                <dl className="clearfix">
                                                    <dt>
                                                        {t("space:price.deposit")}
                                                        <button className="tips"
                                                                onClick={() => this.changeTips('DepositTips')}
                                                        />
                                                        {this.state.DepositTips &&
                                                        <div
                                                            className="tipsText">{t("payment:popup.deposit")}</div>
                                                        }
                                                    </dt>
                                                    <dd>{Helper.numeralMoney(this.state.calculation.deposit)}</dd>
                                                </dl>
                                                }

                                                {this.state.calculation.rentaldeposit > 0 &&
                                                <dl className="clearfix">
                                                    <dt>{t("space:price.rental_deposit")}</dt>
                                                    <dd>{Helper.numeralMoney(this.state.calculation.rentaldeposit)}</dd>
                                                </dl>
                                                }

                                                <dl className="clearfix">
                                                    <dt><b>{t("space:price.total")}</b>
                                                        <button className="tips"
                                                                onClick={() => this.changeTips('TotalTips')}
                                                        />
                                                        {this.state.TotalTips &&
                                                        <div
                                                            className="tipsText">{t("payment:popup.deposit")}</div>
                                                        }
                                                    </dt>
                                                    <dd>
                                                        <b>{Helper.numeralMoney(this.state.calculation.totalcost)}</b>
                                                    </dd>
                                                </dl>
                                            </div>
                                        ) : (
                                            <div className="payment_total">
                                                <dl className="clearfix">
                                                    <dt>{t("space:price.subTotal")}</dt>
                                                    <dd>{Helper.numeralMoney(this.state.calculation.org_subtotal)}</dd>

                                                    {typeof (this.state.calculation.promotion) !== "undefined" &&
                                                    <dl className="clearfix">
                                                        <dt>{t("space:price.discount")}</dt>
                                                        <dd>- {Helper.numeralMoney(this.state.calculation.discount_total)}</dd>
                                                    </dl>
                                                    }

                                                    <dt>
                                                        {t("space:price.deposit")}
                                                        <button className="tips"
                                                                onClick={() => this.changeTips('DepositTips')}
                                                        />
                                                        {this.state.DepositTips &&
                                                        <div
                                                            className="tipsText">{t("payment:popup.deposit")}</div>
                                                        }
                                                    </dt>
                                                    <dd>{Helper.numeralMoney(this.state.calculation.deposit)}</dd>

                                                    <dt>{t("space:price.rental_deposit")}</dt>
                                                    <dd>{Helper.numeralMoney(this.state.calculation.rentaldeposit)}</dd>

                                                    {/*<dt>HKD 20 x {props.calculation.hours} hour(s)</dt>*/}
                                                    {/*<dd>HKD {Helper.number_format(props.calculation.amount)}</dd>*/}
                                                    {/*<div className="sep-0" />*/}
                                                    <dt>
                                                        <b>{t("space:price.total")}</b>
                                                        <button className="tips"
                                                                onClick={() => this.changeTips('TotalTips')}
                                                        />
                                                        {this.state.TotalTips &&
                                                        <div
                                                            className="tipsText">{t("payment:popup.deposit")}</div>
                                                        }
                                                    </dt>
                                                    <dd>
                                                        <b>{Helper.numeralMoney(this.state.calculation.totalcost)}</b>
                                                    </dd>
                                                </dl>

                                                {this.state.calculation.months > 1 && this.state.calculation.totalcost !== (this.state.calculation.cost.first + this.state.calculation.deposit + this.state.calculation.rentaldeposit) &&
                                                <div>
                                                    <div className="sep-20"/>

                                                    <hr/>

                                                    <div className="sep-20"/>

                                                    <dl className="clearfix">
                                                        <dt>{t("space:price.firstMonth")}</dt>
                                                        <dd>{Helper.numeralMoney(this.state.calculation.org_first_month)}</dd>

                                                        {typeof (this.state.calculation.promotion) !== "undefined" &&
                                                        <dl className="clearfix">
                                                            <dt>{t("space:price.discount")}</dt>
                                                            <dd>- {Helper.numeralMoney(this.state.calculation.discount)}</dd>
                                                        </dl>
                                                        }

                                                        <dt>{t("space:price.deposit")}</dt>
                                                        <dd>{Helper.numeralMoney(this.state.calculation.deposit)}</dd>

                                                        <dt>
                                                            {t("space:price.rental_deposit")}
                                                        </dt>
                                                        <dd>{Helper.numeralMoney(this.state.calculation.rentaldeposit)}</dd>

                                                        <dt><b>{t("space:price.firstPayment")}</b></dt>
                                                        <dd>
                                                            <b>{Helper.numeralMoney((this.state.calculation.cost.first + this.state.calculation.deposit + this.state.calculation.rentaldeposit))}</b>
                                                        </dd>
                                                    </dl>

                                                    <div className="sep-20"/>

                                                    <hr/>

                                                    <div className="sep-20"/>

                                                    <dl className="clearfix">
                                                        <dt><b>{t("space:price.monthlyPayment")}</b>
                                                            <button className="tips"
                                                                    onClick={() => this.changeTips('InstantBookingTips')}
                                                            />
                                                            {this.state.InstantBookingTips &&
                                                            <div
                                                                className="tipsText">{t("payment:popup.monthlyPayment")}</div>
                                                            }
                                                        </dt>
                                                        <dd>
                                                            <b>{Helper.numeralMoney(this.state.calculation.recurring_cost)}</b>
                                                        </dd>
                                                    </dl>
                                                </div>
                                                }
                                            </div>
                                        )
                                    }


                                    {/*<div className="sep-0" />*/}
                                    {/*<dt>Deposit</dt>*/}
                                    {/*<dd>HKD 1,900</dd>*/}
                                    {/*<div className="sep-0" />*/}
                                    {/*<dt><b>Total Amount</b></dt>*/}
                                    {/*<dd><b>HKD 3,800</b></dd>*/}
                                </div>
                            </div>
                        </div>

                        <div className="fifty left">
                            <Formik
                                initialValues={{
                                    paymentID: this.state.defaultCreditCardID,
                                    cardNumber: '',
                                    holderName: '', //'Jabie',
                                    expiryDateYY: '',
                                    expiryDateMM: '',
                                    CVC: '',
                                    agree: true,

                                    bookingID: this.state.bookingID,
                                    type: this.state.type,
                                    startDate: this.state.startDate,
                                    endDate: this.state.endDate,
                                    startTime: (this.state.startTime ? this.state.startTime : ""),
                                    endTime: (this.state.endTime ? this.state.endTime : ""),
                                    uid: this.state.uid,
                                }}
                                validationSchema={this.schema}
                                onSubmit={this.handleSubmit}
                                component={this.form}
                            />
                        </div>

                        <div className="sep-30"/>
                    </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => (
    {
        members: state.members,
        listParkingSpace: state.listParkingSpace
    }
);

export default withTranslation()(connect(mapStateToProps)(UpdatePayment));
