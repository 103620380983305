import React, { Component } from 'react';
import {withTranslation} from 'react-i18next';

class ErrorMessages extends Component {
	render() {
		const {
			t,
			// i18n
		} = this.props;

		if (!this.props.errors.length)
			return null;

		let output = [];

		this.props.errors.forEach((e, i) => {
			output.push(<li key={"error_" + i}>{t(e.message)}</li>)
		})

		return (
			<div className="ErrorMessages">
				<ul>
					{output}
				</ul>
			</div>
		);
	}
}

export default withTranslation()(ErrorMessages);
