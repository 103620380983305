import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

import TermsZHHK from '../../components/10General/Terms_zh_HK';
import TermsENUS from '../../components/10General/Terms_en_US';

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as InviteeActionCreators from '../actions/invitee';

import {getTnc} from '../../utils/04Api/Tnc';

class Terms extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tnc: null,
        }
    }

    componentDidMount() {

        this.ApiGetTnc(this.ApiGetTnc);
    }

    ApiGetTnc = (errorCallback) => {

        let callback = (res) => {

            if (res.status === 200) {

                this.setState({
                    ...this.state,
                    tnc: res.body
                })
            }
        }

        getTnc('terms_and_conditions', callback, errorCallback);
    }

    render() {

        const {
            t,
            i18n
        } = this.props;

        // console.log(this.state.tnc);

        return (
            <div className="wrapper-container media">

                <div className="page-title"><h2>{t("Menu.TermsAndConditions")}</h2></div>

                <div className="sep-0"/>

                <div className="containerMain">
                    <div className="content clearfix">
                        {i18n.language === 'zh-HK'
                            ? this.state.tnc !== null ? <TermsZHHK content={this.state.tnc['zh-hk'].tnc_content_chi.value}/> : ''
                            : this.state.tnc !== null ? <TermsENUS content={this.state.tnc['en-us'].tnc_content_eng.value}/> : ''
                        }
                    </div>
                </div>
            </div>
        );
    }
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default withTranslation()(Terms);
// connect(mapStateToProps)(About);