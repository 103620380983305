import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
import {Redirect} from 'react-router';
import {withTranslation} from 'react-i18next';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as MembersActionCreators from '../../../actions/members';
import ReactPixel from "react-facebook-pixel";

class MemberCreateAccountCorporateComplete extends Component {

    constructor(props) {

        super(props);

        const {dispatch} = props;

        // DISPATCH TO ACTIONS
        this.ResetRegistration = bindActionCreators(MembersActionCreators.ResetRegistration, dispatch);

        // clear the registered record flag
        this.ResetRegistration();
    }

    componentDidMount() {

        ReactPixel.track('Lead', {content_category: 'Corporate', content_name: 'Email'});
    }

    render() {
        const {
            t,
            i18n
        } = this.props;

        if (this.props.params === null) {
            return <Redirect to={"/" + i18n.language + "/register-individual"}/>;
        }

        return (
            <div className="wrapper-container memberCreateAccount padding">

                {/*<div className="page-title"><h2>Contact Us</h2></div>*/}

                {/*<div className="sep-0"/>*/}

                <div className="containerMain borderTop">
                    <div className="centerBox clearfix">

                        <div className="sep-20"/>

                        <h4>{t("registerCorporate:corporateComplete.description")}</h4>

                        <div className="sep-20"/>

                        <div>{t("registerCorporate:corporateComplete.step1", {"email": this.props.params.email})}</div>

                        <div className="sep-20"/>

                        <div>{t("registerCorporate:corporateComplete.step2")}</div>

                        <div className="sep-20"/>

                        <div>{t("registerCorporate:corporateComplete.inform")}</div>

                        <div className="sep-20"/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {}
);

export default withTranslation()(connect(mapStateToProps)(MemberCreateAccountCorporateComplete));
