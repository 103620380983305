import { apiFetch, apiPost } from "./General";

export const getWishList = (accessToken, callback, errorCallback) => {
    let params = {
        access_token: accessToken,
    }
    apiFetch('wishlist', params, callback, errorCallback);
}

export const addWishList = (accessToken, spaceId, callback, errorCallback) => {
    let params = {
        sid: spaceId
    }
    apiPost('wishlist?access_token=' + accessToken, params, callback, errorCallback);
}

export const removeWishList = (accessToken, spaceId, callback, errorCallback) => {
    let params = {
        sid: spaceId
    }
    apiPost('wishlist/remove?access_token=' + accessToken, params, callback, errorCallback);
}
