import * as ListParkingSpaceActionTypes from '../actiontypes/listParkingSpace';

const initialState = {
    StepOne: [],
    StepTwo: [],
    StepThree: [],
    Preview: []
}

export default function ListParkingSpaceReducer(state = initialState, action) {
    switch (action.type) {
        /* STEP ONE */
        case ListParkingSpaceActionTypes.SubmitStep1: {
            return {
                ...state,
                StepOne: action.data
            }
        }

        /* STEP TWO */
        case ListParkingSpaceActionTypes.SubmitStep2: {
            return {
                ...state,
                StepTwo: action.data
            }
        }

        /* STEP THREE */
        case ListParkingSpaceActionTypes.SubmitStep3: {
            return {
                ...state,
                StepThree: action.data
            }
        }

        /* ClearListParkingSpace */
        case ListParkingSpaceActionTypes.ClearListParkingSpace: {
            return {
                ...state,
                StepOne: [],
                StepTwo: [],
                StepThree: []
            }
        }

        default:
            return state;
    }
}
