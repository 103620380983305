import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class ErrorMessage extends Component {
    render(){
        const {
            t,
            // i18n,
        } = this.props;

        return(
            <span className="errorMessage">{ t(this.props.message) }</span>
        )
    }
}

export default withTranslation()(ErrorMessage);