import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {mongoIdTimestamp} from "../../utils/00General/Helpers";
import AddReviews from "../../components/103Parts/AddReviews";

import {getBookingById} from "../../utils/04Api/Booking";
import {getUserInfo} from "../../utils/04Api/User";
import {createReviews, updateReviews} from "../../utils/04Api/Reviews";
import {connect} from "react-redux";

import {Redirect} from 'react-router';
import ErrorMessage from "../../components/103Parts/ErrorMessage";

class Review extends Component {
    constructor(props) {

        super(props);

        this.state = {
            score: 1,
            comment: '',
            bookingID: this.props.bookingID,
            reviewed: false,
            loading: true,
            invalid: false,
            isBooker: false,
            errors: {
                comment: false,
                rate: false,
            }
        };
    }

    componentDidMount = () => {

        this.handleGetBooking();
    }

    changeStateLevelOne = (data1, value) => {
        this.setState({
            [data1]: value
        });
    }

    changeStateLevelTwo = (data1, data2, value, cb) => {
        this.setState({
            [data1]: {
                ...this.state[data1],
                [data2]: value
            }
        }, () => {
            if (typeof (cb) === "function")
                cb();
        })
    }

    handleGetBooking = () => {

        let callback = (res) => {

            let body = res.body;

            // console.log(body);

            if (body.status === 'depositReleasing' ||
                body.status === 'complete' ||
                body.status === 'holdDeposit' ||
                body.status === 'completeWithDeposit') {

                this.setState({
                    ...this.state,
                    booking: body,
                }, () => {

                    this.handleGetUser();
                });
            } else {

                this.setState({
                    ...this.state,
                    invalid: true,
                });
            }
        }

        getBookingById(this.props.members.Login.userToken, this.state.bookingID, callback);
    }

    handleGetUser = () => {

        let callback = (res) => {

            let body = res.body;

            this.setState({
                ...this.state,
                image: body.image,
                loading: false,
            });
        }

        if (
            this.props.members.Login.userId === this.state.booking.booker._id &&
            typeof (this.state.booking.review) !== 'undefined' &&
            this.state.booking.review.status === 'booker_comment_rejected'
        ) {

            this.setState({
                ...this.state,
                isBooker: true,
            });

            getUserInfo(this.state.booking.owner._id, callback);
        } else if (
            this.props.members.Login.userId === this.state.booking.owner._id &&
            typeof (this.state.booking.review) !== 'undefined' &&
            this.state.booking.review.oComment !== '' &&
            this.state.booking.review.status === 'owner_comment_rejected'
        ) {

            getUserInfo(this.state.booking.booker._id, callback);
        } else if (
            this.props.members.Login.userId === this.state.booking.booker._id &&
            typeof (this.state.booking.review) === 'undefined'
        ) {

            this.setState({
                ...this.state,
                isBooker: true,
            });

            getUserInfo(this.state.booking.owner._id, callback);
        } else if (
            this.props.members.Login.userId === this.state.booking.owner._id &&
            typeof (this.state.booking.review) !== 'undefined' &&
            this.state.booking.review.oComment === ''
        ) {

            getUserInfo(this.state.booking.booker._id, callback);
        } else {

            this.setState({
                ...this.state,
                invalid: true,
            });
        }
    }

    handleCreateOrUpdateReviews = () => {

        let error = false;

        if (this.state.comment === '') {

            this.changeStateLevelTwo('errors', 'comment', true);

            error = true;
        }

        if (this.state.score <= 0) {

            this.changeStateLevelTwo('errors', 'rate', true);

            error = true;
        }

        if (!error) {

            if (!this.state.isBooker && typeof (this.state.booking.review) !== 'undefined' && this.state.booking.review.status === 'booker_comment_rejected') {

                let callback = (res) => {

                    this.setState({
                        ...this.state,
                        reviewed: true,
                    });
                }

                updateReviews(this.props.members.Login.userToken, this.state.booking._id, this.state.comment, callback);
            } else if (this.state.isBooker && typeof (this.state.booking.review) !== 'undefined' && this.state.booking.review.status === 'owner_comment_rejected') {

                let callback = (res) => {

                    this.setState({
                        ...this.state,
                        reviewed: true,
                    });
                }

                createReviews(this.props.members.Login.userToken, this.state.booking._id, this.state.score, this.state.comment, callback);
            } else if (!this.state.isBooker && typeof (this.state.booking.review) !== 'undefined') {

                let callback = (res) => {

                    this.setState({
                        ...this.state,
                        reviewed: true,
                    });
                }

                updateReviews(this.props.members.Login.userToken, this.state.booking._id, this.state.comment, callback);
            } else if (this.state.isBooker && typeof (this.state.booking.review) === 'undefined') {

                let callback = (res) => {

                    this.setState({
                        ...this.state,
                        reviewed: true,
                    });
                }

                createReviews(this.props.members.Login.userToken, this.state.booking._id, this.state.score, this.state.comment, callback);
            }
        }
    }

    render() {

        // console.log("review state", this.state);

        const {
            t,
            i18n
        } = this.props;

        if (this.state.invalid) {
            return <Redirect to={"/" + i18n.language + "/space/" + this.state.booking.space._id}/>;
        }

        if (this.state.loading) {
            return (
                <div>{t("common:Loading")}</div>
            )
        }

        let booking = this.state.booking;

        return (
            <div className="wrapper-container review">
                <div className="containerMain">
                    <div className="spaceInfo clearfix">
                        <div className="person right">
                            <div className="image">
                                <img width="120" height="120"
                                     src={typeof (this.state.image) != "undefined" ? this.state.image : require('../../images/blank.png')}
                                     alt=""/>
                            </div>

                            <h3 className="name">{this.state.isBooker ? booking.owner.name : booking.booker.name}</h3>

                            <div className="memberSince">
                                {t("contactOwner:memberSince")}<span>{mongoIdTimestamp(this.props.members.Login.userId === booking.booker._id ? booking.owner._id : booking.booker._id, "YYYY-MM-DD")}</span>{t("contactOwner:memberBecome")}
                            </div>
                        </div>
                        <div className="space left">
                            <h3>{booking.space.title}</h3>
                            <h4>{booking.space.address}</h4>
                            <span className="spaceId">{t("contactOwner:spaceID")} - {booking._id}</span>
                        </div>
                    </div>

                    <div className="sep-20"/>

                    <div className="reviewInfo clearfix">

                        <div className="reviewInfoContent">

                            {this.state.reviewed &&
                            <div className="reviewed">
                                <h4>{t("review:thankyou.title")}</h4>
                                <div className="sep-20"/>
                                <div>{t("review:thankyou.content")}</div>
                            </div>
                            }

                            {!this.state.reviewed &&
                            <div>
                                <b>{!this.state.isBooker ? t("review:owner.write", {"name": booking.booker.name}) : t("review:booker.write", {"name": booking.owner.name})}</b>

                                {!this.state.isBooker &&
                                <div>
                                    <div>{booking.review.bComment}</div>
                                </div>
                                }

                                <div className="sep-20"/>


                                {this.state.isBooker &&
                                <div>
                                    <div>{t("review:booker.expired")}</div>
                                    <div className="sep-20"/>
                                </div>
                                }

                                <div>{this.state.isBooker ? t("review:owner.14days", {"name": booking.booker.name}) : t("review:booker.14days")}</div>
                                <div className="sep-20"/>

                                {this.state.isBooker &&
                                <div>
                                    <div>{t("review:booker.rate")}</div>
                                    <div className="sep-20"/>

                                    <AddReviews
                                        score={this.state.score}
                                        changeStateLevelOne={this.changeStateLevelOne}
                                    />
                                    <div className="sep-20"/>
                                </div>
                                }

                                <div>{!this.state.isBooker ? t("review:owner.briefly", {"name": booking.booker.name}) : t("review:booker.briefly", {"name": booking.owner.name})}</div>
                                <div className="sep-20"/>

                                <div className="form">

                                <textarea className="messageEntry"
                                          value={this.state.comment}
                                          onChange={(e) => this.changeStateLevelOne("comment", e.target.value)}
                                />

                                    <div className="sep-20"/>

                                    {this.state.errors.comment &&
                                    <div>
                                        <ErrorMessage message={t('review:errors.comment')}/>
                                        <div className="sep-10"/>
                                    </div>
                                    }

                                    {this.state.errors.rate &&
                                    <div>
                                        <ErrorMessage message={t('review:errors.rate')}/>
                                        <div className="sep-10"/>
                                    </div>
                                    }

                                    <div className="button" onClick={() => this.handleCreateOrUpdateReviews()}>
                                        {t("review:submit")}
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {
        members: state.members,
    }
);

export default withTranslation()(connect(mapStateToProps)(Review));
