import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class ViewChangeButton extends Component {
    render() {

        const {
            t,
            // i18n,
        } = this.props;

        return (
            <div className={"toggleViews " + (this.props.showView === 'map' ? 'absolute' : '')}>
                <ul className="clearfix">
                    <li style={{marginRight: 10}}>
                        <Link to="/" className={(this.props.filter.bcode ? 'active' : '')} onClick={(e) => {e.preventDefault();this.props.handleFilterBoolean('bcode');}}>{t("search:filterView.bcode")}</Link>
                    </li>

                    {this.props.showView === 'list' &&
                        <li className="filter">
                            <Link to="/" className={(this.props.desktopFilter.showDesktopFilter ? 'active' : '')} onClick={(e) => this.props.handleDesktopFilter('toggleDesktopFilter', e)}>{t("search:filterView.title")}</Link>
                        </li>
                    }

                    <li>
                        <Link to="/" className={(this.props.showView === 'map' ? 'active' : '')} onClick={(e) => this.props.handleViewChange(e, 'map')}>{t("search:mapView")}</Link>
                    </li>
                    <li>
                        <Link to="/" className={(this.props.showView === 'list' ? 'active' : '')} onClick={(e) => this.props.handleViewChange(e, 'list')}>{t("search:listView")}</Link>
                    </li>
                </ul>
            </div >
        )
    }
}

export default withTranslation()(ViewChangeButton);
