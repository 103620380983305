import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

class Tags extends Component {
    render() {

        const {
            t,
            // i18n
        } = this.props;

        return (
            <ul className="tags clearfix">
                {this.props.tags.indexOf('NearEntrance') > -1 ? <li>{t("space:nearOptions.entrance")}</li> : ''}
                {this.props.tags.indexOf('WideLoad') > -1 ? <li>{t("space:nearOptions.wideLoad")}</li> : ''}
                {this.props.tags.indexOf('NearLift') > -1 ? <li>{t("space:nearOptions.lift")}</li> : ''}
                {this.props.tags.indexOf('NearStairs') > -1 ? <li>{t("space:nearOptions.stairs")}</li> : ''}
                {this.props.tags.indexOf('NearBlock') > -1 ?
                    <li>{t("space:nearOptions.block", {block: this.props.nearBlockText})}</li> : ''}
                {this.props.tags.indexOf('CorneredSpace') > -1 ? <li>{t("space:nearOptions.cornered")}</li> : ''}
            </ul>
        )
    }
}

export default withTranslation()(Tags);