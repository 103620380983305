import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import Newsletter from './newsletter';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import {getUIContent} from "../../utils/04Api/UIContent";
import {getLogo} from "../../utils/04Api/PartnerLogo";

class Footer extends Component {

    constructor(props) {

        super(props)

        // const { dispatch } = props;

        // DISPATCH TO ACTIONS
        // this.Contact = bindActionCreators(ContactActionCreators.Contact, dispatch);

        this.state = {
            footer: {
                copy: {}
            },
            logo: [],
            retryCount: 0
        }
    }

    componentDidMount() {

        this.ApiGetFooter(this.ApiGetFooter);
        this.ApiGetLogo(this.ApiGetLogo);
    }

    ApiGetFooter = (errorCallback) => {

        let callback = (res) => {

            let en_copy = '', tc_copy = '';

            if (res.status === 200) {

                Object.keys(res.body).forEach((key) => {

                    let row_data = res.body[key];

                    switch (row_data.lang) {

                        case 'en-US':
                            en_copy = row_data.data.text_copyright;
                            break;
                        case 'zh-HK':
                            tc_copy = row_data.data.text_copyright;
                            break;
                        default:
                            break;
                    }
                });

                this.setState({
                    ...this.state,
                    footer: {
                        copy: {
                            'en-US': en_copy,
                            'zh-HK': tc_copy,
                        }
                    }
                });
            }
        }

        getUIContent('footer', callback, errorCallback);
    }

    ApiGetLogo = (errorCallback) => {

        let callback = (res) => {
            if (res.status === 200) {
                this.setState({
                    ...this.state,
                    logo: res.body
                })
            }
        }
        getLogo(callback, () => {
            this.setState({retryCount: this.state.retryCount + 1}, () => {
                if (this.state.retryCount <= 3){
                    if (typeof errorCallback === "function") errorCallback();
                }
            })
        });
    }

    render() {
        const {
            t,
            i18n
        } = this.props;

        let debug = false;

        return (
            <div className="wrapper-footer">

                <div className="sep-40"/>

                <div className="footer">
                    <div className="inner clearfix">
                        <div className="right-part right clearfix">
                            <Newsletter/>
                        </div>

                        <div className="left-part desktop left clearfix">
                            <div className="forty left">
                                <ul>
                                    <li><h3>{t('General.LetsPark')}</h3></li>
                                    <li><Link to={"/" + i18n.language + "/discover"}>{t("Menu.Discover")}</Link></li>
                                    {/*<li><Link to={"/" + i18n.language + "/media"}>{t("Menu.MediaCoverage")}</Link></li>*/}
                                    <li><Link to={"/" + i18n.language + "/helpCenter"}>{t("Menu.HelpCentre")}</Link>
                                    </li>
                                    <li><Link to={"/" + i18n.language + "/contact"}>{t("Menu.ContactUs")}</Link></li>
                                    <br/><br/>

                                    {debug ?
                                        <div>
                                            <li></li>
                                            <li><Link to={"/" + i18n.language + "/hotSpaces"}> HotSpaces</Link></li>
                                            <br/><br/>

                                            <li><Link to={"/" + i18n.language + "/memberGetStarted"}
                                                      className="color-orange">MemberGetStarted</Link></li>
                                            <li><Link to={"/" + i18n.language + "/login"}
                                                      className="color-orange">MemberLogin</Link></li>
                                            <li><Link to={"/" + i18n.language + "/register"}
                                                      className="color-orange">MemberCreateAccountStart</Link></li>
                                            <li><Link to={"/" + i18n.language + "/register-individual"}
                                                      className="color-orange">MemberCreateAccount</Link></li>
                                            <li><Link to={"/" + i18n.language + "/register-success"}
                                                      className="color-orange">MemberCreateAccountComplete</Link></li>
                                            <li><Link to={"/" + i18n.language + "/memberMobileVerification"}
                                                      className="color-orange">MemberMobileVerification</Link></li>
                                            <br/><br/>
                                            <li><Link
                                                to={"/" + i18n.language + "/memberBookings"}> MemberBookings</Link></li>
                                            <li><Link
                                                to={"/" + i18n.language + "/memberListedSpaces"}> MemberListedSpaces</Link>
                                            </li>
                                            <li><Link to={"/" + i18n.language + "/memberHistory"}> MemberHistory</Link>
                                            </li>
                                            <li><Link to={"/" + i18n.language + "/memberProfile"}> MemberProfile</Link>
                                            </li>
                                            <li><Link to={"/" + i18n.language + "/memberCredit"}> MemberCredit</Link>
                                            </li>
                                            <li><Link
                                                to={"/" + i18n.language + "/settings"}> MemberSettings</Link></li>

                                            <br/><br/>

                                            <li><Link to={"/" + i18n.language + "/stepOne"} className="color-red">Step
                                                1</Link></li>
                                            <li><Link to={"/" + i18n.language + "/stepTwo"} className="color-red">Step 2
                                                (FormHourly)</Link></li>
                                            <li><Link to={"/" + i18n.language + "/stepThree"} className="color-red">Step
                                                3</Link></li>
                                            {/* <li><Link to="/" + i18n.language + "/preview" className="color-red">Step 4</Link></li> */}
                                            <li><Link to={"/" + i18n.language + "/listASpaceConfirm"}>List A Space
                                                Confirm</Link></li>
                                            <br/><br/>

                                            <li><Link to={"/" + i18n.language + "/spaceMap"}>SpaceMap</Link></li>
                                            <li><Link to={"/" + i18n.language + "/findYourSpace"}
                                                      className="color-green">FindYourSpace</Link></li>
                                            <li><Link to={"/" + i18n.language + "/space"}
                                                      className="color-green">SpaceOne</Link></li>
                                            <li><Link to={"/" + i18n.language + "/spaceTwo"}
                                                      className="color-green">SpaceTwo</Link></li>
                                            <li><Link to={"/" + i18n.language + "/spaceThree"}
                                                      className="color-green">SpaceThree</Link></li>
                                            <li><Link to={"/" + i18n.language + "/detailMonthly"}
                                                      className="color-green">DetailMonthly</Link></li>
                                            <li><Link to={"/" + i18n.language + "/detailHourly"}
                                                      className="color-green">DetailHourly</Link></li>
                                            <li><Link to={"/" + i18n.language + "/detailTimeshare"}
                                                      className="color-green">DetailTimeshare</Link></li>
                                            <li><Link to={"/" + i18n.language + "/payment"}
                                                      className="color-green">Payment</Link></li>
                                            <li><Link
                                                to={"/" + i18n.language + "/landlordProfile"}>LandlordProfile</Link>
                                            </li>
                                            {/*<li><Link to={"/" + i18n.language + "/findYourSpaceConfirm"}>Find Your Space*/}
                                            {/*    Confirm</Link></li>*/}
                                            {/*<br/><br/>*/}

                                            <li><Link to={"/" + i18n.language + "/inbox"}>Messenger</Link></li>
                                            <li><Link to={"/" + i18n.language + "/messages"}>Messages</Link></li>
                                            <br/><br/>

                                            <li><Link to={"/" + i18n.language + "/contact"}>Contact</Link></li>
                                            <li><Link to={"/" + i18n.language + "/media"}>Media</Link></li>
                                            <br/><br/>

                                            {/*<li><Link to={"/" + i18n.language + "/results"}>Application Results</Link></li>*/}
                                        </div>
                                        : ''}
                                </ul>
                            </div>
                            <div className="sixty left">
                                <ul>
                                    <li><h3>{t("General.Others")}</h3></li>
                                    <li><Link to={"/" + i18n.language + "/terms"}>{t("Menu.TermsAndConditions")}</Link>
                                    </li>
                                    <li><Link
                                        to={"/" + i18n.language + "/cancellation"}>{t("Menu.CancellationPolicy")}</Link>
                                    </li>
                                    <li><Link to={"/" + i18n.language + "/privacy"}>{t("Menu.PrivacyPolicy")}</Link>
                                    </li>
                                    <li><Link to={"/" + i18n.language + "/disclaimer"}>{t("Menu.Disclaimer")}</Link>
                                    </li>
                                    <li><Link
                                        to={"/" + i18n.language + "/parkerProtectionGuarantee"}>{t("Menu.ParkerProtectionGuarantee")}</Link>
                                    </li>
                                    {
                                        this.state.logo.length > 0 && (
                                            <li>
                                                <Link to={"/" + i18n.language + "/partners"}>{t("Menu.Partners")}</Link>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>

                            <div className="sep-0"/>

                            <div className="full left bottom">
                                <div className="copy-right">
                                    <span>&copy; {t("General.AllRightsReserved")}</span>
                                </div>

                                {/*<div className="footer-app">*/}
                                {/*<span>{t("General.NowAvailableAt")}</span>*/}
                                {/*<img src={require('../../images/mobile/General/menu-and-footer/btn_appstore.png')}*/}
                                {/*     alt="" className="ios"/>*/}
                                {/*<img src={require('../../images/mobile/General/menu-and-footer/btn_googleplay.png')}*/}
                                {/*     alt="" className="android"/>*/}
                                {/*</div>*/}
                            </div>
                        </div>

                        <div className="left-part mobile left clearfix">
                            <Accordion>
                                <AccordionItem>
                                    <AccordionItemTitle>
                                        <h3>{t('General.LetsPark')}</h3>
                                    </AccordionItemTitle>
                                    <AccordionItemBody>
                                        <Link to={"/" + i18n.language + "/discover"}>{t("Menu.Discover")}</Link>
                                        {/*<Link to={"/" + i18n.language + "/media"}>{t("Menu.MediaCoverage")}</Link>*/}
                                        <Link to={"/" + i18n.language + "/helpCenter"}>{t("Menu.HelpCentre")}</Link>
                                        <Link to={"/" + i18n.language + "/contact"}>{t("Menu.ContactUs")}</Link>
                                    </AccordionItemBody>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemTitle>
                                        <h3>{t("General.Others")}</h3>
                                    </AccordionItemTitle>
                                    <AccordionItemBody>
                                        <Link to={"/" + i18n.language + "/terms"}>{t("Menu.TermsAndConditions")}</Link>
                                        <Link
                                            to={"/" + i18n.language + "/cancellation"}>{t("Menu.CancellationPolicy")}</Link>
                                        <Link to={"/" + i18n.language + "/privacy"}>{t("Menu.PrivacyPolicy")}</Link>
                                        <Link to={"/" + i18n.language + "/disclaimer"}>{t("Menu.Disclaimer")}</Link>
                                        <Link
                                            to={"/" + i18n.language + "/parkerProtectionGuarantee"}>{t("Menu.ParkerProtectionGuarantee")}</Link>
                                    </AccordionItemBody>
                                </AccordionItem>
                            </Accordion>

                            <div className="sep-20"/>
                            <div className="sep-20"/>

                            <div className="copy-right">
                                {/*<span>&copy; {t("General.AllRightsReserved")}</span>*/}
                                {this.state.footer.copy[i18n.language]}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Footer);
