import {apiFetch, apiPost, buildParam} from "./General";

export const register = (body, callback, errorCallback,) => {
    apiPost('users', body, callback, errorCallback);
}

export const emailLogin = (email, password, callback, errorCallback) => {
    let body = {
        email: email,
        passwd: password
    }
    apiPost('auth', body, callback, errorCallback);
}

export const smsLoginGetToken = (phone, callback) => {
    let body = {
        phone: String(phone),
    }
    apiPost('auth/sms', body, callback);
}

export const smsLoginValidate = (phone, token, callback) => {
    let body = {
        phone: String(phone),
        token: token,
    }
    apiPost('auth/smsvalid?' + buildParam(body), body, callback);
}

export const getMe = (accessToken, callback, errorCallback) => {
    let params = {
        access_token: accessToken,
    }
    apiFetch('users/me/', params, callback, errorCallback);
}

export const updateUserInfo = (accessToken, body, callback, errorCallback) => {
    apiPost('users/update?access_token=' + accessToken, body, callback, errorCallback);
}

//2019-08-08 Samson- for profile email modification
export const request_update_email = (accessToken, body, callback, errorCallback) => {
    apiPost('users/request_update_email?access_token=' + accessToken, body, callback, errorCallback);
};

export const email_validate_update = (body, callback, errorCallback) => {
    apiPost('users/email_validate_update', body, callback, errorCallback);
};
//End of Samson- for profile email modification

export const updateCarplate = (accessToken, body, callback, errorCallback) => {
    apiPost('users/updatecarplate?access_token=' + accessToken, body, callback, errorCallback);
}

export const updateOctopusCard = (accessToken, body, callback, errorCallback) => {
    apiPost('users/updateoctopuscard?access_token=' + accessToken, body, callback, errorCallback);
}

export const updateAlert = (accessToken, body, callback, errorCallback) => {
    apiPost('users/updatealert?access_token=' + accessToken, body, callback, errorCallback);
}

// export const getEmailToken = (email, rurl, callback) => {
//     let body = {
//         email: email,
//         rurl: rurl,
//     }
//     apiPost('auth/email?' + buildParam(body), body, callback);
// }

export const emailValidate = (uid, token, callback, errorCallback) => {
    let body = {
        uid: uid,
        token: token,
    }
    apiPost('auth/emailvalid?' + buildParam(body), body, callback, errorCallback);
}

export const getSmsToken = (phone, callback) => {
    let body = {
        phone: phone,
    }
    apiPost('auth/smslite', body, callback);
}
//
// export const smsValidate = (phone, token, callback) => {
//     let body = {
//         phone: phone,
//         token: token,
//     }
//     apiPost('auth/smslitevalid?' + buildParam(body), body, callback);
// }

export const forgetPasswordEmailValidate = (email, language, callback, errorCallback) => {
    const body = {
        email: email,
        language: language
    }
    apiPost("auth/forgetpassword", body, callback, errorCallback);
}

export const forgetPasswordTokenValidate = (uid, token, callback, errorCallback) => {
    const body = {
        uid: uid,
        token: token
    };

    apiPost("auth/fpvalid?" + buildParam(body), body, callback, errorCallback);
};

export const resetPassword = (params, passwd, callback, errorCallback) => {
    const body = {
        passwd: passwd,
    };
    apiPost("auth/fpupdate?" + buildParam(params), body, callback, errorCallback);
};

export const getUserInfo = (user_id, callback, errorCallback) => {
    apiFetch("users/info/" + user_id, {}, callback, errorCallback);
}

export const unreadMessages = (accessToken, cb, ecb) => {
    apiPost('users/unreadMessages/?access_token=' + accessToken, {}, cb, ecb);
}

// export const readRoomMessages = (accessToken, rid, cb, ecb) => {
//     apiPost('users/readRoomMessages/' + rid + '?access_token=' + accessToken, {}, cb, ecb);
// }

export const logout = (accessToken, cb, ecb) => {
    apiPost('auth/logout?access_token=' + accessToken, {}, cb, ecb);
}