import React, {Component} from 'react';
// import {Redirect} from 'react-router';

import {connect} from 'react-redux';

import ThreeSteps from '../../../components/04ListASpace/ThreeSteps';
import PrevNext from '../../../components/04ListASpace/PrevNext';
import ErrorMessages from '../../../components/04ListASpace/ErrorMessages';

// import * as Config from "../../../config";
import {withTranslation} from 'react-i18next';
import {apiUploadImage} from "../../../utils/04Api/General";

// https://codepen.io/a9p6/pen/xrEKvP

function RotateIMG(props) {
    const {
        src,
        // width,
        // height,
        index,
        rotation,
        rotateImage,
        is_new,
    } = props;

    // console.log("img", props);
    const rotate = (degree) => {
        let newRotation = rotation + degree;
        if (newRotation >= 360) {
            newRotation = -360;
        }
        rotateImage(index, newRotation);
    }

    return (
        <div>
            <div className="imageWrap" style={{backgroundImage: "url(" + src + ")", transform: `rotate(${rotation}deg)`}}>
                {/*<img style={{transform: `rotate(${rotation}deg)`}} src={src}*/}
                {/*     className={(width > height ? 'wide' : 'long')} alt=""/>*/}
            </div>
            <div className="sep-10"/>
            {is_new &&
                <ul className="clearfix">
                    <li className="antiClockwise"
                        onClick={()=> rotate(-90)}
                    />
                    <li className="clockwise"
                        onClick={()=> rotate(90)}
                    />
                </ul>
            }
        </div>
    )
}

class StepThree extends Component {
    constructor(props) {
        super(props);

        if (props.data[3]) {
            this.state = props.data[3];
        } else {
            const space = props.space;

            let file = [],
                imagePreviewUrl = [];
            space.images.forEach((o, i)=> {
                file[(i+1)] = {
                    src: o,
                }

                imagePreviewUrl[(i+1)] = o;
            })

            let ownerName,
                ownerName2,
                moreOwner = false;
            space.owners.forEach((o, i)=> {
                if (i === 0)
                    ownerName = o;
                else {
                    ownerName2 = o;

                    if (o)
                        moreOwner = true;
                }
            })

            this.state = {
                file: file,
                imagePreviewUrl: imagePreviewUrl,
                ownerName: ownerName,
                ownerName2: ownerName2,
                Errors: [],
                imageSize: [],
                moreOwner: moreOwner
            };

            // console.log(this.state);
        }
    }

    submitting = false;

    _handleImageChange(e, id) {
        e.preventDefault();

        let reader = new FileReader();
        let uploaded_file = e.target.files[0];

        if (uploaded_file.size >= 10240000) {

            let errors = [];

            errors.push({field: "imageSize", message: this.props.t("listASpace:errors.step3.imageSize")});

            this.setState({
                ...this.state,
                imageSize: errors
            });
        } else {
            reader.onloadend = (file) => {

                let image = new Image();
                image.src = file.target.result;

                image.onload = () => {
                    // access image size here
                    // let width = image.width;
                    // let height = image.height;

                    this.setState({
                        file: {
                            ...this.state.file,
                            [id]: {
                                src: image.src,
                                // width: width,
                                // height: height,
                                rotation: 0,
                                uploaded_file: uploaded_file,
                                img: null,
                                new: true
                            }
                        },
                        imagePreviewUrl: {
                            ...this.state.imagePreviewUrl,
                            [id]: reader.result
                        }
                    });
                };

                // this.ApiUploadImage(id, uploaded_file);
            }

            reader.readAsDataURL(uploaded_file)
        }
    }

    uploadImages = (cb) => {
        this.uploadedFiles = [];
        this.ApiUploadImage(1, ()=>{
            this.ApiUploadImage(2, ()=>{
                this.ApiUploadImage(3, ()=>{
                    // console.log("ok");
                    // return;
                    cb();
                })
            })
        })
    }

    uploadedFiles = [];

    ApiUploadImage = (id, cb) => {
        // cb = () => {}
        console.log(id);
        if (typeof(this.state.file[id]) !== "undefined" && this.state.file[id]['new']) {
            let file = this.state.file[id];
            const image = file['uploaded_file'],
                rotation = file['rotation'];

            // console.log("upload img", this.state.file[id]);

            apiUploadImage(image, 'carpark', rotation, (res)=>{
                file['img'] = res.path;
                file['new'] = false;

                // this.uploadedFiles.push(file);
                // console.log("ddd", res);
                this.setState({
                    file: {
                        ...this.state.file,
                        [id]: file
                    },
                }, ()=>{
                    cb();
                });

            }, (err) => {
                console.log(err);
                alert("Cannot upload image");
                this.submitting = false;
                // cb()
            })
        } else {
            // console.log("bypass");
            cb()
        }
    }

    _removeImage(id) {
        this.setState({
            file: {
                ...this.state.file,
                [id]: ''
            },
            imagePreviewUrl: {
                ...this.state.imagePreviewUrl,
                [id]: ''
            }
        });
    }

    changeStateLevelOne = (data1, value) => {
        this.setState({
            [data1]: value
        })
    }

    handleExchange = (target) => {
        if (target === 'OneTwo') {
            this.setState({
                ...this.state,
                file: {
                    ...this.state.file,
                    1: this.state.file[2],
                    2: this.state.file[1],
                },
                imagePreviewUrl: {
                    ...this.state.imagePreviewUrl,
                    1: this.state.imagePreviewUrl[2],
                    2: this.state.imagePreviewUrl[1],
                }
            })
        } else if (target === "TwoThree") {
            this.setState({
                ...this.state,
                file: {
                    ...this.state.file,
                    2: this.state.file[3],
                    3: this.state.file[2],
                },
                imagePreviewUrl: {
                    ...this.state.imagePreviewUrl,
                    2: this.state.imagePreviewUrl[3],
                    3: this.state.imagePreviewUrl[2],
                }
            })
        }
    }

    handlemoreOwner = () => {
        this.setState({
            ...this.state,
            moreOwner: !this.state.moreOwner
        })
    }

    rotateImage = (index, rotation) => {
        let file = this.state.file;
        file[index].rotation = rotation;

        this.setState({
            ...this.state,
            file: file,
        })
    }

    submitForm = (e) => {
        e.preventDefault();
        //
        // console.log(this.state);
        // return;

        if (!this.submitting) {
            this.submitting = true;

            let errors = [];

            if (this.state.ownerName === "") {
                errors.push({field: "ownerName", message: this.props.t("listASpace:errors.step3.name")});
            }

            if (errors.length) {
                this.submitting = false;
                this.setState({
                    ...this.state,
                    Errors: errors
                })
            } else {
                this.uploadImages(() => {
                    this.props.submitStep(3, this.state);
                });
            }
        }
    };

    render() {
        const {
            t,
            // i18n
        } = this.props;
        // console.log(this.state);

        return (
            <div className="wrapper-container memberSettings" ref={this.props.pageTop}>
                <div className="page-title">
                    <h2>{t("listASpace:title")}</h2>
                    <div className="sep-0"/>
                    <ThreeSteps
                        selected={3}
                        stepsLeft={1}
                    />
                </div>

                <div className="containerMain">
                    <div className="content">

                        <h3>{t("listASpace:stepThree.title")}</h3>

                        <div className="sep-30"/>

                        <dl className="generalForm clearfix">
                            <div>
                                <dt>
                                    <p>{t("listASpace:stepThree.allowImage")}</p>
                                </dt>
                                <dd>
                                    <ul className="image-upload clearfix">
                                        <div className="oneThird">
                                            <div>
                                                <li>
                                                    <div>
                                                        <input type="file" id="file-1"
                                                               onChange={(e) => this._handleImageChange(e, 1)}/>
                                                        <label htmlFor="file-1"
                                                               className={(this.state.file[1] ? 'hide' : '')}/>
                                                        <div className={"remove " + (this.state.file[1] ? 'show' : '')}
                                                             onClick={(e) => this._removeImage(1)}/>
                                                        {this.state.file[1] ?
                                                            <RotateIMG
                                                                index={1}
                                                                src={this.state.imagePreviewUrl[1]}
                                                                rotation={this.state.file[1].rotation}
                                                                // width={this.state.file[1].width}
                                                                // height={this.state.file[1].height}
                                                                rotateImage={this.rotateImage}
                                                                is_new = {this.state.file[1].new}
                                                            /> : ''}
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                        <div className="oneThird">
                                            <div>
                                                <li>
                                                    <span className="exchange"
                                                          onClick={(e) => this.handleExchange('OneTwo')}
                                                    />
                                                    <div>
                                                        <input type="file" id="file-2"
                                                               onChange={(e) => this._handleImageChange(e, 2)}/>
                                                        <label htmlFor="file-2"
                                                               className={(this.state.file[2] ? 'hide' : '')}/>
                                                        <div className={"remove " + (this.state.file[2] ? 'show' : '')}
                                                             onClick={(e) => this._removeImage(2)}/>
                                                        {this.state.file[2] ?
                                                            <RotateIMG
                                                                index={2}
                                                                src={this.state.imagePreviewUrl[2]}
                                                                rotation={this.state.file[2].rotation}
                                                                // width={this.state.file[2].width}
                                                                // height={this.state.file[2].height}
                                                                rotateImage={this.rotateImage}
                                                                is_new = {this.state.file[2].new}
                                                            /> : ''}
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                        <div className="oneThird">
                                            <div>
                                                <li>
                                                    <span className="exchange"
                                                          onClick={() => this.handleExchange('TwoThree')}
                                                    />
                                                    <div>
                                                        <input type="file" id="file-3"
                                                               onChange={(e) => this._handleImageChange(e, 3)}/>
                                                        <label htmlFor="file-3"
                                                               className={(this.state.file[3] ? 'hide' : '')}/>
                                                        <div className={"remove " + (this.state.file[3] ? 'show' : '')}
                                                             onClick={(e) => this._removeImage(3)}/>
                                                        {this.state.file[3] ?
                                                            <RotateIMG
                                                                index={3}
                                                                src={this.state.imagePreviewUrl[3]}
                                                                rotation={this.state.file[3].rotation}
                                                                // width={this.state.file[3].width}
                                                                // height={this.state.file[3].height}
                                                                rotateImage={this.rotateImage}
                                                                is_new = {this.state.file[3].new}
                                                            /> : ''}
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                    </ul>
                                    <ErrorMessages errors={this.state.imageSize}/>
                                </dd>
                            </div>

                            <div className="sep-40"/>

                            <div>
                                <dt>
                                    <p>{t("listASpace:stepThree.ownerName")} *</p>
                                </dt>
                                <dd>
                                    <input type="text" name="ownerName"
                                           placeholder={t("listASpace:stepThree.name")}
                                           value={this.state.ownerName}
                                           readOnly={!this.props.newSpace}
                                           onChange={(e) => this.changeStateLevelOne('ownerName', e.target.value)}
                                    />
                                    <ErrorMessages errors={this.state.Errors}/>
                                    {this.props.newSpace && !this.state.moreOwner &&
                                    <span className="addmore"
                                          onClick={() => this.handlemoreOwner()}>{t("listASpace:stepThree.addMore")}</span>
                                    }
                                </dd>
                            </div>

                            {this.state.moreOwner &&
                            <div className="sep-40"/>
                            }

                            {this.state.moreOwner &&
                            <div>
                                <dt>
                                    <p>{t("listASpace:stepThree.ownerName")}</p>
                                </dt>
                                <dd>
                                    <input type="text" name="ownerName2"
                                           placeholder={t("listASpace:stepThree.name")}
                                           value={this.state.ownerName2}
                                           readOnly={!this.props.newSpace}
                                    onChange={(e) => this.changeStateLevelOne('ownerName2', e.target.value)}
                                    />
                                    {this.props.newSpace && this.state.moreOwner &&
                                    <span className="removemore"
                                          onClick={() => this.handlemoreOwner()}>{t("listASpace:stepThree.remove")}</span>
                                    }
                                </dd>
                            </div>
                            }
                        </dl>

                        <div className="sep-25"/>

                        <PrevNext
                            prevHas={true}
                            prevURL={"#"}
                            prevText={t("listASpace:back")}
                            nextURL={"#"}
                            nextText={t("listASpace:preview")}
                            progress="three"
                            value="3/3"
                            prevHandler={() => this.props.goToStep(2)}
                            nextHandler={(e) => this.submitForm(e)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {
        members: state.members,
    }
);

export default withTranslation()(connect(mapStateToProps)(StepThree));
