import React, {Component} from 'react';
import {Redirect} from 'react-router';
// import { Link } from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {connect} from 'react-redux';
import {getSpaceDetail, insertSpace, updateSpace} from "../../../utils/04Api/Space";

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import Preview from './Preview'
import Loading from "../../../components/10General/Loading";

class EditSpace extends Component
{
    constructor(props)
    {
        super(props)

        this.state={
            loading: true,
            redirect: false,
            formSubmitted: false,
            step: 1,
            data: {
                1: '',
                2: '',
                3: '',
            },
            body: {},
        }

        this.ApiUpdateSpace=this.ApiUpdateSpace.bind(this);
        this.setBody=this.setBody.bind(this);

    }

    pageTop=React.createRef();

    componentDidMount=() =>
    {
        this.ApiGetSpace();
    }

    ApiGetSpace=() =>
    {
        let callback=(obj) =>
        {
            if (obj.status === 200)
            {
                // console.log(obj.body);
                // console.log(obj.body.hourly);

                //
                // this.setState({
                //     ...this.state,
                //     loading: false,
                //     space: obj.body,
                // });
                if (obj.body.owner._id !== this.props.members.Login.userId)
                {//2019-07-24 samson unable to edit when status == "disapproved"
                    this.setState({
                        ...this.state,
                        redirect: true,
                    })
                }
                else
                {
                    this.setState({
                        ...this.state,
                        loading: false,
                        space: obj.body,
                    });
                }
            }
            else if (obj.status === 400)
            {
                this.setState({
                    ...this.state,
                    redirect: true
                });
            }
        }
        getSpaceDetail(this.props.space_id, callback);
    }

    goToStep=(step) =>
    {
        this.setState({
            ...this.state,
            step: step,
        }, this.scrollToTop)
    }

    submitStep=(step, d) =>
    {
        let data=this.state.data;
        data[step]=d;

        // console.log(step, d);

        this.setState({
            ...this.state,
            step: (step + 1),
            data: data,
        }, this.scrollToTop)
    }

    scrollToTop()
    {
        this.pageTop.current && window.scrollTo(0, this.pageTop.offsetTop)
    }

    setBody=(body, cb) =>
    {
        this.setState({
            ...this.state,
            body: body,
        }, cb)
    }

    ApiUpdateSpace=() =>
    {

        let body=this.state.body;

        if (this.props.newSpace && body.status<0)
        {
            // console.log("This is Submit Body");
            // console.log(body);
            insertSpace(this.props.members.Login.userToken, body, (obj) => {
                if (obj.status === 200) {
                    this.setState({
                        ...this.state,
                        formSubmitted: true
                    });
                } else if (obj.status === 400) {
                    alert("Cannot upload space, please try again.");
                    this.setSubmitting(false);
                }
            }, (error) => {
                // console.log("error", error);
                alert("Cannot upload space, please try again.");
                this.setSubmitting(false);
            })
        }
        else
        {
            body.status=1;
            delete body.lat;
            delete body.lng;
            delete body.title;
            delete body.address;
            delete body.floor;
            delete body.spaceno;


            // console.log("body", body);
            // return;

            //
            updateSpace(this.props.members.Login.userToken, this.props.space_id, body, (obj) =>
            {
                if (obj.status === 200)
                {
                    this.setState({
                        ...this.state,
                        formSubmitted: true
                    });
                }
                else if (obj.status === 400)
                {
                    alert("Cannot update space, please try again.");
                }
            }, (error) =>
            {
                // console.log("error", error);
            })
        }
    }

    render()
    {
        // console.log(this.state);
        const {
            // t,
            i18n
        }=this.props;

        if (this.props.members.Login.length === 0)
        {
            return <Redirect to={"/" + i18n.language + "/login"}/>;
        }

        if (this.state.redirect)
        {
            return <Redirect to={"/" + i18n.language + "/"}/>;
        }

        if (this.state.formSubmitted && this.props.newSpace) {
            return <Redirect to={"/" + i18n.language + "/editSpaceConfirm"}/>;
        }

        if (this.state.formSubmitted)
        {
            return <Redirect to={"/" + i18n.language + "/memberListedSpaces"}/>;
        }

        if (this.state.loading)
        {
            return <Loading />;
        }

        switch (this.state.step)
        {
            case 4:
                return <Preview
                space={this.state.space}
                data={this.state.data}
                goToStep={this.goToStep}
                members={this.props.members}
                pageTop={this.pageTop}
                ApiUpdateSpace={this.ApiUpdateSpace}
                setBody={this.setBody}
                newSpace={this.props.newSpace}
                />
            case 3:
                return <StepThree
                space={this.state.space}
                submitStep={this.submitStep}
                goToStep={this.goToStep}
                data={this.state.data}
                pageTop={this.pageTop}
                newSpace={this.props.newSpace}
                />;
            case 2:
                return <StepTwo
                space={this.state.space}
                submitStep={this.submitStep}
                goToStep={this.goToStep}
                data={this.state.data}
                pageTop={this.pageTop}
                newSpace={this.props.newSpace}
                />;
            default:
                return <StepOne
                space={this.state.space}
                submitStep={this.submitStep}
                data={this.state.data}
                pageTop={this.pageTop}
                newSpace={this.props.newSpace}
                />;
        }

    }
}

const mapStateToProps=(state) => (
{
    members: state.members,
}
);

export default withTranslation()(connect(mapStateToProps)(EditSpace));
