import React, {Component} from 'react';
import DatePicker from "react-datepicker";
import moment from "moment";

import LocationSearchInput from '../04ListASpace/LocationSearchInput';
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";

import ErrorMessage from '../../components/103Parts/ErrorMessage';

class SearchBox2 extends Component {

    onDatepickerRef = (el) => {
        if (el && el.input) {
            el.input.readOnly = true;
        }
    }

    // Address auto complete with Google Map
    handleChange = (address) => {
        this.props.handleSearchInput('address', address);
    };

    latLngHandler = (latLng, address) => {
        this.props.handleSearchInput('lng', latLng.lng);
        this.props.handleSearchInput('lat', latLng.lat);
        this.props.handleSearchInput('address', address);
    }

    handleMapNoResult = (address) => {
        this.props.handleSearchInput('lng', null);
        this.props.handleSearchInput('lat', null);
        this.props.handleSearchInput('address', address);
    }
    //
    // handleChange = (address) => {
    //     this.props.handleSearchInput('address', address);
    // };
    //
    // handleSelect = (address) => {
    //     // this.props.handleSearchInput('address', address);
    //
    //     geocodeByAddress(address)
    //         .then(results => getLatLng(results[0]))
    //         .then(latLng => {
    //             this.latLngHandler(latLng, address);
    //         });
    //     // .catch(error => console.error('Error', error));
    // };
    //
    // latLngHandler = (latLng, address) => {
    //     this.props.handleSearchInput('lng', latLng.lng);
    //     this.props.handleSearchInput('lat', latLng.lat);
    //     this.props.handleSearchInput('address', address);
    //
    //     //console.log("StepOne", latLng);
    //     // const data = {
    //     //     lat: latLng.lat,
    //     //     lng: latLng.lng,
    //     //     where: address
    //     // };
    //     //
    //     // this.props.changeStateLevelTwoArray('search', data);
    // }

    render() {

        const {
            t,
            i18n,
        } = this.props;

        return (
            <div>
                <div className="search2 clearfix">
                    <div className="wrap clearfix">
                        <div className="oneFourth left">
                            <div>
                                <DatePicker
                                    dateFormat="YYYY-MM-DD"
                                    selected={this.props.search.from === "" ? null : this.props.search.from}
                                    onChange={(e) => this.props.handleSearchInput('from', e)}
                                    minDate={moment()}
                                    // maxDate={this.props.search.to ? this.props.search.to : null}
                                    placeholderText={t("search:from")}
                                    ref={el => this.onDatepickerRef(el)}
                                />
                                <div className="vertical-sep"></div>
                            </div>
                        </div>
                        <div className="oneFourth left">
                            <div>
                                <DatePicker
                                    dateFormat="YYYY-MM-DD"
                                    selected={this.props.search.to === "" ? null : this.props.search.to}
                                    onChange={(e) => this.props.handleSearchInput('to', e)}
                                    minDate={this.props.search.from ? this.props.search.from : moment()}
                                    placeholderText={t("search:until")}
                                    ref={el => this.onDatepickerRef(el)}
                                />
                                <div className="vertical-sep special"></div>
                            </div>
                        </div>

                        <div className="sep-0"/>

                        <div className="fifty left">
                            <div className="clearfix">
                            <span className="bell">
                                <Link
                                    to={"/" + i18n.language + "/settings?session=alerts"}>
                                   <img src={require('../../images/mobile/03_find_parking_space/icon_alert.png')}
                                        alt=""/>
                                </Link>
                            </span>

                                <LocationSearchInput
                                    className="short"
                                    placeholder={t("search:enterAddress")}
                                    address={this.props.search.address}
                                    handleChange={this.handleChange}
                                    latLngHandler={this.latLngHandler}
                                    handleMapNoResult={this.handleMapNoResult}
                                />

                                {/*<input type="text" className="short" placeholder="Please enter your location"*/}
                                {/*value={this.props.search.address}*/}
                                {/*onChange={(e) => this.props.handleSearchInput('address', e.target.value)}*/}
                                {/*/>*/}
                                <img src={require('../../images/mobile/03_find_parking_space/icon_search.png')} alt=""
                                     className="magnifier" onClick={this.props.handleSearch}/>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.errors.address &&
                <div className="addressError">
                    <ErrorMessage message={t("listASpace:errors.step1.address")}/>
                </div>
                }
            </div>
        )
    }
}

export default withTranslation()(SearchBox2);
