import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

import ThreeSteps from '../../../components/04ListASpace/ThreeSteps';
import PrevNext from '../../../components/04ListASpace/PrevNext';
import ErrorMessages from '../../../components/04ListASpace/ErrorMessages';
import LocationSearchInput from "../../../components/04ListASpace/LocationSearchInput";
import GoogleApiWrapper from '../../../components/103Parts/GoogleMap';

class StepOne extends Component
{
    constructor(props)
    {
        super(props)

        if (props.data[1])
        {
            this.state=props.data[1];
        }
        else
        {
            const space=props.space;

            let feature='';

            if (space.features.indexOf('Indoor')> -1)
                feature='Indoor';

            if (space.features.indexOf('WithCovers')> -1)
                feature='WithCovers';

            if (space.features.indexOf('NoCovers')> -1)
                feature='NoCovers';

            this.state={
                addressTitle: space.title,
                addressTcTitle: space.title_tc,
                address: space.address,
                floor: space['floor'],
                spaceNo: space.spaceno,
                SpaceSuitable: {
                    PrivateCar: space.cartype.indexOf('PrivateCar')> -1,
                    Van: space.cartype.indexOf('Van')> -1,
                    Motorcycle: space.cartype.indexOf('Motorcycle')> -1,
                    Lorry: space.cartype.indexOf('Lorry')> -1,
                    Others: space.cartype.indexOf('Others')> -1,
                    LorryTones: '< 5.5'
                },
                lat: space.lat,
                lng: space.lng,
                Feature: feature,
                FeatureEV: {
                    yes: space.features.indexOf('EV')> -1
                },
                FeatureHeadRoom: {
                    yes: space.features.indexOf('HeadRoom')> -1,
                    Height: space.height,
                },
                Restrictions: space.restrictions,
                DetailedDescription: {
                    NearEntrance: space.description.indexOf('NearEntrance')> -1,
                    WideLoad: space.description.indexOf('WideLoad')> -1,
                    NearLift: space.description.indexOf('NearLift')> -1,
                    NearStairs: space.description.indexOf('NearStairs')> -1,
                    NearBlock: space.description.indexOf('NearBlock')> -1,
                    NearBlockText: space.nearblock,
                    CorneredSpace: space.description.indexOf('CorneredSpace')> -1,
                },
                AccessType: space.accesstype[0],
                reserved: (typeof (space.reserved) !== "undefined" && space.reserved),
                // quantity: (typeof (space.quantity) !== "undefined" && space.quantity ? space.quantity : 0),
                isCorp: (typeof (space.isCorp) !== "undefined" && space.isCorp),
                Errors: [],
            };
        }

        // console.log(this.state);
    }

    handleChange=(address) =>
    {
        this.setState({address});
    };

    latLngHandler=(latLng, address) =>
    {
        this.setState({
            lat: latLng.lat,
            lng: latLng.lng,
            address: address,
        })
    }

    changeStateLevelOne=(data1, value) =>
    {
        this.setState({
            [data1]: value
        })
    }

    changeStateLevelTwo=(data1, data2, value, cb) =>
    {
        if (data1 === "AccessCard" && data2 === "Cost")
            value=parseInt(value) ? parseInt(value) : '';

        this.setState({
            [data1]: {
                ...this.state[data1],
                [data2]: value
            }
        }, () =>
        {
            if (typeof (cb) === "function")
                cb();
        })
    }
    //
    // checkLorryIsOn = () => {
    //     if (this.state.SpaceSuitable.LorryTones !== '' && !this.state.SpaceSuitable.Lorry)
    //         this.changeStateCheckboxLevelTwo('SpaceSuitable', 'Lorry');
    // }

    resetLorrySize=() =>
    {
        if (this.state.SpaceSuitable.LorryTones !== '' && !this.state.SpaceSuitable.Lorry)
            this.changeStateLevelTwo('SpaceSuitable', 'LorryTones', '')
    }

    changeStateRadioLevelOne=(data1, value) =>
    {
        this.setState({
            [data1]: value
        })
    }

    resetFeatureHeadroom=() =>
    {
        if (this.state.FeatureHeadRoom.Height !== '' && !this.state.FeatureHeadRoom.yes)
            this.changeStateLevelTwo('FeatureHeadRoom', 'Height', '')
    }

    changeStateCheckboxLevelTwo=(data1, data2, cb) =>
    {
        let ok=true;

        if (data1 === "SpaceSuitable" && this.state.SpaceSuitable[data2])
        {
            // turn off space suitable
            // check if is last one
            let types=["PrivateCar", "Van", "Motorcycle", "Lorry", "Others"];

            ok=false;
            types.splice(types.indexOf(data2), 1);

            types.forEach((t) =>
            {
                if (this.state.SpaceSuitable[t])
                    ok=true;
            })
        }

        if (data1 === "DetailedDescription")
        {
            // check max 5 selects
            let types=['NearEntrance', 'WideLoad', 'NearLift', 'NearStairs', 'NearBlock', 'CorneredSpace'],
            DetailedDescription=this.state.DetailedDescription,
            choosen=0;

            types.forEach((t) =>
            {
                if ((t !== data2 && DetailedDescription[t]) ||
                (t === data2 && !DetailedDescription[t]))
                    choosen++;
            })
            if (choosen>5)
                ok=false;
        }

        if (data1 === "Restrictions")
        {
            ok=false;

            if (data2 === 'No')
            {
                this.setState({
                    [data1]: [data2]
                }, () =>
                {
                    if (typeof (cb) === "function")
                        cb();
                })
            }
            else
            {
                if (this.state[data1].indexOf('No')> -1)
                    this.state[data1].splice(this.state[data1].indexOf('No'), 1);

                if (this.state[data1].indexOf(data2)>-1)
                {
                    this.state[data1].splice(this.state[data1].indexOf(data2), 1);

                    this.setState({
                        [data1]: [
                            ...this.state[data1]
                        ]
                    }, () =>
                    {
                        if (typeof (cb) === "function")
                            cb();
                    });
                }
                else
                {
                    this.setState({
                        [data1]: [
                            ...this.state[data1],
                            data2
                        ]
                    }, () =>
                    {
                        if (typeof (cb) === "function")
                            cb();
                    });

                }
            }
        }

        if (!ok)
            return;

        this.setState({
            [data1]: {
                ...this.state[data1],
                [data2]: !this.state[data1][data2]
            }
        }, () =>
        {
            if (typeof (cb) === "function")
                cb();
        })
    }

    formSubmit=(e) =>
    {
        e.preventDefault();
        let data=this.state;
        let errors=[];

        if (data.addressTitle === "")
            errors.push({field: "addressTitle", message: "listASpace:errors.step1.addressTitle"});

        if (data.addressTcTitle === "")
            errors.push({field: "addressTitle", message: "listASpace:errors.step1.addressTcTitle"});

        if (data.address === "")
            errors.push({field: "address", message: "listASpace:errors.step1.address"});
        else if (data.lat === null || data.lng === null)
            errors.push({field: "address", message: "listASpace:errors.step1.validAddress"});

        if (!data.SpaceSuitable.PrivateCar && !data.SpaceSuitable.Van && !data.SpaceSuitable.Motorcycle && !data.SpaceSuitable.Lorry && !data.SpaceSuitable.Others)
            errors.push({field: "SpaceSuitable", message: "Please choose at least 1 space suitable."});

        if (data.SpaceSuitable.Lorry && data.SpaceSuitable.LorryTones === '')
            errors.push({field: "SpaceSuitable", message: "listASpace:errors.step1.lorryWeight"});

        // if (data.SpaceSuitable.Others && data.SpaceSuitable.OthersSpecify === "")
        //     errors.push({ field: "SpaceSuitable", message: "Please specify the type of other space suitable." });

        if (data.FeatureHeadRoom.yes && data.FeatureHeadRoom.Height === "")
            errors.push({field: "FeatureHeadRoom", message: "listASpace:errors.step1.headRoom"});

        if (data.DetailedDescription.NearBlock && data.DetailedDescription.NearBlockText === "")
            errors.push({field: "NearBlockText", message: "listASpace:errors.step1.nearBlock"});

        if (errors.length)
        {
            this.setState({
                ...this.state,
                Errors: errors
            })
        }
        else
        {
            this.props.submitStep(1, this.state);
        }
    };

    render()
    {
        const {
            t,
            // i18n
        }=this.props;

        return (
        <div className="wrapper-container memberSettings" ref={this.props.pageTop}>
            <div className="page-title">

                <h2>{t("listASpace:title")}</h2>

                <div className="sep-0"/>

                <ThreeSteps
                selected={1}
                stepsLeft={3}
                />
            </div>
            <div className="containerMain">
                <div className="content">
                    <p className="requiredField absolute">{/*TODO*/} * {t("listASpace:stepOne.required")}</p>
                    <h3>{t("listASpace:stepOne.title")}</h3>

                    <div className="sep-20"/>

                    <dl className="generalForm clearfix">
                        <div>
                            <dt className="align-top">
                                <h4>{t("listASpace:stepOne.addressTitle")}</h4>
                            </dt>
                            <dd>
                                <div className="wrap clearfix">
                                    <div className="full">
                                        <div>
                                            <input type="text"
                                                   readOnly={!this.props.newSpace}
                                                   placeholder={t("listASpace:stepOne.enterAddressTitle")}
                                                   value={this.state.addressTitle}
                                                   onChange={(e) => this.changeStateLevelOne('addressTitle', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sep-40"/>
                            </dd>
                        </div>

                        <div>
                            <dt className="align-top">
                                <h4>{t("listASpace:stepOne.addressTcTitle")}</h4>
                            </dt>
                            <dd>
                                <div className="wrap clearfix">
                                    <div className="full">
                                        <div>
                                            <input type="text"
                                                   readOnly={!this.props.newSpace}
                                                   placeholder={t("listASpace:stepOne.enterAddressTcTitle")}
                                                   value={this.state.addressTcTitle}
                                                   onChange={(e) => this.changeStateLevelOne('addressTcTitle', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sep-40"/>
                            </dd>
                        </div>

                        <div>
                            <dt className="align-top">
                                <h4>{t("listASpace:stepOne.address")} *</h4>
                            </dt>
                            <dd>
                                <div className="wrap clearfix">
                                    <div className="full">
                                        {this.props.newSpace ?
                                        <div>
                                            <LocationSearchInput
                                            address={this.state.address}
                                            handleChange={this.handleChange}
                                            latLngHandler={this.latLngHandler}
                                            handleMapNoResult={this.handleMapNoResult}
                                            />
                                        </div>
                                        : <div>
                                            <input type="text"
                                                   readOnly={true}
                                                   value={this.state.address}
                                            />
                                        </div>
                                        }
                                    </div>
                                </div>

                                <div className="sep-40"/>
                            </dd>
                        </div>

                        {!this.state.isCorp &&
                        <div>
                            <dt></dt>
                            <dd>
                                <div className="wrap clearfix">
                                    <div className="fifty">
                                        <div>
                                            <input type="text"
                                                   readOnly={!this.props.newSpace}
                                                   onChange={(e) => this.changeStateLevelOne('floor', e.target.value)}
                                                   value={this.state.floor}
                                                   maxLength={5}
                                            />
                                        </div>
                                    </div>
                                    <div className="fifty">
                                        <div>
                                            <input type="text"
                                                   readOnly={!this.props.newSpace}
                                                   onChange={(e) => this.changeStateLevelOne('spaceNo', e.target.value)}
                                                   value={this.state.spaceNo}
                                                   maxLength={5}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="sep-10 spaceFix"></div>
                                <p>*{t("listASpace:stepOne.addressPrivacy")}</p>
                                <div className="sep-10 spaceFix"></div>
                            </dd>
                        </div>
                        }

                        {this.props.newSpace &&
                        <div>
                            <dt></dt>
                            <dd>
                                <div className="wrapper-map">
                                    <GoogleApiWrapper lat={this.state.lat} lng={this.state.lng} width={"100%"}/>
                                </div>
                            </dd>
                        </div>
                        }

                        {this.props.newSpace &&
                        <div className="sep-40"/>
                        }

                        {this.state.isCorp &&
                        <div className="displayTable">
                            {/*  reserved */}
                            <div>
                                <dt className="align-top">
                                    <h4>{t("listASpace:stepOne.reserved.title")}</h4>
                                </dt>
                                <dd>
                                    <div className="wrap clearfix">
                                        <div className="fifty">
                                            <div>
                                                <div className="checkboxFive green">
                                                    <div>
                                                        <input type="checkbox" id="reserved"
                                                               checked={this.state.reserved}
                                                               onChange={(e) => this.changeStateRadioLevelOne('reserved', true)}
                                                        />
                                                        <label htmlFor="reserved"/>
                                                    </div>
                                                    <label
                                                    htmlFor="reserved">{t("listASpace:stepOne.reserved.options.yes")}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="fifty">
                                            <div>
                                                <div className="checkboxFive green">
                                                    <div>
                                                        <input type="checkbox" id="notreserved"
                                                               checked={!this.state.reserved}
                                                               onChange={(e) => this.changeStateRadioLevelOne('reserved', false)}
                                                        />
                                                        <label htmlFor="notreserved"/>
                                                    </div>
                                                    <label
                                                    htmlFor="notreserved">{t("listASpace:stepOne.reserved.options.no")}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </dd>
                            </div>
                            {/*  EOF reserved */}
                        </div>
                        }

                        <div className="sep-40"/>

                        <div>
                            <dt className="align-top">
                                <h4>{t("listASpace:stepOne.spaceSuitable.title")}</h4>
                            </dt>
                            <dd className="align-top">
                                <ul className="booking-types clearfix">

                                    <div className="oneThird">
                                        <li>
                                            <div
                                            onClick={(e) => this.changeStateCheckboxLevelTwo('SpaceSuitable', 'PrivateCar')}>
                                                <div
                                                className={"icon privateCar " + (this.state.SpaceSuitable.PrivateCar ? 'active' : '')}></div>
                                                <h5>{t("listASpace:stepOne.spaceSuitable.options.privateCar")}</h5>
                                            </div>
                                        </li>
                                    </div>
                                    <div className="oneThird">
                                        <li>
                                            <div
                                            onClick={(e) => this.changeStateCheckboxLevelTwo('SpaceSuitable', 'Van')}>
                                                <div
                                                className={"icon van " + (this.state.SpaceSuitable.Van ? 'active' : '')}></div>
                                                <h5>{t("listASpace:stepOne.spaceSuitable.options.van")}</h5>
                                            </div>
                                        </li>
                                    </div>
                                    <div className="oneThird">
                                        <li>
                                            <div
                                            onClick={(e) => this.changeStateCheckboxLevelTwo('SpaceSuitable', 'Motorcycle')}>
                                                <div
                                                className={"icon motorcycle " + (this.state.SpaceSuitable.Motorcycle ? 'active' : '')}></div>
                                                <h5>{t("listASpace:stepOne.spaceSuitable.options.motorcycle")}</h5>
                                            </div>
                                        </li>
                                    </div>
                                    <div className="sep-0"/>
                                    <div className="oneThird">
                                        <li>
                                            <div
                                            onClick={(e) => this.changeStateCheckboxLevelTwo('SpaceSuitable', 'Lorry', this.resetLorrySize)}>
                                                <div
                                                className={"icon lorry " + (this.state.SpaceSuitable.Lorry ? 'active' : '')}></div>
                                                <h5>{t("listASpace:stepOne.spaceSuitable.options.lorry")}</h5>
                                            </div>
                                            {this.state.SpaceSuitable.Lorry &&
                                            <div>
                                                <div className="sep-10"/>
                                                <select
                                                value={this.state.SpaceSuitable.LorryTones}
                                                onChange={(e) => this.changeStateLevelTwo('SpaceSuitable', 'LorryTones', e.target.value)}
                                                >
                                                    <option
                                                    value=''>{t("listASpace:stepOne.spaceSuitable.pleaseSelect")}</option>
                                                    <option
                                                    value='< 5.5'>{'<'} 5.5 {t("listASpace:stepOne.spaceSuitable.tones")}</option>
                                                    <option
                                                    value='5.5'>5.5 {t("listASpace:stepOne.spaceSuitable.tones")}</option>
                                                    <option
                                                    value='> 5.5'>{'>'} 5.5 {t("listASpace:stepOne.spaceSuitable.tones")}</option>
                                                </select>
                                            </div>
                                            }
                                        </li>
                                    </div>
                                    <div className="oneThird">
                                        <li>
                                            <div
                                            onClick={(e) => this.changeStateCheckboxLevelTwo('SpaceSuitable', 'Others')}>
                                                <div
                                                className={"icon others " + (this.state.SpaceSuitable.Others ? 'active' : '')}></div>
                                                <h5>{t("listASpace:stepOne.spaceSuitable.options.others")}</h5>
                                            </div>
                                            {/* <div className="sep-10" />
                                                <input type="text"
                                                    onChange={(e) => this.changeStateLevelTwo('SpaceSuitable', 'OthersSpecify', e.target.value)}
                                                    value={this.state.SpaceSuitable.OthersSpecify}
                                                /> */}
                                        </li>
                                    </div>
                                </ul>


                                {this.state.SpaceSuitable.Lorry &&
                                <div>
                                    <ul className="booking-types clearfix no-margin">
                                        <div className="oneThird">
                                            <li>
                                                        <span
                                                        className={"triangle " + (this.state.SpaceSuitable.Lorry ? 'active' : '')}></span>
                                            </li>
                                        </div>
                                        <div className="oneThird">
                                            <li>
                                                &nbsp;
                                            </li>
                                        </div>
                                        <div className="oneThird">
                                            <li>
                                                &nbsp;
                                            </li>
                                        </div>
                                    </ul>
                                    <div
                                    className={"detailBox " + (this.state.SpaceSuitable.Lorry ? 'active ' : '')}>
                                        <select
                                        className={(this.state.SpaceSuitable.Lorry ? 'active' : '')}
                                        onChange={(e) => this.changeStateLevelTwo('SpaceSuitable', 'LorryTones', e.target.value)}
                                        >
                                            <option
                                            value=''>{t("listASpace:stepOne.spaceSuitable.pleaseSelect")}</option>
                                            <option
                                            value='< 5.5'>{'<'} 5.5 {t("listASpace:stepOne.spaceSuitable.tones")}</option>
                                            <option
                                            value='5.5'>5.5 {t("listASpace:stepOne.spaceSuitable.tones")}</option>
                                            <option
                                            value='> 5.5'>{'>'} 5.5 {t("listASpace:stepOne.spaceSuitable.tones")}</option>
                                        </select>
                                        {/* <input type="text"
                                            className={(this.state.SpaceSuitable.Others ? 'active' : '')}
                                            onChange={(e) => this.changeStateLevelTwo('SpaceSuitable', 'OthersSpecify', e.target.value)}
                                            value={this.state.SpaceSuitable.OthersSpecify}
                                        /> */}
                                    </div>
                                </div>
                                }
                            </dd>
                        </div>

                        <div className="sep-40"/>

                        <div>
                            <dt className="align-top">
                                <h4>{t("listASpace:stepOne.feature.title")}</h4>
                            </dt>
                            <dd className="align-top">
                                <ul className="booking-types clearfix">
                                    <div className="oneThird">
                                        <li>
                                            <div
                                            onClick={(e) => this.changeStateRadioLevelOne('Feature', 'Indoor')}>
                                                <div
                                                className={"icon indoor " + (this.state.Feature === 'Indoor' ? 'active' : '')}></div>
                                                <h5>{t("listASpace:stepOne.feature.options.indoor")}</h5>
                                            </div>
                                        </li>
                                    </div>
                                    <div className="oneThird">
                                        <li>
                                            <div
                                            onClick={(e) => this.changeStateRadioLevelOne('Feature', 'WithCovers')}>
                                                <div className="or">{t("listASpace:stepOne.feature.or")}</div>
                                                <div
                                                className={"icon withCovers " + (this.state.Feature === 'WithCovers' ? 'active' : '')}></div>
                                                <h5>{t("listASpace:stepOne.feature.options.withCovers")}</h5>
                                            </div>
                                        </li>
                                    </div>
                                    <div className="oneThird">
                                        <li>
                                            <div
                                            onClick={(e) => this.changeStateRadioLevelOne('Feature', 'NoCovers')}>
                                                <div className="or">{t("listASpace:stepOne.feature.or")}</div>
                                                <div
                                                className={"icon noCovers " + (this.state.Feature === 'NoCovers' ? 'active' : '')}></div>
                                                <h5>{t("listASpace:stepOne.feature.options.noCovers")}</h5>
                                            </div>
                                        </li>
                                    </div>

                                    <div className="sep-0"/>

                                    <div className="oneThird">
                                        <li>
                                            <div
                                            onClick={(e) => this.changeStateCheckboxLevelTwo('FeatureEV', 'yes')}>
                                                <div
                                                className={"icon ev " + (this.state.FeatureEV.yes ? 'active' : '')}></div>
                                                <h5>{t("listASpace:stepOne.feature.options.ev")}</h5>
                                            </div>
                                        </li>
                                    </div>
                                    <div className="oneThird">
                                        <li>
                                            <div
                                            onClick={(e) => this.changeStateCheckboxLevelTwo('FeatureHeadRoom', 'yes', this.resetFeatureHeadroom)}>
                                                <div
                                                className={"icon headRoom " + (this.state.FeatureHeadRoom.yes ? 'active' : '')}></div>
                                                <h5>{t("listASpace:stepOne.feature.options.headRoom")}</h5>
                                            </div>

                                            {this.state.FeatureHeadRoom.yes &&
                                            <div>
                                                <div className="sep-10"/>

                                                <select
                                                value={this.state.FeatureHeadRoom.Height}
                                                onChange={(e) => this.changeStateLevelTwo('FeatureHeadRoom', 'Height', e.target.value)}
                                                >
                                                    <option
                                                    value="">{t("listASpace:stepOne.feature.pleaseSelect")}</option>
                                                    <option
                                                    value="1.7">1.7 {t("listASpace:stepOne.feature.m")}</option>
                                                    <option
                                                    value="1.8">1.8 {t("listASpace:stepOne.feature.m")}</option>
                                                    <option
                                                    value="1.9">1.9 {t("listASpace:stepOne.feature.m")}</option>
                                                    <option
                                                    value="2.0">2.0 {t("listASpace:stepOne.feature.m")}</option>
                                                    <option
                                                    value="2.1">2.1 {t("listASpace:stepOne.feature.m")}</option>
                                                    <option
                                                    value="2.2">2.2 {t("listASpace:stepOne.feature.m")}</option>
                                                    <option
                                                    value="2.3">2.3 {t("listASpace:stepOne.feature.m")}</option>
                                                    <option
                                                    value="2.4orAbove">2.4 {t("listASpace:stepOne.feature.m")}{t("listASpace:stepOne.feature.above")}</option>
                                                </select>
                                            </div>
                                            }
                                        </li>
                                    </div>
                                </ul>

                                {this.state.FeatureHeadRoom.yes &&
                                <div>
                                    <ul className="booking-types clearfix no-margin">
                                        <div className="oneThird">
                                            <li>
                                                <span className="triangle"></span>
                                            </li>
                                        </div>
                                        <div className="oneThird">
                                            <li>
                                                        <span
                                                        className={"triangle " + (this.state.FeatureHeadRoom.yes ? 'active ' : '')}></span>
                                            </li>
                                        </div>
                                        <div className="oneThird">
                                            <li>

                                            </li>
                                        </div>
                                    </ul>
                                    <div
                                    className={"detailBox " + (this.state.FeatureHeadRoom.yes ? 'active ' : '')}>
                                        <select
                                        className={this.state.FeatureHeadRoom.yes ? 'active' : ''}
                                        value={this.state.FeatureHeadRoom.Height}
                                        onChange={(e) => this.changeStateLevelTwo('FeatureHeadRoom', 'Height', e.target.value)}
                                        >
                                            <option value="">{t("listASpace:stepOne.feature.pleaseSelect")}</option>
                                            <option value="1.7">1.7 {t("listASpace:stepOne.feature.m")}</option>
                                            <option value="1.8">1.8 {t("listASpace:stepOne.feature.m")}</option>
                                            <option value="1.9">1.9 {t("listASpace:stepOne.feature.m")}</option>
                                            <option value="2.0">2.0 {t("listASpace:stepOne.feature.m")}</option>
                                            <option value="2.1">2.1 {t("listASpace:stepOne.feature.m")}</option>
                                            <option value="2.2">2.2 {t("listASpace:stepOne.feature.m")}</option>
                                            <option value="2.3">2.3 {t("listASpace:stepOne.feature.m")}</option>
                                            <option
                                            value="2.4orAbove">2.4 {t("listASpace:stepOne.feature.m")}{t("listASpace:stepOne.feature.above")}</option>
                                        </select>
                                    </div>
                                </div>
                                }
                            </dd>
                        </div>

                        <div className="sep-40"/>

                        <div>
                            <dt className="align-top">
                                <h4>{t("listASpace:stepOne.restrictions.title")}*</h4>
                            </dt>
                            <dd className="align-top">
                                <div className="wrap clearfix">
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="RestrictionsNo"
                                                           checked={this.state.Restrictions.indexOf('No')> -1}
                                                           onChange={(e) => this.changeStateCheckboxLevelTwo('Restrictions', 'No')}
                                                    />
                                                    <label htmlFor="RestrictionsNo"></label>
                                                </div>
                                                <label
                                                htmlFor="RestrictionsNo">{t("listASpace:stepOne.restrictions.options.noRestriction")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="owner"
                                                           checked={this.state.Restrictions.indexOf('Owners')> -1}
                                                           onChange={(e) => this.changeStateCheckboxLevelTwo('Restrictions', 'Owners')}
                                                    />
                                                    <label htmlFor="owner"></label>
                                                </div>
                                                <label
                                                htmlFor="owner">{t("listASpace:stepOne.restrictions.options.ownersBuilding")}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="sep-40"/>

                                <div className="wrap clearfix">
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="OwnersParkers"
                                                           checked={this.state.Restrictions.indexOf('Parkers')> -1}
                                                           onChange={(e) => this.changeStateCheckboxLevelTwo('Restrictions', 'Parkers')}
                                                    />
                                                    <label htmlFor="OwnersParkers"></label>
                                                </div>
                                                <label
                                                htmlFor="OwnersParkers">{t("listASpace:stepOne.restrictions.options.ownersParkersBuilding")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="VisitorsOnly"
                                                           checked={this.state.Restrictions.indexOf('Visitors')> -1}
                                                           onChange={(e) => this.changeStateCheckboxLevelTwo('Restrictions', 'Visitors')}
                                                    />
                                                    <label htmlFor="VisitorsOnly"></label>
                                                </div>
                                                <label
                                                htmlFor="VisitorsOnly">{t("listASpace:stepOne.restrictions.options.visitors")}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </dd>
                        </div>

                        <div className="sep-40"/>

                        <div>
                            <dt className="align-top">
                                <h4>{t("listASpace:stepOne.detailedDescription.title")}</h4>
                            </dt>
                            <dd className="align-top">
                                <div className="wrap clearfix">
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="NearEntrance"
                                                           checked={this.state.DetailedDescription.NearEntrance}
                                                           onChange={(e) => this.changeStateCheckboxLevelTwo('DetailedDescription', 'NearEntrance')}
                                                    />
                                                    <label htmlFor="NearEntrance"></label>
                                                </div>
                                                <label
                                                htmlFor="NearEntrance">{t("listASpace:stepOne.detailedDescription.options.entrance")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="WideLoad"
                                                           checked={this.state.DetailedDescription.WideLoad}
                                                           onChange={(e) => this.changeStateCheckboxLevelTwo('DetailedDescription', 'WideLoad')}
                                                    />
                                                    <label htmlFor="WideLoad"></label>
                                                </div>
                                                <label
                                                htmlFor="WideLoad">{t("listASpace:stepOne.detailedDescription.options.wideLoad")}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="sep-40"/>

                                <div className="wrap clearfix">
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="NearLift"
                                                           checked={this.state.DetailedDescription.NearLift}
                                                           onChange={(e) => this.changeStateCheckboxLevelTwo('DetailedDescription', 'NearLift')}
                                                    />
                                                    <label htmlFor="NearLift"></label>
                                                </div>
                                                <label
                                                htmlFor="NearLift">{t("listASpace:stepOne.detailedDescription.options.lift")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="NearStairs"
                                                           checked={this.state.DetailedDescription.NearStairs}
                                                           onChange={(e) => this.changeStateCheckboxLevelTwo('DetailedDescription', 'NearStairs')}
                                                    />
                                                    <label htmlFor="NearStairs"></label>
                                                </div>
                                                <label
                                                htmlFor="NearStairs">{t("listASpace:stepOne.detailedDescription.options.stairs")}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="sep-40"/>

                                <div className="wrap clearfix">
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="NearBlock"
                                                           checked={this.state.DetailedDescription.NearBlock}
                                                           onChange={(e) => this.changeStateCheckboxLevelTwo('DetailedDescription', 'NearBlock')}
                                                    />
                                                    <label htmlFor="NearBlock"></label>
                                                </div>
                                                <label
                                                htmlFor="NearBlock">{t("listASpace:stepOne.detailedDescription.options.block")}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="fifty">
                                        {this.state.DetailedDescription.NearBlock ?
                                        <div>
                                            <input type="text"
                                                   placeholder={t("listASpace:stepOne.detailedDescription.pleaseEnter")}
                                                   maxLength="10"
                                                   onChange={(e) => this.changeStateLevelTwo('DetailedDescription', 'NearBlockText', e.target.value)}
                                                   value={this.state.DetailedDescription.NearBlockText}
                                            />
                                        </div> : ''}
                                    </div>
                                </div>

                                <div className="sep-40"/>

                                <div className="wrap clearfix">
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="CorneredSpace"
                                                           checked={this.state.DetailedDescription.CorneredSpace}
                                                           onChange={(e) => this.changeStateCheckboxLevelTwo('DetailedDescription', 'CorneredSpace')}
                                                    />
                                                    <label htmlFor="CorneredSpace"></label>
                                                </div>
                                                <label
                                                htmlFor="CorneredSpace">{t("listASpace:stepOne.detailedDescription.options.cornered")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fifty">
                                        <div></div>
                                    </div>
                                </div>
                            </dd>
                        </div>

                        <div className="sep-40"/>

                        <div>
                            <dt className="align-top">
                                <h4>{t("listASpace:stepOne.accessType.title")}*</h4>
                            </dt>
                            <dd>
                                <div className="wrap clearfix">
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="AccessCard"
                                                           checked={this.state.AccessType === "AccessCard"}
                                                           onChange={(e) => this.changeStateRadioLevelOne('AccessType', 'AccessCard')}
                                                    />
                                                    <label htmlFor="AccessCard"></label>
                                                </div>
                                                <label
                                                htmlFor="AccessCard">{t("listASpace:stepOne.accessType.options.accessCard")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fifty">

                                    </div>
                                </div>

                                <div className="sep-40"/>

                                <div className="wrap clearfix">
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="OctopusCard"
                                                           checked={this.state.AccessType === "OctopusCard"}
                                                           onChange={(e) => this.changeStateRadioLevelOne('AccessType', 'OctopusCard')}
                                                    />
                                                    <label htmlFor="OctopusCard"></label>
                                                </div>
                                                <label
                                                htmlFor="OctopusCard">{t("listASpace:stepOne.accessType.options.octopusCard")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="AccessTypeNo"
                                                           checked={this.state.AccessType === "No"}
                                                           onChange={(e) => this.changeStateRadioLevelOne('AccessType', 'No')}
                                                    />
                                                    <label htmlFor="AccessTypeNo"></label>
                                                </div>
                                                <label
                                                htmlFor="AccessTypeNo">{t("listASpace:stepOne.accessType.options.noAccessCard")}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </dd>
                        </div>

                        <div className="sep-40"/>

                        <div>
                            <dt className="align-top">

                            </dt>
                            <dd>
                                <ErrorMessages errors={this.state.Errors}/>
                            </dd>
                        </div>
                    </dl>

                    <div className="sep-25"/>

                    <PrevNext
                    prevHas={false}
                    prevURL=''
                    prevText={t("listASpace:back")}
                    nextURL='#'
                    nextText={t("listASpace:next")}
                    progress="one"
                    value="1/3"
                    prevHandler={null}
                    nextHandler={(e) => this.formSubmit(e)}
                    />
                </div>
            </div>
        </div>
        );
    }
}

export default withTranslation()(StepOne);
