import $ from 'jquery';

export const DesktopHandle = {
    init: function () {

        const $desktopMenuBtn = $('.wrapper-header .header > div .desktop-menu-btn');

        const $wrapperHeader_header_move = $('.wrapper-header .header .move');

        const $wrapperHeader = $('.wrapper-header');

        $desktopMenuBtn.on('click', function () {

            $desktopMenuBtn.toggleClass('active');

            if ($desktopMenuBtn.hasClass('active')) {

                DesktopHandle.maxHeightDesktopMenu();

                $wrapperHeader_header_move.slideDown(100);
            } else {

                $wrapperHeader_header_move.slideUp(100);
            }
        });

        $(document).mouseup(function (e) {

            if (!$wrapperHeader.is(e.target) && $wrapperHeader.has(e.target).length === 0) {

                $wrapperHeader_header_move.slideUp(100);

                $desktopMenuBtn.removeClass('active');
            }
        });

        $('.wrapper-header .header .wrapper-move .move > div ul li a').click(function () {
            DesktopHandle.resetDesktopMenu();
        });
    },
    resetDesktopMenu: function () {
        $('.wrapper-header .header .move').slideUp(100);
        $('.wrapper-header .header > div .desktop-menu-btn').removeClass('active');
    },
    maxHeightDesktopMenu: function () {
        $('.wrapper-move').css('max-height', $(window).height() - 95);
    }
}
