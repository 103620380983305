import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Verified from '../103Parts/Verified';
import Heart from '../103Parts/Heart';
import Reviews from '../103Parts/Reviews';
import CarTypes from '../103Parts/CarTypes';

function ListType2a(props) {
    const {
        t,
        i18n,
        selected,
        count,
        score,
        reviews,
    } = props;

    const space = props.space.data;

    const lowestPrice = props.space.price,
        lowestType = props.space.price_type;

    return (
        <div className={"ListType2a clearfix " + (count === 0 ? 'first' : '') + (selected ? ' selected' : '')}>
            <div className="image"
                style={space.images ? { backgroundImage: "url(" + space.images[0] + ")" } : null}
            >
                <Verified
                    id={props.id}
                    verified={false}
                />
                <Heart
                    id={space._id}
                />
            </div>
            <div className="text">
                <h3>{i18n.language === 'en-US' ? space.title : space.title_tc}</h3>
                <h4>{i18n.language === 'zh-HK' && space.address_tc ? space.address_tc : space.address}</h4>
                {lowestPrice > 0 &&
                <div>
                    <div className="sep-5"/>
                    <div className="price">
                        {t("search:pricePer" + lowestType, {price: lowestPrice})}

                        {space.instant &&
                        <div className="instantBooking"/>
                        }
                        {props.space.bcode &&
                        <div className="bcode"/>
                        }
                    </div>
                </div>
                }
                <div className="sep-10" />
                <Reviews score={space.rating} reviews={space.reviews.length} />
                <div className="sep-10" />
                <CarTypes
                    carTypes={space.cartype}
                    features={space.features}
                />
                <Link to={"/" + i18n.language + "/space/" + space._id} className="button">{t("search:bookNow")}</Link>
            </div>
        </div>
    )
}

export default withTranslation()(ListType2a);
