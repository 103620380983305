import React, {Component} from 'react';
import {Redirect} from 'react-router';
// import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import MemberMenuDesktop from '../../../components/02Members/MemberMenuDesktop';
import MemberMenuMobile from '../../../components/02Members/MemberMenuMobile';

// import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
// import * as MembersActionCreators from '../../actions/members';

import * as UserApi from "../../../utils/04Api/User";
import {apiUploadImage} from "../../../utils/04Api/General";
import * as Yup from "yup";
import {Form, Field, Formik} from "formik";
import ErrorMessage from "../../../components/103Parts/ErrorMessage";
import Loading from "../../../components/10General/Loading";

class MemberProfile extends Component {
    constructor(props) {

        super(props);

        let logined = this.props.members.Login.length !== 0;

        this.state = {
            logined: logined,
            me: {
                id: '',
                profilePic: [],
                title: '',
                firstName: '',
                lastName: '',
                hkidName: '',
                email: '',
                phone: '',
                code: '',
                vehicles: [],
                octCard: [],
                payment: [],
                tempVehicles: '',
                tempOctCard: '',
                jobtitle: '',
                companyname: '',
                isCorp: false,
            },
            originalPhone: '',
            smsDelay: 0,
            loading: true,
            errors: [],
            saved: false,
            submitting: false,
            email_request_change: null,
            mobile_remark_open: false,
        }
    }

    componentDidMount = () => {
        this.ApiGetMe(this.ApiGetMe);
    }

    componentDidUpdate(p) {
        if (this.props.members.Login.userToken !== p.members.Login.userToken) {
            if (typeof(this.props.members.Login.userToken) === "undefined" || this.props.members.Login.userToken === "") {
                this.setState({
                    logined: false
                })
            }
        }
    }

    changeStateLevelOne = (data1, value) => {
        this.setState({
            [data1]: value
        })
    }

    changeStateLevelTwo = (data1, data2, value, cb) => {
        this.setState({
            [data1]: {
                ...this.state[data1],
                [data2]: value
            }
        }, () => {
            if (typeof (cb) === "function")
                cb();
        })
    }

    changeDefault = (data1, data2, value) => {
        let Arr = this.state.me[data1];

        Arr.map((value, key) => {
            Arr[key].default = false;
            return true;
        });

        Arr[data2].default = value;

        this.changeStateLevelTwo("me", data1, Arr);
    }

    removeData = (data1, data2) => {
        let Arr = this.state.me[data1];

        let newArr = [];

        Arr.map((value, key) => {
            if (key !== data2)
                newArr.push(value);
            return true;
        });

        this.changeStateLevelTwo("me", data1, newArr);
    }

    addData = (data1, data2, data3, value) => {

        let schema = Yup.object().shape({
            tempOctCard: Yup.number()
                .typeError("format"),
        });

        let successCallBack = (data1, data2, data3, value) => {

            let Arr = this.state.me[data1];

            let Obj = {
                [data3]: value,
                default: false
            }

            Arr.push(Obj);

            this.changeStateLevelTwo("me", data1, Arr);

            this.changeStateLevelTwo("me", data2, '');

            this.setState({
                ...this.state,
                errors: {
                    tempOctCard: ''
                }
            });
        }

        let failCallBack = () => {
            this.setState({
                ...this.state,
                errors: {
                    tempOctCard: this.props.t("myAccount:tabProfile.errors.octCard")
                }
            });
        }

        if (data1 === "octCard") {
            schema
                .isValid({
                    tempOctCard: value,
                })
                .then(function (valid) {
                    if (valid) {
                        successCallBack(data1, data2, data3, value);
                    } else {
                        failCallBack();
                    }
                });
        } else {
            successCallBack(data1, data2, data3, value);
        }
    }

    ApiGetMe = (cb) => {
        let callback = (res) => {
            //console.log(res.body);
            if (typeof (res.body) !== "undefined") {

                let me = res.body;

                // console.log('me', me);

                let stateMe = {
                    id: me._id,
                    profilePic: {
                        src: me.image,
                    },
                    title: me.title,
                    firstName: me.first_name,
                    lastName: me.last_name,
                    hkidName: me.hkid_name,
                    email: me.email,
                    phone: me.phone,
                    code: '',
                    vehicles: me.vehicles,
                    octCard: me.octcard,
                    payment: me.payment,
                    tempVehicles: '',
                    tempOctCard: '',
                    isCorp: me.isCorp,
                };

                if (me.isCorp) {
                    stateMe['jobtitle'] = me.jobtitle;
                    stateMe['companyname'] = me.companyname;
                }

                this.setState({
                    ...this.state,
                    me: stateMe,
                    originalPhone: me.phone,
                    loading: false,
                    errors: [],
                }, cb);
            }
        }

        UserApi.getMe(this.props.members.Login.userToken, callback);
    }

    _handleImageChange(e) {
        e.preventDefault();

        // let reader = new FileReader();
        let uploaded_file = e.target.files[0];
        this.uploadImage(uploaded_file);

        // console.log(uploaded_file);

        // reader.onloadend = (file) => {
        //
        //     let image = new Image();
        //     image.src = file.target.result;
        //
        //     image.onload = () => {
        //         // access image size here
        //         // let width = image.width;
        //         // let height = image.height;
        //
        //         let obj = {
        //             src: image.src,
        //         }
        //
        //         this.changeStateLevelTwo("me", "profilePic", obj);
        //     };
        //
        //     this.uploadImage(uploaded_file);
        // }
        //
        // reader.readAsDataURL(uploaded_file)
    }

    uploadImage = (image) => {
        apiUploadImage(image, 'profile', {}, (res) => {
            if (typeof (res.path) === "undefined") {
                alert(res.msg);
                return;
            }

            setTimeout(() => {
                let obj = {
                    src: res.path,
                }
                this.changeStateLevelTwo("me", "profilePic", obj);
            }, 1000);
        }, () => {
            alert("Cannot upload image");
        })

        // let formData = new FormData();
        // formData.append("image", image);
        //
        // fetch(Config.API_URL_BASE + 'upload', {
        //     method: 'post',
        //     headers: new Headers({}),
        //     body: formData,
        // })
        //     .then(response => response.json())
        //     .then(response => {
        //
        //         let obj = {
        //             src: response.path,
        //         }
        //
        //         console.log(obj);
        //
        //         this.changeStateLevelTwo("me", "profilePic", obj);
        //     })
        //     .catch(error => {
        //
        //         console.log("error");
        //         console.log(error);
        //     });
    }

    handleSMSCountdown = () => {
        // Set countdown to 120
        this.setState({
            ...this.state,
            smsDelay: 120,
        }, () => {
            // start count down
            const countdown = () => {
                setTimeout(() => {
                    let smsDelay = this.state.smsDelay - 1;
                    this.setState({
                        ...this.state,
                        smsDelay: smsDelay
                    }, () => {
                        if (this.state.smsDelay > 0)
                            countdown();
                    })
                }, 1000)
            }
            countdown();
        })
    }

    handleSMS = (values, setErrors, setFieldTouched) => {
        const {t} = this.props;

        if (this.state.smsDelay === 0) {

            const yupPhone = Yup.object().shape({
                phone: Yup.string()
                    .required()
                    .matches(/^\d{8,20}$/),
            });

            yupPhone.isValid({
                phone: values.phone,
            }).then((valid) => {

                if (valid) {
                    // SEND VERIFICATION CODE
                    const cb = (obj) => {

                        if (obj.status === 200) {
                            // console.log(obj.body.stoken.token);
                            this.handleSMSCountdown();
                        } else if (obj.status === 400) {

                            let msg = obj.body.msg;

                            switch (msg) {
                                case "mobile exist":
                                    msg = this.props.t("myAccount:errors.profile.phone");
                                    break;
                                case "10 times sms limit reach, you will be blocked for 24 hours":
                                    msg = this.props.t("login:errors.smsLimitation");
                                    break;
                                case "You IP blocked for 24 hours":
                                    msg = this.props.t("login:errors.smsLimitation");
                                    break;
                                default:
                                    break;
                            }

                            this.setState({
                                ...this.state,
                                errors: {
                                    validMobile: msg,
                                }
                            });
                        } else {
                            // console.log("smsLoginGetToken success", obj.body);
                        }
                    }
                    const eCb = (obj) => {

                        // console.log("smsLoginGetToken failed", obj);
                    }
                    UserApi.getSmsToken(values.phone, cb, eCb);
                } else {

                    setFieldTouched("phone", true);
                    setErrors({phone: t("Please enter a valid Mobile Number")});
                }
            });
        }
    }

    setSubmitting = (submitting, cb) => {
        if (typeof(cb) !== "function")
            cb = () => {
                return false
            };
        this.setState({
            ...this.state,
            submitting: submitting
        }, cb);
    };

    handleSubmit = (values, {setFieldError,resetForm}) => {
        if (!this.state.submitting) {
            this.setSubmitting(true, () => {
                let cb = () => {
                    let body = {
                        image: this.state.me.profilePic.src,
                        title: values.title,
                        first_name: values.firstName,
                        last_name: values.lastName,
                        // email: values.email,
                        email: this.state.me.email,//2019-08-08 Samson-dont save the values.email since the verification has been sent to that mailbox and waiting user to confirm
                        phone: values.phone,
                        code: values.code,
                        vehicles: this.state.me.vehicles,
                        octcard: this.state.me.octCard,
                        card: "N4",
                        cardData: {
                            action: "detail"
                        }
                    }
                    if (this.state.me.isCorp) {
                        body['companyname'] = values.companyname;
                        body['jobtitle'] = values.jobtitle;
                    }
                    let callback = (res) => {

                        if (!res.body.msg) {
                            this.setState({
                                ...this.state,
                                saved: true,
                            });

                            setTimeout(() => {
                                this.setState({
                                    ...this.state,
                                    saved: false,
                                },() => {
                                    this.setSubmitting(false);
                                })
                            }, 2000);
                        } else {
                            let msg = res.body.msg;

                            switch (msg) {
                                case "email exist":
                                    setFieldError('email', this.props.t("registerCorporate:errors.email"));
                                    break;
                                case "mobile exist":
                                    msg = this.props.t("myAccount:errors.profile.phone");
                                    break;
                                case "verification code not valid":
                                    msg = this.props.t("myAccount:errors.profile.code");
                                    break;
                                default:
                                    break;
                            }

                            this.setState({
                                ...this.state,
                                errors: {
                                    validMobile: msg,
                                }
                            }, () => {
                                this.setSubmitting(false);
                            });
                        }
                    }
                    UserApi.updateUserInfo(this.props.members.Login.userToken, body, callback);
                };

                if (this.state.me.email !== values.email) {//2019-08-08 Samson- for profile email modification
                    let language = 'english';
                    if (typeof(this.props.i18n.language) !== "undefined" && this.props.i18n.language === 'zh-HK')
                        language = 'chinese';

                    let params = {
                        language: language,
                        email: values.email,
                    };

                    const callback = (res) => {
                        if (typeof (res.body) !== "undefined") {
                            if (typeof (res.body.status) !== "undefined" && res.body.status === 1) {
                                this.setState({
                                    ...this.state,
                                    email_request_change: values.email,
                                }, () => {
                                    resetForm({"email":this.state.me.email});//2019-08-08 Samson-since the verification has been sent to that mailbox and waiting user to confirm
                                    cb();
                                });
                            } else if (typeof (res.body.msg) !== "undefined") {

                                let msg = res.body.msg;

                                switch (msg) {
                                    case "user_exist":
                                        setFieldError('email', this.props.t("registerCorporate:errors.email"));
                                        break;
                                    default:
                                        setFieldError('email', this.props.t("Cannot request to update email"));
                                }

                                this.setSubmitting(false);
                            }
                        }
                    };

                    UserApi.request_update_email(this.props.members.Login.userToken, params, callback);
                } else
                    cb();
            });
        }
    };

    form = ({values, errors, touched, handleChange, handleBlur, setErrors, setFieldTouched}) => {

        const {
            t,
            i18n,
        } = this.props;

        return (
            <div className="content clearfix memberProfile">
                <div className="generalForm clearfix">
                    <Form>
                        <div id="profile_pic" className="wrap clearfix">

                            <div className="twenty left mobile-full"><h4>{t("myAccount:tabProfile.pic")}</h4></div>

                            <div className="eighty right mobile-full">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td width="200">
                                            <div className="profile_pic_block margin_left_right">
                                                <img className="profile_pic_display" src={this.state.me.profilePic.src}
                                                     alt=""/>

                                                <div className="sep-10"/>

                                                <input type="file" id="edit_profile_pic"
                                                       onChange={(e) => this._handleImageChange(e, 1)}/>
                                                <label className="edit_profile_pic_btn button margin_left_right"
                                                       htmlFor="edit_profile_pic">{t("myAccount:tabProfile.edit")}</label>
                                            </div>
                                        </td>
                                        <td className="viewProfileBlock">
                                            <a className="button viewProfile"
                                               href={"/" + i18n.language + "/landlordProfile/" + this.state.me.id}>
                                                {t("myAccount:tabProfile.viewProfile")}
                                            </a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="sep-25"/>

                        {this.state.me.isCorp &&
                        <div>
                            <div className="wrap clearfix">
                                <div className="twenty left mobile-full">
                                    <h4>{t("myAccount:tabProfile.companyname")} *</h4></div>
                                <div className="eighty right mobile-full">
                                    <div className="margin_left_right">
                                        <Field type="text" name="companyname"/>
                                        {errors.companyname && touched.companyname ?
                                            <ErrorMessage message={t(errors.companyname)}/> : null}
                                    </div>
                                </div>
                            </div>

                            <div className="sep-25"/>

                            <div className="wrap clearfix">
                                <div className="twenty left mobile-full"><h4>{t("myAccount:tabProfile.jobtitle")} *</h4>
                                </div>
                                <div className="eighty right mobile-full">
                                    <div className="margin_left_right">
                                        <Field type="text" name="jobtitle"/>
                                        {errors.jobtitle && touched.jobtitle ?
                                            <ErrorMessage message={t(errors.jobtitle)}/> : null}
                                    </div>
                                </div>
                            </div>

                            <div className="sep-25"/>
                        </div>
                        }

                        <div>
                            <div className="twenty left mobile-full"><h4>{t("myAccount:tabProfile.honorific")} *</h4>
                            </div>
                            <div className="eighty right mobile-full">
                                <div className="margin_left_right">
                                    <select
                                        name="title"
                                        value={values.title}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        <option value="mr">{t("myAccount:tabProfile.honorificOptions.mr")}</option>
                                        <option value="ms">{t("myAccount:tabProfile.honorificOptions.ms")}</option>
                                        <option value="mrs">{t("myAccount:tabProfile.honorificOptions.mrs")}</option>
                                        <option value="miss">{t("myAccount:tabProfile.honorificOptions.miss")}</option>
                                    </select>
                                    {errors.honorific && touched.honorific ?
                                        <ErrorMessage message={t(errors.honorific)}/> : null}
                                </div>
                            </div>
                        </div>

                        <div className="sep-25"/>

                        <div className="wrap clearfix">
                            <div className="twenty left mobile-full"><h4>{t("myAccount:tabProfile.name")} *</h4></div>
                            <div className="eighty right mobile-full">
                                <div className="wrap clearfix">
                                    <div className="fifty left mobile-full">
                                        <div className="margin_left_right">
                                            <Field type="text" name="firstName"/>
                                            {errors.firstName && touched.firstName ?
                                                <ErrorMessage message={t(errors.firstName)}/> : null}
                                        </div>
                                    </div>
                                    <div className="fifty right mobile-full">
                                        <div className="margin_left_right">
                                            <Field type="text" name="lastName"/>
                                            {errors.lastName && touched.lastName ?
                                                <ErrorMessage message={t(errors.lastName)}/> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="sep-25"/>

                        <div>
                            <div className="twenty left mobile-full"><h4>{t("myAccount:tabProfile.email")}</h4></div>
                            <div className="eighty right mobile-full">
                                <div className="margin_left_right">
                                    <Field type="text" name="email"/>
                                    {errors.email && touched.email ?
                                        <ErrorMessage message={t(errors.email)}/> : null}
                                    {this.state.email_request_change ? <ErrorMessage
                                        message={t("myAccount:changeEmail.request_success", {"email": this.state.email_request_change})}/> : null}
                                </div>
                            </div>
                        </div>

                        <div className="sep-25"/>

                        <div>
                            <div className="twenty left mobile-full">
                                <h4>
                                    {t("myAccount:tabProfile.mobile")}
                                    <span className="remark_btn" onClick={() => { this.setState({ mobile_remark_open: !this.state.mobile_remark_open }) }}>&nbsp;</span>
                                    {
                                        this.state.mobile_remark_open && (
                                          <div className="popup"><h5>{t("myAccount:tabProfile.mobile_remarks")}</h5></div>
                                        )
                                    }
                                </h4>
                            </div>
                            <div className="eighty right mobile-full">
                                <div className="margin_left_right">
                                    <Field type="text" name="phone" maxLength="8"/>
                                    {errors.phone ?
                                        <ErrorMessage message={t(errors.phone)}/> : null}
                                </div>
                            </div>
                        </div>

                        {this.state.originalPhone !== values.phone &&
                        <div>
                            <div className="sep-25"/>

                            <div className="oneTimePassword">
                                <div className="twenty left mobile-full">
                                    <h4>{t("registerCorporate:verificationCode")}</h4>
                                </div>

                                <div className="eighty right mobile-full">
                                    <div>
                                        <div className="seventy left mobile-full">
                                            <div className="margin_left_right">
                                                <Field type="text" name="code" maxLength="6"/>
                                                {errors.code && touched.code ?
                                                    <ErrorMessage message={errors.code}/> : null}
                                                {this.state.errors.validMobile ?
                                                    <ErrorMessage message={this.state.errors.validMobile}/> : null}
                                            </div>
                                        </div>
                                        <div className="thirty right mobile-full">
                                            <div className="margin_left_right">
                                                <button style={{"fontSize": "14px"}} type="button" className="button"
                                                        onClick={() => {
                                                            this.handleSMS(values, setErrors, setFieldTouched);
                                                        }}>{t(this.state.smsDelay > 0 ? t("registerCorporate:resend") + this.state.smsDelay + t("registerCorporate:second") : t("registerCorporate:send"))}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }

                        <div className="sep-25"/>

                        <div className="vehicleRegMark">
                            <div className="twenty left mobile-full"><h4
                                className="title">{t("myAccount:tabProfile.vehicles")}</h4></div>
                            <div className="eighty right mobile-full">
                                {this.state.me.vehicles.length === 0 &&
                                <div>&nbsp;&nbsp;&nbsp;{t("myAccount:tabProfile.noVehicle")}</div>
                                }

                                {this.state.me.vehicles.map((value, key) => {
                                    return (
                                        <div key={key}>
                                            <div className="wrap clearfix">
                                                <div className="sixty left mobile-full">
                                                    <div className="vehiclesInput margin_left_right">
                                                        <input readOnly type="text" placeholder=""
                                                               value={value.carplate}
                                                               onChange={(e) => null}/>
                                                        <span className="button remove"
                                                              onClick={(e) => this.removeData("vehicles", key)}
                                                        ></span>
                                                    </div>
                                                </div>
                                                <div className="twenty left mobile-full">
                                                    <div className="vehiclesInput margin_left_right">
                                                    <input readOnly type="text" placeholder=""
                                                           value={t(`myAccount:tabSettings.alerts.suitable.options.${value.type.toLowerCase()}`)}
                                                           disabled={true}/>
                                                </div>
                                                </div>
                                                <div className="twenty right mobile-full">
                                                    <div className="margin_left_right">
                                                        <div type="submit"
                                                             className={"button" + (value.default === true ? "" : " whiteBtn")}
                                                             onClick={(e) => this.changeDefault("vehicles", key, true)}>
                                                            {(value.default === true ? t("myAccount:tabProfile.default") : t("myAccount:tabProfile.setDefault"))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sep-25"/>
                                        </div>
                                    )
                                })}
                                {/*<div className="wrap clearfix">*/}
                                {/*    <div className="eighty left mobile-full">*/}
                                {/*        <div className="margin_left_right">*/}
                                {/*            <input name="tempVehicles" type="text" placeholder=""*/}
                                {/*                   value={this.state.me.tempVehicles}*/}
                                {/*                   onChange={(e) => this.changeStateLevelTwo("me", "tempVehicles", e.target.value)}/>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="twenty right mobile-full">*/}
                                {/*        <div className="margin_left_right">*/}
                                {/*            <div type="submit" className="button whiteBtn"*/}
                                {/*                 onClick={(e) => this.addData("vehicles", "tempVehicles", "carplate", this.state.me.tempVehicles)}>*/}
                                {/*                {t("myAccount:tabProfile.addMore")}*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    {errors.tempVehicles && touched.tempVehicles ?*/}
                                {/*        <ErrorMessage message={t(errors.tempVehicles)}/> : null}*/}
                                {/*</div>*/}
                            </div>
                        </div>

                        <div className="sep-0"/>

                        <div className="vehicleRegMark">
                            <div className="twenty left mobile-full"><h4
                                className="title">{t("myAccount:tabProfile.octCard")}</h4>
                            </div>
                            <div className="eighty right mobile-full">
                                {this.state.me.octCard.length === 0 &&
                                <div>&nbsp;&nbsp;&nbsp;{t("myAccount:tabProfile.noOctCard")}</div>
                                }

                                {this.state.me.octCard.map((value, key) => {
                                    return (
                                        <div key={key}>
                                            <div className="wrap clearfix">
                                                <div className="eighty left mobile-full">
                                                    <div className="vehiclesInput margin_left_right">
                                                        <input readOnly type="text" placeholder=""
                                                               value={value.number}
                                                               onChange={(e) => null}/>
                                                        <span className="button remove"
                                                              onClick={(e) => this.removeData("octCard", key)}
                                                        ></span>
                                                    </div>
                                                </div>
                                                <div className="twenty right mobile-full">
                                                    <div className="margin_left_right">
                                                        {/*<div type="submit"*/}
                                                        {/*className={"button" + (value.default === true ? "" : " whiteBtn")}*/}
                                                        {/*onClick={(e) => this.changeDefault("octCard", key, true)}>*/}
                                                        {/*{(value.default === true ? t("myAccount:tabProfile.default") : t("myAccount:tabProfile.setDefault"))}*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sep-25"/>
                                        </div>
                                    )
                                })}
                                {/*<div className="wrap clearfix">*/}
                                {/*    <div className="eighty left mobile-full">*/}
                                {/*        <div className="margin_left_right">*/}
                                {/*            <input type="text" placeholder=""*/}
                                {/*                   value={this.state.me.tempOctCard}*/}
                                {/*                   onChange={(e) => this.changeStateLevelTwo("me", "tempOctCard", e.target.value)}/>*/}
                                {/*            {this.state.errors.tempOctCard ?*/}
                                {/*                <ErrorMessage message={t(this.state.errors.tempOctCard)}/> : null}*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="twenty right mobile-full">*/}
                                {/*        <div className="margin_left_right">*/}
                                {/*            <div type="submit" className="button whiteBtn"*/}
                                {/*                 onClick={(e) => this.addData("octCard", "tempOctCard", "number", this.state.me.tempOctCard)}>*/}
                                {/*                {t("myAccount:tabProfile.addMore")}*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>

                        <div className="sep-25"/>

                        <div className="full left align-center">
                            <div>
                                <button type="submit" className="button">{t("myAccount:save")}</button>
                            </div>

                            {this.state.saved &&
                            <div>
                                <div className="sep-20"/>
                                {t("myAccount:updated")}
                            </div>
                            }
                        </div>
                    </Form>
                </div>
            </div>
        )
    }

    render() {
        const {
            //t,
            i18n,
        } = this.props;

        if (!this.state.logined)
            return <Redirect to={"/" + i18n.language + "/login"}/>;

        if (this.state.loading)
            return <Loading />;

        let yupShape = {
            firstName: Yup.string()
                .required("myAccount:tabProfile.errors.name"),
            lastName: Yup.string()
                .required("myAccount:tabProfile.errors.name"),
            email: Yup.string()
                .required("myAccount:tabProfile.errors.email")
                .email("myAccount:tabProfile.errors.invalidEmail"),
            // phone: Yup.string()
            //     .required("myAccount:tabProfile.errors.phone")
            //     .min(8, "myAccount:tabProfile.errors.invalidPhone")
        }

        if (this.state.me.isCorp) {
            yupShape['companyname'] = Yup.string().required('myAccount:tabProfile.errors.companyname');
            yupShape['jobtitle'] = Yup.string().required('myAccount:tabProfile.errors.jobtitle');
        }

        this.schema = Yup.object().shape(yupShape);

        return (
            <div id="myAccountProfile" className="wrapper-container">
                <MemberMenuMobile/>
                <div className="containerMain">
                    <MemberMenuDesktop/>

                    <Formik
                        initialValues={
                            this.state.me
                        }
                        validationSchema={this.schema}
                        onSubmit={this.handleSubmit}
                        component={this.form}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => (
    {
        members: state.members
    }
);

export default withTranslation()(connect(mapStateToProps)(MemberProfile));
