import React from 'react';
import {withTranslation} from 'react-i18next';
import moment from 'moment';
import {
    // filter,
    find
} from 'lodash';

function TimePickerRange(props)
{
    // let {availableTime} = props;
    const {
        booked=[],
    }=props;

    if (props.dateChanged || !props.dateChanged)
    {

        let time=moment("2019-01-01 00:00:00"),
        TimeOption=[],
        // i = 1,
        startMin=true,
        stopMax=false,
        times=[];

        const minTime=props.minTime,
        maxTime=props.maxTime;

        if (minTime !== undefined)
            startMin=false;
        let endTime=moment("2019-01-01 23:59");
        while (time<=endTime)
        {
            // while (time.format("YYYY-MM-DD HH:mm") !== "2019-01-02 00:15") {
            let t=time.format("HH:mm");
            if (time.format("DD") === "02")
                t="24:00";

            if ((!props.stime || t>=props.stime) && (!props.etime || t<=props.etime))
            {
                if (maxTime !== undefined && !stopMax)
                {
                    if (t === maxTime)
                        stopMax=true;
                }

                if (startMin && !stopMax)
                {
                    times.push(t);
                }

                if (minTime !== undefined && !startMin)
                {
                    if (t === minTime)
                        startMin=true;
                }
            }

            // time.add(15, 'm');
            time.add(1, 'h');
        }

        // times = filter(times, (time)=>{
        // 	return !find(booked, (o) => {
        // 		return o === time;
        // 	})
        // })

        times.forEach((t, i) =>
        {
            let disabled=false;

            if (find(booked, (o) =>
            {
                return o === t;
            }))
            {
                disabled=true;
            }

            if (props.value === t)
                TimeOption.push(<option value={t} key={"timepickerrange-" + i} selected
                                        disabled={disabled}>{t.split(":")[0]}</option>);
            else
                TimeOption.push(<option value={t} key={"timepickerrange-" + i}
                                        disabled={disabled}>{t.split(":")[0]}</option>);
        })

        return (
        TimeOption
        );
    }


    // let x = 60; //minutes interval
    // let tt = 0; // start time
    // let ap = ['AM', 'PM']; // AM-PM
    // let TimeOption = [];
    // let default_selection;
    //
    // if (props.value !== undefined) {
    // 	default_selection = props.value;
    // }
    //
    // //loop to increment the time and push results in array
    // let key = 1;
    // for (let i = 0; tt < 24 * 60; i++) {
    //
    // 	// getting hours of day in 0-24 format
    // 	let hh = Math.floor(tt / 60);
    // 	// getting minutes of the hour in 0-55 format
    // 	let mm = (tt % 60);
    // 	// pushing data in array in [00:00 - 12:00 AM/PM format]
    // 	let times = ("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + " " + ap[Math.floor(hh / 12)];
    // 	let val = (hh < 10 ? "0" : "") + hh + ":" + (mm < 10 ? "0" : "") + mm;
    //
    // 	let Option;
    //
    // 	if (val === default_selection) {
    // 		Option = <option value={val} selected key={"timepickerrange-" + key}>{times}</option>
    // 	} else {
    // 		Option = <option value={val} key={"timepickerrange-" + key}>{times}</option>
    // 	}
    //
    // 	TimeOption.push(
    // 		Option
    // 	);
    //
    // 	tt = tt + x;
    // 	key++;
    // }
    //
    // return (
    // 	TimeOption
    // );
}

function MinutePickerRange(props)
{
    // let {availableTime} = props;
    const {
        booked=[],
    }=props;

    // console.log("This is Prop Value");
    // console.log(props.value);

    let time=moment("2019-01-01 00:00"),
    TimeOption=[],
    // i = 1,
    // startMin=true,
    // stopMax=false,
    times=[];

    // const minTime=props.minTime,
    // maxTime=props.maxTime;

    // if (minTime !== undefined)
    //     startMin=false;

    let endTime=moment("2019-01-01 01:00");

    while (time<endTime)
    {
        if (props.endMinute)
        {
            if (props.value !== null)
                times.push(props.value);

            break;
        }
        // while (time.format("YYYY-MM-DD HH:mm") !== "2019-01-02 00:15") {
        let t=time.format("mm");

        times.push(t);
        /*if (time.format("DD") === "02")
            t="24:00";*/

        /*if ((!props.stime || t>=props.stime) && (!props.etime || t<=props.etime))
        {
            if (maxTime !== undefined && !stopMax)
            {
                if (t === maxTime)
                    stopMax=true;
            }

            if (startMin && !stopMax)
            {
                times.push(t);
            }

            if (minTime !== undefined && !startMin)
            {
                if (t === minTime)
                    startMin=true;
            }
        }*/

        // time.add(15, 'm');
        time.add(15, 'm');
    }

    // times = filter(times, (time)=>{
    // 	return !find(booked, (o) => {
    // 		return o === time;
    // 	})
    // })

    times.forEach((t, i) =>
    {
        let disabled=false;

        if (find(booked, (o) =>
        {
            return o === t;
        }))
        {
            disabled=true;
        }

        if (props.value === t)
            TimeOption.push(<option value={t} key={"timepickerrange-" + i} selected
                                    disabled={disabled}>{t}</option>);
        else
            TimeOption.push(<option value={t} key={"timepickerrange-" + i}
                                    disabled={disabled}>{t}</option>);
    });

    return (
    TimeOption
    );
}

function TimePicker(props)
{
    const {
        t,
        booked=[],
    }=props;

    return (
    (props.fieldType === 'hour') ?
    <select
    className="whiteBg"
    onChange={(e) => (props.onChangeHandler !== undefined ? props.onChangeHandler(e.target.value) : null)}
    >
        {(props.value === null) ?
        <option value="" selected disabled={true}>{t("space:" + props.fieldType)}</option>
        :
        <option value="" disabled={true}>{t("space:" + props.fieldType)}</option>
        }
        <TimePickerRange value={props.value} stime={props.stime} etime={props.etime} booked={booked}
                         dateChanged={props.dateChanged}/>
    </select>
    :
    <select
    className="whiteBg"
    onChange={(e) => (props.onChangeHandler !== undefined ? props.onChangeHandler(e.target.value) : null)}
    >
        {(props.value === null) ?
        <option value="" selected disabled={true}>{t("space:" + props.fieldType)}</option>
        :
        <option value="" disabled={true}>{t("space:" + props.fieldType)}</option>
        }
        <MinutePickerRange value={props.value} stime={props.stime} etime={props.etime} booked={booked}
                           endMinute={props.endMinute}/>
    </select>
    )
}

export default withTranslation()(TimePicker);
