import React from 'react';
import { withTranslation } from 'react-i18next';
import {mongoIdTimestamp} from "../../utils/00General/Helpers";

function Landlord(props) {
    const {t, space, i18n} = props;
    return (
        <div className="spaceInfo clearfix">
            <div className="person right">
                <div className="image">
                    <img src={require('../../images/blank.png')} alt="" />
                </div>

                <h3>{space.owner.name}</h3>

                <div className="memberSince">{t("contactOwner:memberSince")}<span>{mongoIdTimestamp(space.owner._id, "YYYY-MM-DD")}</span>{t("contactOwner:memberBecome")}</div>
            </div>
            <div className="space left">
                <h3>{space.title}</h3>
                <h4>{i18n.language === 'zh-HK' && space.address_tc ? space.address_tc : space.address}</h4>
                <span className="spaceId">{t("contactOwner:spaceID")} - {space._id}</span>
            </div>
        </div>
    )
}

export default withTranslation()(Landlord);
