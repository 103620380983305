import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

import {Redirect} from 'react-router';
import {io, sendMessage} from '../../socket/connect';

// import Landlord from '../../components/05Messages/Landlord';
import Messages from '../../components/05Messages/Messages';

import {connect} from 'react-redux';
import {getSpaceDetail} from "../../utils/04Api/Space";

import {find} from 'lodash';
import {mongoIdTimestamp} from "../../utils/00General/Helpers";

import {getUserInfo} from "../../utils/04Api/User";

import {getSpaceBookings, sendM8Email} from "../../utils/04Api/Booking";
import {renderListingTitle} from "../../utils/00General/Helpers";
import {enterpriseContactUsSubmit} from "../../utils/04Api/Contactus";

class Chat extends Component {
    constructor(props) {
        super(props);

        let redirect = true,
            myId = null,
            isOwner = false;

        if (this.props.members.Login.length !== 0) {
            redirect = false;
            myId = props.members.Login.user._id;
        }

        this.state = {
            loading: true,
            isOwner: isOwner,
            ownerId: null,
            myId: myId,
            toId: null,
            spaceId: props.space_id,
            roomId: '',
            message: '',
            messages: [],
            space: null,
            bookings: [],
            redirect: redirect,
            toUser: null,
            filterError: false,
            havePending: true,
            contactUsBookingType: 'Monthly',
            carplate: ''
        }

        this.setRedirect = this.setRedirect.bind(this);
        this.setupSocket = this.setupSocket.bind(this);

        this.lastMsgRef = React.createRef();
    }

    scrollToRef(ref) {
        setTimeout(() => {
            ref.current && window.scrollTo(0, ref.current.offsetTop)
        }, 100)
    }

    componentDidMount() {
        if (!this.state.redirect) {
            this.getSpace(() => {
                this.setupSocket();
                this.getToUser();

                // this.interval = setInterval(()=> {
                //     if (this.props.members.Login.length !== 0) {
                //         this.readMsg();
                //     }
                // }, 3000);
            });
        }
    }

    componentWillUnmount() {
        if (!this.loading)
            this.leaveSocket();

        // if (typeof(this.interval) !== "undefined") {
        //     clearInterval(this.interval);
        // }
    }

    // componentWillUnmount() {
    //     // io.off('action')
    // }

    getToUser = (cb) => {
        let callback = (obj) => {
            if (obj.status === 200) {
                const toUser = obj.body;

                // console.log("touser", obj);
                this.setState({
                    ...this.state,
                    toUser: toUser,
                }, () => {
                    // console.log(toUser);
                    if (typeof (cb) === "function")
                        cb();
                })
            }
        }
        getUserInfo(this.state.toId, callback);
    }

    // readMsg = () => {
    //     readRoomMessages(this.props.members.Login.userToken, this.state.roomId);
    // }

    getBooking = () => {
        let callback = (res) => {
            // console.log('booking', res.body);
            if (res.status === 200) {
                let bookings = res.body;
                const userId = this.props.members.Login.user._id;
                let needCheck = true;
                let userBookings = [];

                // check if user has successful booking of this space, if so, disable message email / phone filtering
                Object.keys(bookings).forEach(key => {

                    let booking = bookings[key];

                    // console.log(booking);

                    if (booking.booker._id === userId || booking.owner._id === userId) {
                        userBookings.push(booking);
                    }

                    if (needCheck && (booking.booker._id === userId || booking.owner._id === userId)) {

                        if (booking.status === 'pendingStart' ||
                            booking.status === 'processing' ||
                            booking.status === 'depositReleasing' ||
                            booking.status === 'complete' ||
                            booking.status === 'cancelled' ||
                            booking.status === 'holdDeposit' ||
                            booking.status === 'completeWithDeposit') {

                            needCheck = false;
                        }
                    }
                });

                // console.log(needCheck);

                this.setState({
                    ...this.state,
                    havePending: needCheck,
                    bookings: userBookings
                });
            }
        }
        getSpaceBookings(this.props.members.Login.userToken, this.state.space._id, callback);
    }

    getSpace = (cb) => {
        let callback = (obj) => {
            if (obj.status === 200) {
                // console.log("getspacedetail", obj.body);

                const owner_id = obj.body.owner._id;

                if (this.state.myId !== this.props.user_id && this.state.myId !== owner_id) {
                    // not user or owner

                    // console.log(this.state.myId, this.props.user_id, this.state.myId, owner_id);
                    this.setRedirect();
                } else {
                    let isOwner, toId;
                    if (this.state.myId === owner_id) {
                        isOwner = true;
                        toId = this.props.user_id;
                    } else {
                        isOwner = false;
                        toId = owner_id;
                    }

                    // console.log("space", obj.body);

                    this.setState({
                        ...this.state,
                        space: obj.body,
                        ownerId: owner_id,
                        roomId: owner_id + "-" + this.props.user_id + "-" + this.props.space_id,
                        isOwner: isOwner,
                        toId: toId,
                    }, () => {
                        // this.readMsg();

                        if (typeof (cb) === "function")
                            cb();
                    })

                }

                this.getBooking();
            } else
                this.setRedirect();
        }
        getSpaceDetail(this.props.space_id, callback, this.setRedirect);
    }

    leaveSocket = () => {
        // io.emit('leaveroom', this.state.roomId);
        io.off('joinroomsuccess');
        io.off('output');
        io.off('messages');
        // io.off('read');
    }

    readRoomMessages = () => {
        let i = 1;
        const emit = () => {
            io.emit('readRoom', {rid: this.state.roomId, uid: this.state.myId});

            if (i < 10) {
                setTimeout(() => {
                    emit();
                }, 300);
                i++;
            }
        }
        emit();
    }

    setupSocket = () => {
        const room = {
            uid: this.state.space.owner._id,
            reid: this.props.user_id,
            sid: this.props.space_id,
        }

        io.emit('joinroom', room);

        io.on('joinroomsuccess', (data) => {
            this.setState({
                ...this.state,
                loading: false,
            }, () => {
                this.leaveSocket();

                io.emit('getmsg', {rid: this.state.roomId, order: 1});

                // io.on('readRoomResult', (data) => {
                //     console.log('readRoomResult', data);
                // })

                // io.on('action', (data) => {
                //     this.addMessage(data);
                // });

                // io.emit("checkroom", this.state.uid);
                //
                // io.on('roomlist', (data) => {
                //     console.log(data);
                // })

                io.on("output", (data) => {
                    // console.log('output', data);
                    if (typeof (data.roomID) === "undefined" || data.roomID !== this.state.roomId) {
                        // console.log('ignore msg');
                        return;
                    }

                    if (typeof (data.error) !== "undefined") {

                        this.setState({
                            ...this.state,
                            filterError: true
                        }, () => {

                            setTimeout(() => {

                                this.setState({
                                    ...this.state,
                                    filterError: false
                                });
                            }, 2000);
                        });

                        // console.log(333333);

                        return;
                    }

                    // console.log(this.state);

                    let messages = this.state.messages;
                    if (find(messages, {_id: data._id})) {
                        // console.log("duplicate message");
                        return;
                    }

                    messages.push(data);
                    this.setState({
                        ...this.state,
                        messages: messages,
                    }, () => {
                        this.getBooking();
                        this.readRoomMessages();
                    })

                    // this.readMsg();
                })

                io.on("messages", (data) => {
                    // console.log('messages', data);
                    //
                    // console.log(data.rid, this.state.roomId, data.msgs);
                    //
                    if (typeof (data.rid) !== "undefined" && typeof (data.msgs) !== "undefined") {
                        if (this.state.roomId === data.rid) {
                            // set room messages as read
                            this.readRoomMessages();

                            this.setState({
                                ...this.state,
                                messages: data.msgs
                            }, () => {
                                if (data.msgs.length) {
                                    this.scrollToRef(this.lastMsgRef)
                                    // console.log("gotoref", this.lastMsgRef);
                                }

                                this.getBooking();
                            })
                        }
                    }
                })
            })
        });
    }

    // processMessage = (message)=>{
    //     // TODO : process messages here instead of card
    // }

    setRedirect() {
        this.setState({
            ...this.state,
            redirect: true
        })
    }

    // General
    updateMessageArea = (message) => {
        this.setState({
            ...this.state,
            message: message
        })
    }

    send = () => {
        // console.log("send");
        if (!this.state.loading && this.state.message !== "") {
            // console.log("sendMessage");
            sendMessage(this.state.roomId, this.state.myId, this.state.toId, this.state.message, this.state.havePending, () => {
                // console.log("sent");
                // alert("Send M8");
                sendM8Email(this.props.members.Login.userToken, this.state.spaceId, this.props.members.Login.user._id, this.state.toUser._id);

                this.updateMessageArea("");
            })
        }
    }

    renderEnquiryBoxForEnterprise = () => {
        const {
            i18n,
            t,
            space_id,
            user_id
        } = this.props;

        return (
            <div className="enquiry clearfix">
                <h4>{t("messenger:general.booking_type")}</h4>
                <select
                    value={this.state.contactUsBookingType}
                    onChange={(e) => this.setState({contactUsBookingType: e.target.value})}
                >
                    <option value={"Monthly"}>{t("messenger:general.booking_type_monthly")}</option>
                    <option value={"Hourly"}>{t("messenger:general.booking_type_hourly")}</option>
                </select>

                <h4 style={{marginTop: 10}}>{t("messenger:general.car_plate")}<span style={{color: 'red'}}>*</span></h4>
                <input
                  className="input"
                  type="text"
                  onChange={(e) => this.setState({carplate: e.target.value})}
                  value={this.state.carplate}
                  placeholder={t("messenger:general.type_a_carplate")}
                />

                <textarea
                    className="messageEntry"
                    onChange={(e) => this.setState({message: e.target.value})}
                    value={this.state.message}
                    placeholder={t("messenger:general.type_a_message")}
                    maxLength={1000}
                />

                <div
                    className="messageSend button"
                    onClick={(e) => {
                        let body = {
                            space_id: (this.state.space || {}).enterprise_id || '',
                            name: `${this.props.members.Login.user.first_name} ${this.props.members.Login.user.last_name}`,
                            phone: this.props.members.Login.user.phone,
                            email: this.props.members.Login.user.email,
                            message: this.state.message,
                            newsletter: false,
                            bookingType: this.state.contactUsBookingType,
                            origin: 'Aggregator',
                            carplate: this.state.carplate
                        };
                        if (body.carplate === '') {
                            alert(t('messenger:general.empty_carplate'))
                        } else {
                            enterpriseContactUsSubmit(body, response => {
                                this.setState({message: '', carplate: ''});
                                alert(t('messenger:general.contact_us_success'));
                            }, error => {
                                console.log(error);
                                alert(t('messenger:general.contact_us_failed'));
                            })
                        }
                    }}
                >{t("messenger:general.send")}</div>

                <h5>* {t("messenger:general.contact_us_remarks")}</h5>
            </div>
        )
    };

    render() {
        const {
            i18n,
            t,
            space_id,
            user_id
        } = this.props;

        if (!space_id || !user_id)
            return <Redirect to={"/" + i18n.language + "/home"}/>;

        if (this.props.members.Login.length === 0)
            return <Redirect to={"/" + i18n.language + "/login"}/>;

        if (this.state.loading) {
            return (
                <div className="wrapper-container messages">
                    <div className="containerMain">
                        <div>{t("common:Loading")}</div>
                    </div>
                </div>
            );
        }

        // if (this.state.space && this.state.myId === this.state.space.owner._id)
        //     return <div>no space {space_id} / uid {user_id} / owner id</div>;
        //     // return <Redirect to={"/" + i18n.language + "/space/" + space_id} />;

        if (this.state.redirect)
            return <Redirect to={"/" + i18n.language + "/home"}/>;

        let img = "",
            toUserName = "",
            toUserId = "";

        if (this.state.toUser) {
            if (typeof (this.state.toUser.image) === "undefined")
                img = require('../../images/blank.png');
            else
                img = this.state.toUser.image;

            if (this.state.toUser.isCorp)
                toUserName = this.state.toUser.companyname;
            else
                toUserName = this.state.toUser.first_name;
            toUserId = this.state.toUser._id;
        }

        // console.log(this.state.filterError);

        return (
            <div className="wrapper-container messages">
                {this.state.space &&
                <div className="containerMain">
                    <div className="spaceInfo clearfix">
                        <div className="person right">
                            <div className="image">
                                <a href={"/" + i18n.language + "/landlordProfile/" + toUserId}>
                                    <img src={img} alt=""/>
                                </a>
                            </div>

                            <h3>{toUserName}</h3>

                            <div
                                className="memberSince">{t("contactOwner:memberSince")}<span>{mongoIdTimestamp(toUserId, "YYYY-MM-DD")}</span>{t("contactOwner:memberBecome")}
                            </div>
                        </div>
                        <div className="space left">
                            <a href={"/" + i18n.language + "/space/" + this.state.space._id}>
                                <h3>{renderListingTitle(this.state.space, i18n.language)}</h3>
                            </a>
                            <h4>{this.state.space.address}</h4>
                            {/*<span className="spaceId">{t("contactOwner:spaceID")} - {this.state.space._id}</span>*/}
                        </div>
                    </div>

                    <div className="dialogues clearfix">

                        {
                            !(this.state.space || {}).enterprise_id || this.state.space.owner._id === this.state.myId ? (
                                <Messages
                                    messages={this.state.messages}
                                    myId={this.state.myId}
                                    space={this.state.space}
                                    lastMsgRef={this.lastMsgRef}
                                    toUserName={toUserName}
                                    user={this.props.members.Login.user}
                                    toUser={this.state.toUser}
                                    bookings={this.state.bookings}
                                />
                            ) : this.renderEnquiryBoxForEnterprise()
                        }

                        {
                            !(this.state.space || {}).enterprise_id ? (
                                <div className="wrapper-messageEntry">
                                    <textarea
                                        className="messageEntry"
                                        onChange={(e) => this.updateMessageArea(e.target.value)}
                                        value={this.state.message}
                                        placeholder={t("messenger:general.type_a_message")}
                                        maxLength={1000}
                                    />

                                    <div
                                        className="messageSend button"
                                        onClick={(e) => this.send()}
                                    >{t("messenger:general.send")}</div>
                                </div>
                            ) : (
                                <div className="wrapper-messageEntry">
                                    <textarea
                                        disabled
                                        className="messageEntry"
                                        value={t(this.state.space.owner._id === this.state.myId ? "messenger:general.not_support_owner" : "messenger:general.not_support_parker") + (this.state.space.owner._id === this.state.myId ? "" : `\n ${this.state.space.owner.email}`)}
                                        maxLength={1000}
                                    />
                                </div>
                            )
                        }
                    </div>

                    {this.state.filterError &&
                    <div className="clearfix">{t("messenger:error.filter")}</div>
                    }
                </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {
        members: state.members,
    }
);

export default withTranslation()(connect(mapStateToProps)(Chat));
