import React, {Component} from 'react';
import {Redirect} from 'react-router';
import {Tabs, Tab} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';

import moment from 'moment';

import ThreeSteps from '../../components/04ListASpace/ThreeSteps';
import PrevNext from '../../components/04ListASpace/PrevNext';
import TabHourly from './TabHourly';
import TabMonthly from './TabMonthly';
import TabTimeshare from './TabTimeshare';
import GoogleApiWrapper from '../../components/103Parts/GoogleMap';
import ErrorMessages from '../../components/04ListASpace/ErrorMessages';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as ListParkingSpaceCreators from '../../actions/listParkingSpace';
import {getHoliday} from "../../utils/04Api/Holiday";
import ReactPixel from "react-facebook-pixel";
import {checkForOwnerPromotionCode} from "../../utils/04Api/Promotion";

// import DetailHourly from "../03FindParkingSpace/DetailHourly";

class FormHourly extends Component {
    constructor(props) {
        super(props);
        const {dispatch} = props;

        // DISPATCH TO ACTIONS
        this.SubmitStep2 = bindActionCreators(ListParkingSpaceCreators.SubmitStep2, dispatch);

        const isCorp = typeof (this.props.members.Login.user) !== "undefined" ? this.props.members.Login.user.isCorp : null;

        this.state = {
            formSubmitted: false,
            BookingType: {
                Hourly: true,
                Monthly: false,
                Timeshare: false,
            },
            InstantBooking: 'Accept',
            InstantBookingTips: false,
            ActiveTab: 1,

            HourlyTab: {
                Availability: {
                    SelectedDateRange: {
                        DateStart: '',
                        DateEnd: '',
                    },
                    AlwaysAvailable: isCorp ? 'No' : 'Yes',
                    AvailableDayTime: [{
                        SelectedDayOfWeek: 'MonFri',
                        CustomDates: [],
                        SelectedTimeRange: {
                            TimeStart: '00:00',
                            TimeEnd: '24:00',
                        },
                        SelectedTimeRange2: null,
                    }],
                    BlockedDateRange: [{
                        DateStart: '',
                        DateEnd: ''
                    }],
                    Price: [[0, 0], [0, 0]],
                },
                Pricing: {
                    WeekOfDays: ['Mon', 'Fri'],
                    price: '',
                    minBooking: 1,
                    deposit: 0,
                },
                CalendarData: {
                    AvailableDates: [],
                    OnDates: [],
                    CustomDates: [],
                    Editing: null,
                    EditingDate: false,
                },
                Promotion: null,
            },

            MonthlyTab: {
                Availability: {
                    SelectedDateRange: {
                        DateStart: '',
                        DateEnd: '',
                    },
                    BlockedDateRange: [{
                        DateStart: '',
                        DateEnd: ''
                    }],
                },
                Pricing: {
                    price: '',
                    minBooking: 1,
                    // fullCalendarMonth: 'Yes',
                    deposit: 0,
                },
                Promotion: null,
            },

            TimeshareTab: {
                Availability: {
                    SelectedDateRange: {
                        DateStart: '',
                        DateEnd: '',
                    },
                    AvailableDayTime: [{
                        SelectedDayOfWeek: 'MonFri',
                        CustomDates: [],
                        SelectedTimeRange: {
                            TimeStart: '00:00',
                            TimeEnd: '24:00',
                        },
                    }],
                    BlockedDateRange: [{
                        DateStart: '',
                        DateEnd: ''
                    }],
                },
                Pricing: {
                    price: '',
                    minBooking: 1,
                    fullCalendarMonth: 'Yes',
                    deposit: 0,
                },
                Promotion: null,
            },
            PromotionValid: false,
            holiday: [],
            isCorp: isCorp,
            Errors: [],
        }

        // Hourly Tab
        this.HourlyTabHandleChange = this.HourlyTabHandleChange.bind(this);
        this.HourlyTabChangeStateLevelOne = this.HourlyTabChangeStateLevelOne.bind(this);
        this.HourlyTabChangeStateLevelTwoA = this.HourlyTabChangeStateLevelTwoA.bind(this);
        this.HourlyTabChangeStateLevelTwoB = this.HourlyTabChangeStateLevelTwoB.bind(this);
        this.HourlyTabChangeStateRadioLevelOne = this.HourlyTabChangeStateRadioLevelOne.bind(this);
        this.HourlyTabHandleDaysOfWeek = this.HourlyTabHandleDaysOfWeek.bind(this);
        this.TabChangeBlockDate = this.TabChangeBlockDate.bind(this);
        this.TabAddUnavailability = this.TabAddUnavailability.bind(this);
        this.TabRemoveUnavailability = this.TabRemoveUnavailability.bind(this);
        this.TabAddDayTime = this.TabAddDayTime.bind(this);
        this.TabDeleteDayTime = this.TabDeleteDayTime.bind(this);
        this.TabChangeAvailableDay = this.TabChangeAvailableDay.bind(this);
        this.TabChangeAvailableTime = this.TabChangeAvailableTime.bind(this);
        this.TabHandleCustomDayTime = this.TabHandleCustomDayTime.bind(this);
        this.HourlyCalendarHandleEventClick = this.HourlyCalendarHandleEventClick.bind(this);
        this.HourlyCalendarHandleCloseEditor = this.HourlyCalendarHandleCloseEditor.bind(this);
        this.HourlyCalendarHandleSetCustomAvailableDates = this.HourlyCalendarHandleSetCustomAvailableDates.bind(this);

        this.TabAddAvailableTime = this.TabAddAvailableTime.bind(this);
        this.TabRemoveAvailableTime = this.TabRemoveAvailableTime.bind(this);

        // Monthly Tab
        this.MonthlyTabHandleChange = this.MonthlyTabHandleChange.bind(this);
        this.MonthlyTabChangeStateLevelOne = this.MonthlyTabChangeStateLevelOne.bind(this);
        this.MonthlyTabChangeStateRadioLevelOne = this.MonthlyTabChangeStateRadioLevelOne.bind(this);

        // Timeshare Tab
        this.TimeshareTabHandleChange = this.TimeshareTabHandleChange.bind(this);
        this.TimeshareTabChangeStateLevelOne = this.TimeshareTabChangeStateLevelOne.bind(this);
        this.TimeshareTabChangeStateLevelTwoA = this.TimeshareTabChangeStateLevelTwoA.bind(this);
        this.TimeshareTabChangeStateLevelTwoB = this.TimeshareTabChangeStateLevelTwoB.bind(this);
        this.TimeshareTabChangeStateRadioLevelOne = this.TimeshareTabChangeStateRadioLevelOne.bind(this);

        // console.log(this.props.listParkingSpace.StepOne);
    }

    componentDidMount = () => {
        // const storageData = JSON.parse(sessionStorage.getItem('state'));
        // console.log(storageData);

        this.ApiGetHoliday();

        if (this.props.listParkingSpace.StepTwo.length !== 0) {

            // console.log("KKK", this.props.listParkingSpace.StepTwo.MonthlyTab);

            this.setState(this.props.listParkingSpace.StepTwo)
            this.setState({
                formSubmitted: false,
                Errors: [],
            });
        }

        ReactPixel.trackCustom('ListSpace', {
            content_name: 'step2',
        });
    }

    componentDidUpdate = () => {
        // sessionStorage.setItem('state', JSON.stringify(this.state));
        // console.log(this.state);
    }

    ApiCheckForOwnerPromotionCode = (nextStep, errorCallback) => {

        console.log(this.state.HourlyTab.Promotion);

        let callback = (res) => {

            console.log("promotion", res);

            if (res.status === 200) {

                this.setState({
                    ...this.state,
                    PromotionValid: true
                }, nextStep);
            } else if (res.status === 400) {

                let errors = [];

                errors.push({
                    field: "Promotion",
                    message: this.props.t("listASpace:errors.step2.tempPromotion")
                });

                this.setState({
                    ...this.state,
                    Errors: errors
                });
            }
        }

        checkForOwnerPromotionCode(
            this.props.members.Login.userToken,
            this.state.HourlyTab.Promotion !== '' ? this.state.HourlyTab.Promotion : null,
            this.state.MonthlyTab.Promotion !== '' ? this.state.MonthlyTab.Promotion : null,
            this.state.TimeshareTab.Promotion !== '' ? this.state.TimeshareTab.Promotion : null,
            callback,
            errorCallback
        );
    }

    // OVERALL
    changeStateLevelOne = (data1, value) => {
        this.setState({
            [data1]: value
        })
    }

    changeStateLevelTwo = (data1, data2, value, cb) => {

        this.setState({
            [data1]: {
                ...this.state[data1],
                [data2]: value
            }
        }, () => {
            if (typeof (cb) === "function")
                cb();
        });
    }

    changeStateRadioLevelOne = (data1, data2) => {
        this.setState({
            [data1]: data2
        })
    }

    changeStateRadioLevelTwo = (data1, data2, value) => {
        this.setState({
            [data1]: {
                ...this.state[data1],
                [data2]: value
            }
        })
    }

    changeStateCheckboxLevelOne = (data1, data2) => {

        if (data2 === 'Hourly') {
            // ignore if hourly is the only tab left
            if (this.state.BookingType.Hourly === true &&
                this.state.BookingType.Monthly === false &&
                this.state.BookingType.Timeshare === false) {
                return;
            }

            if (this.state[data1][data2] === false) {
                this.setState({
                    ActiveTab: 1,
                    [data1]: {
                        ...this.state[data1],
                        [data2]: !this.state[data1][data2]
                    }
                })
            } else {
                if (this.state.BookingType.Monthly === true) {
                    this.setState({
                        ActiveTab: 2,
                        [data1]: {
                            ...this.state[data1],
                            [data2]: !this.state[data1][data2]
                        }
                    })
                } else if (this.state.BookingType.Timeshare === true) {
                    this.setState({
                        ActiveTab: 3,
                        [data1]: {
                            ...this.state[data1],
                            [data2]: !this.state[data1][data2]
                        }
                    })
                } else {
                    this.setState({
                        ActiveTab: 1,
                        [data1]: {
                            ...this.state[data1],
                            [data2]: !this.state[data1][data2]
                        }
                    })
                }
            }
        } else if (data2 === 'Monthly') {
            // ignore if hourly is the only tab left
            if (this.state.BookingType.Hourly === false &&
                this.state.BookingType.Monthly === true &&
                this.state.BookingType.Timeshare === false) {
                return;
            }

            if (this.state[data1][data2] === false) {
                this.setState({
                    ActiveTab: 2,
                    [data1]: {
                        ...this.state[data1],
                        [data2]: !this.state[data1][data2]
                    }
                })
            } else {
                if (this.state.BookingType.Hourly === true) {
                    this.setState({
                        ActiveTab: 1,
                        [data1]: {
                            ...this.state[data1],
                            [data2]: !this.state[data1][data2]
                        }
                    })
                } else if (this.state.BookingType.Timeshare === true) {
                    this.setState({
                        ActiveTab: 3,
                        [data1]: {
                            ...this.state[data1],
                            [data2]: !this.state[data1][data2]
                        }
                    })
                } else {
                    this.setState({
                        ActiveTab: 2,
                        [data1]: {
                            ...this.state[data1],
                            [data2]: !this.state[data1][data2]
                        }
                    })
                }
            }
        } else if (data2 === 'Timeshare') {
            // ignore if hourly is the only tab left
            if (this.state.BookingType.Hourly === false &&
                this.state.BookingType.Monthly === false &&
                this.state.BookingType.Timeshare === true) {
                return;
            }

            if (this.state[data1][data2] === false) {
                this.setState({
                    ActiveTab: 3,
                    [data1]: {
                        ...this.state[data1],
                        [data2]: !this.state[data1][data2]
                    }
                })
            } else {
                if (this.state.BookingType.Hourly === true) {
                    this.setState({
                        ActiveTab: 1,
                        [data1]: {
                            ...this.state[data1],
                            [data2]: !this.state[data1][data2]
                        }
                    })
                } else if (this.state.BookingType.Monthly === true) {
                    this.setState({
                        ActiveTab: 2,
                        [data1]: {
                            ...this.state[data1],
                            [data2]: !this.state[data1][data2]
                        }
                    })
                } else {
                    this.setState({
                        ActiveTab: 3,
                        [data1]: {
                            ...this.state[data1],
                            [data2]: !this.state[data1][data2]
                        }
                    })
                }
            }
        } else {
            this.setState({
                [data1]: {
                    ...this.state[data1],
                    [data2]: !this.state[data1][data2]
                }
            })
        }
    }

    changeStateCheckboxLevelTwo = (data1, data2) => {
        this.setState({
            [data1]: {
                ...this.state[data1],
                [data2]: !this.state[data1][data2]
            }
        })
    }

    changeTips(data) {
        this.setState({
            ...this.state,
            [data]: !this.state[data]
        })
    }

    handleTabSelect = (data) => {
        this.setState({
            ActiveTab: data
        })
    }

    // HourlyTab: {
    // 	Availability: {
    // 		SelectedDateRange: {
    // 			DateStart: '',
    // 			DateEnd: '',
    // 		},
    // 		AlwaysAvailable: 'Yes',
    // 		AvailableDayTime: [{
    // 			SelectedDayOfWeek: 'MonFri',
    // 			CustomDates: [],
    //

    GetDefaultCalendarData() {
        return {
            AvailableDates: [],
            OnDates: [],
            CustomDates: [],
            Editing: null,
            EditingDate: false,
        };
    }

    // HOURLY TAB
    HourlyTabHandleChange(data1, data2, date) {
        //console.log("reset calendar custom dates");

        if (data2 === 'DateStart') {
            if (typeof (this.state.HourlyTab.Availability.SelectedDateRange.DateEnd) !== "undefined") {
                if (date.isAfter(this.state.HourlyTab.Availability.SelectedDateRange.DateEnd)) {

                    this.setState({
                        ...this.state,
                        HourlyTab: {
                            ...this.state.HourlyTab,
                            Availability: {
                                ...this.state.HourlyTab.Availability,
                                [data1]: {
                                    ...this.state.HourlyTab.Availability[data1],
                                    [data2]: date,
                                    DateEnd: ''
                                }
                            },
                            CalendarData: this.GetDefaultCalendarData(),
                        }
                    }, () => {
                        this.updateHourlyCalendar();
                    })
                    return;
                }
            }
        }

        this.setState({
            ...this.state,
            HourlyTab: {
                ...this.state.HourlyTab,
                Availability: {
                    ...this.state.HourlyTab.Availability,
                    [data1]: {
                        ...this.state.HourlyTab.Availability[data1],
                        [data2]: date
                    }
                },
                CalendarData: this.GetDefaultCalendarData(),
            }
        }, () => {
            this.updateHourlyCalendar();
        })
    }

    HourlyTabChangePrice = (value, index, i) => {
        let prices = this.state.HourlyTab.Availability.Price;

        value = parseInt(value) ? parseInt(value) : '';
        if (value > 200)
            value = 200;

        prices[index][i] = value;

        this.setState({
            ...this.state,
            HourlyTab: {
                ...this.state.HourlyTab,
                Availability: {
                    ...this.state.HourlyTab.Availability,
                    Price: prices
                }
            }
        })
    }

    HourlyTabChangeStateLevelOne = (data1, value) => {
        if (data1 === 'price') {
            value = parseInt(value) ? parseInt(value) : '';

            if (value > 200)
                value = 200;
        }

        this.setState({
            ...this.state,
            HourlyTab: {
                ...this.state.HourlyTab,
                Pricing: {
                    ...this.state.HourlyTab.Pricing,
                    [data1]: value,
                }
            }
        })
    }

    HourlyTabChangeStateLevelTwoA = (data1, value) => {
        this.setState({
            ...this.state,
            HourlyTab: {
                ...this.state.HourlyTab,
                Availability: {
                    ...this.state.HourlyTab.Availability,
                    [data1]: value
                }
            }
        })
    }

    HourlyTabChangeStateLevelTwoB = (data1, data2, value) => {
        this.setState({
            ...this.state,
            HourlyTab: {
                ...this.state.HourlyTab,
                Availability: {
                    ...this.state.HourlyTab.Availability,
                    [data1]: {
                        ...this.state.HourlyTab.Availability[data1],
                        [data2]: value
                    },
                }
            }
        })
    }

    // ------- functions to handle available day time --------
    TabUpdateAvailableDayTime = (tab, availableDayTime) => {
        // console.log("e");
        // console.log(this.state.HourlyTab);
        this.setState({
            ...this.state,
            [tab]: {
                ...this.state[tab],
                Availability: {
                    ...this.state[tab].Availability,
                    AvailableDayTime: availableDayTime
                }
            }
        }, () => {
            if (tab === 'HourlyTab')
                this.updateHourlyCalendar();
        })
    }

    TabChangeAvailableDay = (tab, value, index) => {
        let tabData = this.state[tab],
            availableDayTime = tabData.Availability.AvailableDayTime;
        availableDayTime[index].SelectedDayOfWeek = value;

        if (availableDayTime[0].SelectedDayOfWeek !== 'MonFri' && availableDayTime[0].SelectedDayOfWeek !== 'SatSunPH')
            availableDayTime = [availableDayTime[0]];

        if (availableDayTime[index].SelectedDayOfWeek === 'Custom')
            availableDayTime[index].CustomDates = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'PH'];

        this.TabUpdateAvailableDayTime(tab, availableDayTime);
    }

    TabChangeAvailableTime = (tab, type, value, index) => {
        let tabData = this.state[tab],
            availableDayTime = tabData.Availability.AvailableDayTime;
        availableDayTime[index].SelectedTimeRange[type] = value;

        if (type === 'TimeEnd' && availableDayTime[index].SelectedTimeRange2)
            availableDayTime[index].SelectedTimeRange2['TimeStart'] = value;

        if (type === 'MaxHour' && !!availableDayTime[index].SelectedTimeRange2)
            availableDayTime[index].SelectedTimeRange2['MaxHour'] = null;

        this.TabUpdateAvailableDayTime(tab, availableDayTime);
    }

    TabChangeAvailableTime2 = (tab, type, value, index) => {
        let tabData = this.state[tab],
            availableDayTime = tabData.Availability.AvailableDayTime;
        availableDayTime[index].SelectedTimeRange2[type] = value;

        if (type === 'TimeStart')
            availableDayTime[index].SelectedTimeRange['TimeEnd'] = value;

        if (type === 'MaxHour')
            availableDayTime[index].SelectedTimeRange['MaxHour'] = null;

        this.TabUpdateAvailableDayTime(tab, availableDayTime);
    }

    TabHandleCustomDayTime = (tab, value) => {
        let tabData = this.state[tab],
            availableDayTime = tabData.Availability.AvailableDayTime,
            index = availableDayTime[0].CustomDates.indexOf(value);

        if (index < 0)
            availableDayTime[0].CustomDates.push(value);
        else if (availableDayTime[0].CustomDates.length > 1)
            availableDayTime[0].CustomDates.splice(index, 1);

        this.TabUpdateAvailableDayTime(tab, availableDayTime);
    }

    TabAddDayTime = (tab) => {
        let tabData = this.state[tab],
            availableDayTime = tabData.Availability.AvailableDayTime;

        let SelectedDayOfWeek = 'MonFri';
        if (availableDayTime[0].SelectedDayOfWeek === 'MonFri')
            SelectedDayOfWeek = 'SatSunPH';
        if (availableDayTime.length === 1) {
            availableDayTime.push({
                SelectedDayOfWeek: SelectedDayOfWeek,
                CustomDates: [],
                SelectedTimeRange: {
                    TimeStart: '00:00',
                    TimeEnd: '24:00',
                },
            });
        }
        this.TabUpdateAvailableDayTime(tab, availableDayTime);
    }

    TabDeleteDayTime = (tab) => {
        let tabData = this.state[tab],
            availableDayTime = tabData.Availability.AvailableDayTime;

        availableDayTime.splice(availableDayTime, 1);

        tabData.Availability.AvailableDayTime = availableDayTime;

        this.setState({
            ...this.state,
            [tab]: tabData
        });
    }
    // -------  EOF functions to handle available day time --------

    // ------- Functions to handle blocked dates -------
    TabSetBlockDateState = (tab, blockedDate) => {
        this.setState({
            ...this.state,
            [tab]: {
                ...this.state[tab],
                Availability: {
                    ...this.state[tab].Availability,
                    BlockedDateRange: blockedDate
                }
            }
        }, () => {
            if (tab === 'HourlyTab')
                this.updateHourlyCalendar();
        })
    }

    TabChangeBlockDate = (tab, key, value, index) => {
        let tabData = this.state[tab],
            blockedDate = tabData.Availability.BlockedDateRange;
        blockedDate[index][key] = value;

        if (key === 'DateStart') {
            if (typeof (blockedDate[index].DateEnd) !== "undefined") {
                if (moment(value).isAfter(moment(blockedDate[index].DateEnd))) {
                    blockedDate[index].DateEnd = '';
                }
            }
        }

        this.TabSetBlockDateState(tab, blockedDate);
    }

    TabAddUnavailability = (tab) => {
        let tabData = this.state[tab],
            blockedDate = tabData.Availability.BlockedDateRange;
        blockedDate.push({
            DateStart: '',
            DateEnd: '',
        });

        this.TabSetBlockDateState(tab, blockedDate);
    }

    TabRemoveUnavailability = (tab, index) => {
        let tabData = this.state[tab],
            blockedDate = tabData.Availability.BlockedDateRange;
        blockedDate.splice(index, 1);
        this.TabSetBlockDateState(tab, blockedDate);
    }

    TabAddAvailableTime = (tab, index) => {
        let tabData = this.state[tab],
            days = tabData.Availability.AvailableDayTime;

        if (!tabData.Availability.AvailableDayTime[index].SelectedTimeRange2) {
            let startTime = 12;
            if (parseInt(days[index].SelectedTimeRange.TimeEnd.substr(0, 2)) > 12) {
                tabData.Availability.AvailableDayTime[index].SelectedTimeRange.TimeEnd = "12:00";

                if (parseInt(days[index].SelectedTimeRange.TimeStart.substr(0, 2)) >= 12)
                    tabData.Availability.AvailableDayTime[index].SelectedTimeRange.TimeStart = "00:00";
            } else {
                startTime = parseInt(days[index].SelectedTimeRange.TimeEnd.substr(0, 2));
            }

            tabData.Availability.AvailableDayTime[index].SelectedTimeRange2 = {
                TimeStart: startTime + ':00',
                TimeEnd: '24:00',
            };

            this.setState({
                ...this.state,
                [tab]: tabData
            }, () => {
                if (tab === 'HourlyTab')
                    this.updateHourlyCalendar();
            })
        }

        // blockedDate.push({
        //     DateStart: '',
        //     DateEnd: '',
        // });
        //
        // this.TabSetBlockDateState(tab, blockedDate);
    }

    TabRemoveAvailableTime = (tab, index, i) => {
        let tabData = this.state[tab];
        // days = tabData.Availability.AvailableDayTime;

        if (i === 2) {
            // remove second record
            tabData.Availability.AvailableDayTime[index].SelectedTimeRange2 = null;
        } else {
            // remove first record
            // check if there is a second first
            if (tabData.Availability.AvailableDayTime[index].SelectedTimeRange2) {
                tabData.Availability.AvailableDayTime[index].SelectedTimeRange = tabData.Availability.AvailableDayTime[index].SelectedTimeRange2;
                tabData.Availability.AvailableDayTime[index].SelectedTimeRange2 = null;
            }
        }

        this.setState({
            ...this.state,
            [tab]: tabData
        }, () => {
            if (tab === 'HourlyTab')
                this.updateHourlyCalendar();
        })
    }
    //
    // HourlyTab: {
    //     Availability: {
    //         SelectedDateRange: {
    //             DateStart: '',
    //             DateEnd: '',
    //         },
    //         AlwaysAvailable: isCorp ? 'No' : 'Yes',
    //         AvailableDayTime: [{
    //             SelectedDayOfWeek: 'MonFri',
    //             CustomDates: [],
    //             SelectedTimeRange: {
    //                 TimeStart: '00:00',
    //                 TimeEnd: '24:00',
    //             },
    //             SelectedTimeRange2: null,
    //         }],

    HourlyCheckHourlyDateIsBlocked(date) {
        // console.log("check date " + date.format("YYYY-MM-DD"));
        const blockDates = this.state.HourlyTab.Availability.BlockedDateRange;
        let blocked = false;
        blockDates.forEach((blockDate) => {
            if (blockDate.DateStart !== "" && blockDate.DateEnd !== "") {
                // console.log(blockDate.DateStart.format("YYYY-MM-DD") + " - " + blockDate.DateEnd.format("YYYY-MM-DD"));
                if (date >= blockDate.DateStart && date <= blockDate.DateEnd) {
                    // console.log("Blocked");
                    blocked = true;
                }
            }
        })
        return blocked;
    }

    // ------- EOF Functions to handle  blocked dates -------


    // -------- Functions to handle custom calendar edits -------
    HourlyUpdateHourlyTab(HourlyTab) {
        this.setState({
            ...this.state,
            HourlyTab: HourlyTab
        }, () => {
            this.updateHourlyCalendar();
        })
    }

    HourlyCheckHourlyDateIsAvailable(date, days) {
        //this.holiday
        for (let i = 0; i < days.length; i++) {
            let d = days[i];
            if ((d.ph && this.state.holiday.indexOf(date.format("YYYY-MM-DD")) >= 0) ||
                d.days.indexOf(parseInt(date.format("d"))) >= 0
            ) {
                let t = d.startTime + " - " + d.endTime;
                if (d.startTime2 && d.startTime2 !== "")
                    t += "\n" + d.startTime2 + " - " + d.endTime2;
                return t;
            }
        }
        return false;
    }

    HourlyCalendarHandleEventClick(value) {
        let HourlyTab = this.state.HourlyTab;
        HourlyTab.CalendarData.Editing = value;
        this.HourlyUpdateHourlyTab(HourlyTab);
    }

    HourlyCalendarHandleCloseEditor() {
        let HourlyTab = this.state.HourlyTab;
        if (HourlyTab.CalendarData.Editing !== null) {
            HourlyTab.CalendarData.Editing = null;
            this.HourlyUpdateHourlyTab(HourlyTab);
        }
    }

    HourlyCalendarHandleSetCustomAvailableDates(date, value) {
        let HourlyTab = this.state.HourlyTab,
            CustomDates = HourlyTab.CalendarData.CustomDates;

        HourlyTab.CalendarData.Editing = null;

        let momentDate = date;
        if (typeof (date) === "string")
            momentDate = moment(date);

        // find if date already exists
        let index = CustomDates.findIndex(d => {
            let c = d.Date;
            if (typeof (c) === "string")
                c = moment(d.Date);

            return c.format("YYYY-MM-DD") === momentDate.format("YYYY-MM-DD")
        });

        if (index >= 0) {
            CustomDates[index].Available = value;
        } else {
            CustomDates.push({
                Date: date,
                Available: value,
            })
        }

        // console.log(CustomDates);

        HourlyTab.CalendarData.CustomDates = CustomDates;

        this.HourlyUpdateHourlyTab(HourlyTab);
    }

    HourlyCheckHourlyDateIsInCusomDates(date) {
        let CustomDates = this.state.HourlyTab.CalendarData.CustomDates;

        let index = CustomDates.findIndex(d => {
            let c = d.Date;
            if (typeof (c) === "string")
                c = moment(d.Date);

            return c.format("YYYY-MM-DD") === date.format("YYYY-MM-DD")
        });

        if (index >= 0) {
            // console.log(index, CustomDates[index]);
            return CustomDates[index];
        }
        return false;
    }

    // -------- EOF Functions to handle custom calendar edits -------

    updateHourlyCalendar() {
        let availableDates = [],
            onDates = [];

        const HourlyTab = this.state.HourlyTab,
            Availability = HourlyTab.Availability,
            startDate = Availability.SelectedDateRange.DateStart,
            endDate = Availability.SelectedDateRange.DateEnd;

        if (startDate !== '' && endDate !== '') {
            // generate available day of time array
            let days = [];
            if (Availability.AlwaysAvailable !== "Yes") {
                Availability.AvailableDayTime.forEach((dt) => {
                    let dayDetail = {
                        days: [],
                        ph: false,
                        startTime: dt.SelectedTimeRange.TimeStart,
                        endTime: dt.SelectedTimeRange.TimeEnd,
                        startTime2: dt.SelectedTimeRange2 && dt.SelectedTimeRange2.TimeStart !== "" ? dt.SelectedTimeRange2.TimeStart : null,
                        endTime2: dt.SelectedTimeRange2 && dt.SelectedTimeRange2.TimeEnd !== "" ? dt.SelectedTimeRange2.TimeEnd : null,
                    }

                    switch (dt.SelectedDayOfWeek) {
                        case 'MonFri':
                            dayDetail.days.push(1, 2, 3, 4, 5);
                            break;
                        case 'SatSunPH':
                            dayDetail.days.push(6, 0);
                            dayDetail.ph = true;
                            break;
                        case 'MonSunPH':
                            dayDetail.days.push(1, 2, 3, 4, 5, 6, 0);
                            dayDetail.ph = true;
                            break;
                        case 'Custom':
                            dt.CustomDates.forEach((d) => {
                                switch (d) {
                                    case 'Mon':
                                        dayDetail.days.push(1);
                                        break;
                                    case 'Tue':
                                        dayDetail.days.push(2);
                                        break;
                                    case 'Wed':
                                        dayDetail.days.push(3);
                                        break;
                                    case 'Thu':
                                        dayDetail.days.push(4);
                                        break;
                                    case 'Fri':
                                        dayDetail.days.push(5);
                                        break;
                                    case 'Sat':
                                        dayDetail.days.push(6);
                                        break;
                                    case 'Sun':
                                        dayDetail.days.push(0);
                                        break;
                                    case 'PH':
                                        dayDetail.ph = true;
                                        break;
                                    default:
                                }
                            })
                            break;
                        default:
                    }
                    days.push(dayDetail);
                })
            }

            // Loop through startDate to endDate
            let date = moment(startDate),
                loopEndDate = moment(endDate);
            while (date <= loopEndDate) {

                // console.log(date.format("YYYY-MM-DD"));
                let ok = true,
                    title = "Available all day",
                    isCustomDate = this.HourlyCheckHourlyDateIsInCusomDates(date);

                if (isCustomDate !== false) {
                    // Is custom date
                    if (isCustomDate.Available === false) {
                        ok = false;
                    } else {
                        availableDates.push({
                            title: title,
                            start: date.clone(),
                            end: date.clone(),
                            allDay: true,
                            selectable: false,
                        });
                        onDates.push(date.format("YYYY-MM-DD"));
                    }
                } else if (!this.HourlyCheckHourlyDateIsBlocked(date)) {
                    // not blocked

                    if (Availability.AlwaysAvailable !== "Yes") {
                        // not always available
                        // check day is avilable in avilable days
                        let timeAvailable = this.HourlyCheckHourlyDateIsAvailable(date, days);
                        // console.log(timeAvailable);
                        if (timeAvailable) {
                            title = timeAvailable;
                        } else {
                            ok = false;
                        }
                    }

                    // day ok
                    if (ok) {
                        availableDates.push({
                            title: title,
                            start: date.clone(),
                            end: date.clone(),
                            allDay: true,
                            selectable: false,
                        });
                        onDates.push(date.format("YYYY-MM-DD"));
                    }
                }

                date.add(1, 'day');
            }
        }

        this.setState({
            ...this.state,
            HourlyTab: {
                ...this.state.HourlyTab,
                CalendarData: {
                    ...this.state.HourlyTab.CalendarData,
                    AvailableDates: availableDates,
                    OnDates: onDates,
                }
            }
        })
    }

    HourlyTabChangeStateRadioLevelOne(data1, data2, value) {
        // let HourlyTab = {...this.state.HourlyTab, }
        //
        //
        // let HourlyTab = this.state.HourlyTab;
        // HourlyTab[data1][data2] = value;
        // let calendarData = this.updateHourlyCalendar(HourlyTab);
        //
        // console.log(calendarData);
        //

        this.setState({
            ...this.state,
            HourlyTab: {
                ...this.state.HourlyTab,
                [data1]: {
                    ...this.state.HourlyTab[data1],
                    [data2]: value
                },
                CalendarData: this.GetDefaultCalendarData(),
            }
        }, () => {
            this.updateHourlyCalendar();
        })
    }

    HourlyTabHandleDaysOfWeek(value) {
        let array = this.state.HourlyTab.Pricing.WeekOfDays;
        let index = array.indexOf(value);

        if (index > -1) {
            array.splice(index, 1);
        } else {
            array.push(value);
        }

        this.setState({
            ...this.state,
            HourlyTab: {
                ...this.state.HourlyTab,
                Pricing: {
                    ...this.state.HourlyTab.Pricing,
                    WeekOfDays: array
                }
            }
        })
    }

    // MONTHLY TAB
    MonthlyTabHandleChange(data1, data2, date) {

        if (data2 === 'DateStart') {
            if (typeof (this.state.MonthlyTab.Availability.SelectedDateRange.DateEnd) !== "undefined") {
                if (date.isAfter(this.state.MonthlyTab.Availability.SelectedDateRange.DateEnd)) {

                    this.setState({
                        ...this.state,
                        MonthlyTab: {
                            ...this.state.MonthlyTab,
                            Availability: {
                                ...this.state.MonthlyTab.Availability,
                                [data1]: {
                                    ...this.state.MonthlyTab.Availability[data1],
                                    [data2]: date,
                                    DateEnd: ''
                                }
                            }
                        }
                    })
                    return;
                }
            }
        }

        this.setState({
            ...this.state,
            MonthlyTab: {
                ...this.state.MonthlyTab,
                Availability: {
                    ...this.state.MonthlyTab.Availability,
                    [data1]: {
                        ...this.state.MonthlyTab.Availability[data1],
                        [data2]: date
                    }
                }
            }
        })
    }

    MonthlyTabChangeStateLevelOne = (data1, value) => {
        if (data1 === 'price') {
            value = parseInt(value) ? parseInt(value) : '';

            if (value > 20000)
                value = 20000;
        }

        this.setState({
            ...this.state,
            MonthlyTab: {
                ...this.state.MonthlyTab,
                Pricing: {
                    ...this.state.MonthlyTab.Pricing,
                    [data1]: value,
                }
            }
        })
    }

    MonthlyTabChangeStateRadioLevelOne(data1, value) {
        if (data1 === 'price') {
            value = parseInt(value) ? parseInt(value) : '';

            if (value > 20000)
                value = 20000;
        }

        this.setState({
            ...this.state,
            MonthlyTab: {
                ...this.state.MonthlyTab,
                Pricing: {
                    ...this.state.MonthlyTab.Pricing,
                    [data1]: value
                }
            }
        })
    }

    // TIMESHARE TAB
    TimeshareTabHandleChange(data1, data2, date) {

        if (data2 === 'DateStart') {
            if (typeof (this.state.TimeshareTab.Availability.SelectedDateRange.DateEnd) !== "undefined") {
                if (date.isAfter(this.state.TimeshareTab.Availability.SelectedDateRange.DateEnd)) {

                    this.setState({
                        ...this.state,
                        TimeshareTab: {
                            ...this.state.TimeshareTab,
                            Availability: {
                                ...this.state.TimeshareTab.Availability,
                                [data1]: {
                                    ...this.state.TimeshareTab.Availability[data1],
                                    [data2]: date,
                                    DateEnd: ''
                                }
                            }
                        }
                    })
                    return;
                }
            }
        }

        this.setState({
            ...this.state,
            TimeshareTab: {
                ...this.state.TimeshareTab,
                Availability: {
                    ...this.state.TimeshareTab.Availability,
                    [data1]: {
                        ...this.state.TimeshareTab.Availability[data1],
                        [data2]: date
                    }
                }
            }
        })
    }

    TimeshareTabChangeStateLevelOne = (data1, value) => {
        if (data1 === 'price') {
            value = parseInt(value) ? parseInt(value) : '';

            if (value > 20000)
                value = 20000;
        }

        this.setState({
            ...this.state,
            TimeshareTab: {
                ...this.state.TimeshareTab,
                Pricing: {
                    ...this.state.TimeshareTab.Pricing,
                    [data1]: value,
                }
            }
        })
    }

    TimeshareTabChangeStateLevelTwoA = (data1, value) => {
        this.setState({
            ...this.state,
            TimeshareTab: {
                ...this.state.TimeshareTab,
                Availability: {
                    ...this.state.TimeshareTab.Availability,
                    [data1]: value
                }
            }
        })
    }

    TimeshareTabChangeStateLevelTwoB = (data1, data2, value) => {
        this.setState({
            ...this.state,
            TimeshareTab: {
                ...this.state.TimeshareTab,
                Availability: {
                    ...this.state.TimeshareTab.Availability,
                    [data1]: {
                        ...this.state.TimeshareTab.Availability[data1],
                        [data2]: value
                    },
                }
            }
        })
    }

    TimeshareTabChangeStateRadioLevelOne(data1, value) {
        this.setState({
            ...this.state,
            TimeshareTab: {
                ...this.state.TimeshareTab,
                Pricing: {
                    ...this.state.TimeshareTab.Pricing,
                    [data1]: value
                }
            }
        })
    }

    // Calendar
    ApiGetHoliday = (cb) => {
        if (typeof (cb) === "undefined")
            cb = () => {
                return;
            }
        let callback = (res) => {
            if (typeof (res.body) !== "undefined") {
                let holiday = this.state.holiday;
                // console.log(holiday);
                res.body.forEach((e) => {
                    holiday.push(e.date);
                })

                this.setState({
                    ...this.state,
                    holiday: holiday
                }, cb);
            }
        }

        getHoliday(callback);
    }

    isPublicHoliday = (date) => {
        let isHoliday = false;
        this.state.holiday.forEach((d) => {
            // console.log(date.utcOffset(8).format("YYYY-MM-DD"), d);
            if (date.utcOffset(8).format("YYYY-MM-DD") === d)
                isHoliday = true;
        })

        // if (isHoliday)
        //     console.log("Is Holiday: " + date.utcOffset(8).format("YYYY-MM-DD"))
        return isHoliday;
    }


    // SUBMIT FORM
    submitForm = (e) => {
        e.preventDefault();

        let nextStep = () => {

            const {
                t
            } = this.props;

            let data = this.state,
                errors = [];

            if (this.state.PromotionValid) {

                if (data.BookingType.Hourly) {
                    let tab = data.HourlyTab;
                    let minBooking = tab.Pricing.minBooking;

                    if (tab.Availability.SelectedDateRange.DateStart === "" || tab.Availability.SelectedDateRange.DateEnd === "")
                        errors.push({field: "Hourly.DateStart", message: t("listASpace:errors.step2.hourly")});

                    if (tab.Availability.BlockedDateRange.length > 0) {
                        for (let j = 0; j < tab.Availability.BlockedDateRange.length; j++) {
                            const blocked = tab.Availability.BlockedDateRange[j];

                            if ((blocked.DateStart !== '' && blocked.DateEnd === '') || (blocked.DateStart === '' && blocked.DateEnd !== '')) {
                                errors.push({
                                    field: "Hourly.Blocked",
                                    message: t("listASpace:errors.step2.hourlyBlocked")
                                });
                                break;
                            } else {
                                // check if blocked date is before / after available period
                                if (tab.Availability.SelectedDateRange.DateStart !== "" && tab.Availability.SelectedDateRange.DateEnd !== "") {
                                    if (moment(blocked.DateStart).isBefore(moment(tab.Availability.SelectedDateRange.DateStart)) || moment(blocked.DateEnd).isAfter(moment(tab.Availability.SelectedDateRange.DateEnd))) {
                                        errors.push({
                                            field: "Hourly.Blocked",
                                            message: t("listASpace:errors.step2.hourlyBlockedOutOfRange")
                                        });
                                    }
                                }
                            }
                        }
                    }

                    if (!this.state.isCorp) {
                        if (tab.Availability.Price[0][0] <= 0 || tab.Availability.Price[0][0] > 200)
                            errors.push({field: "Hourly.price", message: t("listASpace:errors.step2.hourlyRange")});

                    } else {
                        let error_price = false,
                            error_startend = false,
                            error_minbooking = false;

                        // console.log("test corp");
                        for (let ti = 0; ti < tab.Availability.AvailableDayTime.length; ti++) {
                            if (!error_price && (tab.Availability.Price[ti][0] <= 0 || tab.Availability.Price[ti][0] > 200)) {
                                errors.push({field: "Hourly.price", message: t("listASpace:errors.step2.hourlyRange")});
                                error_price = true;
                            }

                            // console.log(tab.Availability.AvailableDayTime[ti].SelectedTimeRange2);

                            if (!error_startend && tab.Availability.AvailableDayTime[ti].SelectedTimeRange2) {
                                let ts = [
                                    parseInt(tab.Availability.AvailableDayTime[ti].SelectedTimeRange.TimeStart.substr(0, 2)),
                                    parseInt(tab.Availability.AvailableDayTime[ti].SelectedTimeRange.TimeEnd.substr(0, 2)),
                                    parseInt(tab.Availability.AvailableDayTime[ti].SelectedTimeRange2.TimeStart.substr(0, 2)),
                                    parseInt(tab.Availability.AvailableDayTime[ti].SelectedTimeRange2.TimeEnd.substr(0, 2)),
                                ];

                                // console.log("ts", ts);

                                if (ts[1] > ts[2]) {
                                    errors.push({
                                        field: "Hourly.price",
                                        message: t("listASpace:errors.step2.hourlyRangeOverlap")
                                    });
                                    error_startend = true;
                                }

                                if (!error_minbooking && tab.Availability.AvailableDayTime[ti].SelectedTimeRange.MaxHour) {
                                    if (minBooking > tab.Availability.AvailableDayTime[ti].SelectedTimeRange.MaxHour) {
                                        errors.push({
                                            field: "Hourly.minBooking",
                                            message: t("listASpace:errors.step2.hourlyMinBooking")
                                        });
                                        error_minbooking = true;
                                    }
                                }
                                if (!error_minbooking && tab.Availability.AvailableDayTime[ti].SelectedTimeRange2 && tab.Availability.AvailableDayTime[ti].SelectedTimeRange2.MaxHour) {
                                    if (minBooking > tab.Availability.AvailableDayTime[ti].SelectedTimeRange2.MaxHour) {
                                        errors.push({
                                            field: "Hourly.minBooking",
                                            message: t("listASpace:errors.step2.hourlyMinBooking")
                                        });
                                        error_minbooking = true;
                                    }
                                }
                            }
                        }
                    }

                    //
                    // HourlyTab: {
                    //     Availability: {
                    //         SelectedDateRange: {
                    //             DateStart: '',
                    //                 DateEnd: '',
                    //         },
                    //         AlwaysAvailable: isCorp ? 'No' : 'Yes',
                    //             AvailableDayTime: [{
                    //             SelectedDayOfWeek: 'MonFri',
                    //             CustomDates: [],
                    //             SelectedTimeRange: {
                    //                 TimeStart: '00:00',
                    //                 TimeEnd: '24:00',
                    //             },
                    //             SelectedTimeRange2: null,
                    //         }],
                    //             BlockedDateRange: [{
                    //             DateStart: '',
                    //             DateEnd: ''
                    //         }],
                    //             Price: [[10, 10],[10, 10]],
                    //     },
                    //     Pricing: {
                    //         WeekOfDays: ['Mon', 'Fri'],
                    //             price: 10,
                    //             minBooking: 1,
                    //             deposit: 0,
                    //     },
                    //     CalendarData: {
                    //         AvailableDates: [],
                    //             OnDates: [],
                    //             CustomDates: [],
                    //             Editing: null,
                    //             EditingDate: false,
                    //     },
                    // },

                }

                if (data.BookingType.Monthly) {
                    let tab = data.MonthlyTab;
                    if (tab.Availability.SelectedDateRange.DateStart === "")
                        errors.push({field: "Monthly.DateStart", message: t("listASpace:errors.step2.monthly")});
                    else if (tab.Availability.SelectedDateRange.DateEnd === "")
                        errors.push({field: "Monthly.DateEnd", message: t("listASpace:errors.step2.monthly")});

                    if (tab.Availability.BlockedDateRange.length > 0) {
                        for (let j = 0; j < tab.Availability.BlockedDateRange.length; j++) {
                            const blocked = tab.Availability.BlockedDateRange[j];

                            if ((blocked.DateStart !== '' && blocked.DateEnd === '') || (blocked.DateStart === '' && blocked.DateEnd !== '')) {
                                errors.push({
                                    field: "Monthly.Blocked",
                                    message: t("listASpace:errors.step2.monthlyBlocked")
                                });
                                break;
                            } else {
                                // check if blocked date is before / after available period
                                if (tab.Availability.SelectedDateRange.DateStart !== "" && tab.Availability.SelectedDateRange.DateEnd !== "") {
                                    if (moment(blocked.DateStart).isBefore(moment(tab.Availability.SelectedDateRange.DateStart)) || moment(blocked.DateEnd).isAfter(moment(tab.Availability.SelectedDateRange.DateEnd))) {
                                        errors.push({
                                            field: "Monthly.Blocked",
                                            message: t("listASpace:errors.step2.monthlyBlockedOutOfRange")
                                        });
                                    }
                                }
                            }
                        }
                    }


                    if (tab.Pricing.price < 1000 || tab.Pricing.price > 20000) {
                        // console.log("monthly price: " + tab.Pricing.price + " < " + 1000);
                        errors.push({
                            field: "Monthly.price", message: t("listASpace:errors.step2.monthlyRange")
                        });
                    }

                    if (tab.Availability.SelectedDateRange.DateStart !== "" && tab.Availability.SelectedDateRange.DateEnd !== "") {
                        const minBooking = tab.Pricing.minBooking,
                            start = tab.Availability.SelectedDateRange.DateStart,
                            end = tab.Availability.SelectedDateRange.DateEnd,
                            duration = moment.duration(end.diff(start));
                        let months = duration.months() + (duration.years() * 12);

                        // console.log(end);

                        if (start.date() === 1)
                            months++;

                        if (minBooking > months)
                            errors.push({
                                field: "Monthly.minBooking",
                                message: t("listASpace:errors.step2.monthlyMinBooking")
                            });
                    }
                }

                if (data.BookingType.Timeshare) {
                    let tab = data.TimeshareTab;
                    if (tab.Availability.SelectedDateRange.DateStart === "")
                        errors.push({field: "Timeshare.DateStart", message: t("listASpace:errors.step2.timeShare")});
                    else if (tab.Availability.SelectedDateRange.DateEnd === "")
                        errors.push({field: "Timeshare.DateEnd", message: t("listASpace:errors.step2.timeShare")});

                    if (tab.Availability.BlockedDateRange.length > 0) {
                        for (let j = 0; j < tab.Availability.BlockedDateRange.length; j++) {
                            const blocked = tab.Availability.BlockedDateRange[j];

                            if ((blocked.DateStart !== '' && blocked.DateEnd === '') || (blocked.DateStart === '' && blocked.DateEnd !== '')) {
                                errors.push({
                                    field: "Timeshare.Blocked",
                                    message: t("listASpace:errors.step2.timeshareBlocked")
                                });
                                break;
                            } else {
                                // check if blocked date is before / after available period
                                if (tab.Availability.SelectedDateRange.DateStart !== "" && tab.Availability.SelectedDateRange.DateEnd !== "") {
                                    if (moment(blocked.DateStart).isBefore(moment(tab.Availability.SelectedDateRange.DateStart)) || moment(blocked.DateEnd).isAfter(moment(tab.Availability.SelectedDateRange.DateEnd))) {
                                        errors.push({
                                            field: "Timeshare.Blocked",
                                            message: t("listASpace:errors.step2.timeshareBlockedOutOfRange")
                                        });
                                    }
                                }
                            }
                        }
                    }

                    if (tab.Pricing.price < 1000 || tab.Pricing.price > 20000) {
                        // console.log("Timeshare price: " + tab.Pricing.price + " < " + 1000);
                        errors.push({
                            field: "Timeshare.price", message: t("listASpace:errors.step2.timeShareRange")
                        });
                    }

                    if (tab.Availability.SelectedDateRange.DateStart !== "" && tab.Availability.SelectedDateRange.DateEnd !== "") {
                        const minBooking = tab.Pricing.minBooking,
                            start = tab.Availability.SelectedDateRange.DateStart,
                            end = tab.Availability.SelectedDateRange.DateEnd,
                            duration = moment.duration(end.diff(start));
                        let months = duration.months() + (duration.years() * 12);

                        if (start.date() === 1)
                            months++;

                        if (minBooking > months)
                            errors.push({
                                field: "Timeshare.minBooking",
                                message: t("listASpace:errors.step2.timeshareMinBooking")
                            });
                    }
                }

                // console.log("errors", errors);

                if (errors.length) {
                    this.setState({
                        ...this.state,
                        Errors: errors
                    })
                } else {
                    this.SubmitStep2(data);

                    this.setState({
                        ...this.state,
                        formSubmitted: true
                    });
                }
            }
        }

        this.ApiCheckForOwnerPromotionCode(nextStep, this.ApiCheckForOwnerPromotionCode);
    };

    render() {
        const {
            t,
            i18n
        } = this.props;

        if (this.props.members.Login.length === 0) {
            return <Redirect to={"/" + i18n.language + "/login"}/>;
        }

        if (this.props.listParkingSpace.StepOne.length === 0) {
            return <Redirect to={"/" + i18n.language + "/stepOne"}/>;
        }

        if (this.state.formSubmitted === true) {
            return <Redirect to={"/" + i18n.language + "/stepThree"}/>;
        }

        // console.log('state', this.state);

        return (
            <div className="wrapper-container formHourly">

                <div className="page-title">

                    <h2>{t("listASpace:title")}</h2>

                    <div className="sep-0"/>

                    <ThreeSteps
                        selected={2}
                        stepsLeft={2}
                    />
                </div>

                <div className="containerMain">
                    <div className="content">
                        <h3>{t("listASpace:stepTwo.title")}</h3>

                        <div className="sep-30"/>

                        <dl className="generalForm clearfix">
                            <div>
                                <dt className="align-top">
                                    <h4>{t("listASpace:stepTwo.about")}</h4>
                                </dt>
                                <dd>
                                    <div className="wrapper-map">
                                        <GoogleApiWrapper lat={this.props.listParkingSpace.StepOne.lat}
                                                          lng={this.props.listParkingSpace.StepOne.lng}/>
                                    </div>

                                </dd>
                            </div>

                            <div className="sep-40"/>
                            <div className="sep-40"/>

                            {!this.state.isCorp &&
                            <div>
                                <dt>
                                    <h4>{t("listASpace:stepTwo.instantBooking.title")} *</h4>
                                    <button className="tips"
                                            onClick={() => this.changeTips('InstantBookingTips')}
                                    ></button>
                                    {this.state.InstantBookingTips &&
                                    <div
                                        className="tipsText">{t("listASpace:stepTwo.instantBooking.popupContent")}</div>
                                    }
                                </dt>
                                <dd>
                                    <div className="wrap clearfix">
                                        <div className="fifty">
                                            <div>
                                                <div className="checkboxFive green">
                                                    <div>
                                                        <input type="checkbox" id="Accept" value="Accept"
                                                               checked={this.state.InstantBooking === 'Accept'}
                                                               onChange={(e) => this.changeStateLevelOne('InstantBooking', e.target.value)}
                                                        />
                                                        <label htmlFor="Accept"></label>
                                                    </div>
                                                    <label
                                                        htmlFor="Accept">{t("listASpace:stepTwo.instantBooking.accept")}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="fifty">
                                            <div>
                                                <div className="checkboxFive green">
                                                    <div>
                                                        <input type="checkbox" id="NotAccept" value="NotAccept"
                                                               checked={this.state.InstantBooking === 'NotAccept'}
                                                               onChange={(e) => this.changeStateLevelOne('InstantBooking', e.target.value)}
                                                        />
                                                        <label htmlFor="NotAccept"></label>
                                                    </div>
                                                    <label
                                                        htmlFor="NotAccept">{t("listASpace:stepTwo.instantBooking.notAccept")}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </dd>
                            </div>
                            }

                            {!this.state.isCorp &&
                            <div>
                                <div className="sep-40"/>
                                <div className="sep-10"/>
                            </div>
                            }

                            <div>
                                <dt>
                                    <h4>{t("listASpace:stepTwo.feeType.title")} *</h4>
                                </dt>
                                <dd>
                                    <ul className="booking-types clearfix">
                                        <div className="oneThird">
                                            <div>
                                                <li>
                                                    <div
                                                        onClick={(e) => this.changeStateCheckboxLevelOne('BookingType', 'Hourly')}>
                                                        <div
                                                            className={"icon hourly " + (this.state.BookingType.Hourly ? 'active' : '')}></div>
                                                        <h5>{t("listASpace:stepTwo.feeType.hourlyTitle")}</h5>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                        <div className="oneThird">
                                            <div>
                                                <li>
                                                    <div
                                                        onClick={(e) => this.changeStateCheckboxLevelOne('BookingType', 'Monthly')}>
                                                        <div
                                                            className={"icon monthly " + (this.state.BookingType.Monthly ? 'active' : '')}></div>
                                                        <h5>{t("listASpace:stepTwo.feeType.monthlyTitle")}</h5>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                        <div className="oneThird">
                                            <div>
                                                <li>
                                                    <div
                                                        onClick={(e) => this.changeStateCheckboxLevelOne('BookingType', 'Timeshare')}>
                                                        <div
                                                            className={"icon timeShare " + (this.state.BookingType.Timeshare ? 'active' : '')}></div>
                                                        <h5>{t("listASpace:stepTwo.feeType.timeShareTitle")}</h5>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                    </ul>
                                </dd>
                            </div>

                            <div className="sep-40"/>

                            <div>
                                <dt className="align-top">
                                    <h4>{t("listASpace:stepTwo.availability.title")}</h4>
                                </dt>
                                <dd className="align-top">
                                    <div className="wrapper-tabs tabMarginFix">
                                        <Tabs activeKey={this.state.ActiveTab} onSelect={(e) => this.handleTabSelect(e)}
                                              id="uncontrolled-tab-example">
                                            {this.state.BookingType.Hourly ?
                                                <Tab eventKey={1}
                                                     title={t("listASpace:stepTwo.availability.hourlyTitle")}>
                                                    <TabHourly
                                                        changeStateLevelTwo={this.changeStateLevelTwo}
                                                        AccessType={this.props.listParkingSpace.StepOne.AccessType}
                                                        HourlyTab={this.state.HourlyTab}
                                                        HourlyTabHandleChange={this.HourlyTabHandleChange}
                                                        HourlyTabChangeStateLevelOne={this.HourlyTabChangeStateLevelOne}
                                                        HourlyTabChangeStateLevelTwoA={this.HourlyTabChangeStateLevelTwoA}
                                                        HourlyTabChangeStateLevelTwoB={this.HourlyTabChangeStateLevelTwoB}
                                                        HourlyTabChangeStateRadioLevelOne={this.HourlyTabChangeStateRadioLevelOne}
                                                        HourlyTabHandleDaysOfWeek={this.HourlyTabHandleDaysOfWeek}
                                                        TabChangeBlockDate={this.TabChangeBlockDate}
                                                        TabAddUnavailability={this.TabAddUnavailability}
                                                        TabRemoveUnavailability={this.TabRemoveUnavailability}
                                                        TabAddDayTime={this.TabAddDayTime}
                                                        TabDeleteDayTime={this.TabDeleteDayTime}
                                                        TabChangeAvailableDay={this.TabChangeAvailableDay}
                                                        TabChangeAvailableTime={this.TabChangeAvailableTime}
                                                        TabHandleCustomDayTime={this.TabHandleCustomDayTime}
                                                        HourlyCalendarHandleEventClick={this.HourlyCalendarHandleEventClick}
                                                        HourlyCalendarHandleCloseEditor={this.HourlyCalendarHandleCloseEditor}
                                                        HourlyCalendarHandleSetCustomAvailableDates={this.HourlyCalendarHandleSetCustomAvailableDates}
                                                        HourlyTabChangePrice={this.HourlyTabChangePrice}

                                                        holiday={this.state.holiday}
                                                        isPublicHoliday={this.isPublicHoliday}

                                                        isCorp={this.state.isCorp}
                                                        TabRemoveAvailableTime={this.TabRemoveAvailableTime}
                                                        TabAddAvailableTime={this.TabAddAvailableTime}
                                                        TabChangeAvailableTime2={this.TabChangeAvailableTime2}
                                                    />
                                                </Tab> : ''}
                                            {this.state.BookingType.Monthly ?
                                                <Tab eventKey={2}
                                                     title={t("listASpace:stepTwo.availability.monthlyTitle")}>
                                                    <TabMonthly
                                                        changeStateLevelTwo={this.changeStateLevelTwo}
                                                        AccessType={this.props.listParkingSpace.StepOne.AccessType}
                                                        MonthlyTab={this.state.MonthlyTab}
                                                        MonthlyTabHandleChange={this.MonthlyTabHandleChange}
                                                        MonthlyTabChangeStateLevelOne={this.MonthlyTabChangeStateLevelOne}
                                                        MonthlyTabChangeStateRadioLevelOne={this.MonthlyTabChangeStateRadioLevelOne}
                                                        TabChangeBlockDate={this.TabChangeBlockDate}
                                                        TabAddUnavailability={this.TabAddUnavailability}
                                                        TabRemoveUnavailability={this.TabRemoveUnavailability}

                                                        holiday={this.state.holiday}
                                                        isPublicHoliday={this.isPublicHoliday}
                                                    />
                                                </Tab> : ''}
                                            {this.state.BookingType.Timeshare ?
                                                <Tab eventKey={3}
                                                     title={t("listASpace:stepTwo.availability.timeShareTitle")}>
                                                    <TabTimeshare
                                                        changeStateLevelTwo={this.changeStateLevelTwo}
                                                        AccessType={this.props.listParkingSpace.StepOne.AccessType}
                                                        TimeshareTab={this.state.TimeshareTab}
                                                        TimeshareTabHandleChange={this.TimeshareTabHandleChange}
                                                        TimeshareTabChangeStateLevelOne={this.TimeshareTabChangeStateLevelOne}
                                                        TimeshareTabChangeStateLevelTwoA={this.TimeshareTabChangeStateLevelTwoA}
                                                        TimeshareTabChangeStateLevelTwoB={this.TimeshareTabChangeStateLevelTwoB}
                                                        TimeshareTabChangeStateRadioLevelOne={this.TimeshareTabChangeStateRadioLevelOne}
                                                        TabChangeBlockDate={this.TabChangeBlockDate}
                                                        TabAddUnavailability={this.TabAddUnavailability}
                                                        TabRemoveUnavailability={this.TabRemoveUnavailability}
                                                        TabAddDayTime={this.TabAddDayTime}
                                                        TabDeleteDayTime={this.TabDeleteDayTime}
                                                        TabChangeAvailableDay={this.TabChangeAvailableDay}
                                                        TabChangeAvailableTime={this.TabChangeAvailableTime}
                                                        TabHandleCustomDayTime={this.TabHandleCustomDayTime}

                                                        holiday={this.state.holiday}
                                                        isPublicHoliday={this.isPublicHoliday}
                                                    />
                                                </Tab> : ''}
                                        </Tabs>
                                    </div>
                                    <ErrorMessages errors={this.state.Errors}/>
                                </dd>
                            </div>
                        </dl>

                        <div className="sep-25"/>

                        <PrevNext
                            prevHas={true}
                            prevURL={"/" + i18n.language + "/stepOne"}
                            prevText={t("listASpace:back")}
                            nextURL={"/" + i18n.language + "/stepThree"}
                            nextText={t("listASpace:next")}
                            progress="two"
                            value="2/3"
                            prevHandler={null}
                            nextHandler={(e) => this.submitForm(e)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {
        members: state.members,
        listParkingSpace: state.listParkingSpace
    }
);

export default withTranslation()(connect(mapStateToProps)(FormHourly));
