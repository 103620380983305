import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import moment from "moment";
import swal from 'sweetalert';
import "../../../css/101Components/_sweetAlert.css";

import * as RenewalApi from "../../../utils/04Api/Renewal";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

class MemberRenewalInvoice extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: false,
            selected_merchant: this.props.merchant
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.merchant !== prevProps.merchant) {
            this.setState({selected_merchant: this.props.merchant, data: [], loading: true});
            this.fetchData();
        }
    }

    fetchData () {
        this.setState({isLoading: true})
        RenewalApi.getEnterpriseInvoices(cookies.get('enterprise_token'),
          (response) => {
            // console.log(response)
              if (response.status === 200) {
                  let data = response.body.filter(i =>
                    (i.cost = i.cost || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') &&
                    (i.issue_date = i.issue_date.replace('T', ' ')) &&
                    i.mid === this.state.selected_merchant._id
                  );
                  this.setState({data})
              }
              this.setState({isLoading: false})
              // console.log(data)
          }, (error) => {
              console.log(error)
              this.setState({isLoading: false})
          })
    }

    isExpired (invoice) {
        if(invoice.type === 'Monthly' && moment(invoice.releaseday).isBefore(new Date())) return false;
        return !(invoice.type === 'Hourly' && moment(invoice.to).isBefore(new Date()));
    }

    checkInvoice (invoice) {
        const {t} = this.props;

        if (invoice.status === 'pending' || invoice.status === 'Expired') {
            alert(`${t('myAccount:tabRenewal.invoice.orderId')}: ${invoice.order_id._id}`)
        } else {
            alert(`${t('myAccount:tabRenewal.invoice.orderId')}: ${invoice.order_id._id} \n${t('myAccount:tabRenewal.invoice.invoiceId')}: ${invoice._id}`)
        }
    }

    handlePayment(invoice) {
        const isMobile = window.screen.width <= 768;
        const domain = this.state.selected_merchant.web_url;
        let url;
        if (Array.isArray(invoice.transaction_id) && invoice.transaction_id.length >= 1 && isMobile) {
            const payment_method = invoice.transaction_id[0].payment_method
            if (payment_method === "711") {
                url = `${domain}/#/paymentoffline?inv_id=${invoice._id}`;
            } else if (payment_method === "ok") {
                url = `${domain}/#/paymentofflineok?inv_id=${invoice._id}`;
            } else if (payment_method === "fps") {
                url = `${domain}/#/paymentofflinefps?inv_id=${invoice._id}`;
            } else {
                url = `${domain}/#/otp?inv_id=${invoice._id}`;
            }
        } else {
            url = `${domain}/#/otp?inv_id=${invoice._id}`;
        }
        this.handleRedirect(url);
    }

    handleRedirect(url) {
        const {t, i18n} = this.props;
        swal({
            text: t('myAccount:tabRenewal.redirect', {merchant: i18n.language === 'zh-HK' ? this.state.selected_merchant.title_tc : this.state.selected_merchant.title}),
            buttons: {
                cancel: {
                    text: t('myAccount:tabRenewal.cancel'),
                    value: null,
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: t('myAccount:tabRenewal.confirm'),
                    value: true,
                    visible: true,
                    closeModal: true
                }
            }
        })
          .then((isConfirmed) => {
              if (isConfirmed) {
                  window.open(url)
              }
          })
    }

    handleCancel(invoice) {
        const {t} = this.props;

        if (invoice.status === "pendingtoprocess") {
            RenewalApi.cancelEnterpriseInvoice (cookies.get('enterprise_token'), invoice._id,
                (response) => {
                    alert(t("myAccount:tabRenewal.invoice.cancel.successMessage"));
                    window.location.reload()
                }, (error) => {
                    console.log(error)
                })
        } else {
            alert(t("myAccount:tabRenewal.invoice.cancel.failMessage"));
        }
    }

    _renderDescription(invoice) {
        const {t} = this.props;

        if (invoice.type === 'temporary') return `(${t("myAccount:tabRenewal.invoice.description.tempVehicleChange")})`
        if (invoice.type === 'permanant') return `(${t("myAccount:tabRenewal.invoice.description.permVehicleChange")})`
        if (invoice.type === 'preorder' && invoice.order_id.type === 'Monthly') return `(${t("myAccount:tabRenewal.invoice.description.monthlyFee")})`
        if (invoice.type === 'preorder' && invoice.order_id.type === 'Hourly') return `(${t("myAccount:tabRenewal.invoice.description.hourlyFee")})`

    }

    render() {
        const {
            t,
            i18n
        } = this.props;

        const {data, isLoading} = this.state
        const lang = i18n.language
        return (
            <div className={"memberRenewal"}>
                <table className={"main_table invoice_table"}>
                    <thead className={"main_table_header"}>
                    <tr>
                        <th>{t("myAccount:tabRenewal.invoice.tableHeader.payNow")}</th>
                        <th>{t("myAccount:tabRenewal.invoice.tableHeader.cancel")}</th>
                        <th>{t("myAccount:tabRenewal.invoice.tableHeader.check")}</th>
                        <th>{t("myAccount:tabRenewal.invoice.tableHeader.carpark")}</th>
                        <th>{t("myAccount:tabRenewal.invoice.tableHeader.package")}</th>
                        <th>{t("myAccount:tabRenewal.invoice.tableHeader.vehicle")}</th>
                        <th>{t("myAccount:tabRenewal.invoice.tableHeader.status")}</th>
                        <th>{t("myAccount:tabRenewal.invoice.tableHeader.date")}</th>
                        <th>{t("myAccount:tabRenewal.invoice.tableHeader.total")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((invoice) =>
                          <tr className={"main_table_row"}>
                              <td>
                                  <button
                                    style={{display: invoice.status === 'pendingtopayment' && this.isExpired(invoice) ? 'block' : 'none', minWidth: 60}}
                                    onClick={() => {this.handlePayment(invoice)}}
                                  >
                                      {t('myAccount:tabRenewal.invoice.action.pay')}
                                  </button>
                              </td>
                              <td>
                                  <button
                                    style={{display: invoice.status === 'pendingtoprocess' ? 'block' : 'none', minWidth: 60}}
                                    onClick={() => {this.handleCancel(invoice)}}
                                  >
                                      {t('myAccount:tabRenewal.invoice.action.cancel')}
                                  </button>
                              </td>
                              <td>
                                  <button
                                    style={{minWidth: 60}}
                                    onClick={() => {this.checkInvoice(invoice)}}
                                  >
                                      {t('myAccount:tabRenewal.invoice.action.check')}
                                  </button>
                              </td>
                              <td>{lang === 'en-US' ? invoice.order_id.space.carpark_id.name_en : invoice.order_id.space.carpark_id.name_tc}</td>
                              <td style={{maxWidth: 250}}>{lang === 'en-US' ? invoice.order_id.space.title : invoice.order_id.space.title_tc} {this._renderDescription(invoice)}</td>
                              <td>{invoice.order_id.vehicle.carplate}</td>
                              <td>{t(`myAccount:tabRenewal.invoice.status.${invoice.status}`)}</td>
                              <td>{invoice.issue_date}</td>
                              <td style={{minWidth: 60}}>{invoice.cost}</td>
                          </tr>)
                    }
                    {
                        isLoading && (
                          <tr>
                              <td colSpan={9} className={"message"}>Loading...</td>
                          </tr>
                        )
                    }
                    {
                        !isLoading && data.length < 1 && (
                          <tr>
                              <td colSpan={9} className={"message"}>{t('myAccount:tabRenewal.invoice.noresult')}</td>
                          </tr>
                        )
                    }
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
        members: state.members
    }
);

export default withTranslation()(connect(mapStateToProps)(MemberRenewalInvoice));
