import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
// import $ from 'jquery';

import ViewChangeButton from '../../components/03FindParkingSpace/ViewChangeButton';
import ClusterMap from "../../components/103Parts/ClusterMap";
// import ListType2b from '../../components/102Grids/ListType2b';
//
// import * as Helper from "../../utils/03FindParkingSpace/LowestHourlyPrice";

// dummy data
import genMarkersData from "../../data/genMarkersData";

class FindYourSpaceMapView extends Component {

    render() {
        // console.log(this.props.mapZoom);
        return (
            <div className="containerMain fullWidth mapView">
                <ViewChangeButton
                    filter={this.props.filter}
                    showView={this.props.showView}
                    handleViewChange={this.props.handleViewChange}
                    handleFilterBoolean={this.props.handleFilterBoolean}
                />
                <ClusterMap
                    markers={genMarkersData({
                        // count: 100,
                        // seed: 7,
                        // latVarM: 0.5,
                        // lngVarM: 0.5,
                        // test: false,
                        result_booking_type: this.props.result_booking_type,//2019-12-11 Samson
                        spaces: this.props.spaces
                    })}
                    selectSpace={this.props.selectSpace}
                    selectedSpace={this.props.selectedSpace}
                    zoom={this.props.mapZoom}
                />
                <div className="sep-0" />
            </div>
        );
    }
}

export default withTranslation()(FindYourSpaceMapView);
