import React from 'react';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

function N3(props) {
    const {
        t,
        // i18n,
        time = "",
        number = "",
        // action = "",
    } = props;

    return (
        <div>
            <h3>{t("messenger:notifications.N3.title")}</h3>
            <div className="sep-15" />
            <span className="dateTime">{time}</span>
            <div className="sep-10" />
            <div>
                {t("messenger:notifications.N3.msg", {number: number})}
                <div className="sep-20" />
                {t("messenger:general.thank_you_1")}
            </div>
        </div>
    )
}
export default withTranslation()(N3);