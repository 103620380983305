import React, {Component} from 'react';
import {Tab, Tabs} from "react-bootstrap";
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle} from "react-accessible-accordion";
// import FaqData from '../../data/helpCenter';
// import ShowMore from '../../components/103Parts/ShowMore';
import {withTranslation} from 'react-i18next';

import {getFAQ} from '../../utils/04Api/FAQ';

import YouTube from 'react-youtube';

function FaqBlock(props) {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 1
        }
    };

    return (
        <AccordionItem>
            <AccordionItemTitle>
                <h3>{props.QuestionTitle}</h3>
            </AccordionItemTitle>
            <AccordionItemBody>

                {props.Youtube &&
                <div>
                    <div className="sep-5"></div>

                    <div className="youtube">
                        <YouTube
                            videoId={props.Youtube}
                            className="youtubeBlock"
                            opts={opts}
                            onReady={props._onReady}
                        />
                    </div>
                </div>
                }

                {props.Image &&
                <div>
                    <div className="sep-5"></div>

                    <div className="image">
                        <img width="100%" src={props.Image} alt=""/>
                    </div>
                </div>
                }

                <div className="content" dangerouslySetInnerHTML={{__html: props.QuestionAnswer}}></div>

            </AccordionItemBody>
        </AccordionItem>
    )
}

class HelpCenter extends Component {

    constructor(props) {

        super(props);

        this.state = {
            Faq: "",
            TabInitDisplay: 1,
            loading: true,
        };
    }

    componentDidMount = () => {

        this.ApiGetFAQ();

        this.setTabsDefault();
    }

    ApiGetFAQ = () => {

        let callback = (res) => {

            console.log(res);

            // let sampleFAQ = FaqData;

            if (res.status === 200) {

                this.setState({
                    ...this.state,
                    Faq: res.body,
                    loading: false,
                });
            }
        }

        getFAQ(callback);
    }

    setTabsDefault = () => {

        let keys = Object.keys(this.getUrlVars());

        for (let i = 0; i < keys.length; i++) {

            let key = (keys[i]);

            if (this.getUrlVars()[key].session === 'parkers') {

                this.setState({
                    TabInitDisplay: 1,
                });

                break;
            } else if (this.getUrlVars()[key].session === 'owners') {

                this.setState({
                    TabInitDisplay: 2,
                });

                break;
            } else {

                this.setState({
                    TabInitDisplay: 1,
                });
            }
        }
    }

    getUrlVars = () => {

        let vars = [];

        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {

            let each = {};

            each[key] = value;

            vars.push(each);
        });

        return vars;
    }

    handleTabSelect = (data) => {
        this.setState({
            TabInitDisplay: data
        })
    }

    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    render() {

        const {
            t,
            i18n
        } = this.props;

        if (this.state.loading) {
            return <div>{t("common:Loading")}</div>
        } else {

            return (
                <div className="wrapper-container helpCenter">
                    <div className="page-title"><h2>{t("Menu.HelpCentre")}</h2></div>
                    <div className="sep-0"/>
                    <div className="containerMain">
                        <div className="content clearfix">
                            <div className="wrapper-tabs">
                                <Tabs activeKey={this.state.TabInitDisplay} onSelect={(e) => this.handleTabSelect(e)}
                                      id="uncontrolled-tab-example">
                                    <Tab eventKey={1} title={t("helpCenter:TabTitle.Parkers")}>
                                        <div className="inner">
                                            <Accordion>
                                                {Object.keys(this.state.Faq).map(key => {

                                                    let faq = this.state.Faq[key];

                                                    if (faq.category === 'parker') {
                                                        return (
                                                            <FaqBlock
                                                                key={key}
                                                                QuestionTitle={i18n.language === 'en-US' ? faq.question : faq.question_chi}
                                                                QuestionAnswer={i18n.language === 'en-US' ? faq.answer : faq.answer_chi}
                                                                Youtube={i18n.language === 'en-US' ? faq.url : faq.url_chi}
                                                                Image={i18n.language === 'en-US' ? faq.image : faq.image_chi}
                                                                onReady={this._onReady}
                                                            />
                                                        )
                                                    } else {
                                                        return null
                                                    }
                                                })}
                                            </Accordion>

                                            {/*<ShowMore />*/}
                                        </div>
                                    </Tab>
                                    <Tab eventKey={2} title={t("helpCenter:TabTitle.Owners")}>
                                        <div className="inner">
                                            <Accordion>
                                                {Object.keys(this.state.Faq).map(key => {

                                                    let faq = this.state.Faq[key];

                                                    if (faq.category === 'owner') {
                                                        return (
                                                            <FaqBlock
                                                                key={key}
                                                                QuestionTitle={i18n.language === 'en-US' ? faq.question : faq.question_chi}
                                                                QuestionAnswer={i18n.language === 'en-US' ? faq.answer : faq.answer_chi}
                                                                Youtube={i18n.language === 'en-US' ? faq.url : faq.url_chi}
                                                                Image={i18n.language === 'en-US' ? faq.image : faq.image_chi}
                                                                onReady={this._onReady}
                                                            />
                                                        )
                                                    } else {
                                                        return null
                                                    }
                                                })}
                                            </Accordion>

                                            {/*<ShowMore />*/}
                                        </div>
                                    </Tab>
                                    <Tab eventKey={3} title={t("helpCenter:TabTitle.General")}>
                                        <div className="inner">
                                            <Accordion>
                                                {Object.keys(this.state.Faq).map(key => {

                                                    let faq = this.state.Faq[key];

                                                    if (faq.category === 'general') {
                                                        return (
                                                            <FaqBlock
                                                                key={key}
                                                                QuestionTitle={i18n.language === 'en-US' ? faq.question : faq.question_chi}
                                                                QuestionAnswer={i18n.language === 'en-US' ? faq.answer : faq.answer_chi}
                                                                Youtube={i18n.language === 'en-US' ? faq.url : faq.url_chi}
                                                                Image={i18n.language === 'en-US' ? faq.image : faq.image_chi}
                                                                onReady={this._onReady}
                                                            />
                                                        )
                                                    } else {
                                                        return null
                                                    }
                                                })}
                                            </Accordion>

                                            {/*<ShowMore />*/}
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default withTranslation()(HelpCenter);
