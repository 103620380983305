import React from 'react';
// import { Link } from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {mongoIdTimestamp} from "../../../utils/00General/Helpers";

function M7(props) {
    const {
        t,
        message,
        isOwner,
        // toUserName
    } = props;

    // console.log(toUserName);

    const msg = (
        <div>
            <div>{t("contactOwner:bookingType.title")}: {t("contactOwner:bookingType.options." + message.data.booking_type)}</div>

            {/*{t("messenger:cards.M7.question", {parker: toUserName})}*/}

            {message.data.question_1 &&
            <div>{t("contactOwner:suggestedQuestion.wideLoad")}</div>
            }

            {message.data.question_2 &&
            <div>{t("contactOwner:suggestedQuestion.noviceDriver")}</div>
            }

            <div>{message.message}</div>
        </div>
    )

    return (
        <div className={"wrapper-message " + (!isOwner ? "mine" : "him")}>
            <div className="message">
                <span className="dateTime">{mongoIdTimestamp(message._id, "LLL")}</span>
                <div className="sep-0"/>
                <div className="content preline">{msg}</div>
            </div>
        </div>
    )
}

export default withTranslation()(M7);