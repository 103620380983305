import React from 'react';
import {withTranslation} from 'react-i18next';


import Verified from '../103Parts/Verified';
import Heart from '../103Parts/Heart';
import Reviews from '../103Parts/Reviews';
import CarTypes from '../103Parts/CarTypes';
import * as Helper from "../../utils/03FindParkingSpace/LowestHourlyPrice";

function ListType1(props) {
    const {
        t,
        i18n
    } = props;

    let lowestPrice = 0,
        lowestType = 'Hour';
    if (typeof (props.space.hourly.from) !== "undefined") {
        lowestPrice = Helper.lowestPrice(props.space.hourly.days);
    } else if (typeof (props.space.monthly.from) !== "undefined") {
        lowestPrice = props.space.monthly.price;
        lowestType = 'Month';
    } else if (typeof (props.space.timeshare.from) !== "undefined") {
        lowestPrice = props.space.timeshare.price;
        lowestType = 'Month';
    }

    // console.log(props);

    return (
        <div className="ListType1 clearfix">
            <div className="image"
                 style={props.space.images ? {backgroundImage: "url(" + props.space.images[0] + ")"} : null}
            >
                <Verified
                    id={props.id}
                    verified={props.verified}
                    handleVerified={props.handleVerified}
                />
                <Heart
                    id={props.id}
                    wishlist={props.wishlist}
                    handleWishlist={props.handleWishlist}
                />
            </div>
            <div className="text">
                <h3>{props.space.title}</h3>
                <h4>{props.space.address}</h4>
                {lowestPrice > 0 &&
                <div>
                    <div className="sep-5"/>
                    <div className="price">
                        {t("search:pricePer" + lowestType, {price: lowestPrice})}

                        {props.space.instant &&
                        <div className="instantBooking"/>
                        }
                        {props.space.bcode &&
                        <div className="bcode"/>
                        }
                    </div>
                </div>
                }
                <div className="sep-10"/>
                <Reviews score={props.space.data.rating} reviews={props.space.data.reviews.length}/>
                <div className="sep-10"/>
                <CarTypes
                    carTypes={props.space.cartype}
                    features={props.space.features}
                />
            </div>
            <a className="button details"
               href={"/" + i18n.language + "/space/" + props.space._id}>{t("space:tabDetails.title")}</a>
        </div>
    )
}

export default withTranslation()(ListType1);
