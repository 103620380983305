import {apiPost} from "./General";

export const createReviews = (accessToken, bookingID, rate, bcomment, callback, errorCallback) => {
    let body = {
        rate: rate,
        bcomment: bcomment,
    }

    apiPost("reviews/create/" + bookingID + "?access_token=" + accessToken, body, callback, errorCallback);
}

export const updateReviews = (accessToken, bookingID, ocomment, callback, errorCallback) => {
    let body = {
        comment: ocomment,
    }
    apiPost("reviews/update/" + bookingID + "?access_token=" + accessToken, body, callback, errorCallback);
}