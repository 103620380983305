import React, {Component} from "react";
import {withTranslation} from 'react-i18next';

// import $ from 'jquery';

import ViewChangeButton from '../../components/03FindParkingSpace/ViewChangeButton';
import SearchBox2 from '../../components/03FindParkingSpace/SearchBox2';
import ListType2 from '../../components/102Grids/ListType2';
import SortbyFilterDesktop2 from '../../components/03FindParkingSpace/SortbyFilterDesktop2';

import * as Helper from "../../utils/03FindParkingSpace/LowestHourlyPrice";

class Block extends Component {
    render() {
        let lowestPrice = Helper.lowestPrice(this.props.space.data.hourly.days);
        return (
            <ListType2
                id={this.props.id}
                count={this.props.count}
                space={this.props.space}
                lowestPrice={lowestPrice}
                params={this.props.params}
            />
        )
    }
}

class FindYourSpaceListView extends Component {

    render() {
        // console.log("FindYourSpaceListView", this.props.spaces);
        const {
            t,
            // i18n,
        } = this.props;

        return (
            <div className="containerMain">
                <div className="content clearfix">
                    {/* <div className="full left align-right">
						<span className="button toggleFilter"
							onClick={(e) => this.props.handleDesktopFilter('toggleDesktopFilter')}
						>Search / Filter</span>
					</div> */}

                    <SearchBox2
                        {...this.props}
                        // search={this.props.search}
                        // handleSearchInput={this.props.handleSearchInput}
                        // handleSearch={this.props.handleSearch}
                    />

                    <div className="sep-20"/>

                    <div className="full left align-right">
                        <ViewChangeButton
                            {...this.props}
                            // showView={this.props.showView}
                            // desktopFilter={this.props.desktopFilter}
                            // handleViewChange={this.props.handleViewChange}
                            // handleDesktopFilter={this.props.handleDesktopFilter}
                        />
                    </div>

                    <div className="sep-20 hell"/>

                    <SortbyFilterDesktop2
                        {...this.props}
                        // desktopFilter={this.props.desktopFilter}
                        // search={this.props.search}
                        // sortby={this.props.sortby}
                        // filter={this.props.filter}
                        //
                        // handleDesktopFilter={this.props.handleDesktopFilter}
                        // handleSortbyOptions={this.props.handleSortbyOptions}
                        // handleFilterOptions={this.props.handleFilterOptions}
                        // handleFilterSelect={this.props.handleFilterSelect}
                        // handleFilterRange={this.props.handleFilterRange}
                        // handleFilterBoolean={this.props.handleFilterBoolean}
                        //
                        // handleReset={this.props.handleReset}
                        // handleApplyFilter={this.props.handleApplyFilter}
                    />

                    {/*<div className="results">*/}
                    {/*	{this.props.results} results found*/}
                    {/*	</div>*/}

                    {this.props.loading === true &&
                    <div className="content clearfix">
                        {t("common:Loading")}
                    </div>
                    }

                    {this.props.loading !== true && this.props.spaces.length <= 0 &&
                    <div className="content clearfix">
                        {t("search:noResult")}
                    </div>
                    }

                    {this.props.loading !== true && this.props.spaces.length > 0 &&
                    <div className="content clearfix">
                        {this.props.spaces
                        // .filter(guest => !props.isFiltered || guest.isConfirmed)
                            .map((space, i) => (
                                <div className="full left" key={i}>
                                    <Block
                                        key={i}
                                        id={i}
                                        count={i}
                                        space={space}
                                        params={this.props.params}
                                    />
                                </div>
                            ))
                        }
                    </div>
                    }
                </div>
            </div>
        )
    }
}

export default withTranslation()(FindYourSpaceListView);
