import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

import PrivacyZHHK from '../../components/10General/Privacy_zh_HK';
import PrivacyENUS from '../../components/10General/Privacy_en_US';
import {getTnc} from "../../utils/04Api/Tnc";

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as InviteeActionCreators from '../actions/invitee';

class Privacy extends Component {

    constructor(props) {
        super(props);

        this.state = {
            privacy: null,
        }
    }

    componentDidMount() {

        this.ApiGetPrivacy(this.ApiGetPrivacy);
    }

    ApiGetPrivacy = (errorCallback) => {

        let callback = (res) => {

            if (res.status === 200) {

                this.setState({
                    ...this.state,
                    privacy: res.body
                })
            }
        }

        getTnc('privacy_policy', callback, errorCallback);
    }

    render() {
        const {
            t,
            i18n
        } = this.props;

        // console.log(this.state.privacy);

        return (
            <div className="wrapper-container media">

                <div className="page-title"><h2>{t("Menu.PrivacyPolicy")}</h2></div>

                <div className="sep-0"/>

                <div className="containerMain">
                    <div className="content clearfix">
                        {i18n.language === 'zh-HK'
                            ? this.state.privacy !== null ?
                                <PrivacyZHHK
                                    content={this.state.privacy['zh-hk'].privacy_policy_content_chi.value}/> : ''
                            : this.state.privacy !== null ?
                                <PrivacyENUS
                                    content={this.state.privacy['en-us'].privacy_policy_content_eng.value}/> : ''
                        }
                    </div>
                </div>
            </div>
        );
    }
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default withTranslation()(Privacy);
// connect(mapStateToProps)(About);