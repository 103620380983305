import React from 'react';
import {Link} from 'react-router-dom';

export const RegisterTnc = (props) => {
    const {t, i18n} = props;
    return (
        <div>
            <div className="sep-25"/>

            <p>
                {t("registerStart:agreement.part1")}
                <Link target="_blank" to={"/" + i18n.language + "/terms"}>{t("registerStart:agreement.part2")}</Link>
                {t("registerStart:agreement.comma")}
                <Link target="_blank" to={"/" + i18n.language + "/licenseAgreement"}>{t("registerStart:agreement.part3")}</Link>
                {t("registerStart:agreement.comma")}
                <Link target="_blank" to={"/" + i18n.language + "/privacy"}>{t("registerStart:agreement.part4")}</Link>
                {t("registerStart:agreement.and")}
                <Link target="_blank" to={"/" + i18n.language + "/disclaimer"}>{t("registerStart:agreement.part5")}</Link>
                {t("registerStart:agreement.fullStop")}
            </p>
        </div>
    )
}

export const RegisterToLogin = (props) => {
    const {t, i18n} = props;
    return (
        <div>
            <div className="sep-25"/>

            <p>
                {t("registerStart:alreadyHaveAccount")} <Link
                to={"/" + i18n.language + "/login"}>{t("registerStart:loginHere")}</Link>
            </p>
        </div>
    )
}
