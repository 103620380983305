import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import {connect} from 'react-redux';

import { withTranslation } from 'react-i18next';
import {mongoIdTimestamp, mongoIdToTime} from "../../../utils/00General/Helpers";
import moment from "moment";
import {rejectBookerBooking, acceptBookerBooking} from "../../../utils/04Api/Booking";

class M3 extends Component {
    constructor(props) {
        super(props);
        this.state = this.calState()

        this.handleReject = this.handleReject.bind(this)
        this.handleAccept = this.handleAccept.bind(this)
    }

    componentDidMount() {
        // if (!this.props.isOwner)
            this.updateTime();
    }

    calState() {
        // booker doesn't require counter
        // if (this.props.isOwner)
        //     return {};
        // console.log("M3", this.props.booking.status);

        if (this.props.booking.status === "pendingBooker") {
            // calculate counter
            let duration = this.calDuration(),
                expired = true,
                time = '';

            if (duration.as("seconds") > 0) {
                expired = false;
                const h = duration.get("hours"),
                    m = duration.get("minutes"),
                    s = duration.get("seconds");

                time = (h < 10 ? "0" : "") + h + ":" + (m < 10 ? "0" : "") + m + ":" + (s < 10 ? "0" : "") + s;
            }

            return {
                expired: expired,
                time: time
            }
        }

        return {};
    }

    calDuration() {
        let x = mongoIdToTime(this.props.message._id).clone().add(1, "day"),
            y = moment();
        // console.log(x.format(), y.format(), x.diff(y), y.diff(x));
        return moment.duration(x.diff(y));
    }

    updateTime() {
        if (this.props.booking.status === "pendingBooker" || this.props.booking.status === 'pending') {
            setTimeout(() => {
                const state = this.calState();
                this.setState(state, this.updateTime);
            }, 1000)
        }
    }

    handleAccept() {
        acceptBookerBooking(this.props.members.Login.userToken, this.props.booking._id)
    }

    handleReject() {
        rejectBookerBooking(this.props.members.Login.userToken, this.props.booking._id)
    }

    render() {
        const {
            t,
            // i18n,
            message,
            // space,
            booking,
            isOwner,
            renderSpace
        } = this.props;

        let status = '';
        if (this.props.booking.status !== "pendingBooker") {
            if (this.props.booking.status === "rejected") {
                status = 'rejected';
            } else if (this.props.booking.status === "rejectedBooker") {
                status = 'rejectedBooker';
            } else {
                status = 'accepted';
            }
        }

        if (isOwner) {
            return (
                <div className="response">
                    <div className="info border clearfix">
                        <h5>{mongoIdTimestamp(message._id, "LLL")} </h5>

                        { renderSpace() }
                    </div>

                    <div className="centerBox">
                        <h3>
                            {t("messenger:cards.M3.owner.headline")}
                        </h3>

                        <div className="sep-10" />

                        <p className="preline">
                            {t("messenger:cards.M3.owner.p1", {booker: booking.booker.name})}
                        </p>
                        <p>
                            {t("messenger:general.thank_you_1")}
                        </p>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="response">
                    <div className="info border clearfix">
                        <h5>{mongoIdTimestamp(message._id, "LLL")} </h5>

                        { renderSpace() }
                    </div>

                    <div className="centerBox">
                        <h3>
                            {t("messenger:cards.M3.booker.headline")}
                        </h3>

                        <div className="sep-10" />

                        <p className="preline">
                            {t("messenger:cards.M3.booker.p1", {owner: booking.owner.name})}
                        </p>

                        {status === "approved" &&
                        <p>{t("messenger:general.accepted_msg")}</p>
                        }

                        {status === "rejected" &&
                        <p>{t("messenger:general.rejected_msg")}</p>
                        }

                        {!status &&
                        <div>
                            {this.state.expired &&
                            <p>{t("messenger:cards.M3.booker.expired")}</p>
                            }

                            {!this.state.expired &&
                            <p>{t("messenger:cards.M3.booker.p2", {time: this.state.time})}</p>
                            }

                            {!this.state.expired &&
                            <p className="please">
                                {t("messenger:general.please_reply")}:
                                <br/>
                                {/*<button*/}
                                {/*    onClick={this.handleReject}*/}
                                {/*    className="button"*/}
                                {/*>{t("messenger:general.reject")}</button>*/}
                                {/*&nbsp;&nbsp;*/}

                                <button
                                    onClick={this.handleReject}
                                    className="button"
                                >{t("messenger:general.reject")}</button>
                                &nbsp;&nbsp;

                                <button
                                    onClick={this.handleAccept}
                                    className="button"
                                >{t("messenger:general.accept")}</button>
                            </p>
                            }
                        </div>
                        }
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => (
    {
        members: state.members,
    }
);

export default withTranslation()(connect(mapStateToProps)(M3));