import React, {Component} from 'react';
import {Redirect} from 'react-router';
import {Tabs, Tab} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';

import MemberMenuDesktop from '../../../components/02Members/MemberMenuDesktop';
import MemberMenuMobile from '../../../components/02Members/MemberMenuMobile';

import MemberSettingsAlert from './MemberSettingsAlert';
import MemberSettingsNotification from './MemberSettingsNotification';
import MemberSettingsPayment from './MemberSettingsPayment';
import MemberSettingsPayout from './MemberSettingsPayout';

import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
import * as UserApi from "../../../utils/04Api/User";
import * as MembersActionCreators from "../../../actions/members";

// import * as MembersActionCreators from '../../actions/members';

class MemberSettings extends Component {
    constructor(props) {
        super(props);

        let logined = this.props.members.Login.length !== 0;

        // console.log(this.props.members.Login);

        // DISPATCH TO ACTIONS
        const {dispatch} = props;
        this.updateMe = bindActionCreators(MembersActionCreators.updateMe, dispatch);

        this.state = {
            logined: logined,
            loading: true,
            TabInitDisplay: 1,
            me: null,
        }

        this.getMe = this.getMe.bind(this);
    }

    componentDidMount = () => {
        if (this.state.logined) {
            // get updated user info
            this.getMe(this.setTabsDefault);
        }
    }

    goToLogin = () => {
        // console.log("gotologin");
        this.setState = ({
            ...this.state,
            logined: false
        })
    }

    getMe = (cb) => {
        if (typeof(cb) !== "function")
            cb = () => {};

        let callback = (res) => {
            if (typeof (res.body) !== "undefined") {
                let me = res.body;

                // update redux
                this.updateMe(me);

                // console.log('me', me);

                // update state
                this.setState({
                    ...this.state,
                    me: me,
                    loading: false,
                }, cb);

                // console.log('me', me);
            }
        }

        UserApi.getMe(this.props.members.Login.userToken, callback, this.goToLogin);
    }

    setTabsDefault = () => {
        let keys = Object.keys(this.getUrlVars());
        for (let i = 0; i < keys.length; i++) {
            let key = (keys[i]);
            if (this.getUrlVars()[key].session === 'parkers') {
                this.setState({
                    TabInitDisplay: 1,
                });
                break;
            } else if (this.getUrlVars()[key].session === 'owners') {
                this.setState({
                    TabInitDisplay: 2,
                });
                break;
            } else {
                this.setState({
                    TabInitDisplay: 1,
                });
            }
        }
    }

    getUrlVars = () => {
        let vars = [];
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            let each = {};
            each[key] = value;
            vars.push(each);
        });
        return vars;
    }

    handleTabSelect = (data) => {
        this.setState({
            TabInitDisplay: data
        })
    }

    render() {
        const {
            t,
            i18n,
        } = this.props;

        if (!this.state.logined)
            return <Redirect to={"/" + i18n.language + "/login"} />;

        if (this.state.loading) {
            return (
                <div className="wrapper-container memberSettings">
                    <MemberMenuMobile/>
                    <div className="containerMain">
                        <MemberMenuDesktop/>

                        <div className="content clearfix">
                            <div className="loading">{t("common:Loading")}</div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="wrapper-container memberSettings">
                <MemberMenuMobile/>
                <div className="containerMain">
                    <MemberMenuDesktop/>

                    <div className="content clearfix">
                        <div className="wrapper-tabs clearfix">
                            <Tabs defaultActiveKey={this.state.TabInitDisplay} onSelect={(e) => this.handleTabSelect(e)}
                                  id="uncontrolled-tab-example">
                                <Tab eventKey={1} title={t("myAccount:tabSettings.alerts.title")}>
                                    <MemberSettingsAlert
                                        alert={this.state.me.alert.length ? this.state.me.alert[0] : null}
                                        getMe={this.getMe}
                                        accessToken={this.props.members.Login.userToken}
                                    />
                                </Tab>
                                <Tab eventKey={2} title={t("myAccount:tabSettings.notification.title")}>
                                    <MemberSettingsNotification
                                        messages={this.state.me.messages}
                                        marketing={this.state.me.marketing}
                                        language={this.state.me.language}
                                        getMe={this.getMe}
                                        accessToken={this.props.members.Login.userToken}
                                    />
                                </Tab>
                                <Tab eventKey={3} title={t("myAccount:tabSettings.payment.title")}>
                                    <MemberSettingsPayment
                                        payments={this.state.me.payment}
                                        getMe={this.getMe}
                                        accessToken={this.props.members.Login.userToken}
                                        userId={this.props.members.Login.userId}
                                    />
                                </Tab>
                                <Tab eventKey={4} title={t("myAccount:tabSettings.payout.title")}>
                                    <MemberSettingsPayout
                                        payout={this.state.me.payout.length ? this.state.me.payout[0] : null}
                                        getMe={this.getMe}
                                        accessToken={this.props.members.Login.userToken}
                                    />
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => (
    {
        members: state.members
    }
);

export default withTranslation()(connect(mapStateToProps)(MemberSettings));
