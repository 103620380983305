import React, {Component} from 'react';

class LicenseAgreement_en_US extends Component {
    render() {
        return (
            <div className="disclaimer" dangerouslySetInnerHTML={{__html: this.props.content}}/>
            // <div className="licenseAgreement">
            //
            //     <p>The agreement for the license of a Parking Space through the Platform (&ldquo;the Licence
            //         Agreement&rdquo;) shall be subject to the terms and conditions as set out in the
            //         Confirmation and in this document.</p>
            //
            //     <ol>
            //         <li><strong>Definition and Interpretation</strong>
            //             <ol>
            //                 <li>In the Licence Agreement, unless the context otherwise requires the
            //                     following words and expressions shall have the following meanings:-
            //                     <ul>
            //                         <li>&ldquo;Access Device&rdquo; means any access device or card which
            //                             the Parker will need to use to access the Related Area and/or the
            //                             Parking Space;
            //                         </li>
            //                         <li>&ldquo;Confirmation&rdquo; means the confirmation sent by the
            //                             Manager pursuant to paragraph 9.1(b) or paragraph 9.2(d) of the
            //                             Platform Terms and Conditions setting out certain terms of the
            //                             Licence;
            //                         </li>
            //                         <li>&ldquo;Deposit&rdquo; means the deposit for this Licence if
            //                             applicable;
            //                         </li>
            //                         <li>&ldquo;Licence&rdquo; means the right granted by the Owner to the
            //                             Parker for the Parker to use the Parking Space on the terms set out
            //                             in the Licence Agreement;
            //                         </li>
            //                         <li>&ldquo;Booking Period&rdquo; means the duration or the times during
            //                             which the Parking Space is licensed by the Owner to the Parker for
            //                             the Parker&rsquo;s use as set out in the Confirmation and for the
            //                             avoidance of doubt, in the event the Parker is only licensed to park
            //                             at the Parking Space for part of a day or for certain days in a
            //                             week, Booking Period does not include the period during which the
            //                             Parker is not licensed to park at the Parking Space;
            //                         </li>
            //                         <li>&ldquo;Parking Fee&rdquo; means the Parking fee payable by the
            //                             Parker to the Owner for the Licence as set out in the Confirmation;
            //                         </li>
            //                         <li>&ldquo;Manager&rdquo; means Let&rsquo;s Park Company (Hong Kong)
            //                             Limited, a company incorporated in Hong Kong with limited
            //                             liabilities;
            //                         </li>
            //                         <li>&ldquo;Owner&rdquo; means the person granting the Licence;</li>
            //                         <li>&ldquo;Parker&rdquo; means the person obtaining the Licence from the
            //                             Owner;
            //                         </li>
            //                         <li>&ldquo;Parking Space&rdquo; means the parking space as described in
            //                             the Confirmation;
            //                         </li>
            //                         <li>&ldquo;Parties&rdquo; means collectively the Owner and the Parker
            //                             and a &ldquo;Party&rdquo; means any one of them;
            //                         </li>
            //                         <li>&ldquo;Platform&rdquo; means the website <a
            //                             style={{"textDecoration":"underline"}}
            //                             href="http://www.letspark.com.hk">www.letspark.com.hk</a> or Mobile App
            //                             under the name of Let&rsquo;s Park both owned and operated by the
            //                             Manager;
            //                         </li>
            //                         <li>&ldquo;Platform Terms and Conditions&rdquo; means the terms and
            //                             condition for the use of the Platform and the provisions of services
            //                             by the Manager as published on the Manager&rsquo;s website at the
            //                             time of the Confirmation;
            //                         </li>
            //                         <li>&ldquo;Related Area&rdquo; means collectively all areas, access
            //                             roads, paths, lifts, staircases, etc., necessary for the Vehicle to
            //                             access the Parking Space and for the driver of the Vehicle and its
            //                             passengers to access the Vehicle;
            //                         </li>
            //                         <li>&ldquo;Suspension Event&rdquo; means any event referred to in clause
            //                             9.1;
            //                         </li>
            //                         <li>&ldquo;Vehicle&rdquo; means the vehicle which will be occupying the
            //                             Parking Space as notified by the Parker to the Manager and by the
            //                             Manager to the Owner (as amended from time to time where
            //                             applicable).
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>In the Licence Agreement, unless the context otherwise requires:
            //                     <ul>
            //                         <li>references to clauses are to clauses of the Licence Agreement;</li>
            //                         <li>words denoting one gender shall include all other genders;</li>
            //                         <li>words denoting the singular shall include the plural and vice versa;
            //                             and
            //                         </li>
            //                         <li>the word &ldquo;person&rdquo; includes an individual, partnership,
            //                             corporation or unincorporated body and government department;
            //                         </li>
            //                         <li>references to procedures and terms as published by the Manager from
            //                             time to time means the relevant procedures and terms published by
            //                             the Manager at its website <a
            //                                 style={{"textDecoration":"underline"}}
            //                                 href="http://www.letspark.com.hk">www.letspark.com.hk</a> at the
            //                             time.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>In the Licence Agreement, &ldquo;business day&rdquo; means days other than
            //                     Saturdays, Sundays and public holidays.
            //                 </li>
            //                 <li>In the event there is any inconsistency between any of the terms as set out
            //                     in the Licence Agreement and the terms as set out in the Confirmation, the
            //                     terms in the Confirmation shall prevail. In the event there is any
            //                     inconsistency between the terms of the Licence Agreement and the Platform
            //                     Terms and Conditions, the terms and conditions of the Licence Agreement
            //                     shall prevail.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Licence</strong>
            //             <ol>
            //                 <li>The Owner hereby grants a licence to the Parker to park the Vehicle at the
            //                     Parking Space during the Booking Period on the terms of the Licence
            //                     Agreement.
            //                 </li>
            //                 <li>Neither the Licence nor the Parker's use of the Parking Space or the Related
            //                     Area or any part thereof shall be construed as creating any lease or tenancy
            //                     on the Parking Space, the Related Area or the building in which the Parking
            //                     Space and the Related Area are located and the Parker shall use the Parking
            //                     Space and the Related Area as a licensee only.
            //                 </li>
            //                 <li>The Parties agree and acknowledge that the Licence is between the Parker and
            //                     the Owner and notwithstanding the role of the Manager in relation to the
            //                     Licence, the Manager is not a party to the Licence or the Licence Agreement.
            //                 </li>
            //                 <li>The Parker may change the Vehicle to another vehicle owned by the Parker for
            //                     the Owner&rsquo;s approval by following the procedures published by the
            //                     Manager request to which may be granted or refused at the Owner&rsquo;s sole
            //                     and absolute discretion provided that the Parker shall not be entitled to
            //                     terminate the Licence in the event such request is refused by the Owner or
            //                     the Vehicle after the change is not able to access the Parking Space or the
            //                     Related Area and/or it cannot park at the Parking Space for whatever reason.
            //                 </li>
            //                 <li>The Licence is non-transferable and the Parker is not allowed to sub-license
            //                     the Licence or allow any third party to occupy the Parking Space with or
            //                     without consideration.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Payment</strong>
            //             <ol>
            //                 <li>The Parking Fee shall be the amount as set out in the Confirmation and shall
            //                     be paid by the Parker to the Manager in accordance with the instructions as
            //                     set out on the Platform.
            //                 </li>
            //                 <li>The Parking Fee shall be deemed to be inclusive of rate and ground rent,
            //                     management fees and other than the Parking Fee, no amount of whatever nature
            //                     shall be payable by the Parker to the Owner in relation to the Licence
            //                     except as expressly set out in the Licence Agreement.
            //                 </li>
            //                 <li>In the event a Deposit is payable or the right to demand a Deposit has been
            //                     reserved and the Owner exercises such right :-
            //                     <ul>
            //                         <li>the Parker shall pay the amount of the Deposit as set out on the
            //                             Platform to the Manager by following the instructions as set out on
            //                             the Platform;
            //                         </li>
            //                         <li>the Owner may instruct the Manager to deduct any amount payable by
            //                             the Parker to the Owner under the Licence from the Deposit at any
            //                             time during the continuation of the Licence and up to 2 business
            //                             days after its termination;
            //                         </li>
            //                         <li>the Owner shall return the Deposit or any balance thereof, if any,
            //                             held by the manager after the deduction referred to in clause 3.3(b)
            //                             to the Parker without interest after the expiration or termination
            //                             of the Booking Period.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>Other than as set out in clauses 4.1, 9.2 and 9.3, no Parking Fee paid by
            //                     the Parker to the Owner shall be refundable.
            //                 </li>
            //                 <li>In the event a pre-authorisation has been obtained on the credit card of the
            //                     Parker, the Owner shall be entitled (but not obliged) to instruct the
            //                     Manager to collect from the credit card any amount payable by the Parker to
            //                     the Owner under the Licence Agreement provided that the non-collection from
            //                     such pre-authorisation for whatever reason shall not affect the
            //                     Parker&rsquo;s liability to pay the same under the Licence Agreement.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Parker Protection Guarantee</strong>
            //             <ol>
            //                 <li>Without prejudice to the rights of the Parker under the Licence Agreement
            //                     and under law but subject to clause 4.2, in the event the description of the
            //                     Parking Space on the Platform is not accurate in such a way which in the
            //                     opinion of the Manager materially affects the use of the Parking Space by
            //                     the Parker, the Parker shall be entitled to terminate the Licence and to a
            //                     refund of the amount paid by the Parker for the Parker Space without
            //                     interest.
            //                 </li>
            //                 <li>The right of the Parker as set out in clause 4.1 is subject to the following
            //                     conditions:-
            //                     <ul>
            //                         <li>the Parker informs the Manager of the details of the inaccuracy
            //                             referred to in clause 4.1 in accordance with the procedures as
            //                             published by the Manager with relevant evidence within 48 hours from
            //                             the commencement of the Booking Period; and
            //                         </li>
            //                         <li>the Manager acting as expert and not an arbitrator confirms the
            //                             inaccuracy will reasonably affect the use of the Parking Space by
            //                             the Parker to a material extent.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>The Owner and the Parker agree that any decision by the Manager under clause
            //                     4.2(b) shall be final and binding on the Parties.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Warranties and Covenants of the Owner</strong>
            //             <ol>
            //                 <li>The Owner warrants to the Parker that
            //                     <ul>
            //                         <li>the Owner is the owner of the Parking Space or has the authority
            //                             from the Owner to manage the Parking Space;
            //                         </li>
            //                         <li>the Owner has the right to enter into the Licence Agreement to grant
            //                             the Licence on the terms as set out in the Licence Agreement;
            //                         </li>
            //                         <li>the descriptions of the Parking Space as set out in the Platform are
            //                             correct and shall continue to be correct to a material extent during
            //                             the continuation of the Booking Period; and
            //                         </li>
            //                         <li>the Owner has taken all necessary actions and obtain all necessary
            //                             consents from all relevant parties to allow the Vehicle to use the
            //                             Parking Space and to allow the Vehicle, its driver and passengers
            //                             access to the Parking Space and the Related Area during the
            //                             continuation of the Booking Period.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>The Owner covenants to the Parker that
            //                     <ul>
            //                         <li>the Parking Space will be available for the Parker to park the
            //                             Vehicle during the Booking Period;
            //                         </li>
            //                         <li>the Parking Space is clean and tidy and free from rubbish at the
            //                             commencement of the Booking Period;
            //                         </li>
            //                         <li>the Vehicle, its driver and passengers shall have access to the
            //                             Parking Space and the Related Area during the Booking Period for the
            //                             sole purpose of access to the Vehicle;
            //                         </li>
            //                         <li>the Owner shall assist the Parker to locate and gain access to the
            //                             Parking Space on or before the commencement of the Booking Period
            //                             and shall provide the Parker with a working Access Device if
            //                             applicable.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>Except as a result of breach of clauses 5.1 and 5.2, the Owner shall not be
            //                     liable to the Parker or any other person in respect of any loss, damage or
            //                     injury of whatsoever nature which may be suffered by the Parker or such
            //                     other person or any property of the Parker or such other person and any sum
            //                     payable by the Parker under the Licence Agreement shall not be reduced or
            //                     cease to be payable on account of :-
            //                     <ul>
            //                         <li>any defect in or failure or breakdown or interruption of any
            //                             services which may be provided to the Parking Space or the Related
            //                             Area including but not limited to electricity and security services;
            //                         </li>
            //                         <li>the act, neglect or default of the tenants and other occupiers of
            //                             the Parking Space and/or Related Area and their respective servants,
            //                             agents or licensees;
            //                         </li>
            //                         <li>the condition of the Parking Space and Related Area or any part
            //                             thereof.
            //                         </li>
            //                     </ul>
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Warranties and Covenants of the Parker</strong>
            //             <ol>
            //                 <li>The Parker warrants to the Owner that
            //                     <ul>
            //                         <li>the Parker is the owner of the Vehicle; and</li>
            //                         <li>the Parker has the right to enter into the Licence Agreement to
            //                             obtain the Licence on the terms as set out in the Licence Agreement.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>The Parker covenants to the Owner that the Parker shall
            //                     <ul>
            //                         <li>only use the Parking Space for the parking of the Vehicle and shall
            //                             not use the Parking Space for any other purposes including without
            //                             limitation any form of commercial activities or repair or
            //                             maintenance of the Vehicle or any other vehicle;
            //                         </li>
            //                         <li>not occupy the Parking Space outside the Booking Period;</li>
            //                         <li>not park any vehicle other than the Vehicle at the Parking Space;
            //                         </li>
            //                         <li>keep the Parking Space and Related Area clean and tidy and free from
            //                             rubbish;
            //                         </li>
            //                         <li>not carry out any illegal or immoral activities in the Parking Space
            //                             and the Related Area;
            //                         </li>
            //                         <li>park the Vehicle within the perimeter of the Parking Space without
            //                             obstructing other users of the Related Area and adjourning and
            //                             nearby parking spaces;
            //                         </li>
            //                         <li>notify the Owner of any damage to the Parking Space and the Related
            //                             Area and to any properties located on or near the Parking Space or
            //                             the Related Area caused by the Vehicle, the driver or passengers of
            //                             the Vehicle;
            //                         </li>
            //                         <li>not do or permit to be done in the Parking Space, the Related Areas
            //                             and areas adjourning the Parking Space and nearby areas anything
            //                             which is or which may become a nuisance, annoyance, inconvenience or
            //                             disturbance to the Owner, or other users of the Related Area and
            //                             areas adjourning the Parking Space and nearby areas;
            //                         </li>
            //                         <li>comply with all by-laws or rules and requirements of any
            //                             governmental or other competent authority relating to the use and
            //                             occupation of the Parking Space and the Related Area as notified by
            //                             the Owner to the Parker and for the purpose of this clause, all
            //                             matters set out in the descriptions of the Parking Space on the
            //                             Platform immediate prior to the booking of the Parking Space shall
            //                             be deemed to have been notified by the Owner to the Parker;
            //                         </li>
            //                         <li>be responsible for the safety of the Vehicle and the property inside
            //                             the Vehicle and not rely on the security measure taken by the Owner
            //                             or the owner or occupier or manager of the Related Area;
            //                         </li>
            //                         <li>access and/or drive in the Related Area with reasonable care and
            //                             follow all traffic signs and directions of the controller or the
            //                             manager of the Parking Space and the Related Area;
            //                         </li>
            //                         <li>act with courtesy toward the Owner and other users of the Related
            //                             Area and areas adjourning the Parking Space and nearby areas;
            //                         </li>
            //                         <li>ensure that the Vehicle shall during the Booking Period be road
            //                             worthy, licensed and insured in accordance with the laws of Hong
            //                             Kong; and
            //                         </li>
            //                         <li>procure that only the driver and the passengers of the Vehicle shall
            //                             access the Parking Space and the Related Area and only for the
            //                             purpose of access to the Vehicle.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>Where an Access Device has to be used, the Parker shall :-
            //                     <ul>
            //                         <li>not use the Access Device other than for the access to the Parking
            //                             Space and/or Related Area;
            //                         </li>
            //                         <li>be responsible for the safe keeping of the Access Device and shall
            //                             inform the Owner immediately in case of any lost or malfunction of
            //                             the Access Device; and
            //                         </li>
            //                         <li>return the Access Device to the Owner at the end of the Licence
            //                             Period in good condition (fair wear and tear excepted) and in
            //                             working order.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>The Parker shall procure that the driver of the Vehicle and its passengers
            //                     shall comply with the provisions of this Licence and the terms and
            //                     conditions in the Licence Agreement applicable to them and any act default
            //                     neglect or omission of any driver or passengers of the Vehicles shall be
            //                     deemed to be the act default neglect or omission of the Parker.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Overstay</strong>
            //             <ol>
            //                 <li>Without prejudice to any other right the Owner may have under law and under
            //                     the Licence Agreement, in the event the Parker fails to vacate the Parking
            //                     Space on or before the end of the Booking Period or the time slot allocated
            //                     for the use of the Vehicle within a Booking Period :
            //                     <ul>
            //                         <li>the Parker shall pay a sum of HK$100 per hour or part of an hour as
            //                             liquidated damages; and
            //                         </li>
            //                         <li>the Owner shall have the right to tow the Vehicle or any other
            //                             vehicle of the Parker occupying the Parking Space away at the cost
            //                             of the Parker after two hours and the Owner shall not be responsible
            //                             for any loss and damages the Parker may suffer or any damage to the
            //                             Vehicle as a result of the Owner exercising its right under this
            //                             clause.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>In order for the Owner to exercise its rights under clause 7.1, the Owner
            //                     shall provide evidence of overstay to the Parker and to the Manager, and an
            //                     image of the Vehicle at the Parking Space with a time stamp shall be deemed
            //                     to be sufficient evidence.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Manager</strong>
            //             <ol>
            //                 <li>The Owner hereby confirms that it has authorised the Manager to collect all
            //                     payments of whatever nature payable by the Parker to the Owner pursuant to
            //                     the Licence Agreement for and on behalf of the Owner and further confirms
            //                     that any payment made to the Manager shall be a good discharge to the Parker
            //                     for making the same payment under the Licence Agreement. The Parker hereby
            //                     agrees to pay all payments payable to the Owner under the Licence Agreement
            //                     to the Manager.
            //                 </li>
            //                 <li>In the event any refund of Parking Fee or any other amount is payable by the
            //                     Owner to the Parker under the Licence Agreement, without prejudice to the
            //                     liability of the Owner to make such payment to the Parker, the Parker agrees
            //                     and accepts that payment of the same amount by the Manager on behalf of the
            //                     Owner shall be a good discharge on the part of the Owner to make such
            //                     payment.
            //                 </li>
            //                 <li>The Owner and the Parker agree to be bound by the determination of facts by
            //                     the Manager as provided for in the Licence Agreement and further agree not
            //                     to make any claim against the Manager for any decision made by the Manager.
            //                 </li>
            //                 <li>Notwithstanding clauses 8.1 and 8.2 or any other provisions of the Licence
            //                     Agreement, the Owner and the Parker agree and acknowledge that:-
            //                     <ul>
            //                         <li>the Manager is not a party to the Licence Agreement and none of the
            //                             Manager, its officer, employee, agent or contractor shall be liable
            //                             to either Party in relation to the Licence or the Licence Agreement;
            //                             and
            //                         </li>
            //                         <li>without prejudice to the validity of any payment by the Manager on
            //                             behalf of the Owner, any payment obligation of the Owner under the
            //                             Licence Agreement shall be the sole obligation of the Owner and the
            //                             Manager shall not be liable to the Parker in the event any of those
            //                             payments are not made.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>The Owner and the Parker agree and confirm that notwithstanding them being
            //                     the only parties to the Licence, they agree and undertake to the Manager
            //                     that they shall not agree to amend the Licence Agreement without the express
            //                     consent of the Manager which can be given or withheld at the Manager&rsquo;s
            //                     sole and absolute discretion.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Suspension of the Licence</strong>
            //             <ol>
            //                 <li>This clause applies when the Parking Space is not available or cannot be
            //                     accessed as a result of circumstances beyond the reasonable control of the
            //                     Parties, including but not limited to industrial action, fire, flood, change
            //                     of law or change of other government or applicable regulations, act of god,
            //                     explosion, riot, civil commotion, storm, typhoon, subsidence, etc.
            //                 </li>
            //                 <li>In the event of the occurrence of a Suspension Event:-
            //                     <ul>
            //                         <li>the Licence shall be suspended during the subsistence of such
            //                             Suspension Event;
            //                         </li>
            //                         <li>the Parking Fee shall not be payable during the subsistence of such
            //                             Suspension Event and any Parking Fee paid for the duration of the
            //                             Suspension Event will be refunded to the Parker unless the Licence
            //                             is subsequently terminated in which case clauses 9.3 and 12.2 shall
            //                             apply; and
            //                         </li>
            //                         <li>the Booking Period shall not be extended as a result of any
            //                             suspension.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>If a Suspension Event lasts for longer than 5 days, either Party may
            //                     terminate the Licence by notice to the other Party and the Manager in
            //                     accordance with the procedures as published by the Manager from time to time
            //                     and the Licence shall terminate upon the receipt of such notice by the
            //                     Manager and the other Party and the Parking Fee paid for the use of the
            //                     Parking Space after the date of termination shall be refunded to the Parker.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Indemnity</strong>
            //             <ol>
            //                 <li>The Parker shall at all times keep the Owner indemnified against all
            //                     actions, proceedings, claims, demands, costs, losses, damages and expenses
            //                     of whatever nature which the Owner may suffer, be liable for, or incur by
            //                     reason of or in connection with the Parker&rsquo;s breach of the Licence
            //                     Agreement, or the use of the Parking Space by the Parker, the lost or damage
            //                     of any Access Device (where applicable) and the Parker hereby agrees that in
            //                     case of its termination of the Licence in default of the Licence Agreement,
            //                     the loss of the Owner shall include any service fee payable by the Owner to
            //                     the Manager under the Platform Terms and Conditions for the remaining
            //                     Booking Period.
            //                 </li>
            //                 <li>The Owner shall at all times keep the Parker indemnified against all
            //                     actions, proceedings, claims, demands, costs, losses, damages and expenses
            //                     of whatever nature which the Parker may suffer, be liable for, or incur by
            //                     reason of or in connection with the Owner&rsquo;s breach of the Licence
            //                     Agreement, or the use of the Parking Space by the Parker.
            //                 </li>
            //                 <li>Each Party shall at all times keep the Manager, its officers and employees
            //                     and agent indemnified against all actions, proceedings, claims, demands,
            //                     costs, losses, taxes, damages and expenses of whatever nature
            //                     (collectively &ldquo;losses&rdquo;) which any of them may suffer, be liable
            //                     for, or incur by reason of or in connection with such Party&rsquo;s breach
            //                     of the Licence Agreement or any act or omission of such Party and in the
            //                     event the losses are as a result of the act or omission or breach of the
            //                     Licence Agreement on the part of both Parties, the Parties&rsquo; liability
            //                     to the Manager under this clause 10.3 shall be joint and several.
            //                 </li>
            //                 <li>The indemnity as set out in this clause 10 shall survive the termination of
            //                     the Licence and shall continue to have effect thereafter.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Termination </strong>
            //             <ol>
            //                 <li>Without prejudice to any termination under law or the provisions of the
            //                     Licence Agreement, the Licence shall terminate upon the expiration of the
            //                     Booking Period.
            //                 </li>
            //                 <li>Without prejudice to any other right the Owner may have against the Parker,
            //                     the Licence shall terminate in the event the Parker fails to pay any part of
            //                     the Parking Fee in accordance with the Licence Agreement.
            //                 </li>
            //                 <li>Other than as expressly set out in the Licence Agreement or as a result of
            //                     breach of the Licence Agreement by the other Party, a Party cannot terminate
            //                     the Licence prior to the expiration of the Booking Period without the
            //                     consent of the other Party and the Manager.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Consequence of Termination</strong>
            //             <ol>
            //                 <li>Upon the termination of the Licence for whatever reason:-
            //                     <ul>
            //                         <li>the Parker shall cease to access the Parking Space and Related
            //                             Areas;
            //                         </li>
            //                         <li>the Parker shall return the Access Device, if any, to the Owner;
            //                         </li>
            //                         <li>the Parker shall vacate the Parking Space at substantial the same
            //                             state as the Parking Space was in at the commencement of the Licence
            //                             Period; and
            //                         </li>
            //                         <li>in the event a Deposit is being held by the Manager on behalf of the
            //                             Owner pursuant to clause 3.3, the Owner shall refund the Deposit or
            //                             balance thereof after deduction under clause 3.3(c) to the Parker
            //                             without interest after termination.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>In the event that the Parking Fee or any part thereof has to be refunded
            //                     after the termination of the Booking Period pursuant to the provisions of
            //                     the Licence Agreement, any Parking Fee for the Booking Period after the
            //                     termination shall subject to any deduction be refunded by the Owner (or the
            //                     Manager on behalf of the Owner) to the Parker after termination.
            //                 </li>
            //                 <li>In the event the Licence Agreement is terminated as a result of breach on
            //                     the part of the Parker, without prejudice to any other right the Owner may
            //                     have against the Parker under clause 10.1 or under law, the Parking Fee paid
            //                     by the Parker shall be forfeited.
            //                 </li>
            //                 <li>The termination of the Booking Period or the Licence Agreement shall be
            //                     without prejudice to :-
            //                     <ul>
            //                         <li>any provisions which due to their nature shall continue to apply
            //                             after the termination of the Booking Period or the Licence Agreement
            //                             including but not limited to clauses 10, 12 and 14; and
            //                         </li>
            //                         <li>the Manager&rsquo;s entitlement to receive the Service Fee under the
            //                             Platform Terms and Conditions for the remaining period of the
            //                             Booking Period if not for the termination.
            //                         </li>
            //                     </ul>
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Dispute between the Parties</strong>
            //             <ol>
            //                 <li>Each Party agrees with the other that that if any dispute arises concerning
            //                     any provision of the Licence, the Parties will attempt to resolve such
            //                     dispute in the first instance by direct communicating with the other through
            //                     the Platform.
            //                 </li>
            //                 <li>Any agreement reached between the Parties shall be communicated to the
            //                     Manager and the Manager is authorised to collect payments from either Party
            //                     (where relevant) to give effect to such agreement.
            //                 </li>
            //                 <li>The Manager at its discretion may mediate between the Parties regarding any
            //                     dispute between them and the Owner and the Parker agree to be bound by any
            //                     decision of fact made by the Manager.
            //                 </li>
            //                 <li>Neither Party shall be liable to the other for any lost profits, loss of
            //                     data, or costs of procurement of substitute services or for any
            //                     consequential, special, indirect, or exemplary damages whatsoever arising
            //                     out of the Licence or any terms thereof however caused (including
            //                     negligence), unless such is expressly referred to in the Licence Agreement.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Rights of Third Parties</strong>
            //             <ol>
            //                 <li>The Parties agree that clauses 8.3, 8.5 and 10.3 intend to confer benefit on
            //                     the Manager and the Manager may enforce clauses 8.3, 8.5 and/or 10.3 against
            //                     one or both of the Parties under the Contracts (Rights of Third Parties)
            //                     Ordinance (Chapter 623 of the laws of Hong Kong).
            //                 </li>
            //                 <li>Other than expressly referred to in clause 14.1, no person other than the
            //                     Parties shall have any right under the Licence Agreement, nor shall the
            //                     Licence Agreement be enforceable by any person other than the Parties
            //                     pursuant to the Contracts (Rights of Third Parties) Ordinance.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Communication</strong>
            //             <ol>
            //                 <li>Unless otherwise specified in the Licence Agreement, any communication
            //                     between the Owner and the Parker shall be through the Platform and shall not
            //                     be between them direct outside the Platform.
            //                 </li>
            //                 <li>Any notice from one Party to the other through the Platform and shall be
            //                     deemed to have been received by the other Party upon the despatch of the
            //                     notice through the Platform.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Miscellaneous</strong>
            //             <ol>
            //                 <li>If any of the provisions of the Licence Agreement is or becomes invalid,
            //                     illegal or unenforceable in any respect under any law, the validity,
            //                     legality and enforceability of the remaining provisions of the Licence
            //                     Agreement shall not in any way be affected or impaired and such remaining
            //                     terms and conditions shall remain in full force and effect.
            //                 </li>
            //                 <li>Any remedy or right expressly set out herein in relation to a breach by a
            //                     Party shall be without prejudice to and in additional to other right or
            //                     remedy the non-defaulting Party has under law or other part of the Licence
            //                     Agreement.
            //                 </li>
            //                 <li>The Parties&rsquo; rights and liabilities under the Licence Agreement cannot
            //                     be assigned to any other person.
            //                 </li>
            //                 <li>Any waiver by any one Party of any breach of the Licence Agreement on the
            //                     part of the other Party shall be effective only in the instance and for the
            //                     purpose for which it is given and no failure or delay by any Party in
            //                     exercising or enforcing any right under the Licence Agreement shall operate
            //                     as a waiver thereof.
            //                 </li>
            //                 <li>In the event of any inconsistency between the Chinese version and the
            //                     English version of the Licence Agreement, the English version shall prevail.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Governing Law</strong>
            //             <ol>
            //                 <li>The Licence Agreement will be governed by and construed in accordance with
            //                     the laws of Hong Kong.
            //                 </li>
            //                 <li>The Parties agree to submit to the non-exclusive jurisdiction of the courts
            //                     of Hong Kong.
            //                 </li>
            //             </ol>
            //        </li>
            // </ol>
            // </div>
        );
    }
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default LicenseAgreement_en_US;
