import * as MessagesActionTypes from '../actiontypes/messages';

const initialState = {
	pendingMessage: "",
	lastMessageId: -1,
	messages: [],
}

export default function MessagesReducer(state=initialState, action) {	
		
	switch(action.type){
		
		// handle socket
		case MessagesActionTypes.HANDLE_SOCKET: {			
			return {
				...state,
			};
		}
		
		// pending message
		case MessagesActionTypes.PENDING_MESSAGE: {			
			return {
				...state,
				pendingMessage: action.message
			};
		}

		// add message
		case MessagesActionTypes.ADD_MESSAGE: {

			const newId = state.lastMessageId + 1;

			const now = new Date().getTime();

			const newMessageSubmitHandler = [
				...state.messages,
				{
					id : newId,
					type : action.message.type,
					time : now,
					content: action.message.content
				}
			];

			if (action.message.type==='self'){
				return {
					...state,
					messages: newMessageSubmitHandler,
					pendingMessage: '',
					lastMessageId: newId
				};
			} else {
				return {
					...state,
					messages: newMessageSubmitHandler,
					lastMessageId: newId
				};
			}
		}

		default:
			return state;
	}
}
