import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Tabs, Tab} from 'react-bootstrap';

import ListType1 from '../../components/102Grids/ListType1';

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as FindParkingSpaceActionCreators from '../../actions/findParkingSpace';

import {getUserInfo} from "../../utils/04Api/User";
import {getSpaceOwner} from "../../utils/04Api/Space";
import {mongoIdTimestamp} from "../../utils/00General/Helpers";
import Loading from "../../components/10General/Loading";

function ReviewBlock(props) {

    // console.log("ReviewBlock", props);

    let review = props.review;

    return (
        <div className="reviewBlock">

            {review.booker &&
            <div className="bookerReview">
                <table>
                    <tbody>
                    <tr>
                        <td width="80">
                            <div className="userImage">
                                <img width="65"
                                     src={review.booker ? review.booker.image : require('../../images/blank.png')}
                                     alt=""/>
                            </div>
                        </td>
                        <td>
                            <div className="userName">
                                <h4>{review.booker ? review.booker.name : null}&nbsp;</h4>
                                &nbsp;
                                <span className="memberDate">
                                    {review.booker ? mongoIdTimestamp(review.booker._id, "YYYY-MM-DD") : null}
                                </span>
                            </div>

                            <div>
                                <ul className="reviews">
                                    <li className={props.review.rate >= 1 ? "active" : ""}></li>
                                    <li className={props.review.rate >= 2 ? "active" : ""}></li>
                                    <li className={props.review.rate >= 3 ? "active" : ""}></li>
                                    <li className={props.review.rate >= 4 ? "active" : ""}></li>
                                    <li className={props.review.rate >= 5 ? "active" : ""}></li>
                                </ul>
                            </div>

                            <div className="sep-10"/>

                            <div className="comment">{props.review.bComment}</div>

                            <div className="sep-10"/>

                            {review.owner &&
                            <div className="ownerReview">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td width="80">
                                            <div className="userImage">
                                                <img width="65"
                                                     src={review.owner ? review.owner.image : require('../../images/blank.png')}
                                                     alt=""/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="userName">
                                                <h4>{review.owner ? review.owner.name : null}&nbsp;</h4>

                                                <span className="memberDate">
                                                    {review.owner ? mongoIdTimestamp(review.owner._id, "YYYY-MM-DD") : null}
                                                </span>
                                            </div>

                                            <div className="comment">{review.oComment}</div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            }
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            }
        </div>
    )
}

class LandlordProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        }
    }

    componentDidMount() {

        this.getOwnerInfo();
    }

    getOwnerInfo = () => {

        let callback = (res) => {

            let body = res.body;

            this.setState({
                ...this.state,
                owner: body,
            }, this.getOwnerSpace);
        }

        getUserInfo(this.props.ownerID, callback);
    }

    getOwnerSpace = () => {

        let callback = (res) => {

            let body = res.body;

            this.setState({
                ...this.state,
                space: body,
                loading: false,
            });
        }

        getSpaceOwner(this.props.ownerID, callback);
    }

    handleVerified = (id) => {
        this.setState({
            verified: {
                ...this.state.verified,
                [id]: !this.state.verified[id]
            }
        });
    }

    handleWishlist = (id) => {
        this.setState({
            wishlist: {
                ...this.state.wishlist,
                [id]: !this.state.wishlist[id]
            }
        });
    }

    isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    render() {

        // console.log(this.state);

        const {t} = this.props;

        if (this.state.loading) {
            return (
                <Loading />
            )
        } else {

            return (
                <div className="wrapper-container landlordProfile">

                    {/* <div className="page-title"><h2>Contact Us</h2></div> */}

                    <div className="sep-0"/>

                    <div className="containerMain">

                        <div className="content clearfix">

                            <div className="landlord left mobile-full">
                                <div className="image">
                                    <img
                                        src={this.state.owner.image ? this.state.owner.image : require('../../images/blank.png')}
                                        alt=""/>
                                </div>

                                <h3>{this.state.owner.isCorp ? (this.state.owner.companyname) : (this.state.owner.first_name + " " + this.state.owner.last_name)}</h3>

                                <div
                                    className="memberSince">{t("space:memberSince")}<span>{mongoIdTimestamp(this.state.owner._id, "YYYY-MM-DD")}</span>{t("space:memberAt")}
                                </div>

                                {/*<div className="Verified">Email and Mobile number verified</div>*/}

                                {/*<div className="response">*/}
                                {/*    <div className="rate">Avg response rate <span>80%</span></div>*/}
                                {/*    <div className="time">Avg response time <span>12 mins</span></div>*/}
                                {/*</div>*/}
                            </div>

                            <div className="profile left mobile-full">
                                <div className="wrapper-tabs">
                                    <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                                        <Tab eventKey={1} title={t("myAccount:current") + t("myAccount:listings")}>
                                            <div className="tab-content">

                                                {Object.keys(this.state.space).map(key1 => {

                                                        return (
                                                            <div key={key1}>
                                                                <ListType1
                                                                    space={this.state.space[key1]}
                                                                    verified={this.state.space[key1].verified}
                                                                    handleVerified={this.handleVerified}
                                                                    wishlist={this.state.space[key1].wishlist}
                                                                    handleWishlist={this.handleWishlist}
                                                                    carTypes={this.state.space[key1].cartype}
                                                                    features={this.state.space[key1].features}
                                                                />

                                                                <div className="sep-10"/>

                                                                <div className="inner">
                                                                    {this.isEmpty(this.state.space[key1].reviews) &&
                                                                    <div>{t("space:tabReviews.noReviews")}</div>
                                                                    }
                                                                    {!this.isEmpty(this.state.space[key1].reviews) &&
                                                                    <div className="reviewsBlock">
                                                                        {Object.keys(this.state.space[key1].reviews).map(key => {

                                                                            let review = this.state.space[key1].reviews[key];

                                                                            return (
                                                                                <ReviewBlock
                                                                                    key={key}
                                                                                    review={review}
                                                                                />
                                                                            )
                                                                        })}
                                                                    </div>
                                                                    }
                                                                </div>

                                                                <div className="sep-30"/>
                                                            </div>
                                                        )
                                                    }
                                                )}

                                                {/*<div className="dialog clearfix">*/}
                                                {/*    <div className="image">*/}
                                                {/*        <img*/}
                                                {/*            src={require('../../images/mobile/General/dummy-img/img_milestone2.jpg')}*/}
                                                {/*            alt=""/>*/}
                                                {/*    </div>*/}
                                                {/*    <div className="text">*/}
                                                {/*        <span className="username">Username</span> <span*/}
                                                {/*        className="date">06/10/2017</span> <Link to="/"*/}
                                                {/*                                                 className="reply">Reply</Link>*/}

                                                {/*        <div className="sep-10"/>*/}

                                                {/*        <Reviews score={4} reviews={100}/>*/}

                                                {/*        <div className="sep-10"/>*/}

                                                {/*        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do*/}
                                                {/*            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut*/}
                                                {/*            enim ad minim veniam, quis nostrud exercitation ullamco laboris*/}
                                                {/*            nisi ut aliquip ex ea commodo consequat.</p>*/}

                                                {/*        <div className="sub-dialog">*/}
                                                {/*            <div className="image">*/}
                                                {/*                <img*/}
                                                {/*                    src={require('../../images/mobile/General/dummy-img/img_milestone2.jpg')}*/}
                                                {/*                    alt=""/>*/}
                                                {/*            </div>*/}
                                                {/*            <div className="text">*/}
                                                {/*                <span className="username">Username</span> <span*/}
                                                {/*                className="date">06/10/2017</span>*/}

                                                {/*                <div className="sep-10"/>*/}

                                                {/*                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,*/}
                                                {/*                    sed do eiusmod tempor incididunt ut labore et dolore*/}
                                                {/*                    magna aliqua.</p>*/}
                                                {/*            </div>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}

                                                {/*    <div className="sep-0"/>*/}

                                                {/*    <Link to="/" className="moreComments">More Comments</Link>*/}
                                                {/*</div>*/}
                                            </div>
                                        </Tab>

                                        {/*<Tab eventKey={2} title="Past booking">*/}
                                        {/*    {t("myAccount:tabListings.tab.noPastBooking")}*/}
                                        {/*</Tab>*/}
                                    </Tabs>
                                </div>


                                {/* <ul className="tab-head clearfix">
                                <li><Link to="/" className="active">Current listing</Link></li>
                                <li><Link to="/">Past booking</Link></li>
                            </ul>

                            <div className="sep-30"/>

                             */}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default withTranslation()(LandlordProfile);
// connect(mapStateToProps)(Contact);
