/* STEP ONE */
export const SubmitStep1 = 'listParkingSpace/SubmitStep1';

/* STEP TWO */
export const SubmitStep2 = 'listParkingSpace/SubmitStep2';

/* STEP THREE */
export const SubmitStep3 = 'listParkingSpace/SubmitStep3';

/* ClearListParkingSpace */
export const ClearListParkingSpace = 'listParkingSpace/ClearListParkingSpace';

/* Contact */
export const Contact = 'listParkingSpace/Contact';

/* Payment */
export const Payment = 'listParkingSpace/Payment';
