import React, {Component} from 'react';
import {Redirect} from 'react-router';
// import { Link } from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import ThreeSteps from '../../components/04ListASpace/ThreeSteps';
import PrevNext from '../../components/04ListASpace/PrevNext';
import GoogleApiWrapper from '../../components/103Parts/GoogleMap';
import LocationSearchInput from '../../components/04ListASpace/LocationSearchInput';
import ErrorMessages from '../../components/04ListASpace/ErrorMessages';

import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import * as StepOneActionCreators from "../../actions/listParkingSpace";
import ReactPixel from "react-facebook-pixel";

class StepOne extends Component
{
    constructor(props)
    {
        super(props)
        const {i18n, dispatch}=props;

        // DISPATCH TO ACTIONS
        this.SubmitStep1=bindActionCreators(StepOneActionCreators.SubmitStep1, dispatch);

        this.state={
            language: i18n.language,
            formSubmitted: false,
            addressTitle: '',
            addressTcTitle: '',
            address: '',
            lat: null,
            lng: null,
            floor: '',
            spaceNo: '',
            SpaceSuitable: {
                PrivateCar: true,
                Van: false,
                Motorcycle: false,
                Lorry: false,
                Others: false,
                LorryTones: ''
            },
            Feature: 'Indoor',
            FeatureEV: {
                yes: false
            },
            FeatureHeadRoom: {
                yes: false,
                Height: ''
            },
            Restrictions: ['No'],
            DetailedDescription: {
                NearEntrance: false,
                WideLoad: false,
                NearLift: false,
                NearStairs: false,
                NearBlock: false,
                NearBlockText: '',
                CorneredSpace: false,
            },
            AccessType: 'AccessCard',
            AccessCard: {
                MeetUp: false,
                SpecifyCost: false,
                Cost: '',
            },
            reserved: false,
            quantity: 1,
            isCorp: typeof (this.props.members.Login.user) !== "undefined" ? this.props.members.Login.user.isCorp : null,
            Errors: [],
        };


        // console.log('u', this.props.members.Login.length, this.props.members.Login.user.isCorp, this.state.isCorp);
        //alert("is corp " + this.state.isCorp, + " - " + this.props.members.Login.user.isCorp);
    }

    componentDidMount=() =>
    {
        // const storageData = JSON.parse(sessionStorage.getItem('state'));
        // console.log(storageData);

        if (this.props.listParkingSpace.StepOne.length !== 0)
        {
            this.setState(this.props.listParkingSpace.StepOne)
            this.setState({
                formSubmitted: false,
                Errors: [],
            });
        }

        ReactPixel.trackCustom('ListSpace', {
            content_name: 'step1',
        });
    }

    componentDidUpdate=() =>
    {
        // sessionStorage.setItem('state', JSON.stringify(this.state));
        // console.log(this.state);
    }

    changeStateLevelOne=(data1, value) =>
    {
        this.setState({
            [data1]: value
        })
    }

    changeStateLevelTwo=(data1, data2, value, cb) =>
    {
        if (data1 === "AccessCard" && data2 === "Cost")
            value=parseInt(value) ? parseInt(value) : '';

        this.setState({
            [data1]: {
                ...this.state[data1],
                [data2]: value
            }
        }, () =>
        {
            if (typeof (cb) === "function")
                cb();
        })
    }
    //
    // checkLorryIsOn = () => {
    //     if (this.state.SpaceSuitable.LorryTones !== '' && !this.state.SpaceSuitable.Lorry)
    //         this.changeStateCheckboxLevelTwo('SpaceSuitable', 'Lorry');
    // }

    resetLorrySize=() =>
    {
        if (this.state.SpaceSuitable.LorryTones !== '' && !this.state.SpaceSuitable.Lorry)
            this.changeStateLevelTwo('SpaceSuitable', 'LorryTones', '')
    }

    changeStateRadioLevelOne=(data1, value) =>
    {
        this.setState({
            [data1]: value
        })
    }

    resetFeatureHeadroom=() =>
    {
        if (this.state.FeatureHeadRoom.Height !== '' && !this.state.FeatureHeadRoom.yes)
            this.changeStateLevelTwo('FeatureHeadRoom', 'Height', '')
    }

    changeStateCheckboxLevelTwo=(data1, data2, cb) =>
    {
        let ok=true;

        if (data1 === "SpaceSuitable" && this.state.SpaceSuitable[data2])
        {
            // turn off space suitable
            // check if is last one
            let types=["PrivateCar", "Van", "Motorcycle", "Lorry", "Others"];

            ok=false;
            types.splice(types.indexOf(data2), 1);

            types.forEach((t) =>
            {
                if (this.state.SpaceSuitable[t])
                    ok=true;
            })
        }

        if (data1 === "DetailedDescription")
        {
            // check max 5 selects
            let types=['NearEntrance', 'WideLoad', 'NearLift', 'NearStairs', 'NearBlock', 'CorneredSpace'],
            DetailedDescription=this.state.DetailedDescription,
            choosen=0;

            types.forEach((t) =>
            {
                if ((t !== data2 && DetailedDescription[t]) ||
                (t === data2 && !DetailedDescription[t]))
                    choosen++;
            })
            if (choosen>5)
                ok=false;
        }

        if (data1 === "Restrictions")
        {
            ok=false;

            if (data2 === 'No')
            {
                this.setState({
                    [data1]: [data2]
                }, () =>
                {
                    if (typeof (cb) === "function")
                        cb();
                })
            }
            else
            {
                if (this.state[data1].indexOf('No')> -1)
                    this.state[data1].splice(this.state[data1].indexOf('No'), 1);

                if (this.state[data1].indexOf(data2)>-1)
                {
                    this.state[data1].splice(this.state[data1].indexOf(data2), 1);

                    this.setState({
                        [data1]: [
                            ...this.state[data1]
                        ]
                    }, () =>
                    {
                        if (typeof (cb) === "function")
                            cb();
                    });
                }
                else
                {
                    this.setState({
                        [data1]: [
                            ...this.state[data1],
                            data2
                        ]
                    }, () =>
                    {
                        if (typeof (cb) === "function")
                            cb();
                    });

                }
            }
        }

        if (!ok)
            return;

        this.setState({
            [data1]: {
                ...this.state[data1],
                [data2]: !this.state[data1][data2]
            }
        }, () =>
        {
            if (typeof (cb) === "function")
                cb();
        })
    };


    // Address auto complete with Google Map
    handleChange=(address) =>
    {
        this.setState({address});
    };

    latLngHandler=(latLng, address) =>
    {
        this.setState({
            lat: latLng.lat,
            lng: latLng.lng,
            address: address,
        })
    }

    handleMapNoResult=(address) =>
    {
        this.setState({
            lat: null,
            lng: null,
            address: address,
        })
    }

    formSubmit=(e) =>
    {
        e.preventDefault();
        //
        // const {
        //     t,
        //     // i18n
        // } = this.props;

        let data=this.state;
        let errors=[];

        if (data.addressTitle === "")
            errors.push({field: "addressTitle", message: "listASpace:errors.step1.addressTitle"});

        if (data.addressTcTitle === "")
            errors.push({field: "addressTitle", message: "listASpace:errors.step1.addressTcTitle"});

        if (data.address === "")
            errors.push({field: "address", message: "listASpace:errors.step1.address"});
        else if (data.lat === null || data.lng === null)
            errors.push({field: "address", message: "listASpace:errors.step1.validAddress"});

        if (!data.isCorp)
        {
            if (data.floor === "")
                errors.push({field: "floor", message: "listASpace:errors.step1.floor"});

            if (data.spaceNo === "")
                errors.push({field: "spaceNo", message: "listASpace:errors.step1.spaceNo"});
        }
        else
        {
            if (parseInt(data.quantity)<1)
                errors.push({field: "quantity", message: "listASpace:errors.step1.quantity"});
        }

        if (!data.SpaceSuitable.PrivateCar && !data.SpaceSuitable.Van && !data.SpaceSuitable.Motorcycle && !data.SpaceSuitable.Lorry && !data.SpaceSuitable.Others)
            errors.push({field: "SpaceSuitable", message: "Please choose at least 1 space suitable."});

        if (data.SpaceSuitable.Lorry && data.SpaceSuitable.LorryTones === '')
            errors.push({field: "SpaceSuitable", message: "listASpace:errors.step1.lorryWeight"});

        // if (data.SpaceSuitable.Others && data.SpaceSuitable.OthersSpecify === "")
        //     errors.push({ field: "SpaceSuitable", message: "Please specify the type of other space suitable." });

        if (data.FeatureHeadRoom.yes && data.FeatureHeadRoom.Height === "")
            errors.push({field: "FeatureHeadRoom", message: "listASpace:errors.step1.headRoom"});

        if (data.DetailedDescription.NearBlock && data.DetailedDescription.NearBlockText === "")
            errors.push({field: "NearBlockText", message: "listASpace:errors.step1.nearBlock"});
        //
        // if (!data.AccessCard.MeetUp && !data.AccessCard.SpecifyCost)
        //     errors.push({ field: "AccessCard", message: "Please choose a access card / device delivery method." });
        // else if (data.AccessCard.SpecifyCost && data.AccessCard.Cost === "")
        //     errors.push({ field: "AccessCard", message: "Please enter the access card / device delivery cost." });

        if (errors.length)
        {
            this.setState({
                ...this.state,
                Errors: errors
            })
        }
        else
        {

            // console.log("step1_data", data);

            this.SubmitStep1(data);

            this.setState({
                ...this.state,
                formSubmitted: true
            });
        }
    };

    render()
    {
        const {
            t,
            i18n
        }=this.props;

        // console.log(this.state);

        if (this.props.members.Login.length === 0)
        {
            return <Redirect to={"/" + i18n.language + "/login"}/>;
        }

        if (this.state.formSubmitted === true)
        {
            return <Redirect to={"/" + i18n.language + "/stepTwo"}/>;
        }

        return (
        <div className="wrapper-container memberSettings">
            <div className="page-title">
                <h2>{t("listASpace:title")}</h2>
                <div className="sep-0"/>
                <ThreeSteps
                selected={1}
                stepsLeft={3}
                />
            </div>
            <div className="containerMain">
                <div className="content">
                    <p className="requiredField absolute">{/*TODO*/} * {t("listASpace:stepOne.required")}</p>
                    <h3>{t("listASpace:stepOne.title")}</h3>

                    <div className="sep-20"/>

                    <dl className="generalForm clearfix">
                        <div>
                            <dt className="align-top">
                                <h4>{t("listASpace:stepOne.addressTitle")} *</h4>
                            </dt>
                            <dd>
                                <div className="wrap clearfix">
                                    <div className="full">
                                        <div>
                                            <input type="text"
                                                   placeholder={t("listASpace:stepOne.enterAddressTitle")}
                                                   onChange={(e) => this.changeStateLevelOne('addressTitle', e.target.value)}
                                                   value={this.state.addressTitle}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sep-40"/>
                            </dd>
                        </div>
                        <div>
                            <dt className="align-top">
                                <h4>{t("listASpace:stepOne.addressTcTitle")} *</h4>
                            </dt>
                            <dd>
                                <div className="wrap clearfix">
                                    <div className="full">
                                        <div>
                                            <input type="text"
                                                   placeholder={t("listASpace:stepOne.enterAddressTcTitle")}
                                                   onChange={(e) => this.changeStateLevelOne('addressTcTitle', e.target.value)}
                                                   value={this.state.addressTcTitle}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sep-40"/>
                            </dd>
                        </div>
                        <div>
                            <dt className="align-top">
                                <h4>{t("listASpace:stepOne.address")} *</h4>
                            </dt>
                            <dd>
                                <div className="wrap clearfix">
                                    <div className="full">
                                        <div>
                                            <LocationSearchInput
                                            address={this.state.address}
                                            handleChange={this.handleChange}
                                            latLngHandler={this.latLngHandler}
                                            handleMapNoResult={this.handleMapNoResult}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sep-40"/>
                            </dd>
                        </div>

                        {!this.state.isCorp &&
                        <div>
                            <dt></dt>
                            <dd>
                                <div className="wrap clearfix">
                                    <div className="fifty">
                                        <div>
                                            <input type="text" placeholder={t("listASpace:stepOne.enterFloor")}
                                                   onChange={(e) => this.changeStateLevelOne('floor', e.target.value)}
                                                   value={this.state.floor}
                                                   maxLength={5}
                                            />
                                        </div>
                                    </div>
                                    <div className="fifty">
                                        <div>
                                            <input type="text" placeholder={t("listASpace:stepOne.enterSpaceNo")}
                                                   onChange={(e) => this.changeStateLevelOne('spaceNo', e.target.value)}
                                                   value={this.state.spaceNo}
                                                   maxLength={5}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="sep-10 spaceFix"></div>
                                <p>*{t("listASpace:stepOne.addressPrivacy")}</p>
                                <div className="sep-10 spaceFix"></div>
                            </dd>
                        </div>
                        }

                        <div>
                            <dt></dt>
                            <dd>
                                <div className="wrapper-map">
                                    <GoogleApiWrapper lat={this.state.lat} lng={this.state.lng} width={"100%"}/>
                                </div>
                            </dd>
                        </div>

                        <div className="sep-40"/>


                        {this.state.isCorp &&
                        <div className="displayTable">
                            {/*  reserved */}
                            <div>
                                <dt className="align-top">
                                    <h4>{t("listASpace:stepOne.reserved.title")}</h4>
                                </dt>

                                <dd>
                                    <div className="wrap clearfix">
                                        <div className="fifty">
                                            <div>
                                                <div className="checkboxFive green">
                                                    <div>
                                                        <input type="checkbox" id="reserved"
                                                               checked={this.state.reserved}
                                                               onChange={(e) => this.changeStateRadioLevelOne('reserved', true)}
                                                        />
                                                        <label htmlFor="reserved"/>
                                                    </div>
                                                    <label
                                                    htmlFor="reserved">{t("listASpace:stepOne.reserved.options.yes")}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="fifty">
                                            <div>
                                                <div className="checkboxFive green">
                                                    <div>
                                                        <input type="checkbox" id="notreserved"
                                                               checked={!this.state.reserved}
                                                               onChange={(e) => this.changeStateRadioLevelOne('reserved', false)}
                                                        />
                                                        <label htmlFor="notreserved"/>
                                                    </div>
                                                    <label
                                                    htmlFor="notreserved">{t("listASpace:stepOne.reserved.options.no")}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </dd>
                            </div>
                            {/*  EOF reserved */}

                            <div className="sep-40"/>

                            {/*  quantity */}
                            <div>
                                <dt className="align-top">
                                    <h4>{t("listASpace:stepOne.quantity")}</h4>
                                </dt>
                                <dd>
                                    <div className="wrap clearfix">
                                        <div className="full">
                                            <div>
                                                <input type="text"
                                                       onChange={(e) => this.changeStateLevelOne('quantity', e.target.value)}
                                                       value={this.state.quantity}
                                                       maxLength={2}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </dd>
                            </div>
                            {/*  EOF quantity */}

                            <div className="sep-40"/>
                        </div>
                        }

                        {/*  suitable */}
                        <div>
                            <dt className="align-top">
                                <h4>{t("listASpace:stepOne.spaceSuitable.title")}</h4>
                            </dt>
                            <dd className="align-top">
                                <ul className="booking-types clearfix">

                                    <div className="oneThird">
                                        <li>
                                            <div
                                            onClick={(e) => this.changeStateCheckboxLevelTwo('SpaceSuitable', 'PrivateCar')}>
                                                <div
                                                className={"icon privateCar " + (this.state.SpaceSuitable.PrivateCar ? 'active' : '')}></div>
                                                <h5>{t("listASpace:stepOne.spaceSuitable.options.privateCar")}</h5>
                                            </div>
                                        </li>
                                    </div>
                                    <div className="oneThird">
                                        <li>
                                            <div
                                            onClick={(e) => this.changeStateCheckboxLevelTwo('SpaceSuitable', 'Van')}>
                                                <div
                                                className={"icon van " + (this.state.SpaceSuitable.Van ? 'active' : '')}></div>
                                                <h5>{t("listASpace:stepOne.spaceSuitable.options.van")}</h5>
                                            </div>
                                        </li>
                                    </div>
                                    <div className="oneThird">
                                        <li>
                                            <div
                                            onClick={(e) => this.changeStateCheckboxLevelTwo('SpaceSuitable', 'Motorcycle')}>
                                                <div
                                                className={"icon motorcycle " + (this.state.SpaceSuitable.Motorcycle ? 'active' : '')}></div>
                                                <h5>{t("listASpace:stepOne.spaceSuitable.options.motorcycle")}</h5>
                                            </div>
                                        </li>
                                    </div>
                                    <div className="sep-0"/>
                                    <div className="oneThird">
                                        <li>
                                            <div
                                            onClick={(e) => this.changeStateCheckboxLevelTwo('SpaceSuitable', 'Lorry', this.resetLorrySize)}>
                                                <div
                                                className={"icon lorry " + (this.state.SpaceSuitable.Lorry ? 'active' : '')}></div>
                                                <h5>{t("listASpace:stepOne.spaceSuitable.options.lorry")}</h5>
                                            </div>
                                            {this.state.SpaceSuitable.Lorry &&
                                            <div>
                                                <div className="sep-10"/>
                                                <select
                                                value={this.state.SpaceSuitable.LorryTones}
                                                onChange={(e) => this.changeStateLevelTwo('SpaceSuitable', 'LorryTones', e.target.value)}
                                                >
                                                    <option
                                                    value=''>{t("listASpace:stepOne.spaceSuitable.pleaseSelect")}</option>
                                                    <option
                                                    value='< 5.5'>{'<'} 5.5 {t("listASpace:stepOne.spaceSuitable.tones")}</option>
                                                    <option
                                                    value='5.5'>5.5 {t("listASpace:stepOne.spaceSuitable.tones")}</option>
                                                    <option
                                                    value='> 5.5'>{'>'} 5.5 {t("listASpace:stepOne.spaceSuitable.tones")}</option>
                                                </select>
                                            </div>
                                            }
                                        </li>
                                    </div>
                                    <div className="oneThird">
                                        <li>
                                            <div
                                            onClick={(e) => this.changeStateCheckboxLevelTwo('SpaceSuitable', 'Others')}>
                                                <div
                                                className={"icon others " + (this.state.SpaceSuitable.Others ? 'active' : '')}></div>
                                                <h5>{t("listASpace:stepOne.spaceSuitable.options.others")}</h5>
                                            </div>
                                            {/* <div className="sep-10" />
                                                <input type="text"
                                                    onChange={(e) => this.changeStateLevelTwo('SpaceSuitable', 'OthersSpecify', e.target.value)}
                                                    value={this.state.SpaceSuitable.OthersSpecify}
                                                /> */}
                                        </li>
                                    </div>
                                </ul>


                                {this.state.SpaceSuitable.Lorry &&
                                <div>
                                    <ul className="booking-types clearfix no-margin">
                                        <div className="oneThird">
                                            <li>
                                                        <span
                                                        className={"triangle " + (this.state.SpaceSuitable.Lorry ? 'active' : '')}></span>
                                            </li>
                                        </div>
                                        <div className="oneThird">
                                            <li>
                                                &nbsp;
                                            </li>
                                        </div>
                                        <div className="oneThird">
                                            <li>
                                                &nbsp;
                                            </li>
                                        </div>
                                    </ul>
                                    <div
                                    className={"detailBox " + (this.state.SpaceSuitable.Lorry ? 'active ' : '')}>
                                        <select
                                        className={(this.state.SpaceSuitable.Lorry ? 'active' : '')}
                                        onChange={(e) => this.changeStateLevelTwo('SpaceSuitable', 'LorryTones', e.target.value)}
                                        >
                                            <option
                                            value=''>{t("listASpace:stepOne.spaceSuitable.pleaseSelect")}</option>
                                            <option
                                            value='< 5.5'>{'<'} 5.5 {t("listASpace:stepOne.spaceSuitable.tones")}</option>
                                            <option
                                            value='5.5'>5.5 {t("listASpace:stepOne.spaceSuitable.tones")}</option>
                                            <option
                                            value='> 5.5'>{'>'} 5.5 {t("listASpace:stepOne.spaceSuitable.tones")}</option>
                                        </select>
                                        {/* <input type="text"
                                            className={(this.state.SpaceSuitable.Others ? 'active' : '')}
                                            onChange={(e) => this.changeStateLevelTwo('SpaceSuitable', 'OthersSpecify', e.target.value)}
                                            value={this.state.SpaceSuitable.OthersSpecify}
                                        /> */}
                                    </div>
                                </div>
                                }
                            </dd>
                        </div>
                        {/*  EOF suitable */}

                        <div className="sep-40"/>

                        {/*  features */}
                        <div>
                            <dt className="align-top">
                                <h4>{t("listASpace:stepOne.feature.title")}</h4>
                            </dt>
                            <dd className="align-top">
                                <ul className="booking-types clearfix">
                                    <div className="oneThird">
                                        <li>
                                            <div
                                            onClick={(e) => this.changeStateRadioLevelOne('Feature', 'Indoor')}>
                                                <div
                                                className={"icon indoor " + (this.state.Feature === 'Indoor' ? 'active' : '')}></div>
                                                <h5>{t("listASpace:stepOne.feature.options.indoor")}</h5>
                                            </div>
                                        </li>
                                    </div>
                                    <div className="oneThird">
                                        <li>
                                            <div
                                            onClick={(e) => this.changeStateRadioLevelOne('Feature', 'WithCovers')}>
                                                <div className="or">{t("listASpace:stepOne.feature.or")}</div>
                                                <div
                                                className={"icon withCovers " + (this.state.Feature === 'WithCovers' ? 'active' : '')}></div>
                                                <h5>{t("listASpace:stepOne.feature.options.withCovers")}</h5>
                                            </div>
                                        </li>
                                    </div>
                                    <div className="oneThird">
                                        <li>
                                            <div
                                            onClick={(e) => this.changeStateRadioLevelOne('Feature', 'NoCovers')}>
                                                <div className="or">{t("listASpace:stepOne.feature.or")}</div>
                                                <div
                                                className={"icon noCovers " + (this.state.Feature === 'NoCovers' ? 'active' : '')}></div>
                                                <h5>{t("listASpace:stepOne.feature.options.noCovers")}</h5>
                                            </div>
                                        </li>
                                    </div>

                                    <div className="sep-0"/>

                                    <div className="oneThird">
                                        <li>
                                            <div
                                            onClick={(e) => this.changeStateCheckboxLevelTwo('FeatureEV', 'yes')}>
                                                <div
                                                className={"icon ev " + (this.state.FeatureEV.yes ? 'active' : '')}></div>
                                                <h5>{t("listASpace:stepOne.feature.options.ev")}</h5>
                                            </div>
                                        </li>
                                    </div>
                                    <div className="oneThird">
                                        <li>
                                            <div
                                            onClick={(e) => this.changeStateCheckboxLevelTwo('FeatureHeadRoom', 'yes', this.resetFeatureHeadroom)}>
                                                <div
                                                className={"icon headRoom " + (this.state.FeatureHeadRoom.yes ? 'active' : '')}></div>
                                                <h5>{t("listASpace:stepOne.feature.options.headRoom")}</h5>
                                            </div>

                                            {this.state.FeatureHeadRoom.yes &&
                                            <div>
                                                <div className="sep-10"/>

                                                <select
                                                value={this.state.FeatureHeadRoom.Height}
                                                onChange={(e) => this.changeStateLevelTwo('FeatureHeadRoom', 'Height', e.target.value)}
                                                >
                                                    <option
                                                    value="">{t("listASpace:stepOne.feature.pleaseSelect")}</option>
                                                    <option
                                                    value="1.7">1.7 {t("listASpace:stepOne.feature.m")}</option>
                                                    <option
                                                    value="1.8">1.8 {t("listASpace:stepOne.feature.m")}</option>
                                                    <option
                                                    value="1.9">1.9 {t("listASpace:stepOne.feature.m")}</option>
                                                    <option
                                                    value="2.0">2.0 {t("listASpace:stepOne.feature.m")}</option>
                                                    <option
                                                    value="2.1">2.1 {t("listASpace:stepOne.feature.m")}</option>
                                                    <option
                                                    value="2.2">2.2 {t("listASpace:stepOne.feature.m")}</option>
                                                    <option
                                                    value="2.3">2.3 {t("listASpace:stepOne.feature.m")}</option>
                                                    <option
                                                    value="2.4orAbove">2.4 {t("listASpace:stepOne.feature.m")}{t("listASpace:stepOne.feature.above")}</option>
                                                </select>
                                            </div>
                                            }
                                        </li>
                                    </div>
                                </ul>

                                {this.state.FeatureHeadRoom.yes &&
                                <div>
                                    <ul className="booking-types clearfix no-margin">
                                        <div className="oneThird">
                                            <li>
                                                <span className="triangle"></span>
                                            </li>
                                        </div>
                                        <div className="oneThird">
                                            <li>
                                                        <span
                                                        className={"triangle " + (this.state.FeatureHeadRoom.yes ? 'active ' : '')}></span>
                                            </li>
                                        </div>
                                        <div className="oneThird">
                                            <li>

                                            </li>
                                        </div>
                                    </ul>
                                    <div
                                    className={"detailBox " + (this.state.FeatureHeadRoom.yes ? 'active ' : '')}>
                                        <select
                                        className={this.state.FeatureHeadRoom.yes ? 'active' : ''}
                                        value={this.state.FeatureHeadRoom.Height}
                                        onChange={(e) => this.changeStateLevelTwo('FeatureHeadRoom', 'Height', e.target.value)}
                                        >
                                            <option value="">{t("listASpace:stepOne.feature.pleaseSelect")}</option>
                                            <option value="1.7">1.7 {t("listASpace:stepOne.feature.m")}</option>
                                            <option value="1.8">1.8 {t("listASpace:stepOne.feature.m")}</option>
                                            <option value="1.9">1.9 {t("listASpace:stepOne.feature.m")}</option>
                                            <option value="2.0">2.0 {t("listASpace:stepOne.feature.m")}</option>
                                            <option value="2.1">2.1 {t("listASpace:stepOne.feature.m")}</option>
                                            <option value="2.2">2.2 {t("listASpace:stepOne.feature.m")}</option>
                                            <option value="2.3">2.3 {t("listASpace:stepOne.feature.m")}</option>
                                            <option
                                            value="2.4orAbove">2.4 {t("listASpace:stepOne.feature.m")}{t("listASpace:stepOne.feature.above")}</option>
                                        </select>
                                    </div>
                                </div>
                                }
                            </dd>
                        </div>
                        {/*  EOF features */}

                        <div className="sep-40"/>

                        {/*  restrictions */}
                        <div>
                            <dt className="align-top">
                                <h4>{t("listASpace:stepOne.restrictions.title")}*</h4>
                            </dt>
                            <dd className="align-top">
                                <div className="wrap clearfix">
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="RestrictionsNo"
                                                           checked={this.state.Restrictions.indexOf('No')> -1}
                                                           onChange={(e) => this.changeStateCheckboxLevelTwo('Restrictions', 'No')}
                                                    />
                                                    <label htmlFor="RestrictionsNo"></label>
                                                </div>
                                                <label
                                                htmlFor="RestrictionsNo">{t("listASpace:stepOne.restrictions.options.noRestriction")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="owner"
                                                           checked={this.state.Restrictions.indexOf('Owners')> -1}
                                                           onChange={(e) => this.changeStateCheckboxLevelTwo('Restrictions', 'Owners')}
                                                    />
                                                    <label htmlFor="owner"></label>
                                                </div>
                                                <label
                                                htmlFor="owner">{t("listASpace:stepOne.restrictions.options.ownersBuilding")}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="sep-40"/>

                                <div className="wrap clearfix">
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="OwnersParkers"
                                                           checked={this.state.Restrictions.indexOf('Parkers')> -1}
                                                           onChange={(e) => this.changeStateCheckboxLevelTwo('Restrictions', 'Parkers')}
                                                    />
                                                    <label htmlFor="OwnersParkers"></label>
                                                </div>
                                                <label
                                                htmlFor="OwnersParkers">{t("listASpace:stepOne.restrictions.options.ownersParkersBuilding")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="VisitorsOnly"
                                                           checked={this.state.Restrictions.indexOf('Visitors')> -1}
                                                           onChange={(e) => this.changeStateCheckboxLevelTwo('Restrictions', 'Visitors')}
                                                    />
                                                    <label htmlFor="VisitorsOnly"></label>
                                                </div>
                                                <label
                                                htmlFor="VisitorsOnly">{t("listASpace:stepOne.restrictions.options.visitors")}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </dd>
                        </div>
                        {/*  EOF restrictions */}

                        <div className="sep-40"/>

                        {/*  descriptions */}
                        <div>
                            <dt className="align-top">
                                <h4>{t("listASpace:stepOne.detailedDescription.title")}</h4>
                            </dt>
                            <dd className="align-top">
                                <div className="wrap clearfix">
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="NearEntrance"
                                                           checked={this.state.DetailedDescription.NearEntrance}
                                                           onChange={(e) => this.changeStateCheckboxLevelTwo('DetailedDescription', 'NearEntrance')}
                                                    />
                                                    <label htmlFor="NearEntrance"></label>
                                                </div>
                                                <label
                                                htmlFor="NearEntrance">{t("listASpace:stepOne.detailedDescription.options.entrance")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="WideLoad"
                                                           checked={this.state.DetailedDescription.WideLoad}
                                                           onChange={(e) => this.changeStateCheckboxLevelTwo('DetailedDescription', 'WideLoad')}
                                                    />
                                                    <label htmlFor="WideLoad"></label>
                                                </div>
                                                <label
                                                htmlFor="WideLoad">{t("listASpace:stepOne.detailedDescription.options.wideLoad")}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="sep-40"/>

                                <div className="wrap clearfix">
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="NearLift"
                                                           checked={this.state.DetailedDescription.NearLift}
                                                           onChange={(e) => this.changeStateCheckboxLevelTwo('DetailedDescription', 'NearLift')}
                                                    />
                                                    <label htmlFor="NearLift"></label>
                                                </div>
                                                <label
                                                htmlFor="NearLift">{t("listASpace:stepOne.detailedDescription.options.lift")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="NearStairs"
                                                           checked={this.state.DetailedDescription.NearStairs}
                                                           onChange={(e) => this.changeStateCheckboxLevelTwo('DetailedDescription', 'NearStairs')}
                                                    />
                                                    <label htmlFor="NearStairs"></label>
                                                </div>
                                                <label
                                                htmlFor="NearStairs">{t("listASpace:stepOne.detailedDescription.options.stairs")}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="sep-40"/>

                                <div className="wrap clearfix">
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="NearBlock"
                                                           checked={this.state.DetailedDescription.NearBlock}
                                                           onChange={(e) => this.changeStateCheckboxLevelTwo('DetailedDescription', 'NearBlock')}
                                                    />
                                                    <label htmlFor="NearBlock"></label>
                                                </div>
                                                <label
                                                htmlFor="NearBlock">{t("listASpace:stepOne.detailedDescription.options.block")}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="fifty">
                                        {this.state.DetailedDescription.NearBlock ?
                                        <div>
                                            <input type="text"
                                                   placeholder={t("listASpace:stepOne.detailedDescription.pleaseEnter")}
                                                   maxLength="10"
                                                   onChange={(e) => this.changeStateLevelTwo('DetailedDescription', 'NearBlockText', e.target.value)}
                                                   value={this.state.DetailedDescription.NearBlockText}
                                            />
                                        </div> : ''}
                                    </div>
                                </div>

                                <div className="sep-40"/>

                                <div className="wrap clearfix">
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="CorneredSpace"
                                                           checked={this.state.DetailedDescription.CorneredSpace}
                                                           onChange={(e) => this.changeStateCheckboxLevelTwo('DetailedDescription', 'CorneredSpace')}
                                                    />
                                                    <label htmlFor="CorneredSpace"></label>
                                                </div>
                                                <label
                                                htmlFor="CorneredSpace">{t("listASpace:stepOne.detailedDescription.options.cornered")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fifty">
                                        <div></div>
                                    </div>
                                </div>
                            </dd>
                        </div>
                        {/*  EOF descriptions */}

                        <div className="sep-40"/>

                        {/*  accessType */}
                        <div>
                            <dt className="align-top">
                                <h4>{t("listASpace:stepOne.accessType.title")}*</h4>
                            </dt>
                            <dd>
                                <div className="wrap clearfix">
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="AccessCard"
                                                           checked={this.state.AccessType === "AccessCard"}
                                                           onChange={(e) => this.changeStateRadioLevelOne('AccessType', 'AccessCard')}
                                                    />
                                                    <label htmlFor="AccessCard"/>
                                                </div>
                                                <label
                                                htmlFor="AccessCard">{t("listASpace:stepOne.accessType.options.accessCard")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fifty">

                                    </div>
                                </div>

                                <div className="sep-40"/>

                                <div className="wrap clearfix">
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="OctopusCard"
                                                           checked={this.state.AccessType === "OctopusCard"}
                                                           onChange={(e) => this.changeStateRadioLevelOne('AccessType', 'OctopusCard')}
                                                    />
                                                    <label htmlFor="OctopusCard"/>
                                                </div>
                                                <label
                                                htmlFor="OctopusCard">{t("listASpace:stepOne.accessType.options.octopusCard")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive green">
                                                <div>
                                                    <input type="checkbox" id="AccessTypeNo"
                                                           checked={this.state.AccessType === "No"}
                                                           onChange={(e) => this.changeStateRadioLevelOne('AccessType', 'No')}
                                                    />
                                                    <label htmlFor="AccessTypeNo"/>
                                                </div>
                                                <label
                                                htmlFor="AccessTypeNo">{t("listASpace:stepOne.accessType.options.noAccessCard")}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </dd>
                        </div>
                        {/*  EOF accessType */}

                        <div className="sep-40"/>

                        <div>
                            <dt className="align-top">

                            </dt>
                            <dd>
                                <ErrorMessages errors={this.state.Errors}/>
                            </dd>
                        </div>
                    </dl>

                    <div className="sep-25"/>

                    <PrevNext
                    prevHas={false}
                    prevURL=''
                    prevText={t("listASpace:back")}
                    nextURL='#'
                    nextText={t("listASpace:next")}
                    progress="one"
                    value="1/3"
                    prevHandler={null}
                    nextHandler={(e) => this.formSubmit(e)}
                    />
                </div>
            </div>
        </div>
        );
    }
}

const mapStateToProps=(state) => (
{
    members: state.members,
    listParkingSpace: state.listParkingSpace
}
);

export default withTranslation()(connect(mapStateToProps)(StepOne));
