import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {getInboxSpaceDetail} from "../../utils/04Api/Space";
import {mongoIdTimestamp} from "../../utils/00General/Helpers";
import {Link} from 'react-router-dom';

import { connect } from 'react-redux';

function Message(props) {
    const {
        index,
        room,
        total_unread,
        t,
        i18n,
        userId,
    } = props;

    const space = room.space;
    const to_user = room.to_user;
    
    const img = (typeof (to_user.image) !== "undefined" ? to_user.image : require('../../images/blank.png'));
    const toUserName = (typeof (to_user.first_name) !== "undefined" ? to_user.first_name : '');
    
    //inbox message
    let inbox_message = '';
    let message = null;
    
    if (room.messages.length) {
        message = room.messages[0];
        inbox_message = message.message;

        if (typeof (message.card) !== 'undefined') {
            // console.log(message);
            let msg_type = (userId === message.receiverID) ? 'owner' : 'parker';

            if (message.card === 'M4' ||
                message.card === 'M3' ||
                message.card === 'M11' ||
                message.card === 'M10' ||
                message.card === 'M6' ||
                (message.card === 'M7'))
                msg_type = msg_type === 'owner' ? 'parker' : 'owner';

            inbox_message = t(("messenger:inbox.card." + message.card + "." + msg_type), {"name": toUserName});

            if(message.card === 'M7' && userId !== message.receiverID)
            {
                if(typeof message.message==='undefined')
                {
                    if(message.data.question_1)
                        message.message=t("contactOwner:suggestedQuestion.wideLoad");

                    if(message.data.question_2)
                        message.message=t("contactOwner:suggestedQuestion.noviceDriver");
                }

                inbox_message = message.message;
            }
        }
    }
    
    return (
        <div key={index} className={"ListType5 clearfix " + (index === 0 ? 'first' : '')}>
            <div className="border-box clearfix">
                <div className="control clearfix">
                    <div className="number">{total_unread}</div>
                </div>

                <div className="wrap clearfix">
                    <div className="person">
                        <Link to={"/" + i18n.language + "/chat/" + space.id + "/" + room.userID}>
                            <div className="image" style={{backgroundImage: `url(${img})`}}/>
                        </Link>
                        <div className="sep-10"/>
                        <h3>{toUserName}</h3>
                    </div>

                    <div className="text">
                        {message &&
                        <div className="details">
                            <Link to={"/" + i18n.language + "/chat/" + space.id + "/" + room.userID}>
                                <h3>{space.title}<br/>{space.address}</h3></Link>
                            <div className="sep-15"/>
                            <span className="dateTime">{mongoIdTimestamp(message._id, "LLL")}</span>
                            <div className="sep-10"/>
                            <p>{inbox_message}</p>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

/*
class Message extends Component {
    constructor(props) {
        super(props);

        this.state = {
            space: null,
            message: null,
            error: false,
        }

        // console.log(props.room);

        if (props.room.messages.length)
            this.state.message = props.room.messages[0];

        // console.log(props.room);
        // console.log("messsage", this.state.message);
    }

    componentDidMount() {
        let cb = (obj) => {
            // console.log("is_mounted", this.props._isMounted);
            if (this.props._isMounted) {
                // console.log("space", obj.body);
                if (obj.status && obj.status === 200) {
                    const space = obj.body;
                    let isOwner, toId;
                    if (space.owner._id === this.props.userId) {
                        isOwner = true;
                        toId = this.props.room.userID;
                    } else {
                        isOwner = false;
                        toId = space.owner._id;
                    }

                    this.setState({
                        ...this.state,
                        space: obj.body,
                        isOwner: isOwner,
                        toId: toId,
                    }, () => {
                        this.getToUser()
                    })
                } else {
                    console.log(obj);
                    this.setError()
                }
            }
        }
        //
        // let errCb = (obj)=> {
        //     this.setState({
        //         ...this.state,
        //         error: true,
        //     })
        // }
        getSpaceDetail(this.props.room.sid, cb, this.setError);
    }

    setError = (err) => {
        console.log(err);
        this.setState({
            ...this.state,
            error: true,
        })
    }

    getToUser = (cb) => {
        let callback = (obj) => {
            // console.log("is_mounted 2", this.props._isMounted);
            if (this.props._isMounted) {
                if (obj.status === 200) {
                    const toUser = obj.body;

                    // console.log("touser", obj);
                    this.setState({
                        ...this.state,
                        toUser: toUser,
                    }, () => {
                        if (typeof (cb) === "function")
                            cb();
                    })
                } else
                    this.setRedirect();
            }
        }
        getUserInfo(this.state.toId, callback, this.setError);
    }

    render() {

        // console.log(this.state.message);

        const {i18n, t, userId} = this.props;

        if (this.state.error)
            return <div/>

        if (!this.state.toUser) {
            return (
                <div className={"ListType5"}>
                    <div className="border-box clearfix">
                        <div>{t("common:Loading")}</div>
                    </div>
                </div>
            )
        }

        const space = this.state.space;

        let img = "",
            toUserName = "";
        if (this.state.toUser) {
            if (typeof (this.state.toUser.image) === "undefined")
                img = require('../../images/blank.png');
            else
                img = this.state.toUser.image;

            toUserName = this.state.toUser.first_name;
        }

        //inbox message
        let message = this.state.message;

        let inbox_message = message.message;

        if (typeof (this.state.message.card) !== 'undefined') {

            // console.log(this.state.message);

            let msg_type = (userId === this.state.message.receiverID) ? 'owner' : 'parker';

            if (this.state.message.card === 'M4' ||
                this.state.message.card === 'M3' ||
                this.state.message.card === 'M11' ||
                this.state.message.card === 'M10' ||
                this.state.message.card === 'M6' ||
                (this.state.message.card === 'M7'))
                msg_type = msg_type === 'owner' ? 'parker' : 'owner';

            inbox_message = t(("messenger:inbox.card." + this.state.message.card + "." + msg_type), {"name": toUserName});

            if(this.state.message.card === 'M7' && userId !== this.state.message.receiverID)
            {
                if(typeof message.message==='undefined')
                {
                    if(message.data.question_1)
                        message.message=t("contactOwner:suggestedQuestion.wideLoad");

                    if(message.data.question_2)
                        message.message=t("contactOwner:suggestedQuestion.noviceDriver");
                }

                inbox_message = message.message;
            }


            // switch (this.state.message.card) {
            //
            //     case "M2":
            //         inbox_message = t(("messenger:inbox.card.M2." + msg_type), {"parker_first_name": toUserName});
            //         break;
            //     case "M4":
            //         inbox_message = t(("messenger:inbox.card.M4." + msg_type), {"parker_first_name": toUserName});
            //         break;
            //     default:
            //         inbox_message = t(("messenger:inbox.card." + this.state.message.card + "." + msg_type));
            //         break;
            // }
        }
        //inbox message

        return (
            <div key={this.props.index} className={"ListType5 clearfix " + (this.props.index === 0 ? 'first' : '')}>
                <div className="border-box clearfix">
                    <div className="control clearfix">
                        <div className="number">{this.props.total_unread}</div>
                    </div>

                    <div className="wrap clearfix">
                        <div className="person">
                            <Link to={"/" + i18n.language + "/chat/" + space._id + "/" + this.props.room.userID}>
                                <div className="image" style={{backgroundImage: `url(${img})`}}/>
                            </Link>
                            <div className="sep-10"/>
                            <h3>{toUserName}</h3>
                        </div>

                        <div className="text">
                            {this.state.message &&
                            <div className="details">
                                <Link to={"/" + i18n.language + "/chat/" + space._id + "/" + this.props.room.userID}>
                                    <h3>{space.title}<br/>{space.address}</h3></Link>
                                <div className="sep-15"/>
                                <span className="dateTime">{mongoIdTimestamp(this.state.message._id, "LLL")}</span>
                                <div className="sep-10"/>
                                <p>{inbox_message}</p>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
*/

class Messages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            rooms: props.rooms,
        }

        // console.log(props.rooms);
    }

    componentDidMount() {
        let {
            rooms,
        } = this.props;

        if (rooms.length) {
            let room_datas = [];
            rooms.forEach((room)=>{
                const room_data = {
                    roomID: room.roomID,
                    space_id: room.sid,
                    user_id: (this.props.members.Login.user._id === room.users[0] ? room.users[1] : room.users[0]),
                };

                room_datas.push(room_data);
            })

            let cb = (obj)=>{
                if (this.props._isMounted) {
                    const room_datas = obj.body;

                    rooms = rooms.map((room) => {
                        let room_data = room_datas.find(item => item.roomID === room.roomID);
                        if (room_data){
                            room.space = room_data.space;
                            room.to_user = room_data.to_user;
                        }
                        return room;
                    });

                    this.setState({
                        ...this.state,
                        rooms: rooms,
                        loaded: true
                    })
                }
            };

            getInboxSpaceDetail(this.props.members.Login.userToken, room_datas, cb, this.setError);
        }
    }

    render() {
        const {
            t,
            i18n,
            rooms_total_unread,
        } = this.props;

        const {
            loaded,
            rooms,
        } = this.state;

        if (!loaded) {
            return <div className={"ListType5"}>
                <div className="border-box clearfix">
                    <div>{t("common:Loading")}</div>
                </div>
            </div>
        }

        let msg_components = {}; //samson
        rooms.forEach((room, index) => {
            if (typeof(room.space) !== 'undefined') {
                let total_unread = 0;
                if (rooms_total_unread) {
                    for (let i = 0; i < rooms_total_unread.length; i++) {
                        if (room.roomID === rooms_total_unread[i].id)
                            total_unread = rooms_total_unread[i].total;
                    }
                }

                msg_components[room.roomID] =
                    <Message key={index}
                             index={index}
                             room={room}
                             total_unread={total_unread}
                             t={t}
                             i18n={i18n}
                             userId={this.props.members.Login.user._id}
                    />;
            }
        });

        return (
            <div>
                <div className="sep-20" />
                {Object.keys(msg_components).map((key) =>
                    msg_components[key]
                )}
            </div>
        )
    };
}

const mapStateToProps = (state) => (
    {
        members: state.members,
    }
);

export default withTranslation()(connect(mapStateToProps)(Messages));