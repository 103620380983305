import React from 'react';
import {withTranslation} from 'react-i18next';

function AddReviews(props) {
    // const {
        // t,
        // i18n,
    // } = props;

    return (
        <div className="addReviewBlock">
            <ul className="reviews">
                <li className={props.score >= 1 ? "active" : ""}
                    onClick={() => {props.changeStateLevelOne("score", 1)}}
                ></li>
                <li className={props.score >= 2 ? "active" : ""}
                    onClick={() => {props.changeStateLevelOne("score", (props.score === 2 ? 1 : 2))}}
                ></li>
                <li className={props.score >= 3 ? "active" : ""}
                    onClick={() => {props.changeStateLevelOne("score", (props.score === 3 ? 2 : 3))}}
                ></li>
                <li className={props.score >= 4 ? "active" : ""}
                    onClick={() => {props.changeStateLevelOne("score", (props.score === 4 ? 3 : 4))}}
                ></li>
                <li className={props.score >= 5 ? "active" : ""}
                    onClick={() => {props.changeStateLevelOne("score", (props.score === 5 ? 4 : 5))}}
                ></li>
            </ul>

            <div className="sep-10"/>

            <div>({props.score}/5)</div>
        </div>
    )
}

export default withTranslation()(AddReviews);