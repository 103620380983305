import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import moment from "moment";
import {Collapse} from 'reactstrap';
import swal from 'sweetalert';
import "../../../css/101Components/_sweetAlert.css";

import * as RenewalApi from "../../../utils/04Api/Renewal";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

class MemberRenewalService extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            selected_booking: '',
            isLoading: false,
            display: false,
            vehicles: [],
            selected_merchant: this.props.merchant
        };
    }

    componentDidMount() {
        this.getBookings();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.merchant !== prevProps.merchant) {
            this.setState({selected_merchant: this.props.merchant, data: [], loading: true});
            this.getBookings();
        }
    }

    getBookings() {
        this.setState({isLoading: true})
        RenewalApi.getEnterpriseBookings(cookies.get('enterprise_token'),
          (response) => {
              // console.log(response)
              if (response.status === 200) {
                  let data = response.body
                    .filter(booking =>
                      booking.status !== "pending" &&
                      booking.status !== "Error" &&
                      (booking.to = moment(booking.to).format("YYYY-MM-DD HH:mm")) &&
                      booking.mid === this.state.selected_merchant._id
                    )
                    .map((item) => ({
                        ...item,
                        _showDetails: true,
                        isRenewed: false,
                        display: false
                    }));
                  this.setState({data})
                  this.getUserVehicles();
              }
              this.setState({isLoading: false})
          }, (error) => {
              console.log(error)
              this.setState({isLoading: false})
          })
    }

    getUserVehicles() {
        this.setState({isLoading: true})
        RenewalApi.getEnterpriseUserInfo(cookies.get('enterprise_token'), this.state.selected_merchant._id,
          (response) => {
              if (response.status === 200) {
                  let data = response.body.accounts[0].vehicles
                  this.setState({vehicles: data})
              }
              this.setState({isLoading: false})
          }, (error) => {
              console.log(error)
              this.setState({isLoading: false})
          })
    }

    getInvoiceDownloadLink(invoiceId) {
        RenewalApi.getEnterpriseInvoiceURL(cookies.get('enterprise_token'), invoiceId,
            (response) => {
                window.location.href = response.body.url
            }, (error) => {
                console.log(error)
            })
    }

    handleRedirect(tabIndex, bookingID, action) {
        const {t, i18n} = this.props;
        const domain = this.state.selected_merchant.web_url
        swal({
            text: t('myAccount:tabRenewal.redirect', {merchant: i18n.language === 'zh-HK' ? this.state.selected_merchant.title_tc : this.state.selected_merchant.title}),
            buttons: {
                cancel: {
                    text: t('myAccount:tabRenewal.cancel'),
                    value: null,
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: t('myAccount:tabRenewal.confirm'),
                    value: true,
                    visible: true,
                    closeModal: true
                }
            }
        })
          .then((isConfirmed) => {
              if (isConfirmed) {
                  window.open(`${domain}/#/profile?tab=${tabIndex}&bid=${bookingID}&action=${action}&access_token=${cookies.get('enterprise_token')}`)
              }
          })
    }

    endDateCheck(toDate) {
        if (moment().isBefore(moment(toDate).endOf('days'))) {
            return true;
        } else {
            return false;
        }
    }

    dateCheck(renewDate, toDate, release) {
        if (moment().isAfter(moment(renewDate)) && moment().isBefore(moment(toDate).add(release, 'days').endOf('days'))) {
            return true;
        } else {
            return false;
        }
    }

    _renderInvoiceType(type, renew) {
        const {t} = this.props;

        if (type === 'preorder' && renew === true)
            return t("myAccount:tabRenewal.service.details.renew")
        else if (type === 'preorder')
            return t("myAccount:tabRenewal.service.details.buynew")
        else if (type === 'temporary' || type === 'permanant')
            return t("myAccount:tabRenewal.service.details.vehiclechange")
    }

    _renderInvoiceSection(booking) {
        const {t} = this.props;

        return <table className={"invoice"}>
            <thead>
            <th>{t('myAccount:tabRenewal.service.invoice.invoiceId')}</th>
            <th>{t('myAccount:tabRenewal.service.invoice.months')}</th>
            <th>{t('myAccount:tabRenewal.service.invoice.type')}</th>
            <th>{t('myAccount:tabRenewal.service.invoice.renew')}</th>
            </thead>
            <tbody>
            {
                booking.invoices.map((invoice) => {
                    if (invoice.status === 'confirmed') {
                        return (
                            <tr>
                                <td>{`#${invoice._id}`}</td>
                                <td>{moment(invoice.expire_date).format('MM-YYYY')}</td>
                                <td>{this._renderInvoiceType(invoice.type, invoice.renew)}</td>
                                <td>
                                    <button onClick={() => this.getInvoiceDownloadLink(invoice._id)}>
                                        {t('myAccount:tabRenewal.service.invoice.download')}
                                    </button>
                                </td>
                            </tr>
                        )
                    }
                })
            }
            </tbody>
        </table>
    }

    _renderNewCarPlate(invoice) {
        const {t} = this.props;

        if (invoice.length > 0 && Array.isArray(invoice)) {
            let carplate = ""
            let vehicle = this.state.vehicles.find((v) => v._id === invoice[0].vehiclenewplate)
            if (vehicle) carplate = vehicle.carplate
            return (
                <table>
                    <tr>
                        {`${t('myAccount:tabRenewal.service.details.newPlate')}: `} {carplate}
                    </tr>
                    <tr>
                        {`${t('myAccount:tabRenewal.service.details.effectiveDate')}: ${t('myAccount:tabRenewal.service.details.from')} ${invoice[0].issue_date} ${t('myAccount:tabRenewal.service.details.to')} ${invoice[0].expire_date}`}
                    </tr>
                </table>
            )
        }
    }

    render() {
        const {
            t,
            i18n
        } = this.props;

        const {data, isLoading} = this.state
        const lang = i18n.language
        const merchant = lang === 'en-US' ? 'Imperial Parking' : '帝豪停車場管理(香港)有限公司'
        return (
            <div className={"memberRenewal"}>
                <table className={"main_table"}>
                    <thead className={"main_table_header"}>
                    <tr>
                        <th>{t("myAccount:tabRenewal.service.tableHeader.carpark")}</th>
                        <th>{t("myAccount:tabRenewal.service.tableHeader.package")}</th>
                        <th>{t("myAccount:tabRenewal.service.tableHeader.vehicle")}</th>
                        <th>{t("myAccount:tabRenewal.service.tableHeader.status")}</th>
                        <th>{t("myAccount:tabRenewal.service.tableHeader.endDate")}</th>
                        <th>{t("myAccount:tabRenewal.service.tableHeader.action")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((booking) => {
                            let change_carplate_invoice = booking.invoices.filter((invoice) => {
                                return (
                                  (invoice.type === 'temporary' || invoice.type === 'permanant') && invoice.status === 'confirmed' && moment(invoice.expire_date).isAfter(Date.now())
                                )
                            })
                            return [
                                <tr className={"main_table_row"}>
                                    <td>{lang === 'en-US' ? booking.space.carpark_id.name_en : booking.space.carpark_id.name_tc}</td>
                                    <td>{lang === 'en-US' ? booking.space.title : booking.space.title_tc}</td>
                                    <td>{booking.vehicle.carplate}</td>
                                    <td>{t(`myAccount:tabRenewal.service.status.${booking.status}`)}</td>
                                    <td>{booking.type === 'Monthly' ? moment(booking.to).format("YYYY-MM-DD") : moment(booking.to).format("YYYY-MM-DD HH:mm")}</td>
                                    <td>
                                        <button
                                          onClick={() => {
                                              let selected_booking = this.state.selected_booking === booking._id ? '' : booking._id
                                              this.setState({selected_booking, display: false})
                                          }}
                                        >
                                            {this.state.selected_booking === booking._id ? t('myAccount:tabRenewal.service.action.hide') : t('myAccount:tabRenewal.service.action.show')}
                                        </button>
                                    </td>
                                </tr>,
                                this.state.selected_booking !== '' && (
                                  <tr>
                                      <td colSpan={6}>
                                          <Collapse className={"collapse"} isOpen={this.state.selected_booking === booking._id}>
                                              <table className={"details"}>
                                                  <tr>
                                                      <td style={{width: "35%"}}>{`${t('myAccount:tabRenewal.service.details.orderId')}: `} {booking._id}</td>
                                                      <td style={{width: "15%", visibility: booking.invoices.length > 0 ? 'visible' : 'hidden'}}>
                                                          <button
                                                            onClick={() => this.setState(prevState => ({display: !prevState.display}))}
                                                          >
                                                              {t('myAccount:tabRenewal.service.details.displayMore')}
                                                          </button>
                                                      </td>
                                                      <td rowSpan={9} style={{width: "50%"}} className={"sub_section"}>
                                                          {this.state.display ? this._renderInvoiceSection(booking) : null}
                                                      </td>
                                                  </tr>
                                                  <tr>
                                                      {`${t('myAccount:tabRenewal.service.details.package')}: `}
                                                      {booking.type === 'Monthly'
                                                        ? t('myAccount:tabRenewal.service.details.monthly')
                                                        : t('myAccount:tabRenewal.service.details.hourly')}
                                                  </tr>
                                                  <tr style={{display: booking.status !== 'terminated' ? 'block' : 'none'}}>
                                                      {`${t('myAccount:tabRenewal.service.details.renewDate')}: ${moment(booking.renewstart).format('YYYY-MM-DD')}`}
                                                  </tr>
                                                  <tr>
                                                      {`${t('myAccount:tabRenewal.service.details.endDate')}: `}
                                                      {booking.type === "Monthly"
                                                        ? moment(booking.to).format("YYYY-MM-DD")
                                                        : `${moment(booking.from).format("YYYY-MM-DD HH:mm")} - ${moment(booking.to).format("YYYY-MM-DD HH:mm")}`}
                                                  </tr>
                                                  <tr className={"configuration"}>
                                                      {t('myAccount:tabRenewal.service.details.configuration')}
                                                  </tr>
                                                  <tr>
                                                      {`${t('myAccount:tabRenewal.service.details.merchant')}: `} {merchant}
                                                  </tr>
                                                  <tr>
                                                      <td colSpan={2}>
                                                          {`${t('myAccount:tabRenewal.service.details.carpark')}: `}
                                                          {lang === 'en-US'
                                                            ? booking.space.carpark_id.name_en
                                                            : booking.space.carpark_id.name_tc}
                                                      </td>
                                                  </tr>
                                                  <tr>
                                                      <td>
                                                          {`${t('myAccount:tabRenewal.service.details.package')}: `}
                                                          {lang === 'en-US'
                                                            ? booking.space.title
                                                            : booking.space.title_tc}
                                                      </td>
                                                      <td style={{display: this.dateCheck(booking.renewstart, booking.to, booking.carparkreleasedate) && booking.status === 'pendingtorenew' && booking.type !== 'Hourly' ? "block" : "none"}}>
                                                          <button
                                                            onClick={() => {
                                                                this.handleRedirect(1, booking._id, 'renew')
                                                            }}
                                                          >
                                                              {t('myAccount:tabRenewal.service.details.renew')}
                                                          </button>
                                                      </td>
                                                  </tr>
                                                  <tr>
                                                      <td>
                                                          {`${t('myAccount:tabRenewal.service.details.registrationMark')}: `} {booking.vehicle.carplate}
                                                      </td>
                                                      <td style={{display: this.endDateCheck(booking.to) && booking.type !== 'Hourly' ? "block" : "none"}}>
                                                          <button
                                                            onClick={() => {
                                                                this.handleRedirect(1, booking._id, 'changecarplate')
                                                            }}
                                                          >
                                                              {t('myAccount:tabRenewal.service.details.vehiclechange')}
                                                          </button>
                                                      </td>
                                                  </tr>
                                                  {this._renderNewCarPlate(change_carplate_invoice)}
                                              </table>
                                          </Collapse>
                                      </td>
                                  </tr>
                                )
                            ]

                        })
                    }
                    {
                        isLoading && (
                          <tr>
                              <td colSpan={9} className={"message"}>Loading...</td>
                          </tr>
                        )
                    }
                    {
                        !isLoading && data.length < 1 && (
                          <tr>
                              <td colSpan={9} className={"message"}>{t('myAccount:tabRenewal.service.noresult')}</td>
                          </tr>
                        )
                    }
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
        members: state.members
    }
);

export default withTranslation()(connect(mapStateToProps)(MemberRenewalService));
