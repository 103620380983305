import React, {Component} from 'react';

class Cancellation_zh_HK extends Component {
    render() {

        // const tableStyle = {border: "1px solid black"};
        //
        // const tdStyle = {border: "1px solid black", padding: "0.5rem"};

        return (
            <div className="cancellation" dangerouslySetInnerHTML={{__html: this.props.content}} />
        );
    }
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default Cancellation_zh_HK;
