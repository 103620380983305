import React, {Component} from 'react';

class ParkerProtectionGuarantee_en_US extends Component {
    render() {
        return (
            <div className="cancellation" dangerouslySetInnerHTML={{__html: this.props.content}}/>
/*{/!*            <div className="cancellation">
                <p>Every booking is covered by the Parker Protection Guarantee with 100% refund policy. Parker should report the case to us within 48 hours from the commencement of the booking, such as:</p>
                <ul>
                    <li>The booked parking space does not match with the description on the Platform which affect the use of the parking space or</li>
                    <li>The booked parking space is unavailable during the booking period.</li>
                </ul>
                <p>Please contact us through email of <a style={{"textDecoration":"underline"}} href="mailto:info@letspark.com.hk">info@letspark.com.hk</a> and explain in details with photographic supporting to facilitate the investigation. Details please refer to the License Agreement.</p>
            </div>*!/}*/
        );
    }
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default ParkerProtectionGuarantee_en_US;
