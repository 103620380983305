import {apiFetch, apiPost} from "./General";

export const findYourSpace = (params, callback) => {
    apiFetch('space', params, callback);
}

export const getSpaceDetail = (spaceID, callback, errorCallback) => {
    apiFetch('space/' + spaceID, null, callback, errorCallback);
}

export const getInboxSpaceDetail = (access_token, room_datas, callback, errorCallback) => {
    const data = {
        room_datas: room_datas
    };
    apiPost('space/inbox?access_token=' + access_token, data, callback, errorCallback);
}

export const getCalculation = (type, spaceID, params, callback, errorCallback) => {
    apiFetch('space/' + type + '/' + spaceID, params, callback, errorCallback);
}

export const getSpaceOwner = (id, callback) => {
    apiFetch('space/owner/' + id, null, callback, null);
}

export const insertSpace = (access_token, data, cb, ecb) => {
    apiPost('space?access_token=' + access_token, data, cb, ecb);
}

export const updateSpace = (access_token, id, data, cb, ecb) => {
    apiPost('space/update/' + id + '?access_token=' + access_token, data, cb, ecb);
}

export const checkHasOnHoldQuota = (userID, callback, errorCallback) => {
    apiFetch(`space/tempBooked/check/${userID}`, {}, callback, errorCallback);
}

export const insertTempBooking = (access_token, id, data, callback, errorCallback) => {
    apiPost(`space/tempBooked/${id}?access_token=${access_token}`, data, callback, errorCallback);
}

export const getQuotaHoldTime = (spaceID, userID, callback, errorCallback) => {
    apiFetch(`space/tempBooked/${spaceID}`, {userID: userID}, callback, errorCallback);
}