import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

// import { Link } from 'react-router-dom';
import {Redirect} from 'react-router';
import {Tabs, Tab} from 'react-bootstrap';

// import * as Config from '../../config';

import ViewChangeWithSlider from "../../components/03FindParkingSpace/ViewChangeWithSlider";
import {lowestPrice} from "../../utils/03FindParkingSpace/LowestHourlyPrice";

import Reviews from '../../components/103Parts/Reviews';
import Tags from '../../components/103Parts/Tags';
import SpaceSuitableFor from '../../components/103Parts/SpaceSuitableFor';
import Features from '../../components/103Parts/Features';
import Restrictions from '../../components/103Parts/Restrictions';
import AccessType from '../../components/103Parts/AccessType';

import DetailHourly from './DetailHourly';
import DetailMonthly from './DetailMonthly';
import DetailTimeshare from './DetailTimeshare';

import * as Helper from "../../utils/03FindParkingSpace/Calculations";
import {mongoIdTimestamp} from "../../utils/00General/Helpers";
import {checkHasOnHoldQuota, getSpaceDetail, insertTempBooking} from '../../utils/04Api/Space';
import {getHoliday} from '../../utils/04Api/Holiday';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as FindParkingSpaceActionCreators from '../../actions/findParkingSpace';

// import moment from 'moment';
import {getUserInfo} from "../../utils/04Api/User";
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle} from "react-accessible-accordion";
import moment from "moment";
import {renderListingTitle} from "../../utils/00General/Helpers";
import Helmet from "react-helmet";
import InstantBooking from "../../components/103Parts/InstantBooking";
import ReactPixel from "react-facebook-pixel";
import {checkBCodeById, getListingById} from "../../utils/04Api/Bcode";

// import forEach from 'lodash';
import swal from 'sweetalert';
import "../../css/101Components/_sweetAlert.css";

function getAvailableWeekDayName(key){
    switch (key){
        case 'MonFri':
            return "listASpace:stepTwo.pricing.price.dayOfWeek4";
        case 'SatSunPH':
            return "listASpace:stepTwo.pricing.price.dayOfWeek1";
        case 'MonSunPH':
            return "listASpace:stepTwo.pricing.price.dayOfWeek2";
        case 'Custom':
            return "listASpace:stepTwo.pricing.price.dayOfWeek3";
        default:
            return '';
    }
}

function BookingTypeBlock(props) {

    let data = props.data;

    //console.log(data);

    let available = '',
        unavailable = '',
        minimum = data.minbooking,
        maximum = [],
        deposit = data.deposit;

    switch (props.bookingType) {

        case "hourly":

            available = data.from + ' - ' + data.to;
            unavailable = data.blocked.length > 0 ? data.blocked.from + ' - ' + data.blocked.to : 'N/A';

            if (typeof (minimum) === 'undefined') {

                minimum = 'N/A'

            } else {

                minimum += props.t("space:tabBookingType.minOptions.hourly");
            }

            if (Array.isArray(data.available) && data.available.some(availableItem => !!availableItem.maxbooking)){
                maximum = data.available.filter(availableItem => !!availableItem.maxbooking);
            }

            break;

        case "monthly":

            available = data.from + ' - ' + data.to;
            unavailable = data.blocked.length > 0 ? data.blocked.from + ' - ' + data.blocked.to : 'N/A';

            if (typeof (minimum) === 'undefined') {
                minimum = 'N/A'
            } else {
                minimum += props.t("space:tabBookingType.minOptions.monthly");
            }

            break;

        case "timeshare":

            available = data.from + ' - ' + data.to;
            unavailable = data.blocked.length > 0 ? data.blocked.from + ' - ' + data.blocked.to : 'N/A';

            if (typeof (minimum) === 'undefined') {
                minimum = 'N/A'
            } else {
                minimum += props.t("space:tabBookingType.minOptions.monthly");
            }

            break;
        default:
            break;
    }

    return (

        <AccordionItem
            expanded={props.expanded}>
            <AccordionItemTitle>
                <h3>{props.t("space:tabBookingType.options." + props.bookingType)}
                </h3>
                <div className={"arrow"}/>
            </AccordionItemTitle>
            <AccordionItemBody>
                <div>
                    <p><b>{props.t("space:tabBookingType.available")}</b></p>
                    <table>
                        <tbody>
                        <tr>
                            <td style={{"float": "left"}}><img style={{"margin": "-3px 6px 0 0"}}
                                                               src={require('../../images/mobile/03_find_parking_space/icon_response_time.png')}
                                                               alt=""/></td>
                            <td style={{"float": "left"}}>{available}</td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="sep-10"/>
                </div>
                {data.blocked.length > 0 &&
                <div>
                    <p><b>{props.t("space:tabBookingType.unavailable")}</b></p>
                    <table>
                        <tbody>
                        <tr>
                            <td style={{"float": "left"}}><img style={{"margin": "-3px 6px 0 0"}}
                                                               src={require('../../images/mobile/03_find_parking_space/icon_unavailable.png')}
                                                               alt=""/></td>
                            <td style={{"float": "left"}}>
                                {Object.keys(data.blocked).map(key => {
                                    return (
                                        <div key={key}>
                                            {data.blocked[key].from + ' - ' + data.blocked[key].to}
                                        </div>
                                    )
                                })}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="sep-10"/>
                </div>
                }
                {data.blocked.length <= 0 &&
                <div>
                    <p><b>{props.t("space:tabBookingType.unavailable")}</b></p>
                    <table>
                        <tbody>
                        <tr>
                            <td style={{"float": "left"}}><img style={{"margin": "-3px 6px 0 0"}}
                                                               src={require('../../images/mobile/03_find_parking_space/icon_unavailable.png')}
                                                               alt=""/></td>
                            <td style={{"float": "left"}}>
                                {unavailable}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="sep-10"/>
                </div>
                }
                <div>
                    <p><b>{props.t("space:tabBookingType.minimum")}</b></p>
                    <table>
                        <tbody>
                        <tr>
                            <td style={{"float": "left"}}><img style={{"margin": "-3px 9px 0 0"}}
                                                               src={require('../../images/mobile/03_find_parking_space/icon_minimum.png')}
                                                               alt=""/></td>
                            <td style={{"float": "left"}}>
                                {minimum}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="sep-10"/>
                </div>
                {
                    maximum.length > 0 &&
                    <div>
                        <p><b>{props.t("space:tabBookingType.maximum")}</b></p>
                        <table>
                            <tbody>
                            {
                                maximum.map(maximumItem => (
                                  <tr>
                                      <td style={{"float": "left"}}><img style={{"margin": "-3px 9px 0 0"}}
                                                                         src={require('../../images/mobile/03_find_parking_space/icon_minimum.png')}
                                                                         alt=""/></td>
                                      <td style={{"float": "left"}}>
                                          {`${props.t(getAvailableWeekDayName(maximumItem.period))}`}&nbsp;
                                      </td>
                                      <td style={{"float": "left"}}>
                                          {`${maximumItem.stime} - ${maximumItem.etime}:`}&nbsp;
                                      </td>
                                      <td style={{"float": "left"}}>
                                          {`${maximumItem.maxbooking} ${props.t("space:tabBookingType.minOptions.hourly")}`}
                                      </td>
                                  </tr>
                                ))
                            }
                            </tbody>
                        </table>
                        <div className="sep-10"/>
                    </div>
                }
                <div>
                    <p><b>{props.t("space:tabBookingType.deposit")}</b></p>
                    <table>
                        <tbody>
                        <tr>
                            <td style={{"float": "left"}}><img style={{"margin": "-3px 4px 0 0"}}
                                                               src={require('../../images/mobile/03_find_parking_space/icon_accesscard.png')}
                                                               alt=""/></td>
                            <td style={{"float": "left"}}>
                                {'$' + deposit}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="sep-10"/>
                </div>
                <div className="remarks"
                     dangerouslySetInnerHTML={{
                         __html: props.t(
                           "space:tabBookingType.remarks." + props.bookingType,
                           {
                               additional_remarks: props.isBcode ? props.t("space:tabBookingType.remarks.bcode") : "",
                           }
                         )
                     }}/>
            </AccordionItemBody>
        </AccordionItem>

    )
}

function ReviewBlock(props) {

// console.log("ReviewBlock", props);

    let review = props.review;

    return (
        <div className="reviewBlock">

            {review.booker &&
            <div className="bookerReview">
                <table>
                    <tbody>
                    <tr>
                        <td width="80">
                            <div className="userImage">
                                <img width="65"
                                     src={review.booker ? review.booker.image : require('../../images/blank.png')}
                                     alt=""/>
                            </div>
                        </td>
                        <td>
                            <div className="userName">
                                <h4>{review.booker ? review.booker.name : null}&nbsp;</h4>
                                &nbsp;
                                <span className="memberDate">
{review.booker ? mongoIdTimestamp(review.booker._id, "YYYY-MM-DD") : null}
</span>
                            </div>

                            <div>
                                <ul className="reviews">
                                    <li className={props.review.rate >= 1 ? "active" : ""}></li>
                                    <li className={props.review.rate >= 2 ? "active" : ""}></li>
                                    <li className={props.review.rate >= 3 ? "active" : ""}></li>
                                    <li className={props.review.rate >= 4 ? "active" : ""}></li>
                                    <li className={props.review.rate >= 5 ? "active" : ""}></li>
                                </ul>
                            </div>

                            <div className="sep-10"/>

                            <div className="comment">{props.review.bComment}</div>

                            <div className="sep-10"/>

                            {review.owner &&
                            <div className="ownerReview">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td width="80">
                                            <div className="userImage">
                                                <img width="65"
                                                     src={review.owner ? review.owner.image : require('../../images/blank.png')}
                                                     alt=""/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="userName">
                                                <h4>{review.owner ? review.owner.name : null}&nbsp;</h4>

                                                <span className="memberDate">
{review.owner ? mongoIdTimestamp(review.owner._id, "YYYY-MM-DD") : null}
</span>
                                            </div>

                                            <div className="comment">{review.oComment}</div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            }
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            }
        </div>
    )
}

class SpaceOne extends Component {

    constructor(props) {

        super(props);

        const {dispatch} = props;

// DISPATCH TO ACTIONS
        this.toPayment = bindActionCreators(FindParkingSpaceActionCreators.toPayment, dispatch);

        this.state = {
// MUST KEEN BELOW STATE STRUCTURE START
            startDateChanged:false,
            view: 'photo',
            BookingType: 'hourly',
            supportTypes: [],
            initSupportTypes: '',
            minPrice: {
                'type': 'hourly',
                'price': 0,
            },
            wishlist: {
                1: true
            },
            formSubmit: false,
            toBookHourly: {
                startDate: null,
                endDate: null,
                startTime: null,
                endTime: null,
                startMinute: null,
                endMinute: null,
                price: 20,
                minBookingError: false,
                maxBookingError: false,
                overnightError: false,
                calculation: {
                    success: null,
                    from: '',
                    to: '',
                    hours: 0,
                    amount: 0,
                    total: 0
                },
                preBookBlocker: 0,
                postBookBlocker: 0
            },
            toBookMonthly: {
                startDate: null,
                endDate: null,
                reserved: false,
                price: 20,
                calculation: {
                    success: null,
                    from: '',
                    to: '',
                    hours: 0,
                    amount: 0,
                    total: 0
                }
            },
            toBookTimeshare: {
                startDate: null,
                endDate: null,
                startMinute: null,
                endMinute: null,
                reserved: false,
                price: 20,
                calculation: {
                    success: null,
                    from: '',
                    to: '',
                    hours: 0,
                    amount: 0,
                    total: 0
                }
            },
// MUST KEEN BELOW STATE STRUCTURE END

            data: {
                accesstype: [""],
                address: "",
                address_tc: "",
                booked: [],
                cartype: [""],
                description: [""],
                features: [""],
                floor: "",
                height: "",
// hourly: {
//     always: true,
//     available: [],
//     avatype: ["MonFri", "SatSunPH"],
//     blocked: [],
//     days: [],
//     deposit: 200,
//     from: "2019-01-01",
//     minbooking: 1,
//     to: "2019-12-30"
// },
                id: "",
                _id: null,
                images: [""],
                instant: true,
                lat: "",
                lng: "",
// monthly: {
//     blocked: [],
//     from: "2019-03-01",
//     minbooking: 1,
//     price: 1500,
//     to: "2019-09-20",
// },
                owner: {
                    name: "",
                    _id: "",
                    created: "",
                },
                restrictions: ["sdf"],
                spaceno: "170",
// timeshare: {
//     available: [],
//     avatype: ["MonFri", "SatSunPH"],
//     blocked: [],
//     from: "2019-04-03",
//     minbooking: 1,
//     price: 1000,
//     to: "2019-05-10",
// },
                title: "",
                title_tc: "",
            },
            holiday: [],

            redirect: false,
            owner: null,
            bcode: false,
            hasOnHoldQuota: false,
        }

        this.handleChangeView = this.handleChangeView.bind(this);
        this.updateState = this.updateState.bind(this);
        this.handleReserved = this.handleReserved.bind(this);
        this.updateCalculation = this.updateCalculation.bind(this);
        this.goToPayment = this.goToPayment.bind(this);
        this.handleCalculation = this.handleCalculation.bind(this);
        this.updateHourlyStates = this.updateHourlyStates.bind(this);
    }

    componentDidMount = () => {
        this.ApiGetHoliday(this.ApiGetSpace);
        this.ApiGetOnHoldQuota();
    }

    ApiGetHoliday = (cb) => {
// setHoliday('The day following the Chinese Mid-Autumn Festival', '2019-09-14', (res) => {
//     console.log(res);
// })
// setHoliday('National Day', '2019-10-01', (res) => {
//     console.log(res);
// })
// setHoliday('Chung Yeung Festival', '2019-10-07', (res) => {
//     console.log(res);
// })
// setHoliday('Christmas Day', '2019-12-25', (res) => {
//     console.log(res);
// })
// setHoliday('The first weekday after Christmas Day', '2019-12-26', (res) => {
//     console.log(res);
// })
        let callback = (res) => {
// console.log(res.body);
            if (typeof (res.body) !== "undefined") {
                let holiday = this.state.holiday;
// console.log(holiday);
                res.body.forEach((e) => {
                    holiday.push(e.date);
                })

                this.setState({
                    ...this.state,
                    holiday: holiday
                }, cb);
            }
        }

        getHoliday(callback);
    }

// General
    handleChangeView = (e, data1, data2) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            [data1]: data2
        })
    }

    handleWishlist = (id) => {
        this.setState({
            wishlist: {
                ...this.state.wishlist,
                [id]: !this.state.wishlist[id]
            }
        });
    }

    handleBookingType = (value) => {
        this.setState({
            ...this.state,
            BookingType: value
        })
    }

    toggleDateChanged=(key) =>
    {
        this.setState({
            ...this.state,
            [key]: !this.state[key]
        })
    }

// Hourly, Monthy, Timeshare General
    updateState = (key1, key2, value,callback=null) => {
        if (key1 === 'toBookTimeshare' && key2 === 'startDate') {
            this.setState({
                ...this.state,
                [key1]: {
                    ...this.state[key1],
                    [key2]: value,
                    'endDate': null
                }
            }, () => {
                this.updateCalculation(key1);
            });
        } else if (key1 === 'toBookMonthly' && key2 === 'startDate') {
            this.setState({
                ...this.state,
                [key1]: {
                    ...this.state[key1],
                    [key2]: value,
                    'endDate': null
                }
            }, () => {
                this.updateCalculation(key1);
            });
        } else {
            this.setState({
                ...this.state,
                [key1]: {
                    ...this.state[key1],
                    [key2]: value
                }
            }, () => {

                if(typeof callback==='function')
                    callback(()=>{
                        this.updateCalculation(key1);
                    });
                else
                    this.updateCalculation(key1);
            });
        }
    }

    updateHourlyStates = (newStates) => {
        let toBookHourly = this.state.toBookHourly;

        for (let key of Object.keys(newStates)) {
            // console.log('foreach', key, newStates[key]);
            toBookHourly[key] = newStates[key];
        }

        // console.log('after', toBookHourly);

        this.setState({
            ...this.state,
            toBookHourly: toBookHourly
        }, () => {
            this.updateCalculation('toBookHourly');
        });
    }

// Hourly, Monthy, Timeshare General
    handleReserved = (key1, v) => {
        this.setState({
            ...this.state,
            [key1]: {
                ...this.state[key1],
                reserved: v
            }
        }, () => {
            this.updateCalculation(key1);
        })
    }

    handleCalculation = (type, calculation) => {
// console.log(type, calculation);

// console.log(calculation.months);
        switch (type) {
            case 'hourly':
                this.setState({
                    ...this.state,
                    toBookHourly: {
                        ...this.state.toBookHourly,
                        calculation: calculation,
                        maxBookingError: calculation.msg && calculation.msg === "EXCEED_MAX_HOUR",
                    }
                })
                break;
            case 'monthly':
                this.setState({
                    ...this.state,
                    toBookMonthly: {
                        ...this.state.toBookMonthly,
                        calculation: calculation
                    },
                    data: {
                        ...this.state.data,
                        monthly: {
                            ...this.state.data.monthly,
                            display_price: calculation.recurring_cost || this.state.data.monthly.display_price,
                        }
                    }
                })
                break;
            default:
                this.setState({
                    ...this.state,
                    toBookTimeshare: {
                        ...this.state.toBookTimeshare,
                        calculation: calculation
                    }
                })
        }
    }

    resetCalculation = (type) => {
        let state = this.state;
        switch (type) {
            case 'hourly':
                state.toBookHourly.calculation.success = null;
                break;
            case 'monthly':
                state.toBookMonthly.calculation.success = null;
                break;
            default:
                state.toBookTimeshare.calculation.success = null;
        }
        this.setState(state);
    }

    isPublicHoliday = (date) => {
        let isHoliday = false;
        this.state.holiday.forEach((d) => {
// console.log(date.utcOffset(8).format("YYYY-MM-DD"), d);
            if (date.utcOffset(8).format("YYYY-MM-DD") === d)
                isHoliday = true;
        })

// if (isHoliday)
//     console.log("Is Holiday: " + date.utcOffset(8).format("YYYY-MM-DD"))
        return isHoliday;
    }

// update calculation
    updateCalculation = (key1) => {
        const access_token = this.props.members.Login.userToken,
            space_id = this.props.space_id;

        if (key1 === 'toBookHourly') {
            // Hourly
            if (this.state.toBookHourly.startDate && this.state.toBookHourly.startTime) {
                let startDate = this.state.toBookHourly.startDate,
                    startTime = this.state.toBookHourly.startTime,
                    days = this.state.data.hourly.days,
                    isPH = this.isPublicHoliday(startDate);

                let availableStartTime = null,
                    day = startDate.day();

                if (day === 0)
                    day = 7;

                // console.log('days', days[0].stime);

                days.forEach((availableDay) => {
                    if ((availableDay.day === 8 && isPH) || availableDay.day === day) {
                        if (!availableStartTime)
                            availableStartTime = JSON.parse(JSON.stringify(availableDay));
                        else {
                            if (parseInt(availableStartTime.stime.substr(0, 2)) > parseInt(availableDay.stime.substr(0, 2)))
                                availableStartTime.stime = availableDay.stime;

                            if (parseInt(availableStartTime.etime.substr(0, 2)) < parseInt(availableDay.etime.substr(0, 2)))
                                availableStartTime.etime = availableDay.etime;
                        }
                    }
                });

// console.log('days -- ', days[0].stime);

                if (availableStartTime && (startTime < availableStartTime.stime || startTime > availableStartTime.etime)) {
// reset startTime to null for unavailable time
                    this.updateState('toBookHourly', 'startTime', null);
                    return;
                }
            }

            if (this.state.toBookHourly.startDate && this.state.toBookHourly.endDate && this.state.toBookHourly.startTime && this.state.toBookHourly.endTime && this.state.toBookHourly.startMinute && this.state.toBookHourly.endMinute) {
// Check if selected time meets min requirement
                const hours = Helper.calculateHours(this.state.toBookHourly.startDate, this.state.toBookHourly.endDate, this.state.toBookHourly.startTime, this.state.toBookHourly.endTime);

// console.log("hours", hours);

// const s = moment(this.state.toBookHourly.startDate.utcOffset(8).format("YYYY-MM-DD") + "T" +  + "+0800"),
//     e = moment(this.state.toBookHourly.endDate.utcOffset(8).format("YYYY-MM-DD") + "T" + this.state.toBookHourly.endTime + "+0800"),
//     duration = moment.duration(e.diff(s));

// console.log(this.state.data.hourly)

// if (duration.hours() < this.state.data.hourly.minbooking) {
                if (hours < this.state.data.hourly.minbooking) {
                    this.setState({
                        ...this.state,
                        toBookHourly: {
                            ...this.state.toBookHourly,
                            minBookingError: true
                        }
                    })
                    return;
                }

// CHeck if overnight
                let startDay = this.state.toBookHourly.startDate.clone().utcOffset(8),
                    endDay = this.state.toBookHourly.endDate.clone().utcOffset(8);

// helper function to get date's available time
                const getTheDay = (date) => {
                    let day = date.day();
                    if (day === 0)
                        day = 7;
                    if (this.isPublicHoliday(date))
                        day = 8;

                    const days = this.state.data.hourly.days;

                    let result = false;


                    days.forEach((availableDay) => {
                        if (availableDay.day === day) {
                            if (!result)
                                result = JSON.parse(JSON.stringify(availableDay));
                            else {
                                if (parseInt(result.stime.substr(0, 2)) > parseInt(availableDay.stime.substr(0, 2)))
                                    result.stime = availableDay.stime;

                                if (parseInt(result.etime.substr(0, 2)) < parseInt(availableDay.etime.substr(0, 2)))
                                    result.etime = availableDay.etime;
                            }
                        }
                    });

// days.forEach((availableDay) => {
//     if (availableDay.day === day)
//         result = availableDay;
// });
                    return result;
                }

                let overnightOk = true;

                while (!startDay.isSame(endDay)) {
// span more than 1 day and need to check overnight ok
                    let result = getTheDay(startDay);
                    if (!result) {
// day not available!
                        overnightOk = false;
                        break;
                    }

                    if (result.etime !== "24:00") {
// not overnight available
                        overnightOk = false;
                        break;
                    }

                    let nextDay = startDay.clone().add(1, "day");
                    result = getTheDay(nextDay);
                    if (!result) {
// day not available!
                        overnightOk = false;
                        break;
                    }

                    if (result.stime !== "00:00") {
// not overnight available
                        overnightOk = false;
                        break;
                    }

// loop next day
                    startDay.add(1, 'day');
                }

                if (!overnightOk) {
                    this.setState({
                        ...this.state,
                        toBookHourly: {
                            ...this.state.toBookHourly,
                            overnightError: true
                        }
                    })
                    return;
                }

                // console.log("FDSFDDF");
// ok and proceed to calculation
                this.setState({
                    ...this.state,
                    toBookHourly: {
                        ...this.state.toBookHourly,
                        minBookingError: false,
                        overnightError: false,
                    }
                }, () => {
                    // console.log('a',this.state.toBookHourly);

                    Helper.CalculateHelper('hourly', this.state.toBookHourly, space_id, this.state.bcode, access_token, this.handleCalculation);
                })
            } else {
                this.resetCalculation('hourly')
            }
        } else if (key1 === 'toBookMonthly') {
// Monthly
            if (this.state.toBookMonthly.startDate && this.state.toBookMonthly.endDate) {
                Helper.CalculateHelper('monthly', this.state.toBookMonthly, space_id, this.state.bcode, access_token, this.handleCalculation);
            } else {
                this.resetCalculation('monthly')
            }
        } else {
// Timeshare
            if (this.state.toBookTimeshare.startDate && this.state.toBookTimeshare.endDate) {
                Helper.CalculateHelper('timeshare', this.state.toBookTimeshare, space_id, this.state.bcode, access_token, this.handleCalculation);
            } else {
                this.resetCalculation('timeshare')
            }
        }
    }

// Hourly, Monthy, Timeshare General
    goToPayment = (e) => {
        e.preventDefault();

        let data = this.state;
        // console.log('data', data);
        // return;
        this.toPayment(data);

        this.setState({
            formSubmit: true
        })
    }

    ApiGetSpace = () => {
        // bCODE Integration
        checkBCodeById(this.props.space_id,
            (response) => {
                let data = response.body.responseDetail;
                this.setState({bcode: data ? data.isBCodeEnabled || false : false})
            },
            (obj) => {
                this.setState({
                    errors: obj.body? obj.body.messages : ''
                });
            }
        );

        getListingById(this.props.space_id,
            (response) => {
                let data = response.body.responseDetail;
                this.setState({
                    ...this.state,
                    toBookHourly: {
                        ...this.state.toBookHourly,
                        preBookBlocker: data ? data.preBookBlocker || 0 : 0,
                        postBookBlocker: data ? data.postBookBlocker || 0 : 0
                    }
                })
            }, (obj) => {
                this.setState({
                    errors: obj.body ? obj.body.messages : ''
                });
            })

        let callback = (obj) => {
            if (obj.status === 200) {
                if ((obj.body.status !== 1 && obj.body.status !== 2) ||
                    (obj.body.status === 2 && obj.body.owner._id !== this.props.members.Login.userId)) {
                    // space is not on
                    this.setState({
                        ...this.state,
                        redirect: true
                    })
                    return;
                }

                // console.log("space", obj.body.booked);

                let booking_type;
                let supportTypes = [];
                let initSupportTypes = '';
                let minPrice = {
                    'type': '',
                    'price': 0,
                };
                let params = this.props.params;

                if (typeof (obj.body.hourly.from) !== "undefined" && obj.body.hourly.from !== "") {
                    supportTypes.push('hourly');
                    if (typeof (booking_type) === "undefined") {
                        booking_type = 'hourly';
                        minPrice.type = 'hourly';
                        minPrice.price = lowestPrice(obj.body.hourly.days);
                        initSupportTypes = 'hourly';
                    }
                }

                if (typeof (obj.body.monthly.from) !== "undefined" && obj.body.monthly.from !== "") {//samson
                    supportTypes.push('monthly');
                    if (typeof (booking_type) === "undefined") {
                        booking_type = 'monthly';
                        minPrice.type = 'monthly';
                        minPrice.price = obj.body.monthly.price;
                        initSupportTypes = 'monthly';
                    }
                }

                if (typeof (obj.body.timeshare.from) !== "undefined" && obj.body.timeshare.from !== "") {
                    supportTypes.push('timeshare');
                    if (typeof (booking_type) === "undefined") {
                        booking_type = 'timeshare';
                        minPrice.type = 'timeshare';
                        minPrice.price = obj.body.timeshare.price;
                        initSupportTypes = 'timeshare';
                    }
                }

// console.log(supportTypes);
                let variables = {
                    ...this.state,
                    data: obj.body,
                }, key_updateCalculation = null;

//2019-07-17 samson - logic for back button in payment page
                if (params !== null && typeof (params.booking_type) !== "undefined") {
                    let params_from = (typeof (params.from) !== "undefined" ? params.from : null),
                        params_to = (typeof (params.to) !== "undefined" ? params.to : null),
                        params_from_time = (typeof (params.from_time) !== "undefined" ? params.from_time.slice(0,-2) + '00' : null), //2019-08-23 Samson - get hour in from_time
                        params_to_time = (typeof (params.to_time) !== "undefined" ? params.to_time.slice(0,-2) + '00' : null), //2019-08-23 Samson - get hour in to_time
                        params_from_minute = (typeof (params.from_time) !== "undefined" ? params.from_time.slice(-2) : null), //2019-08-23 Samson - get minute in from_time
                        params_to_minute = (typeof (params.to_time) !== "undefined" ? params.to_time.slice(-2) : null); //2019-08-23 Samson - get minute in to_time

                    if (supportTypes.includes("hourly") && params.booking_type === "hourly") {
                        booking_type = 'hourly';
                        minPrice.type = 'hourly';
                        minPrice.price = lowestPrice(obj.body.hourly.days);

                        if (params_from)
                            variables['toBookHourly']['startDate'] = moment(params_from + "T00:00:00+0800");
                        if (params_to)
                            variables['toBookHourly']['endDate'] = moment(params_to + "T00:00:00+0800");
                        if (params_from_time)
                            variables['toBookHourly']['startTime'] = params_from_time;
                        if (params_to_time)
                            variables['toBookHourly']['endTime'] = params_to_time;
                        if (params_from_minute)
                            variables['toBookHourly']['startMinute'] = params_from_minute; //2019-08-23 Samson
                        if (params_to_minute)
                            variables['toBookHourly']['endMinute'] = params_to_minute; //2019-08-23 Samson

                        key_updateCalculation = 'toBookHourly';

                    } else if (supportTypes.includes("monthly") && params.booking_type === "monthly") {
                        booking_type = 'monthly';
                        minPrice.type = 'monthly';
                        minPrice.price = obj.body.monthly.price;

                        if (params_from)
                            variables['toBookMonthly']['startDate'] = moment(params_from + "T00:00:00+0800");
                        if (params_to)
                            variables['toBookMonthly']['endDate'] = moment(params_to + "T00:00:00+0800");

                        key_updateCalculation = 'toBookMonthly';

                    } else if (supportTypes.includes("timeshare") && params.booking_type === "timeshare") {
                        booking_type = 'timeshare';
                        minPrice.type = 'timeshare';
                        minPrice.price = obj.body.timeshare.price;

                        if (params_from)
                            variables['toBookTimeshare']['startDate'] = moment(params_from + "T00:00:00+0800");
                        if (params_to)
                            variables['toBookTimeshare']['endDate'] = moment(params_to + "T00:00:00+0800");

                        key_updateCalculation = 'toBookTimeshare';
                    }
                }
                variables['BookingType'] = booking_type;
                variables['minPrice'] = minPrice;
                variables['supportTypes'] = supportTypes;
                variables['initSupportTypes'] = initSupportTypes;

                this.setState(variables, () => {
                    if (key_updateCalculation !== null)
                        this.updateCalculation(key_updateCalculation);

                    let callback = (obj) => {
                        if (obj.status === 200) {
                            this.setState({
                                ...this.state,
                                owner: obj.body,
                            })
                        }
                    }
                    getUserInfo(this.state.data.owner._id, callback);
                });
            } else if (obj.status === 400) {
                this.setState({
                    errors: obj.body.messages
                });
            }

            let booking_type = [];

            if (typeof (obj.body.hourly.from) !== "undefined")
                booking_type.push("hourly");

            if (typeof (obj.body.monthly.from) !== "undefined")
                booking_type.push("monthly");

            if (typeof (obj.body.timeshare.from) !== "undefined")
                booking_type.push("timeshare");

            booking_type = booking_type.toString();

            ReactPixel.track('AddToCart', {
                value: '0.00',
                currency: 'HKD',
                content_type: 'product',
                booking_type: booking_type,
                content_id: this.state.data._id
            });
        }

        getSpaceDetail(this.props.space_id, callback);
    }

    isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    ApiGetOnHoldQuota = () => {
        if (this.props.members.Login.userId) {
            checkHasOnHoldQuota(this.props.members.Login.userId,
              (callback) => {
                  this.setState({hasOnHoldQuota: callback.body.hasOnHoldQuota})
              }, (error) => {
                  this.setState({hasOnHoldQuota: false})
              })
        }
    }

    ApiInsertTempBooking = (e) => {
        if (!this.state.data.instant) {this.goToPayment(e); return;}
        if (this.state.BookingType === 'hourly' && (!this.state.toBookHourly.startDate || !this.state.toBookHourly.startTime || !this.state.toBookHourly.startMinute)) return
        if (this.state.BookingType === 'monthly' && (!this.state.toBookMonthly.startDate || !this.state.toBookMonthly.endDate)) return
        if (this.state.BookingType === 'timeshare' && (!this.state.toBookTimeshare.startDate || !this.state.toBookTimeshare.endDate)) return

        this.ApiGetOnHoldQuota()
        const {t} = this.props;

        if (this.state.hasOnHoldQuota) {
            swal({
                text: t("space:tempBooked"),
                buttons: {
                    cancel: {
                        text: t("space:back"),
                        value: null,
                        visible: true
                    },
                    confirm: {
                        text: t("space:confirm"),
                        value: true,
                        visible: true
                    }
                },
            })
              .then((isConfirmed) => {
                  if (isConfirmed) {
                      this.handleHoldQuota(e);
                  }
              });
        } else {
            this.handleHoldQuota(e);
        }

    }

    handleHoldQuota = (e) => {
        let from, to
        if (this.state.BookingType === 'hourly') {
            from = `${moment(this.state.toBookHourly.startDate).format('YYYY-MM-DD')}T${this.state.toBookHourly.startTime.slice(0,-2)}${this.state.toBookHourly.startMinute}`
            to = `${moment(this.state.toBookHourly.endDate).format('YYYY-MM-DD')}T${this.state.toBookHourly.endTime.slice(0,-2)}${this.state.toBookHourly.endMinute}`

            from = moment(from).subtract(this.state.toBookHourly.preBookBlocker, 'minutes').format("YYYY-MM-DDTHH:mm")
            to = moment(to).add(this.state.toBookHourly.postBookBlocker, 'minutes').format("YYYY-MM-DDTHH:mm")
        } else if (this.state.BookingType === 'monthly') {
            from = `${moment(this.state.toBookMonthly.startDate).format('YYYY-MM-DD')}T00:00`
            to = `${moment(this.state.toBookMonthly.endDate).format('YYYY-MM-DD')}T23:59`
        } else if (this.state.BookingType === 'timeshare') {
            from = `${moment(this.state.toBookTimeshare.startDate).format('YYYY-MM-DD')}T00:00`
            to = `${moment(this.state.toBookTimeshare.endDate).format('YYYY-MM-DD')}T23:59`
        }

        const holdTime = 15
        let data = {
            userID: this.props.members.Login.userId,
            from,
            to,
            until: moment().add(holdTime, 'minutes').format('YYYY-MM-DDTHH:mm:ss')
        }
        // console.log(data)
        insertTempBooking(this.props.members.Login.userToken, this.state.data._id, data,
          (callback) => {
              console.log(callback)
          }, (error) => {
              console.log(error)
          })

        this.goToPayment(e)
    }

    render() {

// console.log("state", this.state);

        const {
            members,
            t,
            i18n
        } = this.props;

        if (this.state.redirect) {
            return <Redirect to={"/" + i18n.language + "/"}/>;
        }

        if (this.state.formSubmit) {

            let spaceID = this.props.space_id;
            let queryString;

            switch (this.state.BookingType) {
                case 'hourly':
                    queryString =
                        'from=' + this.state.toBookHourly.startDate.utcOffset(8).format("YYYY-MM-DD")
                        + '&from_time=' + this.state.toBookHourly.startTime.slice(0,-2) + this.state.toBookHourly.startMinute //2019-08-23 Samson
                        + '&to=' + this.state.toBookHourly.endDate.utcOffset(8).format("YYYY-MM-DD")
                        + '&to_time=' + this.state.toBookHourly.endTime.slice(0,-2) + this.state.toBookHourly.endMinute //2019-08-23 Samson
                        + '&booking_type=' + this.state.BookingType
                        + '&instant=' + this.state.data.instant;
                    break;
                case 'monthly':
                    queryString =
                        'from=' + this.state.toBookMonthly.startDate.utcOffset(8).format("YYYY-MM-DD")
                        + '&to=' + this.state.toBookMonthly.endDate.utcOffset(8).format("YYYY-MM-DD")
                        + '&booking_type=' + this.state.BookingType
                        + '&instant=' + this.state.data.instant;
                    break;
                case 'timeshare':
                    queryString =
                        'from=' + this.state.toBookTimeshare.startDate.utcOffset(8).format("YYYY-MM-DD")
                        + '&to=' + this.state.toBookTimeshare.endDate.utcOffset(8).format("YYYY-MM-DD")
                        + '&booking_type=' + this.state.BookingType
                        + '&instant=' + this.state.data.instant;
                    break;
                default:
                    queryString =
                        'from=' + this.state.toBookHourly.startDate.utcOffset(8).format("YYYY-MM-DD")
                        + '&to=' + this.state.toBookHourly.endDate.utcOffset(8).format("YYYY-MM-DD")
                        + '&booking_type=' + this.state.BookingType
                        + '&instant=' + this.state.data.instant;
            }

            return <Redirect push to={"/" + i18n.language + "/payment/" + spaceID + "?" + queryString}/>;
        }

        const booking_types = {
            'hourly': 'Hourly',
            'monthly': 'Monthly',
            'timeshare': 'Timeshare',
        }

        let ownSpace = false;
        if (this.state.data._id && members.Login.length !== 0) {
// console.log(members.Login.user, this.state.data.owner);
            if (members.Login.user._id === this.state.data.owner._id)
                ownSpace = true;
        }

        return (
            <div className="wrapper-container detailShare">

                <Helmet>
                    <meta property="og:title" content={renderListingTitle(this.state.data, i18n.language)}/>
                    <meta property="og:description"
                          content="Let’s Park Test"/>
                    <meta property="og:url" content={"/" + i18n.language + "/space/" + this.state.data._id}/>
                    <meta property="og:image" content={this.state.data.images[0]}/>
                </Helmet>

                <div className="containerMain">
                    {this.state.data._id &&
                    <div className="content clearfix">
                        <div className="information left">
                            <div className="text">
                                <h3>{renderListingTitle(this.state.data, i18n.language)}</h3>
                                <h4>{i18n.language === 'zh-HK' && this.state.data.address_tc ? this.state.data.address_tc : this.state.data.address}</h4>

                                <div className="sep-0"/>
                                <Reviews reviews={Object.keys(this.state.data.reviews).length}
                                         score={this.state.data.rating}/>

                                <div className="sep-15"/>

                                <Tags
                                    tags={this.state.data.description}
                                    nearBlockText={this.state.data.nearblock}
                                />

                                <div className="sep-20"/>
                            </div>
                            <ViewChangeWithSlider
                                id={this.state.data._id}
                                images={this.state.data ? this.state.data.images : []}
                                view={this.state.view}
                                handleChangeView={this.handleChangeView}
                                wishlist={this.state.wishlist}
                                handleWishlist={this.handleWishlist}
                                lng={this.state.data.lng}
                                lat={this.state.data.lat}
                            />
                            <div className="sep-40"/>

                            <div className="landlord clearfix">

                                {this.state.owner &&
                                <div className="fifty clearfix">

                                    <div className="image">
                                        <a href={"/" + i18n.language + "/landlordProfile/" + this.state.owner._id}>
                                            <img
                                                src={this.state.owner.image ? this.state.owner.image : require('../../images/blank.png')}
                                                alt=""/>
                                        </a>
                                    </div>
                                    <div className="text">
                                        <h3>{this.state.owner.isCorp ? this.state.owner.companyname : this.state.owner.first_name}</h3>
                                        <div className="memberSince">
                                            {t("space:memberSince")}<span>{mongoIdTimestamp(this.state.data.owner._id, "YYYY-MM-DD")}</span>{t("space:memberAt")}
                                        </div>
                                        <div className="sep-10"/>
                                        {!ownSpace && !this.state.owner.isCorp &&
                                        <a href={"/" + i18n.language + "/contact-owner/" + this.props.space_id}
                                           className="button">{t("space:contactOwner")}</a>
                                        }
                                    </div>
                                </div>
                                }

                                <div className="fifty clearfix"/>
                            </div>
                        </div>

                        {this.state.BookingType === 'hourly' &&
                        typeof (this.state.data.hourly) !== "undefined" &&
                        typeof (this.state.data.hourly.from) !== "undefined" &&
                        this.state.data.hourly.from !== "" &&
                        <DetailHourly
                            hourlyData={this.state.data.hourly}
                            BookingType={this.state.BookingType}
                            handleBookingType={this.handleBookingType}
                            supportTypes={this.state.supportTypes}
                            minbooking={this.state.data.hourly.minbooking}

                            startDate={this.state.toBookHourly.startDate}
                            endDate={this.state.toBookHourly.endDate}
                            startTime={this.state.toBookHourly.startTime}
                            endTime={this.state.toBookHourly.endTime}
                            startMinute={this.state.toBookHourly.startMinute}
                            endMinute={this.state.toBookHourly.endMinute}
                            updateState={this.updateState}
                            updateHourlyStates={this.updateHourlyStates}

                            calculation={this.state.toBookHourly.calculation}
                            minBookingError={this.state.toBookHourly.minBookingError}
                            maxBookingError={this.state.toBookHourly.maxBookingError}
                            overnightError={this.state.toBookHourly.overnightError}
                            goToPayment={this.goToPayment}

                            booking_types={booking_types}
                            booked={this.state.data.booked}
                            quota={this.state.data.quantity}

                            holiday={this.state.holiday}
                            isPublicHoliday={this.isPublicHoliday}

                            ownSpace={ownSpace}

                            instant={this.state.data.instant}
                            bcode={this.state.bcode}
                            space={this.state.data}

                            startDateChanged={this.state.startDateChanged}
                            toggleDateChanged={this.toggleDateChanged}

                            insertTempBooking={this.ApiInsertTempBooking}
                            hasOnHoldQuota={this.state.hasOnHoldQuota}

                            preBookBlocker={this.state.toBookHourly.preBookBlocker}
                            postBookBlocker={this.state.toBookHourly.postBookBlocker}
                        />
                        }

                        {this.state.BookingType === 'monthly' &&
                        typeof (this.state.data.monthly) !== "undefined" &&
                        typeof (this.state.data.monthly.from) !== "undefined" &&
                        this.state.data.monthly.from !== "" &&
                        <DetailMonthly
                            monthlyData={this.state.data.monthly}
                            BookingType={this.state.BookingType}
                            handleBookingType={this.handleBookingType}
                            supportTypes={this.state.supportTypes}

                            typeDetail={this.state.data.monthly}
                            startDate={this.state.toBookMonthly.startDate}
                            endDate={this.state.toBookMonthly.endDate}
                            updateState={this.updateState}

                            reserved={this.state.toBookMonthly.reserved}
                            handleReserved={this.handleReserved}

                            calculation={this.state.toBookMonthly.calculation}
                            goToPayment={this.goToPayment}

                            booking_types={booking_types}
                            booked={this.state.data.booked}
                            quota={this.state.data.quantity}

                            holiday={this.state.holiday}
                            isPublicHoliday={this.isPublicHoliday}

                            ownSpace={ownSpace}

                            instant={this.state.data.instant}
                            bcode={this.state.bcode}
                            space={this.state.data}

                            insertTempBooking={this.ApiInsertTempBooking}
                            hasOnHoldQuota={this.state.hasOnHoldQuota}
                        />
                        }

                        {this.state.BookingType === 'timeshare' &&
                        typeof (this.state.data.timeshare) !== "undefined" &&
                        typeof (this.state.data.timeshare.from) !== "undefined" &&
                        this.state.data.timeshare.from !== "" &&
                        <DetailTimeshare
                            timeshareData={this.state.data.timeshare}
                            BookingType={this.state.BookingType}
                            handleBookingType={this.handleBookingType}
                            supportTypes={this.state.supportTypes}

                            typeDetail={this.state.data.timeshare}
                            startDate={this.state.toBookTimeshare.startDate}
                            endDate={this.state.toBookTimeshare.endDate}
                            updateState={this.updateState}

                            reserved={this.state.toBookTimeshare.reserved}
                            handleReserved={this.handleReserved}

                            calculation={this.state.toBookTimeshare.calculation}
                            goToPayment={this.goToPayment}

                            booking_types={booking_types}
                            booked={this.state.data.booked}
                            quota={this.state.data.quantity}

                            holiday={this.state.holiday}
                            isPublicHoliday={this.isPublicHoliday}
                            ownSpace={ownSpace}

                            instant={this.state.data.instant}
                            bcode={this.state.bcode}
                            space={this.state.data}

                            insertTempBooking={this.ApiInsertTempBooking}
                            hasOnHoldQuota={this.state.hasOnHoldQuota}
                        />
                        }

                        <div className="sep-30"/>

                        <div className="wrapper-tabs">

                            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                                <Tab eventKey={1} title={t("space:tabDetails.title")}>
                                    <div className="inner">
                                        <h5>{t("space:tabDetails.suitable.title")}</h5>

                                        <SpaceSuitableFor
                                            cartype={this.state.data.cartype}
                                            tones={this.state.data.tones}
                                        />

                                        <div className="sep-25"/>

                                        <h5>{t("space:tabDetails.feature.title")}</h5>

                                        <Features
                                            features={this.state.data.features}
                                            height={this.state.data.height}
                                        />

                                        <div className="sep-25"/>
                                        <h5>{t("space:tabDetails.restrictions.title")}</h5>
                                        <Restrictions
                                            restrictions={this.state.data.restrictions}
                                        />

                                        <div className="sep-25"/>
                                        {
                                            this.state.bcode?
                                                <h5>{t("space:tabDetails.accessType.bCodeTitle")}</h5>
                                                :
                                                <h5>{t("space:tabDetails.accessType.title")}</h5>
                                        }
                                        {
                                            this.state.bcode?
                                                <p>
                                                    <img src={require('../../images/mobile/03_find_parking_space/smart_pass_icon.png')}
                                                         style={{height: 24, width: 24}}
                                                         alt=""/>
                                                    &nbsp;&nbsp;{t("payment:useBCode")}
                                                </p>
                                                :
                                                <AccessType
                                                    accesstype={this.state.data.accesstype[0]}
                                                />
                                        }

                                        <div className="sep-25"/>
                                        <h5>{t("space:tabDetails.instantbooking.title")}</h5>
                                        <InstantBooking
                                            instant={this.state.data.instant}
                                        />
                                    </div>
                                </Tab>

                                <Tab eventKey={2}
                                     title={t("space:tabReviews.title") + "(" + Object.keys(this.state.data.reviews).length + ")"}>
                                    <div className="inner">
                                        {this.isEmpty(this.state.data.reviews) &&
                                        <div>{t("space:tabReviews.noReviews")}</div>
                                        }
                                        {!this.isEmpty(this.state.data.reviews) &&
                                        <div className="reviewsBlock">
                                            {Object.keys(this.state.data.reviews).map(key => {

                                                let review = this.state.data.reviews[key];

                                                return (
                                                    <ReviewBlock
                                                        key={key}
                                                        review={review}
                                                    />
                                                )
                                            })}
                                        </div>
                                        }
                                    </div>
                                </Tab>

                                <Tab eventKey={3}
                                     title={t("space:tabBookingType.title")}>
                                    <div className="inner">
                                        <Accordion>
                                            {Object.keys(this.state.supportTypes).map(key => {

                                                let bookingType = this.state.supportTypes[key];
                                                let expanded = this.state.initSupportTypes === bookingType;

                                                return (
                                                    <BookingTypeBlock
                                                        key={key}
                                                        bookingType={bookingType}
                                                        data={this.state.data[bookingType]}
                                                        t={t}
                                                        expanded={expanded}
                                                        isBcode={this.state.bcode}
                                                    />
                                                )
                                            })}
                                        </Accordion>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {
        members: state.members,
        findParkingSpace: state.findParkingSpace
    }
);

export default withTranslation()(connect(mapStateToProps)(SpaceOne));
