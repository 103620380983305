import React, {Children} from 'react';
import moment from 'moment';
import BigCalendar from 'react-big-calendar';

import 'moment/locale/zh-hk';

const CustomBigCalendar = props => {

    // console.log(props);


    moment.locale('en');

    if (props.i18n.language === 'zh-HK') {
        moment.locale('zh-hk');
    }

    const localizer = BigCalendar.momentLocalizer(moment);

    const CustomToolBar = props => {
        const navigate = action => {
            props.onNavigate(action)
        }

        return (
            <div className="customToolBar">
                <button type="button" onClick={() => navigate('PREV')} className="btn-back">back</button>
                <span className="rbc-toolbar-label">{props.label}</span>
                <button type="button" onClick={() => navigate('NEXT')} className="btn-next">next</button>
            </div>
        )
    }

    const CustomEvent = props => {

        let title = props.event.title;
        if (title === "Available all day") {
            return (
                <div>
                    {props.t("listASpace:stepTwo.canlendar.available")}
                    {/*Available*/}
                    <br/>
                    {props.t("listASpace:stepTwo.canlendar.allDay")}
                    {/*all day*/}
                </div>
            )
        }
        return (
            <div>
                {title}
            </div>
        );
    }

    const BigCalendarCustomEditor = props => {
        let momentDate = moment(props.date.start);

        return (
            <div className="big_calendar_custom_editor">
                <div>
                    <span className="btn-close" onClick={props.HourlyCalendarHandleCloseEditor}></span>
                    <div className="bc_date">{momentDate.format("DD MMM YYYY")}</div>
                    <div className="bc_content">
                        <div className="bc_title">
                            {props.t("listASpace:stepTwo.canlendar.available")}{props.t("listASpace:stepTwo.canlendar.allDay")}

                            <div className="sep-40"/>

                            <div className="wrapper align-center">

                                <div className="wrap align-center clearfix">

                                    <div className="full">
                                        <div>
                                            <div className="button grey"
                                                 onClick={() => props.HourlyCalendarHandleSetCustomAvailableDates(props.date.start, false)}
                                            >
                                                {props.t("listASpace:stepTwo.canlendar.unavailable")}
                                            </div>
                                        </div>
                                    </div>

                                    {/*<div className="fifty">*/}
                                    {/*<div>*/}
                                    {/*<div className="button grey"*/}
                                    {/*onClick={()=>this.props.HourlyCalendarHandleSetCustomAvailableDates(this.props.date.start, false)}*/}
                                    {/*>Unavailable</div>*/}
                                    {/*</div>*/}
                                    {/*</div>*/}
                                    {/*<div className="fifty">*/}
                                    {/*<div>*/}
                                    {/*<div className="button"*/}
                                    {/*onClick={()=>this.props.HourlyCalendarHandleSetCustomAvailableDates(this.props.date.start, 'b')}*/}
                                    {/*>Custom Range</div>*/}
                                    {/*</div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const ColoredDateCellWrapper = ({children, value}) => {
        const onDates = props.tabData.CalendarData.OnDates;
        let color = onDates.indexOf(moment(value).format("YYYY-MM-DD")) > -1 ? '#fff' : '';

        return React.cloneElement(Children.only(children), {
            style: {
                ...children.style,
                backgroundColor: color,
            },
        });
    }

    const isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    const CustomDateHeader = ({date, label}) => {

        let highlightDate = false;

        if (!isEmpty(props.tabData.CalendarData.AvailableDates)) {
            let last = (props.tabData.CalendarData.AvailableDates).length - 1;

            let events = [
                {
                    startDate: moment(props.tabData.CalendarData.AvailableDates[0].start._d).format("YYYY-MM-DD"),
                    endDate: moment(props.tabData.CalendarData.AvailableDates[last].end._d).format("YYYY-MM-DD"),
                }
            ];

            highlightDate =
                events.find(event =>
                    moment(date).isBetween(
                        moment(event.startDate),
                        moment(event.endDate),
                        null,
                        "[]"
                    )
                ) !== undefined;
        }

        if (moment().format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")) {

            return (
                <span className={highlightDate ? "event" : ""}>
                    {label + " "}
                    <span className="today">
                        {props.t("listASpace:stepTwo.canlendar.today")}
                    </span>
                </span>
            )
        } else {

            return (
                <span className={highlightDate ? "event" : ""}>{label}</span>
            )
        }
    }

    function withTranslation(WrapperComponent, translation, i18n) {
        return class extends React.Component {
            render() {
                return <WrapperComponent {...this.props} t={translation} i18n={i18n}/>
            }
        }
    }

    return (
        <div className="wrapper-calendar">
            <BigCalendar
                localizer={localizer}
                events={props.tabData.CalendarData.AvailableDates}
                startAccessor="start"
                endAccessor="end"
                views={{month: true}}
                components={{
                    // you have to pass your custom wrapper here
                    // so that it actually gets used
                    toolbar: CustomToolBar,
                    dateCellWrapper: ColoredDateCellWrapper,
                    event: withTranslation(CustomEvent, props.t, props.i18n),
                    month: {
                        dateHeader: CustomDateHeader,
                    },
                }}
                onSelectEvent={props.onSelectEvent}
            />

            {props.tabData.CalendarData.Editing !== null &&
            <BigCalendarCustomEditor
                t={props.t}
                date={props.tabData.CalendarData.Editing}
                HourlyCalendarHandleCloseEditor={props.HourlyCalendarHandleCloseEditor}
                HourlyCalendarHandleSetCustomAvailableDates={props.HourlyCalendarHandleSetCustomAvailableDates}
            />
            }
        </div>
    )
}

export default CustomBigCalendar;


