import React, {forwardRef} from 'react';
// import { Link } from 'react-router-dom';
// import { withTranslation } from 'react-i18next';
import {mongoIdTimestamp} from "../../utils/00General/Helpers";

const Message = forwardRef((props, ref) => {
    const {
        myId,
        message
    } = props;

    return (
        <div className={"wrapper-message " + (message.senderID === myId ? "mine" : "him")}>
            <div className="message">
                <span className="dateTime">{mongoIdTimestamp(message._id, "LLL")}</span>
                <div className="sep-0" />
                <div className="content preline">{message.message}</div>
            </div>
        </div>
    )
})

export default Message;