import React, {Component} from 'react';

// import SMSButton from '../../../components/02Members/SMSButton';

// import {bindActionCreators} from 'redux';
// import {connect} from 'react-redux';
// import * as MembersActionCreators from "../../../actions/members";
import {withTranslation} from "react-i18next";

import {emailValidate} from "../../../utils/04Api/User";

class MemberEmailVerification extends Component {
    //
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        }
    }

    componentDidMount = () => {
        this.EmailValidation();
    }

    EmailValidation = () => {

        let params = this.getUrlVars();

        let success = (res) => {
            console.log(res.body);
            if (typeof (res.body) !== "undefined" && res.body.status === 1) {
                this.setState({
                    ...this.state,
                    loading: false,
                    success: true,
                    isCorp: res.body.isCorp
                })
            } else {
                failed();
            }
        }

        let failed = () => {
            this.setState({
                ...this.state,
                loading: false,
                success: false,
            })
        }

        emailValidate(params.uid, params.token, success, failed);
    }

    getUrlVars = () => {

        let vars = {};

        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {

            vars[key] = value;
        });

        return vars;
    }

    render() {

        const {
            t,
            i18n,
        } = this.props;

        if (this.state.loading) {
            return <div className="loading_box">{t("Loading")}</div>
        }

        // TODO: translation
        if (!this.state.success) {
            return (
                <div className="wrapper-container memberEmailVerification">
                    <div className="containerMain borderTop">
                        <div className="content clearfix">
                            <div className="full left align-center">
                                <h2>{t("registerStart:emailVerification.fail.sorry")}</h2>
                                <p>{t("registerStart:emailVerification.fail.invalid")}</p>
                                <a href={"/" + i18n.language}>{t("registerStart:emailVerification.toHomepage")}</a>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="wrapper-container memberEmailVerification">
                <div className="containerMain borderTop">
                    <div className="content clearfix">
                        <div className="full left align-center">
                            <h2>{t("registerStart:emailVerification.success.congratulations")}</h2>
                            <p>{t("registerStart:emailVerification.success.succeeded")}</p>
                            {this.state.isCorp &&
                            <p>{t("registerStart:emailVerification.success.corporate")}</p>
                            }
                            <a href={"/" + i18n.language}>{t("registerStart:emailVerification.toHomepage")}</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(MemberEmailVerification);
