import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

// import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
// import {unreadMessages} from "../../utils/04Api/User";

// import * as AnimationsActionCreators from '../../actions/animations';

import { joinAlertRoom, leaveAlertRoom } from '../../socket/connect';

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            totalUnreadMsg: 0,
            alertUid: null
        }
    }
    //
    // updateUnreadMessage = () => {
    //     if (typeof(this.props.members.Login.userToken) !== "undefined") {
    //         unreadMessages(this.props.members.Login.userToken, (obj) => {
    //             // console.log('header - notification', obj.body);
    //             if (typeof (obj.body.total) !== "undefined" && parseInt(obj.body.total) >= 0 && obj.body.total !== this.state.totalUnreadMsg) {
    //                 this.setState({
    //                     ...this.state,
    //                     totalUnreadMsg: obj.body.total
    //                 })
    //             }
    //         }, (err) => {
    //             // console.log('header - notification Err', err);
    //         })
    //     }
    // }

    handleAlert() {
        if (this.props.members.Login.userToken === undefined) {
            // console.log("Not login");
            this.stopAlert();
        } else {
            // console.log("Logined", this.props.members.Login.userId);
            //
            this.startAlert(this.props.members.Login.userId);
        }
    }

    stopAlert() {
        if (this.state.alertUid) {
            leaveAlertRoom(this.state.alertUid, (uid)=> {
                // console.log("exit uid alert", uid);

                this.setState({
                    ...this.state,
                    alertUid: null,
                    totalUnreadMsg: 0
                })
            })
        }
    }

    startAlert(userId) {
        joinAlertRoom(userId, ()=>{
            this.setState({
                ...this.state,
                alertUid: userId
            })
        }, (res) => {
            let total = 0;
            if (parseInt(res) > 0)
                total = parseInt(res);

            this.setState({
                ...this.state,
                totalUnreadMsg: total
            })
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.members.Login.userToken !== this.props.members.Login.userToken) {
            // console.log("usertoken changed", prevProps.members.Login.userToken, this.props.members.Login.userToken);
            this.handleAlert();
        }
    }

    componentDidMount() {
        // this.updateUnreadMessage();
        //
        // this.interval = setInterval(()=> {
        //     this.updateUnreadMessage();
        // }, 15000);

        // console.log(this.props.members.Login);
        this.handleAlert();
    }

    componentWillUnmount() {
        this.stopAlert();
        // if (typeof(this.interval) !== "undefined")
        //     clearInterval(this.interval);
    }

    changeLanguage = (param) => {
        switch (param) {
            case 'zh-HK':
                param = 'zh-HK';
                break;
            case 'en-US':
                param = 'en-US';
                break;
            default:
                param = 'en-US';
        }
        this.props.i18n.changeLanguage(param);
    }

    render() {
        const {t, i18n} = this.props;

        let logined = false;
        if (typeof (this.props.members.Login) !== "undefined" && this.props.members.Login !== null && this.props.members.Login.length !== 0)
            logined = true;

        return (
            <div className="wrapper-header">
                <div className="header">
                    <div className="rest">
                        <div>
                            <span className="mobile-menu-btn"/>

                            <Link to={"/" + i18n.language} className="logo">
                                <img src={require('../../images/mobile/General/menu-and-footer/logo_letspark.png')}
                                     alt=""/>
                            </Link>
                        </div>
                        <div>
                            <ul className="header-options clearfix">
                                {logined &&
                                <li><Link
                                    to={"/" + i18n.language + "/memberProfile"}>{t("General.MyAccount")}</Link>
                                </li>
                                }
                                {logined &&
                                <li><Link
                                    to={"/" + i18n.language + "/logout"}>{t("General.Logout")}</Link>
                                </li>
                                }
                                {!logined &&
                                <li><Link
                                    to={"/" + i18n.language + "/memberGetStarted"}>{t("General.Login")}</Link>
                                </li>
                                }
                                {!logined &&
                                <li><Link
                                    to={"/" + i18n.language + "/register"}>{t("General.Register")}</Link>
                                </li>
                                }
                                {i18n.language === 'zh-HK' ?
                                    <li><Link to={"/en-US" + this.props.router.location.pathname.substring(6) + this.props.router.location.search}
                                              onClick={() => this.changeLanguage('en-US')}>English</Link></li>
                                    : <li><Link to={"/zh-HK" + this.props.router.location.pathname.substring(6) + this.props.router.location.search}
                                                onClick={() => this.changeLanguage('zh-HK')}>繁</Link></li>
                                }

                            </ul>

                            <Link
                                to={logined ? "/" + i18n.language + "/inbox" : "/" + i18n.language + "/login"}
                                className="bubble">
                                <span className="number">{this.state.totalUnreadMsg}</span>
                            </Link>

                            <ul className="free">
                                <li>
                                    <Link
                                        to={logined ? "/" + i18n.language + "/stepOne" : "/" + i18n.language + "/stepOne"}><img
                                        src={require("../../images/mobile/01_homepage/icon_btn_list" + (i18n.language === 'zh-HK' ? "_tc" : "") + ".png")}
                                        alt=""/></Link>
                                </li>
                            </ul>

                            <span className="desktop-menu-btn"/>
                        </div>
                    </div>

                    <div className="sep-0"/>

                    <div className="wrapper-move">
                        <div className="move clearfix">
                            <div className="forty left">
                                <div className="clearfix">
                                    <ul>
                                        <li><h3>{t("General.ForParkers")}</h3></li>
                                        <li><Link
                                            to={"/" + i18n.language + "/helpCenter?session=parkers"}>{t("Menu.HowItWork")}</Link>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li><h3>{t("General.ForOwners")}</h3></li>
                                        <li><Link
                                            to={"/" + i18n.language + "/helpCenter?session=owners"}>{t("Menu.HowItWork")}</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="sixty left">
                                <div className="clearfix">
                                    <ul>
                                        <li className="spacer"><h3>&nbsp;</h3></li>
                                        {logined &&
                                        <li><Link
                                            to={"/" + i18n.language + "/memberProfile"}>{t("General.MyAccount")}</Link>
                                        </li>
                                        }
                                        {logined &&
                                        <li><Link
                                            to={"/" + i18n.language + "/logout"}>{t("General.Logout")}</Link>
                                        </li>
                                        }
                                        {!logined &&
                                        <li><Link
                                            to={"/" + i18n.language + "/memberGetStarted"}>{t("General.Login")}</Link>
                                        </li>
                                        }
                                        {!logined &&
                                        <li><Link
                                            to={"/" + i18n.language + "/register"}>{t("General.Register")}</Link>
                                        </li>
                                        }
                                    </ul>
                                    <ul>
                                        <li className="spacer"><h3>&nbsp;</h3></li>
                                        {/*<li><a href="https://enterprise.letspark.com.hk/" target="_blank"*/}
                                        {/*       rel="noopener noreferrer">{t("Menu.LetsParkEnterprise")}</a>*/}
                                        {/*</li>*/}
                                    </ul>
                                    <ul>
                                        <li><h3>&nbsp;</h3></li>
                                        <li>
                                            <Link to="/" className="align-center"><img
                                                src={require("../../images/mobile/01_homepage/icon_btn_list" + (i18n.language === 'zh-HK' ? "_tc" : "") + ".png")}
                                                alt="" className="free3"/></Link>
                                        </li>
                                        {i18n.language === 'zh-HK' ?
                                            <li><Link to={"/en-US" + this.props.router.location.pathname.substring(6)}
                                                      onClick={() => this.changeLanguage('en-US')}
                                                      className="align-center">English</Link></li>
                                            : <li><Link to={"/zh-HK" + this.props.router.location.pathname.substring(6)}
                                                        onClick={() => this.changeLanguage('zh-HK')}
                                                        className="align-center">繁</Link></li>
                                        }
                                    </ul>
                                    {/*<ul>*/}
                                    {/*    <li className="align-center"><h3>{t("Menu.DownloadApp")}</h3></li>*/}
                                    {/*    <li>*/}
                                    {/*        <Link to="/" className="align-center"><img*/}
                                    {/*            src={require('../../images/mobile/General/menu-and-footer/btn_appstore.png')}*/}
                                    {/*            alt="" className="ios"/></Link>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*        <Link to="/" className="align-center"><img*/}
                                    {/*            src={require('../../images/mobile/General/menu-and-footer/btn_googleplay.png')}*/}
                                    {/*            alt="" className="android"/></Link>*/}
                                    {/*    </li>*/}
                                    {/*</ul>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {
        members: state.members,
        router: state.router
    }
);

export default withTranslation()(connect(mapStateToProps)(Header));
