import 'react-app-polyfill/ie9';
import React, {useEffect} from 'react';
import {hydrate,render} from 'react-dom';

import {Provider} from 'react-redux'

// react-router-redux https://github.com/sysgears/react-router-redux
import createHistory from 'history/createBrowserHistory'
import {Route} from 'react-router'

import {
    ConnectedRouter,
    // routerReducer,
    // routerMiddleware,
    // push
} from 'react-router-redux'

// redux-persist https://blog.reactnativecoach.com/the-definitive-guide-to-redux-persist-84738167975
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/lib/integration/react';

// i18n-js
import {
    // useTranslation,
    I18nextProvider
    // , withTranslation
    // , Trans
} from 'react-i18next';
//import './i18n/i18n';
import i18n from './i18n/i18n';

// socket.io
// import socketIoMiddleware from 'redux-socket.io-middleware';
// import io from './socket/connect';

// IMPORT REDUCERS
// import members from './reducers/members';
// import listParkingSpace from './reducers/listParkingSpace';
// import findParkingSpace from './reducers/findParkingSpace';
// import messages from './reducers/messages';

// APP ENTRY POINT
import App from './App';

import * as serviceWorker from './serviceWorker';

// Import Store
import store from './store';

// react-router-redux
// Create a history of your choosing (we're using a browser history in this case)
const history = createHistory()

// Build the middleware for intercepting and dispatching navigation actions
// const middleware = routerMiddleware(history)

const persistor = persistStore(store);

// Now you can dispatch navigation actions from anywhere!
// store.dispatch(push('/en-us/foo'))

function WrapperApp(routeProps) {
    // const { t } = useTranslation();

    useEffect(() => {
        let urlFull = history.location.pathname;
        let urlArray = urlFull.split("/");

        let param = '';

        switch (urlArray[1]) {
            case 'zh-HK':
                param = 'zh-HK';
                break;
            case 'en-US':
                param = 'en-US';
                break;
            default:
                param = 'en-US';
        }

        i18n.changeLanguage(param);
    }, []);

    return (
        <App {...routeProps} />
    );
}
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes())
{
   hydrate(
    <Provider store={store}>
        {/* ConnectedRouter will use the store from Provider automatically */}
        <PersistGate persistor={persistor}>
            <I18nextProvider i18n={i18n}>
                <ConnectedRouter history={history}>
                    <div>
                        <Route
                        path="*"
                        component={WrapperApp}
                        />
                    </div>
                </ConnectedRouter>
            </I18nextProvider>
        </PersistGate>
    </Provider>,
    rootElement
    )
}
else
{
    render(
    <Provider store={store}>
        {/* ConnectedRouter will use the store from Provider automatically */}
        <PersistGate persistor={persistor}>
            <I18nextProvider i18n={i18n}>
                <ConnectedRouter history={history}>
                    <div>
                        <Route
                        path="*"
                        component={WrapperApp}
                        />
                    </div>
                </ConnectedRouter>
            </I18nextProvider>
        </PersistGate>
    </Provider>,
    rootElement
    )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
