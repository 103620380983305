import $ from 'jquery';

export const MapviewHandle = {
    init: function () {

    },
    containersSize: function () {
        if ($(window).width() < 980) {
            //$('.clusterMap').css('height', $(window).height() - 200);
            $('.clusterMap').css('height', $(window).height());
        } else {
            $('.clusterMap').css('height', '');
        }
    }
}
