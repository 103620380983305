import React, {Component} from 'react';
import {Redirect} from 'react-router';
import {withTranslation} from 'react-i18next';

import Slider from "react-slick";

import HomeSearch from './HomeSearch';
// import HomeTop from './HomeTop';
import HomeMiddle from './HomeMiddle';
// import HomeBottom from './HomeBottom';

import * as HelperLayoutHandle from '../../utils/00General/LayoutHandle';
import ReactPixel from "react-facebook-pixel";

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as FindParkingSpaceActionCreators from '../../actions/findParkingSpace';
// import moment from "moment";

import {getUIContent} from '../../utils/04Api/UIContent';
import OurPartners from "./OurPartners";
import {parseUrl} from "../../utils/00General/Helpers";
import {connect} from "react-redux";
import {SECRET} from "../../config";
import i18n from "i18next";
import Cookies from "universal-cookie";
import CryptoJS from "crypto-js";

const cookies = new Cookies();

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                // DateStart: moment().utcOffset(8),
                // DateEnd: moment().utcOffset(8).add(1, 'day'),
                DateStart: '',
                DateEnd: '',
                type: 'hourly',
                where: '',
                lat: null,
                lng: null,
            },
            formSubmitted: false,
            verified: {
                0: true,
                2: true
            },
            wishlist: {
                1: true
            },
            homeBanner: {}
        }

        this.settingsBanner = {
            arrows: false,
            dots: true,
            dotsClass: "slick-dots slick-thumb",
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        this.changeStateLevelTwo = this.changeStateLevelTwo.bind(this);
        this.changeStateLevelTwoArray = this.changeStateLevelTwoArray.bind(this);
    }

    componentDidMount =  () => {
        // const storageData = JSON.parse(sessionStorage.getItem('state'));
        //console.log(storageData);

        // if (storageData) {
        //     this.setState(storageData)
        // }

        ReactPixel.trackCustom('Landing');

        HelperLayoutHandle.LayoutHandle.homepage();
        window.addEventListener("resize", this.windowResize);

        this.ApiGetUIContent(this.ApiGetUIContent);
    }

    componentDidUpdate = () => {
        // sessionStorage.setItem('state', JSON.stringify(this.state));
        // console.log(this.state);
    }

    ApiGetUIContent = (errorCallBack) => {

        let callback = (res) => {

            if (res.status === 200) {

                let body = res.body;

                body.sort((a, b) => a.data.priority - b.data.priority);

                this.setState({
                    ...this.state,
                    homeBanner: body
                });
            }
        }

        getUIContent('home_banner', callback, errorCallBack);
    }

    windowResize = () => {
        var resizeTimer;
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            HelperLayoutHandle.LayoutHandle.homepage();
            HelperLayoutHandle.LayoutHandle.top();
        }, 100);
    }

    handleVerified = (id) => {
        this.setState({
            verified: {
                ...this.state.verified,
                [id]: !this.state.verified[id]
            }
        });
    }

    handleWishlist = (id) => {
        this.setState({
            wishlist: {
                ...this.state.wishlist,
                [id]: !this.state.wishlist[id]
            }
        });
    }

    changeStateLevelTwo = (data1, data2, value) => {
        if (data2 === 'DateStart') {
            if (typeof (this.state.search.DateEnd) !== "undefined") {
                if (value.isAfter(this.state.search.DateEnd)) {
                    this.setState({
                        [data1]: {
                            ...this.state[data1],
                            [data2]: value,
                            'DateEnd': value,
                        }
                    })
                    return;
                }
            }
        }

        this.setState({
            [data1]: {
                ...this.state[data1],
                [data2]: value
            }
        })
    }

    changeStateLevelTwoArray = (data1, dataArray) => {
        this.setState({
            [data1]: {
                ...this.state[data1],
                ...dataArray
            }
        }, () => {
            // console.log('home state', this.state)
        })
    }

    handleToSearch = (e) => {
        e.preventDefault();

        this.setState({
            ...this.state,
            formSubmitted: true
        });
    }

    generateUrl = (url) => {
        if (!url || !url.includes('token')) return url;
        const token = this.props.members.Login.userToken;
        const timestamp = Date.now();
        const data = {token, timestamp};
        return parseUrl(url, data, SECRET);
    }

    onClickBanner = (url, target) => {
        const a = document.createElement("a");
        a.href = this.generateUrl(url) || "/";
        a.target = target;
        a.click();
    }

    render() {
        const {
            // t,
            i18n
        } = this.props;

        if (this.state.formSubmitted) {
            // console.log(this.state.search)

            let search = {};
            for (let k in this.state.search) {
                let key = k,
                    value = this.state.search[k];
                if (value && value !== "") {
                    switch (k) {
                        case 'DateStart':
                            key = 'from';
                            value = this.state.search[k].format("YYYY-MM-DD");
                            break;
                        case 'DateEnd':
                            key = 'to';
                            value = this.state.search[k].format("YYYY-MM-DD");
                            break;
                        case 'type':
                            key = 'booking_type';
                            break;
                        case 'where':
                            key = 'address';
                            break;
                        case 'lat':
                        case 'lng':
                            key = k;
                            break;
                        default:
                            key = ''
                    }
                    if (key !== '')
                        search[key] = value;
                }
            }

            let queryString = Object.keys(search).map(k => k + "=" + search[k]).join("&");
            // console.log(queryString)
            return <Redirect to={"/" + i18n.language + "/findYourSpace?" + queryString}/>
        }

        // console.log(this.state.homeBanner);

        return (
            <div>
                <div className="wrapper-container home">

                    {this.state.homeBanner !== null &&
                    <div>
                        <div className="show_for_large">
                            <Slider {...this.settingsBanner}>

                                {Object.keys(this.state.homeBanner).map(key => {

                                    let data = this.state.homeBanner[key];

                                    let content = this.state.homeBanner[key].data;

                                    if (data.lang === i18n.language && content.status === 1 && content.image) {

                                        let bannerStyle = {
                                            backgroundImage: `url(${content.image})`
                                        }

                                        return (
                                            <div key={key}>
                                                {(content.link !== null || typeof (content.link) !== 'undefined') &&
                                                <a role={"button"} onClick={() => this.onClickBanner(content.link, content.target)}>
                                                    <div className="banner" style={bannerStyle}>
                                                        <img className="bannerText"
                                                             alt=""
                                                             src={content.text_image}
                                                        />
                                                    </div>
                                                </a>
                                                }
                                                {(content.link === null || typeof (content.link) === 'undefined') &&
                                                <div className="banner" style={bannerStyle}>
                                                    <img className="bannerText"
                                                         alt=""
                                                         src={content.text_image}
                                                    />
                                                </div>
                                                }
                                            </div>
                                        );
                                    } else {

                                        return null;
                                    }
                                })}
                            </Slider>
                        </div>

                        <div className="show_for_small">
                            <Slider {...this.settingsBanner}>

                                {Object.keys(this.state.homeBanner).map(key => {

                                    let data = this.state.homeBanner[key];

                                    let content = this.state.homeBanner[key].data;

                                    if (data.lang === i18n.language && content.status === 1 && content.image_mb) {

                                        let bannerStyle = {
                                            backgroundImage: `url(${content.image_mb})`
                                        }

                                        return (
                                            <div key={key}>
                                                {(content.link !== null || typeof (content.link) !== 'undefined') &&
                                                <a role={"button"} onClick={() => this.onClickBanner(content.link, content.target)}>
                                                    <div className="banner" style={bannerStyle}>
                                                        <img className="bannerText"
                                                             alt=""
                                                             src={content.text_image}
                                                        />
                                                    </div>
                                                </a>
                                                }
                                                {(content.link === null || typeof (content.link) === 'undefined') &&
                                                <div className="banner" style={bannerStyle}>
                                                    <img className="bannerText"
                                                         alt=""
                                                         src={content.text_image}
                                                    />
                                                </div>
                                                }
                                            </div>
                                        );
                                    } else {

                                        return null;
                                    }
                                })}
                            </Slider>
                        </div>
                    </div>
                    }

                    <div className="sep-0"/>

                    <div className="containerMain no-padding no-background">

                        <HomeSearch
                            search={this.state.search}
                            changeStateLevelTwo={this.changeStateLevelTwo}
                            changeStateLevelTwoArray={this.changeStateLevelTwoArray}
                            ApiSearch={this.handleToSearch}
                        />

                        {/* Leave for Phase 2 */}
                        {/* <HomeTop
                            verified={this.state.verified}
                            handleVerified={this.handleVerified}
                            wishlist={this.state.wishlist}
                            handleWishlist={this.handleWishlist}


                        /> */}
                    </div>
                </div>

                <HomeMiddle/>

            </div>
        );
    }
}

const mapStateToProps = (state) => (
  {
      members: state.members
  }
);


export default withTranslation()(connect(mapStateToProps)(Home));
