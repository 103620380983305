import React from 'react';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

function N30(props) {
    const {
        // t,
        i18n,
        data,
        time = "",
    } = props;

    let title =(i18n.language==="zh-HK")?data.title_tc:data.title_en,
        content = (i18n.language==="zh-HK")?data.content_tc:data.content_en;

    return (
        <div>
            <h3>{title}</h3>
            <div className="sep-15" />
            <span className="dateTime">{time}</span>
            <div className="sep-20" />
            <div className={"preline"}>
                {content}
            </div>
        </div>
    )
}
export default withTranslation()(N30);