import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import GridType1 from '../../components/102Grids/GridType1';
import ShowMore from '../../components/103Parts/ShowMore';

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as InviteeActionCreators from '../actions/invitee';

function Block(props) {
    return (
        <GridType1
            id={props.id}
            verified={props.verified}
            handleVerified={props.handleVerified}
            wishlist={props.wishlist}
            handleWishlist={props.handleWishlist}
            carTypes={props.carTypes}
        />
    )
}

function Cluster(props) {
    let rows = [];
    for (let i = 0; i < 6; i++) {
        rows.push(
            <div className="oneThird left" key={i} >
                <Block
                    key={i}
                    id={i}
                    verified={props.verified}
                    handleVerified={props.handleVerified}
                    wishlist={props.wishlist}
                    handleWishlist={props.handleWishlist}
                    carTypes={props.carTypes}
                />
            </div>
        )
    }
    return (rows);
}

class HotSpaces extends Component {

    constructor(props) {
        super(props);

        this.state = {
            carTypes: ['Indoor', 'EV'],
            verified: {
                2: true
            },
            wishlist: {
                1: true
            }
        }
    }

    handleVerified = (id) => {
        this.setState({
            verified: {
                ...this.state.verified,
                [id]: !this.state.verified[id]
            }
        });
    }

    handleWishlist = (id) => {
        this.setState({
            wishlist: {
                ...this.state.wishlist,
                [id]: !this.state.wishlist[id]
            }
        });
    }

    onDatepickerRef = (el) => {
        if (el && el.input) {
            el.input.readOnly = true;
        }
    }

    render() {
        // const { t } = this.props;

        return (
            <div className="wrapper-container hotSpaces">

                <div className="page-title"><h2>Hot Spaces</h2></div>

                <div className="wrapper-search">
                    <div className="search clearfix">
                        <div className="wrap clearfix">
                            <div className="fifty left">
                                <div>
                                    <h5>Type</h5>
                                    <select>
                                        <option>Hourly</option>
                                        <option>Monthly</option>
                                        <option>Timeshare</option>
                                    </select>
                                    <div className="vertical-sep"></div>
                                </div>
                            </div>

                            <div className="sep-0 toShow"></div>

                            <div className="fifty left">
                                <div>
                                    <h5>where</h5>
                                    <select>
                                        <option>District</option>
                                        <option>Hong Kong</option>
                                        <option>Kowloon</option>
                                        <option>New Territories</option>
                                    </select>
                                </div>
                            </div>
                            <div className="sep-0" />
                            <div className="sep-5" />
                            <div className="full left">
                                <div className="align-center">
                                    <Link to="/" className="button">Search</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sep-40" />

                <div className="containerMain">
                    <div className="content clearfix">
                        <Cluster
                            verified={this.state.verified}
                            handleVerified={this.handleVerified}
                            wishlist={this.state.wishlist}
                            handleWishlist={this.handleWishlist}
                            carTypes={this.state.carTypes}
                        />
                    </div>
                </div>

                <ShowMore />
            </div>
        );
    }
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default withTranslation()(HotSpaces);
// connect(mapStateToProps)(Contact);
