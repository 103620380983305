import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {Formik, Form, Field} from 'formik';

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as InviteeActionCreators from '../actions/invitee';

import ErrorMessage from '../../components/103Parts/ErrorMessage';

import ReCAPTCHA from "react-google-recaptcha";
import * as ContactusApi from "../../utils/04Api/Contactus";

class Contact extends Component {

    constructor(props) {

        super(props)

        // const { dispatch } = props;

        // DISPATCH TO ACTIONS
        // this.Contact = bindActionCreators(ContactActionCreators.Contact, dispatch);

        this.state = {
            saved: false,
            recaptchaValue: false,
            loaded: false,
        };
    }

    componentDidMount() {

        setTimeout(() => {

            this.changeRecaptcha();

            this.setState({loaded: true});
        }, 1000);

    }

    changeStateLevelOne = (data1, value) => {
        this.setState({
            [data1]: value
        })
    }

    changeStateLevelTwo = (data1, data2, value, cb) => {
        this.setState({
            [data1]: {
                ...this.state[data1],
                [data2]: value
            }
        }, () => {
            if (typeof (cb) === "function")
                cb();
        })
    }

    changeRecaptcha = () => {

        // console.log(this.props.i18n.language);

        window.recaptchaOptions = {
            lang: this.props.i18n.language === 'zh-HK' ? 'zh-hk' : 'en',
        };
    }

    handleSubmit = (values, {setFieldError, resetForm}) => {
        // console.log(this.state.recaptchaValue);

        if (!this.state.recaptchaValue) {
            setFieldError('robot', this.props.t("contact:errors.robot"));
        } else {
            let body = {
                title: values.title,
                name: values.fullName,
                email: values.email,
                number: values.mobile,
                nature: values.enquiry,
                message: values.message,
            };

            const cb = (obj) => {
                if (obj.status === 200) {
                    resetForm();

                    if (window.grecaptcha)
                        window.grecaptcha.reset();

                    this.setState({
                        ...this.state,
                        saved: true,
                    });

                    setTimeout(() => {
                        this.setState({
                            ...this.state,
                            recaptchaValue: false,
                            saved: false,
                        })
                    }, 2000);
                }
                // else if (obj.status === 400) {
                //     setFieldError('tnc', this.props.t("registerCorporate:errors.input.invalidCode"));
                // }
            }

            const eCb = (obj) => {
                setFieldError('tnc', this.props.t("Unable to submit contactus form"));
            }

            ContactusApi.formSubmit(body, cb, eCb);
        }
    }


    onChangeRecaptcha = (value) => {
        this.changeStateLevelOne('recaptchaValue', value);
    }

    form = ({values, errors, touched, handleChange, handleBlur, setFieldValue}) => {

        const {
            t
        } = this.props;

        // console.log(errors);

        return (
            <div>
                <Form>
                    <div className="table">
                        <dt><h4>{t("contact:form.name")} *</h4></dt>
                        <dd>
                            <div className="wrap clearfix">
                                <div className="thirty">
                                    <div>
                                        <select
                                            className="title"
                                            name="title"
                                            value={values.title}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            <option value="mr">{t("contact:form.nameTitle.mr")}</option>
                                            <option value="mrs">{t("contact:form.nameTitle.mrs")}</option>
                                            <option value="ms">{t("contact:form.nameTitle.ms")}</option>
                                            <option value="miss">{t("contact:form.nameTitle.miss")}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="seventy">
                                    <div>
                                        <Field className="fullName" type="text" name="fullName"/>
                                        {errors.fullName && touched.fullName ?
                                            <ErrorMessage message={errors.fullName}/> : null}
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </div>

                    <div className="sep-25"/>

                    <div className="table">
                        <dt><h4>{t("contact:form.email")} *</h4></dt>
                        <dd>
                            <Field className="full" type="text" name="email"/>
                            {errors.email && touched.email ? <ErrorMessage message={errors.email}/> : null}
                        </dd>
                    </div>

                    <div className="sep-25"/>

                    <div className="table">
                        <dt><h4>{t("contact:form.mobile")}</h4></dt>
                        <dd>
                            <Field type="text" name="mobile" maxLength={8}/>
                            {errors.mobile && touched.mobile ? <ErrorMessage message={errors.mobile}/> : null}
                        </dd>
                    </div>

                    <div className="sep-25"/>

                    <div className="table">
                        <dt><h4>{t("contact:form.enquiry")} *</h4></dt>
                        <dd>
                            <select
                                name="enquiry"
                                value={values.enquiry}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                <option value="">{t("contact:form.enquiryOption.select")}</option>
                                <option
                                    value="General">{t("contact:form.enquiryOption.options.general")}</option>
                                <option
                                    value="Comment">{t("contact:form.enquiryOption.options.comment")}</option>
                                <option
                                    value="Suggestion">{t("contact:form.enquiryOption.options.suggestion")}</option>
                                <option
                                    value="Media">{t("contact:form.enquiryOption.options.media")}</option>
                                <option
                                    value="Business">{t("contact:form.enquiryOption.options.business")}</option>
                            </select>
                            {errors.enquiry && touched.enquiry ?
                                <ErrorMessage message={errors.enquiry}/> : null}
                        </dd>
                    </div>

                    <div className="sep-25"/>

                    <div className="table">
                        <dt><h4>{t("contact:form.message")}</h4></dt>
                        <dd>
                            <Field component="textarea" type="text" name="message"/>
                        </dd>
                    </div>

                    <div className="sep-25"/>

                    <div className="table">
                        <dt></dt>
                        <dd>
                            <div className="checkboxFive">
                                <div>
                                    <Field type="checkbox" name="tnc" id="tnc"
                                           checked={values.tnc}/>
                                    <label htmlFor="tnc"></label>
                                </div>
                                <label htmlFor="tnc">
                                    <div>{t("contact:form.tnc.privacy")}</div>
                                </label>
                            </div>
                            {errors.tnc && touched.tnc ?
                                <ErrorMessage message={errors.tnc}/> : null}
                        </dd>
                    </div>

                    <div className="sep-25"/>

                    <div className="table">
                        <dt></dt>
                        <dd>
                            {this.state.loaded && (
                                <ReCAPTCHA
                                    sitekey="6LcsZ6kUAAAAACTobDraEqAaCOUJD_ZAhcv6UM5-"
                                    onChange={this.onChangeRecaptcha}
                                />
                            )}
                            {errors.robot ?
                                <ErrorMessage message={errors.robot}/> : null}
                        </dd>
                    </div>

                    <div className="sep-25"/>

                    <div className="table">
                        <dt></dt>
                        <dd>
                            <input type="submit" className="button" value={t("contact:form.send")}/>
                        </dd>
                    </div>
                    {this.state.saved &&
                    <div className="table">
                        <dt></dt>
                        <dd>
                            {t("contact:successSubmit")}
                        </dd>
                    </div>
                    }
                </Form>
            </div>
        );
    }

    render() {

        // console.log(this.state);

        const {
            t,
            // i18n
        } = this.props;

        const schema = Yup.object().shape({
            fullName: Yup.string()
                .required(t("contact:errors.name")),
            email: Yup.string()
                .required(t("contact:errors.email"))
                .email(t("contact:errors.email")),
            mobile: Yup.string()
                .min(8, t("contact:errors.mobile"))
                .max(8, t("contact:errors.mobile")),
            enquiry: Yup.string()
                .required(t("contact:errors.enquiry")),
            tnc: Yup.boolean()
                .oneOf([true], t("contact:errors.tnc")),
            // robot: Yup.boolean()
            //     .oneOf([true], t("contact:errors.robot"))
        });

        return (
            <div className="wrapper-container contact">

                <div className="page-title"><h2>{t("contact:title")}</h2></div>

                <div className="sep-0"/>

                <div className="containerMain">

                    <p>{t("contact:banner")}</p>

                    <div className="content clearfix">
                        <div className="methods right">
                            <h3>{t("contact:contactTel")}</h3>
                            <img src={require('../../images/mobile/07_contact_us/icon_whatsapp.png')} alt=""/>
                            <p><a href="tel:+85260116609">+ 852 6011 6609</a></p>

                            <div className="sep-30"/>

                            <h3>{t("contact:contactEmail")}</h3>
                            <p><a style={{"textDecoration":"underline"}} href="mailto:info@letspark.com.hk">info@letspark.com.hk</a></p>
                        </div>

                        <div className="form left">
                            <dl className="generalForm clearfix">

                                <div className="sep-30"/>

                                <p className="requiredField">* {t("contact:required")}</p>

                                <Formik
                                    initialValues={{
                                        title: "mr",
                                        fullName: "",
                                        email: "",
                                        mobile: "",
                                        enquiry: "",
                                        message: "",
                                        tnc: false,
                                        robot: false,
                                        recaptchaValue: null
                                    }}
                                    validationSchema={schema}
                                    onSubmit={this.handleSubmit}
                                    component={this.form}
                                />
                            </dl>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

// const mapStateToProps = (state) => (
//     {
//         members: state.members,
//         router: state.router
//     }
// );

export default withTranslation()(Contact);
//connect(mapStateToProps)(Contact);
