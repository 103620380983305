import React, {Component} from 'react';
// import {Redirect} from 'react-router';
import {withTranslation} from 'react-i18next';

// import { bindActionCreators } from 'redux';
// import {connect} from 'react-redux';

// import * as findParkingSpaceActionCreators from '../../actions/findParkingSpace';

import {Formik, Form, Field} from 'formik';
// import * as Yup from 'yup';
// import ErrorMessage from '../../../components/103Parts/ErrorMessage';
import {updateUserInfo} from "../../../utils/04Api/User";

class MemberSettingsNotification extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            saved: false,
        }
    }

    setSubmitting = (submitting, cb) => {
        if (typeof (cb) !== "function")
            cb = () => {
            };

        this.setState({
            ...this.state,
            submitting: submitting,
        }, cb)
    }

    form = ({values, errors, touched, setFieldValue}) => {
        const {t} = this.props;
        //
        // console.log('errors', errors);
        //



        return (
            <Form>
                <div className="fifty left mobile-full margin-bottom">
                    <div>
                        <div className="border-box clearfix">
                            <div className="wrapper">
                                <h4>{t("myAccount:tabSettings.notification.message.title")}</h4>

                                <div className="wrap clearfix">
                                    <div className="full">
                                        <div>
                                            <p>{t("myAccount:tabSettings.notification.message.desc")}</p>

                                            <h5>{t("myAccount:tabSettings.notification.message.method.title")}</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className="wrap clearfix">
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive">
                                                <div>
                                                    <Field type="checkbox" name="messages_email" id="messages_email"
                                                           value="1"
                                                           checked={values.messages_email}/>
                                                    <label htmlFor="messages_email"/>
                                                </div>
                                                <label
                                                    htmlFor="messages_email">{t("myAccount:tabSettings.notification.message.method.options.email")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive">
                                                <div>
                                                    <Field type="checkbox" name="messages_push" id="messages_push"
                                                           value="1"
                                                        // checked={values.messages_push}
                                                           checked={false}
                                                           readOnly/>
                                                    <label htmlFor="messages_push"
                                                           style={{"backgroundColor": "lightgrey"}}/>
                                                </div>
                                                <label
                                                    htmlFor="messages_push">{t("myAccount:tabSettings.notification.message.method.options.notification")}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fifty right mobile-full  margin-bottom">
                    <div>
                        <div className="border-box clearfix">
                            <div className="wrapper">
                                <h4>{t("myAccount:tabSettings.notification.newsLetter.title")}</h4>

                                <div className="wrap clearfix">
                                    <div className="full">
                                        <div>
                                            <p>{t("myAccount:tabSettings.notification.newsLetter.desc")}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="wrap clearfix">
                                    <div className="fifty">
                                        <div>
                                            <div className="checkboxFive">
                                                <div>
                                                    <Field type="checkbox" name="marketing" id="marketing" value="1"
                                                           checked={values.marketing}/>
                                                    <label htmlFor="marketing"/>
                                                </div>
                                                <label
                                                    htmlFor="marketing">{t("myAccount:tabSettings.notification.newsLetter.subscribe")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fifty">
                                        <div/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="sep-0"/>

                <div className="fifty left mobile-full">
                    <div>
                        <div className="border-box clearfix">
                            <div className="wrapper">
                                <h4>{t("myAccount:tabSettings.notification.language.title")}</h4>

                                <div className="wrap clearfix">
                                    <div className="fifty">
                                        <div>
                                            <div className="radioFive">
                                                <div>
                                                    <Field type="radio" name="language" id="language" value="chinese"
                                                           checked={values.language === 'chinese'}/>
                                                    <label
                                                        onClick={() => {
                                                            setFieldValue('language', 'chinese')
                                                        }}
                                                        htmlFor="chinese"/>
                                                </div>
                                                <label
                                                    onClick={() => {
                                                        setFieldValue('language', 'chinese')
                                                    }}
                                                    htmlFor="chinese">{t("myAccount:tabSettings.notification.language.options.chinese")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fifty">
                                        <div>
                                            <div className="radioFive">
                                                <div>
                                                    <Field type="radio" name="language" id="language" value="english"
                                                           checked={values.language === 'english'}/>
                                                    <label
                                                        onClick={() => {
                                                            setFieldValue('language', 'english')
                                                        }}
                                                        htmlFor="english"/>
                                                </div>

                                                <label
                                                    onClick={() => {
                                                        setFieldValue('language', 'english')
                                                    }}
                                                    htmlFor="english">{t("myAccount:tabSettings.notification.language.options.english")}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="sep-20"/>

                <div className="wrap clearfix">
                    <div className="text-center">
                        <button type="submit" className="button update_btn">{t("myAccount:update")}</button>

                        {this.state.saved &&
                        <div>
                            <div className="sep-20"/>
                            {t("myAccount:updated")}
                        </div>
                        }
                    </div>
                </div>
            </Form>
        )
    }

    handleSubmit = (values) => {
        if (!this.state.submitting) {
            // console.log(values);
            this.setSubmitting(false, () => {
                let data = {
                    language: values.language,
                    messages: [],
                    marketing: values.marketing,
                };

                if (values.messages_email)
                    data.messages.push('email');

                if (values.messages_push)
                    data.messages.push('push');
                //
                updateUserInfo(this.props.accessToken, data, () => {
                    this.props.getMe(() => {
                        this.setState({
                            ...this.state,
                            saved: true,
                        }, () => {
                            this.setSubmitting(false);
                        })

                        setTimeout(() => {
                            this.setState({
                                ...this.state,
                                saved: false,
                            })
                        }, 2000);
                    })
                });
            })
        }
    }

    render() {
        // const {
        //     t
        // } = this.props;

        // console.log(this.props.messages);

        return (
            <div className="memberPayment clearfix">
                <div className="form_wrapper">
                    <Formik
                        initialValues={{
                            messages_email: this.props.messages && this.props.messages.indexOf('email') > -1,
                            messages_push: this.props.messages && this.props.messages.indexOf('push') > -1,
                            marketing: this.props.marketing,
                            language: (this.props.language ? this.props.language : 'chinese'),
                        }}
                        onSubmit={this.handleSubmit}
                        component={this.form}
                    />
                </div>
            </div>
        );
    }
}

export default withTranslation()(MemberSettingsNotification);
