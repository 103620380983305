import React, {Component} from "react";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {withGoogleMap, GoogleMap} from "react-google-maps";

import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import SingleMarker from "./SimpleMarker";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
// import * as Config from '../../config';
// import CarTypes from "./CarTypes";


// const API_KEY = "AIzaSyDVI89Fgl9ZaUiDH43m5qMeESQdmrt90QQ";


class ClusterMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // center: props.center,
            map: null,
            // zoom: props.zoom,
            selectedMarker: null,
            selectedMarkerId: null,
            selectedLng: null,
            selectedLat: null,
            selectedTotal: 0,
        }
    }

    static defaultProps = {
        center: {
            lat: 22.3008974,
            lng: 114.1681516
        },
        zoom: 14,
        isMarkerShown: true,
        map: null,
    };

    onClick = marker => {
        this.onMarkerClicked(marker);
    };

    onMapClick = () => {
        this.onMarkerClicked(null);
    }

    onMapMounted = (ref) => {
        // console.log(ref);
        this.setState({
            ...this.state,
            map: ref,
        })
    }

    zoomIn() {
        let zoom = this.state.map.getZoom();
        // console.log("zoom in", zoom);

        if (zoom < 17)
            this.state.map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(18);
    }

    onMarkerClicked = (marker) => {
        if (marker) {
            // console.log('marker', marker.get('lat'), marker.get('lng'));
            // this.setState({
            //     ...this.state,
            //     center: {
            //         lat: marker.get('lat'),
            //         lng: marker.get('lng')
            //     },
            // })

            this.state.map.panTo({lat: marker.get('lat'), lng: marker.get('lng')})
            this.zoomIn();

            this.props.selectSpace(marker.get('id'))
        } else
            this.props.selectSpace(null);
    }

    onZoomChanged = () => {
        const zoom = this.state.map.getZoom();
        // console.log("zoom change", zoom);
        if (zoom < 17) {
            this.onMarkerClicked(null);
        }
    }

    componentDidUpdate = () => {
        if (this.props.markers) {
            if (!this.props.selectedSpace && this.state.selectedMarker) {
                this.setState({
                    ...this.state,
                    selectedMarker: null,
                    selectedMarkerId: null,
                    selectedLng: null,
                    selectedLat: null,
                    selectedTotal: 0,
                })
            } else if (this.props.selectedSpace) {
                if (!this.state.selectedMarker || this.props.selectedSpace !== this.state.selectedMarkerId) {
                    this.props.markers.forEach((o) => {
                        if (o.get("id") === this.props.selectedSpace) {
                            const lat = o.get('lat'),
                                lng = o.get('lng'),
                                selectedMarkerId = o.get("id");

                            let selectedTotal = 0;

                            const selectedMarker = this.props.markers.filter((m) => {
                                if (m.get('lat') === lat && m.get('lng') === lng) {
                                    selectedTotal++;
                                    return true;
                                }
                                return false;
                            });

                            this.setState({
                                ...this.state,
                                selectedMarker: selectedMarker,
                                selectedMarkerId: selectedMarkerId,
                                selectedLng: lng,
                                selectedLat: lat,
                                selectedTotal: selectedTotal,
                            }, () => {
                                // console.log(this.state.selectedTotal);

                                this.state.map.panTo({lat: lat, lng: lng})
                                this.zoomIn();
                            })
                        }
                    })
                }
            }
        }
    }

    render() {
        const {
            t,
            i18n,
            center,
            zoom,
            isMarkerShown,
            markers,
        } = this.props;

        const {
            selectedMarker,
            selectedMarkerId,
            selectedLat,
            selectedLng,
            selectedTotal
        } = this.state;

        // console.log("selectedMarker", selectedMarker);

        if (selectedMarker) {
            // console.log("selectedMarker length", selectedMarker.length);
        }
        // console.log("zoom", zoom);

        return (
            <GoogleMap
                defaultZoom={zoom}
                defaultCenter={center}
                onClick={this.onMapClick}
                clickableIcons={false}
                defaultOptions={{
                    // these following 7 options turn certain controls off see link below
                    streetViewControl: false,
                    scaleControl: false,
                    mapTypeControl: false,
                    panControl: false,
                    zoomControl: false,
                    rotateControl: false,
                    fullscreenControl: false
                }}
                ref={this.onMapMounted}
                onZoomChanged={this.onZoomChanged}
                // zoom={this.state.zoom}
                // center={this.state.center}
            >

                {selectedMarker && (
                    <InfoBox
                        key={selectedMarkerId}
                        defaultPosition={
                            new window.google.maps.LatLng(center.lat, center.lng)
                        }
                        position={
                            new window.google.maps.LatLng(
                                selectedLat,
                                selectedLng
                            )
                        }
                        options={{
                            closeBoxURL: ``,
                            enableEventPropagation: true,
                            pixelOffset: new window.google.maps.Size(-191, (selectedTotal > 1 ? -290 : -160)),
                            disableAutoPan: true,
                            maxWidth: 0
                        }}
                    >
                        <div className="info-window">
                            {selectedMarker.map((m,i)=>(
                                <div className="info-window-space" key={i}>
                                    <div className="info-window-wrapper">
                                        <div className="info-window-img-block">
                                            <div className="info-window-img"
                                                 style={{backgroundImage: `url(${m.get("image")})`}}>
                                            </div>
                                        </div>
                                        <div className="info-detail">
                                            <div className="info-detail-address">
                                                <h3>{i18n.language === 'en-US' ? m.get("title") : m.get("title_tc")}</h3>
                                                <h4>{m.get("address")}</h4>
                                            </div>
                                            <div className="info-detail-reviews">
                                                <div className="review-rating">
                                                    <i className={m.get("rating") > 0 ? 'on' : ''} />
                                                    <i className={m.get("rating") > 1 ? 'on' : ''} />
                                                    <i className={m.get("rating") > 2 ? 'on' : ''} />
                                                    <i className={m.get("rating") > 3 ? 'on' : ''} />
                                                    <i className={m.get("rating") > 4 ? 'on' : ''} />
                                                </div>
                                                <div className="review-count">
                                                    {m.get("reviews")} reviews
                                                </div>

                                                <Link to={"/" + i18n.language + "/space/" + m.get('id')} className="button">{t("search:bookNow")}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </InfoBox>
                )}

                <MarkerClusterer
                    averageCenter
                    enableRetinaIcons
                    gridSize={60}
                    defaultMaxZoom={16}
                    styles={[
                         {
                             url: "/images/icon_letspark.png",
                             height: 32,
                             width: 90,
                             textColor: '#fff',
                             textSize: 14
                         }
                     ]}
                    // onClick={(a)=>{console.log("clicked marker", a);}}
                >
                    {isMarkerShown &&
                    markers &&
                    markers.map(marker => (
                        <SingleMarker
                            key={marker.get("id")}
                            active={
                                this.props.selectedSpace &&
                                marker.get("id") === this.props.selectedSpace
                            }
                            position={{
                                lat: marker.get("lat"),
                                lng: marker.get("lng")
                            }}
                            marker={marker}
                            onClick={() => this.onClick(marker)}
                        />
                    ))}
                </MarkerClusterer>
            </GoogleMap>
        );
    }
}

// const WrapperMapComponent = withScriptjs(withGoogleMap(ClusterMap));
const WrapperMapComponent = withGoogleMap(ClusterMap);

WrapperMapComponent.defaultProps = {
    //googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${Config.UAT_GOOGLE_MAP_KEY}`,
    loadingElement: <div style={{height: `100%`}}/>,
    containerElement: <div style={{height: `100%`}}/>,
    mapElement: <div style={{height: `100%`}}/>
};

// export default WrapperMapComponent;

// export default props => (
//     <div className="clusterMap">
//         <WrapperMapComponent {...props} />
//     </div>
// );

export default withTranslation()((props => (
    <div className="clusterMap">
        <WrapperMapComponent {...props} />
    </div>)));