import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

import {getLogo} from '../../utils/04Api/PartnerLogo';

class Partners extends Component {

    constructor(props) {
        super(props);

        this.state = {
            logo: [],
            containerWidth: 0,
            retryCount: 0
        }

        this.logoContainer = React.createRef();
    }

    componentDidMount() {
        this.ApiGetLogo(this.ApiGetLogo);
        setTimeout(this.updateDimensions, 400)
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({ containerWidth: this.logoContainer.current ? this.logoContainer.current.offsetWidth : 0 });
    };

    ApiGetLogo = (errorCallback) => {

        let callback = (res) => {
            if (res.status === 200) {
                this.setState({
                    ...this.state,
                    logo: res.body
                })
            }
        }
        getLogo(callback, () => {
            this.setState({retryCount: this.state.retryCount + 1}, () => {
                if (this.state.retryCount <= 3){
                    errorCallback();
                }
            })
        });
    }

    render() {

        const {
            t,
            i18n
        } = this.props;

        let logoWrapWidth = this.state.containerWidth - (this.state.containerWidth % 110);

        return (
            <div className="wrapper-container media">

                <div className="page-title"><h2>{t("Menu.Partners")}</h2></div>

                <div className="sep-0"/>

                <div className="containerMain" style={{display: 'flex', justifyContent: 'center'}}>
                    <div ref={this.logoContainer}  style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
                        <div style={{margin: 0, width: "100%", height: this.state.logo.length / Math.floor(logoWrapWidth / 250) * 150, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column'}}>
                            {
                                this.state.logo.map((item) =>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <img src={item.image} style={{
                                            height: 100,
                                            maxWidth: 250,
                                            margin: '10px 5px 0 5px',
                                            objectFit: 'contain'
                                        }}/>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Partners);
