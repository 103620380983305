// import moment from "moment";
// import * as Config from "../../config";
import {momentTime} from "../../utils/00General/Helpers";
import { getCalculation } from '../04Api/Space';
import moment from "moment";
import numeral from 'numeral';

export const calculateHours = (from, to, from_time, to_time) => {
    let s = from,
        e = to;

    if (typeof(from) === "string")
        s = moment(from);

    if (typeof(to) === "string")
        e = moment(to);

    if (from_time && to_time) {
        s = moment(from.utcOffset(8).format("YYYY-MM-DD") + "T" + from_time + "+0800");
        e = moment(to.utcOffset(8).format("YYYY-MM-DD") + "T" + to_time + "+0800");
    }

    const duration = moment.duration(e.diff(s));
    // console.log("days", duration.days());
    // console.log("hours", duration.hours());
    return (parseInt(duration.days()) * 24) + parseInt(duration.hours());
}

export const numeralMoney = (number, showDollorSign=true) => {
    return (showDollorSign ? 'HK$ ' : '') + numeral(number).format('0,0[.][0]');
}
//
// export const number_format = (number, decimals, dec_point, thousands_sep) => {
//     // Strip all characters but numerical ones.
//     number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
//     var n = !isFinite(+number) ? 0 : +number,
//         prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
//         sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
//         dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
//         s = '',
//         toFixedFix = function (n, prec) {
//             var k = Math.pow(10, prec);
//             return '' + Math.round(n * k) / k;
//         };
//     // Fix for IE parseFloat(0.55).toFixed(0) = 0;
//     s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
//     if (s[0].length > 3) {
//         s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
//     }
//     if ((s[1] || '').length < prec) {
//         s[1] = s[1] || '';
//         s[1] += new Array(prec - s[1].length + 1).join('0');
//     }
//     return s.join(dec);
// }

export const CalculateHelper = (type, state, space_id, bcode, access_token, cb) => {
    let params = {
        access_token : access_token,
        bcode: bcode === true
    };

    if (type === 'hourly') {
        if (typeof(state.startMinute) !== "undefined")
            params['start'] = momentTime(state.startDate, "YYYY-MM-DD") + "T" + state.startTime.substring(0,2) + ":" + state.startMinute;
        else
            params['start'] = momentTime(state.startDate, "YYYY-MM-DD") + "T" + state.startTime;


        // let endMoment = moment(momentTime(state.endDate, "YYYY-MM-DD") + "T" + state.endTime + "+0800").subtract(1, "minute");
        // params['end'] = momentTime(endMoment, "YYYY-MM-DDTHH:mm");

        if (typeof(state.endMinute) !== "undefined")
            params['end'] = momentTime(state.endDate, "YYYY-MM-DD") + "T" + state.endTime.substring(0,2) + ":" + state.endMinute;
        else
            params['end'] = momentTime(state.endDate, "YYYY-MM-DD") + "T" + state.endTime;

        params['preBookBlocker'] = state.preBookBlocker;
        params['postBookBlocker'] = state.postBookBlocker;
    } else {
        params['start'] = momentTime(state.startDate, "YYYY-MM-DD") + "T00:00";
        params['end'] = momentTime(state.endDate, "YYYY-MM-DD") + "T23:59";
    }

    let callback = (obj) => {
        let returnState = {
            success: false,
            // start: '',
            // end: '',
            // hours: 0,
            // // amount: 0,
            // total: 0,
            // cost: 0,
            // space: null,
        };

        if (obj.status === 200) {
            // console.log("getCalculation", obj.body);
            const calculation = obj.body[0],
                totalcost = calculation.totalcost;

            if (type === "hourly") {

                if (totalcost > 0) {
                    const hours = calculateHours(params['start'], params['end']);

                    returnState = calculation;
                    returnState.success = true;
                    returnState.start = params['start'];
                    returnState.end = params['end'];
                    returnState.hours = hours;
                    //
                    // returnState = {
                    //     success: true,
                    //     // space: calculation.data,
                    //     start: params['start'],
                    //     end: params['end'],
                    //     hours: hours,
                    //     // amount: cost,
                    //     cost: cost,
                    //     total: totalcost,
                    //     res: calculation,
                    // };
                }
            } else {
                if (totalcost > 0) {
                    returnState = calculation;
                    returnState.success = true;
                    returnState.start = params['start'];
                    returnState.end = params['end'];

                    // returnState = {
                    //     success: true,
                    //     // space: calculation.data,
                    //     start: params['start'],
                    //     end: params['end'],
                    //     total: calculation.totalcost,
                    //     res: calculation,
                    //
                    //     // months: res.months,
                    //     // deposit: res.deposit,
                    //     // // amount: cost,
                    //     // total: res.totalcost,
                    //     // cost: res.cost,
                    // };
                }
            }
        } else if (obj.body && obj.body.msg) {
            returnState.msg = obj.body.msg;
        }

        cb(type, returnState);
    };

    let errorCallback = (error) => {
        cb(type, {
            success: false,
            start: '',
            end: '',
            hours: 0,
            amount: 0,
            total: 0,
            cost: 0,
            space: null,
        })
    }

    getCalculation(type, space_id, params, callback, errorCallback);
}


export const isPublicHoliday = (date) => {
    // TODO: get PH and check if date is PH
    return false;
}
