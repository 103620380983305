import React from 'react';
// import { Link } from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {Link} from "react-router-dom";

function N13(props) {
    const {
        t,
        i18n,
        // data = null,
        time = "",
        // id = "",
        booker_name,
    } = props;

    return (
        <div className="response">
            <div className="info border clearfix">
                <h5>{time}</h5>
            </div>

            <div className="centerBox">
                <h3>
                    {t("messenger:notifications.N13.title")}
                </h3>

                <div className="sep-10"/>

                <p className="preline">
                    {t("messenger:notifications.N13.p1", {"fullName" : booker_name, "name": booker_name})}
                </p>

                {/*<p>{t("messenger:general.review", {"name": booker_name})}:</p>*/}

                {/*<p>(Insert Kelvin’s review here)</p>*/}

                <p className="please">
                    {t("messenger:general.reply")}:
                    <br/>
                    <Link to={"/" + i18n.language + "/review/" + props.data.booking_id}
                          className="button">{t("messenger:general.go_to_write_review")}</Link>
                    <br/>
                    {t("messenger:general.thank_you_1")}
                </p>
            </div>
        </div>
    )
}

export default withTranslation()(N13);