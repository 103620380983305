import React, {Component} from 'react';
// import { Redirect } from 'react-router';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import DatePicker from "react-datepicker";
import moment from "moment";
import TimePicker from "../../components/103Parts/TimePicker";
import * as Helper from "../../utils/03FindParkingSpace/Calculations";
import {lowestPrice, isSamePrice} from "../../utils/03FindParkingSpace/LowestHourlyPrice";

import {find, filter} from "lodash";

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as FindParkingSpaceActionCreators from '../../actions/findParkingSpace';

import 'moment/locale/zh-hk';

function Payment(props)
{
    const {t}=props;
    return (
    <div>
        <Link to="/" className={props.className + " button bookNow"} onClick={(e) =>
        {
            e.preventDefault();
            props.insertTempBooking(e);
        }}>{t("space:letGo")}</Link>

        <div className="sep-15"/>

        {/*<p className="notChargedYet">{t("space:chargedYet")}</p>*/}
    </div>
    )
}

function Total(props)
{
    const {
        t,
        calculation,
    }=props;

    return (
    <dl className="clearfix">
        <dt><b>{t("space:price.subTotal")}</b></dt>
        <dd><b>{Helper.numeralMoney(calculation.subtotal)}</b></dd>

        {/*<dt>{t("space:price.deposit")}</dt>*/}
        {/*<dd>HKD {Helper.number_format(calculation.deposit, 0)}</dd>*/}

        {/*<dt>{t("space:price.rental_deposit")}</dt>*/}
        {/*<dd>HKD {Helper.number_format(calculation.rentaldeposit, 0)}</dd>*/}

        {/*/!*<dt>HKD 20 x {props.calculation.hours} hour(s)</dt>*!/*/}
        {/*/!*<dd>HKD {Helper.number_format(props.calculation.amount)}</dd>*!/*/}
        {/*/!*<div className="sep-0" />*!/*/}
        {/*<dt><b>{t("space:price.total")}</b></dt>*/}
        {/*<dd><b>HKD {Helper.number_format(calculation.totalcost, 0)}</b></dd>*/}
    </dl>
    )
}

class Price extends Component
{
    constructor(props)
    {
        super(props);

        this.state={
            open: false,
            popup_instant_open: false,
            popup_bcode_open: false,
            startDateChanged: false,
        };

    }

    handlePopupBtnClick=(data, o_data) =>
    {
        this.setState({
            ...this.state,
            [o_data]: false,
            [data]: !this.state[data]
        })
    }

    render()
    {
        let price=lowestPrice(this.props.days),
        samePrice=isSamePrice(this.props.days),
        t=this.props.t;

        // samePrice = false;

        // console.log(this.props.days);

        let last_day='';

        return (
        <div className="price">

            {/*{this.props.hourlyData.instant &&*/}
            {/*<div className="instantBooking"></div>*/}
            {/*}*/}

            <div className="unavailable-days">
                {!samePrice ? t("space:moneyFrom") : ""} HK$ <span>{price}</span> {t("space:perHour") + (!samePrice ? t("space:moneyAt") : "")}

                {!samePrice &&
                <span className="check" onClick={() =>
                {
                    this.handlePopupBtnClick('open', 'popup_instant_open')
                }}>&nbsp;</span>
                }
                {!samePrice && this.state.open &&
                <div className="popup">
                    <dl>
                        <div>
                            {this.props.days.map((time, index) =>
                            {
                                let the_day=t("Days.day_" + time.day);
                                if (last_day === the_day)
                                    the_day='';
                                else
                                    last_day=the_day;

                                return (
                                <div key={index}>
                                    <div className="forty left">{the_day}</div>
                                    <div
                                    className="sixty right text_align_right">
                                        {time.stime}-{time.etime}
                                        <b style={{minWidth: "60px", display: "inline-block"}}>HK$ {time.price}</b>
                                    </div>
                                    <div className="sep-0"/>
                                </div>
                                )
                            })}
                        </div>
                    </dl>
                </div>
                }

                {this.props.instant &&
                <span className="instant" onClick={() =>
                {
                    this.handlePopupBtnClick('popup_instant_open', 'open')
                }}>&nbsp;</span>
                }
                {this.props.bcode &&
                <span className="bcode" style={{marginTop: -15, right: -7}} onClick={() =>
                {
                    this.handlePopupBtnClick('popup_bcode_open', 'open')
                }}>&nbsp;</span>
                }

                {this.props.instant && this.state.popup_instant_open &&
                <div className="popup">
                    <dl>
                        <div>{t("listASpace:stepTwo.instantBooking.popupContent")}</div>
                    </dl>
                </div>
                }
                {this.props.bcode && this.state.popup_bcode_open &&
                <div className="popup">
                    <dl>
                        <div>{t("listASpace:stepTwo.bcode.popupContent")}</div>
                    </dl>
                </div>
                }
            </div>
        </div>
        );
    }
}

function SwitchTypeIcon(props)
{

    switch (props.type)
    {
        case 'hourly':
            return (
            <img src={require('../../images/mobile/03_find_parking_space/icon_type_3_Hourly_w.png')} alt=""/>
            );
        case 'monthly':
            return (
            <img src={require('../../images/mobile/03_find_parking_space/icon_type_1_monthly_w.png')} alt=""/>
            );
        case 'timeshare':
            return (
            <img src={require('../../images/mobile/03_find_parking_space/icon_type_2_TimeShare_w.png')} alt=""/>
            );
        default:
            return null
    // default:
    //     return (
    //         <img src={require('../../images/mobile/03_find_parking_space/icon_type_3_Hourly_w.png')} alt=""/>
    //     );
    }
}

function DetailHourly(props)
{

    const {
        t,
        i18n,
    }=props;

    moment.locale('en');

    if (i18n.language === 'zh-HK')
    {
        moment.locale('zh-hk');
    }

    let onDatepickerRef=(el) =>
    {
        if (el && el.input)
        {
            el.input.readOnly=true;
        }
    }
    //
    // let calMinStart = () => {
    //     let minStart = moment().add(4, "hours").utcOffset(8);
    //
    //     const isPH = props.isPublicHoliday(minStart),
    //         days = props.hourlyData.days;
    //
    //     if (isPH) {
    //         // check holiday
    //         days.forEach((availableDay) => {
    //             if (availableDay.day === 8)
    //                 availableDay.stime
    //                 isAvailableDay = true; // PH available and the date is PH
    //         });
    //     } else {
    //         days.forEach((availableDay) => {
    //             if (availableDay.day < 8 && availableDay.day === day)
    //                 isAvailableDay = true;
    //         });
    //     }
    //
    // }

    let availability=null;
    if (props.minBookingError)
        availability=<div>{t("space:errors.minimum_hour", {min: props.minbooking})}</div>
    else if (props.maxBookingError)
        availability=<div>{t("space:errors.maximum_hour", {min: props.minbooking})}</div>
    else if (props.overnightError)
        availability=<div>{t("space:errors.overnight")}</div>
    else if (typeof (props.calculation.success) === "boolean")
    {
        if (props.calculation.success)
            availability=<Total calculation={props.calculation} t={t}/>
        else
            availability=<div>{t("space:errors.not_available")}</div>
    }

    const minStart=moment().utcOffset(8).add(15, "minutes"),
    maxEnd=moment().utcOffset(8).add(10, "days");
    // console.log('min & max', minStart.format("YYYY-MM-DD"), maxEnd.format("YYYY-MM-DD"));

    const blockedDates=props.hourlyData.blocked;
    let booked=props.booked;
    if (typeof (booked) === "undefined")
        booked=[];

    let bookedTimes=[];
    if (booked.length)
    {
        for (let j=0; j<booked.length; j++)
        {
            let from=moment(booked[j].from + ":00+0800").utcOffset(8),
            to=moment(booked[j].to + ":00+0800").utcOffset(8);

            bookedTimes.push([from, to]);

            // console.log('booked', from.format('YYYY-MM-DD HH:mm'), to.format('YYYY-MM-DD HH:mm'))
        }
    }
    // console.log('booked', booked);

    let availableDays=[];
    const calculateAvailableDays=(quota = 1) =>
    {
        let d=minStart.clone().utcOffset(8);

        const isBlocked=(d) =>
        {
            if (blockedDates)
            {
                for (const bd of blockedDates)
                {
                    // console.log(d.from, d.to);
                    if (d.isBetween(moment(bd.from + "T00:00:00+08:00"), moment(bd.to + "T00:00:00+08:00"), 'day', '[]'))
                    {
                        return true;
                    }
                }
            }
            return false;
        }

        const isAvailableWeekday=(d) =>
        {
            // check if its in the available day of the week
            let day=d.day();
            if (day === 0)
                day=7;

            if (props.isPublicHoliday(d))
                day=8;

            let theday=[];
            const days=props.hourlyData.days;

            days.forEach((availableDay) =>
            {
                if (availableDay.day === day)
                {
                    theday.push(availableDay); // PH available and the date is PH
                }
            });

            if (theday.length)
            {
                // available, check for available time
                // console.log(theday);
                if (!booked.length)
                    return true;

                // console.log("check booked");
                for (let i=0; i<theday.length; i++)
                {
                    let stime=moment(d.format("YYYY-MM-DD") + "T" + theday[i].stime + ":00+0800").utcOffset(8),
                    etime=moment(d.format("YYYY-MM-DD") + "T" + theday[i].etime + ":00+0800").utcOffset(8);

                    while (stime.isBefore(etime))
                    {
                        // console.log(stime.format(), etime.format());
                        let foundBookedTime=filter(bookedTimes, (o) =>
                        {
                            return stime.isBetween(o[0], o[1], "(]");
                        });

                        if (foundBookedTime.length < quota)
                        {
                            // found timeslot available
                            return true;
                        }
                        stime.add(1, "hour");
                    }
                }
                return false;
            }
            else
            {
                return false;
            }
        }

        // loop the start to end dates
        while (d.isSameOrBefore(maxEnd, 'day'))
        {
            // console.log('check', d.format("YYYY-MM-DD"));

            // check if the date is blocked
            if (isBlocked(d))
            {
                d.add(1, 'day');
                continue;
            }

            // check if the day is within available week days
            if (!isAvailableWeekday(d))
            {
                // console.log('failed', d.format("YYYY-MM-DD"), d.day());
                d.add(1, 'day');
                continue;
            }

            // day is ok and add to availableDays
            availableDays.push(d.format('YYYY-MM-DD'));

            // loop next day
            d.add(1, 'day');
        }
        // console.log('availableDays', availableDays);
    }
    calculateAvailableDays(props.quota);

    const inAvailableDays=(checkD) =>
    {
        for (let i=0; i<availableDays.length; i++)
        {
            if (availableDays[i] === checkD.format("YYYY-MM-DD"))
            {
                return true;
            }
        }
        return false;
    }

    let filterStartDate=(thedate) =>
    {
        let date=thedate.clone().utcOffset(8);
        // console.log(date.format("YYYY-MM-DD"));

        // no available day at all
        if (!availableDays.length)
            return false;

        if (date.isBefore(minStart, 'day'))
        {
            // console.log(date.format("YYYY-MM-DD"), 'isBefore', minStart.format("YYYY-MM-DD"));
            return false;
        }

        if (date.isAfter(maxEnd, 'day'))
        {
            return false;
        }

        if (!inAvailableDays(date))
            return false;

        return true;
    }

    let dayClassName=(date) =>
    {
        return props.isPublicHoliday(date) ? 'ph' : undefined;
    }

    // setup calendar props
    const days=props.hourlyData.days;
    let startDateProps={},
    endDateProps={},
    startTimeProps={},
    endTimeProps={},
    from=moment(props.hourlyData.from + "T00:00:00+08:00"),
    to=moment(props.hourlyData.to + "T00:00:00+08:00");

    if (from.isBefore(moment()))
    {
        startDateProps.minDate=moment();
        endDateProps.minDate=moment();
    }
    else
    {
        startDateProps.minDate=from.clone();
        endDateProps.minDate=from.clone();
    }

    if (to.isBefore(moment()))
    {
        startDateProps.maxDate=moment();
        endDateProps.maxDate=moment();
    }
    else
    {
        startDateProps.maxDate=to.clone();
        endDateProps.maxDate=to.clone();
    }

    if (props.startDate)
        endDateProps.minDate=props.startDate.clone();

    if (props.endDate)
        startDateProps.maxDate=props.endDate.clone();

    startTimeProps.booked=[];
    endTimeProps.booked=[];

    const getBookedTimes=(d, isStart, quota = 1) =>
    {
        let returnData=[];
        let stime=moment(d.format("YYYY-MM-DD") + "T00:00:00+0800").utcOffset(8),
        etime=stime.clone().add(1, 'day').utcOffset(8);
        const preBookBlocker = props.preBookBlocker;
        const postBookBlocker = props.postBookBlocker;

        // console.log(stime, etime, bookedTimes);

        while (stime.isBefore(etime))
        {
            let foundBookedTime=filter(bookedTimes, (o) =>
            {
                const from = o[0].clone().subtract(postBookBlocker, 'minutes');
                const to = o[1].clone().add(preBookBlocker, 'minutes');
                const startOfDay = stime.clone().startOf('day');
                if (stime.clone().subtract(preBookBlocker, 'minutes').isBefore(startOfDay)) return true;
                return stime.isBetween(from, to, null, isStart ? "[)" : "()") && (stime.hours() !== to.hours());
            });

            if (foundBookedTime.length >= quota)
            {
                // found at timeslot available
                returnData.push(stime.format("HH:mm"));
            }
            stime.add(1, "hour");
        }
        // console.log(returnData);
        return returnData;
    }

    // if date is selected, check for available times
    if (props.startDate !== null && props.startDate !== "")
    {
        const isPH=props.isPublicHoliday(props.startDate);
        let day=props.startDate.day();
        if (day === 0)
            day=7;

        // check if the day is available
        days.forEach((availableDay) =>
        {
            if ((availableDay.day === 8 && isPH) || availableDay.day === day)
            {
                if (typeof (startTimeProps.stime) === "undefined" || parseInt(startTimeProps.stime.substr(0, 2))>parseInt(availableDay.stime.substr(0, 2)))
                    startTimeProps.stime=availableDay.stime;

                if (typeof (startTimeProps.etime) === "undefined" || parseInt(startTimeProps.etime.substr(0, 2))<parseInt(availableDay.etime.substr(0, 2)))
                    startTimeProps.etime=availableDay.etime;
            }
        });

        // console.log(startTimeProps);
        // console.log("startDate", props.startDate);

        if (minStart.isSame(props.startDate, 'day'))
        {
            // selected today, check T+4
            // console.log("check minstart for today");
            // console.log('stime', props.startDate, startTimeProps.stime);

            // console.log(parseInt(startTimeProps.stime.substr(0, 2)), parseInt(minStart.format("H"))+ 1);

            if (typeof (startTimeProps.stime) === "undefined" || parseInt(startTimeProps.stime.substr(0, 2))<(parseInt(minStart.format("H")) + 1))
            {
                if (parseInt(minStart.format("H")) + 1 === 24)
                    startTimeProps.stime='24:00';
                else
                    startTimeProps.stime=minStart.clone().add(15, 'minute').format("HH:00");

            }
        }

        // console.log("selected start date", props.startDate.format());
        startTimeProps.booked=getBookedTimes(props.startDate, true, props.quota);
        // console.log("start booked time", startTimeProps.booked);
    }

    if (props.endDate !== null && props.endDate !== "")
    {
        const isPH=props.isPublicHoliday(props.endDate);
        let day=props.endDate.day();
        if (day === 0)
            day=7;

        // check if the day is available
        days.forEach((availableDay) =>
        {
            if ((availableDay.day === 8 && isPH) || availableDay.day === day)
            {
                if (typeof (endTimeProps.stime) === "undefined" || parseInt(endTimeProps.stime.substr(0, 2))>parseInt(availableDay.stime.substr(0, 2)))
                    endTimeProps.stime=availableDay.stime;

                if (typeof (endTimeProps.etime) === "undefined" || parseInt(endTimeProps.etime.substr(0, 2))<parseInt(availableDay.etime.substr(0, 2)))
                    endTimeProps.etime=availableDay.etime;
            }
        });

        if (minStart.isSame(props.endDate, 'day'))
        {
            // selected today, check T+4
            // console.log("check minstart for today");
            // console.log(startTimeProps.stime, parseInt(startTimeProps.stime.substr(0, 2)), parseInt(minStart.format("H")));
            if (typeof (endTimeProps.stime) === "undefined" || parseInt(endTimeProps.stime.substr(0, 2))<parseInt(minStart.format("H")))
            {
                endTimeProps.stime=minStart.format("HH:00");
            }
        }

        endTimeProps.booked=getBookedTimes(props.endDate, false, props.quota);
        // console.log("end booked time", endTimeProps.booked);
    }

    // check if time and date is selected and limit same day before / after
    if (props.startDate && props.endDate && props.endTime)
    {
        if (props.startDate.isSame(props.endDate, 'day'))
        {
            if (props.startTime !== "00:00")
            {
                let t=moment("2019-01-01T" + props.endTime).subtract("1", "minute");
                startTimeProps.etime=t.format("HH:mm");
                // console.log(t.format("HH:mm"));
            }
        }
    }

    if (props.startDate && props.endDate && props.startTime)
    {
        if (props.startDate.isSame(props.endDate, 'day'))
        {
            if (props.startTime !== "24:00")
            {
                let t=moment("2019-01-01T" + props.startTime).add("1", "minute");
                endTimeProps.stime=t.format("HH:mm");
                // console.log(t.format("HH:mm"));
            }
        }
    }

    // console.log(props.startDate);
    // console.log(availableStartTime);
    // console.log(props.hourlyData);

    // console.log("detailHourly", props);

    return (
    <div className="book left">
        <div>
            <div className="one left">
                <div className="typeIcon">
                    <SwitchTypeIcon
                    type={props.BookingType}
                    >
                    </SwitchTypeIcon>
                </div>
            </div>

            <select className="ninety left" value={props.BookingType}
                    onChange={(e) => props.handleBookingType(e.target.value)}>
                {
                    props.supportTypes.map((value, index) =>
                    {
                        switch (value)
                        {
                            case 'hourly':
                                return <option value={value} key={index}>{t("space:tabOptions.hourly")}</option>
                            case 'monthly':
                                return <option value={value} key={index}>{t("space:tabOptions.monthly")}</option>
                            case 'timeshare':
                                return <option value={value} key={index}>{t("space:tabOptions.timeShare")}</option>
                            default:
                                return null
                        // default:
                        //     return <option value={value} key={index}>{t("space:tabOptions.hourly")}</option>
                        }
                    }
                    )
                }
            </select>
        </div>

        <div className="sep-20"/>

        <Price
        days={props.space.hourly.days}
        t={t}
        instant={props.instant}
        bcode={props.bcode}
        />

        <div className="sep-30"/>

        <h3>{t("space:form")}</h3>
        <div className="wrap clearfix">
            <div className="fifty">
                <div>
                    <DatePicker
                    {...startDateProps}
                    filterDate={filterStartDate}
                    dateFormat="YYYY-MM-DD"
                    selected={props.startDate === "" ? null : props.startDate}
                    onChange={(e) =>
                    {
                        console.log(e);
                        props.updateState('toBookHourly', 'startDate', e, () =>
                        {
                            props.updateState('toBookHourly', 'startTime', "", () =>
                            {
                                props.updateState('toBookHourly', 'startMinute', "", () =>
                                {
                                    props.toggleDateChanged("startDateChanged")
                                });
                            });
                        })
                    }}
                    placeholderText={t("space:startDate")}
                    ref={el => onDatepickerRef(el)}
                    dayClassName={dayClassName}
                    />
                </div>
            </div>
            <div className="fifty">
                <div className="clearfix">
                    <div className="left timepicker">

                        <TimePicker
                        {...startTimeProps}
                        onChangeHandler={(e) => props.updateState('toBookHourly', 'startTime', e)}
                        fieldType="hour"
                        dateChanged={props.startDateChanged}
                        value={props.startTime === "" ? null : props.startTime}
                        />
                    </div>
                    <div className="left timepicker">
                        <TimePicker
                        {...startTimeProps}
                        onChangeHandler={(e) =>
                        {
                            props.updateState('toBookHourly', 'startMinute', e, () =>
                            {
                                props.updateState('toBookHourly', 'endMinute', e)
                            });
                        }}
                        fieldType="minute"
                        endMinute={false}
                        value={props.startMinute === "" ? null : props.startMinute}
                        />
                    </div>
                </div>
            </div>
        </div>

        <h3>{t("space:to")}</h3>
        <div className="wrap clearfix">
            <div className="fifty">
                <div>
                    <DatePicker
                    {...endDateProps}
                    filterDate={filterStartDate}
                    dateFormat="YYYY-MM-DD"
                    selected={props.endDate === "" ? null : props.endDate}
                    onChange={(e) =>
                    {
                        console.log(e);
                        let newState = {
                            'endDate': e,
                            'endTime': ''
                        }
                        props.updateHourlyStates(newState);

                        // props.updateState('toBookHourly', 'endDate', e, () =>
                        // {
                        //     props.updateState('toBookHourly', 'endTime', "", () =>
                        //     {
                        //         // props.updateState('toBookHourly', 'endMinute', "", () =>
                        //         // {
                        //             props.toggleDateChanged("endDateChanged")
                        //         // });
                        //     });
                        // })
                    }}
                    placeholderText={t("space:endDate")}
                    ref={el => onDatepickerRef(el)}
                    dayClassName={dayClassName}
                    />
                </div>
            </div>
            <div className="fifty">
                <div className="clearfix">
                    <div className="left timepicker">

                        <TimePicker
                        {...endTimeProps}
                        onChangeHandler={(e) => props.updateState('toBookHourly', 'endTime', e)}
                        fieldType="hour"
                        value={props.endTime === "" ? null : props.endTime}
                        />
                    </div>
                    <div className="left timepicker">
                        <TimePicker
                        {...endTimeProps}
                        onChangeHandler={(e) => props.updateState('toBookHourly', 'endMinute', e)}
                        fieldType="minute"
                        endMinute={true}
                        value={props.endMinute === "" ? null : props.endMinute}
                        />
                    </div>
                </div>
            </div>
        </div>

        {availability}

        <div className="sep-20"/>

        <table className="verifiedGuarantee">
            <tbody>
            {props.space.isCorp &&
            <tr>
                <td><img src={require('../../images/mobile/01_homepage/icon_' + (props.space.reserved ? '' : 'non-') + 'reserved.png')} alt=""/></td>
                <td><span>{props.space.reserved ? t("space:reserved") : t("space:notreserved")}</span></td>
            </tr>
            }

            <tr>
                <td><img src={require('../../images/mobile/01_homepage/icon_01_02.png')} alt=""/></td>
                <td>{t("space:landSearch")}<span>{t("space:verified")}</span></td>
            </tr>
            <tr>
                <td><img src={require('../../images/mobile/01_homepage/icon_01_03.png')} alt=""/></td>
                <td>{t("space:enjoy")}
                    <Link target="_blank"
                          to={"/" + i18n.language + "/parkerProtectionGuarantee"}>{t("space:protection")}</Link>
                </td>
            </tr>
            </tbody>
        </table>

        <div className="sep-20"/>

        {!props.ownSpace &&
        <Payment
            t={t}
            className={!props.calculation.success ? "grey" : ""}
            calculation={props.calculation}
            insertTempBooking={props.calculation.success ? props.insertTempBooking : () => {}}
        />
        }
    </div>
    );
}

// const mapStateToProps = (state) => (
// 	{
// 		findParkingSpace: state.findParkingSpace
// 	}
// );

export default withTranslation()(DetailHourly);
// connect(mapStateToProps)(DetailHourly);
