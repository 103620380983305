import React from 'react';
import {withTranslation} from 'react-i18next';

import {Link} from 'react-router-dom';

import moment from 'moment';

// import * as Config from '../../config';
// import Verified from "../103Parts/Verified";
// import Heart from "../103Parts/Heart";
// import Review from "../103Parts/Review";
// import CarTypes from "../103Parts/CarTypes";
// import * as Helper from "../../utils/03FindParkingSpace/LowestHourlyPrice";

function Booking(props) {
    const {
        t,
        i18n,
        // type,
        isOwner,
    } = props;

    // console.log(typeof(props.getBooking), props.getBooking);
    // const booking = null;

    let booking = props.booking;

    // console.log(booking);

    if (typeof (booking) === "undefined")
        booking = props.getBooking(props.id);

    if (!booking) {
        return (
            <div className="information align-center">
                <div>{t("common:Loading")}</div>
            </div>
        )
    }

    let status;

    if (props.filterType === "current_booking" || props.filterType === "listing_current_booking") {
        //2019-07-25 samson - update the status logic
        switch (booking.status) {
            case 'pending':
            case 'pendingBooker':
                status = t("myAccount:tabBookings.tab.status.currentBooking.pending");
                break;
            case 'pendingStart':
            case 'processing':
                status = t("myAccount:tabBookings.tab.status.currentBooking.confirmed");
                break;
            default:
                status = booking.status;
        }
    } else if (props.filterType === "past_booking" || props.filterType === "listing_past_booking") {
        //2019-07-25 samson - update the status logic
        switch (booking.status) {
            case 'depositReleasing':
            case 'complete':
            case 'holdDeposit':
            case 'completeWithDeposit':
                status = t("myAccount:tabBookings.tab.status.pastBooking.confirmed");
                break;
            case 'rejected':
            case 'rejectedBooker':
                status = t("myAccount:tabBookings.tab.status.pastBooking.rejected");
                break;
            case 'cancelled':
                status = t("myAccount:tabBookings.tab.status.pastBooking.cancelled");
                break;
            case 'expired':
                status = t("myAccount:tabBookings.tab.status.pastBooking.expired");
                break;
            default:
                status = booking.status;
        }
    }

    let format = "YYYY-MM-DD";
    if (booking.type === "hourly") {
        format = "YYYY-MM-DD HH:mm";
    }

    // return <div>{booking._id}</div>;

    return (
        <div className="information align-center">
            <h4>{status}</h4>

            {booking.status !== "pending" && booking.status !== "pendingBooker" && booking.status !== "rejected" && booking.status !== "rejectedBooker" && booking.status !== "expired" &&
            <a className="get_direction button" target="_blank" rel="noopener noreferrer"
               href={"https://www.google.com/maps/place/" + booking.space.address + "/@" + booking.space.lat + "," + booking.space.lng + ",15z"}>{t("myAccount:tabBookings.tab.getDirection")}</a>
            }

            <dl className="clearfix">
                <h5>{t("myAccount:tabBookings.tab.bookingNumber")}</h5>
                <dt>{booking._id}</dt>
                <dd></dd>

                <div className="sep-10"/>

                <h5>{t('common:bookingPeriod')}</h5>
                <dt>{t("myAccount:tabBookings.tab.from")} {moment(booking.from).format(format)} {t("myAccount:tabBookings.tab.to")} {moment(booking.to).format(format)}</dt>
                <dd></dd>

                <div className="sep-10"/>

                <h5>{t("common:BookingType.title")}</h5>
                <dt>{t("common:BookingType." + booking.type)}</dt>
                <dd></dd>

                <div className="sep-10"/>

                {booking.status !== "pending" && booking.status !== "pendingBooker" && booking.status !== "rejected" && booking.status !== "rejectedBooker" && booking.status !== "expired" && booking.status !== "cancelled" &&
                <div>
                    {typeof (booking.space.floor) !== "undefined" && booking.space.floor !== "" &&
                    <div>
                        <h5>{t("listASpace:stepOne.enterFloor")}</h5>
                        <dt>{booking.space.floor}</dt>
                        <dd/>
                        <div className="sep-10"/>
                    </div>
                    }


                    {typeof (booking.space.spaceno) !== "undefined" && booking.space.spaceno !== "" &&
                    <div>
                        <h5>{t("listASpace:stepOne.enterSpaceNo")}</h5>
                        <dt>{booking.space.spaceno}</dt>
                        <dd/>

                        <div className="sep-10"/>
                    </div>
                    }
                </div>
                }

                <h5>{t("myAccount:tabBookings.tab.parkingFee")}</h5>
                <dt>HK$ {booking.calculation.subtotal}</dt>
                <dd></dd>

                <div className="sep-10"/>

                {typeof (booking.calculation.deposit) !== "undefined" && booking.calculation.deposit > 0 &&
                <div>
                    <h5>{t("myAccount:tabBookings.tab.accessCardDeposit")}</h5>
                    <dt>HK${booking.calculation.deposit}</dt>
                    <dd></dd>

                    <div className="sep-10"/>
                </div>
                }

                <h5>{t("myAccount:tabBookings.tab.parkingDeposit")}</h5>
                <dt>HK$ {booking.calculation.rentaldeposit}</dt>
                <dd></dd>

                <div className="sep-10"/>

                <h5>{t("myAccount:tabBookings.tab.total")}</h5>
                <dt>HK$ {booking.calculation.totalcost}</dt>
                <dd></dd>

                <div className="sep-10"/>

                {!isOwner &&
                (
                    <div>

                        <h5>{props.filterType === 'listing_current_booking' || props.filterType === 'listing_past_booking' ? t("myAccount:tabBookings.tab.parkerName") : t("myAccount:tabBookings.tab.ownerName")}</h5>
                        <dt>
                            {booking.owner.name}
                            &nbsp;&nbsp;&nbsp;
                            <Link to={"/" + i18n.language + "/chat/" + booking.space._id + "/" + booking.booker._id}
                                  className="button message">{t("myAccount:tabBookings.tab.message")}</Link>
                        </dt>
                    </div>
                )
                }

                {isOwner &&
                (
                    <div>
                        <h5>{props.filterType === 'current_listing' ? t("myAccount:tabBookings.tab.ownerName") : t("myAccount:tabBookings.tab.parkerName")}</h5>
                        <dt>
                            {booking.booker.name}
                            &nbsp;&nbsp;&nbsp;
                            <Link to={"/" + i18n.language + "/chat/" + booking.space._id + "/" + booking.booker._id}
                                  className="button message">{t("myAccount:tabBookings.tab.message")}</Link>
                        </dt>
                    </div>
                )
                }

                <div>
                    <div className="sep-10"/>
                    <h5>{t("myAccount:tabBookings.tab.vehicleRegMark")}</h5>
                    <dt className="short">
                        {booking.vehicle.carplate}

                        &nbsp;&nbsp;&nbsp;
                        {booking.status !== "pending" && booking.status !== "pendingBooker" && booking.status !== "rejected" && booking.status !== "rejectedBooker" && booking.status !== 'complete' && booking.status !== 'expired' && booking.status !== 'completeWithDeposit' && booking.status !== 'depositReleasing' &&
                        <div className="button message"

                             onClick={() => {
                                 props.changeVehicleRegMark(booking._id, booking.vehicle._id, booking.vehicle.carplate)
                             }}
                        >
                            {t("myAccount:tabBookings.tab.change")}
                        </div>
                        }
                    </dt>
                </div>

                <div className="sep-10"/>

                {/*<dd className="short"><Link to="" className="button change">Change</Link></dd>*/}

                {/*<div className="sep-20" />*/}

                {/*<Link to="" className="button message">Button</Link>*/}
            </dl>

            <div className={"overlay " + (props.activeVehicleRegMarkBlock ? 'active' : '')}></div>
            <div className={"changeVehicleRegMarkBlock " + (props.activeVehicleRegMarkBlock ? 'active' : '')}>
                <div className="cancel"
                     onClick={() => {
                         props.changeVehicleRegMark('', '', '')
                     }}
                >X
                </div>
                <div className="sep-20"/>
                <div className="text">{t("myAccount:tabBookings.tab.existingVehicleMark")}</div>
                <div className="sep-10"/>
                <input className="input" type="text" value={props.VehicleRegMarkBlockExistCarplate} readOnly/>
                <div className="text">{t("myAccount:tabBookings.tab.newVehicleMark")}</div>
                <div className="sep-10"/>
                <input className="input" type="text"
                       onChange={(e) => {
                           props.changeStateLevelOne("VehicleRegMarkBlockCarplate", e.target.value);
                       }}
                />
                <div className="submit button message"
                     onClick={() => {
                         props.updateVehicleRegMark()
                     }}
                >
                    {t("myAccount:tabBookings.tab.change")}
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(Booking);
