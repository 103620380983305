import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import SocialShare from '../../components/103Parts/SocialShare';

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as InviteeActionCreators from '../actions/invitee';

class MediaDetail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            verified: {
                2: true
            },
            wishlist: {
                1: true
            }
        }
    }

    handleVerified = (id) => {
        this.setState({
            verified: {
                ...this.state.verified,
                [id]: !this.state.verified[id]
            }
        });
    }

    handleWishlist = (id) => {
        this.setState({
            wishlist: {
                ...this.state.wishlist,
                [id]: !this.state.wishlist[id]
            }
        });
    }

    render() {
        const {
            // t, 
            i18n
        } = this.props;

        // console.log("Media ID : " + this.props.media_id);

        return (
            <div className="wrapper-container mediaDetail">

                <div className="page-title"><h2>Media<br />Coverage</h2></div>

                <div className="sep-0" />

                <div className="containerMain">

                    <div className="content clearfix">

                        <Link to={"/" + i18n.language + "/media"} className="backToListing absolute">{"<"}  Back</Link>

                        <h3>What do we use it?</h3>

                        <div className="date">30/11/2019 ∙ Apple Daily</div>


                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus placerat neque eget ex aliquam lobortis. Praesent eu mattis nunc.</p>

                        <img src={require('../../images/mobile/General/dummy-img/img_media.jpg')} alt="" />

                        <p></p>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus placerat neque eget ex aliquam lobortis. Praesent eu mattis nunc.</p>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus placerat neque eget ex aliquam lobortis. Praesent eu mattis nunc.</p>

                        <img src={require('../../images/mobile/General/dummy-img/img_media.jpg')} alt="" />

                        <p></p>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus placerat neque eget ex aliquam lobortis. Praesent eu mattis nunc.</p>

                        <SocialShare


                        />
                    </div>
                </div>
            </div>
        );
    }
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default withTranslation()(MediaDetail);
// connect(mapStateToProps)(About);