import React, {Component} from 'react';
import {Redirect} from 'react-router';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import ReactPixel from "react-facebook-pixel";

const appleIconZh = require('../../images/mobile/03_find_parking_space/icon_app_store_zh.png');
const appleIconEn = require('../../images/mobile/03_find_parking_space/icon_app_store_en.png');
const googleIconZh = require('../../images/mobile/03_find_parking_space/icon_google_play_zh.png');
const googleIconEn = require('../../images/mobile/03_find_parking_space/icon_google_play_en.png');

class FacebookPixel extends Component {

    componentDidMount() {

        ReactPixel.track('Purchase', {
            value: this.props.params.value,
            currency: 'HKD',
            content_type: 'product',
            booking_type: this.props.params.booking_type,
            content_id: this.props.params.space_id,
        });
    }

    render() {
        return (
            <div/>
        )
    }
}

function FindYourSpaceConfirm(props) {
    const {t, i18n} = props;
    if (props.members.Login.length === 0) {
        return <Redirect to={"/" + i18n.language + "/login"}/>;
    }

    const appleIcon =
      i18n.language === 'zh-HK' ? appleIconZh : appleIconEn;

    const googleIcon =
      i18n.language === 'zh-HK' ? googleIconZh : googleIconEn;

    let orderID = "";
    if (typeof (props.params.orderID) !== "undefined")
        orderID = props.params.orderID;

    return (
        <div className="wrapper-container confirmation padding">
            <FacebookPixel
                params={props.params}
            />
            <div className="containerMain">
                <div className="content clearfix">
                    <h3 className="align-center preline">{t("bookingConfirmation:instant:part1", {orderID: orderID})}</h3>

                    {props.params.isBcode === 'true' ? (
                        <div>
                            <p className="align-center preline">{t("bookingConfirmation:instant:part2_bcode")}</p>
                            <p className="align-center preline">{t("bookingConfirmation:instant:bcode")}</p>
                            <p className="align-center preline">{t("bookingConfirmation:instant:part3_bcode")}</p>
                            <p className="align-center preline">
                                <a href="https://apps.apple.com/us/app/lets-park/id1474074159?ls=1" target="_blank">
                                    <img src={appleIcon} style={{display: 'inline', height: 40, marginRight: 5, resizeMode: 'contain'}}/>
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.letspark_rn" target="_blank">
                                    <img src={googleIcon} style={{display: 'inline', height: 40, marginLeft: 5, resizeMode: 'contain'}}/>
                                </a>
                            </p>
                        </div>
                    ) : (
                        <div>
                            <p className="align-center preline">{t("bookingConfirmation:instant:part2")}</p>
                            <p className="align-center preline">{t("bookingConfirmation:instant:part3")}</p>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => (
    {
        members: state.members,
    }
);

export default withTranslation()(connect(mapStateToProps)(FindYourSpaceConfirm));

