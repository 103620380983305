import React, {Component} from 'react';

class Disclaimer_zh_HK extends Component {
    render() {
        return (
            <div className="disclaimer" dangerouslySetInnerHTML={{__html: this.props.content}}></div>
            // <div className="disclaimer">
            //
            //     <ul>
            //         <li>泊車易盡了一切合理的商業努力來確保平台能夠按預期般操作並且不存在漏洞、錯誤或病毒，但由於平台的性質，我們在法律允許的範圍內不能就平台提供的信息或其運作向你給予保證或被視為向你給予保證。</li>
            //     </ul>
            //
            //     <ul>
            //         <li> 雖然泊車易盡了一切合理的商業努力對業主與車主之間的任何爭議進行調解，但我們毋須就《特許協議》的任何一方的任何行為或不作為或任何一方違反《特許協議》承擔任何責任。</li>
            //     </ul>
            //
            //     <ul>
            //         <li>在法律允許的範圍內，泊車易就預約承擔的責任僅限於我們就預約所收到的服務費用，而我們毋須就任何利潤損失、罰款、相應而生的損失或任何形式的損害賠償承擔任何責任，無論該等損失是否各方預計之內以及雙方是否已被告知出現該等損失的可能性。</li>
            //     </ul>
            //
            //     <ul>
            //         <li>如果你已同意本《免責聲明》或《特許協議》的規定為最終且對你具有約束力，則我們毋須就我們根據該等規定而作出的任何事實決定，對你承擔任何責任。</li>
            //     </ul>
            // </div>
        );
    }
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default Disclaimer_zh_HK;
