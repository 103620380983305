import React from 'react';
import {withTranslation} from 'react-i18next';

import {Link} from 'react-router-dom';
// import * as Config from '../../config';
import Verified from "../103Parts/Verified";
import Heart from "../103Parts/Heart";
import Reviews from "../103Parts/Reviews";
import CarTypes from "../103Parts/CarTypes";
// import * as Helper from "../../utils/03FindParkingSpace/LowestHourlyPrice";
import Booking from "./Booking";
import * as Helper from "../../utils/03FindParkingSpace/LowestHourlyPrice";
import {renderListingTitle} from "../../utils/00General/Helpers";

// import {filter} from 'lodash';

function SpaceWBooking(props) {
    const {
        t,
        i18n,
        space,
        bookings,
        type,
        isOwner,
        // popup = false,
        // showBooking = false
    } = props;

    let lowestPrice = 0,
        lowestType = 'Hour';
    if (typeof (space.data.hourly.from) !== "undefined") {
        lowestPrice = Helper.lowestPrice(space.data.hourly.days);
    } else if (typeof (space.data.monthly.from) !== "undefined") {
        lowestPrice = space.data.monthly.price;
        lowestType = 'Month';
    } else if (typeof (space.data.timeshare.from) !== "undefined") {
        lowestPrice = space.data.timeshare.price;
        lowestType = 'Month';
    }

    //2019-08-14 Samson show the lowest price base on booking type
    if (Object.keys(bookings).length > 0) {
        Object.keys(bookings).forEach((key) => {
            let item = bookings[key];
            if (typeof(item.type) !== "undefined") {
                if (item.type === "monthly" || item.type === "timeshare") {
                    lowestType = 'Month';
                    lowestPrice = space.data.monthly.price;

                    if (item.type === "timeshare")
                        lowestPrice = space.data.timeshare.price;

                } else if (item.type === "hourly") {
                    lowestType = 'Hour';
                    lowestPrice = Helper.lowestPrice(space.data.hourly.days);
                }
                return false;
            }
        });
    }

    return (
        <div className={"ProfileSpace clearfix " + (props.count === 0 ? 'first' : '')}>
            <div className="image"
                 style={space.data.images ? {backgroundImage: "url(" + space.data.images[0] + ")"} : null}
            >
                <Verified
                    id={space.data._id}
                    verified={false}
                />
                <Heart
                    id={space.data._id}

                />
            </div>
            <div className="text">
                <Link to={"/" + i18n.language + "/space/" + space.data._id}>
                    <h3>{renderListingTitle(space.data,i18n.language)}</h3>
                </Link>
                <h4>{i18n.language === 'zh-HK' && space.data.address_tc ? space.data.address_tc : space.data.address}</h4>
                {lowestPrice > 0 &&
                <div>
                    <div className="sep-5"/>
                    <div className="price">
                        {t("search:pricePer" + lowestType, {price: lowestPrice})}

                        {space.data.instant &&
                        <div className="instantBooking"/>
                        }
                        {space.bcode &&
                        <div className="bcode"/>
                        }
                    </div>
                </div>
                }
                <div className="sep-10"/>
                <Reviews score={space.data.rating} reviews={space.data.reviews.length}/>
                <div className="sep-10"/>
                <CarTypes
                    carTypes={space.data.cartype}
                    features={space.data.features}
                />

                {type === "wishlist" &&
                <Link to={"/" + i18n.language + "/space/" + space.data._id}
                      className="button">{t("search:bookNow")}</Link>
                }

                {type === "my_listing" &&
                <Link to={"/" + i18n.language + "/space/" + space.data._id}
                      className="button">{t("myAccount:tabListings.tab.edit")}</Link>
                }
            </div>

            <div>
                <div className="sep-20"/>

                {(Object.keys(bookings).map((key) => {
                        if (space.data._id === bookings[key].space._id) {
                            return (
                                <Booking
                                    key={key}
                                    id={bookings[key]._id}
                                    from={bookings[key].from}
                                    to={bookings[key].to}
                                    type={props.type}
                                    isOwner={isOwner}
                                    booking={bookings[key]}
                                    activeVehicleRegMarkBlock={props.activeVehicleRegMarkBlock}
                                    VehicleRegMarkBlockExistCarplate={props.VehicleRegMarkBlockExistCarplate}
                                    changeVehicleRegMark={props.changeVehicleRegMark}
                                    updateVehicleRegMark={props.updateVehicleRegMark}
                                    changeStateLevelOne={props.changeStateLevelOne}
                                    filterType={props.filterType}
                                />
                            )
                        }
                        return null;
                    }
                ))}
            </div>
        </div>
    )
    //
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         isOwner: props.isOwner
    //     }
    // }
    //
    // render() {
    //     const {
    //         t,
    //         i18n,
    //         space
    //     } = this.props;
    //
    //     const lowestPrice = Helper.lowestPrice(space.hourly.days);
    //
    //     return (
    //         <div className="profile-space-list">
    //             <div className="ListType3 clearfix">
    //                 <div className="image"
    //                      style={space.images ? { backgroundImage: "url(" + space.images[0] + ")" } : null}
    //                 >
    //                     <Verified />
    //                     <Heart id={space._id} />
    //                 </div>
    //
    //                 <div className={"text " + (this.state.isOwner ? "hasActions" : "")}>
    //                     <Link to={"/" + i18n.language + "/space/" + space._id}>
    //                         <h3>{space.title}</h3>
    //                     </Link>
    //
    //                     <Link to={"/" + i18n.language + "/space/" + space._id}>
    //                         <h4>{space.address}</h4>
    //                     </Link>
    //
    //                     {lowestPrice &&
    //                         <div>
    //                             <div className="sep-5" />
    //                             <div className="price">HKD {lowestPrice} {t("myAccount:perHour")}</div>
    //                         </div>
    //                     }
    //
    //                     <div className="sep-10" />
    //                     <Review score="0" reviews="0" />
    //
    //                     {!this.state.isOwner &&
    //                     <div>
    //                         <div className="sep-5" />
    //                         <span className="owner">{space.owner.name}</span>
    //                     </div>
    //                     }
    //
    //                     <div className="sep-10" />
    //                     <CarTypes carTypes={space.cartype} />
    //                 </div>
    //
    //                 {this.state.isOwner &&
    //                 <div className="actions">
    //                     {/*Space ID #{space._id}*/}
    //                     <Link to={"/" + i18n.language + "/space/" + space._id} className="button edit">{t("myAccount:edit")}</Link>
    //                 </div>
    //                 }
    //
    //                 <div className="sep-0" />
    //
    //                 {/*<div className="information align-center">*/}
    //                 {/*    <h4>Confirmed*</h4>*/}
    //                 {/*    <dl className="clearfix">*/}
    //                 {/*        <h5>Booking number</h5>*/}
    //                 {/*        <dt>#B221508</dt>*/}
    //                 {/*        <dd></dd>*/}
    //
    //                 {/*        <div className="sep-10" />*/}
    //
    //                 {/*        <h5>Booking period</h5>*/}
    //                 {/*        <dt>from 2018-03-16 00:00 to 2018-03-17 00:00</dt>*/}
    //                 {/*        <dd></dd>*/}
    //
    //                 {/*        <div className="sep-10" />*/}
    //
    //                 {/*        <h5>Fee type</h5>*/}
    //                 {/*        <dt>Monthly parking</dt>*/}
    //                 {/*        <dd></dd>*/}
    //
    //                 {/*        <div className="sep-10" />*/}
    //
    //                 {/*        <h5>Floor number</h5>*/}
    //                 {/*        <dt>2/f</dt>*/}
    //                 {/*        <dd></dd>*/}
    //
    //                 {/*        <div className="sep-10" />*/}
    //
    //                 {/*        <h5>Space number</h5>*/}
    //                 {/*        <dt>246</dt>*/}
    //                 {/*        <dd></dd>*/}
    //
    //                 {/*        <div className="sep-10" />*/}
    //
    //                 {/*        <h5>Total</h5>*/}
    //                 {/*        <dt>HKD 3,200</dt>*/}
    //                 {/*        <dd></dd>*/}
    //
    //                 {/*        <div className="sep-10" />*/}
    //
    //                 {/*        <h5>Owner's name</h5>*/}
    //                 {/*        <dt className="short">Joe Lo (tel 6025 8669)</dt>*/}
    //                 {/*        <dd className="short"><Link to="" className="button message">Message</Link></dd>*/}
    //
    //                 {/*        <div className="sep-10" />*/}
    //
    //                 {/*        <h5>Vehicle registration mark</h5>*/}
    //                 {/*        <dt className="short">AB 2014</dt>*/}
    //                 {/*        <dd className="short"><Link to="" className="button change">Change</Link></dd>*/}
    //
    //                 {/*        <div className="sep-20" />*/}
    //
    //                 {/*        <Link to="" className="button message">Button</Link>*/}
    //                 {/*    </dl>*/}
    //                 {/*</div>*/}
    //             </div>
    //         </div>
    //     )
    // }
}

export default withTranslation()(SpaceWBooking);
