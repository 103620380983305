import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

class SpaceSuitableFor extends Component {
    render() {

        const {
            t,
            // i18n
            tones
        } = this.props;

        return (
            <ul className="attributes small-icon">
                {this.props.cartype.indexOf("PrivateCar") > -1 ?
                    <li><img src={require('../../images/mobile/03_find_parking_space/icon_car_1.png')}
                             alt=""/>{t("space:tabDetails.suitable.options.privateCar")}</li> : ''}
                {this.props.cartype.indexOf("Van") > -1 ?
                    <li><img src={require('../../images/mobile/03_find_parking_space/icon_car_2.png')}
                             alt=""/>{t("space:tabDetails.suitable.options.van")}
                    </li> : ''}
                {this.props.cartype.indexOf("Motorcycle") > -1 ?
                    <li><img src={require('../../images/mobile/03_find_parking_space/icon_car_3.png')}
                             alt=""/>{t("space:tabDetails.suitable.options.motorcycle")}
                    </li> : ''}
                {this.props.cartype.indexOf("Lorry") > -1 ?
                    <li><img src={require('../../images/mobile/03_find_parking_space/icon_car_4.png')}
                             alt=""/>{t("space:tabDetails.suitable.options.lorry")} ({tones + t("listASpace:stepOne.spaceSuitable.tones")})
                    </li> : ''}
                {this.props.cartype.indexOf("Others") > -1 ?
                    <li><img src={require('../../images/mobile/03_find_parking_space/icon_car_5.png')}
                             alt=""/>{t("space:tabDetails.suitable.options.others")}
                    </li> : ''}
            </ul>
        )
    }
}

export default withTranslation()(SpaceSuitableFor);