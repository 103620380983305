import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import MemberMenuDesktop from '../../../components/02Members/MemberMenuDesktop';
import MemberMenuMobile from '../../../components/02Members/MemberMenuMobile';
import GridCredit from '../../../components/102Grids/GridCredit';
import GridRedeem from '../../../components/02Members/GridRedeem';

// import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import * as MembersActionCreators from '../../actions/members';

function ClusterRedeem() {
    let rows = [];
    for (let i = 0; i < 2; i++) {
        rows.push(
            <div className="fifty left" key={i} >
                <GridRedeem />
            </div>
        )
    }
    return (rows);
}

function ShareWithFriends({ t }) {
    return (
        <div className="shareWithFriends">
            <div className="centerBox">
                <h3>Share Let's Park with Friends</h3>

                <div className="sep-10" />

                <p>Refer friends to Let's Park along with exciting rewards! <br />The will get 5 LP points and you will get 5 LP points too once they complete a booking! Share Now!</p>

                <div className="wrapper-share-your-link">

                    <ul className="share-your-link clearfix">
                        <li><div>Share via email</div></li>
                        <li><div>Share to Gmail</div></li>
                        <div className="sep-0" />
                        <li><div>Share on Messenger</div></li>
                        <li><div>Share to Twitter</div></li>
                    </ul>
                </div>

                <div className="sep-20" />

                <Link to="/" className="button">Read the terms</Link>
            </div>
        </div>
    )
}

function ClusterCredit() {
    let rows = [];
    for (let i = 0; i < 6; i++) {
        rows.push(
            <div className="fifty left" key={i} >
                <GridCredit />
            </div>
        )
    }
    return (rows);
}

function MemberCredit({ t }) {
    return (
        <div className="wrapper-container memberCredit">
            <MemberMenuMobile />
            <div className="containerMain">
                <MemberMenuDesktop />

                <div className="wrapper-redeem">
                    <h4>You need <span>20</span> more points to redeem:</h4>

                    <div className="redeem clearfix">
                        <ClusterRedeem />
                    </div>
                </div>

                <ShareWithFriends />

                <div className="content clearfix">
                    <ClusterCredit />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => (
    {
        members: state.members
    }
);

export default withTranslation()(connect(mapStateToProps)(MemberCredit));
