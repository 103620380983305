import React, {Component} from 'react';
// import {Redirect} from 'react-router';
import {withTranslation} from 'react-i18next';

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as MembersActionCreators from "../../../actions/members";
// import * as UserApi from "../../../utils/04Api/User";

import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';
import ErrorMessage from '../../../components/103Parts/ErrorMessage';
import {updateUserInfo} from "../../../utils/04Api/User";

class MemberSettingsPayout extends Component
{
    constructor(props)
    {
        super(props);

        this.state={
            submitting: false,
            saved: false,
        }

        // Form Validation
        this.schema=Yup.object().shape({
            acc_num: Yup.string()
            .required("payment:errors.bankaccount")
            .matches(/^\d{9,12}$/, "payment:errors.bankaccount"),
            acc_name: Yup.string()
            .required("payment:errors.payee"),
            backname: Yup.string()
            .required("payment:errors.bankname"),
/*            bankcode: Yup.string()
            .required("payment:errors.bankcode")
            .matches(/^\d{3}$/, "payment:errors.bankcode"),*/
        })
    }

    setSubmitting=(submitting, cb) =>
    {
        if (typeof (cb) !== "function")
            cb=() =>
            {
            };

        this.setState({
            ...this.state,
            submitting: submitting,
        }, cb)
    }

    form=({values, errors, touched}) =>
    {
        const {t}=this.props;
        //
        // console.log('errors', errors);
        return (
        <Form>
            <div className="form_wrapper">
                <div className="wrap clearfix">
                    <div className="full">
                        <div>
                            <h5>{t("myAccount:tabSettings.payout.accountNumber")}</h5>
                            <Field type="text" name="acc_num" maxLength="12"/>
                            {errors.acc_num && touched.acc_num ?
                            <ErrorMessage message={t(errors.acc_num)}/> : null}
                        </div>
                    </div>
                </div>
                {/*<div className="fifty  mobile-full left">
                        <div className="wrap clearfi">
                            <div className="full">
                                <div>
                                    <h5>{t("myAccount:tabSettings.payout.accountNumber")}</h5>
                                    <Field type="text" name="acc_num" maxLength="12"/>
                                    {errors.acc_num && touched.acc_num ?
                                        <ErrorMessage message={t(errors.acc_num)}/> : null}
                                </div>
                            </div>
                        </div>

                        <div className="wrap clearfix">
                            <div className="full">
                                <div>
                                    <h5>{t("myAccount:tabSettings.payout.bankCode")}</h5>
                                    <Field type="text" name="bankcode" maxLength="3"/>
                                    {errors.bankcode && touched.bankcode ?
                                        <ErrorMessage message={t(errors.bankcode)}/> : null}
                                </div>
                            </div>
                        </div>
                    </div>*/}
                <div className="full clearfix">
                    <div className="fifty  mobile-full left">
                        <div className="wrap clearfix">
                            <div className="full">
                                <div>
                                    <h5>{t("myAccount:tabSettings.payout.bankName")}</h5>
                                    <Field type="text" name="backname" maxLength="50"/>
                                    {errors.backname && touched.backname ?
                                    <ErrorMessage message={t(errors.backname)}/> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fifty  mobile-full right">
                        <div className="wrap clearfix">
                            <div className="full">
                                <div>
                                    <h5>{t("myAccount:tabSettings.payout.payeeName")}</h5>
                                    <Field type="text" name="acc_name" maxLength="50"/>
                                    {errors.acc_name && touched.acc_name ?
                                    <ErrorMessage message={t(errors.acc_name)}/> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="full clearfix">
                    <div className="fifty mobile-full right">
                        <div className="wrap clearfix">
                            {errors.general ? <div className="wrap clearfix text-right">
                                <ErrorMessage message={t(errors.general)}/>
                                <div className="sep-20"/>
                                &nbsp;
                            </div> : null}

                            <div className="wrap clearfix">
                                <div className="fifty">
                                </div>
                                <div className="fifty right">
                                    <div>
                                        <div className="sep-20"/>
                                        <button type="submit"
                                                className="button bookNow">{t("myAccount:update")}</button>

                                        {this.state.saved &&
                                        <div className="text-center">
                                            <div className="sep-20"/>
                                            {t("myAccount:updated")}
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
        )
    };

    handleSubmit=(values) =>
    {
        console.log("SUBMIT");
        if (!this.state.submitting)
        {
            console.log(values);
            this.setSubmitting(true, () =>
            {
                let number='';
                for (let i=0; i<(values.acc_num.length - 4); i++)
                    number+="x";

                number+=values.acc_num.substr(-4, 4);

                let data={
                    payout: {
                        backname: values.backname,
                        acc_num: values.acc_num,
                        acc_name: values.acc_name,
                        bankcode: values.bankcode,
                    },
                    card: "N3",
                    cardData: {
                        number: number,
                    }
                };

                if (this.props.payout)
                    data.payout._id=this.props.payout._id;

                updateUserInfo(this.props.accessToken, data, () =>
                {
                    this.props.getMe(() =>
                    {
                        this.setState({
                            ...this.state,
                            saved: true,
                        }, () =>
                        {
                            this.setSubmitting(false);
                        })

                        setTimeout(() =>
                        {
                            this.setState({
                                ...this.state,
                                saved: false,
                            })
                        }, 2000);
                    })
                });
            })
        }
    }

    render()
    {
        const {
            t,
            // i18n,
            payout,
        }=this.props;

        return (
        <div className="memberPayment clearfix">
            <div className="full left">
                <div>
                    <h4>{t("myAccount:tabSettings.payout.desc")}</h4>
                    <div className="sep-25"/>
                    <div className="border-box clearfix">
                        <div className="wrapper">
                            <Formik
                            initialValues={{
                                backname: (payout ? payout.backname : ''),
                                acc_num: (payout ? payout.acc_num : ''),
                                acc_name: (payout ? payout.acc_name : ''),
                                bankcode: (payout ? payout.bankcode : ''),
                            }}
                            validationSchema={this.schema}
                            onSubmit={this.handleSubmit}
                            component={this.form}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default withTranslation()(MemberSettingsPayout);
