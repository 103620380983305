import React, { Component } from 'react';

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as InviteeActionCreators from '../actions/invitee';

class Contact extends Component {

	render() {
		return (
			<div>
				404
			</div>
		);
	}
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default Contact;
// connect(mapStateToProps)(Contact);
