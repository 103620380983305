import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
import {withTranslation} from 'react-i18next';
// import {mongoIdTimestamp} from "../../utils/00General/Helpers";
// import {getBookingById} from "../../utils/04Api/Booking";
import {connect} from 'react-redux';
import moment from "moment";

import M1 from './Cards/M1';
import M2 from './Cards/M2';
import M3 from './Cards/M3';
import M4 from './Cards/M4';
import M5 from './Cards/M5';
import M6 from './Cards/M6';
import M7 from './Cards/M7';
import M9 from './Cards/M9';
import M10 from './Cards/M10';
import M11 from './Cards/M11';
import M21 from './Cards/M21';
import M22 from './Cards/M22';
import M23 from './Cards/M23';

import * as Helper from "../../utils/03FindParkingSpace/Calculations";

class Card extends Component
{
    constructor(props)
    {
        super(props);

        this.state={
            myId: props.members.Login.user._id,
            err: false,
        }

        this.renderSpace=this.renderSpace.bind(this);
    }

    // componentDidMount() {
    //     const data = this.props.message.data;
    //     if (typeof(data) !== "undefined" && typeof(data.booking_id) !== "undefined") {
    //         // get booking detail
    //         const cb = (res)=> {
    //             // console.log("booking", res.body);
    //             this.setState({
    //                 ...this.state,
    //                 loading: false,
    //                 booking: res.body
    //             })
    //         }
    //
    //         const rCb = (err) => {
    //             this.setState({
    //                 ...this.state,
    //                 err: true,
    //             })
    //         }
    //
    //         getBookingById(this.props.members.Login.userToken, data.booking_id, cb, rCb);
    //     } else {
    //         this.setState({
    //             ...this.state,
    //             loading: false,
    //         })
    //     }
    // }

    renderSpace()
    {
        const {
            t,
            i18n,
            space,
            booking,
        }=this.props;

        const dateRegex = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;
        if (dateRegex.test(booking.from)) booking.from = booking.from + "T00:00";
        if (dateRegex.test(booking.to)) booking.to = booking.to + "T23:59";

        let from=moment(String(booking.from) + "+0800"),
        to=moment(String(booking.to) + "+0800");
        let spaceName=space.title;

        if(i18n.language==='zh-HK')
            spaceName=space.title_tc;

        return (
        <div>
            <div className="image">
                {space.images.length>0 &&
                <img src={space.images[0]} alt=""/>
                }

                {space.images.length === 0 &&
                <img src={require('../../images/blank.png')} alt=""/>
                }
            </div>

            <div className="text">
                <h3>{spaceName}</h3>
                <h4>{i18n.language === 'zh-HK' && space.address_tc ? space.address_tc : space.address}</h4>


                {booking.type === "hourly" &&
                <span>{t("messenger:general.period")}: {from.format("YYYY-MM-DD HH:mm")} - {to.format("YYYY-MM-DD HH:mm")}</span>
                }
                {booking.type !== "hourly" &&
                <span>{t("messenger:general.period")}: {from.format("YYYY-MM-DD")} - {to.format("YYYY-MM-DD")}</span>
                }

                <span>{t("messenger:general.booking_type")}: {t("messenger:general.booking_type_" + booking.type)}</span>

                {/*<span>Total HKD 29,300</span>*/}
                {/*<span>Service fee HKD 879</span>*/}
                <span>{t("messenger:general.total")}: {Helper.numeralMoney(booking.cost)}</span>
            </div>
        </div>
        )
    }

    render()
    {

        const {
            t,
            // i18n,
            message,
            space,
            booking,
            toUserName,
            toUser,
            user
        }=this.props;

        if (this.state.err)
            return null

        if (!booking && message.card !== 'M7')
        {
            return (
            <div className="response">
                <div className="info clearfix">
                    {t("common:Loading")}
                </div>
            </div>
            )
        }
        else
        {

        }

        // console.log('bk', message.data.booking_id, booking._id);

        // const booking = booking;

        const isOwner=space.owner._id === this.state.myId;

        // console.log(message);

        switch (message.card)
        {
            case "M1":
                return <M1
                message={message}
                space={space}
                booking={booking}
                isOwner={isOwner}
                toUser={toUser}
                user={user}
                renderSpace={this.renderSpace}
                />

            case "M2":
                return <M2
                message={message}
                space={space}
                booking={booking}
                isOwner={isOwner}
                renderSpace={this.renderSpace}
                />

            case "M3":
                return <M3
                message={message}
                space={space}
                booking={booking}
                isOwner={isOwner}
                renderSpace={this.renderSpace}
                />

            case "M4":
                return <M4
                message={message}
                space={space}
                booking={booking}
                isOwner={isOwner}
                renderSpace={this.renderSpace}
                />

            case "M5":
                return <M5
                message={message}
                space={space}
                booking={booking}
                isOwner={isOwner}
                renderSpace={this.renderSpace}
                />

            case "M6":
                return <M6
                message={message}
                space={space}
                booking={booking}
                isOwner={isOwner}
                renderSpace={this.renderSpace}
                />

            case "M7":
                return <M7
                message={message}
                isOwner={isOwner}
                toUserName={toUserName}
                />

            case "M9":
                return <M9
                message={message}
                space={space}
                booking={booking}
                isOwner={isOwner}
                renderSpace={this.renderSpace}
                />

            case "M10":
                return <M10
                message={message}
                space={space}
                booking={booking}
                isOwner={isOwner}
                renderSpace={this.renderSpace}
                />

            case "M11":
                return <M11
                    message={message}
                    space={space}
                    booking={booking}
                    isOwner={isOwner}
                    renderSpace={this.renderSpace}
                />

            case "M21":
                return <M21
                    message={message}
                    space={space}
                    booking={booking}
                    isOwner={isOwner}
                    renderSpace={this.renderSpace}
                />

            case "M22":
                return <M22
                    message={message}
                    space={space}
                    booking={booking}
                    isOwner={isOwner}
                    renderSpace={this.renderSpace}
                />

            case "M23":
                return <M23
                    message={message}
                    space={space}
                    booking={booking}
                    isOwner={isOwner}
                    renderSpace={this.renderSpace}
                />

            default:
                return <div>{message.card}</div>
        }
    }
}


const mapStateToProps=(state) => (
{
    members: state.members,
}
);

export default withTranslation()(connect(mapStateToProps)(Card));
