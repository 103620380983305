import React from 'react';
// import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import * as Config from '../../../config';

import {connect} from 'react-redux';

import {RegisterTnc, RegisterToLogin} from '../../../components/02Members/MemberRegister';
import ReactPixel from "react-facebook-pixel";

function MemberCreateAccountStart(props) {
    const {
        t,
        i18n
    } = props;

    ReactPixel.trackCustom('Register', {category_type: 'Individual'});

    return (
        <div className="wrapper-container memberLogin padding">

            <div className="containerMain borderTop">
                <div className="content clearfix">
                    <div className="full left align-center">
                        <span className="door"/>
                        <h2>{t("registerStart:start")}</h2>
                    </div>

                    <div className="sep-20"/>

                    <dl className="generalForm clearfix">
                        <div>
                            <dt/>
                            <dd>
                                {/*<FacebookLogin*/}
                                {/*	appId={Config.UAT_FACEBOOK_APP_ID}*/}
                                {/*	autoLoad={false}*/}
                                {/*	fields="name,email,picture"*/}
                                {/*	callback={this.props.responseFacebook}*/}
                                {/*	render={renderProps => (*/}
                                {/*		<button className="button facebook" onClick={renderProps.onClick}>Register with Facebook</button>*/}
                                {/*	)}*/}
                                {/*/>*/}
                                <a href={Config.API_URL + "auth/facebook"} className="button facebook">
                                    <div className="register_icon">
                                        <img
                                            src={require('../../../images/mobile/02_membership/00_signin/facebook.png')}
                                            alt=""/>
                                    </div>
                                    {t("registerStart:registerFacebook")}
                                </a>
                            </dd>
                        </div>

                        <div className="sep-25"/>

                        <div>
                            <dt/>
                            <dd>
                                {/*<GoogleLogin*/}
                                {/*	clientId={Config.UAT_GOOGLE_PLUS_clientId}*/}
                                {/*	render={renderProps => (*/}
                                {/*		<button className="button googlePlus" onClick={renderProps.onClick}>Register with Google +</button>*/}
                                {/*	)}*/}
                                {/*	buttonText="Register with Google +"*/}
                                {/*	onSuccess={this.props.responseGoogle}*/}
                                {/*	onFailure={this.props.responseGoogle}*/}
                                {/*/>*/}
                                <a href={Config.API_URL + "auth/google"} className="button googlePlus">
                                    <div className="register_icon">
                                        <img
                                            src={require('../../../images/mobile/02_membership/00_signin/google.png')}
                                            alt=""/>
                                    </div>
                                    {t("registerStart:registerGoogle")}
                                </a>
                            </dd>
                        </div>

                        <div className="sep-25"/>

                        <div>
                            <dt/>
                            <dd>
                                <a href={"/" + i18n.language + "/memberCreateAccount"}
                                      className="button registerWithEmail">
                                    <div className="register_icon">
                                        <img
                                            src={require('../../../images/mobile/02_membership/00_signin/email.png')}
                                            alt=""/>
                                    </div>
                                    {t("registerStart:registerEmail")}
                                </a>
                            </dd>
                        </div>

                        <RegisterTnc t={t} i18n={i18n}/>

                        <RegisterToLogin t={t} i18n={i18n}/>
                    </dl>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => (
    {
        members: state.members
    }
);

export default withTranslation()(connect(mapStateToProps)(MemberCreateAccountStart));
