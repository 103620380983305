import React, { Component } from 'react';

class CarTypes extends Component {
    render() {
        return (
            <ul className="carTypes clearfix">
                {this.props.features.indexOf('Indoor') > -1 ? <li className="one"></li> : ''}
                {this.props.features.indexOf('WithCovers') > -1 ? <li className="two"></li> : ''}
                {this.props.features.indexOf('NoCovers') > -1 ? <li className="three"></li> : ''}
                {this.props.features.indexOf('EV') > -1 ? <li className="four"></li> : ''}

                {this.props.carTypes.indexOf('PrivateCar') > -1 ? <li className="PrivateCar"></li> : ''}
                {this.props.carTypes.indexOf('Van') > -1 ? <li className="Van"></li> : ''}
                {this.props.carTypes.indexOf('Motorcycle') > -1 ? <li className="Motorcycle"></li> : ''}
                {this.props.carTypes.indexOf('Lorry') > -1 ? <li className="Lorry"></li> : ''}
                {this.props.carTypes.indexOf('Others') > -1 ? <li className="Others"></li> : ''}
            </ul>
        )
    }
}

export default CarTypes;
