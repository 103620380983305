import {apiPost} from "./General";
import CryptoJS from 'crypto-js';

export const saveCreditCard = (accessToken, data, userId, sendNotification, callback, errorCallback) => {
    const dataToEncrypt = {
        expiryDateMM: data.expiryDateMM,
        expiryDateYY: data.expiryDateYY,
        cardNumber: data.cardNumber,
        CVC: data.CVC,
    }

    const date = new Date(),
        ts = date.getTime(),
        k = userId + ts;

    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(dataToEncrypt), k);
    const body = {
        data: ciphertext.toString(),
        ts: ts,
        sendNotification: sendNotification
    }
    apiPost("payment/token?access_token=" + accessToken, body, callback, errorCallback);
}

// export const paybytoken = (accessToken, data, callback, errorCallback) => {
//     const body = {
//         orderId: data.orderID,
//         tranId: data.transactionID,
//         amt: data.total,
//         securityCode: data.CVC,
//         paymentID: data.paymentID,
//     }
//     apiPost("payment/paybytoken?access_token=" + accessToken, body, callback, errorCallback);
// }
