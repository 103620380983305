import React from 'react';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {mongoIdTimestamp} from "../../../utils/00General/Helpers";

function M11(props) {
    const {
        t,
        // i18n,
        message,
        // space,
        booking,
        isOwner,
        renderSpace
    } = props;

    if (isOwner) {
        return (
            <div className="response">
                <div className="info border clearfix">
                    <h5>{mongoIdTimestamp(message._id, "LLL")} </h5>

                    { renderSpace() }
                </div>

                <div className="centerBox">
                    <h3>
                        {t("messenger:cards.M11.owner.headline", {booker: booking.booker.name})}
                    </h3>

                    <div className="sep-10" />

                    <p className="preline">
                        {t("messenger:cards.M11.owner.p1", {booker: booking.booker.name})}
                    </p>
                </div>
            </div>
        )
    } else {
        return (
            <div className="response">
                <div className="info border clearfix">
                    <h5>{mongoIdTimestamp(message._id, "LLL")} </h5>

                    { props.renderSpace() }
                </div>

                <div className="centerBox">
                    <h3>
                        {t("messenger:cards.M11.booker.headline")}
                    </h3>

                    <div className="sep-10" />

                    <p className="preline">
                        {t("messenger:cards.M11.booker.p1", {owner: booking.owner.name})}
                    </p>
                </div>
            </div>
        )
    }
}
export default withTranslation()(M11);