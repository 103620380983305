import React from 'react';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import DatePicker from "react-datepicker";
import moment from "moment";

import LocationSearchInput from '../../components/04ListASpace/LocationSearchInput';

import 'moment/locale/zh-hk';

function SwitchTypeIcon(props) {

    switch (props.type) {
        case 'hourly':
            return (
                <img src={require('../../images/mobile/03_find_parking_space/icon_type_3_Hourly.png')} alt=""/>
            );
        case 'monthly':
            return (
                <img src={require('../../images/mobile/03_find_parking_space/icon_type_1_monthly.png')} alt=""/>
            );
        case 'timeshare':
            return (
                <img src={require('../../images/mobile/03_find_parking_space/icon_type_2_TimeShare.png')} alt=""/>
            );
        default:
            return (
                <img src={require('../../images/mobile/03_find_parking_space/icon_type_3_Hourly.png')} alt=""/>
            );
    }
}

function HomeSearch(props) {

    const {
        t,
        i18n,
    } = props;

    moment.locale('en');

    if (i18n.language === 'zh-HK') {
        moment.locale('zh-hk');
    }

    let onDatepickerRef = (el) => {
        if (el && el.input) {
            el.input.readOnly = true;
        }
    }

    // Address auto complete with Google Map

    const handleChange = (address) => {
        props.changeStateLevelTwo('search', 'where', address);
    };

    const latLngHandler = (latLng, address) => {
        const data = {
            lat: latLng.lat,
            lng: latLng.lng,
            where: address
        };

        props.changeStateLevelTwoArray('search', data);
    }

    const handleMapNoResult = (address) => {
        const data = {
            lat: null,
            lng: null,
            where: address
        };

        props.changeStateLevelTwoArray('search', data);
    }

    //
    // let handleChange = (address) => {
    //     console.log("handleChange", address);
    //     // this.setState({ address });
    //     props.changeStateLevelTwo('search', 'where', address);
    //
    //     geocodeByAddress(address)
    //         .then(results => getLatLng(results[0]))
    //         .then(latLng => {
    //             latLngHandler(latLng, address);
    //         });
    // };

    // let handleSelect = (address) => {
    //     console.log("handleSelect", address);
    //
    //     props.changeStateLevelTwo('search', 'where', address);
    //
    //     geocodeByAddress(address)
    //         .then(results => getLatLng(results[0]))
    //         .then(latLng => {
    //             latLngHandler(latLng, address);
    //         });
    //     //.catch(error => console.error('Error', error));
    // };
    //
    // let latLngHandler = (latLng, address) => {
    //     console.log("latLngHandler", address, latLng);
    //     //console.log("StepOne", latLng);
    //     const data = {
    //         lat: latLng.lat,
    //         lng: latLng.lng,
    //         where: address
    //     };
    //
    //     props.changeStateLevelTwoArray('search', data);
    // }

    return (
        <div className="wrapper-search">
            <div className="search clearfix">
                <div className="wrap clearfix">
                    <div className="oneThird left">
                        <div>
                            <h5>{t("homepage:searchBlock.type.title")}</h5>
                            <div>
                                <div className="twenty left">
                                    <div className="typeIcon">
                                        <SwitchTypeIcon
                                            type={props.search.type}
                                        >
                                        </SwitchTypeIcon>
                                    </div>
                                </div>
                                <select className="eighty left"
                                        value={props.type}
                                        onChange={(e) => props.changeStateLevelTwo('search', 'type', e.target.value)}>
                                    <option value="hourly">{t("homepage:searchBlock.type.options.hourly")}</option>
                                    <option value="monthly">{t("homepage:searchBlock.type.options.monthly")}</option>
                                    <option value="timeshare">{t("homepage:searchBlock.type.options.timeShare")}</option>
                                </select>
                            </div>
                            <div className="vertical-sep"></div>
                        </div>
                    </div>

                    <div className="sep-0 toShow"></div>

                    <div className="oneThird left">
                        <div>
                            <h5>{t("homepage:searchBlock.when.title")}</h5>
                            <DatePicker
                                dateFormat="YYYY-MM-DD"
                                selected={props.search.DateStart === "" ? null : props.search.DateStart}
                                onChange={(e) => props.changeStateLevelTwo('search', 'DateStart', e)}
                                minDate={moment()}
                                // maxDate={props.search.DateEnd ? moment(props.search.DateEnd) : null}
                                placeholderText={t("homepage:searchBlock.when.from")}
                                ref={el => onDatepickerRef(el)}
                            />
                            <div className="vertical-sep"></div>
                        </div>
                    </div>
                    <div className="oneThird left">
                        <div>
                            <h5 className="toHide">&nbsp;</h5>
                            <DatePicker
                                dateFormat="YYYY-MM-DD"
                                selected={props.search.DateEnd === "" ? null : props.search.DateEnd}
                                onChange={(e) => props.changeStateLevelTwo('search', 'DateEnd', e)}
                                minDate={props.search.DateStart ? moment(props.search.DateStart) : moment()}
                                placeholderText={t("homepage:searchBlock.when.until")}
                                ref={el => onDatepickerRef(el)}
                            />
                        </div>
                    </div>
                    <div className="sep-0"/>
                    <div className="sep-5" />
                    <div className="twoThird left">
                        <div>
                            <h5>{t("homepage:searchBlock.where.title")}</h5>
                            <LocationSearchInput
                                placeholder={t("homepage:searchBlock.when.enterAddress")}
                                address={props.search.where}
                                handleChange={handleChange}
                                latLngHandler={latLngHandler}
                                handleMapNoResult={handleMapNoResult}
                            />

                            {/*<input type="text" placeholder="Please enter your location"*/}
                            {/*onChange={(e) => props.changeStateLevelTwo('search', 'where', e.target.value)}*/}
                            {/*value={props.search.where}*/}
                            {/*/>*/}
                        </div>
                    </div>
                    <div className="oneThird left">
                        <div className="align-center">

                            {props.search.DateStart === "" || props.search.DateEnd === "" ? (
                                <Link to="/" className="button special disabled"
                                      onClick={(e) => e.preventDefault()}
                                >{t("homepage:searchBlock.search")}</Link>
                            ) : (
                                <Link to="/" className="button special"
                                      onClick={(e) => props.ApiSearch(e)}
                                >{t("homepage:searchBlock.search")}</Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(HomeSearch);
