import React, {Component} from 'react';

class Disclaimer_en_US extends Component {
    render() {
        return (
            <div className="disclaimer"  dangerouslySetInnerHTML={{__html: this.props.content}}></div>
            // <div className="disclaimer">
            //     <ul>
            //         <li> Let’s Park have used all reasonable commercial effort to ensure that this Platform will work as
            //             intended and will be free from bugs or error or virus but due to the nature of this Platform, no
            //             warranty is given or is deemed to have been given to you in relation to the information provided
            //             in or the operation of this Platform to the extent allowed by law.
            //         </li>
            //     </ul>
            //
            //     <ul>
            //         <li> Although Let’s Park will use all reason commercial efforts to mediate any dispute between the
            //             Owner and the Parker, we shall not be liable for any act or omission of any party to the Licence
            //             Agreement or the breach of the Licence Agreement by any party thereto.
            //         </li>
            //     </ul>
            //
            //     <ul>
            //         <li> To the extent allowed by law, the liability of Let’s Park under these Terms in relation to a
            //             Booking shall be limited to the Service Fee received by us for such Booking and we shall not be
            //             liable for any loss of profit, penalty, consequential losses or damages of any kind whatsoever,
            //             whether in the contemplation of the parties or otherwise and whether we have been advised of the
            //             possibility of such.
            //         </li>
            //     </ul>
            //
            //     <ul>
            //         <li> We shall not be liable to you for any decision of fact we may make pursuant to the provisions
            //     of these Terms or the Licence Agreement, which you agree to be final and binding on you.
            //      </li>
            //    </ul>
            // </div>
        );
    }
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default Disclaimer_en_US;
