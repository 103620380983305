import React from 'react';
import {withTranslation} from 'react-i18next';

function Reviews(props) {
    const {
        t,
        // i18n,
        score = 0,
        reviews = 0,
    } = props;

    return (
        <div>
            <ul className="reviews">
                <li className={score >= 1 ? "active" : ""}></li>
                <li className={score >= 2 ? "active" : ""}></li>
                <li className={score >= 3 ? "active" : ""}></li>
                <li className={score >= 4 ? "active" : ""}></li>
                <li className={score >= 5 ? "active" : ""}></li>
                <span>{reviews} {t("space:reviews")}</span>
            </ul>
        </div>
    )
}

export default withTranslation()(Reviews);