import React, {Component} from 'react';
import InputRange from 'react-input-range';
import {withTranslation} from 'react-i18next';

class SortbyFilterDesktop2 extends Component {

    render() {

        // console.log(this.props.filter);
        const {
            t,
            // i18n,
        } = this.props;

        return (
            <div>
                <ul className={"sortbyFilterDesktop2 clearfix " + (this.props.desktopFilter.showDesktopFilter ? 'active' : '')}>
                    <li><span className={(this.props.desktopFilter.showSortby ? "active " : "")}
                              onClick={(e) => this.props.handleDesktopFilter('sortBy')}
                    >{t("search:filterView.sortBy.title")}</span>

                        <ul className={"menuSortby " + (this.props.desktopFilter.showSortby ? "active " : "")}>
                            <div className="contentSortby">
                                <ul className="clearfix">
                                    <div className="oneFourth left">
                                        <li className={"price " + (this.props.sortby.price ? "active" : "")}
                                            onClick={(e) => this.props.handleSortbyOptions('price')}>{t("search:filterView.sortBy.price")}</li>
                                    </div>
                                    <div className="oneFourth left">
                                        <li className={"rating " + (this.props.sortby.rating ? "active" : "")}
                                            onClick={(e) => this.props.handleSortbyOptions('rating')}>{t("search:filterView.sortBy.rating")}
                                        </li>
                                    </div>
                                    <div className="oneFourth left">
                                        <li className={"distance " + (this.props.sortby.distance ? "active" : "")}
                                            onClick={(e) => this.props.handleSortbyOptions('distance')}>{t("search:filterView.sortBy.distance")}
                                        </li>
                                    </div>
                                    <div className="oneFourth left">
                                        <li className={"review " + (this.props.sortby.review ? "active" : "")}
                                            onClick={(e) => this.props.handleSortbyOptions('review')}>{t("search:filterView.sortBy.reviews")}
                                        </li>
                                    </div>
                                </ul>
                            </div>
                        </ul>
                    </li>
                    <li><span className={"reverse " + (this.props.desktopFilter.showFilter ? "active " : "")}
                              onClick={(e) => this.props.handleDesktopFilter('filter')}
                    >{t("search:filterView.filter.title")}</span>

                        <ul className={"menuFilter " + (this.props.desktopFilter.showFilter ? "active " : "")}>
                            <div className="contentFilter clearfix">

                                <h5>{t("search:filterView.filter.vehicle.title")}</h5>
                                <ul className="booking-options clearfix">
                                    <div className="oneFifth">
                                        <li className={(this.props.filter.cartype === "PrivateCar" ? "active" : "")}
                                            onClick={(e) => this.props.handleFilterSelect('cartype', 'PrivateCar')}>
                                            <div
                                                className={"icon privateCar " + (this.props.filter.cartype === "PrivateCar" ? "active" : "")}/>
                                            <h5>{t("search:filterView.filter.vehicle.options.privateCar")}</h5>
                                        </li>
                                    </div>
                                    <div className="oneFifth">
                                        <li className={(this.props.filter.cartype === "Motorcycle" ? "active" : "")}
                                            onClick={(e) => this.props.handleFilterSelect('cartype', 'Motorcycle')}>
                                            <div
                                                className={"icon motorcycle " + (this.props.filter.cartype === "Motorcycle" ? "active" : "")}/>
                                            <h5>{t("search:filterView.filter.vehicle.options.motorcycle")}</h5>
                                        </li>
                                    </div>
                                    <div className="oneFifth">
                                        <li className={(this.props.filter.cartype === "Van" ? "active" : "")}
                                            onClick={(e) => this.props.handleFilterSelect('cartype', 'Van')}>
                                            <div
                                                className={"icon van " + (this.props.filter.cartype === "Van" ? "active" : "")} />
                                            <h5>{t("search:filterView.filter.vehicle.options.van")}</h5>
                                        </li>
                                    </div>
                                    <div className="oneFifth">
                                        <li className={(this.props.filter.cartype === "Lorry" ? "active" : "")}
                                            onClick={(e) => this.props.handleFilterSelect('cartype', 'Lorry')}>
                                            <div
                                                className={"icon lorry " + (this.props.filter.cartype === "Lorry" ? "active" : "")}/>
                                            <h5>{t("search:filterView.filter.vehicle.options.lorry")}</h5>
                                        </li>
                                    </div>
                                    <div className="oneFifth">
                                        <li className={(this.props.filter.cartype === "Others" ? "active" : "")}
                                            onClick={(e) => this.props.handleFilterSelect('cartype', 'Others')}>
                                            <div
                                                className={"icon others " + (this.props.filter.cartype === "Others" ? "active" : "")}/>
                                            <h5>{t("search:filterView.filter.vehicle.options.other")}</h5>
                                        </li>
                                    </div>
                                </ul>

                                <div className="sep-20"/>

                                <h5>{t("search:filterView.filter.feature.title")}</h5>
                                <ul className="booking-options clearfix special">
                                    <div className="oneThird">
                                        <li className={(this.props.filter.features1.indexOf('Indoor') > -1 ? "active" : "")}
                                            onClick={(e) => this.props.handleFilterSelect('features1', 'Indoor')}>
                                            <div
                                                className={"icon indoor " + (this.props.filter.features1.indexOf('Indoor') > -1 ? "active" : "")}/>
                                            <h5>{t("search:filterView.filter.feature.options.indoor")}</h5>
                                        </li>
                                    </div>
                                    <div className="oneThird">
                                        <li className={(this.props.filter.features1.indexOf('WithCovers') > -1 ? "active" : "")}
                                            onClick={(e) => this.props.handleFilterSelect('features1', 'WithCovers')}>
                                            <div
                                                className={"icon withCovers " + (this.props.filter.features1.indexOf('WithCovers') > -1 ? "active" : "")}/>
                                            <h5>{t("search:filterView.filter.feature.options.withCovers")}</h5>
                                        </li>
                                    </div>
                                    <div className="oneThird">
                                        <li className={(this.props.filter.features1.indexOf('NoCovers') > -1 ? "active" : "")}
                                            onClick={(e) => this.props.handleFilterSelect('features1', 'NoCovers')}>
                                            <div
                                                className={"icon noCovers " + (this.props.filter.features1.indexOf('NoCovers') > -1 ? "active" : "")}/>
                                            <h5>{t("search:filterView.filter.feature.options.noCovers")}</h5>
                                        </li>
                                    </div>
                                </ul>
                                <ul className="booking-options ev special">
                                    <div className="oneThird">
                                        <li className={(this.props.filter.features2.indexOf('EV') > -1 ? "active" : "")}
                                            onClick={(e) => this.props.handleFilterOptions('features2', 'EV')}>
                                            <div
                                                className={"icon ev " + (this.props.filter.features2.indexOf('EV') > -1 ? "active" : "")}/>
                                            <h5>{t("search:filterView.filter.feature.options.ev")}</h5>
                                        </li>
                                    </div>
                                    <div className="oneThird">
                                        <li className={(this.props.filter.features2.indexOf('HeadRoom') > -1 ? "active" : "")}
                                            onClick={(e) => this.props.handleFilterOptions('features2', 'HeadRoom')}>
                                            <div
                                                className={"icon headRoom " + (this.props.filter.features2.indexOf('HeadRoom') > -1 ? "active" : "")}/>
                                            <h5>{t("search:filterView.filter.feature.options.headRoom")}</h5>
                                        </li>
                                    </div>
                                    <div className="oneThird align-bottom align-left">
                                        {this.props.filter.features2.indexOf('HeadRoom') > -1 &&
                                        <li>
                                            <select
                                                value={this.props.filter.height}
                                                onChange={(e) => this.props.handleFilterSelect('height', e.target.value)}>
                                                <option value="1.7">1.7 {t("search:filterView.filter.feature.m")}</option>
                                                <option value="1.8">1.8 {t("search:filterView.filter.feature.m")}</option>
                                                <option value="1.9">1.9 {t("search:filterView.filter.feature.m")}</option>
                                                <option value="2.0">2.0 {t("search:filterView.filter.feature.m")}</option>
                                                <option value="2.1">2.1 {t("search:filterView.filter.feature.m")}</option>
                                                <option value="2.2">2.2 {t("search:filterView.filter.feature.m")}</option>
                                                <option value="2.3">2.3 {t("search:filterView.filter.feature.m")}</option>
                                                <option value="2.4orAbove">2.4 {t("search:filterView.filter.feature.mAbove")}</option>
                                            </select>
                                            <div className="sep-10"/>
                                        </li>
                                        }
                                    </div>
                                </ul>

                                <div className="sep-20"/>

                                <div className="fifty left">
                                    <h5>{t("search:filterView.filter.typeOfBooking.title")}</h5>
                                    <ul className="booking-options clearfix">
                                        <div className="oneThird">
                                            <li className={(this.props.filter.bookingType === "hourly" ? "active" : "")}
                                                onClick={(e) => this.props.handleFilterSelect('bookingType', 'hourly')}>
                                                <div
                                                    className={"icon hourly " + (this.props.filter.bookingType === "hourly" ? "active" : "")}/>
                                                <h5>{t("search:filterView.filter.typeOfBooking.options.hourly")}</h5>
                                            </li>
                                        </div>
                                        <div className="oneThird">
                                            <li className={(this.props.filter.bookingType === "monthly" ? "active" : "")}
                                                onClick={(e) => this.props.handleFilterSelect('bookingType', 'monthly')}>
                                                <div
                                                    className={"icon monthly " + (this.props.filter.bookingType === "monthly" ? "active" : "")}/>
                                                <h5>{t("search:filterView.filter.typeOfBooking.options.monthly")}</h5>
                                            </li>
                                        </div>
                                        <div className="oneThird">
                                            <li className={(this.props.filter.bookingType === "timeshare" ? "active" : "")}
                                                onClick={(e) => this.props.handleFilterSelect('bookingType', 'timeshare')}>
                                                <div
                                                    className={"icon timeShare " + (this.props.filter.bookingType === "timeshare" ? "active" : "")}/>
                                                <h5>{t("search:filterView.filter.typeOfBooking.options.timeShare")}</h5>
                                            </li>
                                        </div>
                                    </ul>
                                </div>

                                <div className="fifty left">
                                    <h5>{t("search:filterView.filter.bookingType.title")}</h5>
                                    <ul className="booking-options clearfix">
                                        <div className="oneThird">
                                            <li className={(this.props.filter.instant ? "active" : "")}
                                                onClick={(e) => this.props.handleFilterBoolean('instant')}>
                                                <div
                                                    className={"icon timeShare " + (this.props.filter.instant ? "active" : "")}/>
                                                <h5>{t("search:filterView.filter.bookingType.options.instant")}</h5>
                                            </li>
                                        </div>
                                    </ul>
                                </div>

                                <div className="sep-20"/>

                                <div className="fifty left">
                                    <h5>{t("search:filterView.filter.walkingDistance.title")}</h5>
                                    <div className="inputRangeDiv">
                                        <InputRange
                                            maxValue={30}
                                            minValue={0}
                                            value={this.props.filter.distance}
                                            formatLabel={value => `${value} ` + t("search:filterView.filter.walkingDistance.mins")}
                                            onChange={value => this.props.handleFilterRange('distance', value)}/>
                                    </div>
                                </div>

                                <div className="fifty left">
                                    <h5>{t("search:filterView.filter.price.title")}</h5>
                                    <div className="inputRangeDiv">
                                        <InputRange
                                            maxValue={this.props.filter.initPrice.max}
                                            minValue={this.props.filter.initPrice.min}
                                            value={this.props.filter.price}
                                            formatLabel={value => `$${value}`}
                                            onChange={value => this.props.handleFilterRange('price', value)}/>
                                    </div>
                                </div>

                                <div className="sep-40"/>

                                <div className="wrap clearfix">
                                    <div className="fifty left">
                                        <div>
                                            <span className="button grey"
                                                  onClick={(e) => this.props.handleReset()}>{t("search:filterView.reset")}</span>
                                        </div>
                                    </div>
                                    <div className="fifty left">
                                        <div>
                                            <span className="button"
                                                  onClick={(e) => this.props.handleApplyFilter()}>{t("search:filterView.apply")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ul>
                    </li>

                    <div className="sep-20"/>
                </ul>
            </div>
        )
    }
}

// const mappropsToProps = (props) => (
// 	{
// 		route: props.router,
// 	}
// );

export default withTranslation()(SortbyFilterDesktop2);
