import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Redirect} from 'react-router';
import {withTranslation} from 'react-i18next';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as MembersActionCreators from '../../../actions/members';
import ReactPixel from "react-facebook-pixel";

class MemberCreateAccountComplete extends Component {

    constructor(props) {

        super(props);

        const {dispatch} = props;

        // DISPATCH TO ACTIONS
        this.ResetRegistration = bindActionCreators(MembersActionCreators.ResetRegistration, dispatch);

        // clear the registered record flag
        this.ResetRegistration();
    }

    componentDidMount() {

        ReactPixel.track('Lead', {content_category: 'Individual', content_name: this.props.params.ref});
    }

    render() {
        const {
            t,
            i18n
        } = this.props;

        if (this.props.params === null) {
            return <Redirect to={"/" + i18n.language + "/register-individual"}/>;
        }

        return (
            <div className="wrapper-container memberCreateAccount padding">

                {/* <div className="page-title"><h2>Contact Us</h2></div>

                    <div className="sep-0" /> */}

                <div className="containerMain borderTop">
                    <div className="centerBox clearfix">

                        <div className="sep-20"/>
                        <div className="sep-20"/>
                        <div className="sep-20"/>
                        <div className="sep-20"/>

                        <h2>{t("registerStart:success.title")}</h2>
                        <p>{t("registerStart:success.desc1")}<br/>
                            <Link
                                to={"/" + i18n.language + "/register-individual"}>{this.props.params.email}</Link><br/>
                            {t("registerStart:success.desc2")}
                        </p>

                        <div className="sep-20"/>
                        <div className="sep-20"/>
                        <div className="sep-20"/>
                        <div className="sep-20"/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {}
);

export default withTranslation()(connect(mapStateToProps)(MemberCreateAccountComplete));
