import {apiPost} from "./General";

export const checkPromotionCode = (accessToken, spaceID, code, type, from, to, bcode, callback, errorCallback) => {
    let body = {
        space: spaceID,
        code: code,
        type: type,
        from: from,
        to: to,
        bcode,
    }
    apiPost("promotion/check?access_token=" + accessToken, body, callback, errorCallback);
}

export const checkForOwnerPromotionCode = (accessToken, hourly_promotion, monthly_promotion, timeshare_promotion, callback, errorCallback) => {

    let body = {
        access_token: accessToken,
        hourly_promotion: hourly_promotion,
        monthly_promotion: monthly_promotion,
        timeshare_promotion: timeshare_promotion
    }

    apiPost("promotion/check_for_owner?access_token=" + accessToken, body, callback, errorCallback)
}
