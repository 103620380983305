import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { withTranslation } from 'react-i18next';

class MemberForgetPassword extends Component {
    render() {
        const {
            t,
            i18n
        } = this.props;

        if (this.props.params === null) {
            return <Redirect to={"/" + i18n.language + "/memberForgetPassword"} />;
        }

        return (
            <div className="wrapper-container memberCreateAccount padding">

                {/* <div className="page-title"><h2>Contact Us</h2></div>

                    <div className="sep-0" /> */}

                <div className="containerMain borderTop">
                    <div className="centerBox clearfix">

                        <div className="sep-20" />
                        <div className="sep-20" />
                        <div className="sep-20" />
                        <div className="sep-20" />

                        <h2>{t("forgotPassword:checkEmail")}</h2>
                        <p>{t("forgotPassword:sentEmail")}<br /> <Link to={"/" + i18n.language + "/register-individual"}>{this.props.params.email}</Link> <br />{t("forgotPassword:instructions")}</p>

                        <div className="sep-20" />
                        <div className="sep-20" />
                        <div className="sep-20" />
                        <div className="sep-20" />
                    </div>
                </div>
            </div>
        );
    }
}

// const mapStateToProps = (state) => (
//     {
//         members: state.members,
//     }
// );

export default withTranslation()(MemberForgetPassword);
// connect(mapStateToProps)(MemberForgetPassword);
