import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import * as WishListApi from "../../utils/04Api/WishList";

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as MembersActionCreators from "../../actions/members";
import {Link} from "react-router-dom";

class Heart extends Component {
    constructor(props) {
        super(props);
        const {dispatch} = props;
        this.toggleWishList = bindActionCreators(MembersActionCreators.toggleWishList, dispatch);
    }

    handleWishlist = (spaceId) => {
        this.toggleWishList(spaceId);
        if (this.props.members.Login.userWishList.indexOf(spaceId) > -1) {
            const cb = (obj) => {
                if (obj.status === 202) {
                    // console.log("success");
                } else {
                    // console.log("failed", obj.body);
                }
            }
            const eCb = (obj) => {
                // console.log("failed", obj.body);
            }
            WishListApi.removeWishList(this.props.members.Login.userToken, spaceId, cb, eCb);
        } else {
            // console.log('to Add');
            const cb = (obj) => {
                if (obj.status === 200) {
                    // console.log("success");
                } else {
                    // console.log("failed", obj.body);
                }
            }
            const eCb = (obj) => {
                // console.log("failed", obj.body);
            }
            WishListApi.addWishList(this.props.members.Login.userToken, spaceId, cb, eCb);
        }
    }

    render() {

        const {
            // t,
            i18n,
        } = this.props;

        if (this.props.members.Login.userWishList) {
            return (
                <span
                    className={"heart" + (this.props.members.Login.userWishList.indexOf(this.props.id) > -1 ? ' active' : '')}
                    onClick={() => this.handleWishlist(this.props.id)}/>
            )
        } else {
            return (
                <Link to={"/" + i18n.language + "/memberGetStarted"}><span className={"heart"}/></Link>
            )
        }
    }
}

const mapStateToProps = (state) => (
    {
        members: state.members
    }
);

export default withTranslation()(connect(mapStateToProps)(Heart));