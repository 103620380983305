import React from 'react';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {renderListingTitle} from "../../utils/00General/Helpers";

import Verified from '../103Parts/Verified';
import Heart from '../103Parts/Heart';
import Reviews from '../103Parts/Reviews';
import CarTypes from '../103Parts/CarTypes';
// import * as Helper from "../../utils/03FindParkingSpace/LowestHourlyPrice";

function ListType2(props) {
    const {
        t,
        i18n,
        space
    } = props;

    // console.log('space', space.data);

    const lowestPrice = space.price,
        lowestType = space.price_type;

    // let lowestPrice = 0,
    //     lowestType = 'Hour';
    // if (typeof (space.data.hourly.from) !== "undefined") {
    //     lowestPrice = Helper.lowestPrice(space.data.hourly.days);
    // } else if (typeof (space.data.monthly.from) !== "undefined") {
    //     lowestPrice = space.data.monthly.price;
    //     lowestType = 'Month';
    // } else if (typeof (space.data.timeshare.from) !== "undefined") {
    //     lowestPrice = space.data.timeshare.price;
    //     lowestType = 'Month';
    // }

    let queryString = '?';
    if (typeof(props.params) !== "undefined" && props.params !== null) {
        let params = props.params;
        Object.keys(params).forEach((itm, idx) => {
            if (idx === 0)
                queryString += itm + '=' + params[itm];
            else
                queryString += '&' + itm + '=' + params[itm];
        });
    }

    return (
        <div className={"ListType2 clearfix " + (props.count === 0 ? 'first' : '')}>
            <div className="image"
                 style={space.data.images ? {backgroundImage: "url(" + space.data.images[0] + ")"} : null}
            >
                <Verified
                    id={space.data._id}
                    verified={false}
                />
                <Heart
                    id={space.data._id}
                />
            </div>
            <div className="text">
{/*                <h3>
                    {space.data.title}
                </h3>*/}
                <h3>{renderListingTitle(space.data,i18n.language)}</h3>
                <h4>{i18n.language === 'zh-HK' && space.data.address_tc ? space.data.address_tc : space.data.address}</h4>
                {lowestPrice > 0 &&
                <div>
                    <div className="sep-5"/>
                    <div className="price">
                        {t("search:pricePer" + lowestType, {price: lowestPrice})}

                        {space.data.instant &&
                        <div className="instantBooking"/>
                        }
                        {space.bcode &&
                        <div className="bcode"/>
                        }
                    </div>
                </div>
                }
                <div className="sep-10"/>
                <Reviews score={space.data.rating} reviews={space.data.reviews.length}/>
                <div className="sep-10"/>
                <CarTypes
                    carTypes={space.data.cartype}
                    features={space.data.features}
                />
                <Link to={"/" + i18n.language + "/space/" + space.data._id + queryString}
                      className="button">{t("search:bookNow")}</Link>
            </div>
        </div>
    )
}

export default withTranslation()(ListType2);
