import React from 'react';
import {withTranslation} from 'react-i18next';
// import { getSpaceDetail } from "../../utils/04Api/Space";
import {mongoIdTimestamp} from "../../utils/00General/Helpers";
// import { Link } from 'react-router-dom';

import N2 from '../../components/05Messages/Notifications/N2';
import N3 from '../../components/05Messages/Notifications/N3';
import N4 from '../../components/05Messages/Notifications/N4';
import N5 from '../../components/05Messages/Notifications/N5';
import N6 from '../../components/05Messages/Notifications/N6';

import N7 from '../../components/05Messages/Notifications/N7';
import N8 from '../../components/05Messages/Notifications/N8';
import N9 from '../../components/05Messages/Notifications/N9';
import N10 from '../../components/05Messages/Notifications/N10';
import N11 from '../../components/05Messages/Notifications/N11';
import N12 from '../../components/05Messages/Notifications/N12';
import N13 from '../../components/05Messages/Notifications/N13';
import N14 from '../../components/05Messages/Notifications/N14';

import N18 from '../../components/05Messages/Notifications/N18';
import N20 from '../../components/05Messages/Notifications/N20';
import N21 from '../../components/05Messages/Notifications/N21';
import N30 from "../../components/05Messages/Notifications/N30";

function Notification(props) {
    const {
        t,
        i18n,
        notification,
        booker,//2019-07-29 Samson
        owner,//2019-07-29 Samson
    } = props;

    let card, owner_name = '', booker_name = '';
    const time = mongoIdTimestamp(notification._id, "LLL");
    const address = i18n.language === 'zh-HK' && notification.data.address_tc ? notification.data.address_tc : notification.data.address;

    //2019-07-29 Samson
    // console.log(owner,booker);
    if (typeof(owner) !== "undefined" && owner !== null) {
        owner_name = owner.first_name + ' ' + owner.last_name;
        if (owner.isCorp)
            owner_name = owner.companyname;
    }
    if (typeof(booker) !== "undefined" && booker !== null) {
        booker_name = booker.first_name + ' ' + booker.last_name;
        if (booker.isCorp)
            booker_name = booker.companyname;
    }
////2019-07-29 Samson

    switch (notification.card) {
        case 'N2':
            card = <N2
                time={time}
                number={notification.data.number}
                action={notification.data.action}
            />
            break;
        case 'N3':
            card = <N3
                time={time}
                number={notification.data.number}
            />
            break;
        case 'N4':
            card = <N4
                time={time}
                name={notification.data.name}
            />
            break;
        case 'N5':
            card = <N5
                time={time}
                sid={notification.data.sid}
                address={address}
                success={notification.data.success}
            />
            break;
        case 'N6':
            card = <N6
                time={time}
                sid={notification.data.sid}
                address={address}
            />
            break;
        case 'N7':
            card = <N7
                time={time}
                data={notification.data}
                id={notification._id}
                owner_name={owner_name}
                booker_name={booker_name}
            />
            break;
        case 'N8':
            card = <N8
                time={time}
                data={notification.data}
                id={notification._id}
                owner_name={owner_name}
                booker_name={booker_name}
            />
            break;
        case 'N9':
            card = <N9
                time={time}
                data={notification.data}
                id={notification._id}
                owner_name={owner_name}
                booker_name={booker_name}
            />
            break;
        case 'N10':
            card = <N10
                time={time}
                data={notification.data}
                id={notification._id}
                owner_name={owner_name}
                booker_name={booker_name}
            />
            break;
        case 'N11':
            card = <N11
                time={time}
                data={notification.data}
                sid={notification.data.sid}
                owner_name={owner_name}
                booker_name={booker_name}
            />
            break;
        case 'N12':
            card = <N12
                time={time}
                data={notification.data}
                sid={notification.data.sid}
                owner_name={owner_name}
                booker_name={booker_name}
            />
            break;
        case 'N13':
            card = <N13
                time={time}
                data={notification.data}
                sid={notification.data.sid}
                owner_name={owner_name}
                booker_name={booker_name}
            />
            break;
        case 'N14':
            card = <N14
                time={time}
                data={notification.data}
                id={notification._id}
                owner_name={owner_name}
                booker_name={booker_name}
            />
            break;
        case 'N18':
            card = <N18 time={time}/>
            break;
        case 'N20':
            card = <N20 time={time}/>
            break;
        case 'N21':
            card = <N21 time={time}/>
            break;
        case 'N30':
            card = <N30
                data={notification.data}
                time={time}/>
            break;
        default:
            card = (
                <div>
                    <h3>{t("Let's Park System Message")}</h3>
                    <div className="sep-15"/>
                    <span className="dateTime">{time}</span>
                    <div className="sep-10"/>
                    <div>{notification.card}</div>
                </div>
            )
    }

    return (
        <div key={props.keyVal} className={"ListType5a clearfix " + (props.keyVal === 0 ? 'first' : '')}>
            <div className="border-box clearfix">
                {/*<div className="control clearfix">*/}
                {/*    <div className="number">3</div>*/}
                {/*</div>*/}

                <div className="wrap clearfix">
                    <div className="person">
                        <div className="image"/>
                        <div className="sep-10"/>
                        <h3>{t('General.LetsPark')}</h3>
                    </div>

                    <div className="text">
                        {card}
                    </div>
                </div>
            </div>
        </div>
    )
}

function Notifications(props) {
    const {
        t,
        i18n,
        notifications,
        // n_booking_owners,
        // n_booking_bookers,
        _isMounted,
    } = props;
    //
    // if (!notifications) {
    //     return (
    //         <div className={"ListType5"}>
    //             <div className="border-box clearfix">
    //                 <div>{t("common:Loading")}</div>
    //             </div>
    //         </div>
    //     )
    // }
    //
    // if (!notifications.length)
    //     return <div>{t("messenger.general.noNotification")}</div>

    return (
        <div>
            <div className="sep-20"/>
            {notifications.map((notification, index) =>
                <Notification
                    key={index}
                    keyVal={index}
                    notification={notification}
                    t={t}
                    i18n={i18n}
                    userId={props.userId}
                    owner={(typeof(notification.owner) !== "undefined") ? notification.owner : null}
                    booker={(typeof(notification.booker) !== "undefined") ? notification.booker : null}
                    _isMounted={_isMounted}
                />
            )}
        </div>
    )
}

// class Notifications extends Component {
//     constructor(props) {
//         super(props);
//
//         this.state = {
//             error: false,
//         }
//
//         // if (props.room.messages.length)
//         //     this.state.message = props.room.messages[0];
//
//         console.log(this.state.notifications);
//     }
//
//     componentDidMount() {
//         // let cb = (obj) => {
//         //     console.log("space", obj.body);
//         //     if (obj.status && obj.status === 200) {
//         //         this.setState({
//         //             space: obj.body
//         //         })
//         //     } else {
//         //         this.setState({
//         //             ...this.state,
//         //             error: true,
//         //         })
//         //     }
//         // }
//         //
//         // let errCb = (obj) => {
//         //     this.setState({
//         //         ...this.state,
//         //         error: true,
//         //     })
//         // }
//         // getSpaceDetail(this.props.room.sid, cb, errCb);
//     }
//
//     render() {
//         const { i18n, t } = this.props;
//
//         // if (this.state.error)
//         //     return <div />
//
//         // if (!this.state.space)
//         //     return <div>{t("common:Loading")}</div>;
//
//         // const space = this.state.space;
//
//         // if (!props.notifications)
//         //     return <div>{t("common:Loading")}</div>
//
//         // if (!props.notifications.length)
//         //     return <div>{t("No Message")}</div>
//
//         return <div>{t("No Message")}</div>
//
//         return (
//             <div>
//                 {/* {props.notifications.map((notification, index) =>
//                 <Notification key={index} index={index} notification={notification} t={t} i18n={i18n} />
//             )} */}
//
//                 {/*<ListType5a />*/}
//                 {/*<ListType5a />*/}
//                 {/*<ListType5a />*/}
//                 {/*<ListType5a />*/}
//
//                 {/*<ShowMore />*/}
//             </div>
//
//             // <div key={this.props.index} className={"ListType5 clearfix " + (this.props.index === 0 ? 'first' : '')}>
//             //     <div className="border-box clearfix">
//             //         <div className="control clearfix">
//             //             <div className="number">{this.props.room.totalMessages}</div>
//             //             <div className="arrow"></div>
//             //         </div>
//
//             //         <div className="wrap clearfix">
//             //             <div className="image" style={{ backgroundImage: `url(${require('../../images/blank.png')})` }}></div>
//             //             <div className="text">
//             //                 <h4>{space.owner.name}</h4>
//             //                 <div className="sep-5" />
//             //                 <h3>{space.title}<br />{space.address}</h3>
//             //             </div>
//             //         </div>
//             //         {this.state.message &&
//             //             <div className="details">
//             //                 <span className="dateTime">{mongoIdTimestamp(this.state.message._id, "LLL")}</span>
//             //                 <div className="sep-10" />
//             //                 <p>{this.state.message.message}</p>
//
//             //                 <div className="align-center">
//             //                     <Link to={"/" + i18n.language + "/chat/" + this.props.room.sid} className="button">Read more</Link>
//             //                 </div>
//             //             </div>
//             //         }
//             //     </div>
//             // </div>
//         )
//     }
// }

export default withTranslation()(Notifications);
