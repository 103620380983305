import React, {Component} from 'react';

class Terms_zh_HK extends Component {

    // constructor(props) {
    //
    //     super(props);
    // }

    render() {
        return (
            <div className="terms" dangerouslySetInnerHTML={{__html: this.props.content}} />
            // <div className="terms">
            //
            //     <p>你使用本網站以及由泊車易(香港)有限公司提供的服務須受本《條款及條件》管限，本《條款及條件》將構成你與泊車易之間具有法律約束力的協議。</p>
            //
            //     <ol>
            //         <li><strong>定義及釋義</strong>
            //             <ol>
            //                 <li>在本《條款及條件》中，除非上下文另有要求，否則下列字詞具有以下含義：
            //                     <ul>
            //                         <li>「帳戶」指你在泊車易註冊的帳戶；</li>
            //                         <li>「預訂」指在特定時間使用泊車位的特許，其對相關業主及車主具有約束力，凡提述預訂完成的時間，指預訂生效並對業主和車主具有約束力的時間；</li>
            //                         <li>「確認書」在<em>即時</em><em>預訂</em>的情況下，指我們根據第1(b)條向相關車主發送的確認書，而在<em>按協議</em><em>預訂</em>的情況下，指我們根據第9.2(d)條向相關車主發送的確認書；
            //                         </li>
            //                         <li>「按金」指就預訂應付的按金（如適用）；</li>
            //                         <li>「手續費」指第2條所述的手續費；</li>
            //                         <li>「特許」就泊車位而言，指業主根據相關《特許協議》就該泊車位授予車主的特許；</li>
            //                         <li>「《特許協議》」就預訂而言，指業主與車主之間關於此預訂的《特許協議》，此《特許協議》的最新版本條款可在此查閱；</li>
            //                         <li>「泊車費用」就預訂而言，指車主根據相關《特許協議》就該預訂應向業主支付的泊車費用；</li>
            //                         <li>「業主」就泊車位而言，指在平台上發布該泊車位的人，而如果你在平台上發布泊車位，則業主的權利和義務將適用於你；</li>
            //                         <li>「車主」就預訂而言，指根據該預訂獲得使用泊車位的特許的人，而如果你通過平台獲得特許，則就該特許而言，車主的權利和義務將適用於你；</li>
            //                         <li>「泊車位」指在平台上發布的泊車位；</li>
            //                         <li>「泊車位內容」指平台上描述的泊車位的內容（包括照片）；</li>
            //                         <li>「平台」指本網站、泊車易手機應用程式及該服務；</li>
            //                         <li>「服務」指我們通過平台提供的服務；</li>
            //                         <li>「服務費用」指業主就我們協助其獲授予第1(a)條所述的特許，而應向我們支付的服務費用；</li>
            //                         <li>「本《條款》」指不時修訂的本《條款及條件》；</li>
            //                         <li>「我們」或「泊車易」指在香港註冊成立的有限公司－泊車易(香港)有限公司；</li>
            //                         <li>「用戶」指已註冊為平台用戶的一方；</li>
            //                         <li>「車輛」就預訂而言，指根據經《特許協議》修訂（如適用）的預訂將會使用泊車位的車輛，該車輛將按其登記號碼以作識別；</li>
            //                         <li>「網站」指本網站<a
            //                             style={{"textDecoration":"underline"}}
            //                             href="http://www.letspark.com.hk">www.letspark.com.hk</a>；及</li>
            //                         <li>「你」指瀏覽該網站的人。</li>
            //                     </ul>
            //                 </li>
            //                 <li>在本《條款》中，除非上下文另有要求，否則：
            //                     <ul>
            //                         <li>凡提述條款，指本《條款》的條款；</li>
            //                         <li>某一性別字詞亦包括所有其他性別；</li>
            //                         <li>單數字詞亦包括複數，反之亦然；及</li>
            //                         <li>「人士」包括個人、合夥、公司或非法人團體及政府部門。</li>
            //                     </ul>
            //                 </li>
            //                 <li>在本《條款》中，凡提述我們不時公佈的信息或程序，指本網站不時公佈的修訂或程序。</li>
            //                 <li>在本《條款》中，「營業日」指除星期六、星期日和香港的公眾假期以外的日子。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>效力及用途</strong>
            //             <ol>
            //                 <li>平台的用途是發布和查看泊車位，以及授予使用泊車位的特許。你不得將平台或平台中包含的任何信息用作任何其他用途。</li>
            //                 <li>本《條款》管限你對本網站的取用、你對平台的使用以及我們向你提供的服務。本《條款》對你具有法律約束力。你查看本網站和使用平台將被視為你同意受本《條款》約束。如果你不同意本《條款》的任何條款或當中提及的任何其他條款，你不得瀏覽本網站，也不得使用平台。</li>
            //                 <li>如果你想通過平台發布泊車位或獲取使用泊車位的特許，或聯絡業主或車主，你需要先註冊一個帳戶。</li>
            //                 <li>如果你通過平台授予或獲授予特許，《特許協議》的條款須適用。</li>
            //                 <li>由於互聯網的性質，我們無法保證你能持續不斷地取用平台或我們能持續不斷地提供服務。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>泊車易提供的服務</strong>
            //             <ol>
            //                 <li>該等服務包括根據本《條款》刊登泊車位的廣告、提供有關泊車位的信息、協助業主向車主授予特許並協助管理泊車位。預訂由業主與車主直接進行，而我們不是《特許協議》的一方，也不是業主或車主的代理人或代表。</li>
            //                 <li>業主、車主和我們是彼此獨立的承包商，三者之間並無代理、委託或合夥的關係。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>註冊帳戶</strong>
            //             <ol>
            //                 <li>你需要先註冊一個帳戶，然後才能在平台上以業主的身分發布泊車位、聯絡業主或車主、以車主身分預訂泊車位。帳戶的註冊由泊車易全權酌情決定，泊車易有權不註冊任何帳戶，而毋須給予任何理由。</li>
            //                 <li>你可以使用電郵地址及自定密碼註冊帳戶，也可以使用你在某些第三方社交網絡服務的帳戶進行註冊。</li>
            //                 <li>註冊帳戶時，你需要提供某些信息，而我們的《個人資料收集聲明》和《個人資料政策》（可<strong><u>在此</u></strong>查閱）須適用於註冊過程中收集的個人資料。你進一步承諾將在你開立帳戶後，因應我們可能不時提出的要求，向我們提供所需信息，以讓我們維持你的帳戶和提供服務給你。
            //                 </li>
            //                 <li>在註冊帳戶的同時，你保證並確認：
            //                     <ul>
            //                         <li>你提供給我們的註冊信息真確無誤；</li>
            //                         <li>如果你是個人，你年滿18歲；</li>
            //                         <li>該帳戶僅供你自己使用，你並非以任何其他方的受託人或代理人或代表的身分使用該帳戶；</li>
            //                         <li>如果你是公司，則代表你註冊帳戶的人員已獲得你的適當授權。</li>
            //                     </ul>
            //                 </li>
            //                 <li>你須保密帳戶登入信息，不得向任何第三方披露此等信息，也不得允許任何第三方使用你的帳戶。</li>
            //                 <li>如果你認為某人正在使用或已使用你的帳戶，或者你認為或知道你的登入信息已為第三方所知，請立即通知我們。</li>
            //                 <li>在我們確認收到你根據第6條作出的通知之前，你須對任何人使用你的登入信息在你的帳戶作出的所有活動負責。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>暫停或終止帳戶</strong>
            //             <ol>
            //                 <li>為保障你及／或我們的利益，我們有權隨時暫停你的帳戶，恕不另行通知。一旦我們暫停你的帳戶，你將收到通知。帳戶暫停後，你將無法發布新的泊車位或預訂泊車位，直至我們酌情決定重新啟動你的帳戶。在帳戶暫停期間，你將無法預訂任何泊車位、發布任何泊車位或授予任何泊車位的特許。</li>
            //                 <li>如果你違反你簽訂的本《條款》或《特許協議》，或我們全權絕對酌情認為你繼續使用帳戶將影響我們的聲譽或利益，我們有權隨時終止你的帳戶，恕不另行通知，而我們亦毋須為此承擔任何責任。我們可以在給予你不少於30天通知的情況下，隨時終止你的帳戶，而毋須給予任何理由。</li>
            //                 <li>你可以按照我們不時公佈的程序隨時終止帳戶。</li>
            //                 <li>無論任何原因導致你的帳戶被暫停或終止，都不得損害：－
            //                     <ul>
            //                         <li>在帳戶終止或暫停之前已完成的任何預訂以及相關《特許協議》的有效性及／或繼續，惟《特許協議》須受其條款約束；</li>
            //                         <li>我們就所有已完成的預訂及相關《特許協議》收取服務費用或手續費的權利；</li>
            //                         <li>在帳戶終止或暫停之前你通過帳戶產生的責任；及</li>
            //                         <li>你根據第4、8.5、12.2(b)、(c)、12.4、12.5、13、14、15、16至21條負有的義務或責任，以及在帳戶暫停期間或終止之後須繼續有效的其他規定。</li>
            //                     </ul>
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>你的風險</strong>
            //             <ol>
            //                 <li>你使用平台，即表示你承認由於互聯網和在線交易的性質，你通過平台向泊車位授予和獲取特許存在風險。特別是，你承認並同意承擔以下風險：業主有可能不擁有相關泊車位、業主未必有權向你授予相關泊車位的特許、業主或車主的身分未必真實。</li>
            //                 <li>為減低第1條所述的風險，我們會採用合理的商業努力來核實泊車位的擁有權，我們將會在香港特別行政區政府的土地註冊處對該等泊車位進行土地查冊，並將結果與業主提供的詳細信息配對。你承認我們不可能進行進一步驗證，並且我們對你因以包括但不限於以下原因而就特許遭受的任何損失不承擔任何責任：業主並非泊車位的擁有人，及／或未經泊車位的擁有人授權，或向你授予或被你授予特許的人的身分是虛假。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>發布泊車位</strong>
            //             <ol>
            //                 <li>你可以按照我們不時公佈的程序發布你擁有或你有權管理的泊車位，並向我們提供泊車位內容，包括但不限於照片、地址、停泊限制、取用信息、泊車位適用的車輛類型以及我們可能要求用以驗證你的身分和泊車位擁有權的其他信息或文件。</li>
            //                 <li>我們有絕對酌情權決定是否在平台上發布泊車位並從平台刪除任何泊車位。</li>
            //                 <li>泊車位的業主完成在平台上發布泊車位的程序，即表示其向我們和其他用戶保證並承諾：
            //                     <ul>
            //                         <li>你是該泊車位的擁有人，或獲業主授權根據《特許協議》的條款透過平台授予該泊車位的特許；</li>
            //                         <li>你所提供的有關該泊車位的泊車位內容是準確及最新的，並包含所有必要的資料，以便任何人決定是否使用該泊車位；及</li>
            //                         <li>一旦泊車位內容和你就發布泊車位而提交給我們的其他信息有任何變更，你須立即向我們提供最新的信息。</li>
            //                     </ul>
            //                 </li>
            //                 <li>泊車位的擁有人於平台上發布泊車位時同意：－
            //                     <ul>
            //                         <li>授權我們在泊車位於平台上發布期間按照本《條款》和《特許協議》的條款向用戶宣傳泊車位及安排該泊車位的特許；</li>
            //                         <li>委任泊車易根據本《條款》及《特許協議》的條款管理該泊車位的預訂；</li>
            //                         <li>授權我們根據本《條款》及《特許協議》的條款收取任何按金、泊車費用和車主應付給你的其他付款，並代表你退還或退回任何按金或按金餘額；</li>
            //                         <li>授予我們不可撤銷的免版稅永久特許，以使用你提交的泊車位內容，來在平台上宣傳相關泊車位的特許，並以我們絕對酌情認為合適的任何其他方式使用你提交的泊車位內容，及將之用作宣傳平台。</li>
            //                     </ul>
            //                 </li>
            //                 <li>我們有權但沒有義務在泊車位於平台上發布之前或之後檢查泊車位及該泊車位的其他擁有權文件或信息，惟前提是我們的任何檢查或調查不影響在本《條款》下業主的責任和保證。</li>
            //                 <li>我們在平台上發布泊車位及／或顯示泊車位作為搜索或篩選結果並不代表我們建議或認可該泊車位。</li>
            //                 <li>業主可以按照我們不時公佈的程序刪除他在平台上發布的任何泊車位，惟前提是泊車位的刪除不得影響刪除前已完成的預訂和《特許協議》的效力及作用，也不得影響與預訂和《特許協議》有關而應付的服務費用或手續費。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>費用</strong>
            //             <ol>
            //                 <li>作為在平台上發布泊車位並根據本《條款》和《特許協議》的條款管理該泊車位的預訂的代價，業主須按以下條款就該泊車位的每項預訂支付服務費用給泊車易：
            //                     <ul>
            //                         <li>服務費用須為車主在預訂的整個期間內須向業主支付的經協定泊車費用總額的10%；</li>
            //                         <li>在不損害我們就該預訂或涉及同一業主的任何其他預訂，從業主所支付的任何款項中扣除服務費用的權利的情況下，業主須在預訂完成後因應我們的要求，將與預訂有關的服務費用支付給我們；</li>
            //                         <li>為免生疑問，即使與預訂有關的特許因任何原因，在業主和車主有或沒有給予同意的情況下提前終止或暫停，業主在整個預訂期間內支付經協定服務費用的義務仍不受影響，而服務費用須在該預訂的相關特許因任何原因提前終止後20個營業日內支付。</li>
            //                     </ul>
            //                 </li>
            //                 <li>除了服務費用之外，我們須向業主收取的手續費的金額，相等於我們代表業主就預訂或相關《特許協議》收取的總金額（除泊車費用及按金外）的10%。我們將先從收到的所有相關金額中扣除此手續費，然後才根據第2條把餘額交給業主；</li>
            //                 <li>就預訂支付服務費用和手續費的義務不受以下因素影響：因任何原因而導致業主或車主的帳戶在及後暫停、關閉或終止；預訂在及後取消或終止；業主向車主退回泊車費用的任何部分。</li>
            //                 <li>服務費用或手續費一經支付，將不予退還。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>預訂泊車位</strong>
            //             <ol>
            //                 <li>如果你想預訂發布為可供<strong><em>即時</em></strong><strong><em>預訂</em></strong>的泊車位：
            //                     <ul>
            //                         <li>你可以在預訂泊車位時選擇所需泊車位和可用時段；按照屏幕上的說明輸入相關信息，並支付泊車費用；</li>
            //                         <li>一旦確認泊車費用成功支付，即表示完成預訂所選泊車位的所選時段，然後我們會向你發送確認書，當中述明預訂的各項細節；</li>
            //                         <li>如果因任何原因付款被拒或未成功，預訂將未能完成；及</li>
            //                         <li>除非另有明確規定，否則在特別時段內只會有一項有效的預訂，同一時段不允許多項停泊。</li>
            //                     </ul>
            //                 </li>
            //                 <li>如果你希望預訂發布為<strong><em>按協議</em></strong><strong><em>預訂</em></strong>的泊車位：－
            //                     <ul>
            //                         <li>你可以按照平台上的說明選擇泊車位和可用時段，要求預訂任何泊車位；</li>
            //                         <li>泊車位的業主須有24小時考慮是否接受通過平台提出的預訂要求，惟前提是如果業主在該要求提出後24小時內未接受該要求，該要求須被視為已被拒；</li>
            //                         <li>業主接受預訂的條件是你需要支付必要的費用；</li>
            //                         <li>一旦業主接受了你的預訂要求，你須在24小時內通過平台提供車輛信息，並按照付款指示支付泊車費用和按金（如適用），藉此完成預訂。車輛信息提供並且付款成功後，你對所選泊車位的所選時段的預訂即為完成，然後我們將向你發送確認書；</li>
            //                         <li>如果你在我們通知你預訂要求已被接受後24小時內未完成預訂，預訂將取消。</li>
            //                     </ul>
            //                 </li>
            //                 <li>你要求作出預訂，即表示你確認自己是將使用泊車位的車輛的業主，並且該車輛的司機持有有效的駕駛執照。</li>
            //                 <li>有關時租車位預訂，泊車易將在車主登記信用卡進行信用卡預授權及收取港幣$300按金，以防任何逾時停泊費用等問題。當預訂服務完成後並沒有逾時停泊問題，泊車易將向車主退還港幣$300按金。而業主則有權利和義務責任向泊車易提供有關車主逾期泊車之証明文件作案件跟進，同時泊車易將會向業主收取有關服務費用。</li>
            //                 <li>
            //                     <p>有關月租/共享車位預訂，泊車易將根據車主於平台預訂車位的服務生效日而有不同的泊車費用計算類型。</p>
            //                     <p>(a) 若服務的生效日期於1號，車主需繳付該預訂月份之泊車費用。</p>
            //                     <p>(b) 若泊車服務的生效日期於2號，車主需繳付該預訂月份之泊車費用 (按比例) 及下一個月份的泊車費用。</p>
            //                     <p>(c) 與此同時，未繳付的餘下月份之收費，泊車易將透過自動付款機制於每月的26號向車主收取費用。</p>
            //                 </li>
            //                 <li>預訂完成後，除非按照《特許協議》中明確規定的條款准予取消，否則預訂不能取消。</li>
            //                 <li>如果你擔心泊車位及其通道是否適合你的車輛，請先通過平台聯絡業主或在業主同意的情況下前往泊車位，然後才作出預訂，因為除非涉及泊車位內容不準確的問題，否則預訂不能因為取用或停泊問題而取消。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>泊車位的特許</strong>
            //             <ol>
            //                 <li>你同意，預訂的特許須按照在預訂完成之日存在的《特許協議》版本中規定的條款。你進一步同意，未經泊車易明確同意，業主與車主不得藉訂立協議來更改《特許協議》的條款。因此，於平台上發布任何泊車位之前，或作出或確認任何預訂要求前，請務必閱讀並理解本網站上公佈的最新版本的《特許協議》。《特許協議》可能會不時更改，因此你須定期查看。</li>
            //                 <li>你承認並同意，儘管我們在特許中扮演的角色，泊車易並非《特許協議》的一方，也不就《特許協議》或預訂對業主或車主負責。</li>
            //                 <li>預訂和相關《特許協議》的繼續和有效性不受業主或車主的帳戶的終止或暫停所影響。</li>
            //                 <li>如果本《條款》與《特許協議》有任何不符，概以《特許協議》的條款為準。</li>
            //                 <li>我們可能會不時在平台上發布《特許協議》的條款的最新版本，作為經修訂版本，惟前提是《特許協議》的任何修訂不會影響於經修訂版本生效前已完成的預訂的條款。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>泊車費用及其他付款</strong>
            //             <ol>
            //                 <li>在不抵觸《特許協議》的條款的情況下，你同意泊車費用和按金（如適用）以及車主須向業主支付的與泊車位有關的任何其他金額，須按照在平台上規定的方式支付給我們，而業主和車主之間不得直接結算任何金額。所有這些金額須由我們代表業主收取。</li>
            //                 <li>在我們收到車主應付給業主的任何金額（除按金外）及訂單開始後5個營業日內，我們須從中扣除適用的服務費用或手續費，並根據第6條將餘額支付至業主指定的銀行帳戶。資金一經支付到該銀行帳戶，我們的義務即告解除。</li>
            //                 <li>你同意儘管泊車易作為泊車位管理人的角色，及其就預訂收取泊車費用和其他付款，但除第4條規定的付款以外，泊車易不就任何泊車費用或任何其他付款的支付或退款，對業主或車主負責。</li>
            //                 <li>除非《特許協議》中明確提及，否則車主向業主支付的金額均不予退還。根據《特許協議》進行的任何退款須通過泊車易進行，惟前提是我們無責任向車主支付任何退款，除非我們為業主持有資金或業主已存放資金給我們用作退款用途。</li>
            //                 <li>如果業主要求就泊車位支付按金：－
            //                     <ul>
            //                         <li>按金金額須根據平台上就有關泊車位列出的金額；</li>
            //                         <li>業主特此授權我們按照相關《特許協議》的條款處理此筆按金；</li>
            //                         <li>泊車易將為業主持有按金，並且未經業主授權，泊車易毋須向車主支付或退還按金或按金餘額，惟前提是在特許終止時，如果業主沒有通知泊車易在終止後的2個營業日內從按金中扣除任何款項，則泊車易須被視為已獲授權退還按金（或按金的餘額）。</li>
            //                     </ul>
            //                 </li>
            //                 <li>如果我們代表業主通過信用卡從車主收取任何金額，而我們因任何理由而被要求退還通過該信用卡支付的任何款項（「退款」）：－
            //                     <ul>
            //                         <li>我們不會被要求根據第2條向業主支付退款金額，而如果業主須就未支付退款金額而提出任何索賠，他須針對車主提出該索賠；</li>
            //                         <li>如果我們已經根據本《條款》第2條向業主支付了所收取的金額，業主須根據我們的要求支付退款金額給我們；</li>
            //                         <li>不損害第6(b)條的情況下，我們保留權利根據第11.6(b)條將業主應付給我們的任何款項用以抵銷我們於當時或之後應付予業主的任何款項。</li>
            //                     </ul>
            //                 </li>
            //                 <li>如果車主就預訂應支付任何金額，車主同意我們可以按我們認為合理的金額和期限從車主提供的信用卡獲得預先授權，以保證車主應付的金額及你就該預訂可能支付的任何其他款項獲支付。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>平台的內容</strong>
            //             <ol>
            //                 <li>你承認所有泊車位內容均由業主提供，我們不負責對其進行核實。</li>
            //                 <li>如果我們酌情決定允許你將訊息上載至平台：－
            //                     <ul>
            //                         <li>你上載的訊息必須是為合法目的上載，且訊息不得為猥褻、非法、侮辱性、威脅性、誹謗或侵犯任何第三方的所有權權利的性質；</li>
            //                         <li>你授予我們不可撤銷的免版稅永久特許，以將你的訊息內容用於我們認為合適的任何目的；</li>
            //                         <li>你同意我們可以（但沒有義務）按我們絕對酌情認為合適的方式編輯或取消你上載的任何訊息。</li>
            //                     </ul>
            //                 </li>
            //                 <li>我們不對用戶上載到平台的任何訊息的內容負責，而我們在平台上發布訊息不得被視為我們對其內容的認同或認可。</li>
            //                 <li>除非我們另有規定，否則除泊車位內容和你上載的訊息之外，平台上的所有內容的版權均屬於泊車易，並且泊車易並無就上述內容以任何方式授予任何特許或權利。</li>
            //                 <li>除非你是上述內容的版權業主，否則你不得複製、修改、更改、出版、廣播、分銷、銷售或轉讓平台上的任何內容，並且不得使用任何內容作除了查看或預訂泊車位以外的用途。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>禁止的行為</strong>
            //             <ol>
            //                 <li>你同意你不得：
            //                     <ul>
            //                         <li>在平台上發布或上載任何猥褻、非法、侮辱性、威脅性、誹謗或侵犯任何第三方的所有權權利的內容；</li>
            //                         <li>將平台用於任何非法目的，或除了發布或查看泊車位或授予泊車位的特許以外的目的；</li>
            //                         <li>作出任何虛假、非法或未經授權的預訂；</li>
            //                         <li>使用任何數據挖掘、機械人或類似的數據收集和提取工具，以從平台取用或收集數據或內容，或與平台進行互動；</li>
            //                         <li>干擾或試圖干擾平台的操作或界面，或更改平台上的任何內容，或上載任何病毒或被破壞的數據，或對平台採取任何行動或與平台作出任何互動，以致可能影響平台的性能或令平台超出負荷；或</li>
            //                         <li>採取任何行動以未經授權地取用或試圖未經授權地取用你無權取用的平台的一部分或數據，或獲取在平台上未公開提供的任何信息。</li>
            //                     </ul>
            //                 </li>
            //                 <li>在你的帳戶存續期間以及帳戶因任何理由關閉後12個月內，未經泊車易明確同意，你不得試圖聯絡任何其他用戶，以在收費或免費基礎上獲取或提供任何泊車位的特許，或以任何方式試圖繞過平台獲取或提供任何泊車位的特許。並且在不損害泊車易在本條款被違反的情況下所擁有的任何其他權利或補救措施的情況下，業主須向泊車易支付違約賠償金，賠償金金額相等於如果業主和車主透過平台達成該特許的話，業主本來須支付給泊車易的服務費用金額。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>免責聲明和法律責任的限度</strong>
            //             <ol>
            //                 <li>我們盡了一切合理的商業努力來確保平台能夠按預期般操作並且不存在漏洞、錯誤或病毒，但由於平台的性質，我們在法律允許的範圍內不能就平台提供的信息或其運作向你給予保證或被視為向你給予保證。</li>
            //                 <li>雖然我們盡了一切合理的商業努力對業主與車主之間的任何爭議進行調解，但我們毋須就《特許協議》的任何一方的任何行為或不作為或任何一方違反《特許協議》承擔任何責任。</li>
            //                 <li>在法律允許的範圍內，泊車易根的據本《條款》就預訂承擔的責任僅限於我們就預訂所收到的服務費用，而我們毋須就任何利潤損失、罰款、相應而生的損失或任何形式的損害賠償承擔任何責任，無論該等損失是否各方預計之內以及雙方是否已被告知出現該等損失的可能性。</li>
            //                 <li>如果你已同意本《條款》或《特許協議》的規定為最終且對你具有約束力，則我們毋須就我們根據該等規定而作出的任何事實決定，對你承擔任何責任。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>彌償</strong>
            //             <ol>
            //                 <li>你須在所有時候均就以下項目確保令泊車易及其高級人員及僱員及代理人獲得彌償：由於你違反本《條款》或本《特許協議》、泊車位的特許或泊車易在本《條款》或《特許協議》下的任何角色，而導致泊車易及其高級人員及僱員及代理人可能蒙受、負責或產生或與之相關的任何性質的訴訟、程序、索賠、要求、成本、損失、稅項、損害和開支。</li>
            //                 <li>上述彌償在特許終止後繼續有效。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>本網站的連結</strong>
            //             <ol>
            //                 <li>未經我們明確同意，你不得將平台與任何其他網站連結。</li>
            //                 <li>平台可能包含連繫到其他網站的連結，如你前往這些網站，你須自行承擔風險。我們不對這些網站提供的任何內容或商品或服務負責，而我們也沒有認可或推薦這些網站的操作。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>對本《條款》的修訂</strong>
            //             <ol>
            //                 <li>我們可能會通過在網站上發布本《條款》的經修訂版本並註明新版本的生效日期，來對本《條款》作出修訂。</li>
            //                 <li>如果你不接受本《條款》的經修訂版本，你須根據第3條終止帳戶。你在本《條款》的經修訂版本的生效日期之後繼續使用帳戶，即被視為你接受此經修改版本。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>業主</strong><strong>與</strong><strong>車主</strong><strong>之間的傳訊</strong>
            //             <ol>
            //                 <li>在完成預訂之前，用戶之間就泊車位進行的任何傳訊均須通過平台進行，並須由我們監察。如果我們認為你發給其他用戶的訊息或其他用戶發給你的訊息構成試圖繞過平台獲取泊車位的特許，或我們認為訊息的內容屬猥褻、非法、侮辱性、威脅性、誹謗或侵犯任何第三方的所有權權利，我們有權不發出該等訊息。</li>
            //                 <li>預訂完成後，我們須為該預訂的目的，將相關業主和車主聯繫上，惟前提是此聯繫不得影響他們在本《條款》或《特許協議》下的義務。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>通知</strong>
            //             <ol>
            //                 <li>除非本《條款》另有規定，否則我們向你發出的任何通知或文件須發送至你在註冊帳戶時指定並可不時修訂的電郵地址，此等通知或文件在發送時即被視為已由你收悉。</li>
            //                 <li>你可以按照我們不時公佈的程序，更改你根據第19條所提供用以收取通知的電郵地址，而此更改須於你收到我們的確認後生效。</li>
            //                 <li>你同意，如果通知或文件發送至本條所述的電郵地址，其須在被發送時被視為已收悉，並且即使電郵及後被退回或未送達，也不會影響該通知的有效性。</li>
            //                 <li>你可透過電郵、郵寄或專人送遞方式，向我們為此目的不時發布的電郵地址或地址發送任何通知或文件，而只有在我們實際收到該通知或文件時，該通知或文件才被視為已收悉。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>其他</strong>
            //             <ol>
            //                 <li>如果本《條款》的任何條款在任何方面在任何法律下屬於或成為無效、非法或不可強制執行，其餘條款的有效性、合法性和可強制執行性不得以任何方式受到影響或受到損害，其餘條款和條件仍須具有十足的效力和作用。</li>
            //                 <li>在本《條款》中明確列出的有關你的違約行為的任何補救措施或權利，須不影響並附加於我們根據法律或本《條款》的其他部分或我們與你訂立的任何其他協議所享有的其他權利或補救措施。</li>
            //                 <li>你在本《條款》下的權利和責任不能轉讓給任何其他人。</li>
            //                 <li>我們可以通過給予你書面通知將我們在本《條款》下的權利和責任轉讓給任何第三方，而你須採取我們要求的一切合理行動來使上述轉讓生效。</li>
            //                 <li>如果我們對你違反本《條款》作出任何棄權，此棄權須僅在該情況適用並為該目的適用，並且即使我們沒有或延遲行使或強制執行本《條款》下的任何權利，也不得被視作棄權。</li>
            //                 <li>除非經獲授權人士明確書面同意，否則我們在本《條款》下的任何權利均不獲豁免。</li>
            //                 <li>如果本《條款》的中英文版本有任何不符，概以英文版本為準。</li>
            //                 <li>《合同（第三方權利）條例》不適用於本《條款》，而除各方以外的任何人均不享有本《條款》下的任何權利，並且本《條款》也不得由雙方以外的任何人根據《合同（第三方權利）條例》強制執行。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>管限法律</strong>
            //             <ol>
            //                 <li>本《條款》受香港特別行政區法律管限並按其解釋。</li>
            //                 <li>你同意服從香港特別行政區法院的非專屬管轄權。</li>
            //              </ol>
            //            </li>
            //           </ol>
            //        </div>
        );
    }
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default Terms_zh_HK;
