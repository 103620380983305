import {apiFetch, apiPost, buildParam} from "./General";

export const getEnterpriseBookings = (token, callback, errorCallback) => {
    let params = {
        access_token: token
    }
    apiFetch('bookings/user/order/', params, callback, errorCallback, true);
}

export const getEnterpriseInvoices = (token, callback, errorCallback) => {
    let params = {
        access_token: token
    }
    apiFetch('invoices/user/', params, callback, errorCallback, true);
}

export const getEnterpriseUserInfo = (token, mid, callback, errorCallback) => {
    let params = {
        access_token: token,
        mid: mid
    }
    apiFetch('users/me/', params, callback, errorCallback, true);
}

export const getEnterpriseInvoiceURL = (token, invoice_id, callback, errorCallback) => {
    let params = {
        access_token: token
    }
    apiFetch(`invoices/receipt/${invoice_id}`, params, callback, errorCallback, true);
}

export const cancelEnterpriseInvoice = (token, invoice_id, callback, errorCallback) => {
    let body = {
        status: 'pendingtopayment'
    }
    apiPost(`invoices/update/inv/${invoice_id}?access_token=${token}`, body, callback, errorCallback, true);
}

export const getEnterpriseToken = (token, callback, errorCallback) => {
    let params = {
        access_token: token
    }
    apiFetch(`sso/link/getToken/`, params, callback, errorCallback);
}

export const getEnterpriseMerchants = (token, callback, errorCallback) => {
    let params = {
        access_token: token
    }
    apiFetch(`aggregator/accounts/`, params, callback, errorCallback, true);
}
