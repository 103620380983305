import React from 'react';

function Verified(props) {
    const verified = true; // props.verified

    return (
        <span className={"verified " + (verified ? 'active' : '')} ></span>
    )
}

export default Verified;