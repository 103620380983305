import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {mongoIdTimestamp} from "../../../utils/00General/Helpers";

function M1(props) {
    const {
        t,
        i18n,
        message,
        space,
        booking,
        isOwner,
        renderSpace,
        toUser,
        user
    } = props;

    let toUserSafe = toUser || {};
    let owner = toUserSafe._id == booking.owner._id ? toUserSafe : user;
    let booker = toUserSafe._id == booking.booker._id ? toUserSafe : user;
    let isCorp = false;
    if (owner) isCorp = owner.isCorp;

    if (isOwner) {
        return (
            <div className="response">
                <div className="info border clearfix">
                    <h5>{mongoIdTimestamp(message._id, "LLL")} </h5>

                    { renderSpace() }
                </div>

                <div className="centerBox">
                    <h3>
                        {t("messenger:cards.M1.owner.headline")}
                    </h3>

                    <div className="sep-10" />

                    <p>
                        {t("messenger:cards.M1.owner.p1")}
                    </p>

                    <p>
                        {t("messenger:general.booking_number")}: {booking._id}
                    </p>

                    <p>
                        {t("messenger:general.parker_name")}: {booker.first_name}
                        <br/>
                        {t("messenger:general.contact")}: {booker.phone}
                        {
                            isCorp && (
                                <div>{t("messenger:general.car_plate")}: {booking.vehicle.carplate}</div>
                            )
                        }
                    </p>

                    <p className="please">
                        <Link to={"/" + i18n.language + "/space/" + space._id} className="button">{t("messenger:general.go_to_listing")}</Link>
                        <br/>
                        {t("messenger:general.thank_you_1")}
                    </p>
                </div>
            </div>
        )
    } else {
        return (
            <div className="response">
                <div className="info border clearfix">
                    <h5>{mongoIdTimestamp(message._id, "LLL")} </h5>

                    { props.renderSpace() }
                </div>

                <div className="centerBox">
                    <h3>
                        {t("messenger:cards.M1.booker.headline")}
                    </h3>

                    <div className="sep-10" />

                    <p>
                        {t("messenger:cards.M1.booker.p1")}
                    </p>

                    <p>
                        {t("messenger:general.booking_number")}: {booking._id}
                    </p>

                    <p>
                        {t("messenger:general.owner_name")}: {owner.first_name}
                        <br/>
                        {t("messenger:general.contact")}: {isCorp? owner.email : owner.phone}
                    </p>

                    <p className="please">
                        <Link to={"/" + i18n.language + "/memberBookings"} className="button">{t("messenger:general.go_to_bookings")}</Link>
                        <br/>
                        {t("messenger:general.thank_you_2")}
                    </p>
                </div>
            </div>
        )
    }
}
export default withTranslation()(M1);
