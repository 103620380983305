import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';

class ThreeSteps extends Component {
    //
    // constructor(props) {
    //     super(props);
    //     const {dispatch} = props;
    // }

    render() {

        const {
            t,
            // i18n
        } = this.props;

        if (this.props.selected === 1) {
            return (
                <div className="wrapperThreeSteps">
                    <ul className="threeSteps clearfix">
                        <li className="active">1</li>
                        <li>2</li>
                        <li>3</li>
                        <span className="line" />
                    </ul>

                    <p>{t("listASpace:stepShowing.step1")} <span>{this.props.stepsLeft}</span> {t("listASpace:stepShowing.step2")}!</p>
                </div>
            )
        } else if (this.props.selected === 2) {
            return (
                <div className="wrapperThreeSteps">
                    <ul className="threeSteps clearfix">
                        <li>1</li>
                        <li className="active">2</li>
                        <li>3</li>
                        <span className="line" />
                    </ul>

                    <p>{t("listASpace:stepShowing.step1")}<span>{this.props.stepsLeft}</span>{t("listASpace:stepShowing.step2")}!
                    </p>
                </div>
            )
        } else {
            return (
                <div className="wrapperThreeSteps">
                    <ul className="threeSteps clearfix">
                        <li>1</li>
                        <li>2</li>
                        <li className="active">3</li>
                        <span className="line" />
                    </ul>

                    <p>{t("listASpace:stepShowing.step1")} <span>{this.props.stepsLeft}</span> {t("listASpace:stepShowing.step2")}!</p>
                </div>
            )
        }
    }
}

export default withTranslation()(ThreeSteps);
