// reference: https://github.com/google-map-react/old-examples/blob/master/web/flux/components/examples/data/gen_markers_data.js
import immutable from "immutable";

// really really nice but big size library
// import { seed } from "faker/vendor/mersenne.js";
// import faker from "faker";

import * as Helper from "../utils/03FindParkingSpace/LowestHourlyPrice";

// const K_PITER_LAT_LNG = { lat: 22.28552, lng: 114.15769 };

// function imageRndUrl(width = 180, height = 128) {
// 	const categories = [
// 		"abstract",
// 		"animals",
// 		"business",
// 		"cats",
// 		"city",
// 		"food",
// 		"nightlife",
// 		"fashion",
// 		"people",
// 		"nature",
// 		"sports",
// 		"technics",
// 		"transport"
// 	];
// 	const category =
// 		categories[faker.random.number({ max: categories.length - 1 })];
// 	const index = 1 + faker.random.number({ max: 8 });
// 	return `http://lorempixel.com/${width}/${height}/${category}/${index}/`;
// }

// // something like normal distribution around 0.5
// function nLikeRnd() {
// 	const K_N = 10;
// 	let sum = 0;
// 	for (let i = 0; i < K_N; ++i) {
// 		sum += faker.random.number({ max: 1, precision: 0.0001 });
// 	}

// 	return sum / K_N;
// }

export default function genMarkersData({
	// count,
	// seed: seedNumber,
	// test,
	// latVarM,
	// lngVarM,
   result_booking_type,//2019-12-11 Samson
	spaces
}) {
	// seed(seedNumber);

	let markersData = new immutable.Range(0, spaces.length)
		.map(
			i => {
				const space = spaces[i].data;

				// const lowestPrice = Helper.lowestPrice(space.hourly.days);
				//2019-12-10 Samson - show lowest price base on type available
                let lowestPrice = 0;
                if (typeof (space.hourly.from) !== "undefined" && result_booking_type === "hourly") {
                    lowestPrice = Helper.lowestPrice(space.hourly.days);
                } else if (typeof (space.monthly.from) !== "undefined" && result_booking_type === "monthly") {
                    lowestPrice = space.monthly.price;
                } else if (typeof (space.timeshare.from) !== "undefined" && result_booking_type === "timeshare") {
                    lowestPrice = space.timeshare.price;
                }

				return new immutable.Map({
					id: space._id,
					// lat: K_PITER_LAT_LNG.lat + (latVarM || 1.5) * (nLikeRnd() - 0.5),
					// lng: K_PITER_LAT_LNG.lng + (lngVarM || 1.5) * (nLikeRnd() - 0.5),
					lat: parseFloat(space.lat),
					lng: parseFloat(space.lng),
					reviews: space.reviews.length, //Math.floor(Math.random() * 100),
					rating: space.rating, //Math.random(),
					title: space.title,
					title_tc: space.title_tc,
					address: space.address,
					image: space.images[0],
					price: `$${lowestPrice}`,
					bcode: spaces[i].bcode
				})
			}
		)
		.toList(); // we need List not Seq

	// let markersData = spaces
	// 	.map(
	// 		space => {
	// 			// console.log(space.data.lat);
	// 			new immutable.Map({
	// 				id: space.data._id,
	// 				lat: space.data.lat,
	// 				lng: space.data.lng,
	// 				reviews: 13,
	// 				rating: 17,
	// 				address: space.data.address,
	// 				image: space.data.images,
	// 				price: `$$12`
	// 			})
	// 		});

	// let markersData = new immutable.Range(0, 5)
	// 	.map(
	// 		i =>
	// 			new immutable.Map({
	// 				id: "uuid_" + i,
	// 				lat: K_PITER_LAT_LNG.lat + (latVarM || 1.5) * (nLikeRnd() - 0.5),
	// 				lng: K_PITER_LAT_LNG.lng + (lngVarM || 1.5) * (nLikeRnd() - 0.5),
	// 				reviews: Math.floor(Math.random() * 100),
	// 				rating: Math.random(),
	// 				address: faker.address.streetAddress(),
	// 				image: imageRndUrl(),
	// 				price: `$${20 + i}`
	// 			})
	// 	)
	// 	.toList(); // we need List not Seq

	// if (test) {
	// 	markersData = markersData
	// 		.push(
	// 			new immutable.Map({
	// 				id: "guru-online",
	// 				lat: 22.308941,
	// 				lng: 114.224136,
	// 				reviews: nLikeRnd(),
	// 				rating: Math.random(),
	// 				address: "KOHO",
	// 				image: imageRndUrl(),
	// 				price: "$500"
	// 			})
	// 		)
	// 		.push(
	// 			new immutable.Map({
	// 				id: "tuspark",
	// 				lat: 22.31333,
	// 				lng: 114.21767,
	// 				reviews: nLikeRnd(),
	// 				rating: Math.random(),
	// 				address: "偉業街118號",
	// 				image: imageRndUrl(),
	// 				price: "$501"
	// 			})
	// 		);
	// }

	return markersData;
}
