import * as FindParkingSpaceActionTypes from '../actiontypes/findParkingSpace';

// Step One
// export const updateFilter = (Name, Value) => {
// 	return {
// 		type: FindParkingSpaceActionTypes.updateFilter,
// 		Name,
// 		Value
// 	};
// };

export const toPayment = (data) => {
	return {
		type: FindParkingSpaceActionTypes.toPayment,
		data,
	};
};

export const saveFilter = (data) => {
	return {
		type: FindParkingSpaceActionTypes.saveFilter,
		data,
	};
};

export const homeSearch = (data) => {
	return {
		type: FindParkingSpaceActionTypes.homeSearch,
		data,
	};
};
