import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import en_us_common from "./en-us/common.json";
import en_us_home from "./en-us/home.json";
import en_us_benefits from "./en-us/benefits.json";
import en_us_contact from "./en-us/contact.json";
import en_us_helpCenter from "./en-us/helpCenter";
import en_us_listASpace from "./en-us/listASpace";
import en_us_space from "./en-us/space";
import en_us_payment from "./en-us/payment";
import en_us_contactOwner from "./en-us/contactOwner";
import en_us_registerCorporate from "./en-us/registerCorporate";
import en_us_registerStart from "./en-us/registerStart";
import en_us_login from "./en-us/login";
import en_us_discover from "./en-us/discover";
import en_us_homepage from "./en-us/homepage";
import en_us_messenger from "./en-us/messenger";
import en_us_search from "./en-us/search";
import en_us_myAccount from "./en-us/myAccount";
import en_us_forgotPassword from "./en-us/forgotPassword";
import en_us_bookingConfirmation from "./en-us/bookingConfirmation";
import en_us_review from "./en-us/review";
import en_us_promotion from "./en-us/promotion";
import en_us_updatePayment from "./en-us/updatePayment";

import zh_hk_common from "./zh-hk/common.json";
import zh_hk_home from "./zh-hk/home.json";
import zh_hk_benefits from "./zh-hk/benefits.json";
import zh_hk_contact from "./zh-hk/contact.json";
import zh_hk_helpCenter from "./zh-hk/helpCenter";
import zh_hk_listASpace from "./zh-hk/listASpace";
import zh_hk_space from "./zh-hk/space";
import zh_hk_payment from "./zh-hk/payment";
import zh_hk_contactOwner from "./zh-hk/contactOwner";
import zh_hk_registerCorporate from "./zh-hk/registerCorporate";
import zh_hk_registerStart from "./zh-hk/registerStart";
import zh_hk_login from "./zh-hk/login";
import zh_hk_discover from "./zh-hk/discover";
import zh_hk_homepage from "./zh-hk/homepage";
import zh_hk_messenger from "./zh-hk/messenger";
import zh_hk_search from "./zh-hk/search";
import zh_hk_myAccount from "./zh-hk/myAccount";
import zh_hk_forgotPassword from "./zh-hk/forgotPassword";
import zh_hk_bookingConfirmation from "./zh-hk/bookingConfirmation";
import zh_hk_review from "./zh-hk/review";
import zh_hk_promotion from "./zh-hk/promotion";
import zh_hk_updatePayment from "./zh-hk/updatePayment";

i18n
// load translation using xhr -> see /public/locales
// learn more: https://github.com/i18next/i18next-xhr-backend
// .use(Backend)
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
// .use(LanguageDetector)
// pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: 'en-US',
        fallbackLng: 'en-US',
        debug: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        ns: ['common', 'contact'],
        defaultNS: 'common',
        resources: {
            'en-US': {
                common: en_us_common,
                home: en_us_home,
                benefits: en_us_benefits,
                contact: en_us_contact,
                helpCenter: en_us_helpCenter,
                listASpace: en_us_listASpace,
                space: en_us_space,
                payment: en_us_payment,
                contactOwner: en_us_contactOwner,
                registerCorporate: en_us_registerCorporate,
                registerStart: en_us_registerStart,
                login: en_us_login,
                discover: en_us_discover,
                homepage: en_us_homepage,
                messenger: en_us_messenger,
                search: en_us_search,
                myAccount: en_us_myAccount,
                forgotPassword: en_us_forgotPassword,
                bookingConfirmation: en_us_bookingConfirmation,
                review: en_us_review,
                promotion: en_us_promotion,
                updatePayment: en_us_updatePayment,
            },
            'zh-HK': {
                common: zh_hk_common,
                home: zh_hk_home,
                benefits: zh_hk_benefits,
                contact: zh_hk_contact,
                helpCenter: zh_hk_helpCenter,
                listASpace: zh_hk_listASpace,
                space: zh_hk_space,
                payment: zh_hk_payment,
                contactOwner: zh_hk_contactOwner,
                registerCorporate: zh_hk_registerCorporate,
                registerStart: zh_hk_registerStart,
                login: zh_hk_login,
                discover: zh_hk_discover,
                homepage: zh_hk_homepage,
                messenger: zh_hk_messenger,
                search: zh_hk_search,
                myAccount: zh_hk_myAccount,
                forgotPassword: zh_hk_forgotPassword,
                bookingConfirmation: zh_hk_bookingConfirmation,
                review: zh_hk_review,
                promotion: zh_hk_promotion,
                updatePayment: zh_hk_updatePayment,
            }
        }
    });

export default i18n;