import React from 'react';
import {useTranslation, withTranslation} from 'react-i18next';

function getHeightWithUnit(height) {
    const {t} = useTranslation();
    switch (height){
        case "1.7":
            return `1.7 ${t("space:tabDetails.feature.options.m")}`;
        case "1.8":
            return `1.8 ${t("space:tabDetails.feature.options.m")}`;
        case "1.9":
            return `1.9 ${t("space:tabDetails.feature.options.m")}`;
        case "2.0":
            return `2.0 ${t("space:tabDetails.feature.options.m")}`;
        case "2.1":
            return `2.1 ${t("space:tabDetails.feature.options.m")}`;
        case "2.2":
            return `2.2 ${t("space:tabDetails.feature.options.m")}`;
        case "2.3":
            return `2.3 ${t("space:tabDetails.feature.options.m")}`;
        case "2.4orAbove":
            return `2.4 ${t("space:tabDetails.feature.options.above")}`;
        default:
            return height;
    }
}

function Features(props) {
    const {t} = props;
    return (
        <ul className="attributes">
            {props.features.indexOf("Indoor") > -1 ?
                <li><img src={require('../../images/mobile/03_find_parking_space/icon_feature_1.png')}
                         alt=""/>{t("space:tabDetails.feature.options.indoor")}</li> : ''}
            {props.features.indexOf("WithCovers") > -1 ?
                <li><img src={require('../../images/mobile/03_find_parking_space/icon_feature_2.png')}
                         alt=""/>{t("space:tabDetails.feature.options.withCovers")}</li> : ''}
            {props.features.indexOf("NoCovers") > -1 ?
                <li><img src={require('../../images/mobile/03_find_parking_space/icon_feature_3.png')}
                         alt=""/>{t("space:tabDetails.feature.options.noCovers")}</li> : ''}
            {props.features.indexOf("EV") > -1 ?
                <li><img src={require('../../images/mobile/03_find_parking_space/icon_feature_4.png')}
                         alt=""/>{t("space:tabDetails.feature.options.ev")}</li> : ''}
            {props.features.indexOf("HeadRoom") > -1 ?
                <li><img src={require('../../images/mobile/03_find_parking_space/icon_feature_5.png')}
                         alt=""/>{t("space:tabDetails.feature.options.headroom")} ({getHeightWithUnit(props.height)})</li> : ''}
        </ul>
    )
}

export default withTranslation()(Features);