import * as Config from "../../config";
import store from '../../store';

const getAccessToken = () => {
    const state = store.getState();
    if (typeof(state.members.Login.userToken) !== "undefined")
        return state.members.Login.userToken;

    return '';
}


export const apiUploadImage = (image, type, rotate, cb, ecb) => {
    const accessToken = getAccessToken();
    const fullUrl = Config.API_URL_BASE + "upload?access_token=" + accessToken;

    let formData = new FormData();
    formData.append("image", image);
    formData.append('type', type);
    if (type === 'carpark')
        formData.append('rotate', rotate);

    fetch(fullUrl, {
        method: 'post',
        headers: new Headers({}),
        body: formData,
    })
        .then(response => response.json())
        .then(response => {
            if (typeof (cb) === "function") {
                // console.log(callback);
                cb(response);
            }
        })
        .catch(error => {
            // console.log("uplaod image error", error);
            if (typeof (ecb) === "function") {
                ecb(error);
            }
        });
}


export const apiFetch = (url, params, callback, errorCallback, isEnterpriseAPI = false) => {
    // const accessToken = getAccessToken();
    let fullUrl = Config.API_URL + url;

    if (isEnterpriseAPI) fullUrl = Config.ENTERPRISE_URL + url;

    if (params)
        fullUrl += "?" + buildParam(params);

    fetch(fullUrl)
        .then(r =>
            r.json().then(data => ({status: r.status, body: data}))
        )
        .then((obj) => {
            // console.log(fullUrl, "success", obj);
            if (typeof (callback) === "function") {
                // console.log(callback);
                callback(obj);
            }
        })
        .catch(error => {
            // console.log(fullUrl, "error", error);
            if (typeof (errorCallback) === "function") {
                errorCallback(error);
            // } else {
            //     window.location.href = Config.ROOT_PATH + 'logout';
            }
        });
}

export const apiPost = (url, body, callback, errorCallback, isEnterpriseAPI = false) => {
    let fullUrl = Config.API_URL + url;

    if (isEnterpriseAPI) fullUrl = Config.ENTERPRISE_URL + url;

    // const state = store.getState();
    // console.log(state.members);

    fetch(fullUrl, {
        method: 'post',
        headers: new Headers({
            // 'Authorization': 'Basic ' + btoa(Config.UAT_API_LOGIN + ':' + Config.UAT_API_PASSWORD),
            // 'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify(body)
    })
        .then(r =>
            r.json().then(data => ({status: r.status, body: data}))
        )
        .then((obj) => {
            if (typeof (callback) === "function")
                callback(obj);
        })
        .catch(error => {
            // console.log(error)
            if (typeof (errorCallback) === "function") {
                errorCallback(error);
            // } else {
            //     window.location.href = Config.ROOT_PATH + 'logout';
            }
        });
}

export const bcodeApiFetch = (url, params, callback, errorCallback) => {
    // const accessToken = getAccessToken();
    let fullUrl = Config.BCODE_API_URL + url;

    if (params)
        fullUrl += "?" + buildParam(params);

    fetch(fullUrl)
        .then(r =>
            r.json().then(data => ({status: r.status, body: data}))
        )
        .then((obj) => {
            // console.log(fullUrl, "success", obj);
            if (typeof (callback) === "function") {
                // console.log(callback);
                callback(obj);
            }
        })
        .catch(error => {
            // console.log(fullUrl, "error", error);
            if (typeof (errorCallback) === "function") {
                errorCallback(error);
                // } else {
                //     window.location.href = Config.ROOT_PATH + 'logout';
            }
        });
}

export const bcodeApiPost = (url, body, callback, errorCallback) => {
    let fullUrl = Config.BCODE_API_URL + url;

    // const state = store.getState();
    // console.log(state.members);

    fetch(fullUrl, {
        method: 'post',
        headers: new Headers({
            // 'Authorization': 'Basic ' + btoa(Config.UAT_API_LOGIN + ':' + Config.UAT_API_PASSWORD),
            // 'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify(body)
    })
        .then(r =>
            r.json().then(data => ({status: r.status, body: data}))
        )
        .then((obj) => {
            if (typeof (callback) === "function")
                callback(obj);
        })
        .catch(error => {
            // console.log(error)
            if (typeof (errorCallback) === "function") {
                errorCallback(error);
                // } else {
                //     window.location.href = Config.ROOT_PATH + 'logout';
            }
        });
}

// export const apiPut = (url, body, callback, errorCallback) => {
//     let fullUrl = Config.API_URL + url;
//
//     fetch(fullUrl, {
//         method: 'PUT',
//         headers: new Headers({
//             // 'Authorization': 'Basic ' + btoa(Config.UAT_API_LOGIN + ':' + Config.UAT_API_PASSWORD),
//             //'Accept': 'application/json',
//             'Content-Type': 'application/json',
//         }),
//         cache: 'no-cache',
//         body: JSON.stringify(body)
//     })
//         .then(r =>
//             r.json().then(data => ({status: r.status, body: data}))
//         )
//         .then((obj) => {
//             if (typeof (callback) === "function")
//                 callback(obj);
//         })
//         .catch(error => {
//             console.log(error)
//             if (typeof (errorCallback) === "function")
//                 errorCallback(error);
//         });
// }

export const buildParam = (params) => {
    return Object.keys(params).map(k => k + "=" + params[k]).join("&");
}
