import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as InviteeActionCreators from '../actions/invitee';

class Maintenance extends Component {
    render() {
        const {
            t,
        } = this.props;
        return (
            <div className="wrapper-container media">

                <div className="sep-60"/>

                <div className="containerMain">
                <div className="page-title"><h3>{t("common:General.MaintenanceContent")}</h3></div>
                </div>
            </div>
        );
    }
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default withTranslation()(Maintenance);
// connect(mapStateToProps)(About);