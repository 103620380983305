import * as ActionTypes from '../actiontypes/findParkingSpace';

const initialState = {
	order: [],
	Filter: [],
}

export default function FindParkingSpaceReducer(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.toPayment: {
			return {
				...state,
				order: []
			};
		}
		case ActionTypes.saveFilter: {
			return {
				...state,
				Filter: action.data
			};
		}
		case ActionTypes.homeSearch: {
			return {
				...state,
				Filter: {
					...state.Filter,
					search: {
						from: action.data.DateStart,
						to: action.data.DateEnd,
						address: action.data.address
					},
					filter: {
						vehicleClass: [],
						feature: [],
						typeParking: [action.data.type],
						headRoom: '3',
						instantBooking: [],
						distance: 30,
						price: {
							min: 0,
							max: 20000,
						},
					}
				}
			};
		}
		default:
			return state;
	}
}