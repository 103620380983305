import React from 'react';
// import { Link } from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {Link} from "react-router-dom";

function N14(props) {
    const {
        t,
        i18n,
        // data = null,
        time = "",
        // id = "",
    } = props;

    return (
        <div className="response">
            <div className="info border clearfix">
                <h5>{time}</h5>
            </div>

            <div className="centerBox">

                <div className="sep-10"/>

                <p className="preline">
                    {t("messenger:notifications.N14.p1")}
                </p>

                <p className="please">
                    {t("messenger:general.tell_us_what_you_think")}:
                    <br/>
                    <Link to={"/" + i18n.language + "/review/" + props.data.booking_id} className="button">{t("messenger:general.go_to_write_review")}</Link>
                    <br/>
                    {t("messenger:general.thank_you_2")}
                </p>
            </div>
        </div>
    )
}

export default withTranslation()(N14);