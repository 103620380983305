import React from 'react';
import {withTranslation} from 'react-i18next';

function InstantBooking(props) {
    const {t} = props;
    return (
        <p>
            {props.instant ?
                t("space:tabDetails.instantbooking.options.accept")
                :
                t("space:tabDetails.instantbooking.options.notAccept")
            }
        </p>
    )
}

export default withTranslation()(InstantBooking);