import React, { Component } from 'react';
import moment from 'moment';

class TimePickerRange extends Component {
    render() {
        let time = moment("2019-01-01 00:00:00"),
            TimeOption = [],
            // i = 1,
            startMin = true,
            stopMax = false,
            times = [];
        const minTime = this.props.minTime,
            maxTime = this.props.maxTime;

        if (minTime !== undefined)
            startMin = false;


        let endTime = moment("2019-01-02 00:00");
        while (time <= endTime) {
        // while (time.format("YYYY-MM-DD HH:mm") !== "2019-01-02 00:15") {
            let t = time.format("HH:mm");
            if (time.format("DD") === "02")
                t = "24:00";

            if (maxTime !== undefined && !stopMax) {
                if (t === maxTime)
                    stopMax = true;
            }

            if (startMin && !stopMax) {
                times.push(t);
            }

            if (minTime !== undefined && !startMin) {
                if (t === minTime)
                    startMin = true;
            }
            // time.add(15, 'm');
            time.add(1, 'h');
        }

        times.forEach((t, i) => {
            TimeOption.push(<option value={t} key={"timepickerrange-" + i}>{t}</option>);
        })

        return (
            TimeOption
        );
    }
}

export default TimePickerRange;
