import React, {Component} from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';

import {Redirect} from 'react-router';
import MemberMenuDesktop from '../../../components/02Members/MemberMenuDesktop';
import MemberMenuMobile from '../../../components/02Members/MemberMenuMobile';
import Space from '../../../components/02Members/Space';
import SpaceWBooking from "../../../components/02Members/SpaceWBooking";

import moment from 'moment';

// import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
// import * as MembersActionCreators from '../../actions/members';

import {getSpaceOwner, updateSpace} from '../../../utils/04Api/Space';
import {getBookingById, getSpaceBookings} from '../../../utils/04Api/Booking';

// import ListType2 from "../../../components/102Grids/ListType2";

import {filter} from "lodash";

// function Cluster(props) {
//     const spaces = props.spaces;
//     return spaces.map((space, i) =>
//         <Space
//             // space={space}
//             // key={i}
//             // isOwner={true}
//             key={i}
//             id={space._id}
//             count={i}
//             space={{data: space}}
//             lowestPrice="$0"
//             type={props.type}
//             getBooking={}
//         />
//     )
// }

class MemberListedSpaces extends Component {
    constructor(props) {
        super(props);

        let logined = false,
            myId = '';
        if (this.props.members.Login.length !== 0) {
            logined = true;
            myId = props.members.Login.user._id;
        }

        this.state = {
            logined: logined,
            myId: myId,
            spaces: [],
            pastBookings: [],
            currentBookings: [],
            bookingsLoaded: false,
            bookings: {},
            loading: true,
            filter1Show: false,
            filter2Show: false,
            filter3Show: false,
            filter1: {
                on: false,
                address: "",
                showAll: true,
                hourly: false,
                monthly: false,
                timeShare: false,
            },
            filter2: {
                on: false,
                address: "",
                showAll: true,
                hourly: false,
                monthly: false,
                timeShare: false,
                finished: false,
                declined: false,
                cancelled: false,
                expired: false,
                pending: true,
                confirmed: true,
            },
            filter3: {
                on: false,
                address: "",
                showAll: true,
                hourly: false,
                monthly: false,
                timeShare: false,
                finished: true,
                declined: true,
                cancelled: true,
                expired: true,
            },
            editSpaceId: null,
            spaceEditPopup: false,
            spaceEditPopupID: '',
            spaceEditPopupStatus: '',
        }

        this.getBooking = this.getBooking.bind(this);
        this.UpdateSpaceStatus = this.UpdateSpaceStatus.bind(this);
        this.goToEdit = this.goToEdit.bind(this);
    }

    componentDidMount = () => {
        this.ApiSpaces();
    }

    finishedLoading = () => {
        this.setState({
            ...this.state,
            loading: false,
        })
    }

    changeStateLevelOne = (data1, value) => {

        this.setState({
            [data1]: value
        })
    }

    changeStateLevelTwo = (data1, data2, value, cb) => {

        if (data2 === "showAll") {

            if (!this.state[data1].hourly && !this.state[data1].monthly && !this.state[data1].timeShare) {
                value = true;
            }

            this.setState({
                [data1]: {
                    ...this.state[data1],
                    showAll: value,
                    hourly: false,
                    monthly: false,
                    timeShare: false,
                }
            }, () => {
                if (typeof (cb) === "function")
                    cb();
            });
        } else {
            this.setState({
                [data1]: {
                    ...this.state[data1],
                    [data2]: value,
                    showAll: false,
                }
            }, () => {
                if (typeof (cb) === "function")
                    cb();

                if (!this.state[data1].hourly && !this.state[data1].monthly && !this.state[data1].timeShare) {

                    this.setState({
                        [data1]: {
                            ...this.state[data1],
                            showAll: true,
                            hourly: false,
                            monthly: false,
                            timeShare: false,
                        }
                    });
                }
            });
        }
    }

    goToEdit = (space_id, status) => {
        if (status !== -1) {//2019-07-24 samson able to edit when status != "disapproved"
            this.UpdateSpaceStatus(space_id, 2, true);
        } else {
            this.setState({
                ...this.state,
                editSpaceId: space_id
            })
        }
    }

    changeSpaceEditPopup = (id, status) => {

        this.setState({
            ...this.state,
            spaceEditPopup: !this.state.spaceEditPopup,
            spaceEditPopupID: id,
            spaceEditPopupStatus: status,
        });
    }

    UpdateSpaceStatus = (space_id, status, goToEdit) => {
        const data = {
            status: status,
        }

        const cb = (obj) => {
            let spaces = this.state.spaces;

            spaces = spaces.map((o) => {
                if (o._id === space_id)
                    o.status = status;
                return o;
            })

            this.setState({
                ...this.state,
                spaces: spaces
            }, () => {
                if (goToEdit) {
                    this.setState({
                        ...this.state,
                        editSpaceId: space_id
                    })
                }
            })
        }

        const ecb = (obj) => {
            console.log(obj);
            return null;
        }

        updateSpace(this.props.members.Login.userToken, space_id, data, cb, ecb);
    }

    ApiSpaces = (cb) => {
        let callback = (res) => {
            // console.log("getSpace",res.body);
            if (typeof (res.body) !== "undefined") {
                if (!res.body.length) {
                    this.setState({
                        ...this.state,
                        loading: false,
                        bookingsLoaded: true,
                    })
                    return;
                }

                const spaces = res.body;
                let total = spaces.length;
                let pastBookings = [];
                let currentBookings = [];

                spaces.forEach((space) => {
                    let callback = (res) => {
                        const bookings = res.body;
                        // console.log("fetched bookings", bookings);

                        const isCurrentBooking = processCurrentBooking(space, bookings);
                        if (isCurrentBooking)
                            currentBookings.push(isCurrentBooking);

                        const isPastBooking = processPastBooking(space, bookings);
                        if (isPastBooking)
                            pastBookings.push(isPastBooking);

                        // console.log("returned", pastBookings, currentBookings);

                        this.setState({
                            ...this.state,
                            pastBookings: pastBookings,
                            currentBookings: currentBookings,
                        }, () => {

                            total--;
                            // console.log(total);
                            if (total <= 0) {
                                this.setState({
                                    ...this.state,
                                    bookingsLoaded: true,
                                })
                            }
                        })
                    }

                    const done = (res) => {
                        // console.log(res);
                        this.setState({
                            ...this.state,
                            bookingsLoaded: true,
                        })
                    }

                    this.setState({
                        ...this.state,
                        spaces: res.body,
                        loading: false,
                    })

                    getSpaceBookings(this.props.members.Login.userToken, space._id, callback, done);
                })

                // this.setState({
                //
                // })

                // const now = moment().utcOffset(8);
                //
                // const spaceWithBooking = filter(res.body, (o) => {
                //     return o.booked.length;
                // })
                //
                // const spaceWithCurrentBooking = filter(spaceWithBooking, (o) => {
                //     for(let i=0; i<o.booked.length; i++) {
                //         const t = moment(o.booked[i].to).utcOffset(8);
                //         // console.log(now.format(), t.format());
                //         if (!t.isBefore(now))
                //             return true;
                //     }
                //     return false;
                // })
                //
                // const spaceWithPastBooking = filter(spaceWithBooking, (o) => {
                //     for(let i=0; i<o.booked.length; i++) {
                //         const t = moment(o.booked[i].to).utcOffset(8);
                //         // console.log(now.format(), t.format());
                //         if (t.isBefore(now))
                //             return true;
                //     }
                //     return false;
                // })
                //
                // console.log("spaceWithCurrentBooking", spaceWithCurrentBooking);
                //
                // this.setState({
                //     ...this.state,
                //     spaces: res.body,
                //     spaceWithCurrentBooking: spaceWithCurrentBooking,
                //     spaceWithPastBooking: spaceWithPastBooking,
                //     loading: false,
                // }, ()=> {
                //     spaceWithBooking.forEach((space)=>{
                //         space.booked.forEach((booking) => {
                //             this.fetchBooking(booking._id);
                //         })
                //     })
                // });
            }
        }
        getSpaceOwner(this.props.members.Login.user._id, callback, this.finishedLoading);


        const processCurrentBooking = (space, bookings) => {
            // current bookings
            const now = moment().utcOffset(8);

            let filteredBookings = filter(bookings, (booking) => {
                // console.log(booking.space._id, space._id)
                //2019-07-23 samson - using array to manage the status
                let status_for_finished = ['depositReleasing', 'complete', 'holdDeposit', 'completeWithDeposit'],
                    status_for_declined = ['rejected', 'rejectedBooker'],
                    status_for_cancelled = ['cancelled'],
                    status_for_expired = ['expired'];
                if (status_for_finished.includes(booking.status) || status_for_declined.includes(booking.status) || status_for_cancelled.includes(booking.status) || status_for_expired.includes(booking.status)
                ) {
                    // if (booking.status === 'rejected' || booking.status === 'expired' ||
                    //     booking.status === 'depositReleasing' || booking.status === 'complete' || booking.status === 'completeWithDeposit' ||
                    //     booking.status === 'cancelled' || booking.status === 'holdDeposit'
                    // ) {
                    return false;
                }

                if (booking.space._id === space._id) {
                    const booking_time = moment(booking.to).utcOffset(8);
                    if (!booking_time.isBefore(now))
                        return true;
                }
                return false;
            })

            // console.log(space);

            if (filteredBookings.length) {
                const s = {
                    bookings: filteredBookings,
                    space: space,
                }
                // console.log("return space", space._id, space);
                return s;
            }
            return false;
        }

        const processPastBooking = (space, bookings) => {
            // past booking
            const now = moment().utcOffset(8);

            const filteredBookings = filter(bookings, (booking) => {
                //2019-07-23 samson - using array to manage the status
                let status_for_finished = ['depositReleasing', 'complete', 'holdDeposit', 'completeWithDeposit'],
                    status_for_declined = ['rejected', 'rejectedBooker'],
                    status_for_cancelled = ['cancelled'],
                    status_for_expired = ['expired'];
                if (status_for_finished.includes(booking.status) || status_for_declined.includes(booking.status) || status_for_cancelled.includes(booking.status) || status_for_expired.includes(booking.status)
                ) {
                    // if (booking.status === 'rejected' || booking.status === 'expired' ||
                    //     booking.status === 'depositReleasing' || booking.status === 'complete' || booking.status === 'completeWithDeposit' ||
                    //     booking.status === 'cancelled' || booking.status === 'holdDeposit'
                    // ) {
                    return true;
                }

                if (booking.space._id === space._id) {
                    const booking_time = moment(booking.to).utcOffset(8);
                    if (booking_time.isBefore(now)) {
                        // console.log(space._id, booking.space._id, booking._id, booking.status);
                        return true;
                    }
                }
                return false;
            })

            if (filteredBookings.length) {
                const s = {
                    bookings: filteredBookings,
                    space: space,
                }
                // space.bookings = filteredBookings;
                // console.log("return space", space._id, space);
                return s;
            }
            return false;
        }
    }

    fetchBooking = (booking_id) => {
        let callback = (res) => {
            // console.log("fetched booking", res.body);

            let bookings = this.state.bookings;
            bookings[booking_id] = res.body;
            this.setState({
                ...this.state,
                bookings: bookings
            });
        }
        getBookingById(this.props.members.Login.userToken, booking_id, callback);
    }

    getBooking = (booking_id) => {
        const bookings = this.state.bookings;
        if (typeof (bookings[booking_id]) !== "undefined")
            return bookings[booking_id];
        return null;
    }

    // getBooking = (booking_id) => {
    //     let booking = this.findStateBooking(booking_id);
    //
    //     if (booking) {
    //         // found booking
    //         console.log("found booking", booking);
    //         return booking;
    //     } else {
    //         // not found booking
    //         let callback = (res) => {
    //             console.log("fetched booking", res.body);
    //
    //             if (!this.findStateBooking(booking_id)) {
    //                 let bookings = this.state.bookings;
    //                 bookings[booking_id] = res.body;
    //
    //                 this.setState({
    //                     ...this.state,
    //                     bookings: bookings
    //                 });
    //
    //                 console.log(bookings);
    //
    //                 cb(res.body);
    //             }
    //         }
    //         getBookingById(this.props.members.Login.userToken, booking_id, callback);
    //     }
    // }

    handleVerified = (id) => {
        this.setState({
            verified: {
                ...this.state.verified,
                [id]: !this.state.verified[id]
            }
        });
    }

    handleWishlist = (id) => {
        this.setState({
            wishlist: {
                ...this.state.wishlist,
                [id]: !this.state.wishlist[id]
            }
        });
    }

    isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    render() {

        // console.log("memberListState", this.state);

        const {t, i18n} = this.props;

        const loading = this.state.loading;
        const bookingsLoaded = this.state.bookingsLoaded;

        if (!this.state.logined)
            return <Redirect to={"/" + i18n.language + "/login"}/>;

        if (this.state.editSpaceId)
            return <Redirect push to={"/" + i18n.language + "/edit-space/" + this.state.editSpaceId}/>;

        return (
            <div className="wrapper-container memberWishList">
                <MemberMenuMobile/>
                <div className="containerMain paddingFix">
                    <MemberMenuDesktop/>

                    <div className="content clearfix">
                        <div className="wrapper-tabs clearfix">
                            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                                <Tab eventKey={1} title={t("myAccount:current") + t("myAccount:listings")}>
                                    <div style={{minHeight: "600px"}}>

                                        {loading && <div>{t("common:Loading")}</div>}

                                        {!loading && this.state.spaces.length === 0 &&
                                        <div>{t("myAccount:tabListings.tab.noListing")}</div>
                                        }

                                        {!loading && this.state.spaces.length > 0 &&
                                        <div>
                                            <div className="profileFilter">
                                                {/*<input placeholder={t("myAccount:filter.address")} type="text"*/}
                                                {/*       value={this.state.filter1.address}*/}
                                                {/*       onChange={(e) => this.changeStateLevelTwo("filter1", "address", e.target.value)}*/}
                                                {/*/>*/}

                                                {/*<div className="sep-25"/>*/}

                                                <div className="filterTitleBlock"
                                                     onClick={() => this.changeStateLevelOne("filter1Show", !this.state.filter1Show)}
                                                >
                                                    <div
                                                        className="filterTitle">{this.state.filter1Show ? t("myAccount:filter.showFilters.off") : t("myAccount:filter.showFilters.on")}</div>
                                                    <div className="filterTitleArrow"></div>
                                                </div>

                                                <div className="sep-25"/>

                                                <div
                                                    className={"filterBlock " + (this.state.filter1Show ? "active" : '')}>
                                                    <div className="wrap clearfix">
                                                        <div
                                                            className="twenty left mobile-full">{t("myAccount:filter.bookType.title")}</div>
                                                        <div className="eighty left mobile-full">
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="showAll1" name="showAll1"
                                                                           checked={this.state.filter1.showAll}
                                                                           onChange={() => this.changeStateLevelTwo("filter1", "showAll", true)}
                                                                    />
                                                                    <label htmlFor="showAll1"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="showAll1">{t("myAccount:filter.bookType.options.showAll")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="hourly1" name="hourly1"
                                                                           checked={this.state.filter1.hourly}
                                                                           onChange={() => this.changeStateLevelTwo("filter1", "hourly", !this.state.filter1.hourly)}
                                                                    />
                                                                    <label htmlFor="hourly1"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="hourly1">{t("myAccount:filter.bookType.options.hourly")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="monthly1" name="monthly1"
                                                                           checked={this.state.filter1.monthly}
                                                                           onChange={() => this.changeStateLevelTwo("filter1", "monthly", !this.state.filter1.monthly)}
                                                                    />
                                                                    <label htmlFor="monthly1"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="monthly1">{t("myAccount:filter.bookType.options.monthly")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="timeShare1"
                                                                           name="timeShare1"
                                                                           checked={this.state.filter1.timeShare}
                                                                           onChange={() => this.changeStateLevelTwo("filter1", "timeShare", !this.state.filter1.timeShare)}
                                                                    />
                                                                    <label htmlFor="timeShare1"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="timeShare1">{t("myAccount:filter.bookType.options.timeShare")}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sep-25"/>
                                        </div>
                                        }

                                        {!loading && this.state.spaces.length > 0 &&
                                        (this.state.spaces.map((space, i) => {

                                                if ((this.state.filter1.address !== "" && !space.address.toLowerCase().includes(this.state.filter1.address.toLowerCase())) ||
                                                    (this.state.filter1.hourly && this.isEmpty(space.hourly.from)) ||
                                                    (this.state.filter1.monthly && this.isEmpty(space.monthly.from)) ||
                                                    (this.state.filter1.timeShare && this.isEmpty(space.timeshare.from))) {

                                                    return null;
                                                } else {
                                                    return (
                                                        <div key={i}>
                                                            <Space
                                                                // space={space}
                                                                // key={i}
                                                                // isOwner={true}
                                                                key={i}
                                                                id={space._id}
                                                                count={i}
                                                                space={{data: space}}
                                                                lowestPrice={0}
                                                                type="my_listing"
                                                                isOwner={true}
                                                                myId={this.state.myId}
                                                                showBooking={false}
                                                                UpdateSpaceStatus={this.UpdateSpaceStatus}
                                                                goToEdit={this.goToEdit}
                                                                changeSpaceEditPopup={this.changeSpaceEditPopup}
                                                                filterType="current_listing"
                                                            />
                                                        </div>
                                                    )
                                                }
                                            }
                                        ))}
                                    </div>

                                    <div className={"overlay" + (this.state.spaceEditPopup ? ' active' : '')}></div>
                                    <div className={"spaceEditPopup" + (this.state.spaceEditPopup ? " active" : "")}>

                                        <div className="close"
                                             onClick={() => {
                                                 this.changeSpaceEditPopup('', '')
                                             }}
                                        >x
                                        </div>

                                        <div className="sep-10"/>

                                        <div className="content">
                                            {t("myAccount:tabListings.popup.text")}
                                        </div>

                                        <div className="sep-20"/>

                                        <div className="button edit"
                                             onClick={() => {
                                                 this.goToEdit(this.state.spaceEditPopupID, this.state.spaceEditPopupStatus)
                                             }}
                                        >
                                            {t("myAccount:tabListings.popup.button")}
                                        </div>
                                    </div>
                                    {/*<Cluster*/}
                                    {/*    verified={this.state.verified}*/}
                                    {/*    handleVerified={this.handleVerified}*/}
                                    {/*    wishlist={this.state.wishlist}*/}
                                    {/*    handleWishlist={this.handleWishlist}*/}
                                    {/*    carTypes={this.state.carTypes}*/}
                                    {/*    score={this.state.score}*/}
                                    {/*    reviews={this.state.reviews}*/}
                                    {/*/>*/}
                                </Tab>
                                <Tab eventKey={2} title={t("myAccount:current") + t("myAccount:bookings")}>
                                    <div style={{minHeight: "600px"}}>

                                        {!bookingsLoaded && <div>{t("common:Loading")}</div>}

                                        {bookingsLoaded && this.state.currentBookings.length === 0 &&
                                        <div>{t("myAccount:tabListings.tab.noCurrentListing")}</div>
                                        }

                                        {bookingsLoaded && this.state.currentBookings.length > 0 &&
                                        <div>
                                            <div className="profileFilter">
                                                {/*<input placeholder="Address" type="text"*/}
                                                {/*       value={this.state.filter2.address}*/}
                                                {/*       onChange={(e) => this.changeStateLevelTwo("filter2", "address", e.target.value)}*/}
                                                {/*/>*/}

                                                {/*<div className="sep-25"/>*/}

                                                <div className="filterTitleBlock"
                                                     onClick={(e) => this.changeStateLevelOne("filter2Show", !this.state.filter2Show)}
                                                >
                                                    <div
                                                        className="filterTitle">{this.state.filter2Show ? t("myAccount:filter.showFilters.off") : t("myAccount:filter.showFilters.on")}</div>
                                                    <div className="filterTitleArrow"></div>
                                                </div>

                                                <div className="sep-25"/>

                                                <div
                                                    className={"filterBlock " + (this.state.filter2Show ? "active" : '')}>
                                                    <div className="wrap clearfix">
                                                        <div
                                                            className="twenty left mobile-full">{t("myAccount:filter.bookType.title")}</div>
                                                        <div className="eighty left mobile-full">
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="showAll2" name="showAll2"
                                                                           checked={this.state.filter2.showAll}
                                                                           onChange={() => this.changeStateLevelTwo("filter2", "showAll", true)}
                                                                    />
                                                                    <label htmlFor="showAll2"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="showAll2">{t("myAccount:filter.bookType.options.showAll")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="hourly2" name="hourly2"
                                                                           checked={this.state.filter2.hourly}
                                                                           onChange={() => this.changeStateLevelTwo("filter2", "hourly", !this.state.filter2.hourly)}
                                                                    />
                                                                    <label htmlFor="hourly2"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="hourly2">{t("myAccount:filter.bookType.options.hourly")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="monthly2" name="monthly2"
                                                                           checked={this.state.filter2.monthly}
                                                                           onChange={() => this.changeStateLevelTwo("filter2", "monthly", !this.state.filter2.monthly)}
                                                                    />
                                                                    <label htmlFor="monthly2"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="monthly2">{t("myAccount:filter.bookType.options.monthly")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="timeShare2"
                                                                           name="timeShare2"
                                                                           checked={this.state.filter2.timeShare}
                                                                           onChange={() => this.changeStateLevelTwo("filter2", "timeShare", !this.state.filter2.timeShare)}
                                                                    />
                                                                    <label htmlFor="timeShare2"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="timeShare2">{t("myAccount:filter.bookType.options.timeShare")}</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="sep-25"/>

                                                    <div className="wrap clearfix">
                                                        <div className="twenty left mobile-full">
                                                            {t("myAccount:filter.booking.title")}
                                                        </div>
                                                        <div className="eighty left mobile-full">
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="pending2" name="pending2"
                                                                           checked={this.state.filter2.pending}
                                                                           onChange={() => this.changeStateLevelTwo("filter2", "pending", !this.state.filter2.pending)}
                                                                    />
                                                                    <label htmlFor="pending2"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="pending2">{t("myAccount:filter.booking.options.pending")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="confirmed2"
                                                                           name="confirmed2"
                                                                           checked={this.state.filter2.confirmed}
                                                                           onChange={() => this.changeStateLevelTwo("filter2", "confirmed", !this.state.filter2.confirmed)}
                                                                    />
                                                                    <label htmlFor="confirmed2"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="confirmed2">{t("myAccount:filter.booking.options.confirmed")}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="sep-25"/>
                                        </div>
                                        }

                                        {bookingsLoaded && this.state.currentBookings.length > 0 &&
                                        (this.state.currentBookings.map((s, i) => {

                                                let filteredBookings = {}, row = 0;

                                                let filter = this.state.filter2;

                                                s.bookings.forEach((value, key) => {

                                                    let display = false;

                                                    //2019-07-19 samson - using array to manage status
                                                    let status_for_pending = ['pending', 'pendingBooker'],
                                                        status_for_confirmed = ['pendingStart', 'processing'];

                                                    if (filter.showAll) {
                                                        if (filter.pending && status_for_pending.includes(value.status))
                                                            display = true;

                                                        if (filter.confirmed && status_for_confirmed.includes(value.status))
                                                            display = true;
                                                    } else {
                                                        if (
                                                            (filter.hourly && value.type === "hourly") ||
                                                            (filter.monthly && value.type === "monthly") ||
                                                            (filter.timeshare && value.type === "timeshare")
                                                        ) {
                                                            if (filter.pending && status_for_pending.includes(value.status))
                                                                display = true;

                                                            if (filter.confirmed && status_for_confirmed.includes(value.status))
                                                                display = true;
                                                        }
                                                    }
                                                    // if (filter.showAll && (filter.pending && value.status === "pending")) {
                                                    //     display = true;
                                                    // } else if (filter.showAll && (filter.confirmed && value.status === "approved")) {
                                                    //     display = true;
                                                    // } else if (
                                                    //     (
                                                    //         (!filter.showAll && filter.hourly && value.type === "hourly") ||
                                                    //         (!filter.showAll && filter.monthly && value.type === "monthly") ||
                                                    //         (!filter.showAll && filter.timeshare && value.type === "timeshare")
                                                    //     ) && (filter.pending && value.status === "pending")
                                                    // ) {
                                                    //     display = true;
                                                    // } else if (
                                                    //     (
                                                    //         (!filter.showAll && filter.hourly && value.type === "hourly") ||
                                                    //         (!filter.showAll && filter.monthly && value.type === "monthly") ||
                                                    //         (!filter.showAll && filter.timeshare && value.type === "timeshare")
                                                    //     ) && (filter.confirmed && value.status === "approved")
                                                    // ) {
                                                    //     display = true;
                                                    // }

                                                    if (display) {

                                                        filteredBookings[row] = value;

                                                        row++;
                                                    }
                                                });

                                                if (this.isEmpty(filteredBookings)) {
                                                    return null;
                                                } else {
                                                    //2019-07-24 samson - check has record before show the spaceWBooking
                                                    let hasRecord = false;
                                                    Object.keys(filteredBookings).forEach((key, idx) => {
                                                        if (filteredBookings[key].space._id === s.space._id) {
                                                            hasRecord = true;
                                                            return false;
                                                        }
                                                    });
                                                    if (hasRecord) {
                                                        return (
                                                            <div key={i}>
                                                                <SpaceWBooking
                                                                    // space={s}
                                                                    // key={i}
                                                                    // isOwner={true}
                                                                    key={i}
                                                                    id={s.space._id}
                                                                    count={i}
                                                                    space={{data: s.space}}
                                                                    bookings={filteredBookings}
                                                                    lowestPrice={0}
                                                                    type="my_booking"
                                                                    getBooking={this.getBooking}
                                                                    isOwner={true}
                                                                    myId={this.state.myId}
                                                                    showBooking={true}
                                                                    passBooking={true}
                                                                    filterType="listing_current_booking"
                                                                />
                                                            </div>
                                                        )
                                                    } else
                                                        return null;
                                                }
                                            }
                                        ))
                                        }

                                        {/*{loading && <div>Loading...</div>}*/}

                                        {/*{!loading && (!this.state.spaceWithCurrentBooking || this.state.spaceWithCurrentBooking.length === 0) &&*/}
                                        {/*    <div>No Current Booking</div>*/}
                                        {/*}*/}

                                        {/*{!loading && this.state.spaceWithCurrentBooking && this.state.spaceWithCurrentBooking.length > 0 &&*/}
                                        {/*    (this.state.spaceWithCurrentBooking.map((space, i) =>*/}
                                        {/*        <Space*/}
                                        {/*            // space={space}*/}
                                        {/*            // key={i}*/}
                                        {/*            // isOwner={true}*/}
                                        {/*            key={i}*/}
                                        {/*            id={space._id}*/}
                                        {/*            count={i}*/}
                                        {/*            space={{data: space}}*/}
                                        {/*            lowestPrice="$0"*/}
                                        {/*            type="current_booking"*/}
                                        {/*            getBooking={this.getBooking}*/}
                                        {/*            isOwner={true}*/}
                                        {/*            myId={this.state.myId}*/}
                                        {/*            showBooking={true}*/}
                                        {/*        />*/}
                                        {/*    ))*/}
                                        {/*}*/}
                                    </div>
                                    {/*<Cluster*/}
                                    {/*    verified={this.state.verified}*/}
                                    {/*    handleVerified={this.handleVerified}*/}
                                    {/*    wishlist={this.state.wishlist}*/}
                                    {/*    handleWishlist={this.handleWishlist}*/}
                                    {/*    carTypes={this.state.carTypes}*/}
                                    {/*    score={this.state.score}*/}
                                    {/*    reviews={this.state.reviews}*/}
                                    {/*/>*/}
                                </Tab>
                                <Tab eventKey={3} title={t("myAccount:past") + " " + t("myAccount:bookings")}>
                                    <div style={{minHeight: "600px"}}>

                                        {!bookingsLoaded && <div>{t("common:Loading")}</div>}

                                        {bookingsLoaded && this.state.pastBookings.length === 0 &&
                                        <div>{t("myAccount:tabListings.tab.noPastListing")}</div>
                                        }


                                        {bookingsLoaded && this.state.pastBookings.length > 0 &&
                                        <div>
                                            <div className="profileFilter">
                                                {/*<input placeholder="Address" type="text"*/}
                                                {/*       value={this.state.filter3.address}*/}
                                                {/*       onChange={(e) => this.changeStateLevelTwo("filter3", "address", e.target.value)}*/}
                                                {/*/>*/}

                                                {/*<div className="sep-25"/>*/}

                                                <div className="filterTitleBlock"
                                                     onClick={(e) => this.changeStateLevelOne("filter3Show", !this.state.filter3Show)}
                                                >
                                                    <div
                                                        className="filterTitle">{this.state.filter3Show ? t("myAccount:filter.showFilters.off") : t("myAccount:filter.showFilters.on")}</div>
                                                    <div className="filterTitleArrow"></div>
                                                </div>

                                                <div className="sep-25"/>

                                                <div
                                                    className={"filterBlock " + (this.state.filter3Show ? "active" : '')}>
                                                    <div className="wrap clearfix">
                                                        <div
                                                            className="twenty left mobile-full">{t("myAccount:filter.bookType.title")}</div>
                                                        <div className="eighty left mobile-full">
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="showAll3" name="showAll3"
                                                                           checked={this.state.filter3.showAll}
                                                                           onChange={() => this.changeStateLevelTwo("filter3", "showAll", true)}
                                                                    />
                                                                    <label htmlFor="showAll3"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="showAll3">{t("myAccount:filter.bookType.options.showAll")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="hourly3" name="hourly3"
                                                                           checked={this.state.filter3.hourly}
                                                                           onChange={() => this.changeStateLevelTwo("filter3", "hourly", !this.state.filter3.hourly)}
                                                                    />
                                                                    <label htmlFor="hourly3"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="hourly3">{t("myAccount:filter.bookType.options.hourly")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="monthly3" name="monthly3"
                                                                           checked={this.state.filter3.monthly}
                                                                           onChange={() => this.changeStateLevelTwo("filter3", "monthly", !this.state.filter3.monthly)}
                                                                    />
                                                                    <label htmlFor="monthly3"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="monthly">{t("myAccount:filter.bookType.options.monthly")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="timeShare3"
                                                                           name="timeShare3"
                                                                           checked={this.state.filter3.timeShare}
                                                                           onChange={() => this.changeStateLevelTwo("filter3", "timeShare", !this.state.filter3.timeShare)}
                                                                    />
                                                                    <label htmlFor="timeShare3"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="timeShare3">{t("myAccount:filter.bookType.options.timeShare")}</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="sep-25"/>

                                                    <div className="wrap clearfix">
                                                        <div
                                                            className="twenty left mobile-full">{t("myAccount:filter.status.title")}</div>
                                                        <div className="eighty left mobile-full">
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="bookingFinished3"
                                                                           checked={this.state.filter3.finished}
                                                                           onChange={() => this.changeStateLevelTwo("filter3", "finished", !this.state.filter3.finished)}
                                                                           name="bookingFinished3"/>
                                                                    <label htmlFor="bookingFinished3"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="bookingFinished3">{t("myAccount:filter.status.options.finished")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="requestDeclined3"
                                                                           checked={this.state.filter3.declined}
                                                                           onChange={() => this.changeStateLevelTwo("filter3", "declined", !this.state.filter3.declined)}
                                                                           name="requestDeclined3"/>
                                                                    <label htmlFor="requestDeclined3"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="requestDeclined3">{t("myAccount:filter.status.options.declined")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="requestCancelled3"
                                                                           checked={this.state.filter3.cancelled}
                                                                           onChange={() => this.changeStateLevelTwo("filter3", "cancelled", !this.state.filter3.cancelled)}
                                                                           name="requestCancelled3"/>
                                                                    <label htmlFor="requestCancelled3"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="requestCancelled3">{t("myAccount:filter.status.options.cancelled")}</label>
                                                            </div>
                                                            <div className="checkboxFive">
                                                                <div>
                                                                    <input type="checkbox" id="requestExpired3"
                                                                           checked={this.state.filter3.expired}
                                                                           onChange={() => this.changeStateLevelTwo("filter3", "expired", !this.state.filter3.expired)}
                                                                           name="requestExpired3"/>
                                                                    <label htmlFor="requestExpired3"></label>
                                                                </div>
                                                                <label
                                                                    htmlFor="requestExpired3">{t("myAccount:filter.status.options.expired")}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="sep-25"/>
                                        </div>
                                        }

                                        {bookingsLoaded && this.state.pastBookings.length > 0 &&
                                        (this.state.pastBookings.map((s, i) => {

                                                let filteredBookings = {}, row = 0;

                                                let filter = this.state.filter3;

                                                s.bookings.forEach((value, key) => {
                                                    let display = false;

                                                    //2019-07-19 samson - using array to manage status
                                                    let status_for_finished = ['depositReleasing', 'complete', 'holdDeposit', 'completeWithDeposit'],
                                                        status_for_declined = ['rejected', 'rejectedBooker'],
                                                        status_for_cancelled = ['cancelled'],
                                                        status_for_expired = ['expired'];

                                                    if (filter.showAll) {
                                                        if (filter.finished && status_for_finished.includes(value.status))
                                                            display = true;

                                                        if (filter.declined && status_for_declined.includes(value.status))
                                                            display = true;

                                                        if (filter.cancelled && status_for_cancelled.includes(value.status))
                                                            display = true;

                                                        if (filter.expired && status_for_expired.includes(value.status))
                                                            display = true;
                                                    } else {
                                                        if (
                                                            (filter.hourly && value.type === "hourly") ||
                                                            (filter.monthly && value.type === "monthly") ||
                                                            (filter.timeshare && value.type === "timeshare")
                                                        ) {
                                                            if (filter.finished && status_for_finished.includes(value.status))
                                                                display = true;

                                                            if (filter.declined && status_for_declined.includes(value.status))
                                                                display = true;

                                                            if (filter.cancelled && status_for_cancelled.includes(value.status))
                                                                display = true;

                                                            if (filter.expired && status_for_expired.includes(value.status))
                                                                display = true;
                                                        }
                                                    }
                                                    // if (filter.showAll && (filter.finished && value.status === "approved")) {
                                                    //     display = true;
                                                    // } else if (filter.showAll && (filter.declined && value.status === "rejected")) {
                                                    //     display = true;
                                                    // } else if (filter.showAll && (filter.cancelled && value.status === "rejectedBooker")) {
                                                    //     display = true;
                                                    // } else if (filter.showAll && (filter.expired && value.status === "expired")) {
                                                    //     display = true;
                                                    // } else if (
                                                    //     (
                                                    //         (!filter.showAll && filter.hourly && value.type === "hourly") ||
                                                    //         (!filter.showAll && filter.monthly && value.type === "monthly") ||
                                                    //         (!filter.showAll && filter.timeshare && value.type === "timeshare")
                                                    //     ) && (filter.finished && value.status === "approved")
                                                    // ) {
                                                    //     display = true;
                                                    // } else if (
                                                    //     (
                                                    //         (!filter.showAll && filter.hourly && value.type === "hourly") ||
                                                    //         (!filter.showAll && filter.monthly && value.type === "monthly") ||
                                                    //         (!filter.showAll && filter.timeshare && value.type === "timeshare")
                                                    //     ) && (filter.declined && value.status === "rejected")
                                                    // ) {
                                                    //     display = true;
                                                    // } else if (
                                                    //     (
                                                    //         (!filter.showAll && filter.hourly && value.type === "hourly") ||
                                                    //         (!filter.showAll && filter.monthly && value.type === "monthly") ||
                                                    //         (!filter.showAll && filter.timeshare && value.type === "timeshare")
                                                    //     ) && (filter.cancelled && value.status === "rejectedBooker")
                                                    // ) {
                                                    //     display = true;
                                                    // } else if (
                                                    //     (
                                                    //         (!filter.showAll && filter.hourly && value.type === "hourly") ||
                                                    //         (!filter.showAll && filter.monthly && value.type === "monthly") ||
                                                    //         (!filter.showAll && filter.timeshare && value.type === "timeshare")
                                                    //     ) && (filter.expired && value.status === "expired")
                                                    // ) {
                                                    //     display = true;
                                                    // }

                                                    if (display) {

                                                        filteredBookings[row] = value;

                                                        row++;
                                                    }
                                                });

                                                if (this.isEmpty(filteredBookings)) {
                                                    return null;
                                                } else {
                                                    //2019-07-24 samson - check has record before show the spaceWBooking
                                                    let hasRecord = false;
                                                    Object.keys(filteredBookings).forEach((key, idx) => {
                                                        if (filteredBookings[key].space._id === s.space._id) {
                                                            hasRecord = true;
                                                            return false;
                                                        }
                                                    });
                                                    if (hasRecord) {
                                                        return (
                                                            <div key={i}>
                                                                <SpaceWBooking
                                                                    // space={space}
                                                                    // key={i}
                                                                    // isOwner={true}
                                                                    key={i}
                                                                    id={s.space._id}
                                                                    count={i}
                                                                    space={{data: s.space}}
                                                                    bookings={filteredBookings}
                                                                    lowestPrice={0}
                                                                    type="my_booking"
                                                                    getBooking={this.getBooking}
                                                                    isOwner={true}
                                                                    myId={this.state.myId}
                                                                    showBooking={true}
                                                                    passBooking={true}
                                                                    filterType="listing_past_booking"
                                                                />
                                                            </div>
                                                        )
                                                    } else
                                                        return null;
                                                }
                                            }
                                        ))
                                        }

                                        {/*{!loading && this.state.spaceWithPastBooking && this.state.spaceWithPastBooking.length > 0 &&*/}
                                        {/*(this.state.spaceWithPastBooking.map((space, i) =>*/}
                                        {/*    <Space*/}
                                        {/*        // space={space}*/}
                                        {/*        // key={i}*/}
                                        {/*        // isOwner={true}*/}
                                        {/*        key={i}*/}
                                        {/*        id={space._id}*/}
                                        {/*        count={i}*/}
                                        {/*        space={{data: space}}*/}
                                        {/*        lowestPrice="$0"*/}
                                        {/*        type="current_booking"*/}
                                        {/*        getBooking={this.getBooking}*/}
                                        {/*        isOwner={true}*/}
                                        {/*        myId={this.state.myId}*/}
                                        {/*        showBooking={true}*/}
                                        {/*    />*/}
                                        {/*))*/}
                                        {/*}*/}
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {
        members: state.members
    }
);

export default withTranslation()(connect(mapStateToProps)(MemberListedSpaces));
