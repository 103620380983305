import React, {Component} from 'react';

class Privacy_zh_HK extends Component {
    render() {
        return (
            <div className="privacy" dangerouslySetInnerHTML={{__html: this.props.content}}/>
            // <div className="privacy">
            //
            //     <p align="center"><strong>有關您的個人資料的須知</strong></p>
            //
            //     <p><strong>第</strong><strong>1</strong><strong>部分：簡介</strong></p>
            //
            //     <p>本文件包含我們的《個人資料收集聲明》，其中列出了法例規定我們須在收集可用於識別您身分的信息（或您的「個人資料」）之時或之前提供給您的各種信息。這也是我們的《私隱政策聲明》，列出了與您的個人資料相關的政策和做法。</p>
            //
            //
            //     <p>在本文件中，「平台」指<a
            //         style={{"textDecoration":"underline"}}
            //         href="http://www.letspark.com.hk">www.letspark.com.hk</a>網站及[手機應用程式]；「我們」指在香港註冊成立的有限公司－泊車易(香港)有限公司；「您」指取用平台、註冊成為用戶及／或使用我們通過平台提供的服務（「泊車易服務」）的人。本文件中未明確定義的任何術語的含義，如我們不時公佈的《平台使用條款及條件》中所述。</p>
            //
            //     <p><strong>請在向我們提供個人資料之前，先仔細閱讀本文件。</strong></p>
            //
            //     <p><strong>第</strong><strong>2</strong><strong>部分：個人資料收集聲明</strong></p>
            //
            //     <p><strong>個人資料的收集</strong></p>
            //
            //     <p>如果您前往平台但未註冊為用戶，我們不會收集您的任何個人資料。但是，您用於前往平台的電腦的IP地址可能會被記錄為「點擊次數」，而我們可能會收集您用來查看平台的瀏覽器的信息、屏幕的解像度及電腦的其他技術信息，但不會收集個人資料。點擊次數的紀錄僅用作平台使用情況的一般統計數據，而收集其他技術信息是為了提升您的使用體驗。</p>
            //
            //     <p>如果您希望在平台上註冊帳戶以成為用戶，您需要向我們提供某些個人資料。在您註冊帳戶後，如果您希望使用泊車易服務，例如發布或預約泊車位或使用我們提供的任何其他服務，我們也會要求您提供額外的個人資料。每當我們要求您提供個人資料時，我們會告訴您哪些是必須提供的資料，而如果您不希望提供那些資料，我們將無法向您提供所需帳戶或服務。您可自行選擇是否提供其他個人資料。</p>
            //
            //     <p><strong>使用個人資料</strong></p>
            //
            //     <p>我們向您收集的個人資料可能用於以下目的：－</p>
            //
            //     <ol>
            //         <li>註冊和維持您的帳戶</li>
            //         <li>操作和管理平台</li>
            //         <li>向您提供泊車易服務</li>
            //         <li>聯絡您以告知您關於我們提供的服務或您的帳戶的事宜</li>
            //         <li>處理和跟進您的查詢、建議、投訴</li>
            //         <li>核實泊車位的擁有權</li>
            //         <li>識別並驗證您的身分</li>
            //         <li>進行服務調查，以更深入了解您的所需，從而改善和增強泊車易服務，並為您設計和開發新服務</li>
            //         <li>信貸控制和向您收取債務</li>
            //         <li>預防或偵查罪案</li>
            //         <li>監察平台的使用，以幫助其進一步發展</li>
            //     </ol>
            //
            //     <p>此外，您與我們的客戶服務熱線或職員之間的電話對話錄音，可能用於質量監控、員工評估和培訓用途。</p>
            //
            //     <p><strong>移轉個人資料</strong></p>
            //
            //     <p>除下列情況外，我們不會將您的個人資料與任何一方共享或移轉給任何一方：</p>
            //
            //     <ol>
            //         <li>如果您是某項預約所涉的泊車者或擁有者，您的姓名和聯絡資料可能會被發送到該預約的另一方，以便管理預約情況。</li>
            //         <li>如果您是預約的一方，您的相關個人資料和銀行信息及／或信用卡號可能會提供給我們的代理人或銀行，以便我們向您支付或收取款項。</li>
            //         <li>我們可能會將您的個人資料移轉給我們所聘請的第三方（例如印刷商、製作公司或郵寄服務提供商），以代我們處理該等資料，或主持及／或經營平台。</li>
            //         <li>任何適用法律要求我們向任何人作出披露。</li>
            //         <li>我們可能聘請以管理預約的任何第三方，例如拖車公司。</li>
            //         <li>您明確同意獲取您的個人資料的任何人。</li>
            //         <li>為向您收取欠款而聘請的債務催收機構。</li>
            //         <li>我們業務的任何實際或擬議受讓人，其將接管您所訂購的泊車易服務。</li>
            //     </ol>
            //
            //     <p><strong>查閱及改正被收集的資料的權利</strong></p>
            //
            //     <p>您有權要求查閱並改正我們所持有而關於您的個人資料。如果您想行使這些權利，請電郵至<a style={{"textDecoration":"underline"}} href="mailto:info@letspark.com.hk">info@letspark.com.hk</a>或郵寄至香港新界葵涌興芳路新都會廣場1座29樓2901室。</p>
            //
            //     <p><strong>直接促銷</strong></p>
            //
            //     <p>我們打算使用您提供的姓名和聯絡資料向您促銷泊車易服務或可供預約的泊車位，但我們不會為此目的在未得到您表示不反對或給予同意的情況下使用上述個人資料。</p>
            //
            //     <p>如果您不是用戶，但您已在平台上向我們提供您的聯絡資料，以便接收有關泊車易服務或可供預約的泊車位的更多資訊，即表示您已同意我們進行直接促銷。如果您是用戶，但您不希望我們使用您的個人資料來直接向您促銷泊車易服務，請在註冊過程中在適當的空格加上剔號，拒收直接促銷資訊。您也可以在日間通過平台或發電郵至<a style={{"textDecoration":"underline"}} href="mailto:info@letspark.com.hk">info@letspark.com.hk</a>，拒收直接促銷資訊。</p>
            //
            //     <p>我們不會將您的個人資料提供給任何第三方以用作直接促銷用途。</p>
            //
            //     <p><strong>第</strong><strong>3</strong><strong>部分：私隱政策聲明</strong></p>
            //
            //     <p><strong>我們的承諾</strong></p>
            //
            //     <p>我們致力於保護您的個人資料，因此我們遵守《個人資料（私隱）條例》中有關管理個人資料的規定。我們同樣竭力確保所有員工和代理人都履行這義務。</p>
            //
            //     <p><strong>使用小型文字檔案</strong><strong>(</strong><strong>Cookie)</strong></p>
            //
            //     <p>我們在平台上使用cookie，以提高平台性能並優化您的使用體驗。平台使用的暫時性cookie，讓您可查看平台各部分內容，並使用平台上的基本功能，例如暫時保留登入資料，以免卻在互聯網上傳輸信息。Google
            //         Analytics用於識別經常到訪平台的訪客，以便我們更深入了解用戶瀏覽平台的方式、逗留時間及到訪頻率，從而改進平台運作。在使用cookie時我們不會收集您的任何個人資料。</p>
            //
            //     <p><strong>保留您的個人資料</strong></p>
            //
            //     <p>泊車易已採取一切切實可行的措施，以確保您的個人資料的保留時間不會超過為達到資料使用目的（包括任何直接相關的目的）而所需的時間。在您關閉帳戶後6個月，我們將把您的個人資料從系統中永久刪除，並以安全和適當的方式銷毀載有您的個人資料的任何實體副本，惟您的預約詳情將作為我們會計紀錄的一部分，按照法律規定的期限保留。</p>
            //
            //     <p><strong>您的個人資料的保安</strong></p>
            //
            //     <p>您的個人資料被加密並儲存在我們的系統或我們的服務提供商的專用伺服器中。我們也採取了適當的技術和管理措施，以防止任何未經授權人士查閱或意外披露您的個人資料。只有受過培訓的獲授權員工才能在必須得知您的個人資料的情況下查閱該等資料。</p>
            //
            //     <p><strong>外包</strong></p>
            //
            //     <p>我們可能不時聘請某些服務提供商執行與平台的操作及／或管理相關的某些項目，這些項目可能涉及處理您的個人資料。如果出現這種情況，我們將確保服務提供商了解該等資料的性質，並確保他們受合同約束保持該等資料的保安及機密性。</p>
            //
            //     <p><strong>連結</strong></p>
            //
            //     <p>平台可能會提供連接到其他網站或支付網關的連結。請注意，您到訪那些第三方網站時將須遵守那裡的個人私隱政策和個人資料收集聲明。我們的政策、常規和聲明並不涵蓋該等內容。</p>
            //
            //     <p><strong>進一步查詢</strong></p>
            //
            //     <p>如果您對本文件或《個人資料收集聲明》中所述的常規和政策有任何疑問，請發送電郵至<a style={{"textDecoration":"underline"}} href="mailto:info@letspark.com.hk">info@letspark.com.hk</a>或郵寄至香港銅鑼灣灣告士打道280號世界貿易中心26樓2601室</p>
            // </div>
        );
    }
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default Privacy_zh_HK;
