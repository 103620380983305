import React, { Component } from 'react';
import ReactStreetview from 'react-streetview';
import * as Config from '../../config';

class GoogleMapStreetView extends Component {
    render() {
        // see https://developers.google.com/maps/documentation/javascript/3.exp/reference#StreetViewPanoramaOptions
        const streetViewPanoramaOptions = {
            position: {
                lat: parseFloat(this.props.lat),
                lng: parseFloat(this.props.lng)
            },
            pov: {
                heading: 100,
                pitch: 0
            },
            zoom: 1
        };
        // console.log(streetViewPanoramaOptions);

        const mapStyles = {
            width: this.props.width !== undefined ? this.props.width : '100%',
            height: this.props.height !== undefined ? this.props.height : '100%'
        };

        // console.log(Config.UAT_GOOGLE_MAP_KEY)
        // console.log(this.props.lng, this.props.lat);
        return (
            <div style={{
                width: mapStyles.width,
                height: mapStyles.height,
                backgroundColor: '#eeeeee'
            }}>
                <ReactStreetview
                    apiKey={Config.UAT_GOOGLE_MAP_KEY}
                    streetViewPanoramaOptions={streetViewPanoramaOptions}
                />
            </div>
        );
    }
}

export default GoogleMapStreetView;