import React from 'react';
import { Link } from 'react-router-dom';

import Heart from '../103Parts/Heart';
import Slider from "react-slick";
import SpaceGoogleMap from "../../components/103Parts/SpaceGoogleMap";
import GoogleMapStreetView from "../../components/103Parts/GoogleMapStreetView";
import {withTranslation} from 'react-i18next';

function ViewChangeWithSlider(props) {

    const {
        t,
        // i18n
    } = props;

    const images = typeof(props.images) !== "undefined" ? props.images : [];

    // react slick settings
    const settings = {
        customPaging: function (i) {
            if (images[i]) {
                return (
                    <div>
                        <a href="/" onClick={(e) => e.preventDefault()}>
                            <img src={images[i]} alt=""/>
                        </a>
                    </div>
                );
            }
        },
        dots: true,
        dotsClass: "slick-dots",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    
    // const {i} = props;

    return (
        <div className={"super-wrapper " + (props.view === 'photo' ? 'active' : '')}>
            <img src={require('../../images/blank.png')} alt="" />

            <ul className="views clearfix">
                <li><Link to="/" className={"mapView " + (props.view === 'map' ? 'active' : '')}
                    onClick={(e) => props.handleChangeView(e, 'view', 'map')}>{t("space:map")}</Link></li>
                <li><Link to="/" className={"streetView " + (props.view === 'street' ? 'active' : '')}
                    onClick={(e) => props.handleChangeView(e, 'view', 'street')}>{t("space:streetView")}</Link></li>
                <li><Link to="/" className={"photoView " + (props.view === 'photo' ? 'active' : '')}
                    onClick={(e) => props.handleChangeView(e, 'view', 'photo')}>{t("space:photo")}</Link></li>
            </ul>

            {props.view === 'map' &&
                <div className="clusterMap"><SpaceGoogleMap lat={props.lat} lng={props.lng} /></div>
            }

            {props.view === 'street' &&
                <div className="clusterMap"><GoogleMapStreetView lat={props.lat} lng={props.lng} /></div>
            }

            {props.view === "photo" &&
                <div>
                    <Heart
                        id={props.id}
                    />
                    <Slider {...settings}>
                        {images.map((i, index) => {
                            if (i) {
                                return (
                                    <div key={index}>
                                        <img src={i} alt="" />
                                    </div>
                                )
                            }
                            return null
                        })}
                    </Slider>
                </div>
            }
        </div>
    )
}

export default withTranslation()(ViewChangeWithSlider);
