import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

class AccessType extends Component {
    render() {

        const {
            t,
            // i18n
        } = this.props;

        switch (this.props.accesstype) {
            case 'AccessCard':
                return <p>{t("space:tabDetails.accessType.options.accessCard")}</p>
            case 'OctopusCard':
                return <p>{t("space:tabDetails.accessType.options.octopusCard")}</p>
            case 'No':
                return <p>{t("space:tabDetails.accessType.options.noAccessCard")}</p>
            default:
                return <p>{t("space:tabDetails.accessType.options.accessCard")}</p>
        }
    }
}

export default withTranslation()(AccessType);