import React, {Component} from 'react';
// import {Tabs, Tab} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';

import {Redirect} from 'react-router';
import MemberMenuDesktop from '../../../components/02Members/MemberMenuDesktop';
import MemberMenuMobile from '../../../components/02Members/MemberMenuMobile';
// import Space from '../../../components/02Members/Space';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as MembersActionCreators from '../../../actions/members';

import {getWishList} from "../../../utils/04Api/WishList";

import Space from '../../../components/02Members/Space';
// import ListType2 from '../../../components/102Grids/ListType2';

function Cluster(props) {
    return props.wishlist.map((item, i) =>
        <Space
            key={i}
            id={item.space._id}
            count={i}
            space={{data: item.space}}
            lowestPrice="$0"
            type="wishlist"
            showBooking={false}
        />
    )
}

class MemberWishlist extends Component {
    constructor(props) {
        super(props);

        console.log("This is PROP");
        console.log(props);

        const {dispatch} = props;

        // DISPATCH TO ACTIONS
        this.updateWishList = bindActionCreators(MembersActionCreators.updateWishList, dispatch);

        let logined = false,
            uid = '';
        if (this.props.members.Login.length !== 0) {
            logined = true;
            uid = props.members.Login.user._id;
        }

        this.state = {
            logined: logined,
            uid: uid,
            loading: true,
            wishlist: [],
        }
    }

    componentDidMount = () => {
        if (this.state.logined) {
            getWishList(this.props.members.Login.userToken, (obj) => {
                if (obj.status === 200) {
                    let wishlist = [];
                    obj.body.map((record, index) => {
                        wishlist.push(record.space._id);
                        return null;
                    })

                    this.updateWishList(wishlist);

                    // console.log(obj.body);
                    // console.log(wishlist);

                    this.setState({
                        ...this.state,
                        wishlist: obj.body,
                        loading: false,
                    })
                }
            })
        }
    }

    render() {
        const {t, i18n} = this.props;

        if (!this.state.logined)
            return <Redirect to={"/" + i18n.language + "/login"}/>;

        return (
            <div className="wrapper-container memberWishList">
                <MemberMenuMobile/>
                <div className="containerMain paddingFix">
                    <MemberMenuDesktop/>

                    <div className="content clearfix">
                        <div className="wrapper-tabs clearfix" style={{minHeight: 490 + 'px'}}>

                            <div className="sep-25"/>
                            <div className="sep-25"/>

                            {this.state.loading &&
                            <div>{t("common:Loading")}</div>
                            }

                            {!this.state.loading &&
                            <div>
                                {this.state.wishlist.length > 0 &&
                                <Cluster
                                    wishlist={this.state.wishlist}
                                />
                                }

                                {this.state.wishlist.length === 0 &&
                                <div>{t("myAccount:tabWishList.noBookmark")}</div>
                                }
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {
        members: state.members
    }
);

export default withTranslation()(connect(mapStateToProps)(MemberWishlist));
