import React, {Component} from 'react';

class Terms_en_US extends Component {

    // constructor(props) {
    //
    //     super(props);
    // }

    render() {

        return (
            <div className="terms" dangerouslySetInnerHTML={{__html: this.props.content}}/>
            // <div className="terms">
            //
            //     <p>Your use of this Website and the services to be provided by Let&rsquo;s Park Company (Hong Kong)
            //         Limited shall be governed by these Terms and Conditions which shall form a legally binding agreement
            //         between you and Let&rsquo;s Park.</p>
            //
            //     <ol>
            //         <li><strong>Definitions and Interpretation</strong>
            //             <ol>
            //                 <li>In these Terms, unless the context otherwise requires the following words and
            //                     expressions shall have the following meanings:
            //                     <ul>
            //                         <li>&ldquo;Account&rdquo; means your Account with Let&rsquo;s Park after your
            //                             registration;
            //                         </li>
            //                         <li>&ldquo;Booking&rdquo; means a binding licence for the use of a Parking Space at
            //                             a particular time which is binding on the related Owner and Parker and reference
            //                             to the time a Booking is completed means the time the Booking becomes valid and
            //                             binding on the Owner and the Parker;
            //                         </li>
            //                         <li>&ldquo;Confirmation&rdquo; means in the case of <em>Instant Booking</em> the
            //                             confirmation sent by us to the related Parker pursuant to clause 9.1(b) and in
            //                             the case of <em>Booking by Agreement</em>, the confirmation sent by us to the
            //                             related Parker pursuant to clause 9.2(d);
            //                         </li>
            //                         <li>&ldquo;Deposit&rdquo; means the deposit payable in relation to a Booking where
            //                             applicable;
            //                         </li>
            //                         <li>&ldquo;Handling Fee&rdquo; means the handling fees referred to in clause 8.2;
            //                         </li>
            //                         <li>&ldquo;Licence&rdquo; in relation to a Parking Space means the licence granted
            //                             by the Owner to the Parker for such Parking Space pursuant to the relevant
            //                             Licence Agreement;
            //                         </li>
            //                         <li>&ldquo;Licence Agreement&rdquo; in relation to a Booking means the licence
            //                             agreement between the Owner and the Parker in relation to such Booking and the
            //                             latest version of the terms of the Licence Agreement can be found here;
            //                         </li>
            //                         <li>&ldquo;Parking Fee&rdquo; in relation to a Booking means the parking fee payable
            //                             by the Parker to the Owner in relation to such Booking under the related Licence
            //                             Agreement;
            //                         </li>
            //                         <li>&ldquo;Owner&rdquo; in relation to a Parking Space means the person who has
            //                             listed such Parking Space at this Platform and if you have listed a Parking
            //                             Space on this Platform, the rights and obligations of an Owner will apply to
            //                             you;
            //                         </li>
            //                         <li>&ldquo;Parker&rdquo; in relation to a Booking means the person who has obtained
            //                             a Licence to use a Parking Space pursuant to such Booking and if you have
            //                             obtained a Licence through this Platform, the rights and obligations of a Parker
            //                             will apply to you in respect of such Licence;
            //                         </li>
            //                         <li>&ldquo;Parking Space&rdquo; means a parking space which is listed on this
            //                             Platform;
            //                         </li>
            //                         <li>&ldquo;Parking Space Details&rdquo; in relation to a Parking Space means the
            //                             contents (including photographs) on this Platform which describe such Parking
            //                             Space;
            //                         </li>
            //                         <li>&ldquo;Platform&rdquo; means collectively this website, mobile App under the
            //                             name of Let&rsquo;s Park and the Service;
            //                         </li>
            //                         <li>&ldquo;Services&rdquo; means the services to be provided by us through this
            //                             Platform;
            //                         </li>
            //                         <li>&ldquo;Service Fee&rdquo; means the service fee payable by the Owner to us for
            //                             our assistance in relation to the grant of the Licence as referred to in clause
            //                             8.1(a);
            //                         </li>
            //                         <li>&ldquo;Terms&rdquo; means these terms and conditions as amended from time to
            //                             time;
            //                         </li>
            //                         <li>&ldquo;us&rdquo; or &ldquo;Let&rsquo;s Park&rdquo; or &ldquo;we&rdquo; means
            //                             Let&rsquo;s Park Company (Hong Kong) Limited, a company incorporated in Hong
            //                             Kong with limited liability;
            //                         </li>
            //                         <li>&ldquo;User&rdquo; means a party who has registered as a user of this
            //                             Platform;
            //                         </li>
            //                         <li>&ldquo;Vehicle&rdquo; in relation to a Booking means the vehicle identified by
            //                             its registration mark which will be using the Parking Space pursuant to such
            //                             Booking as amended in accordance with the Licence Agreement, where applicable;
            //                         </li>
            //                         <li>&ldquo;Website&rdquo; means this website at <a
            //                             style={{"textDecoration":"underline"}}
            //                             href="http://www.letspark.com.hk">www.letspark.com.hk</a>; and
            //                         </li>
            //                         <li>&ldquo;you&rdquo; or &ldquo;yours&rdquo; means the person access the website.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>In these Terms, unless the context otherwise requires:
            //                     <ul>
            //                         <li>references to clauses are to clauses of these Terms;</li>
            //                         <li>words denoting one gender shall include all other genders;</li>
            //                         <li>words denoting the singular shall include the plural and vice versa; and</li>
            //                         <li>the word &ldquo;person&rdquo; includes an individual, partnership, corporation
            //                             or unincorporated body and government department.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>In these Terms, references to information or procedures as published by us means the
            //                     amendment or procedures as published by us in our Website from time to time.
            //                 </li>
            //                 <li>In these Terms, business days mean days other than Saturdays, Sundays and public
            //                     holidays in Hong Kong.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Effect and Purpose </strong>
            //             <ol>
            //                 <li>The purposes of this Platform are for the listing and viewing of Parking Spaces, and the
            //                     grant of Licence to use the Parking Spaces. You may not use this Platform or any
            //                     information contained in this Platform for any other purpose.
            //                 </li>
            //                 <li>These Terms govern your access to our Website, your use of this Platform and our
            //                     provision of the Services to you and are legally binding on you. Your viewing of our
            //                     Website and your use of this Platform shall be deemed to be your agreement to be bound
            //                     by these Terms.&nbsp; If you do not agree to any of the Terms or any other terms
            //                     referred to in these Terms, you must not access this Website and you must not use this
            //                     Platform.
            //                 </li>
            //                 <li>If you wish to list a Parking Space or to obtain a licence for the use of a Parking
            //                     Space through this Platform, or to contact an Owner or a Parker you will need to
            //                     register an Account first.
            //                 </li>
            //                 <li>In the event a Licence is granted by you or to you through this Platform, the terms of
            //                     the Licence Agreement shall apply.
            //                 </li>
            //                 <li>Due to the nature of the internet, we cannot guarantee uninterrupted access to this
            //                     Platform or uninterrupted provision of the Services.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Services provided by Let&rsquo;s Park</strong>
            //             <ol>
            //                 <li>The Services include the advertising of Parking Spaces, the provision of information in
            //                     relation to the Parking Spaces, assisting the Owner to grant a Licence to a Parker and
            //                     assisting the management of the Parking Space on these Terms. The Booking is between the
            //                     Owner and the Parker direct and we are not a party to the Licence Agreement or the agent
            //                     or representative of the Owner or the Parker.&nbsp; &nbsp;&nbsp;&nbsp;</li>
            //                 <li>The Owner, the Parker and us are independent contractors to one another and there is no
            //                     relationship of agent and principal or partnership between any of the Owner, the Parkers
            //                     and us.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Registration of an Account</strong>
            //             <ol>
            //                 <li>You need to register an Account before you can list a Parking Space on this Platform as
            //                     an Owner, contact an Owner or a Parker or to book a Parking Space as a Parker. The
            //                     registration of an Account is at the sole discretion of Let&rsquo;s Park which has the
            //                     right not to register an Account without assigning any reason.
            //                 </li>
            //                 <li>You can register an Account using an email address and creating a password or through
            //                     your account with certain third party social networking services.
            //                 </li>
            //                 <li>You will need to provide certain information about yourself when you register an Account
            //                     and our personal information collection statement and personal data policy which can be
            //                     found <strong><u>here</u></strong> shall apply to the personal data collected during the
            //                     registration process. You further undertake to provide us with information we may from
            //                     time to time request from you after the opening of your Account for the maintenance of
            //                     your Account and our provision of the Services.
            //                 </li>
            //                 <li>By registering an Account, you warrant and confirm that
            //                     <ul>
            //                         <li>the information you provide to us for registration is true and correct;</li>
            //                         <li>if you are an individual, you are over the age of 18 years;</li>
            //                         <li>the Account is for your own use and benefit and not as trustee or agent or on
            //                             behalf of any other party;
            //                         </li>
            //                         <li>if you are a corporation, the person registering an Account on your behalf is
            //                             properly authorised by you to do so.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>You shall keep the login details of your Account confidential and shall not disclose
            //                     them to any third party or allow any third party to use your Account.
            //                 </li>
            //                 <li>If you think someone is using or has used your Account or you think or you are aware
            //                     that your login details are known to a third party, you shall notify us immediately.
            //                 </li>
            //                 <li>You shall be liable for all activities in your Account using your login details until we
            //                     have acknowledged our receipt of your notice pursuant to clause 4.6.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Suspension or Termination of the Account</strong>
            //             <ol>
            //                 <li>We reserve the right to suspend your Account at any time without prior notice to protect
            //                     your interest and/or ours. You will be notified once we have suspended your Account and
            //                     you shall not be able to list new Parking Space, or book a Parking Space until we have
            //                     at our discretion re-activated your Account.&nbsp; During the suspension of your
            //                     Account, you shall not be able to book any Parking Space, list any Parking Space or
            //                     grant any Licence in respect of any Parking Space.
            //                 </li>
            //                 <li>We reserve the right to terminate your Account at any time without prior notice or
            //                     liability in the event of any breach of these Terms or a Licence Agreement to which you
            //                     are a party or if we consider at our sole and absolutely discretion that the
            //                     continuation of your Account will affect our reputation or interest. We may also
            //                     terminate your Account at any time without assigning any reason by given not less than
            //                     30 days&rsquo; notice to you. &nbsp;</li>
            //                 <li>You may terminate your Account at any time by following the procedures published by us
            //                     from time to time.
            //                 </li>
            //                 <li>The suspension or termination of your Account for whatever reason shall be without
            //                     prejudice to :-
            //                     <ul>
            //                         <li>the validity and/or continuation of any Booking which has been completed prior
            //                             to the termination or suspension and the related Licence Agreement subject to
            //                             the terms of such Licence Agreement;
            //                         </li>
            //                         <li>our right to receive Service Fee or Handling Fee in relation to all completed
            //                             Bookings and related Licence Agreement;
            //                         </li>
            //                         <li>the liability incurred by you through the Account prior to such termination or
            //                             suspension; and
            //                         </li>
            //                         <li>your obligations or liabilities under clauses 7.4, 8.5, 12.2(b), (c), 12.4,
            //                             12.5, 13, 14, 15, 16 to 21 and other provisions which due to their nature shall
            //                             continue during the suspension or after the termination of your Account.
            //                         </li>
            //                     </ul>
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Your Risk </strong>
            //             <ol>
            //                 <li>By using this Platform, you acknowledge there are risk associated with the granting and
            //                     obtaining of a Licence to a Parking Space through this Platform due to the nature of the
            //                     internet and online transactions. In particular, you acknowledge and agree to take the
            //                     risk that an Owner may not own the relevant Parking Space or may not have the right to
            //                     licence the relevant Parking Space to you or the identity of the Owner or Parker may not
            //                     be genuine.
            //                 </li>
            //                 <li>To reduce the risks as referred to in clause 6.1, we shall use reasonable commercial
            //                     effort to verify the ownership of a Parking Space by conducting a land search at the
            //                     Lands Registry of the Government of the Hong Kong Special Administrative Region on such
            //                     Parking Space and matching the result with the details provided by the Owner. You
            //                     acknowledge that it will not be practical for us to undertake further verification and
            //                     that we shall not be liable to you for any loss you may suffer in relation to a Licence
            //                     including but not limited to losses you may incur in the event that the Owner is not the
            //                     owner of the Parking Space and/or have not been authorised by the owner of the Parking
            //                     Space or the identity of the person you obtained a Licence from or grant a Licence to is
            //                     false.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Listing of Parking Spaces </strong>
            //             <ol>
            //                 <li>You may list a parking space which is owned by you or which you have the right to manage
            //                     by following the procedures as published by us from time to time and providing us with
            //                     the Parking Space Contents including but not limited to the photographs, address,
            //                     parking restrictions, access information, the type of vehicle the Parking Space is
            //                     suitable for and such other information or document we may request to verify your
            //                     identity and the ownership of the parking space.
            //                 </li>
            //                 <li>We shall have absolute discretion to decide whether to list a parking space on this
            //                     Platform and to withdraw any Parking Space from this Platform.
            //                 </li>
            //                 <li>By completing the procedures to list a parking space on this Platform, the Owner of such
            //                     parking space warrants and undertakes to us and other Users that:-
            //                     <ul>
            //                         <li>you are the owner of such parking space or have the authority of the owner to
            //                             grant a licence for such parking space on the terms as set out in the Licence
            //                             Agreement through this Platform;
            //                         </li>
            //                         <li>the Parking Space Contents in relation to such parking space you provided are
            //                             accurate and up to date and contain all the information a person will need to
            //                             know to decide whether to use such parking space; and
            //                         </li>
            //                         <li>you shall provide us with an update as soon as there is any change to the
            //                             Parking Space Contents and other information you submit to us with the listing
            //                             of such parking space.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>By listing a Parking Space on this Platform, the Owner of such Parking Space agrees:-
            //                     <ul>
            //                         <li>to grant to us a right to advertise the Parking Space to the Users, arrange for
            //                             a Licence for such Parking Space during the time that the Parking Space is
            //                             listed on this Platform on these Terms and the terms of the Licence Agreement;
            //                         </li>
            //                         <li>to appoint Let&rsquo;s Park to manage the Booking of that Parking Space on these
            //                             Terms and the terms of the Licence Agreement;
            //                         </li>
            //                         <li>to authorise us to receive any Deposit, Licence Fee and other payments payable
            //                             to you by a Parker and make refund or return any Deposit or balance thereof on
            //                             your behalf on these Terms and the terms of the Licence Agreement;
            //                         </li>
            //                         <li>to grant to us an irrevocable royalty free perpetual licence to use the Parking
            //                             Space Contents you submitted for the purpose of advertising the related Parking
            //                             Space for licence on the Platform and in any other manner as we may in our
            //                             absolute discretion think fit and also for promoting this Platform.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>We shall have the right but shall not be obliged to inspect a parking space and other
            //                     document or information in relation to its ownership prior to its listing on this
            //                     Platform or thereafter provided that any inspection or investigation on our part shall
            //                     be without prejudice to the Owner&rsquo;s responsibility and warranties as set out in
            //                     these Terms.
            //                 </li>
            //                 <li>Our listing of a Parking Space on this Platform and/or showing a Parking Space as a
            //                     search or filter result is not a recommendation or endorsement of the Parking Space by
            //                     us.
            //                 </li>
            //                 <li>The Owner can withdraw any Parking Space he listed on this Platform by following the
            //                     procedures published by us from time to time provided that any withdrawal of a Parking
            //                     Space shall be without prejudice to the force and effect of the Bookings and Licence
            //                     Agreements completed prior to the withdrawal or the Service Fee or Handling Fee payable
            //                     in relation to those Bookings and Licence Agreements.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Fees</strong>
            //             <ol>
            //                 <li>In consideration of the listing of a Parking Space on this Platform and to manage the
            //                     Bookings of that Parking Space on these Terms and the terms of the Licence Agreement,
            //                     the Owner shall pay the Service Fee to Let&rsquo;s Park in relation to each Booking of
            //                     that Parking Space on the following terms :
            //                     <ul>
            //                         <li>the Service Fee shall be at the rate of 10% of the total Parking Fee payable by
            //                             the Parker to the Owner for the whole duration of the Booking as contracted;
            //                         </li>
            //                         <li>without prejudice to our right to deduct the Service Fee from any sum received
            //                             by us from the Parker in relation to such Booking or any other Booking involving
            //                             the same Owner, the Service Fee in relation to a Booking shall be payable by the
            //                             Owner to us upon demand after the Booking has been completed; and
            //                         </li>
            //                         <li>for the avoidance of doubt, the obligations of the Owner to pay the Service Fee
            //                             for the full duration of the Booking as contracted shall not be affected by the
            //                             early termination or suspension of the Licence for such Booking for whatever
            //                             reason with or without the agreement of the Owner and the Parker and such shall
            //                             be paid within 20 business days after the early termination of the Licence of
            //                             such Booking for whatever reason.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>In addition to the Service Fee, we shall charge the Owner a Handling Fee of 10% all
            //                     amounts we receive or collect on behalf of the Owner in relation to a Booking or the
            //                     relevant Licence Agreement (other than the Parking Fee &amp; Deposit) and such Handling
            //                     Fee shall be deducted from the relevant amount received before passing the balance to
            //                     the Owner pursuant to clause 11.2;
            //                 </li>
            //                 <li>The obligation to pay the Service Fee and Handling Fee in relation to a Booking is not
            //                     affected by the subsequent suspension, closure or termination of the Account of the
            //                     Owner or the Parker or subsequent cancellation or termination of the Booking or the
            //                     refund by the Owner of any part of the Parking Fee to the Parker for whatever reason.
            //                 </li>
            //                 <li>Once the Service Fee or Handling Fee has been paid, it shall not be refundable.</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Booking of Parking Space</strong>
            //             <ol>
            //                 <li>If you wish to book a Parking Space which is listed as available for <strong><em>Instant
            //                     Booking</em></strong>:
            //                     <ul>
            //                         <li>you can book such Parking Space by selecting the Parking Space and the available
            //                             time slot; following the on-screen instructions to enter the relevant
            //                             information, and making the payment of the Parking Fee;
            //                         </li>
            //                         <li>once the payment of the Parking Fee is confirmed as successful, the Booking for
            //                             the selected Parking Space for the selected time slot shall be completed and we
            //                             shall send you the Confirmation summarising certain details of the Booking;
            //                         </li>
            //                         <li>in the event payment is rejected or unsuccessfully for whatever reason, the
            //                             booking shall not be completed; and
            //                         </li>
            //                         <li>unless expressly stated otherwise, the Booking will be valid for use once only
            //                             during the time slot and no multiple parking during the time slot will be
            //                             allowed.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>If you wish to book a Parking Space which is listed as available for <strong><em>Booking
            //                     by Agreement</em></strong>:-
            //                     <ul>
            //                         <li>you can request a booking for any of those Parking Spaces by selecting the
            //                             Parking Space and the available time slot by following the instructions on this
            //                             Platform;
            //                         </li>
            //                         <li>the Owner of the Parking Space shall have 24 hours to consider and accept the
            //                             request for booking through this Platform provided that if the Owner does not
            //                             accept your request within 24 hours of its submission, such request shall be
            //                             deemed to have been declined by the Owner;
            //                         </li>
            //                         <li>the acceptance of booking by the Owner is subject to you making the necessary
            //                             payment;
            //                         </li>
            //                         <li>once the Owner has accepted your request, you have 24 hours to complete the
            //                             booking by providing the information on the Vehicle through this Platform and
            //                             following the payment instructions for the Parking Fee and the Deposit where
            //                             applicable. Once the information on the Vehicle has been provided and payments
            //                             are successful, your booking for the selected Parking Space during the selected
            //                             time slot shall be completed and we will send you a Confirmation;
            //                         </li>
            //                         <li>if you do not complete your booking within 24 hours from our notification to you
            //                             that your booking request has been accepted, then such booking will not be
            //                             completed.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>By requesting a booking, you confirm that you are the owner of the vehicle which will be
            //                     using the Parking Space and the driver of such vehicle holds a valid driving licence.
            //                 </li>
            //                 <li>For the hourly booking, this platform will charge HK$300 to the Parker through
            //                     pre-authorization in case the Parker have the overstay problem, and the amount of HK$
            //                     300 will be refunded to the Parker once the service complete if
            //                     there is no overstay problem. The Owner have to provide the evidence of overstay to the
            //                     Parker and to the Manager and the handling fee will be applied to the Owner in this
            //                     case.
            //                 </li>
            //                 <li>For the monthly / Time Share booking, there are different type of the Parking Fee to be
            //                     applied based on the effective date of the service when the Parker book the parking
            //                     space in this Platform:
            //                     <ul>
            //                         <li>If the service date is effective on 1<sup>st</sup>, the Parker has to pay 1 x current month Parking Fee.
            //                         </li>
            //                         <li> If the service date is effective on 2<sup>nd</sup>, the Parker has to pay 1 x current month Parking Fee in pro rata basis PLUS 1 x following month Parking Fee.
            //                         </li>
            //                         <li>For the outstanding charges to the following months, the Platform will charge
            //                             the Parker by recurrent payment mechanism on 26<sup>th</sup> in each month basis
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>The acceptance of booking by the Owner is subject to you making the necessary payment;
            //                     All Bookings are not transferrable and is only valid for the parking of the Vehicle
            //                     during the agreed time slot.
            //                 </li>
            //                 <li>Once a Booking has been completed, it cannot be cancelled other than in accordance with
            //                     the terms expressly set out in the Licence Agreement.
            //                 </li>
            //                 <li>If you are concerned whether the Parking Space and its access are suitable for your
            //                     vehicle, please contact the Owner through this Platform or visit the Parking Space with
            //                     the consent of the Owner before booking as a Booking cannot be cancelled as a result of
            //                     assess or parking problems unless those problems are the results of inaccurate Parking
            //                     Space Contents.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Licence of Parking Space</strong>
            //             <ol>
            //                 <li>You agree that the Licence for a Booking shall be on the terms as set out in the version
            //                     of the Licence Agreement existing as at the date the Booking is completed. You further
            //                     agree that the terms of the Licence Agreement cannot be changed by agreement between the
            //                     Owner and Parker without express consent from Let&rsquo;s Park.&nbsp; It is therefore
            //                     important that you read and understand the latest version of the Licence Agreement as
            //                     posted on the Website before listing any Parking Space on this Platform or make or
            //                     confirm any request for booking. &nbsp;The Licence Agreement may change from time to
            //                     time so you should review it regularly.
            //                 </li>
            //                 <li>You acknowledge and agree that notwithstanding our role in the Licence, Let&rsquo;s Park
            //                     is not a party to the Licence Agreement and shall not be liable to the Owner or the
            //                     Parker in relation to the Licence Agreement or the Booking.
            //                 </li>
            //                 <li>The continuation and validity of a Booking and the related Licence Agreement shall not
            //                     be affected by termination or suspension of the Account of the Owner or the Parker.
            //                 </li>
            //                 <li>In case of inconsistency between these Terms and the Licence Agreement, terms of the
            //                     Licence Agreement shall prevail.
            //                 </li>
            //                 <li>We may amend the terms of the Licence Agreement from time to time by publishing the
            //                     latest version on this Platform provided that any amendment to the Licence Agreement
            //                     shall not affect the terms of the Bookings that have been completed before the amended
            //                     version takes effect.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>The Parking Fee and other Payments</strong>
            //             <ol>
            //                 <li>Subject to the terms of the Licence Agreement, you agree that the Parking Fee and the
            //                     Deposit (where applicable) and any other amount payable by the Parker to the Owner in
            //                     relation to a Parking Space shall be paid to us in the manner as set out in the Platform
            //                     and no amount shall be settled between the Owner and the Parker direct. All such amounts
            //                     shall be collected by us on behalf of the Owner.
            //                 </li>
            //                 <li>Within 5 x business days after the booking is started and our receipt of an amount
            //                     payable by the Parker to the Owner other than the Deposit where applicable, we shall
            //                     deduct the applicable Service Fee or Handling Fee therefrom and pay the balance into the
            //                     bank account as nominated by the Owner subject to clause 11.6. Our obligation shall be
            //                     discharged once the funds have been paid into such bank account.
            //                 </li>
            //                 <li>You agree that notwithstanding Let&rsquo;s Park&rsquo;s role as the manager of the
            //                     Parking Space and its collection of the Parking Fee and other payment in relation to a
            //                     Booking, Let&rsquo;s Park shall not be liable to the Owner or the Parker for the payment
            //                     or refund of any Parking Fee or any other payment other than as set out in clause 11.4.
            //                 </li>
            //                 <li>Other than as expressly referred to in the Licence Agreement, no amount paid by the
            //                     Parker to an Owner is refundable. Any refund under the Licence Agreement shall be made
            //                     through Let&rsquo;s Park provided that we shall not be liable to pay any refund to the
            //                     Parker unless we are holding funds for the Owner or we have been put in funds by the
            //                     Owner for the purpose of such refund.
            //                 </li>
            //                 <li>In the event a Deposit is payable in relation to a Parking Space :-
            //                     <ul>
            //                         <li>the Deposit shall be for such amount as is set out in the Platform for the
            //                             relevant Parking Space;
            //                         </li>
            //                         <li>the Owner hereby authorises us to deal with such Deposit on the terms as set out
            //                             in the relevant Licence Agreement;
            //                         </li>
            //                         <li>Let&rsquo;s Park will be holding the Deposit for the Owner and shall not be
            //                             liable to pay or return such or the balance to the Parker without the
            //                             authorisation of the Owner provided that upon the termination of a Licence,
            //                             Let&rsquo;s Park shall be deemed to have been authorised to return the Deposit
            //                             (or the balance thereof) if it has not received any notice from the Owner to
            //                             make any deduction from the Deposit within 2 business days from such
            //                             termination.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>In the event any amount is collected by us on behalf of an Owner from a Parker through
            //                     credit card and we are required to return any payment made through such credit card for
            //                     whatever reason (&ldquo;the Chargeback&rdquo;):-
            //                     <ul>
            //                         <li>we will not be required to pay the amount of the Chargeback to the Owner
            //                             pursuant to clause 11.2 and any claim by the Owner for the non-payment of the
            //                             amount due to the Chargeback shall be against the Parker;
            //                         </li>
            //                         <li>if we have already paid the amount collected to the Owner pursuant
            //                             to&nbsp; clause 11.2 of these Terms, the Owner shall pay to us the amount of the
            //                             Chargeback upon demand;
            //                         </li>
            //                         <li>without prejudice to clause 11.6 (b), we reserve the right to set off any amount
            //                             payable by the Owner to us under clause 11.6 (b) against any amount payable by
            //                             us to the Owner at the time or thereafter.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>In the event any amount is payable by a Parker in relation to a Booking, the Parker
            //                     agrees that we may obtain a pre-authorisation from the credit card as provided by the
            //                     Parker for an amount and a period we consider as reasonable to secure the amount payable
            //                     by the Parker and any other sum which may be payable by you in relation to such Booking.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Contents of this Platform</strong>
            //             <ol>
            //                 <li>You acknowledge that all Parking Space Contents are provided by the Owner and we are not
            //                     responsible to verify the same.
            //                 </li>
            //                 <li>In the event we at our discretion allow you to upload message on to this Platform:-
            //                     <ul>
            //                         <li>the message uploaded by you must be for a legal purpose and must not be obscene,
            //                             illegal, abusive, threatening, defamatory or breach of proprietary right of any
            //                             third party;
            //                         </li>
            //                         <li>you grant us an irrevocable royalty free perpetual licence to use the content of
            //                             your message for any purpose we may see fit;
            //                         </li>
            //                         <li>you agree that we may (but we are not obliged to) at our absolutely discretion
            //                             edit or withdraw any message uploaded by you in any way we see fit.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>We shall not be responsible for the contents of any message uploaded by a User to this
            //                     Platform and our publication of a message from a User on this Platform shall not be
            //                     deemed to be our approval or endorsement of its contents.
            //                 </li>
            //                 <li>Unless we specified otherwise, the copyright for all contents on this Platform other
            //                     than the Parking Space Contents and the message uploaded by you shall belong to
            //                     Let&rsquo;s Park and no licence or right has been granted by Let&rsquo;s Park in respect
            //                     of such content in any way.
            //                 </li>
            //                 <li>Unless you are the copyright owner of such content, you shall not copy, modify, alter,
            //                     publish, broadcast, distribute sell or transfer any contents on this Platform and may
            //                     not use any contents other than for the purpose of viewing or booking a Parking Space.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Prohibited Acts</strong>
            //             <ol>
            //                 <li>You agree that you shall not
            //                     <ul>
            //                         <li>post at or upload to the Platform any contents which is obscene, illegal,
            //                             abusive, threatening, defamatory or breach of proprietary right of any third
            //                             party;
            //                         </li>
            //                         <li>use this Platform for any illegal purpose or any purpose other than the listing,
            //                             viewing or licence of Parking Space;
            //                         </li>
            //                         <li>make any false illegal or unauthorised booking;</li>
            //                         <li>use any data mining, robots, or similar data gathering and extraction tools to
            //                             access or collect data or content from or interact with this Platform;
            //                         </li>
            //                         <li>interfere or attempt to interfere with the operation of this Platform, its
            //                             interface, or change any contents on this Platform or upload any virus or
            //                             corrupted data or take any action or interact with this Platform in anyway which
            //                             may affect or overload the performance of this Platform; or
            //                         </li>
            //                         <li>take any action to gain or attempt to gain any unauthorised access to part of
            //                             this Platform or data which you are not authorised to access or obtain any
            //                             information which has not been made available generally on this Platform.
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>You shall not without the express consent of Let&rsquo;s Park try to contact any other
            //                     User with a view to obtain or make available for licence of any Parking Space with or
            //                     without payment or in any way try to bypass this Platform in the licence of any Parking
            //                     Space during the continuation of your Account and for a period of 12 months after the
            //                     closure of the Account for whatever reason and without prejudice to any other right or
            //                     remedy Let&rsquo;s Park may have in the event of a breach of this provision, the Owner
            //                     shall pay to Let&rsquo;s Park as liquidated damages the amount of Service Fee the Owner
            //                     would have to pay to Let&rsquo;s Park had the licence they agreed outside the Platform
            //                     been contracted through the Platform.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Disclaimer and Limitation of Liability</strong>
            //             <ol>
            //                 <li>We have used all reasonable commercial effort to ensure that this Platform will work as
            //                     intended and will be free from bugs or error or virus but due to the nature of this
            //                     Platform, no warranty is given or is deemed to have been given to you in relation to the
            //                     information provided in or the operation of this Platform to the extent allowed by law.
            //                 </li>
            //                 <li>Although we will use all reason commercial efforts to mediate any dispute between the
            //                     Owner and the Parker, we shall not be liable for any act or omission of any party to the
            //                     Licence Agreement or the breach of the Licence Agreement by any party thereto.
            //                 </li>
            //                 <li>To the extent allowed by law, the liability of Let&rsquo;s Park under these Terms in
            //                     relation to a Booking shall be limited to the Service Fee received by us for such
            //                     Booking and we shall not be liable for any loss of profit, penalty, consequential losses
            //                     or damages of any kind whatsoever, whether in the contemplation of the parties or
            //                     otherwise and whether we have been advised of the possibility of such.
            //                 </li>
            //                 <li>We shall not be liable to you for any decision of fact we may make pursuant to the
            //                     provisions of these Terms or the Licence Agreement which you agree to be final and
            //                     binding on you.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Indemnity</strong>
            //             <ol>
            //                 <li>You shall at all times keep Let&rsquo;s Park, its officers and employees and agent
            //                     indemnified against all actions, proceedings, claims, demands, costs, losses, taxes,
            //                     damages and expenses of whatever nature which any of them may suffer, be liable for, or
            //                     incur by reason of or in connection with your breach of any of these Terms or the
            //                     Licence Agreement, the licence of the Parking Space or any of Let&rsquo;s Park&rsquo;s
            //                     roles under these Terms or the Licence Agreement.
            //                 </li>
            //                 <li>The indemnity as set out above shall survive the termination of your Account and shall
            //                     continue to have effect thereafter.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Link to or from website</strong>
            //             <ol>
            //                 <li>You shall not link this Platform to any other website without our express consent.</li>
            //                 <li>This Platform may include links to other websites and you may assess those other
            //                     websites at your sole risk. We are not responsible for any contents or goods or services
            //                     offered by those websites and the operations of those websites have not been endorsed or
            //                     recommended by us.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Amendment to these Terms</strong>
            //             <ol>
            //                 <li>We may amend these Terms from time to time by publishing the amended version on our
            //                     website with the effective date of the new version.
            //                 </li>
            //                 <li>If you do not accept the amended Terms, you shall terminate your Account pursuant to
            //                     clause 5.3. Your continuation of the Account after the effective date of any amendments
            //                     to these Terms shall be deemed to be your acceptance of such amendments.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Communication between Owner and Parker</strong>
            //             <ol>
            //                 <li>Any communication between Users in relation to a Parking Space prior to the completion
            //                     of the Booking shall be through this Platform and shall be monitored by us. We reserve
            //                     the right not to pass your message to another User or another User&rsquo;s message to
            //                     you if we consider that such message contains an attempt to bypass this Platform for a
            //                     licence of the Parking Space or if we consider the message to contain contents which is
            //                     in our opinion obscene, illegal, abusive, threatening, defamatory or breach of
            //                     proprietary right of any third party.
            //                 </li>
            //                 <li>After a Booking is completed, we shall put the Owner and Parker of such Booking in touch
            //                     with each other for the purpose of the Booking provided that such shall be without
            //                     prejudice to their obligations under these Terms or the provisions of the Licence
            //                     Agreement. &nbsp;&nbsp;</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Notice</strong>
            //             <ol>
            //                 <li>Unless otherwise specified in these Terms, any notice or document from us to you shall
            //                     be sent to the email address as specified by you on the registration of the Account as
            //                     amended from time to time and shall be deemed to have been received by you upon
            //                     despatch.
            //                 </li>
            //                 <li>You may change the email address provided by you for the service of notice to you under
            //                     this clause 19 by following the procedures published by us from time to time and such
            //                     change shall be effective upon your receipt of our acknowledge of such change.
            //                 </li>
            //                 <li>You agree that the notice or document sent to the email address referred to in this
            //                     clause shall be deemed to have been received upon it being sent and any subsequent
            //                     return or non-delivery of such email shall not affect the validity of such notice.
            //                 </li>
            //                 <li>Any notice or document from you to us may be sent by email, post or personal delivery to
            //                     the email address or address as published by us for this purpose from time to time and
            //                     shall be considered as received only upon its actual receipt by us.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Miscellaneous</strong>
            //             <ol>
            //                 <li>If any of these Terms is or become invalid, illegal or unenforceable in any respect
            //                     under any law, the validity, legality and enforceability of the remaining Terms shall
            //                     not in any way be affected or impaired and such remaining terms and conditions shall
            //                     remain in full force and effect.
            //                 </li>
            //                 <li>Any remedy or right expressly set out herein in relation to a breach by you shall be
            //                     without prejudice to and in additional to other right or remedy we have under law or
            //                     other part of these Terms or under any other agreement we have with you.
            //                 </li>
            //                 <li>Your rights and liabilities under these Terms cannot be assigned to any other person.
            //                 </li>
            //                 <li>We may assign our rights and liability under these Terms to any third party by notice in
            //                     writing to you and you shall take all reasonable action requested by us to effect such
            //                     transfer.
            //                 </li>
            //                 <li>Any waiver by us of any breach of these Terms on your part shall be effective only in
            //                     the instance and for the purpose for which it is given and no failure or delay by us in
            //                     exercising or enforcing any right under these Terms shall operate as a waiver thereof.
            //                 </li>
            //                 <li>None of our rights under these Terms may be waived unless agreed in writing by us
            //                     expressly by an authorised person.
            //                 </li>
            //                 <li>In the event of any inconsistency between the Chinese version and the English version of
            //                     these Terms, the English version shall prevail.
            //                 </li>
            //                 <li>The Contracts (Rights of Third Parties) Ordinance shall not apply to these Terms and no
            //                     person other than parties to these Terms shall have any right under it nor shall it be
            //                     enforceable by any person other than the parties to it.
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>Governing Law</strong>
            //             <ol>
            //                 <li>These Terms will be governed by and construed in accordance with the laws of Hong Kong
            //                     SAR.
            //                 </li>
            //                 <li>You agree to submit to the non-exclusive jurisdiction of the courts of Hong Kong SAR.
            //                 </li>
            //   </ol>
            //          </li>
            //     </ol>
            // </div>
        );
    }
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default Terms_en_US;
