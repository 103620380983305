import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Redirect } from 'react-router';
import { getRooms, getNotifications } from '../../socket/connect';

import Messages from './Messages';
import Notifications from './Notifications';

//import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {unreadMessages} from "../../utils/04Api/User";
import {mongoIdTimestamp} from "../../utils/00General/Helpers";
import {getInboxBookings} from "../../utils/04Api/Booking";

import {find, uniq} from 'lodash';

class Inbox extends Component {
	constructor(props) {
		super(props);

		let redirect = true,
			uid = null;

		if (this.props.members.Login.length !== 0) {
			redirect = false;
			uid = props.members.Login.user._id;
		}

		this.state = {
			userId: uid,
			redirect: redirect,
			rooms: null,
			notifications: null,
            // n_booking_owners: {}, //2019-07-29 Samson
            // n_booking_bookers: {}, //2019-07-29 Samson
			roomsLoaded: false,
			notificationLoaded: false,
		}

		this._isMounted = false;
	}

	componentDidMount() {
		this._isMounted = true;
		if (!this.state.redirect) {
			// readRoomMessages(this.props.members.Login.userToken, this.props.members.Login.user._id);
			this.setupSocket();
			this.setupNotifications();
		}
	}

	componentWillUnmount() {
		// console.log("set to not mounted");
		this._isMounted = false;
	}

	setupSocket = () => {
		let cb_called = false;
		const getRoomCb = (data) => {
			// prevent memory leak
			if (this._isMounted && !cb_called) {
				cb_called = true;

				// console.log(data);
				//samson

				let len = data.length;
				let swapped;

				// for (let i = 0; i < len; i++) {
				//     console.log(new Date(mongoIdTimestamp(data[i]._id)));
				// }

				do {
					swapped = false;
					for (let i = 0; i < len; i++) {
						if (data[i + 1] !== undefined) {
							// if (new Date(mongoIdTimestamp(data[i]._id)) < new Date(mongoIdTimestamp(data[i + 1]._id))) {
							if (new Date(mongoIdTimestamp(data[i].messages[0]._id)) < new Date(mongoIdTimestamp(data[i + 1].messages[0]._id))) {
								let tmp = data[i];
								data[i] = data[i + 1];
								data[i + 1] = tmp;
								swapped = true;
							}
						}
					}
				} while (swapped);

				// for (let i = 0; i < len; i++) {
				//     console.warn(new Date(mongoIdTimestamp(data[i]._id)));
				// }

				this.setState({
					...this.state,
					rooms: data,
					roomsLoaded: true,
				});

				if (typeof (this.props.members.Login.userToken) !== "undefined") {
					unreadMessages(this.props.members.Login.userToken, (obj) => {
						if (this._isMounted) {
							if (typeof (obj.body.total) !== "undefined" && parseInt(obj.body.total) >= 0 && obj.body.total !== this.state.totalUnreadMsg) {
								this.setState({
									...this.state,
									rooms_total_unread: obj.body.rooms
								})
							}
						}
					}, (err) => {
						// console.log(err);
					})
				}
			}
		}

		getRooms(this.state.userId, getRoomCb);
	}

	setupNotifications = () => {
		let cb_called = false;
		const cb = (data) => {
			if (this._isMounted && !cb_called) {
				cb_called = true;

				//samson
				let len = data.length;
				let swapped;
				do {
					swapped = false;
					for (let i = 0; i < len; i++) {
						if (data[i + 1] !== undefined) {
							if (new Date(mongoIdTimestamp(data[i]._id)) < new Date(mongoIdTimestamp(data[i + 1]._id))) {
								let tmp = data[i];
								data[i] = data[i + 1];
								data[i + 1] = tmp;
								swapped = true;
							}
						}
					}
				} while (swapped);

				// console.log('cb', data);
				let booking_ids = [];
				if (data.length) {
					data = data.map((notification) => {
						let id = null;
						if (typeof (notification.data) !== "undefined" && typeof (notification.data.booking_id) !== "undefined") {
							if (typeof (notification.data.booking_id) === "string")
								id = notification.data.booking_id;
							else if (typeof (notification.data.booking_id) === "object")
								id = notification.data.booking_id[0];
						}

						if (id) {
							notification.booking_id = id;
							booking_ids.push(id);
						}

						return notification;
					});
				}
				booking_ids = uniq(booking_ids);

				let cb = (res) => {
					const bookings = res.body;
					if (data.length && bookings.length) {
						data = data.map((notification, i) => {
							if (!notification.booking_id) return notification;
							const booking = bookings.find( b => b.booking_id === notification.booking_id);
							if (!booking) console.log(i, booking, notification)
							if (!booking) return notification;

							notification.booking = booking.booking;
							notification.owner = booking.owner;
							notification.booker = booking.booker;

							return notification;
						});
					}

					this.setState({
						...this.state,
						notifications: data,
						notificationLoaded: true,
					})
				};

				// console.log('booking_ids', booking_ids);

				getInboxBookings(this.props.members.Login.userToken, booking_ids, cb);

				//
				// this.setState({
				// 	...this.state,
				// 	notifications: data,
				// }, () => {
				//
				// 	console.log('cb', data);
				// 	return;
				// 	//2019-07-29 Samson
				// 	if (data !== null && data.length) {
				// 		data.forEach((notification, idx) => {
				// 			if (typeof (notification.data) !== "undefined" && typeof (notification.data.booking_id) !== "undefined") {
				// 				let cb = (res) => {
				// 					let booking_body = res.body,
				// 						booking_id = res.body._id;
				// 					if (booking_body !== null) {
				// 						if (booking_body.owner !== null) {
				// 							let cb = (res) => {
				// 								let states = {
				// 									...this.state,
				// 								};
				// 								states['n_booking_owners'][booking_id] = res.body;
				// 								this.setState(states);
				// 							};
				// 							getUserInfo(booking_body.owner._id, cb);
				// 						}
				//
				// 						if (booking_body.booker !== null) {
				// 							let cb = (res) => {
				// 								let states = {
				// 									...this.state,
				// 								};
				// 								states['n_booking_bookers'][booking_id] = res.body;
				// 								this.setState(states);
				// 							};
				// 							getUserInfo(booking_body.booker._id, cb);
				// 						}
				// 					}
				// 				};
				//
				// 				getBookingById(this.props.members.Login.userToken, notification.data.booking_id, cb);
				// 			}
				// 		});
				// 	}
				// })
			}
		}
		getNotifications(this.state.userId, cb);
	}

	render() {
		// console.log(this.state);
		const { i18n, t } = this.props;

		if (this.props.members.Login.length === 0)
			return <Redirect to={"/" + i18n.language + "/login"} />;

		return (
			<div className="wrapper-container messages">
				<div className="containerMain">
					<div className="content clearfix">
						<div className="wrapper-tabs clearfix">
							{/*<select className="readStatus">*/}
							{/*	<option className="unread">Unread</option>*/}
							{/*</select>*/}
							<Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
								<Tab eventKey={1} title={t("messenger:general.message")}>
									{!this.state.roomsLoaded &&
										<div className={"ListType5"}>
											<div className="border-box clearfix">
												<div>{t("common:Loading")}</div>
											</div>
										</div>
									}

									{this.state.roomsLoaded && !this.state.rooms.length &&
										<div>{t("messenger:general.noMessage")}</div>
									}

									{this.state.roomsLoaded && this.state.rooms.length &&
										<Messages
											userId={this.state.userId}
											rooms={this.state.rooms}
											rooms_total_unread={this.state.rooms_total_unread}
											_isMounted={this._isMounted}
										/>
									}
								</Tab>

								<Tab eventKey={2} title={t("messenger:general.notifications")}>
									{!this.state.notificationLoaded &&
										<div className={"ListType5"}>
											<div className="border-box clearfix">
												<div>{t("common:Loading")}</div>
											</div>
										</div>
									}

									{this.state.notificationLoaded && !this.state.notifications.length &&
										<div>{t("messenger:general.noNotification")}</div>
									}

									{this.state.notificationLoaded && this.state.notifications.length &&
										<Notifications
											userId={this.state.userId}
											notifications={this.state.notifications}
											_isMounted={this._isMounted}
										/>
									}
								</Tab>
							</Tabs>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => (
	{
		members: state.members,
	}
);

export default withTranslation()(connect(mapStateToProps)(Inbox));
