import * as MembersActionTypes from '../actiontypes/members';

const initialState = {
    Register: [],
    Login: [],
    MobileVerify: [],
}

export default function MembersReducer(state = initialState, action) {

    switch (action.type) {

        // to Register
        case MembersActionTypes.TO_REGISTER: {

            return {
                ...state,
                Register: {
                    isRegistered: true,
                    RegisterEmailAddress: action.data.EmailAddress,
                    RegisterPassword: action.data.Password,
                    RegisterConfirmPassword: action.data.ConfirmPassword,
                    RegisterFirstName: action.data.FirstName,
                    RegisterLastName: action.data.LastName,
                    RegisterObjectPersonalData: action.data.objectPersonalData,
                }
            }
        }

        // to Login
        case MembersActionTypes.TO_LOGIN: {

            return {
                ...state,
                Login: action.data
            }
        }

        // to Login
        case MembersActionTypes.MobileVerification: {

            return {
                ...state,
                MobileVerify: {
                    isMobileVerified: true,
                    countryCode: action.data.countryCode,
                    mobileNumber: action.data.mobileNumber,
                    smsVerificationCode: action.data.smsVerificationCode
                }
            }
        }

        // toggle wishList
        case MembersActionTypes.TOGGLE_WISHLIST: {

            if (state.Login.userWishList.indexOf(action.data) > -1) {
                return {
                    ...state,
                    Login: {
                        ...state.Login,
                        userWishList: state.Login.userWishList.filter(item => item !== action.data),
                    }
                }
            }

            return {
                ...state,
                Login: {
                    ...state.Login,
                    userWishList: [
                        ...state.Login.userWishList,
                        action.data
                    ]
                }
            }
        }

        // update wishlist
        case MembersActionTypes.UPDATE_WISHLIST: {
            return {
                ...state,
                Login: {
                    ...state.Login,
                    userWishList: action.data
                }
            }
        }

        case MembersActionTypes.UPDATE_ME: {
            return {
                ...state,
                Login: {
                    ...state.Login,
                    user: action.data
                }
            }
        }

        default:
            return state;
    }
}
