import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

class DaysOfWeek extends Component {
    render() {

        const {
            t,
            // i18n,
        } = this.props;

        return (
            <ul className="days-of-week clearfix">
                <li className={(this.props.active.indexOf('Mon') > -1) ? 'active' : ''}
                    onClick={(e) => this.props.handleDaysOfWeek('Mon')}>{t("shortDays.day_1")}</li>
                <li className={(this.props.active.indexOf('Tue') > -1) ? 'active' : ''}
                    onClick={(e) => this.props.handleDaysOfWeek('Tue')}>{t("shortDays.day_2")}
                </li>
                <li className={(this.props.active.indexOf('Wed') > -1) ? 'active' : ''}
                    onClick={(e) => this.props.handleDaysOfWeek('Wed')}>{t("shortDays.day_3")}
                </li>
                <li className={(this.props.active.indexOf('Thu') > -1) ? 'active' : ''}
                    onClick={(e) => this.props.handleDaysOfWeek('Thu')}>{t("shortDays.day_4")}
                </li>
                <li className={(this.props.active.indexOf('Fri') > -1) ? 'active' : ''}
                    onClick={(e) => this.props.handleDaysOfWeek('Fri')}>{t("shortDays.day_5")}
                </li>
                <li className={(this.props.active.indexOf('Sat') > -1) ? 'active' : ''}
                    onClick={(e) => this.props.handleDaysOfWeek('Sat')}>{t("shortDays.day_6")}
                </li>
                <li className={(this.props.active.indexOf('Sun') > -1) ? 'active' : ''}
                    onClick={(e) => this.props.handleDaysOfWeek('Sun')}>{t("shortDays.day_7")}
                </li>
                <li className={(this.props.active.indexOf('PH') > -1) ? 'active' : ''}
                    onClick={(e) => this.props.handleDaysOfWeek('PH')}>{t("shortDays.day_8")}
                </li>
            </ul>
        )
    }
}

export default withTranslation()(DaysOfWeek);
