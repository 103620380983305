import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {getLogo} from "../../utils/04Api/PartnerLogo";

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as InviteeActionCreators from '../actions/invitee';

class OurPartners extends Component {
    constructor(props) {
        super(props);

        this.state = {
            logo: [],
            retryCount: 0
        }

        this.logoContainer = React.createRef();
    }

    componentDidMount() {
        this.ApiGetLogo(this.ApiGetLogo);
    }

    ApiGetLogo = (errorCallback) => {

        let callback = (res) => {
            if (res.status === 200) {
                this.setState({
                    ...this.state,
                    logo: res.body
                })
            }
        }
        getLogo(callback, () => {
            this.setState({retryCount: this.state.retryCount + 1}, () => {
                if (this.state.retryCount <= 3){
                    if (typeof errorCallback === "function") errorCallback();
                }
            })
        });
    }

    render(){
        const {
            t,
            // i18n,
        } = this.props;

        if (!Array.isArray(this.state.logo)) return null;
        if (this.state.logo.length <= 0) return null;

        return (
            <div className="wrapper-benefitsOf">

                <h3>{t("Menu.Partners")}</h3>

                <div className="containerMain" style={{display: 'flex', justifyContent: 'center', background: '#FFF', padding: 15}}>
                    <div style={{flex: 1, display: 'flex', justifyContent: 'center', position: 'relative'}}>
                        <img
                          src={require('../../images/arrow-left.png')}
                          className="partnersArrow partnersArrowLeft"
                          onClick={() => {
                              this.logoContainer.current.scrollLeft -= 250;
                          }}
                          alt=""/>
                        <div ref={this.logoContainer} className="partnersContainer">
                            {
                                this.state.logo.map((item) =>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <img src={item.image} style={{
                                            height: 100,
                                            maxWidth: 250,
                                            margin: '10px 5px 0 5px',
                                            objectFit: 'contain'
                                        }}/>
                                    </div>
                                )
                            }
                        </div>
                        <img
                          src={require('../../images/arrow-right.png')}
                          className="partnersArrow partnersArrowRight"
                          onClick={() => {
                              this.logoContainer.current.scrollLeft += 250;
                          }}
                          alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(OurPartners);
