import React from 'react';
import {Redirect} from 'react-router';
import {withTranslation} from 'react-i18next';

// import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
import ReactPixel from "react-facebook-pixel";

// import * as findParkingSpaceActionCreators from '../../actions/findParkingSpace';

function ListASpaceConfirm(props) {
    const {t, i18n} = props;
    if (props.members.Login.length === 0) {
        return <Redirect to={"/" + i18n.language + "/login"}/>;
    }

    ReactPixel.track('SubmitApplication', {
        booking_type: props.params.booking_type,
    });

    return (
        <div className="wrapper-container confirmation padding">
            <div className="containerMain">
                <div className="content clearfix">
                    <h3 className="align-center">{t("listASpace:confirm.title")}</h3>
                    <p className="align-center">{t("listASpace:confirm.thanks")}</p>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => (
    {
        members: state.members,
    }
);

export default withTranslation()(connect(mapStateToProps)(ListASpaceConfirm));
