import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {getUIContent} from "../../utils/04Api/UIContent";

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as InviteeActionCreators from '../actions/invitee';

class Benefits extends Component {

    constructor(props) {
        super(props);

        this.state = {
            benefits: {
                'en-US': {
                    parker: [],
                    owner: [],
                    common: []
                },
                'zh-HK': {
                    parker: [],
                    owner: [],
                    common: []
                }
            },
        }
    }

    componentDidMount() {

        this.ApiGetBenefits(this.ApiGetBenefits);
    }

    ApiGetBenefits = (errorCallBack) => {

        let callback = (res) => {

            if (res.status === 200) {

                // console.log(res.body);

                let benefits = {
                    'en-US': {
                        parker: [],
                        owner: [],
                        common: []
                    },
                    'zh-HK': {
                        parker: [],
                        owner: [],
                        common: []
                    }
                };

                Object.keys(res.body).forEach(key => {

                    let row_data = res.body[key];

                    benefits[row_data.lang][row_data.data.benefit_type].push(row_data);
                });

                Object.keys(benefits).forEach(key => {

                    let benefits_lang = key;

                    let row_data_lang = benefits[key];

                    Object.keys(row_data_lang).forEach(key => {

                        let benefits_type = key;

                        let row_data_type = row_data_lang[key];

                        row_data_type.sort((a, b) => a.data.priority - b.data.priority);

                        benefits[benefits_lang][benefits_type] = row_data_type;
                    });
                });

                this.setState({
                    ...this.state,
                    benefits: benefits,
                });
            }
        }

        getUIContent("benefit", callback, errorCallBack);
    }

    render() {

        const {
            t,
            i18n
        } = this.props;

        // console.log(this.state.benefits['en-US'].common);

        return (
            <div className="wrapper-container benefits">

                <div className="page-title"><h2>{t("benefits:title")}</h2></div>

                <div className="sep-0"/>

                <div className="containerMain">
                    <div className="content clearfix">

                        {this.state.benefits[i18n.language].parker &&
                        <div>
                            <h3>{t("benefits:BenefitsofParkers.title-1")} {t("benefits:BenefitsofParkers.title-2")}</h3>
                            <ul className="benefitsOf clearfix">
                                {(this.state.benefits[i18n.language].parker).slice(0, 3).map((value, key) => {

                                    return (
                                        <div className="oneThird" key={key}>
                                            <li>
                                                <img className="icon" src={value.data.image} alt=""/>
                                                <h5>{value.data.title}</h5>
                                                <p>{value.data.desc}</p>
                                            </li>
                                        </div>
                                    );
                                })}
                            </ul>

                            <div className="sep-40"/>
                        </div>
                        }

                        {this.state.benefits[i18n.language].owner &&
                        <div>
                            <h3>{t("benefits:BenefitsofOwners.title-1")} {t("benefits:BenefitsofOwners.title-2")}</h3>
                            <ul className="benefitsOf clearfix">
                                {(this.state.benefits[i18n.language].owner).slice(0, 3).map((value, key) => {

                                    return (
                                        <div className="oneThird" key={key}>
                                            <li>
                                                <img className="icon" src={value.data.image} alt=""/>
                                                <h5>{value.data.title}</h5>
                                                <p>{value.data.desc}</p>
                                            </li>
                                        </div>
                                    );
                                })}
                            </ul>

                            <div className="sep-40"/>

                            <h3>{t("benefits:CommonBenefitsofParkersandOwners.title-1")}
                                <div className="sep-0"/>
                                {t("benefits:CommonBenefitsofParkersandOwners.title-2")}</h3>
                            <ul className="benefitsOf clearfix">
                                {(this.state.benefits[i18n.language].common).slice(0, 3).map((value, key) => {

                                    return (
                                        <div className="oneThird" key={key}>
                                            <li>
                                                <img className="icon" src={value.data.image} alt=""/>
                                                <h5>{value.data.title}</h5>
                                                <p>{value.data.desc}</p>
                                            </li>
                                        </div>
                                    );
                                })}
                            </ul>

                            <div className="sep-40"/>
                        </div>
                        }

                        <div className="full left align-center">
                            {/*<Link to={"/" + i18n.language + "/stepOne"} className="button">{t("General.ListYourSpace")}</Link>*/}
                            <Link className={"benefits_list_ahref"} to={"/" + i18n.language + "/stepOne"}>
                                <img
                                    src={require("../../images/mobile/01_homepage/icon_btn_list" + (i18n.language === 'zh-HK' ? "_tc" : "") + ".png")}
                                    alt=""/>
                            </Link>
                        </div>

                        <div className="sep-20"/>
                    </div>
                </div>
            </div>
        );
    }
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default withTranslation()(Benefits);
// connect(mapStateToProps)(About);