import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

class Restrictions extends Component
{

    render()
    {

        const {
            t,
            // i18n
        }=this.props;


       return this.props.restrictions.map((restriction,i)=>{
            switch (this.props.restrictions[i])
            {
                case 'No':
                    return <p key={i}>{t("space:tabDetails.restrictions.options.noRestriction")}</p>
                case 'Owners':
                    return <p key={i}>{t("space:tabDetails.restrictions.options.ownersBuilding")}</p>
                case 'Parkers':
                    return <p key={i}>{t("space:tabDetails.restrictions.options.ownersParkersBuilding")}</p>
                case 'Visitors':
                    return <p key={i}>{t("space:tabDetails.restrictions.options.visitors")}</p>
                default:
                    return <p key={i}>{t("space:tabDetails.restrictions.options.noRestriction")}</p>
            }
        })
    }
}

export default withTranslation()(Restrictions);