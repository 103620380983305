import {
    createStore,
    combineReducers,
    // applyMiddleware,
    // compose,
} from 'redux'

import {
    routerReducer,
} from 'react-router-redux'

import {persistReducer} from 'redux-persist';

import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import members from "./reducers/members";
import listParkingSpace from "./reducers/listParkingSpace";
import findParkingSpace from "./reducers/findParkingSpace";
import messages from "./reducers/messages";

// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
const appReducer = combineReducers({
    router: routerReducer,
    members,
    listParkingSpace,
    findParkingSpace,
    messages
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        storage.removeItem('persist:root')
        state.members = undefined;
        state.listParkingSpace = undefined;
        state.findParkingSpace = undefined;
        state.messages = undefined;
    }

    return appReducer(state, action)
}

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
};

const pReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
    pReducer,
    // compose(
    //     applyMiddleware(middleware, socketIoMiddleware(io)),
    //     window.devToolsExtension && window.devToolsExtension()
    // )
);

export default store;