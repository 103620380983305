export const lowestPrice = (values) => {
    let lowestPrice;

    if (values && values.length) {
        values.forEach((value) => {
            if (typeof (lowestPrice) === "undefined" || value.price < lowestPrice)
                lowestPrice = value.price;
        });
        return lowestPrice;
    } else {
        return null;
    }
}

// export const lowestPriceHourly = (values) => {
//     let lowestPrice;
//
//     if (values && values.length) {
//         values.forEach((value) => {
//             if (typeof (lowestPrice) === "undefined" || value.price < lowestPrice)
//                 lowestPrice = value.price;
//         });
//         return lowestPrice;
//     } else {
//         return null;
//     }
// }
//
// export const spaceLowestPrice = (space, booking_type) => {
//     let lowestPrice;
//
//     if (typeof(booking_type) === "undefined") {
//         booking_type = "";
//     }
//
//     switch(booking_type) {
//         case "":
//         case "hourly":
//             let p = lowestPriceHourly()
//     }
//
//     if (values && values.length) {
//         values.forEach((value) => {
//             if (typeof (lowestPrice) === "undefined" || value.price < lowestPrice)
//                 lowestPrice = value.price;
//         });
//         return lowestPrice;
//     } else {
//         return 0;
//     }
// }

export const isSamePrice = (values) => {
    let current, result = true;
    if (values.length) {
        values.forEach((value) => {
            if (typeof (current) === "undefined")
                current = value.price;
            else if (value.price !== current)
                result = false;
        });
    }
    return result;
}