import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import GridType4 from '../../components/102Grids/GridType4';
import ShowMore from '../../components/103Parts/ShowMore';

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as InviteeActionCreators from '../actions/invitee';

class Block extends Component {
    render() {
        return (
            <GridType4
                id={this.props.id}
                verified={this.props.verified}
                handleVerified={this.props.handleVerified}
                wishlist={this.props.wishlist}
                handleWishlist={this.props.handleWishlist}


            />
        )
    }
}

class Cluster extends Component {
    render() {
        let rows = [];
        for (let i = 0; i < 6; i++) {
            rows.push(
                <div className="oneThird left" key={i}>
                    <Block
                        key={i}
                        id={i}
                        verified={this.props.verified}
                        handleVerified={this.props.handleVerified}
                        wishlist={this.props.wishlist}
                        handleWishlist={this.props.handleWishlist}
                        image="../images/mobile/General/dummy-img/img_media.jpg"


                    />
                </div>
            )
        }
        return (rows);
    }
}

class Media extends Component {

    constructor(props) {
        super(props);

        this.state = {
            verified: {
                2: true
            },
            wishlist: {
                1: true
            }
        }
    }

    handleVerified = (id) => {
        this.setState({
            verified: {
                ...this.state.verified,
                [id]: !this.state.verified[id]
            }
        });
    }

    handleWishlist = (id) => {
        this.setState({
            wishlist: {
                ...this.state.wishlist,
                [id]: !this.state.wishlist[id]
            }
        });
    }

    render() {
        const {
            t,
            // i18n,
        } = this.props;
        return (
            <div className="wrapper-container media">

                <div className="page-title"><h2>{t("Menu.MediaCoverage")}</h2></div>

                <div className="sep-0" />

                <div className="containerMain">

                    {/*<div className="filter clearfix">*/}
                    {/*<div className="fifty left">*/}
                    {/*<div>*/}
                    {/*<input className="text" type="text" placeholder="News" />*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*<div className="fifty left">*/}
                    {/*<div>*/}
                    {/*<input className="sortby" type="text" placeholder="Sort by : Date" />*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*</div>*/}

                    {/*<div className="sep-0" />*/}

                    <div className="content clearfix">
                        <Cluster
                            verified={this.state.verified}
                            handleVerified={this.handleVerified}
                            wishlist={this.state.wishlist}
                            handleWishlist={this.handleWishlist}


                        />
                    </div>

                    <ShowMore />
                </div>
            </div>
        );
    }
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default withTranslation()(Media);
// connect(mapStateToProps)(About);