import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import GridType1 from '../../../components/102Grids/GridType1';
import MemberMenuDesktop from '../../../components/02Members/MemberMenuDesktop';
import MemberMenuMobile from '../../../components/02Members/MemberMenuMobile';

// import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import * as MembersActionCreators from '../../actions/members';

function Block(props) {
    return (
        <GridType1
            id={props.id}
            verified={props.verified}
            handleVerified={props.handleVerified}
            wishlist={props.wishlist}
            handleWishlist={props.handleWishlist}
            carTypes={props.carTypes}
        />
    )
}

function Cluster(props) {
    let rows = [];
    for (let i = 0; i < 6; i++) {
        rows.push(
            <div className="oneThird left" key={i}>
                <Block
                    key={i}
                    id={i}
                    verified={props.verified}
                    handleVerified={props.handleVerified}
                    wishlist={props.wishlist}
                    handleWishlist={props.handleWishlist}
                    carTypes={props.carTypes}
                />
            </div>
        )
    }
    return (rows);
}

class MemberHistory extends Component {

    constructor(props) {
        super(props);

        this.state = {
            carTypes: ['Indoor', 'EV'],
            verified: {
                2: true
            },
            wishlist: {
                1: true
            }
        }
    }

    handleVerified = (id) => {
        this.setState({
            verified: {
                ...this.state.verified,
                [id]: !this.state.verified[id]
            }
        });
    }

    handleWishlist = (id) => {
        this.setState({
            wishlist: {
                ...this.state.wishlist,
                [id]: !this.state.wishlist[id]
            }
        });
    }

    render() {
        // const {t} = this.props;
        return (
            <div className="wrapper-container media">
                <MemberMenuMobile />
                <div className="containerMain">
                    <MemberMenuDesktop />

                    <div className="content clearfix">
                        <Cluster
                            verified={this.state.verified}
                            handleVerified={this.handleVerified}
                            wishlist={this.state.wishlist}
                            handleWishlist={this.handleWishlist}
                            carTypes={this.state.carTypes}


                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {
        members: state.members
    }
);

export default withTranslation()(connect(mapStateToProps)(MemberHistory));