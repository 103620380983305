import React from 'react';
// import { Link } from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import * as Config from '../../config';

import moment from 'moment';
import DatePicker from "react-datepicker";

import DaysOfWeek from '../../components/103Parts/DaysOfWeek';
import TimePickerRange from '../../components/04ListASpace/TimePickerRange';
import CustomBigCalendar from '../../components/04ListASpace/CustomBigCalendar';
// import {Field, Form} from "formik";
// import ErrorMessage from "../../components/103Parts/ErrorMessage";

// const CURRENT_DATE = moment().toDate();

// import { connect } from 'react-redux';
// import { bindActionCreators } from "redux";
// import * as ListParkingSpaceCreators from "../../actions/listParkingSpace";

function TabHourly(props) {
    const {
        t,
        i18n,
        isCorp,
    } = props;

    let onDatepickerRef = (el) => {
        if (el && el.input) {
            el.input.readOnly = true;
        }
    }

    let genShowAddMoreDayTimeBtn = () => {
        const availableDayTimes = props.HourlyTab.Availability.AvailableDayTime,
            selectedDayOfWeek = availableDayTimes[0].SelectedDayOfWeek;
        if (availableDayTimes.length < 2 && (selectedDayOfWeek === "SatSunPH" || selectedDayOfWeek === "MonFri")) {
            return (
                <div className="form-elements clearfix">
                    <div className="full align-center">
                        <div>
                            <div onClick={() => props.TabAddDayTime('HourlyTab')}
                                 className="button additional"> {t("listASpace:stepTwo.availability.specificPeriod.additionalAvailability")}</div>
                            <div className="sep-10"/>
                        </div>
                    </div>
                </div>
            )
        } else if (availableDayTimes.length === 2) {
            return (
                <div className="form-elements clearfix">
                    <div className="full align-center">
                        <div>
                            <div onClick={() => props.TabDeleteDayTime('HourlyTab')}
                                 className="button delete"> {t("listASpace:stepTwo.availability.specificPeriod.deleteAdditionalAvailability")}</div>
                            <div className="sep-10"/>
                        </div>
                    </div>
                </div>
            );
        } else return null;
    }

    let genSeletecDaysOfWeek = (alwaysAvailable, availableDayTime, counter) => {
        let daysofweek;

        if (alwaysAvailable)
            daysofweek = <h3>{t("listASpace:stepTwo.pricing.price.dayOfWeek2")}</h3>
        else {
            if (availableDayTime.SelectedDayOfWeek !== 'Custom') {
                let selectedDayOfWeek;
                switch (availableDayTime.SelectedDayOfWeek) {
                    case 'SatSunPH':
                        selectedDayOfWeek = t("listASpace:stepTwo.pricing.price.dayOfWeek1");
                        break;
                    case 'MonSunPH':
                        selectedDayOfWeek = t("listASpace:stepTwo.pricing.price.dayOfWeek2");
                        break;
                    case 'Custom':
                        selectedDayOfWeek = t("listASpace:stepTwo.pricing.price.dayOfWeek3");
                        break;
                    default:
                        selectedDayOfWeek = t("listASpace:stepTwo.pricing.price.dayOfWeek4");
                        break;
                }
                daysofweek = <h3>{selectedDayOfWeek}</h3>
            } else {
                daysofweek = (
                    <DaysOfWeek
                        active={props.HourlyTab.Availability.AvailableDayTime[0].CustomDates}
                        handleDaysOfWeek={() => {
                        }}
                    />
                );
            }
        }

        const price = props.HourlyTab.Availability.Price[counter];

        // console.log("aa", availableDayTime)

        return (
            <div>
                {daysofweek}

                {isCorp &&
                <div
                    style={{fontSize: "16px"}}>{availableDayTime.SelectedTimeRange.TimeStart} - {availableDayTime.SelectedTimeRange.TimeEnd}</div>
                }
                <div className="form-elements clearfix">
                    <div className="fifty">
                        <div>
                            <div className="specialInput clearfix">
                                <div className="dollar">HK$</div>
                                <input type="text"
                                       onChange={(e) => props.HourlyTabChangePrice(e.target.value, counter, 0)}
                                       value={price[0]}
                                />
                                <div className="per-month">{t("listASpace:stepTwo.pricing.price.perHour")}</div>
                            </div>
                        </div>
                    </div>
                    <div className="fifty">
                        <div>
                            {price[0] > 0 &&
                            <p className="color-green">{t("listASpace:stepTwo.pricing.price.receive")} HK$ {(price[0] * (1 - Config.COMMISION_PERCENTAGE)).toFixed(1)} {t("listASpace:stepTwo.pricing.price.perHour")}</p>
                            }
                        </div>
                    </div>
                </div>

                {!alwaysAvailable && availableDayTime.SelectedTimeRange2 &&
                <div>
                    <div
                        style={{fontSize: "16px"}}>{availableDayTime.SelectedTimeRange2.TimeStart} - {availableDayTime.SelectedTimeRange2.TimeEnd}</div>
                    <div className="form-elements clearfix">
                        <div className="fifty">
                            <div>
                                <div className="specialInput clearfix">
                                    <div className="dollar">HK$</div>
                                    <input type="text"
                                           onChange={(e) => props.HourlyTabChangePrice(e.target.value, counter, 1)}
                                           value={price[1]}
                                    />
                                    <div className="per-month">{t("listASpace:stepTwo.pricing.price.perHour")}</div>
                                </div>
                            </div>
                        </div>
                        <div className="fifty">
                            <div>
                                {price[1] > 0 &&
                                <p className="color-green">{t("listASpace:stepTwo.pricing.price.receive")} HK$ {(price[1] * (1 - Config.COMMISION_PERCENTAGE)).toFixed(1)} {t("listASpace:stepTwo.pricing.price.perHour")}</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        );


        // if (props.HourlyTab.Availability.AlwaysAvailable === 'Yes')
        //     return <h3>Mon to Sun & PH</h3>
        //
        // if (props.HourlyTab.Availability.AvailableDayTime[0].SelectedDayOfWeek !== 'Custom') {
        //     let selectedDayOfWeek;
        //     switch (props.HourlyTab.Availability.AvailableDayTime[0].SelectedDayOfWeek) {
        //         case 'SatSunPH':
        //             selectedDayOfWeek = 'Sat, Sun & PH';
        //             break;
        //         case 'MonSunPH':
        //             selectedDayOfWeek = 'Mon to Sun & PH';
        //             break;
        //         case 'Custom':
        //             selectedDayOfWeek = 'Customize';
        //             break;
        //         default:
        //             selectedDayOfWeek = 'Mon to Fri';
        //             break;
        //     }
        //     return <h3>{selectedDayOfWeek}</h3>
        // }
        //
        // // Custom
        // return (
        //     <DaysOfWeek
        //         active={props.HourlyTab.Availability.AvailableDayTime[0].CustomDates}
        //         handleDaysOfWeek={() => { }}
        //     />
        // )
    }

    let genAvailableTimeAndPricing = () => {
        let avail = props.HourlyTab.Availability;

        //console.log("gena");

        let htmls = [];

        if (avail.AlwaysAvailable === 'Yes') {
            htmls.push(genSeletecDaysOfWeek(true, null, 0))

            //
            // return (
            //     <div>
            //         <h3>Mon to Sun & PH</h3>
            //
            //     </div>
            // )
        } else {
            for (let i = 0; i < avail.AvailableDayTime.length; i++) {
                let availableDayTime = avail.AvailableDayTime[i];
                htmls.push(genSeletecDaysOfWeek(false, availableDayTime, i));
            }
        }

        return (
            <div>
                {htmls.map((d, index) =>
                    <div key={index}>{d}</div>
                )}
            </div>
        )
    }

    let tabName = 'HourlyTab',
        tabData = props[tabName];

    //let availableTimeAndPricing = genAvailableTimeAndPricing();

    let availableDayTime = (
        <div>
            {
                tabData.Availability.AvailableDayTime.map((daytime, index) =>
                    <div key={index}>
                        <div className="form-elements clearfix">
                            <div className="fifty">
                                <div>
                                    <select
                                        onChange={(e) => props.TabChangeAvailableDay(tabName, e.target.value, index)}
                                        value={daytime.SelectedDayOfWeek}
                                    >
                                        {(index === 0 || (index === 1 && tabData.Availability.AvailableDayTime[0].SelectedDayOfWeek === "SatSunPH")) &&
                                        <option
                                            value="MonFri">{t("listASpace:stepTwo.pricing.price.dayOfWeek4")}</option>
                                        }
                                        {(index === 0 || (index === 1 && tabData.Availability.AvailableDayTime[0].SelectedDayOfWeek === "MonFri")) &&
                                        <option
                                            value="SatSunPH">{t("listASpace:stepTwo.pricing.price.dayOfWeek1")}</option>
                                        }
                                        {index === 0 &&
                                        <option
                                            value="MonSunPH">{t("listASpace:stepTwo.pricing.price.dayOfWeek2")}</option>
                                        }

                                        {!isCorp && index === 0 &&
                                        <option
                                            value="Custom">{t("listASpace:stepTwo.pricing.price.dayOfWeek3")}</option>
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="fifty">
                                <div>
                                </div>
                            </div>
                        </div>

                        {daytime.SelectedDayOfWeek === 'Custom' &&
                        <div className="form-elements clearfix">
                            <div className="full">
                                <div className="narrow">
                                    <DaysOfWeek
                                        active={daytime.CustomDates}
                                        handleDaysOfWeek={(v) => {
                                            props.TabHandleCustomDayTime(tabName, v)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        }

                        <div className="form-elements clearfix">
                            <div className={tabName !== "HourlyTab" ? "fifty" : "oneThird"}>
                                <div>
                                    <select
                                        onChange={(e) => {
                                            props.TabChangeAvailableTime(tabName, 'TimeStart', e.target.value, index)
                                        }}
                                        value={daytime.SelectedTimeRange.TimeStart}
                                    >
                                        <TimePickerRange maxTime={daytime.SelectedTimeRange.TimeEnd}/>
                                    </select>
                                </div>
                            </div>

                            <div className={tabName !== "HourlyTab" ? "fifty" : "oneThird"}>
                                <div className={isCorp && tabName !== "HourlyTab" ? "shorter" : ""}>
                                    <select
                                      onChange={(e) => {
                                          props.TabChangeAvailableTime(tabName, 'TimeEnd', e.target.value, index)
                                      }}
                                      value={daytime.SelectedTimeRange.TimeEnd}
                                    >
                                        <TimePickerRange minTime={daytime.SelectedTimeRange.TimeStart}/>
                                    </select>
                                </div>
                                {
                                    isCorp && tabName !== "HourlyTab" && (
                                      <div className="shorterFriend">
                                          <div>
                                              {!daytime.SelectedTimeRange2 &&
                                              <span
                                                className="button add"
                                                onClick={() => props.TabAddAvailableTime(tabName, index)}
                                              />
                                              }

                                              {daytime.SelectedTimeRange2 &&
                                              <span
                                                className="button remove"
                                                onClick={() => props.TabRemoveAvailableTime(tabName, index, 1)}
                                              />
                                              }
                                          </div>
                                      </div>
                                    )
                                }
                            </div>

                            {
                                tabName === "HourlyTab" && (
                                  <div className="oneThird">
                                      <div>
                                          <div className={isCorp ? "shorter" : ""}>
                                              <h5 style={{height: 20, paddingBottom: 6}}>
                                                  {t("listASpace:stepTwo.availability.specificPeriod.maxHour")}
                                              </h5>
                                              <input type="number" name="MaxHour"
                                                     style={{height: 30}}
                                                     value={daytime.SelectedTimeRange.MaxHour || ''}
                                                     disabled={daytime.SelectedTimeRange2 && !!daytime.SelectedTimeRange2.MaxHour}
                                                     min={0}
                                                     onChange={(e) => {
                                                         let newValue = parseInt(e.target.value);
                                                         let periodStart = parseInt(daytime.SelectedTimeRange.TimeStart);
                                                         let periodEnd = parseInt(daytime.SelectedTimeRange.TimeEnd);
                                                         if (newValue && !isNaN(periodStart) && !isNaN(periodEnd) && periodEnd - periodStart < newValue) return;
                                                         props.TabChangeAvailableTime(tabName, 'MaxHour', newValue || '', index)
                                                     }}/>
                                          </div>
                                          {
                                              isCorp && (
                                                <div className="shorterFriend">
                                                    <div>
                                                        {!daytime.SelectedTimeRange2 &&
                                                        <span
                                                          className="button add"
                                                          onClick={() => props.TabAddAvailableTime(tabName, index)}
                                                        />
                                                        }

                                                        {daytime.SelectedTimeRange2 &&
                                                        <span
                                                          className="button remove"
                                                          onClick={() => props.TabRemoveAvailableTime(tabName, index, 1)}
                                                        />
                                                        }
                                                    </div>
                                                </div>
                                              )
                                          }
                                      </div>
                                  </div>
                                )
                            }


                            {/*{isCorp &&*/}
                            {/*<div className="fifty">*/}
                            {/*    <div>*/}

                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*}*/}
                        </div>

                        {isCorp && daytime.SelectedTimeRange2 &&
                        <div className="form-elements clearfix">
                            <div className={tabName !== "HourlyTab" ? "fifty" : "oneThird"}>
                                <div>
                                    <select
                                        onChange={(e) => {
                                            props.TabChangeAvailableTime2(tabName, 'TimeStart', e.target.value, index)
                                        }}
                                        value={daytime.SelectedTimeRange2.TimeStart}
                                    >
                                        <TimePickerRange maxTime={daytime.SelectedTimeRange2.TimeEnd}/>
                                    </select>
                                </div>
                            </div>

                            <div className={tabName !== "HourlyTab" ? "fifty" : "oneThird"}>
                                <div>
                                    <div className={tabName !== "HourlyTab" ? "shorter" : ""}>
                                        <select
                                            onChange={(e) => {
                                                props.TabChangeAvailableTime2(tabName, 'TimeEnd', e.target.value, index)
                                            }}
                                            value={daytime.SelectedTimeRange2.TimeEnd}
                                        >
                                            <TimePickerRange minTime={daytime.SelectedTimeRange2.TimeStart}/>
                                        </select>
                                    </div>
                                    {
                                        tabName !== "HourlyTab" && (
                                          <div className="shorterFriend">
                                              <div>
                                                  <span
                                                    className="button remove"
                                                    onClick={() => props.TabRemoveAvailableTime(tabName, index, 2)}
                                                  />
                                              </div>
                                          </div>
                                        )
                                    }
                                </div>
                            </div>

                            {
                                tabName === "HourlyTab" && (
                                  <div className="oneThird">
                                      <div>
                                          <div className={"shorter"}>
                                              <h5 style={{height: 20, paddingBottom: 6}}>
                                                  {t("listASpace:stepTwo.availability.specificPeriod.maxHour")}
                                              </h5>
                                              <input type="number" name="MaxHour"
                                                     style={{height: 30}}
                                                     value={daytime.SelectedTimeRange2.MaxHour || ''}
                                                     disabled={!!daytime.SelectedTimeRange.MaxHour}
                                                     min={0}
                                                     onChange={(e) => {
                                                         let newValue = parseInt(e.target.value);
                                                         let periodStart = parseInt(daytime.SelectedTimeRange2.TimeStart);
                                                         let periodEnd = parseInt(daytime.SelectedTimeRange2.TimeEnd);
                                                         if (newValue && !isNaN(periodStart) && !isNaN(periodEnd) && periodEnd - periodStart < newValue) return;
                                                         props.TabChangeAvailableTime2(tabName, 'MaxHour', newValue || '', index)
                                                     }}/>
                                          </div>
                                          <div className="shorterFriend">
                                              <div className="shorterFriend">
                                                  <div>
                                                    <span
                                                        className="button remove"
                                                        onClick={() => props.TabRemoveAvailableTime(tabName, index, 2)}
                                                    />
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                )
                            }
                        </div>
                        }
                    </div>
                )
            }
        </div>
    )

    let showAddMoreDayTimeBtn = genShowAddMoreDayTimeBtn();

    let dayClassName = (date) => {
        return props.isPublicHoliday(date) ? 'ph' : undefined;
    }

    let blockdates = (
        <div>
            {
                (tabData.Availability.BlockedDateRange).map((blockDate, index) =>
                    <div className="form-elements clearfix" key={index}>
                        <div className="fifty">
                            <div>
                                <DatePicker
                                    dateFormat="YYYY-MM-DD"
                                    selected={blockDate.DateStart === "" ? null : moment(blockDate.DateStart)}
                                    onChange={(e) => props.TabChangeBlockDate(tabName, 'DateStart', e, index)}
                                    minDate={moment()}
                                    // maxDate={blockDate.DateEnd ? moment(blockDate.DateEnd) : null}
                                    placeholderText={t("listASpace:stepTwo.availability.blockPeriod.from")}
                                    ref={el => onDatepickerRef(el)}
                                    dayClassName={dayClassName}
                                />
                            </div>
                        </div>
                        <div className="fifty">
                            <div>
                                <div className="shorter">
                                    <DatePicker
                                        dateFormat="YYYY-MM-DD"
                                        selected={blockDate.DateEnd === "" ? null : moment(blockDate.DateEnd)}
                                        onChange={(e) => props.TabChangeBlockDate(tabName, 'DateEnd', e, index)}
                                        minDate={blockDate.DateStart ? moment(blockDate.DateStart) : moment()}
                                        // maxDate={moment().add(5, "days")}
                                        placeholderText={t("listASpace:stepTwo.availability.blockPeriod.until")}
                                        ref={el => onDatepickerRef(el)}
                                        dayClassName={dayClassName}
                                    />
                                </div>
                                <div className="shorterFriend">
                                    <div>
                                        <span
                                            className="button remove"
                                            onClick={() => props.TabRemoveUnavailability(tabName, index)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sep-10"/>
                    </div>
                )
            }
        </div>
    )

    return (
        <div>
            <div className="title">
                {t("listASpace:stepTwo.availability.title")}
            </div>

            <div className="content">
                {/* from & to */}
                <div className="form-elements clearfix">
                    <div className="fifty">
                        <div>
                            <DatePicker
                                dateFormat="YYYY-MM-DD"
                                selected={tabData.Availability.SelectedDateRange.DateStart === "" ? null : moment(tabData.Availability.SelectedDateRange.DateStart)}
                                onChange={(e) => props.HourlyTabHandleChange('SelectedDateRange', 'DateStart', e)}
                                minDate={moment()}
                                // maxDate={tabData.Availability.SelectedDateRange.DateEnd ? moment(tabData.Availability.SelectedDateRange.DateEnd) : null}
                                placeholderText={t("listASpace:stepTwo.availability.from")}
                                ref={el => onDatepickerRef(el)}
                                dayClassName={dayClassName}
                            />
                        </div>
                    </div>
                    <div className="fifty">
                        <div>
                            <DatePicker
                                dateFormat="YYYY-MM-DD"
                                selected={tabData.Availability.SelectedDateRange.DateEnd === "" ? null : moment(tabData.Availability.SelectedDateRange.DateEnd)}
                                onChange={(e) => props.HourlyTabHandleChange('SelectedDateRange', 'DateEnd', e)}
                                minDate={tabData.Availability.SelectedDateRange.DateStart ? moment(tabData.Availability.SelectedDateRange.DateStart) : moment()}
                                // maxDate={moment().add(5, "days")}
                                placeholderText={t("listASpace:stepTwo.availability.until")}
                                ref={el => onDatepickerRef(el)}
                                dayClassName={dayClassName}
                            />
                        </div>
                    </div>
                </div>
                {/* EOF from & to */}

                <div className="sep-10"/>

                {/* always available */}
                {!isCorp &&
                <div>
                    <h3>{t("listASpace:stepTwo.availability.alwaysAvailable.title")}</h3>
                    <div className="form-elements clearfix">
                        <div className="fifty">
                            <div>
                                <div className="checkboxFive green">
                                    <div>
                                        <input type="checkbox" id="AlwaysAvailable" value="Yes"
                                               checked={tabData.Availability.AlwaysAvailable === 'Yes'}
                                               onChange={(e) => props.HourlyTabChangeStateRadioLevelOne('Availability', 'AlwaysAvailable', e.target.value)}
                                        />
                                        <label htmlFor="AlwaysAvailable"></label>
                                    </div>
                                    <label
                                        htmlFor="AlwaysAvailable">{t("listASpace:stepTwo.availability.alwaysAvailable.yes")}</label>
                                </div>
                            </div>
                        </div>
                        <div className="fifty">
                            <div>
                                <div className="checkboxFive green">
                                    <div>
                                        <input type="checkbox" id="notAlwaysAvailable" value="No"
                                               checked={tabData.Availability.AlwaysAvailable === 'No'}
                                               onChange={(e) => props.HourlyTabChangeStateRadioLevelOne('Availability', 'AlwaysAvailable', e.target.value)}
                                        />
                                        <label htmlFor="notAlwaysAvailable"></label>
                                    </div>
                                    <label
                                        htmlFor="notAlwaysAvailable">{t("listASpace:stepTwo.availability.alwaysAvailable.no")}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                {/* EOF always available */}

                {/* available */}
                {(isCorp || tabData.Availability.AlwaysAvailable === 'No') &&
                <div>
                    <div className="sep-10"/>

                    <h3>{t("listASpace:stepTwo.availability.specificPeriod.title")}</h3>

                    {availableDayTime}

                    <div className="sep-10"/>

                    {showAddMoreDayTimeBtn}

                </div>
                }
                {/* EOF available */}

                <div className="sep-10"/>

                {/* blocked */}
                <div>
                    <h3>{t("listASpace:stepTwo.availability.blockPeriod.title")}</h3>

                    {blockdates}

                    <div className="sep-10"/>

                    <div className="form-elements clearfix">
                        <div className="full align-center">
                            <div>
                                <div onClick={() => props.TabAddUnavailability(tabName)}
                                     className="button additional"> {t("listASpace:stepTwo.availability.blockPeriod.additionalUnavailability")}</div>
                                <div className="sep-10"/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* EOF blocked */}

                <hr/>

                <div className="sep-30"/>

                <CustomBigCalendar
                    tabData={tabData}
                    onSelectEvent={props.HourlyCalendarHandleEventClick}
                    HourlyCalendarHandleCloseEditor={props.HourlyCalendarHandleCloseEditor}
                    HourlyCalendarHandleSetCustomAvailableDates={props.HourlyCalendarHandleSetCustomAvailableDates}
                    t={t}
                    i18n={i18n}
                />

                {/*<div className="wrapper-calendar">*/}
                {/*<BigCalendar*/}
                {/*localizer={this.localizer}*/}
                {/*events={tabData.CalendarData.AvailableDates}*/}
                {/*startAccessor="start"*/}
                {/*endAccessor="end"*/}
                {/*views={{ month: true }}*/}
                {/*components={{*/}
                {/*// you have to pass your custom wrapper here*/}
                {/*// so that it actually gets used*/}
                {/*toolbar: CustomToolBar,*/}
                {/*dateCellWrapper: this.ColoredDateCellWrapper,*/}
                {/*event: CustomEvent*/}
                {/*}}*/}
                {/*onSelectEvent={props.HourlyCalendarHandleEventClick}*/}
                {/*/>*/}

                {/*{tabData.CalendarData.Editing !== null &&*/}
                {/*<BigCalendarCustomEditor*/}
                {/*date={tabData.CalendarData.Editing}*/}

                {/*/>*/}
                {/*}*/}
                {/*</div>*/}

                <div className="sep-30"/>
            </div>

            <div className="sep-30"/>
            <div className="sep-30"/>

            {/* form-elements */}

            <div className="title">
                {t("listASpace:stepTwo.pricing.title")}
            </div>

            <div className="content">

                {genAvailableTimeAndPricing()}

                <div className="sep-10"/>

                <h3>{t("listASpace:stepTwo.pricing.booking.title")}</h3>
                <div className="form-elements clearfix">
                    <div className="fifty">
                        <div>
                            <select
                                onChange={(e) => props.HourlyTabChangeStateLevelOne('minBooking', e.target.value)}
                                value={tabData.Pricing.minBooking}
                            >
                                <option value="1">1 {t("listASpace:stepTwo.pricing.booking.hour")}</option>
                                <option value="2">2 {t("listASpace:stepTwo.pricing.booking.hour")}</option>
                                <option value="3">3 {t("listASpace:stepTwo.pricing.booking.hour")}</option>
                                <option value="4">4 {t("listASpace:stepTwo.pricing.booking.hour")}</option>
                                <option value="5">5 {t("listASpace:stepTwo.pricing.booking.hour")}</option>
                            </select>
                        </div>
                    </div>
                    <div className="fifty">
                        <div>

                        </div>
                    </div>
                </div>

                <div className="sep-10"/>

                {props.AccessType !== "No" &&
                <div>
                    <h3>{t("listASpace:stepTwo.pricing.accessCard.title")}</h3>
                    <div className="form-elements clearfix">
                        <div className="fifty">
                            <div>
                                <select
                                    onChange={(e) => props.HourlyTabChangeStateLevelOne('deposit', e.target.value)}
                                    value={tabData.Pricing.deposit}
                                >
                                    <option value="0">HK$0</option>
                                    <option value="100">HK$100</option>
                                    <option value="200">HK$200</option>
                                    <option value="300">HK$300</option>
                                    <option value="400">HK$400</option>
                                    <option value="500">HK$500</option>
                                </select>
                            </div>
                        </div>
                        <div className="fifty">
                            <div></div>
                        </div>
                    </div>

                    <div className="sep-10"/>
                </div>
                }

                <h3>{t("payment:promotionCode.title")}</h3>
                <div className="form-elements clearfix">
                    <div className="fifty">
                        <div>
                            <input type="text" name="promotionCode"
                                   onChange={(e) => props.changeStateLevelTwo('HourlyTab', 'Promotion', e.target.value)}/>
                        </div>
                    </div>
                    <div className="fifty">
                        <div></div>
                    </div>
                </div>

                <div className="sep-10"/>
            </div>
        </div>
    );
}

// const mapStateToProps = (state) => (
// 	{
// 		listParkingSpace: state.listParkingSpace
// 	}
// );

export default withTranslation()(TabHourly);
// connect(mapStateToProps)(TabHourly);
