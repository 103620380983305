import React, {Component} from 'react';
import {Redirect} from 'react-router';
import {withTranslation} from 'react-i18next';

import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';

import ErrorMessage from '../../../components/103Parts/ErrorMessage';

import {connect} from 'react-redux';
import * as UserApi from "../../../utils/04Api/User";
import ReactPixel from "react-facebook-pixel";

class MemberCreateAccountCorporate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            userCreated: false,
            smsDelay: 0,
            submitting: false,
            smsError: null,
        }
    }

    componentDidMount() {

        ReactPixel.trackCustom('MemberForm', {category_type: 'Corporate'});
    }

    //
    // ApiGetEmailToken = (values) => {
    //     let cb = (res) => {
    //         if (typeof (res.body) !== "undefined") {
    //             if (res.body.status === 0) {
    //                 console.log("send email error", res.body.msg);
    //             } else if (res.body.status === 1) {
    //                 console.log("send email success", res.body);
    //             }
    //         }
    //         this.setState({
    //             ...this.state,
    //             userCreated: true,
    //             email: values.email,
    //         });
    //     }
    //
    //     const eCb = (obj) => {
    //         console.log("send email error", obj);
    //
    //         this.setState({
    //             ...this.state,
    //             userCreated: true,
    //             email: values.email,
    //         });
    //     }
    //
    //     UserApi.getEmailToken(values.email, window.location.origin + '/' + this.props.i18n.language + '/memberEmailValiation', cb, eCb);
    // }

    handleSMSCountdown = () => {
        // Set countdown to 120
        this.setState({
            ...this.state,
            smsDelay: 120,
        }, () => {
            // start count down
            const countdown = () => {
                setTimeout(() => {
                    let smsDelay = this.state.smsDelay - 1;
                    this.setState({
                        ...this.state,
                        smsDelay: smsDelay
                    }, () => {
                        if (this.state.smsDelay > 0)
                            countdown();
                    })
                }, 1000)
            }
            countdown();
        })
    }

    handleSMS = (values) => {
        if (this.state.smsDelay === 0) {
            const yupPhone = Yup.object().shape({
                phone: Yup.string()
                    .required("registerCorporate:errors.input.mobile")
                    .min(8, "registerCorporate:errors.input.invalidMobile"),
            })


            yupPhone.validate({
                phone: values.phone,
            })
                .then((value) => {
                    // SEND VERIFICATION CODE
                    const cb = (obj) => {
                        if (obj.status === 200) {
                            this.handleSMSCountdown();
                        } else {
                            // console.log("smsLoginGetToken failed", obj.body.msg);
                            if (obj.body.msg === 'mobile exist') {
                                this.setState({
                                    ...this.state,
                                    smsError: "registerCorporate:errors.mobile"
                                })
                            } else if (obj.body.msg === '10 times sms limit reach, you will be blocked for 24 hours'){
                                this.setState({
                                    ...this.state,
                                    smsError: "login:errors.smsLimitation"
                                })
                            } else if (obj.body.msg === 'You IP blocked for 24 hours'){
                                this.setState({
                                    ...this.state,
                                    smsError: "login:errors.smsLimitation"
                                })
                            }
                        }
                    }
                    const eCb = (obj) => {
                        // console.log("smsLoginGetToken failed ecb", obj);
                    }
                    UserApi.getSmsToken(values.phone, cb, eCb);
                })
                .catch((err) => {
                    this.setState({
                        ...this.state,
                        smsError: err.message
                    })
                });
        }
    }

    schema = Yup.object().shape({
        companyname: Yup.string()
            .required('registerCorporate:errors.input.company'),
        jobtitle: Yup.string()
            .required('registerCorporate:errors.input.job'),
        firstName: Yup.string()
            .required('registerCorporate:errors.input.firstName'),
        lastName: Yup.string()
            .required('registerCorporate:errors.input.lastName'),
        email: Yup.string()
            .required('registerCorporate:errors.input.email')
            .email('registerCorporate:errors.input.invalidEmail'),
        password: Yup.string()
            .required('registerCorporate:errors.input.password')
            .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/, 'registerCorporate:errors.input.passwordFormat'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], "registerCorporate:errors.input.confirmPassword"),
        phone: Yup.string()
            .required("registerCorporate:errors.input.mobile")
            .min(8, "registerCorporate:errors.input.invalidMobile"),
        code: Yup.string()
            .required('registerCorporate:errors.input.code')
            .length(6, "registerCorporate:errors.input.invalidCode"),
    })

    form = ({values, errors, touched, handleChange, handleBlur, setErrors, setFieldTouched}) => {
        const {
            t,
            // i18n
        } = this.props;

        return (
            <Form>
                <dl className="generalForm clearfix">
                    <div>
                        <dt><h4>{t("registerCorporate:companyName")}</h4></dt>
                        <dd>
                            <Field name="companyname" type="text" maxLength="50"/>
                            {errors.companyname && touched.companyname ?
                                <ErrorMessage message={errors.companyname}/> : null}
                        </dd>
                    </div>

                    <div className="sep-25"/>

                    <div>
                        <dt><h4>{t("registerCorporate:jobTitle")}</h4></dt>
                        <dd>
                            <Field name="jobtitle" type="text" maxLength="50"/>
                            {errors.jobtitle && touched.jobtitle ?
                                <ErrorMessage message={errors.jobtitle}/> : null}
                        </dd>
                    </div>

                    <div className="sep-25"/>

                    <div>
                        <dt><h4>{t("registerCorporate:title")}</h4></dt>
                        <dd>
                            <select
                                name="title"
                                value={values.title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                <option value="mr">{t("myAccount:tabProfile.honorificOptions.mr")}</option>
                                <option value="ms">{t("myAccount:tabProfile.honorificOptions.ms")}</option>
                                <option value="mrs">{t("myAccount:tabProfile.honorificOptions.mrs")}</option>
                                <option value="miss">{t("myAccount:tabProfile.honorificOptions.miss")}</option>
                            </select>
                            {errors.title && touched.title ?
                                <ErrorMessage message={errors.title}/> : null}
                        </dd>
                    </div>

                    <div className="sep-25"/>

                    <div>
                        <dt><h4>{t("registerCorporate:firstName")}</h4></dt>
                        <dd>
                            <Field name="firstName" type="text" maxLength="20"/>
                            {errors.firstName && touched.firstName ?
                                <ErrorMessage message={errors.firstName}/> : null}
                        </dd>
                    </div>

                    <div className="sep-25"/>

                    <div>
                        <dt><h4>{t("registerCorporate:lastName")}</h4></dt>
                        <dd>
                            <Field name="lastName" type="text" maxLength="20"/>
                            {errors.lastName && touched.lastName ?
                                <ErrorMessage message={errors.lastName}/> : null}
                        </dd>
                    </div>

                    <div className="sep-25"/>

                    <div>
                        <dt><h4>{t("registerCorporate:emailAddress")}</h4></dt>
                        <dd>
                            <Field name="email" type="email" maxLength="100"/>
                            {errors.email && touched.email ?
                                <ErrorMessage message={errors.email}/> : null}
                        </dd>
                    </div>

                    <div className="sep-25"/>

                    <div>
                        <dt><h4>{t("registerCorporate:password")}</h4></dt>
                        <dd>
                            <Field name="password" type="password" maxLength="30"/>
                            {errors.password && touched.password ?
                                <ErrorMessage message={errors.password}/> : null}
                        </dd>
                    </div>

                    <div className="sep-25"/>

                    <div>
                        <dt><h4>{t("registerCorporate:confirmPassword")}</h4></dt>
                        <dd>
                            <Field name="confirmPassword" type="password" maxLength="30"/>
                            {errors.confirmPassword && touched.confirmPassword ?
                                <ErrorMessage message={errors.confirmPassword}/> : null}
                        </dd>
                    </div>

                    <div className="sep-25"/>

                    <div>
                        <dt><h4>{t("registerCorporate:mobileNumber")}</h4></dt>
                        <dd>
                            <Field name="phone" type="text" maxLength="8"/>
                            {errors.phone && touched.phone ?
                                <ErrorMessage message={errors.phone}/> : null
                            }
                            {!errors.phone && this.state.smsError ?
                                <ErrorMessage message={this.state.smsError}/> : null
                            }
                        </dd>
                    </div>

                    <div className="sep-25"/>

                    <div>
                        <dt><h4>{t("registerCorporate:verificationCode")}</h4></dt>
                        <dd>
                            <Field name="code" type="text" maxLength="6"/>
                            {errors.code && touched.code ?
                                <ErrorMessage message={errors.code}/> : null}
                        </dd>
                    </div>

                    <div className="sep-25"/>

                    <div>
                        <dt>&nbsp;</dt>

                        <dd>
                            {t("registerCorporate:sendVerificationCode")}
                            &nbsp;
                            <button type="button" className="button" onClick={() => {
                                this.handleSMS(values);
                            }}>{t(this.state.smsDelay > 0 ? t("registerCorporate:resend") + this.state.smsDelay + t("registerCorporate:second") : t("registerCorporate:send"))}</button>
                        </dd>
                    </div>

                    <div className="sep-25"/>

                    <div>
                        <dt></dt>
                        <dd>
                            <div className="checkboxFive">
                                <div>
                                    <input type="checkbox" id="directMarketing"
                                           name="directMarketing" onChange={handleChange}/>
                                    <label htmlFor="directMarketing"/>
                                </div>
                                <label htmlFor="directMarketing">
                                    {t("registerCorporate:directMarketing")}
                                </label>
                            </div>

                            {this.state.errors &&
                            <div className="sep-20"/>
                            }

                            {errors.general ?
                                <ErrorMessage message={errors.general}/> : null}
                        </dd>
                    </div>
                </dl>

                <div className="sep-25"/>
                <div className="sep-25"/>

                <div className="full left align-center">
                    <div>
                        <button type="submit" className="button">{t("registerCorporate:submit")}</button>
                    </div>
                </div>
            </Form>
        )
    }
    //
    // register = (values, setFieldError) => {
    //     let body = {
    //         companyname: values.companyname,
    //         jobtitle: values.jobtitle,
    //         title: 'Mr',
    //         first_name: values.firstName,
    //         last_name: values.lastName,
    //         hkid_name: values.firstName + ' ' + values.lastName,
    //         email: values.email,
    //         smsisvalid: 1,
    //         passwd: values.password,
    //         phone: String(values.phone),
    //         code: values.code,
    //         vehicles: [],
    //         accesscard: [],
    //         octcard: [],
    //         payment: [],
    //         marketing: values.directMarketing,
    //         isCorp: true,
    //     };
    //
    //     let cb = (res) => {
    //         console.log(res.body);
    //         if (typeof (res.body) !== "undefined") {
    //             if (res.body.msg === 'user exist') {
    //                 setFieldError('general', this.props.t("registerCorporate:errors.email"));
    //             } else if (res.body.token) {
    //                 this.ApiGetEmailToken(values);
    //             } else {
    //                 setFieldError('general', this.props.t("Cannot create account"));
    //             }
    //         }
    //     }
    //
    //     const eCb = (obj) => {
    //         setFieldError('general', this.props.t("Cannot create account"));
    //     }
    //
    //     UserApi.register(body, cb, eCb);
    // }


    setSubmitting = (submitting, cb) => {
        if (typeof (cb) !== "function")
            cb = () => {
                return false
            }
        this.setState({
            ...this.state,
            submitting: submitting
        }, cb);
    }

    handleSubmit = (values, {setFieldError}) => {
        if (!this.state.submitting) {
            this.setState({
                ...this.state,
                smsError: null
            }, () => {
                this.setSubmitting(true, () => {
                    let language = 'english';
                    if (typeof (this.props.i18n.language) !== "undefined" && this.props.i18n.language === 'zh-HK')
                        language = 'chinese';

                    let body = {
                        companyname: values.companyname,
                        jobtitle: values.jobtitle,
                        title: values.title,
                        first_name: values.firstName,
                        last_name: values.lastName,
                        hkid_name: values.firstName + ' ' + values.lastName,
                        email: values.email,
                        smsisvalid: 1,
                        passwd: values.password,
                        phone: String(values.phone),
                        code: values.code,
                        vehicles: [],
                        accesscard: [],
                        octcard: [],
                        payment: [],
                        messages: ['email'],
                        marketing: !values.directMarketing,
                        isCorp: true,
                        language: language,
                    };

                    let cb = (res) => {
                        // console.log(res.body);
                        if (typeof (res.body) !== "undefined") {
                            if (typeof (res.body.status) !== "undefined" && res.body.status === 1) {
                                // this.ApiGetEmailToken(values);
                                this.setState({
                                    ...this.state,
                                    userCreated: true,
                                    email: values.email,
                                });
                            } else if (typeof (res.body.msg) !== "undefined") {
                                if (res.body.msg === 'user exist') {
                                    setFieldError('general', this.props.t("registerCorporate:errors.email"));
                                } else if (res.body.msg === 'No such record') {
                                    setFieldError('general', this.props.t("registerCorporate:errors.input.invalidCode"));
                                } else if (res.body.msg === 'mobile exist') {
                                    setFieldError('general', this.props.t("registerCorporate:errors.mobile"));
                                }
                                this.setSubmitting(false);
                            } else {
                                setFieldError('general', this.props.t("Cannot create account"));
                                this.setSubmitting(false);
                            }

                            // if (res.body.msg === 'user exist') {
                            //     setFieldError('general', this.props.t("registerCorporate:errors.email"));
                            // } else if (res.body.token) {
                            //     this.ApiGetEmailToken(values);
                            // } else {
                            //     setFieldError('general', this.props.t("Cannot create account"));
                            // }
                        }
                    }

                    const eCb = (obj) => {
                        setFieldError('general', this.props.t("Cannot create account"));
                        this.setSubmitting(false);
                    }

                    UserApi.register(body, cb, eCb);
                })
            });
        }


        // same shape as initial values
        // console.log(values);
        //
        // const cb = (obj) => {
        //     if (obj.status === 200) {
        //         // this.loginSuccessCallback(obj, setFieldError, 'phoneGeneral');
        //         this.register(values, setFieldError)
        //     } else if (obj.status === 400) {
        //         setFieldError('code', "Invalid mobile code");
        //     }
        // }
        //
        // const eCb = (obj) => {
        //     setFieldError('code', this.props.t("Invalid mobile code"));
        // }
        //
        // UserApi.smsValidate(values.phone, values.code, cb, eCb);
    }

    render() {
        const {
            t,
            i18n
        } = this.props;

        if (this.state.userCreated === true) {
            return <Redirect to={"/" + i18n.language + "/corporate-register-success?email=" + this.state.email}/>;
        }


        return (
            <div className="wrapper-container memberCreateAccount padding">
                <div className="containerMain borderTop">
                    <div className="content clearfix">
                        <div className="full left align-center">
                            {/*<span className="door"></span>*/}
                            <h2>{t("registerCorporate:welcome")}</h2>
                            <p>{t("registerCorporate:createAccount")}</p>
                        </div>

                        <div className="sep-20"/>

                        <Formik
                            initialValues={{
                                companyname: '',
                                jobtitle: '',
                                title: 'mr',
                                firstName: '',
                                lastName: '',
                                email: '',
                                password: '',
                                confirmPassword: '',
                                phone: '',
                                code: '',
                                directMarketing: false,
                            }}
                            validationSchema={this.schema}
                            onSubmit={this.handleSubmit}
                            component={this.form}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {
        members: state.members
    }
);

export default withTranslation()(connect(mapStateToProps)(MemberCreateAccountCorporate));
