import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

import DisclaimerZHHK from '../../components/10General/Disclaimer_zh_HK';
import DisclaimerENUS from '../../components/10General/Disclaimer_en_US';
import {getTnc} from "../../utils/04Api/Tnc";

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as InviteeActionCreators from '../actions/invitee';

class Disclaimer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            disclaimer: null,
        }
    }

    componentDidMount() {

        this.ApiGetDisclaimer(this.ApiGetDisclaimer);
    }

    ApiGetDisclaimer = (errorCallback) => {

        let callback = (res) => {

            if (res.status === 200) {

                this.setState({
                    ...this.state,
                    disclaimer: res.body
                })
            }
        }

        getTnc('disclaimer', callback, errorCallback);
    }

    render() {
        const {
            t,
            i18n
        } = this.props;

        // console.log(this.state.disclaimer);

        return (
            <div className="wrapper-container media">

                <div className="page-title"><h2>{t("Menu.Disclaimer")}</h2></div>

                <div className="sep-0"/>

                <div className="containerMain">
                    <div className="content clearfix">
                        {i18n.language === 'zh-HK'
                            ? this.state.disclaimer !== null ?
                                <DisclaimerZHHK content={this.state.disclaimer['zh-hk'].disclaimer_content_chi.value}/> : ''
                            : this.state.disclaimer !== null ?
                                <DisclaimerENUS content={this.state.disclaimer['en-us'].disclaimer_content_eng.value}/> : ''
                        }
                    </div>
                </div>
            </div>
        );
    }
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default withTranslation()(Disclaimer);
