import {bcodeApiFetch, bcodeApiPost} from "./General";

export const getBCodeEnabledListings = (callback, errorCallback) => {
    bcodeApiFetch('listings/?isBCodeEnabled=true', null, callback, errorCallback);
}

export const checkBCodeById = (spaceID, callback, errorCallback) => {
    bcodeApiFetch('listings/' + spaceID, null, callback, errorCallback);
}

export const getListingById = (spaceID, callback, errorCallback) => {
    bcodeApiFetch('listings/listing/' + spaceID, null, callback, errorCallback);
}

