import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Prev extends Component {
    render() {
        return (
            <Link to={this.props.prevURL} className="prev" onClick={this.props.prevHandler}>&#60; {this.props.prevText}</Link>
        )
    }
}

class PrevNext extends Component {
    render() {
        return (
            <div className="prevNext">
                {this.props.prevHas ?
                    <Prev
                        prevURL={this.props.prevURL}
                        prevHandler={this.props.prevHandler}
                        prevText={this.props.prevText}
                    /> : ''}
                <Link to={this.props.nextURL} onClick={this.props.nextHandler} className={"next " + (this.props.prevHas ? '' : 'align-center')}>{this.props.nextText} &#62;</Link>
                <div className="sep-0" />
                <div className={"progressBar " + this.props.progress}>
                    <span>{this.props.value}</span>
                </div>
            </div>
        )
    }
}

export default PrevNext;
