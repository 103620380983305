import React, {Component} from 'react';
import {useTranslation, withTranslation} from 'react-i18next';
import moment from "moment";

import {Redirect} from 'react-router';
// import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
// import * as findParkingSpaceActionCreators from '../../actions/findParkingSpace';
import ErrorMessage from '../../components/103Parts/ErrorMessage';

// import GridType5 from '../../components/102Grids/GridType5';
// import * as Config from "../../config";
// import {lowestPrice} from "../../utils/03FindParkingSpace/LowestHourlyPrice";
// import {getSpaceDetail} from "../../utils/00General/Api";
import * as Helper from "../../utils/03FindParkingSpace/Calculations";
import Verified from "../../components/103Parts/Verified";
import Heart from "../../components/103Parts/Heart";
import Reviews from "../../components/103Parts/Reviews";

import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';

import * as UserApi from "../../utils/04Api/User";
import {saveCreditCard} from "../../utils/04Api/Payment";
import {bookASpace} from "../../utils/04Api/Booking";
import {Link} from "react-router-dom";
// import {updateUserInfo} from "../../utils/04Api/User";
// import {filter, findIndex} from "lodash";

// import {sendCard, sendMessage} from '../../socket/connect';

import {checkPromotionCode} from "../../utils/04Api/Promotion";
import * as Config from "../../config";
import ReactPixel from "react-facebook-pixel";
import {checkBCodeById, getListingById} from "../../utils/04Api/Bcode";
import swal from 'sweetalert';
import "../../css/101Components/_sweetAlert.css";

import * as SpaceApi from "../../utils/04Api/Space";

class Payment extends Component {

    constructor(props) {
        super(props)

        let logined = false,
            uid = '';
        if (this.props.members.Login.length !== 0) {
            logined = true;
            uid = props.members.Login.user._id;
        }

        this.state = {
            spaceID: props.space_id,
            type: props.params.booking_type,
            startDate: moment(props.params.from + "T" + (typeof (props.params.from_time) !== "undefined" ? props.params.from_time : "00:00") + ":00+0800"),
            endDate: moment(props.params.to + "T" + (typeof (props.params.to_time) !== "undefined" ? props.params.to_time : "23:59") + ":00+0800"),
            startTime: (typeof (props.params.from_time) !== "undefined" ? props.params.from_time : null),
            endTime: (typeof (props.params.to_time) !== "undefined" ? props.params.to_time : null),

            uid: uid,
            space: null,
            total: null,
            redirect: false,
            logined: logined,
            wishlist: [],
            me: null,
            selectedCard: 'newCreditCard',
            newCard: true,
            paymentID: null,
            calculation: null,

            submitting: false,
            values: null,

            completed: false,
            isInstant: (typeof (props.params.instant) !== "undefined" ? props.params.instant : false),

            loading: true,
            defaultCreditCardID: '',
            defaultVehicle: '',

            ddd: null,
            bcode: false,
            // otp: null,
            time: 0,
            formattedTime: "00:00",
            isRunning: false,
            earlyInTime: 0,
            gracePeriod: 0,
            preBookBlocker: 0,
            postBookBlocker: 0,
            overstayFine: 0,
            extendPrice: 0,
        }

        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
        this.ApiGetQuotaHoldTime = this.ApiGetQuotaHoldTime.bind(this);
    }

    componentWillMount = () => {
        this.ApiGetMe(this.ApiGetSpace);
        setInterval(this.ApiGetQuotaHoldTime, 1000 * 10);
    }

    startTimer() {
        this.timer = setInterval(this.countDown, 1000);
        this.setState({isRunning: true})
    }

    countDown() {
        if (this.state.time === 0) {
            clearInterval(this.timer);
            this.setState({
                time: 0,
                formattedTime: "00:00",
                isRunning: false
            });
            return
        }

        let newTime = this.state.time - 1;
        let minute = newTime/60 < 10 ? `0${Math.floor(newTime/60)}` : `${Math.floor(newTime/60)}`
        let second = newTime%60 < 10 ? `0${newTime%60}` : `${newTime%60}`
        this.setState({
            time: newTime,
            formattedTime: `${minute}:${second}`
        });
    }

    changeStateLevelOne = (data1, value) => {
        this.setState({
            [data1]: value
        })
    }

    ApiGetMe = (cb) => {
        if (this.state.logined) {
            const token = this.props.members.Login.userToken;

            UserApi.getMe(token, (obj) => {
                if (obj.status === 200) {
                    const me = obj.body;
                    let newCard = true,
                        paymentID = null;
                    if (me.payment.length > 0) {
                        newCard = false;
                        paymentID = me.payment[0]._id;
                    }

                    let defaultCreditCardID = '';

                    if (obj.body.payment.length > 0) {

                        Object.keys(obj.body.payment).forEach(key => {
                            let payment = obj.body.payment[key];
                            if (payment.default) {
                                defaultCreditCardID = payment._id;
                            }
                        });

                        if (defaultCreditCardID === '') {
                            defaultCreditCardID = obj.body.payment[0]._id;
                        }
                    }

                    let defaultVehicleID = '';
                    if (obj.body.vehicles.length > 0) {
                        obj.body.vehicles.forEach((itm, idx) => {
                            if (itm.default) {
                                defaultVehicleID = itm._id;
                            }
                        });

                        if (defaultVehicleID === '')
                            defaultVehicleID = obj.body.vehicles[0]._id;
                    }

                    let defaultOctopusID = '';
                    //20190715 commented by samson as client requested no default octcard
                    // if (obj.body.octcard.length > 0) {
                    //     obj.body.octcard.forEach((itm, idx) =>  {
                    //         if(itm.default) {
                    //             defaultOctopusID = itm._id;
                    //         }
                    //     });
                    //
                    //     if (defaultOctopusID === '')
                    //         defaultOctopusID = obj.body.octcard[0]._id;
                    // }

                    this.setState({
                        ...this.state,
                        me: obj.body,
                        newCard: newCard,
                        paymentID: paymentID,
                        defaultCreditCardID: defaultCreditCardID,
                        defaultVehicleID: defaultVehicleID,
                        defaultOctopusID: defaultOctopusID,
                    }, cb)
                } else {
                    this.setState({
                        logined: false
                    });
                }
            })
        }
    }

    ApiGetSpace = () => {

        const access_token = this.props.members.Login.userToken;

        let callback = (type, results) => {
            // console.log("results", results);
            if (results.success) {
                //console.log(obj.body.length);
                // console.log(results.space);
                const space = results.data;

                if (space.owner._id === this.props.members.Login.user._id) {
                    // own space
                    this.setState({
                        ...this.state,
                        redirect: true,
                    });
                    return;
                }

                if (space.status !== 1) {
                    // space is not on
                    this.setState({
                        ...this.state,
                        redirect: true,
                    });
                    return;
                }

                this.setState({
                    ...this.state,
                    total: results.totalcost,
                    space: space,
                    calculation: results,
                    loading: false
                });

                this.ApiGetQuotaHoldTime();
                this.startTimer();

            } else {
                this.setState({
                    ...this.state,
                    redirect: true,
                });
            }
        };

        checkBCodeById(this.state.spaceID,
            (response) => {
                let data = response.body.responseDetail;
                this.setState({bcode: data ? data.isBCodeEnabled || false : false}, () => {
                    Helper.CalculateHelper(this.state.type, this.state, this.state.spaceID, this.state.bcode, access_token, callback);
                })
            },
            (obj) => {
                this.setState({
                    errors: obj.body ? obj.body.messages : ''
                });
                Helper.CalculateHelper(this.state.type, this.state, this.state.spaceID, this.state.bcode, access_token, callback);
            }
        );

        getListingById(this.state.spaceID,
            (response) => {
                let data = response.body.responseDetail;
                let toDateMoment = moment(this.state.endDate);
                this.setState({
                    earlyInTime: data ? data.earlyInTime || 0 : 0,
                    gracePeriod: data ? data.gracePeriod || 0 : 0,
                    preBookBlocker: data ? data.preBookBlocker || 0 : 0,
                    postBookBlocker: data ? data.postBookBlocker || 0 : 0,
                    overstayFine: data && data.overstayFine ? data.overstayFine[toDateMoment.day()] || 0 : 0,
                    extendPrice: data && data.extendPrice ? data.extendPrice[toDateMoment.day()] || 0 : 0,
                })
            }, (obj) => {
                this.setState({
                    errors: obj.body ? obj.body.messages : ''
                });
            })

    }

    ApiGetQuotaHoldTime() {
        if ((this.props.members.Login.user || {})._id) {
            SpaceApi.getQuotaHoldTime(this.state.spaceID, this.props.members.Login.user._id,
              (callback) => {
                  let time = Math.round(moment(callback.body.until).diff(moment())/1000)
                  if (time <= 0) time = 0
                  this.setState({time})
                  if (time > 0 && !this.state.isRunning) this.startTimer()
              }, (error) => {
                  this.setState({time: 0})
                  console.log("error", error)
              })
        }
    }

    handleVerified = (id) => {
        this.setState({
            verified: {
                ...this.state.verified,
                [id]: !this.state.verified[id]
            }
        });
    }

    handleWishlist = (id) => {
        this.setState({
            wishlist: {
                ...this.state.wishlist,
                [id]: !this.state.wishlist[id]
            }
        });
    }

    updateSubmitting = (submitting, cb) => {
        this.setState({
            ...this.state,
            submitting: submitting,
        }, cb)
    }

    setSubmitting = (cb) => {
        this.updateSubmitting(true, cb);
    }

    setNotSubmitting = (cb) => {
        // console.log("Final: ", this.state.values);

        this.updateSubmitting(false, cb);
    }

    // vehicleViewType: this.state.myCars.length ? 2 : 1,
    // vehicleID: '',
    // vehicleType: 'Private car',
    // carplate: '',
    // octcardViewType: this.state.octcards.length ? 1 : 2,
    // octcard: '123123123',
    // octcardID: '',
    // cardNumber: '2223000000000023',
    // holderName: 'Jabie',
    // expiryDateYY: '21',
    // expiryDateMM: '05',
    // CVC: '100',
    // addCard: false,
    // agree: true,
    //
    // spaceID: this.state.spaceID,
    // type: this.state.type,
    // startDate: this.state.startDate,
    // endDate: this.state.endDate,
    // startTime: this.state.startTime,
    // endTime: this.state.endTime,
    // uid: this.state.uid,

    findVehicleId = (vehicles, carplate) => {

        if (typeof (vehicles) !== 'undefined') {

            for (var i = 0; i < vehicles.length; i++) {
                const v = vehicles[i];
                if (v.carplate === carplate)
                    return v._id;
            }
        }

        return false;
    }

    updateVehicle = (cb) => {
        const values = this.state.values;

        if (values.vehicleViewType === 2) {
            // new vehicle
            let me = this.state.me;
            let vehicleID = this.findVehicleId(me.vehicles, values.carplate);
            // console.log("me", me, me.vehicles);
            if (vehicleID) {
                values.vehicleID = vehicleID;

                this.setState({
                    ...this.state,
                    values: values
                }, cb)
            } else {
                me.vehicles.push({
                    carplate: values.carplate,
                    type: values.vehicleType,
                    default: false
                })

                const body = {
                    vehicles: me.vehicles
                }

                let callback = (res) => {
                    // console.log("updateUserInfo", res);
                    let id = this.findVehicleId(res.body.vehicles, values.carplate);
                    if (id) {
                        values.vehicleID = id;

                        this.setState({
                            ...this.state,
                            values: values
                        }, cb)
                    } else {
                        errcallback('vehicle id not found after insert');
                    }
                }

                let errcallback = (res) => {
                    // console.log("cannot create vehicle", res);
                    this.setNotSubmitting();
                }

                UserApi.updateCarplate(this.props.members.Login.userToken, body, callback, errcallback)
            }
        } else
            cb();
    }

    findOctcardId = (octcards, number) => {
        for (var i = 0; i < octcards.length; i++) {
            const c = octcards[i];
            if (c.number === number)
                return c._id;
        }
        return false;
    }

    updateOctcard = (cb) => {
        const values = this.state.values;
        if (values.octcardViewType === 2) {
            if (values.octcard !== "") {
                // new octcard
                let me = this.state.me;
                let octcardID = this.findOctcardId(me.octcard, values.octcard);
                // console.log("me", me, me.octcard);
                if (octcardID) {
                    values.octcardID = octcardID;

                    this.setState({
                        ...this.state,
                        values: values
                    }, cb)
                } else {
                    me.octcard.push({
                        number: values.octcard,
                        default: false
                    })
                    const body = {
                        octcard: me.octcard
                    }

                    let callback = (res) => {
                        // console.log("updateUserInfo", res);
                        let id = this.findOctcardId(res.body.octcard, values.octcard);
                        if (id) {
                            values.octcardID = id;

                            this.setState({
                                ...this.state,
                                values: values
                            }, cb)
                        } else {
                            errcallback('octcard id not found after insert');
                        }
                    }

                    let errcallback = (res) => {
                        // console.log("cannot create octcard", res);
                        this.setNotSubmitting();
                    }

                    UserApi.updateOctopusCard(this.props.members.Login.userToken, body, callback, errcallback)
                }
            } else {//20190715samson - octcard can be empty
                values.octcardID = "";
                cb();
            }
        } else
            cb();
    }

    handleToken = (cb) => {
        const values = this.state.values;
        const token = this.props.members.Login.userToken;

        let eCb = (res) => {
            console.log("save creditcard failed", res);
            this.setNotSubmitting();
        }

        // get / save credit card token
        saveCreditCard(
            token,
            values,
            this.props.members.Login.userId,
            false,
            cb,
            eCb
        );
    }

    handleSubmit = (values, {setFieldError}) => {
        // console.log("handleSubmit")
        // console.log(this.state.calculation);
        // return;
        //
        // window.location.href = 'https://example.com/1234';
        // return;
        //
        // return this.setState({
        //     ...this.state,
        //     otp: true
        // });

        if (!this.state.submitting) {
            // console.log("ok to submit");

            // payment callback
            const paymentIDCallback = () => {

                // update vehicle
                this.updateVehicle(() => {
                    // console.log("updateVehicle done");

                    // update oct card
                    this.updateOctcard(() => {
                        // console.log("updateOctcard done");

                        let values = this.state.values;

                        values.calculation = this.state.calculation;
                        values.total = this.state.calculation.totalcost;
                        values.bcode = this.state.bcode;
                        values.earlyInTime = this.state.earlyInTime;
                        values.gracePeriod = this.state.gracePeriod;
                        values.preBookBlocker = this.state.preBookBlocker;
                        values.postBookBlocker = this.state.postBookBlocker;
                        values.overstayFine = this.state.overstayFine;
                        values.extendPrice = this.state.extendPrice;

                        // all done, submit to book a space api
                        bookASpace(this.props.members.Login.userToken, this.state.spaceID, this.state.values, (res) => {
                            if (typeof (res.body.status) !== "undefined") {
                                if (this.state.values.endDate.diff(this.state.values.startDate, 'months', true) > 1 && this.state.values.type === 'monthly')  {
                                    const {t} = this.props;
                                    swal({
                                        text: t("payment:3ds"),
                                        buttons: {
                                            cancel: {
                                                value: null,
                                                visible: true,
                                                text: t("payment:back")
                                            },
                                            confirm: {
                                                value: true,
                                                visible: true,
                                                text: t("payment:ok")
                                            }
                                        },
                                    })
                                      .then((isConfirmed) => {
                                          if (isConfirmed && res.body.status === 1) {
                                              window.location.href = Config.API_URL + "payment/3ds/" + res.body.id;
                                          }
                                      });
                                } else {
                                    window.location.href = Config.API_URL + "payment/3ds/" + res.body.id;
                                    return;
                                }
                            }
                            console.log(res);
                            if (res.body.msg === 'Enterprise cross-check failed'){
                                const {t} = this.props;
                                alert(t("payment:crossCheckFailed"));
                            } else {
                                setFieldError("general", "payment:gateway.PAYMENT_FAILED");
                            }

                            this.setNotSubmitting();
                            return;

                            // if (typeof (res.body._id) === "undefined") {
                            //
                            //     console.log(res.body.r.body['3DSecure'].authenticationRedirect.simple.htmlBodyContent);
                            //
                            //     setFieldError("general", "Cannot create booking");
                            //     this.setNotSubmitting();
                            //     return;
                            // }
                            //
                            // let values = this.state.values;
                            // values.orderID = res.body._id;
                            //
                            // this.setState({
                            //     ...this.state,
                            //     completed: true,
                            //     isInstant: this.state.space.instant,
                            //     values: values,
                            // })
                        }, (res) => {
                            console.log(res);
                            if (res.body.msg === 'Enterprise cross-check failed'){
                                const {t} = this.props;
                                alert(t("payment:crossCheckFailed"));
                            } else {
                                setFieldError("general", "payment:gateway.PAYMENT_FAILED");
                            }
                            this.setNotSubmitting();
                        })
                    })
                })
            }

            const process = () => {
                if (values.newCard) {
                    // if add a new card, save to server
                    this.handleToken((res) => {
                        console.log("handletoken done", res);

                        if (res.body.status === 1) {
                            // ok
                            // update paymentID
                            let values = this.state.values;
                            values.paymentID = res.body.paymentID;

                            this.setState({
                                ...this.state,
                                values: values
                            }, paymentIDCallback);
                        } else {
                            setFieldError("general", "payment:errors.credit_card_info");
                            this.setNotSubmitting();
                        }
                    });
                } else {
                    // no new card, load from paymentID is enough
                    paymentIDCallback();

                    // let values = this.state.values;
                    // // console.log("no new", values);
                    // this.setState({
                    //     ...this.state,
                    //     values: values
                    // }, paymentIDCallback);
                }
            }

            // set submitting and then run process
            this.setState({
                ...this.state,
                values: values
            }, () => {
                this.setSubmitting(process);
            })
        }
    }

    scrollToTop() {
        this.pageTop.current && window.scrollTo(0, this.pageTop.offsetTop)
    };

    handlePromotionCodeSubmit = (values, {setFieldError}) => {
        // const hours = Helper.calculateHours(this.state.startDate, this.state.endDate, null, null);

        const callback = (res) => {
            let body = res.body;
            if (body.status === 1) {
                //success
                this.setState({
                    ...this.state,
                    calculation: body.calculation
                });
            } else {
                setFieldError("promotionCode", "promotion:errors." + body.msg);
            }
        }
        checkPromotionCode(this.props.members.Login.userToken, this.state.spaceID, values.promotionCode, this.state.type, this.state.startDate.format("YYYY-MM-DDTHH:mm"), this.state.endDate.format("YYYY-MM-DDTHH:mm"), this.state.bcode, callback);
    }

    promotionCodeForm = ({errors}) => {
        const {t} = this.props;
        return (
            <div className="border-box">
                <b>{t("payment:promotionCode.title")}</b>
                {!this.state.calculation.promotion &&
                <Form>
                    <div className="sep-20"/>
                    <div>
                        <Field type="text" name="promotionCode"/>
                        {errors.promotionCode ?
                            <ErrorMessage message={errors.promotionCode}/> : null}
                        <div className="sep-20"/>
                        <input type="submit" className="button" value={t("payment:promotionCode.apply")}/>
                    </div>
                </Form>
                }

                {this.state.calculation.promotion &&
                <div>
                    {this.state.calculation.promotion.name}
                </div>
                }
            </div>
        )
    }

    changeTips(data) {
        this.setState({
            ...this.state,
            [data]: !this.state[data]
        })
    }

    form = ({values, errors, touched, handleChange, handleBlur, setFieldValue}) => {
        const {t, i18n} = this.props;

        let queryString = '?';
        if (this.props.params !== null) {
            let params = this.props.params;
            Object.keys(params).forEach((itm, idx) => {
                if (idx === 0)
                    queryString += itm + '=' + params[itm];
                else
                    queryString += '&' + itm + '=' + params[itm];
            });
        }

        return (
            <div>
                <Form>
                    {/*<Field name="spaceID" type="hidden"/>*/}
                    {/*<Field name="type" type="hidden"/>*/}
                    {/*<Field name="startDate" type="hidden"/>*/}
                    {/*<Field name="endDate" type="hidden"/>*/}
                    {/*<Field name="startTime" type="hidden"/>*/}
                    {/*<Field name="endTime" type="hidden"/>*/}
                    {/*<Field name="uid" type="hidden"/>*/}

                    <div className="wrapper">
                        <h4><span>1</span>{t("payment:vehicle.title")}</h4>

                        <div className="wrap clearfix">
                            <div className="full">
                                <div>
                                    <Field name="vehicleViewType" type="hidden"/>

                                    {values.vehicleViewType === 1 &&
                                    <div>
                                        <div className="sep-20"/>

                                        <select
                                            name="vehicleID"
                                            value={values.vehicleID}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            {Object.keys(this.state.me.vehicles).map(key =>
                                                <option key={key}
                                                        value={this.state.me.vehicles[key]._id}>
                                                    {this.state.me.vehicles[key].carplate}
                                                </option>
                                            )}
                                        </select>

                                        <div className="sep-20"/>

                                        <span
                                            className="additional"
                                            onClick={() => {
                                                setFieldValue('vehicleViewType', 2)
                                            }}
                                        >
                                            {t("payment:vehicle.addVehicle")}
                                        </span>
                                    </div>
                                    }

                                    {values.vehicleViewType !== 1 &&
                                    <div>
                                        <div className="sep-20"/>

                                        <select
                                            name="vehicleType"
                                            value={values.vehicleType}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            {this.state.space.cartype.indexOf("PrivateCar") >= 0 &&
                                            <option
                                                value="PrivateCar">{t("payment:vehicle.options.privateCar")}</option>
                                            }

                                            {this.state.space.cartype.indexOf("Van") >= 0 &&
                                            <option value="Van">{t("payment:vehicle.options.van")}</option>
                                            }

                                            {this.state.space.cartype.indexOf("Motorcycle") >= 0 &&
                                            <option
                                                value="Motorcycle">{t("payment:vehicle.options.motorcycle")}</option>
                                            }

                                            {this.state.space.cartype.indexOf("Lorry") >= 0 &&
                                            <option value="Lorry">{t("payment:vehicle.options.lorry")}</option>
                                            }

                                            {this.state.space.cartype.indexOf("Others") >= 0 &&
                                            <option value="Others">{t("payment:vehicle.options.others")}</option>
                                            }
                                        </select>

                                        <div className="sep-20"/>

                                        <Field type="text" name="carplate"
                                               placeholder={t("payment:vehicle.vehicleRegistrationMark")}
                                               maxLength="8"/>
                                        {errors.carplate && touched.carplate ?
                                            <ErrorMessage message={errors.carplate}/> : null}

                                        {this.state.me.vehicles.length > 0 &&
                                        <div>
                                            <div className="sep-20"/>
                                            <button
                                                className="button grey first"
                                                onClick={() => {
                                                    setFieldValue('vehicleViewType', 1)
                                                }}
                                            >
                                                {t("payment:cancel")}
                                            </button>
                                        </div>
                                        }
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>

                        {/*TODO * add change vehicle type*/}
                        {/* <div className="wrap clearfix">
                                        <div className="full">
                                            <div>
                                                <input type="text" placeholder="Vehicle registration mark" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="wrap clearfix">
                                        <div className="full">
                                            <div>
                                                <a href="/" className="button">Cancel</a><a href="/" className="button">Add</a>
                                            </div>
                                        </div>
                                    </div> */}
                    </div>

                    <div className="wrapper">
                        <h4><span>2</span>{t(!this.state.bcode ? "payment:octopusCard" : "payment:bCode")}</h4>

                        <div className="wrap clearfix">
                            <div className="full">
                                {!this.state.bcode ? (
                                    <div>
                                        <Field name="octcardViewType" type="hidden"/>

                                        {values.octcardViewType === 1 &&
                                        <div>
                                            <div className="sep-20"/>

                                            <select
                                                name="octcardID"
                                                value={values.octcardID}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                <option key='' value=''>{t("payment:pleaseSelect")}</option>
                                                {Object.keys(this.state.me.octcard).map(key =>
                                                    <option key={key}
                                                            value={this.state.me.octcard[key]._id}>
                                                        {this.state.me.octcard[key].number}
                                                    </option>
                                                )}
                                            </select>

                                            <div className="sep-20"/>

                                            <span
                                                className="additional"
                                                onClick={() => {
                                                    setFieldValue('octcardViewType', 2)
                                                }}
                                            >
                                            {t("payment:addOctopus")}
                                        </span>
                                        </div>
                                        }

                                        {values.octcardViewType !== 1 &&
                                        <div>
                                            <div className="sep-20"/>

                                            <Field type="text" name="octcard" maxLength="9"/>
                                            {errors.octcard && touched.octcard ?
                                                <ErrorMessage message={errors.octcard}/> : null}

                                            {this.state.me.octcard.length > 0 &&
                                            <div>
                                                <div className="sep-20"/>
                                                <button
                                                    className="button grey first"
                                                    onClick={() => {
                                                        setFieldValue('octcardViewType', 1)
                                                    }}
                                                >
                                                    {t("payment:cancel")}
                                                </button>
                                            </div>
                                            }
                                        </div>
                                        }
                                    </div>
                                ) : (
                                    <div>
                                        <h5>
                                            <img src={require('../../images/mobile/03_find_parking_space/smart_pass_icon.png')}
                                                 style={{marginLeft: 30, height: 24, width: 24}}
                                                 alt=""/>
                                                 &nbsp;{t("payment:useBCode")}
                                        </h5>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/*<div className="wrap clearfix">*/}
                        {/*    <div className="full">*/}
                        {/*        <div>*/}
                        {/*            <Field type="text" name="octcard" />*/}
                        {/*            {errors.octcard && touched.octcard ? <ErrorMessage message={errors.octcard} /> : null}*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div className="checkboxFive">*/}
                        {/*    <div>*/}
                        {/*        <input type="checkbox" id="AddOctopus"*/}
                        {/*        />*/}
                        {/*        <label htmlFor="AddOctopus"></label>*/}
                        {/*    </div>*/}
                        {/*    <label htmlFor="AddOctopus">{t("Add this card into account")}</label>*/}
                        {/*</div>*/}
                    </div>

                    <div className="wrapper">
                        <div className="cardIcons">
                            <span>
                                <img src={require('../../images/mobile/03_find_parking_space/icon_visa.png')}
                                     alt=""/>
                            </span>
                            <span>
                                <img src={require('../../images/mobile/03_find_parking_space/icon_master.png')}
                                     alt=""/>
                            </span>
                        </div>

                        <h4><span>3</span>{t("payment:payment.title")}</h4>

                        {!values.newCard &&
                        <div className="card_list">
                            <div className="sep-20"/>

                            <select
                                name="paymentID"
                                value={values.paymentID}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                {Object.keys(this.state.me.payment).map(key => {
                                    let payment = this.state.me.payment[key];
                                    if (payment.display) {
                                        return (
                                            <option key={key}
                                                    value={payment._id}>
                                                {payment.number}
                                            </option>
                                        )
                                    }
                                    return null;
                                })}
                            </select>

                            <div className="sep-20"/>

                            <span
                                className="additional"
                                onClick={() => {
                                    setFieldValue('newCard', true)
                                }}
                            >
                                        {t("payment:addNewCard")}
                                    </span>
                            <div className="sep-20"/>
                        </div>
                        }

                        {values.newCard &&
                        <div>
                            <div className="wrap clearfix">
                                <div className="full">
                                    <div>
                                        <h5>{t("payment:payment.creditCard")}</h5>
                                        <Field type="text" name="cardNumber" maxLength="16"/>
                                        {errors.cardNumber && touched.cardNumber ?
                                            <ErrorMessage message={errors.cardNumber}/> : null}
                                    </div>
                                </div>
                            </div>

                            <div className="wrap clearfix">
                                <div className="full">
                                    <div>
                                        <h5>{t("payment:payment.cardHolder")}</h5>
                                        <Field type="text" name="holderName" maxLength="50"/>
                                        {errors.holderName && touched.holderName ?
                                            <ErrorMessage message={errors.holderName}/> : null}
                                    </div>
                                </div>
                            </div>

                            <div className="wrap clearfix">
                                <div className="fifty">
                                    <div>
                                        <h5>{t("payment:payment.expiryDate.title")}</h5>
                                        <Field type="text" name="expiryDateMM"
                                               placeholder={t("payment:payment.expiryDate.mm")} maxLength="2"/>
                                        {(errors.expiryDateYY && touched.expiryDateYY) || (errors.expiryDateMM && touched.expiryDateMM) ?
                                            <ErrorMessage
                                                message={(errors.expiryDateMM ? errors.expiryDateMM : '\u00A0')}/> : null}
                                    </div>
                                </div>
                                <div className="fifty">
                                    <div>
                                        <h5>&nbsp;</h5>
                                        <Field type="text" name="expiryDateYY"
                                               placeholder={t("payment:payment.expiryDate.yy")} maxLength="2"/>
                                        {(errors.expiryDateYY && touched.expiryDateYY) || (errors.expiryDateMM && touched.expiryDateMM) ?
                                            <ErrorMessage
                                                message={(errors.expiryDateYY ? errors.expiryDateYY : '\u00A0')}/> : null}
                                    </div>
                                </div>
                            </div>

                            <div className="wrap clearfix">
                                <div className="full">
                                    <div>
                                        <h5>{t("payment:payment.expiryDate.cvc")}</h5>
                                        <Field type="text" name="CVC" maxLength="3"/>
                                        {errors.CVC && touched.CVC ? <ErrorMessage message={errors.CVC}/> : null}
                                    </div>
                                </div>
                            </div>

                            {/* <div className="secure">
                                    <span><img className="norton" src={require('../../images/mobile/03_find_parking_space/icon_norton.png')} alt="" /></span>
                                    <span><img className="lock" src={require('../../images/mobile/03_find_parking_space/icon_secure.png')} alt="" /> Secure payment</span>
                                </div> */}

                            <div className="sep-10"/>

                            <div className="checkboxFive">
                                <div>
                                    <Field type="checkbox" name="addCard" id="AddCreditcard" value="1"
                                           checked={values.addCard}/>
                                    <label htmlFor="AddCreditcard"/>
                                </div>
                                <label htmlFor="AddCreditcard">
                                    {t("payment:rememberCreditCard")}
                                </label>
                            </div>

                            {this.state.me.payment.length > 0 &&
                            <div>
                                <div className="sep-20"/>
                                <button
                                    className="button grey first"
                                    onClick={() => {
                                        setFieldValue('newCard', false)
                                    }}
                                >
                                    {t("payment:cancel")}
                                </button>
                            </div>
                            }

                            <div className="sep-20"/>
                        </div>
                        }

                        {/*<div>*/}
                        {/*    {Object.keys(this.state.me.payment).map(i => {*/}
                        {/*        const payment = this.state.me.payment[i];*/}

                        {/*        return (*/}
                        {/*            <div className="checkboxFive" key={i}>*/}
                        {/*                <div>*/}
                        {/*                    <input type="checkbox" id={"AddCreditcard_" + i} value={i}*/}
                        {/*                           checked={this.state.selectedCard === i ? true : false}*/}
                        {/*                           onChange={(e) => this.changeStateLevelOne("selectedCard", e.target.value)}/>*/}
                        {/*                    <label htmlFor={"AddCreditcard_" + i}/>*/}
                        {/*                </div>*/}
                        {/*                <label htmlFor={"AddCreditcard_" + i}>*/}
                        {/*                    {payment.number}*/}
                        {/*                </label>*/}
                        {/*            </div>*/}
                        {/*        );*/}
                        {/*    })}*/}

                        {/*    {this.state.me.payment.length > 0 &&*/}
                        {/*    <div className="checkboxFive">*/}
                        {/*        <div>*/}
                        {/*            <input type="checkbox" id="newCreditCard" value="newCreditCard"*/}
                        {/*                   checked={this.state.selectedCard === "newCreditCard" ? true : false}*/}
                        {/*                   onChange={(e) => this.changeStateLevelOne("selectedCard", e.target.value)}/>*/}
                        {/*            <label htmlFor="newCreditCard"/>*/}
                        {/*        </div>*/}
                        {/*        <label htmlFor="newCreditCard">*/}
                        {/*            Use another credit card*/}
                        {/*        </label>*/}
                        {/*    </div>*/}
                        {/*    }*/}
                        {/*</div>*/}

                        <div className="sep-20"/>
                        <div className="sep-20"/>

                        <div className="checkboxFive">
                            <div>
                                <Field
                                    type="checkbox"
                                    id="Accept"
                                    name="agree"
                                    value={values.agree}
                                    checked={values.agree}
                                    onChange={handleChange}/>
                                <label htmlFor="Accept"/>
                            </div>
                            <label htmlFor="Accept">
                                <p>
                                    {t("payment:agreement1")}
                                    <Link target="_blank"
                                          to={"/" + i18n.language + "/terms"}>{t("registerStart:agreement.part2")}</Link>
                                    {t("registerStart:agreement.comma")}
                                    <Link target="_blank"
                                          to={"/" + i18n.language + "/licenseAgreement"}>{t("registerStart:agreement.part3")}</Link>
                                    {t("registerStart:agreement.comma")}
                                    <Link target="_blank"
                                          to={"/" + i18n.language + "/privacy"}>{t("registerStart:agreement.part4")}</Link>
                                    {t("registerStart:agreement.and")}
                                    <Link target="_blank"
                                          to={"/" + i18n.language + "/disclaimer"}>{t("registerStart:agreement.part5")}</Link>
                                    {t("payment:agreement4")}
                                </p>
                            </label>

                        </div>

                        {errors.agree && touched.agree ? <ErrorMessage message={errors.agree}/> : null}

                        <div className="sep-20"/>

                        <table className="verifiedGuarantee">
                            <tbody>
                            <tr>
                                <td><img
                                    src={require('../../images/mobile/01_homepage/icon_01_02.png')}
                                    alt=""/></td>
                                <td>{t("payment:landSearch")}<span>{t("payment:verified")}</span></td>
                            </tr>
                            <tr>
                                <td><img
                                    src={require('../../images/mobile/01_homepage/icon_01_03.png')}
                                    alt=""/></td>
                                <td>{t("space:enjoy")}
                                    <Link target="_blank"
                                          to={"/" + i18n.language + "/parkerProtectionGuarantee"}>{t("space:protection")}</Link>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        {errors.general ? <div>
                            <div className="sep-20"/>
                            <ErrorMessage message={errors.general}/></div> : null}

                        {typeof (this.props.params.err) !== "undefined" ? <div>
                            <div className="sep-20"/>
                            <ErrorMessage message={t("payment:gateway.PAYMENT_FAILED")}/></div> : null}

                        <div className="sep-20"/>

                        <input type="submit" className="button bookNow" value={t("payment:payNow")}/>
                        <div className="sep-15"/>
                        <Link to={"/" + i18n.language + "/space/" + this.props.space_id + queryString}
                              className="button bookNow grey">{t("payment:back")}</Link>
                    </div>
                </Form>
            </div>
        )
    }

    // Form Validation
    schema = Yup.object().shape({
        cardNumber: Yup.string()
        // .required(t("payment:errors.cardNumber"))
            .matches(/^\d{13,19}$/, "payment:errors.cardNumber"),
        // holderName: Yup.string()
        // .required(t("payment:errors.cardHolder")),
        expiryDateYY: Yup.string()
        // .required(t("payment:errors.expiryDate"))
            .matches(/^([1][9]|[2-9]\d)$/, "payment:errors.expiryDate"),
        expiryDateMM: Yup.string()
        // .required(t("payment:errors.expiryDate"))
            .matches(/^(\d|[0]\d|[1][012])$/, "payment:errors.expiryDate"),
        CVC: Yup.string()
        // .required(t("payment:errors.cvc"))
            .matches(/^\d{3}$/, "payment:errors.cvc"),
        agree: Yup.boolean()
            .oneOf([true], "payment:errors.tnc"),
        vehicleViewType: Yup.number()
            .min(1)
            .max(2),
        carplate: Yup.string()
            .when('vehicleViewType', {
                is: 2,
                then: Yup.string().required("payment:errors.carPlate")
            }),
        octcardViewType: Yup.number()
            .min(1)
            .max(2),
        octcard: Yup.string()
            .when('octcardViewType', {
                is: 2,
                then: Yup.string()
                // .required("payment:errors.octopusCard")
                    .matches(/^\d{9}$/, "payment:errors.octopusCard")
                    .nullable() //20190715samson - octcard can be empty
            }),
    })

    promotionCodeSchema = Yup.object().shape({});

    render() {
        const {
            t,
            i18n
        } = this.props;

        if (this.state.redirect)
            return <Redirect to={"/" + i18n.language + "/space/" + this.state.spaceID}/>;

        if (!this.state.logined)
            return <Redirect to={"/" + i18n.language + "/login"}/>;

        if (this.state.completed) {

            // console.log("paymentComplete", this.state);

            if (this.state.isInstant === "true" || this.state.isInstant === true)
                return <Redirect
                    to={"/" + i18n.language + "/booking-success?orderID=" + this.state.values.orderID + "&booking_type=" + this.state.type + "&value=" + this.state.Helper.numeralMoney(this.state.calculation.totalcost, false)}/>;
            else
                return <Redirect to={"/" + i18n.language + "/booking-pending?orderID=" + this.state.values.orderID + "&booking_type=" + this.state.type + "&value=" + this.state.Helper.numeralMoney(this.state.calculation.totalcost, false)}/>;
        }

        if (this.state.loading) {
            return <div className="loading_box">{t("Loading")}</div>
        }
        //
        // if (this.state.otp) {
        //     return <div component={() => {
        //         window.location.href = 'https://example.com/1234';
        //         return null;
        //     }}/>
        // }

        // console.log(Helper.numeralMoney(this.state.calculation.totalcost));

        ReactPixel.track('AddPaymentInfo', {
            value: Helper.numeralMoney(this.state.calculation.totalcost),
            currency: 'HKD',
            content_type: 'product',
            booking_type: this.state.type,
            content_id: this.state.spaceID
        });

        return (
            <div className="wrapper-container payment">

                <div className="containerMain">

                    {this.state.me && this.state.space &&
                    <div className="content clearfix">

                        <div className={"fifty left"}>
                            <div>
                                <h2>{t("payment:title")}</h2>
                            </div>
                        </div>

                        {
                            this.state.isInstant === "true" || this.state.isInstant === true
                              ? <div className={"fifty right"}>
                                  <div className="clearfix">
                                      <div className="full" style={{display: 'flex', flexDirection: 'row', float: "right"}}>
                                          <div
                                            style={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                justifyContent: "center",
                                                padding: 10,
                                                maxWidth: "55%"
                                            }}
                                          >
                                              <span>{t("payment:quotaReminder")}</span>
                                          </div>
                                          <div
                                            style={{
                                                backgroundColor: "#c0d032",
                                                alignItems: 'center',
                                                display: 'flex',
                                                justifyContent: "center",
                                                minWidth: "45%",
                                                maxHeight: 80,
                                                padding: 10
                                            }}
                                          >
                                              <h4 style={{margin: 0, color: "white", flexWrap: "wrap", padding: 0}}>{t("payment:timeLeft")}: {this.state.formattedTime}</h4>
                                          </div>
                                      </div>
                                  </div>
                              </div> : null
                        }

                        <div className="sep-0"/>

                        <div className="fifty left">
                            <div>
                                <div className="border-box clearfix">
                                    <div className="GridType5">
                                        <div className="image"
                                             style={{backgroundImage: "url(" + (typeof (this.state.space) !== "undefined" && this.state.space.images.length ? this.state.space.images[0] : require('../../images/mobile/General/dummy-img/img_media.jpg')) + ")"}}>

                                            <Verified
                                                id={this.props.space_id}
                                                verified={this.state.verified}
                                                handleVerified={this.handleVerified}
                                            />
                                            <Heart
                                                id={this.props.space_id}
                                                wishlist={this.state.wishlist}
                                                handleWishlist={this.handleWishlist}
                                            />
                                        </div>
                                        <div className="text clearfix">
                                            <h3>{this.state.space ? i18n.language ===  'zh-HK' && this.state.space.title_tc ? this.state.space.title_tc : this.state.space.title : ''}</h3>
                                            <h4>{this.state.space ? i18n.language === 'zh-HK' && this.state.space.address_tc ? this.state.space.address_tc : this.state.space.address : ''}</h4>
                                            <div className="sep-10"/>
                                            <Reviews score={0} reviews={0}/>
                                        </div>
                                    </div>

                                    <div className="sep-20"/>

                                    <hr/>

                                    <div className="sep-20"/>

                                    {this.props.params.booking_type === "hourly" ?
                                        (
                                            <div className="payment_total">
                                                <dl className="clearfix">
                                                    <dt>{this.props.params.from} {this.props.params.from_time} - {this.props.params.to} {this.props.params.to_time}</dt>
                                                    <dd>{Helper.numeralMoney(this.state.calculation.cost)}</dd>
                                                </dl>

                                                {typeof (this.state.calculation.promotion) !== "undefined" &&
                                                <dl className="clearfix">
                                                    <dt>{t("space:price.discount")}</dt>
                                                    <dd>- {Helper.numeralMoney(this.state.calculation.discount)}</dd>
                                                </dl>
                                                }

                                                {this.state.calculation.deposit > 0 &&
                                                <dl className="clearfix">
                                                    <dt>
                                                        {t("space:price.deposit")}
                                                        <button className="tips"
                                                                onClick={() => this.changeTips('DepositTips')}
                                                        />
                                                        {this.state.DepositTips &&
                                                        <div
                                                            className="tipsText">{t("payment:popup.deposit")}</div>
                                                        }
                                                    </dt>
                                                    <dd>{Helper.numeralMoney(this.state.calculation.deposit)}</dd>
                                                </dl>
                                                }

                                                {this.state.calculation.rentaldeposit > 0 &&
                                                <dl className="clearfix">
                                                    <dt>{t("space:price.rental_deposit")}</dt>
                                                    <dd>{Helper.numeralMoney(this.state.calculation.rentaldeposit)}</dd>
                                                </dl>
                                                }

                                                <dl className="clearfix">
                                                    <dt><b>{t("space:price.total")}</b>
                                                        <button className="tips"
                                                                onClick={() => this.changeTips('TotalTips')}
                                                        />
                                                        {this.state.TotalTips &&
                                                        <div
                                                            className="tipsText">{t("payment:popup.deposit")}</div>
                                                        }
                                                    </dt>
                                                    <dd>
                                                        <b>{Helper.numeralMoney(this.state.calculation.totalcost)}</b>
                                                    </dd>
                                                </dl>
                                            </div>
                                        ) : (
                                            <div className="payment_total">
                                                <dl className="clearfix">
                                                    <dt>{t("space:price.subTotal")}</dt>
                                                    <dd>{Helper.numeralMoney(this.state.calculation.org_subtotal)}</dd>

                                                    {typeof (this.state.calculation.promotion) !== "undefined" &&
                                                    <dl className="clearfix">
                                                        <dt>{t("space:price.discount")}</dt>
                                                        <dd>- {Helper.numeralMoney(this.state.calculation.discount_total)}</dd>
                                                    </dl>
                                                    }

                                                    <dt>
                                                        {t("space:price.deposit")}
                                                        <button className="tips"
                                                                onClick={() => this.changeTips('DepositTips')}
                                                        />
                                                        {this.state.DepositTips &&
                                                        <div
                                                            className="tipsText">{t("payment:popup.deposit")}</div>
                                                        }
                                                    </dt>
                                                    <dd>{Helper.numeralMoney(this.state.calculation.deposit)}</dd>

                                                    <dt>{t("space:price.rental_deposit")}</dt>
                                                    <dd>{Helper.numeralMoney(this.state.calculation.rentaldeposit)}</dd>

                                                    {/*<dt>HKD 20 x {props.calculation.hours} hour(s)</dt>*/}
                                                    {/*<dd>HKD {Helper.number_format(props.calculation.amount)}</dd>*/}
                                                    {/*<div className="sep-0" />*/}
                                                    <dt>
                                                        <b>{t("space:price.total")}</b>
                                                        <button className="tips"
                                                                onClick={() => this.changeTips('TotalTips')}
                                                        />
                                                        {this.state.TotalTips &&
                                                        <div
                                                            className="tipsText">{t("payment:popup.deposit")}</div>
                                                        }
                                                    </dt>
                                                    <dd>
                                                        <b>{Helper.numeralMoney(this.state.calculation.totalcost)}</b>
                                                    </dd>
                                                </dl>

                                                {this.state.calculation.months > 1 && this.state.calculation.totalcost !== (this.state.calculation.cost.first + this.state.calculation.deposit + this.state.calculation.rentaldeposit) &&
                                                <div>
                                                    <div className="sep-20"/>

                                                    <hr/>

                                                    <div className="sep-20"/>

                                                    <dl className="clearfix">
                                                        <dt>{t("space:price.firstMonth")}</dt>
                                                        <dd>{Helper.numeralMoney(this.state.calculation.org_first_month)}</dd>

                                                        {typeof (this.state.calculation.promotion) !== "undefined" &&
                                                        <dl className="clearfix">
                                                            <dt>{t("space:price.discount")}</dt>
                                                            <dd>- {Helper.numeralMoney(this.state.calculation.discount)}</dd>
                                                        </dl>
                                                        }

                                                        <dt>{t("space:price.deposit")}</dt>
                                                        <dd>{Helper.numeralMoney(this.state.calculation.deposit)}</dd>

                                                        <dt>
                                                            {t("space:price.rental_deposit")}
                                                        </dt>
                                                        <dd>{Helper.numeralMoney(this.state.calculation.rentaldeposit)}</dd>

                                                        <dt><b>{t("space:price.firstPayment")}</b></dt>
                                                        <dd>
                                                            <b>{Helper.numeralMoney((this.state.calculation.cost.first + this.state.calculation.deposit + this.state.calculation.rentaldeposit))}</b>
                                                        </dd>
                                                    </dl>

                                                    <div className="sep-20"/>

                                                    <hr/>

                                                    <div className="sep-20"/>

                                                    <dl className="clearfix">
                                                        <dt><b>{t("space:price.monthlyPayment")}</b>
                                                            <button className="tips"
                                                                    onClick={() => this.changeTips('InstantBookingTips')}
                                                            />
                                                            {this.state.InstantBookingTips &&
                                                            <div
                                                                className="tipsText">{t("payment:popup.monthlyPayment")}</div>
                                                            }
                                                        </dt>
                                                        <dd>
                                                            <b>{Helper.numeralMoney(this.state.calculation.recurring_cost)}</b>
                                                        </dd>
                                                    </dl>
                                                </div>
                                                }
                                            </div>
                                        )
                                    }


                                    {/*<div className="sep-0" />*/}
                                    {/*<dt>Deposit</dt>*/}
                                    {/*<dd>HKD 1,900</dd>*/}
                                    {/*<div className="sep-0" />*/}
                                    {/*<dt><b>Total Amount</b></dt>*/}
                                    {/*<dd><b>HKD 3,800</b></dd>*/}
                                </div>

                                <div className="sep-20"/>

                                <Formik
                                    initialValues={{
                                        promotionCode: ''
                                    }}
                                    validationSchema={this.promotionCodeSchema}
                                    onSubmit={this.handlePromotionCodeSubmit}
                                    component={this.promotionCodeForm}
                                />
                            </div>
                        </div>

                        <div className="fifty left">
                            <Formik
                                initialValues={{
                                    vehicleViewType: this.state.me.vehicles.length ? 1 : 2,
                                    vehicleID: this.state.defaultVehicleID,
                                    vehicleType: 'privatecar',
                                    carplate: '',
                                    octcardViewType: this.state.me.octcard.length ? 1 : 2,
                                    octcard: '',
                                    octcardID: this.state.defaultOctopusID,

                                    newCard: this.state.newCard,
                                    paymentID: this.state.defaultCreditCardID,
                                    cardNumber: '',
                                    holderName: '', //'Jabie',
                                    expiryDateYY: '',
                                    expiryDateMM: '',
                                    CVC: '',
                                    addCard: false,
                                    agree: true,

                                    spaceID: this.state.spaceID,
                                    type: this.state.type,
                                    startDate: this.state.startDate,
                                    endDate: this.state.endDate,
                                    startTime: (this.state.startTime ? this.state.startTime : ""),
                                    endTime: (this.state.endTime ? this.state.endTime : ""),
                                    uid: this.state.uid,
                                }}
                                validationSchema={this.schema}
                                onSubmit={this.handleSubmit}
                                component={this.form}
                            />
                        </div>

                        <div className="sep-30"/>
                    </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => (
    {
        members: state.members,
        listParkingSpace: state.listParkingSpace
    }
);

export default withTranslation()(connect(mapStateToProps)(Payment));
