import React from 'react';
// import { Link } from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import moment from 'moment';
import DatePicker from "react-datepicker";

import DaysOfWeek from '../../components/103Parts/DaysOfWeek';
import TimePickerRange from '../../components/04ListASpace/TimePickerRange';
import * as Config from "../../config";

// import { connect } from 'react-redux';
// import { bindActionCreators } from "redux";
// import * as ListParkingSpaceCreators from "../../actions/listParkingSpace";

function TabTimeshare(props) {
    const {
        t,
        // i18n
    } = props;

    let onDatepickerRef = (el) => {
        if (el && el.input) {
            el.input.readOnly = true;
        }
    }

    let genShowAddMoreDayTimeBtn = () => {

        const {
            t,
            // i18n
        } = props;

        const availableDayTimes = props.TimeshareTab.Availability.AvailableDayTime,
            selectedDayOfWeek = availableDayTimes[0].SelectedDayOfWeek;
        if (availableDayTimes.length < 2 && (selectedDayOfWeek === "SatSunPH" || selectedDayOfWeek === "MonFri")) {
            return (
                <div className="form-elements clearfix">
                    <div className="full align-center">
                        <div>
                            <div onClick={() => props.TabAddDayTime('TimeshareTab')}
                                 className="button additional"> {t("listASpace:stepTwo.availability.specificPeriod.additionalAvailability")}
                            </div>
                            <div className="sep-10"/>
                        </div>
                    </div>
                </div>
            )
        } else if (availableDayTimes.length === 2) {
            return (
                <div className="form-elements clearfix">
                    <div className="full align-center">
                        <div>
                            <div onClick={() => props.TabDeleteDayTime('TimeshareTab')}
                                 className="button delete"> {t("listASpace:stepTwo.availability.specificPeriod.deleteAdditionalAvailability")}</div>
                            <div className="sep-10"/>
                        </div>
                    </div>
                </div>
            );
        } else return null;
    }
    //
    // let genSeletecDaysOfWeek = () => {
    //     if (props.TimeshareTab.Availability.AlwaysAvailable === 'Yes')
    //         return <h3>Mon to Sun & PH</h3>
    //
    //     if (props.TimeshareTab.Availability.AvailableDayTime[0].SelectedDayOfWeek !== 'Custom') {
    //         let selectedDayOfWeek;
    //         switch (props.TimeshareTab.Availability.AvailableDayTime[0].SelectedDayOfWeek) {
    //             case 'SatSunPH':
    //                 selectedDayOfWeek = 'Sat, Sun & PH';
    //                 break;
    //             case 'MonSunPH':
    //                 selectedDayOfWeek = 'Mon to Sun & PH';
    //                 break;
    //             case 'Custom':
    //                 selectedDayOfWeek = 'Customize';
    //                 break;
    //             default:
    //                 selectedDayOfWeek = 'Mon to Fri';
    //                 break;
    //         }
    //         return <h3>{selectedDayOfWeek}</h3>
    //     }
    //
    //     // Custom
    //     return (
    //         <DaysOfWeek
    //             active={props.TimeshareTab.Availability.AvailableDayTime[0].CustomDates}
    //             handleDaysOfWeek={() => {
    //             }}
    //         />
    //     )
    // }

    let filterEndDate = (date) => {
        let endof = date.clone().endOf("month");
        if (!endof.isSame(date, 'day'))
            return false;

        return true;
    }

    let dayClassName = (date) => {
        return props.isPublicHoliday(date) ? 'ph' : undefined;
    }


    let tabName = 'TimeshareTab',
        tabData = props[tabName];

    let availableDayTime = (
        <div>
            {
                tabData.Availability.AvailableDayTime.map((daytime, index) =>
                    <div key={index}>
                        <div className="form-elements clearfix">
                            <div className="fifty">
                                <div>
                                    <select
                                        onChange={(e) => props.TabChangeAvailableDay('TimeshareTab', e.target.value, index)}
                                        value={daytime.SelectedDayOfWeek}
                                    >
                                        {(index === 0 || (index === 1 && tabData.Availability.AvailableDayTime[0].SelectedDayOfWeek === "SatSunPH")) &&
                                        <option
                                            value="MonFri">{t("listASpace:stepTwo.pricing.price.dayOfWeek4")}</option>
                                        }
                                        {(index === 0 || (index === 1 && tabData.Availability.AvailableDayTime[0].SelectedDayOfWeek === "MonFri")) &&
                                        <option
                                            value="SatSunPH">{t("listASpace:stepTwo.pricing.price.dayOfWeek1")}</option>
                                        }
                                        {index === 0 &&
                                        <option
                                            value="MonSunPH">{t("listASpace:stepTwo.pricing.price.dayOfWeek2")}</option>
                                        }

                                        {index === 0 &&
                                        <option
                                            value="Custom">{t("listASpace:stepTwo.pricing.price.dayOfWeek3")}</option>
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="fifty">
                                <div>
                                </div>
                            </div>
                        </div>

                        {daytime.SelectedDayOfWeek === 'Custom' &&
                        <div className="form-elements clearfix">
                            <div className="full">
                                <div className="narrow">
                                    <DaysOfWeek
                                        active={daytime.CustomDates}
                                        handleDaysOfWeek={(v) => {
                                            props.TabHandleCustomDayTime('TimeshareTab', v)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        }

                        <div className="form-elements clearfix" key={index}>
                            <div className="fifty">
                                <div>
                                    <select
                                        onChange={(e) => props.TabChangeAvailableTime('TimeshareTab', 'TimeStart', e.target.value, index)}
                                        value={daytime.SelectedTimeRange.TimeStart}
                                    >
                                        {/*<TimePickerRange maxTime={daytime.SelectedTimeRange.TimeEnd}/>*/}
                                        <TimePickerRange/>
                                    </select>
                                </div>
                            </div>
                            <div className="fifty">
                                <div>
                                    <select
                                        onChange={(e) => props.TabChangeAvailableTime('TimeshareTab', 'TimeEnd', e.target.value, index)}
                                        value={daytime.SelectedTimeRange.TimeEnd}
                                    >
                                        {/*<TimePickerRange minTime={daytime.SelectedTimeRange.TimeStart}/>*/}
                                        <TimePickerRange/>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )

    let blockdates = (
        <div>
            {
                tabData.Availability.BlockedDateRange.map((blockDate, index) =>
                    <div className="form-elements clearfix" key={index}>
                        <div className="fifty">
                            <div>
                                <DatePicker
                                    dateFormat="YYYY-MM-DD"
                                    selected={blockDate.DateStart === "" ? null : moment(blockDate.DateStart)}
                                    onChange={(e) => props.TabChangeBlockDate(tabName, 'DateStart', e, index)}
                                    minDate={moment()}
                                    // maxDate={blockDate.DateEnd ? moment(blockDate.DateEnd) : null}
                                    placeholderText={t("listASpace:stepTwo.availability.blockPeriod.from")}
                                    ref={el => onDatepickerRef(el)}
                                    dayClassName={dayClassName}
                                />
                            </div>
                        </div>
                        <div className="fifty">
                            <div>
                                <div className="shorter">
                                    <DatePicker
                                        dateFormat="YYYY-MM-DD"
                                        selected={blockDate.DateEnd === "" ? null : moment(blockDate.DateEnd)}
                                        onChange={(e) => props.TabChangeBlockDate(tabName, 'DateEnd', e, index)}
                                        minDate={blockDate.DateStart ? moment(blockDate.DateStart) : moment()}
                                        // maxDate={moment().add(5, "days")}
                                        placeholderText={t("listASpace:stepTwo.availability.blockPeriod.until")}
                                        ref={el => onDatepickerRef(el)}
                                        dayClassName={dayClassName}
                                    />
                                </div>
                                <div className="shorterFriend">
                                    <div>
                                        <span
                                            className="button remove"
                                            onClick={() => props.TabRemoveUnavailability(tabName, index)}
                                        ></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sep-10"/>
                    </div>
                )
            }
        </div>
    )

    return (
        <div>
            <div className="title">
                {t("listASpace:stepTwo.availability.title")}
            </div>

            <div className="content">
                <div className="form-elements clearfix">
                    <div className="fifty">
                        <div>
                            <DatePicker
                                dateFormat="YYYY-MM-DD"
                                selected={tabData.Availability.SelectedDateRange.DateStart === "" ? null : moment(tabData.Availability.SelectedDateRange.DateStart)}
                                onChange={(e) => props.TimeshareTabHandleChange('SelectedDateRange', 'DateStart', e)}
                                minDate={moment()}
                                // maxDate={tabData.Availability.SelectedDateRange.DateEnd ? moment(tabData.Availability.SelectedDateRange.DateEnd) : null}
                                placeholderText={t("listASpace:stepTwo.availability.blockPeriod.from")}
                                ref={el => onDatepickerRef(el)}
                                dayClassName={dayClassName}
                            />
                        </div>
                    </div>
                    <div className="fifty">
                        <div>
                            <DatePicker
                                dateFormat="YYYY-MM-DD"
                                selected={tabData.Availability.SelectedDateRange.DateEnd === "" ? null : moment(tabData.Availability.SelectedDateRange.DateEnd)}
                                onChange={(e) => props.TimeshareTabHandleChange('SelectedDateRange', 'DateEnd', e)}
                                minDate={tabData.Availability.SelectedDateRange.DateStart ? moment(tabData.Availability.SelectedDateRange.DateStart) : moment()}
                                // maxDate={moment().add(5, "days")}
                                placeholderText={t("listASpace:stepTwo.availability.blockPeriod.until")}
                                ref={el => onDatepickerRef(el)}
                                filterDate={filterEndDate}
                                dayClassName={dayClassName}
                            />
                        </div>
                    </div>
                </div>


                <div>
                    <div className="sep-10"/>

                    <h3>{t("listASpace:stepTwo.availability.specificPeriod.title")}</h3>

                    {availableDayTime}

                    <div className="sep-10"/>

                    {genShowAddMoreDayTimeBtn()}

                </div>

                <div className="sep-10"/>

                <h3>{t("listASpace:stepTwo.availability.blockPeriod.title")}</h3>

                {blockdates}

                <div className="sep-10"/>


                <div className="form-elements clearfix">
                    <div className="full align-center">
                        <div>
                            <div onClick={() => props.TabAddUnavailability(tabName)}
                                 className="button additional"> {t("listASpace:stepTwo.availability.blockPeriod.additionalUnavailability")}
                            </div>
                            <div className="sep-10"/>
                        </div>
                    </div>
                </div>

            </div>
            {/* form-elements */}

            <div className="title">
                {t("listASpace:stepTwo.pricing.title")}
            </div>

            <div className="content">
                <div className="form-elements clearfix">
                    <div className="fifty">
                        <div>
                            <div className="specialInput clearfix">
                                <div className="dollar">HK$</div>
                                <input type="text"
                                       onChange={(e) => props.TimeshareTabChangeStateLevelOne('price', e.target.value)}
                                       value={tabData.Pricing.price}
                                />
                                <div className="per-month">{t("listASpace:stepTwo.pricing.price.perMonth")}</div>
                            </div>
                        </div>
                    </div>
                    <div className="fifty">
                        <div>
                            {tabData.Pricing.price > 0 &&
                            <p className="color-green">{t("listASpace:stepTwo.pricing.price.receive")}
                                HK${(tabData.Pricing.price * (1 - Config.COMMISION_PERCENTAGE)).toFixed(1)} {t("listASpace:stepTwo.pricing.price.perMonth")}</p>
                            }
                        </div>
                    </div>
                </div>

                <div className="sep-10"/>

                <h3>{t("listASpace:stepTwo.pricing.booking.title")}</h3>
                <div className="form-elements clearfix">
                    <div className="fifty">
                        <div>
                            <select
                                onChange={(e) => props.TimeshareTabChangeStateLevelOne('minBooking', e.target.value)}
                                value={tabData.Pricing.minBooking}
                            >
                                <option value="1">1 {t("listASpace:stepTwo.pricing.booking.month")}</option>
                                <option value="3">3 {t("listASpace:stepTwo.pricing.booking.month")}</option>
                                <option value="6">6 {t("listASpace:stepTwo.pricing.booking.month")}</option>
                                <option value="9">9 {t("listASpace:stepTwo.pricing.booking.month")}</option>
                                <option value="12">12 {t("listASpace:stepTwo.pricing.booking.month")}</option>
                                <option value="24">24 {t("listASpace:stepTwo.pricing.booking.month")}</option>
                            </select>
                        </div>
                    </div>
                    <div className="fifty">
                        <div>

                        </div>
                    </div>
                </div>

                <div className="sep-10"/>

                {props.AccessType !== "No" &&
                <div>
                    <h3>{t("listASpace:stepTwo.pricing.accessCard.title")}</h3>
                    <div className="form-elements clearfix">
                        <div className="fifty">
                            <div>
                                <select
                                    onChange={(e) => props.TimeshareTabChangeStateLevelOne('deposit', e.target.value)}
                                    value={tabData.Pricing.deposit}
                                >
                                    <option value="0">HK$0</option>
                                    <option value="100">HK$100</option>
                                    <option value="200">HK$200</option>
                                    <option value="300">HK$300</option>
                                    <option value="400">HK$400</option>
                                    <option value="500">HK$500</option>
                                </select>
                            </div>
                        </div>
                        <div className="fifty">
                            <div></div>
                        </div>
                    </div>

                    <div className="sep-10"/>
                </div>
                }

                <h3>{t("payment:promotionCode.title")}</h3>
                <div className="form-elements clearfix">
                    <div className="fifty">
                        <div>
                            <input type="text" name="promotionCode"
                                   onChange={(e) => props.changeStateLevelTwo('TimeshareTab', 'Promotion', e.target.value)}/>
                        </div>
                    </div>
                    <div className="fifty">
                        <div></div>
                    </div>
                </div>

                <div className="sep-10"/>
            </div>
        </div>
    )
}

export default withTranslation()(TabTimeshare);
