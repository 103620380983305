import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
// import { Redirect } from 'react-router';
import {Link} from 'react-router-dom';

import DatePicker from "react-datepicker";
import moment from "moment";

import * as Helper from "../../utils/03FindParkingSpace/Calculations";
import {filter} from "lodash";
// import {isSamePrice, lowestPrice} from "../../utils/03FindParkingSpace/LowestHourlyPrice";

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as FindParkingSpaceActionCreators from '../../actions/findParkingSpace';

class Price extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            popup_instant_open: false,
            popup_bcode_open: false,
        };

        this.handleBtnClick = this.handleBtnClick.bind(this);
    }

    handleBtnClick = () => {
        const open = !this.state.open;
        this.setState({
            ...this.state,
            open: open
        })
    }

    handlePopupBtnClick=(data, o_data) =>
    {
        this.setState({
            ...this.state,
            [o_data]: false,
            [data]: !this.state[data]
        })
    }

    render() {
        let price = this.props.price,
            t = this.props.t;

        return (
            <div className="price">

                {/*{this.props.hourlyData.instant &&*/}
                {/*<div className="instantBooking"></div>*/}
                {/*}*/}

                <div className="unavailable-days">
                    HK$ <span>{price}</span>{t("space:perMonth")}
                    <span className={this.props.instant ? "instant" : ""} style={{marginBottom: -6}} onClick={() => {
                        this.handlePopupBtnClick('popup_instant_open', 'open')
                    }}></span>
                    <span className={this.props.bcode ? "bcode" : ""} style={{marginTop: -12, right: -7}} onClick={() => {
                        this.handlePopupBtnClick('popup_bcode_open', 'open')
                    }}></span>
                    <span className="check" style={{marginTop: -15}} onClick={this.handleBtnClick}>&nbsp;</span>

                    {this.state.open &&
                    <div className="popup">
                        <dl>
                            <div>
                                {this.props.available.map((time, index) => {
                                    return (
                                        <div key={index}>
                                            <div className="forty left">{t("Weeks." + time.period)}</div>
                                            <div
                                                className="sixty right text_align_right">{time.stime}-{time.etime}</div>
                                            <div className="sep-0"/>
                                        </div>
                                    )
                                })}
                            </div>
                        </dl>
                    </div>
                    }

                    {this.props.instant && this.state.popup_instant_open &&
                    <div className="popup">
                        <dl>
                            <div>{t("listASpace:stepTwo.instantBooking.popupContent")}</div>
                        </dl>
                    </div>
                    }
                    {this.props.bcode && this.state.popup_bcode_open &&
                    <div className="popup">
                        <dl>
                            <div>{t("listASpace:stepTwo.bcode.popupContent")}</div>
                        </dl>
                    </div>
                    }
                </div>
            </div>
        );
    }
}

function Payment(props) {
    const {t} = props;
    return (
        <div>
            <Link to="/" className={props.className + " button bookNow"} onClick={(e) => {
                e.preventDefault();
                props.insertTempBooking(e);
            }}>{t("space:letGo")}</Link>

            <div className="sep-15"/>

            {/*<p className="notChargedYet">{t("space:chargedYet")}</p>*/}
        </div>
    )
}


function Availability(props) {
    let {
        calculation,
        t,
    } = props;

    // console.log('cal', calculation);
    if (calculation && typeof (calculation.success) === "boolean") {
        if (calculation.success) {
            return (
                <dl className="clearfix">
                    <dt><b>{t("space:price.subTotal")}</b></dt>
                    <dd><b>{Helper.numeralMoney(calculation.subtotal)}</b></dd>

                    {/*<dt>{t("space:price.deposit")}</dt>*/}
                    {/*<dd>HKD {Helper.number_format(calculation.deposit, 0)}</dd>*/}

                    {/*<dt>{t("space:price.rental_deposit")}</dt>*/}
                    {/*<dd>HKD {Helper.number_format(calculation.rentaldeposit, 0)}</dd>*/}

                    {/*<dt><b>{t("space:price.total")}</b></dt>*/}
                    {/*<dd><b>HKD {Helper.number_format(calculation.totalcost, 0)}</b></dd>*/}
                </dl>
            )
        } else {
            return <div>{t("space:errors.not_available")}</div>
        }
    } else
        return null
}

function SwitchTypeIcon(props) {

    switch (props.type) {
        case 'hourly':
            return (
                <img src={require('../../images/mobile/03_find_parking_space/icon_type_3_Hourly_w.png')} alt=""/>
            );
        case 'monthly':
            return (
                <img src={require('../../images/mobile/03_find_parking_space/icon_type_1_monthly_w.png')} alt=""/>
            );
        case 'timeshare':
            return (
                <img src={require('../../images/mobile/03_find_parking_space/icon_type_2_TimeShare_w.png')} alt=""/>
            );
        default:
            return null;
        // default:
        //     return (
        //         <img src={require('../../images/mobile/03_find_parking_space/icon_type_3_Hourly_w.png')} alt=""/>
        //     );
    }
}

function DetailTimeshare(props) {
    let onDatepickerRef = (el) => {
        if (el && el.input) {
            el.input.readOnly = true;
        }
    }

    let {
        t,
        i18n
    } = props;

    moment.locale('en');

    if (i18n.language === 'zh-HK') {
        moment.locale('zh-hk');
    }

    //
    // let availability = null;
    // if (typeof (props.calculation.success) === "boolean") {
    //     if (props.calculation.success)
    //         availability = <Total calculation={props.calculation} t={t}/>
    //     else
    //         availability = <div>Sorry, time not available.</div>
    // }

    // setup calendar props
    let startDateProps = {},
        endDateProps = {},
        from = moment(props.timeshareData.from + "T00:00:00+08:00"),
        to = moment(props.timeshareData.to + "T00:00:00+08:00");

    // const blockedDates = props.monthlyData.blocked;
    const booked = props.booked;

    let minBookingtime = props.timeshareData.minbooking && parseInt(props.timeshareData.minbooking) > 0 ? parseInt(props.timeshareData.minbooking) : 0;
    let minBooking = [
        minBookingtime,
        'month',
    ];

    if (props.startDate && parseInt(props.startDate.date()) === 1 && minBooking[0] > 0)
        minBooking[0] = minBooking[0] - 1;

    let dayClassName = (date) => {
        return props.isPublicHoliday(date) ? 'ph' : undefined;
    }

    // console.log(minBooking);


    const t4 = moment().add(1, 'days');
    if (from.isBefore(t4)) {
        startDateProps.minDate = t4;
        endDateProps.minDate = t4.clone().add(minBooking[0], minBooking[1]);
    } else {
        startDateProps.minDate = from.clone();
        endDateProps.minDate = from.clone().add(minBooking[0], minBooking[1]);
    }

    if (to.isBefore(moment())) {
        startDateProps.maxDate = moment();
        endDateProps.maxDate = moment();
    } else {
        startDateProps.maxDate = to.clone();
        endDateProps.maxDate = to.clone();
    }

    if (props.startDate)
        endDateProps.minDate = props.startDate.clone().add(minBooking[0], minBooking[1]);

    // if (props.endDate)
    //     startDateProps.maxDate = props.endDate.clone().subtract(minBooking[0], minBooking[1]);

    //
    // console.log(endDateProps.minDate.format("YYYY-MM-DD"), endDateProps.maxDate.format("YYYY-MM-DD"));


    // filter unvailable dates
    let filterStartDate = (thedate) => {
        // if (blockedDates) {
        //     for (const d of blockedDates) {
        //         // console.log(d.from, d.to);
        //         if (date.isBetween(moment(d.from + "T00:00:00+08:00"), moment(d.to + "T00:00:00+08:00"), 'day', '[]')) {
        //             return false;
        //         }
        //     }
        // }

        let date = thedate.clone();
        const theday = date.date();
        let checkend,
            addMin = minBookingtime;

        if (theday === 1) {
            // if first day then min is end of month
            addMin--;
        }
        checkend = date.clone().add(addMin, "month").endOf("month");

        // Check if the date's min booking end day is out of the available range
        if (checkend.isAfter(to, 'day')) {
            // console.log(date.format(), checkend.format(), to.format());
            return false;
        }

        // check if the range to min booking is booked
        if (booked) {
            let bookedCount = filter(booked, (d) => {
                let momentFrom = moment(d.from),
                    momentTo = moment(d.to);

                if (date.isBetween(momentFrom, momentTo, 'day', '[]') ||
                    checkend.isBetween(momentFrom, momentTo, 'day', '[]') ||
                    momentFrom.isBetween(date, checkend, 'day', '[]') ||
                    momentTo.isBetween(date, checkend, 'day', '[]')
                ) {
                    return true;
                } else return false;
            }).length;
            let quota = props.quota || 1;
            console.log(date.format('YYYY-MM-DD'), checkend.format('YYYY-MM-DD'), 'start', bookedCount, quota);
            if (bookedCount >= quota) return false;
        }
        return true;
    }

    let minEndDate = null;
    if (props.startDate) {
        if (props.startDate.date() === 1) {
            minEndDate = props.startDate.clone().add(minBookingtime - 1, 'month').endOf("month").utcOffset(0);
        } else {
            minEndDate = props.startDate.clone().add(minBookingtime, "month").endOf("month").utcOffset(0);
        }

        // console.log('minEndDate', minEndDate.format());
    }

    // if (minEndDate)
    //     console.log(minEndDate.format("YYYY-MM-DD"));

    let filterEndDate = (thedate) => {
        let date = thedate.clone();
        let endof = date.clone().endOf("month");

        // console.log(date.format(), date.date(), endof.format());

        if (!endof.isSame(date, 'day'))
            return false;

        // console.log("checking 2", date.format(), minEndDate.format());

        if (minEndDate && !date.isSameOrAfter(minEndDate, "day"))
            return false;

        // console.log("checking 2", date.format(), minEndDate.format());

        if (props.startDate && props.startDate.isBefore(date) && booked) {
            let checkDate = props.startDate.clone();
            // console.log(checkDate.format());
            let bookedCount = filter(booked, (d) => {
                return checkDate.isBetween(moment(d.from), moment(d.to), 'day', '[]');
            }).length;
            let quota = props.quota || 1;
            console.log(thedate.format('YYYY-MM-DD'), 'end', bookedCount, quota);
            if (bookedCount >= quota) return false;
        }
        // console.log("OK", date.format());
        return true;
    }

    // console.log("detailTimeShare", props);

    // if (props.startDate)
    //     console.log(moment(props.startDate).endOf('month').format("YYYY-MM-DD"));

    return (
        <div className="book left">
            <div>
                <div className="one left">
                    <div className="typeIcon">
                        <SwitchTypeIcon
                            type={props.BookingType}
                        >
                        </SwitchTypeIcon>
                    </div>
                </div>

                <select className="ninety left" value={props.BookingType}
                        onChange={(e) => props.handleBookingType(e.target.value)}>
                    {
                        props.supportTypes.map((value, index) => {
                                switch (value) {
                                    case 'hourly':
                                        return <option value={value} key={index}>{t("space:tabOptions.hourly")}</option>
                                    case 'monthly':
                                        return <option value={value} key={index}>{t("space:tabOptions.monthly")}</option>
                                    case 'timeshare':
                                        return <option value={value} key={index}>{t("space:tabOptions.timeShare")}</option>
                                    default:
                                        return null;
                                    // default:
                                    //     return <option value={value} key={index}>{t("space:tabOptions.hourly")}</option>
                                }
                            }
                        )
                    }
                </select>
            </div>

            <div className="sep-20"/>

            {/*<div className="price">*/}
            {/*    HKD <span>{props.typeDetail.price}</span>{t("space:perMonth")}*/}
            {/*</div>*/}

            <Price
                price={props.timeshareData.price}
                available={props.timeshareData.available}
                t={t}
                instant={props.instant}
                bcode={props.bcode}
            />

            <div className="sep-20"/>
            {false &&
            <div>
                <div className="radioFive">
                    <div>
                        <input type="radio" id="non-reserve3" name="reserveTimeshare" value="3600"
                               checked={!props.reserved}
                               onChange={(e) => props.handleReserved('timeshare', false)}
                        />
                        <label htmlFor="non-reserve3"></label>
                    </div>
                    <label htmlFor="non-reserve3">Non-reserved : HK$ <span>3,600</span>{t("space:perMonth")}</label>
                </div>

                <div className="sep-10"/>

                <div className="radioFive">
                    <div>
                        <input type="radio" id="reserve4" name="reserveTimeshare" value="4000"
                               checked={props.reserved}
                               onChange={(e) => props.handleReserved('timeshare', true)}
                        />
                        <label htmlFor="reserve4"></label>
                    </div>
                    <label htmlFor="reserve4">Reserved : HK$ <span>4,000</span>{t("space:perMonth")}</label>
                </div>

                {/* <input type="radio" />  */}
                {/* <div className="sep-10" /> */}
                {/* <input type="radio" /> Reserved : HKD <span>2,300</span> per month */}

                <div className="sep-20"/>
            </div>
            }

            <DatePicker
                filterDate={filterStartDate}
                dateFormat="YYYY-MM-DD"
                selected={props.startDate === "" ? null : props.startDate}
                onChange={(e) => props.updateState('toBookTimeshare', 'startDate', e)}
                minDate={moment()}
                maxDate={!props.endDate || props.endDate === "" ? null : moment(props.endDate).subtract(1, 'month')}
                placeholderText={t("space:startDate")}
                ref={el => onDatepickerRef(el)}
                dayClassName={dayClassName}
                {...startDateProps}
            />

            <div className="sep-15"/>

            <DatePicker
                filterDate={filterEndDate}
                dateFormat="YYYY-MM-DD"
                selected={props.endDate === "" ? null : props.endDate}
                onChange={(e) => props.updateState('toBookTimeshare', 'endDate', e)}
                minDate={!props.startDate || props.startDate === "" ? moment().endOf('month') : moment(props.startDate).endOf('month')}
                placeholderText={t("space:endDate")}
                ref={el => onDatepickerRef(el)}
                dayClassName={dayClassName}
                {...endDateProps}
            />

            <div className="sep-20"/>

            <Availability
                calculation={props.calculation}
                t={t}
            />
            {/*{availability}*/}
            {/*{props.calculation.success ? <Total calculation={props.calculation} /> : ''}*/}

            <div className="sep-10"/>

            {/*<p className="remarks">*/}
            {/*	{props.typeDetail.minbooking > 0 &&*/}
            {/*	<span>Minimum {props.typeDetail.minbooking} months booking<br /></span>*/}
            {/*	}*/}
            {/*	Full calendar month booking (except the first month)*/}
            {/*</p>*/}

            <div className="sep-20"/>

            <table className="verifiedGuarantee">
                <tbody>
                {props.space.isCorp &&
                <tr>
                    <td><img src={require('../../images/mobile/01_homepage/icon_' + (props.space.reserved ? '' : 'non-') + 'reserved.png')} alt=""/></td>
                    <td><span>{props.space.reserved ? t("space:reserved") : t("space:notreserved")}</span></td>
                </tr>
                }
                <tr>
                    <td><img src={require('../../images/mobile/01_homepage/icon_01_02.png')} alt=""/></td>
                    <td>{t("space:landSearch")}<span>{t("space:verified")}</span></td>
                </tr>
                <tr>
                    <td><img src={require('../../images/mobile/01_homepage/icon_01_03.png')} alt=""/></td>
                    <td>{t("space:enjoy")}
                        <Link target="_blank"
                              to={"/" + i18n.language + "/parkerProtectionGuarantee"}>{t("space:protection")}</Link>
                    </td>
                </tr>
                </tbody>
            </table>

            <div className="sep-20"/>

            {!props.ownSpace &&
            <Payment
                t={t}
                className={!props.calculation.success ? "grey" : ""}
                calculation={props.calculation}
                insertTempBooking={props.calculation.success ? props.insertTempBooking : () => {}}
            />
            }
        </div>
    );
}

// const mapStateToProps = (state) => (
// 	{
// 		findParkingSpace: state.findParkingSpace
// 	}
// );

export default withTranslation()(DetailTimeshare);
// connect(mapStateToProps)(DetailTimeshare);
