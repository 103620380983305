import React, {Component} from 'react';
import {Redirect} from 'react-router';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import ReactPixel from "react-facebook-pixel";
// import * as Helper from "../../utils/03FindParkingSpace/Calculations";

class FacebookPixel extends Component {

    componentDidMount() {

        ReactPixel.track('Purchase', {
            value: this.props.params.value,
            currency: 'HKD',
            content_type: 'product',
            booking_type: this.props.params.booking_type,
            content_id: this.props.params.space_id,
        });
    }

    render() {

        return (
            <div/>
        )
    }
}

function FindYourSpacePending(props) {

    const {t, i18n} = props;

    console.log(props.params)

    if (props.members.Login.length === 0) {
        return <Redirect to={"/" + i18n.language + "/login"}/>;
    }

    let orderID = "";
    if (typeof (props.params.orderID) !== "undefined")
        orderID = props.params.orderID;

    return (
        <div className="wrapper-container confirmation padding">
            <FacebookPixel
                params={props.params}
            />
            <div className="containerMain">
                <div className="content clearfix">
                    <h3 className="align-center preline">{t("bookingConfirmation:nonInstant:part1", {orderID: orderID})}</h3>

                    <p className="align-center preline">{t("bookingConfirmation:nonInstant:part2")}</p>
                    <p className="align-center preline">{t("bookingConfirmation:nonInstant:part3")}</p>

                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => (
    {
        members: state.members,
    }
);

export default withTranslation()(connect(mapStateToProps)(FindYourSpacePending));
