import React from 'react';
// import { Link } from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import moment from 'moment';
import DatePicker from "react-datepicker";
import * as Config from "../../config";

// import { connect } from 'react-redux';
// import { bindActionCreators } from "redux";
// import * as ListParkingSpaceCreators from "../../actions/listParkingSpace";

function TabMonthly(props) {

    const {
        t,
        // i18n
    } = props;

    let onDatepickerRef = (el) => {
        if (el && el.input) {
            el.input.readOnly = true;
        }
    }
    let tabName = 'MonthlyTab',
        tabData = props[tabName];

    let blockdates = (
        <div>
            {
                tabData.Availability.BlockedDateRange.map((blockDate, index) => {
                    return (
                        <div className="form-elements clearfix" key={index}>
                            <div className="fifty">
                                <div>
                                    <DatePicker
                                        dateFormat="YYYY-MM-DD"
                                        selected={blockDate.DateStart === "" ? null : moment(blockDate.DateStart)}
                                        onChange={(e) => props.TabChangeBlockDate(tabName, 'DateStart', e, index)}
                                        minDate={moment()}
                                        // maxDate={blockDate.DateEnd ? moment(blockDate.DateEnd) : null}
                                        placeholderText={t("listASpace:stepTwo.availability.blockPeriod.from")}
                                        ref={el => onDatepickerRef(el)}
                                    />
                                </div>
                            </div>
                            <div className="fifty">
                                <div>
                                    <div className="shorter">
                                        <DatePicker
                                            dateFormat="YYYY-MM-DD"
                                            selected={blockDate.DateEnd === "" ? null : moment(blockDate.DateEnd)}
                                            onChange={(e) => props.TabChangeBlockDate(tabName, 'DateEnd', e, index)}
                                            minDate={blockDate.DateStart ? moment(blockDate.DateStart) : moment()}
                                            // minDate={blockDate.DateStart ? moment(blockDate.DateStart) : moment()}
                                            // maxDate={moment().add(5, "days")}
                                            placeholderText={t("listASpace:stepTwo.availability.blockPeriod.until")}
                                            ref={el => onDatepickerRef(el)}
                                        />
                                    </div>
                                    <div className="shorterFriend">
                                        <div>
											<span
                                                className="button remove"
                                                onClick={() => props.TabRemoveUnavailability(tabName, index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sep-10"/>
                        </div>
                    )
                })
            }
        </div>
    )

    let filterEndDate = (date) => {
        let endof = date.clone().endOf("month");
        if (!endof.isSame(date, 'day'))
            return false;

        return true;
    }

    let dayClassName = (date) => {
        return props.isPublicHoliday(date) ? 'ph' : undefined;
    }

    return (
        <div>
            <div className="title">
                {t("listASpace:stepTwo.availability.title")}
            </div>

            <div className="content">
                <div className="form-elements clearfix">
                    <div className="fifty">
                        <div>
                            <DatePicker
                                dateFormat="YYYY-MM-DD"
                                selected={tabData.Availability.SelectedDateRange.DateStart === "" ? null : moment(tabData.Availability.SelectedDateRange.DateStart)}
                                onChange={(e) => props.MonthlyTabHandleChange('SelectedDateRange', 'DateStart', e)}
                                minDate={moment()}
                                // maxDate={tabData.Availability.SelectedDateRange.DateEnd ? moment(tabData.Availability.SelectedDateRange.DateEnd) : null}
                                placeholderText={t("listASpace:stepTwo.availability.blockPeriod.from")}
                                ref={el => onDatepickerRef(el)}
                                dayClassName={dayClassName}
                            />
                        </div>
                    </div>
                    <div className="fifty">
                        <div>
                            <DatePicker
                                dateFormat="YYYY-MM-DD"
                                selected={tabData.Availability.SelectedDateRange.DateEnd === "" ? null : moment(tabData.Availability.SelectedDateRange.DateEnd)}
                                onChange={(e) => props.MonthlyTabHandleChange('SelectedDateRange', 'DateEnd', e)}
                                minDate={tabData.Availability.SelectedDateRange.DateStart ? moment(tabData.Availability.SelectedDateRange.DateStart) : moment()}
                                placeholderText={t("listASpace:stepTwo.availability.blockPeriod.until")}
                                ref={el => onDatepickerRef(el)}
                                filterDate={filterEndDate}
                                dayClassName={dayClassName}
                            />
                        </div>
                    </div>
                </div>

                <div className="sep-10"/>

                <h3>{t("listASpace:stepTwo.availability.blockPeriod.title")}</h3>

                {blockdates}

                <div className="sep-10"/>


                <div className="form-elements clearfix">
                    <div className="full align-center">
                        <div>
                            <div onClick={() => props.TabAddUnavailability(tabName)}
                                 className="button additional"> {t("listASpace:stepTwo.availability.blockPeriod.additionalUnavailability")}</div>
                            <div className="sep-10"/>
                        </div>
                    </div>
                </div>

            </div>
            {/* form-elements */}

            <div className="title">
                {t("listASpace:stepTwo.pricing.title")}
            </div>

            <div className="content">
                <div className="form-elements clearfix">
                    <div className="fifty">
                        <div>
                            <div className="specialInput clearfix">
                                <div className="dollar">HK$</div>
                                <input type="text"
                                       onChange={(e) => props.MonthlyTabChangeStateLevelOne('price', e.target.value)}
                                       value={tabData.Pricing.price}
                                />
                                <div className="per-month">{t("listASpace:stepTwo.pricing.price.perMonth")}</div>
                            </div>
                        </div>
                    </div>
                    <div className="fifty">
                        <div>
                            {tabData.Pricing.price > 0 &&
                            <p className="color-green">{t("listASpace:stepTwo.pricing.price.receive")} HK${(tabData.Pricing.price * (1 - Config.COMMISION_PERCENTAGE)).toFixed(1)} {t("listASpace:stepTwo.pricing.price.perMonth")}</p>
                            }
                        </div>
                    </div>
                </div>

                <div className="sep-10"/>

                <h3>{t("listASpace:stepTwo.pricing.booking.title")}</h3>
                <div className="form-elements clearfix">
                    <div className="fifty">
                        <div>
                            <select
                                onChange={(e) => props.MonthlyTabChangeStateLevelOne('minBooking', e.target.value)}
                                value={tabData.Pricing.minBooking}
                            >
                                <option value="1">1 {t("listASpace:stepTwo.pricing.booking.month")}</option>
                                <option value="3">3 {t("listASpace:stepTwo.pricing.booking.month")}</option>
                                <option value="6">6 {t("listASpace:stepTwo.pricing.booking.month")}</option>
                                <option value="9">9 {t("listASpace:stepTwo.pricing.booking.month")}</option>
                                <option value="12">12 {t("listASpace:stepTwo.pricing.booking.month")}</option>
                                <option value="24">24 {t("listASpace:stepTwo.pricing.booking.month")}</option>
                            </select>
                        </div>
                    </div>
                    <div className="fifty">
                        <div>

                        </div>
                    </div>
                </div>

                <div className="sep-10"/>

                {props.AccessType !== "No" &&
                <div>
                    <h3>{t("listASpace:stepTwo.pricing.accessCard.title")}</h3>
                    <div className="form-elements clearfix">
                        <div className="fifty">
                            <div>
                                <select
                                    onChange={(e) => props.MonthlyTabChangeStateLevelOne('deposit', e.target.value)}
                                    value={tabData.Pricing.deposit}
                                >
                                    <option value="0">HK$0</option>
                                    <option value="100">HK$100</option>
                                    <option value="200">HK$200</option>
                                    <option value="300">HK$300</option>
                                    <option value="400">HK$400</option>
                                    <option value="500">HK$500</option>
                                </select>
                            </div>
                        </div>
                        <div className="fifty">
                            <div></div>
                        </div>
                    </div>

                    <div className="sep-10"/>
                </div>
                }

                <h3>{t("payment:promotionCode.title")}</h3>
                <div className="form-elements clearfix">
                    <div className="fifty">
                        <div>
                            <input type="text" name="promotionCode"
                                   onChange={(e) => props.changeStateLevelTwo('MonthlyTab', 'Promotion', e.target.value)}/>
                        </div>
                    </div>
                    <div className="fifty">
                        <div></div>
                    </div>
                </div>

                <div className="sep-10"/>

                {/* <h3>Booking period must be full calendar month (except the first month)</h3>

				<div className="form-elements clearfix">

					<div className="fifty">
						<div>
							<div className="checkboxFive green">
								<div>
									<input type="checkbox" id="fullCalendarMonthMonthly" value="Yes" checked={tabData.Pricing.fullCalendarMonth === 'Yes'}
										onChange={(e) => props.MonthlyTabChangeStateRadioLevelOne('fullCalendarMonth', e.target.value)}
									/>
									<label htmlFor="fullCalendarMonthMonthly"></label>
								</div>
								<label htmlFor="fullCalendarMonthMonthly">Yes</label>
							</div>
						</div>
					</div>
					<div className="fifty">
						<div>
							<div className="checkboxFive green">
								<div>
									<input type="checkbox" id="notFullCalendarMonthMonthly" value="No" checked={tabData.Pricing.fullCalendarMonth === 'No'}
										onChange={(e) => props.MonthlyTabChangeStateRadioLevelOne('fullCalendarMonth', e.target.value)}
									/>
									<label htmlFor="notFullCalendarMonthMonthly"></label>
								</div>
								<label htmlFor="notFullCalendarMonthMonthly">No</label>
							</div>
						</div>
					</div>
				</div>
				<div className="sep-10" /> */}
            </div>
        </div>
    )
}

// const mapStateToProps = (state) => (
// 	{
// 		listParkingSpace: state.listParkingSpace
// 	}
// );

export default withTranslation()(TabMonthly);
// connect(mapStateToProps)(TabMonthly);
