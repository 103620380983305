import moment from "moment";
import CryptoJS from "crypto-js";

export const mongoIdToTime=(id) =>
{
    let ts=id.toString().substring(0, 8);
    return moment(parseInt(ts, 16) * 1000);
}

export const mongoIdTimestamp=(id, format) =>
{
    let t=mongoIdToTime(id)
    if (format === false)
        return t;
    return momentTime(t, format);
}

export const momentTime=(time, format) =>
{
    if (typeof (time.utcOffset) === "function")
        return time.utcOffset(8).format(format);

    // console.log("***** ERROR: not moment - ", time, time.utcOffset);
    return time;
}

export const renderListingTitle=(data, language) =>
{
    if (language === 'en-US')
    {
        if (String(data.title).length)
            return data.title;
        else if (String(data.title_tc).length)
            return data.title_tc;
        return data.title;
    }
    else if (language === 'zh-HK')
    {
        if (String(data.title_tc).length)
            return data.title_tc;
        else if (String(data.title).length)
            return data.title;
        return data.title_tc;
    }
}

export const parseHash = (message = "", data = {}) => {
    const variables = message ? message.match(/(\${.*?})/g) : [];
    let parsedMessage = message;
    if (Array.isArray(variables)) {
        variables.forEach((variable) => {
            const key = variable.replace(/(\${)|(}$)/g, "");
            if (data[key]) parsedMessage = parsedMessage.replace(variable, data[key]);
        });
    }
    return parsedMessage;
}

export const parseUrl =  (url = "", data = {}, secret) => {
    const variables = url ? url.match(/({{.*?}})/g) : [];
    let parsedUrl = url;
    if (Array.isArray(variables)) {
        variables.forEach((variable) => {
            const key = variable.replace(/(^{{)|(}}$)/g, "");
            if (data[key]) parsedUrl = parsedUrl.replace(variable, data[key]);
            if (/(^hash:)(.+?)(:endhash)/.test(key)) {
                const plainText = key.replace(/(^hash:)|(:endhash$)/g, "");
                const parsedHash = parseHash(plainText, data);
                const hash = CryptoJS.HmacSHA256(parsedHash, secret);
                parsedUrl = parsedUrl.replace(variable, hash);
            }
        })
    }
    return parsedUrl;
}
