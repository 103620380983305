import React, {Component} from 'react';
// import Iframe from 'react-iframe';
import {withTranslation} from 'react-i18next';

// import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
import LocationSearchInput from "../../../components/04ListASpace/LocationSearchInput";
// import * as StepOneActionCreators from "../../../actions/listParkingSpace";

import GoogleApiWrapper from "../../../components/103Parts/GoogleMap";
import InputRange from "react-input-range";
// import SearchBox from "../../../components/03FindParkingSpace/SearchBox";

// import * as MembersActionCreators from '../../actions/members';

import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';
import ErrorMessage from "../../../components/103Parts/ErrorMessage";
// import {saveCreditCard} from "../../../utils/04Api/Payment";
import * as UserApi from "../../../utils/04Api/User";

class MemberSettingsAlert extends Component
{

    constructor(props)
    {
        super(props)
        // const {dispatch} = props;

        this.state={
            nameOfAlert: '',
            address: '',
            lat: null,
            lng: null,
            feeType: 'hourly',
            spaceSuitable: {
                privateCar: false,
                van: false,
                motorcycle: false,
                lorry: false,
                other: false,
            },
            features1: '',
            features2: {
                ev: false,
                headroom: false,
                headroomHigh: '',
            },
            filter: {
                distance: 30,
                price: {
                    min: 0,
                    max: 200,
                },
                initPrice: {
                    min: 0,
                    max: 200,
                },
                defaultPrice: {
                    hourly: {
                        min: 0,
                        max: 200,
                    },
                    monthly: {
                        min: 0,
                        max: 20000,
                    },
                    timeshare: {
                        min: 0,
                        max: 20000,
                    },
                },
                pricingMaxValue: 200,
            },
            frequency: 'weekly',
            email: false,
            pushNotification: false,
            saved: false,
        };
    }

    componentWillMount=() =>
    {
        // this.ApiGetMe(this.ApiGetMe);
        this.ApiGetMe();
    }

    changeStateLevelOne=(data1, value) =>
    {

        if (data1 === 'feeType')
        {

            this.setState({
                [data1]: value,
                filter: {
                    ...this.state.filter,
                    price: {
                        min: this.state.filter.defaultPrice[value].min,
                        max: this.state.filter.defaultPrice[value].max,
                    },
                    initPrice: {
                        min: this.state.filter.defaultPrice[value].min,
                        max: this.state.filter.defaultPrice[value].max,
                    },
                }
            })
        }
        else
        {

            this.setState({
                [data1]: value
            });
        }
    }

    changeStateLevelTwo=(data1, data2, value, cb) =>
    {

        this.setState({
            [data1]: {
                ...this.state[data1],
                [data2]: value
            }
        }, () =>
        {
            if (typeof (cb) === "function")
                cb();
        });
    }

    changeStateRadioLevelOne=(data1, value) =>
    {
        this.setState({
            [data1]: value
        });
    }

    // Address auto complete with Google Map
    handleChange=(address) =>
    {
        this.setState({address});
    };

    latLngHandler=(latLng, address) =>
    {
        this.setState({
            lat: latLng.lat,
            lng: latLng.lng,
            address: address,
        })
    }

    handleMapNoResult=(address) =>
    {
        this.setState({
            lat: null,
            lng: null,
            address: address,
        })
    }

    //
    // handleChange = (address) => {
    //     this.setState({address});
    // };
    //
    // handleSelect = (address) => {
    //     this.setState({address});
    //
    //     geocodeByAddress(address)
    //         .then(results => getLatLng(results[0]))
    //         .then(latLng => {
    //             this.latLngHandler(latLng, address);
    //         });
    //     //.catch(error => console.error('Error', error));
    // };
    //
    // latLngHandler = (latLng, address) => {
    //     //console.log("StepOne", latLng);
    //     this.setState({
    //         lat: latLng.lat,
    //         lng: latLng.lng,
    //         address: address,
    //     })
    // }


    handleFilterRange(key, value)
    {

        let filter=this.state.filter;

        if (filter[key] !== undefined)
        {

            if (key === 'price')
            {
                if (value.min>=this.state.filter.initPrice.min && value.max<=this.state.filter.initPrice.max)
                {

                    filter[key]=value;
                }
            }
            else if (key === 'distance')
            {
                if (value>=0 && value<=30)
                {
                    filter[key]=value;
                }
            }
        }

        this.setState({

            filter: filter
        })
    }

    handleFilterBoolean(key)
    {
        let filter=this.state.filter;
        filter[key]= !filter[key];
        this.setState({
            filter: filter
        })
    }

    form=({values, errors, touched, handleChange, handleBlur, setErrors, setFieldTouched}) =>
    {
        const {t}=this.props;

        return (
        <Form>
            <div className="settingAlert">
                <div className="sep-20"/>
                <dl className="generalForm clearfix">
                    <div>
                        <dt>
                            <h4>{t("myAccount:tabSettings.alerts.name")}</h4>
                        </dt>
                        <dd>
                            <Field type="text" name="nameOfAlert" placeholder=""/>
                        </dd>
                    </div>

                    <div className="sep-40"/>

                    <div>
                        <dt>
                            <h4>{t("myAccount:tabSettings.alerts.address")}</h4>
                        </dt>
                        <dd>
                            <LocationSearchInput
                            address={this.state.address}
                            handleChange={this.handleChange}
                            latLngHandler={this.latLngHandler}
                            handleMapNoResult={this.handleMapNoResult}
                            />
                        </dd>
                    </div>

                    <div className="sep-40"/>

                    <div>
                        <dt></dt>
                        <dd>
                            <div className="wrapper-map">
                                <GoogleApiWrapper lat={this.state.lat} lng={this.state.lng} width={"100%"}/>
                            </div>
                        </dd>
                    </div>

                    <div className="sep-40"/>

                    <div>
                        <dt>
                            <h4>{t("myAccount:tabSettings.alerts.feeType.title")}</h4>
                        </dt>
                        <dd>
                            <select
                            value={this.state.feeType}
                            onChange={(e) =>
                            {
                                this.changeStateLevelOne("feeType", e.target.value)
                            }}
                            >
                                <option
                                value="hourly">{t("myAccount:tabSettings.alerts.feeType.options.hourly")}</option>
                                <option
                                value="monthly">{t("myAccount:tabSettings.alerts.feeType.options.monthly")}</option>
                                <option
                                value="timeshare">{t("myAccount:tabSettings.alerts.feeType.options.timeShare")}</option>
                            </select>
                            {errors.feeType ?
                            <ErrorMessage message={t(errors.feeType)}/> : null}
                        </dd>
                    </div>

                    <div className="sep-40"/>

                    <div>
                        <dt className="align-top">
                            <h4>{t("myAccount:tabSettings.alerts.suitable.title")}</h4>
                        </dt>
                        <dd className="align-top">
                            <div className="wrap clearfix">
                                <div className="fifty">
                                    <div>
                                        <div className="checkboxFive">
                                            <div>
                                                <input type="checkbox" id="PrivateCar"
                                                       checked={this.state.spaceSuitable.privateCar}
                                                       onChange={() =>
                                                       {
                                                           this.changeStateLevelTwo("spaceSuitable", 'privateCar', !this.state.spaceSuitable.privateCar)
                                                       }}
                                                />
                                                <label htmlFor="PrivateCar"></label>
                                            </div>
                                            <label
                                            htmlFor="PrivateCar">{t("myAccount:tabSettings.alerts.suitable.options.privatecar")}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="fifty">
                                    <div>
                                        <div className="checkboxFive">
                                            <div>
                                                <input type="checkbox" id="Van"
                                                       checked={this.state.spaceSuitable.van}
                                                       onChange={() =>
                                                       {
                                                           this.changeStateLevelTwo("spaceSuitable", 'van', !this.state.spaceSuitable.van)
                                                       }}
                                                />
                                                <label htmlFor="Van"></label>
                                            </div>
                                            <label
                                            htmlFor="Van">{t("myAccount:tabSettings.alerts.suitable.options.van")}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="sep-40"/>

                            <div className="wrap clearfix">
                                <div className="fifty">
                                    <div>
                                        <div className="checkboxFive">
                                            <div>
                                                <input type="checkbox" id="Motorcycle"
                                                       checked={this.state.spaceSuitable.motorcycle}
                                                       onChange={() =>
                                                       {
                                                           this.changeStateLevelTwo("spaceSuitable", 'motorcycle', !this.state.spaceSuitable.motorcycle)
                                                       }}
                                                />
                                                <label htmlFor="Motorcycle"></label>
                                            </div>
                                            <label
                                            htmlFor="Motorcycle">{t("myAccount:tabSettings.alerts.suitable.options.motorcycle")}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="fifty">
                                    <div>
                                        <div className="checkboxFive">
                                            <div>
                                                <input type="checkbox" id="Lorry"
                                                       checked={this.state.spaceSuitable.lorry}
                                                       onChange={() =>
                                                       {
                                                           this.changeStateLevelTwo("spaceSuitable", 'lorry', !this.state.spaceSuitable.lorry)
                                                       }}
                                                />
                                                <label htmlFor="Lorry"></label>
                                            </div>
                                            <label
                                            htmlFor="Lorry">{t("myAccount:tabSettings.alerts.suitable.options.lorry")}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="sep-40"/>

                            <div className="wrap clearfix">
                                <div className="full">
                                    <div>
                                        <div className="checkboxFive">
                                            <div>
                                                <input type="checkbox" id="Other"
                                                       checked={this.state.spaceSuitable.other}
                                                       onChange={() =>
                                                       {
                                                           this.changeStateLevelTwo("spaceSuitable", 'other', !this.state.spaceSuitable.other)
                                                       }}
                                                />
                                                <label htmlFor="Other"></label>
                                            </div>
                                            <label
                                            htmlFor="Other">{t("myAccount:tabSettings.alerts.suitable.options.other")}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </div>

                    <div className="sep-40"/>

                    <div>
                        <dt className="align-top">
                            <h4>{t("myAccount:tabSettings.alerts.features.title")}</h4>
                        </dt>
                        <dd>
                            <div className="wrap clearfix">
                                <div className="fifty">
                                    <div>
                                        <div className="checkboxFive">
                                            <div>
                                                <input type="checkbox" id="Indoor"
                                                       checked={this.state.features1 === 'indoor'}
                                                       onChange={(e) => this.changeStateRadioLevelOne('features1', 'indoor')}
                                                />
                                                <label htmlFor="Indoor"></label>
                                            </div>
                                            <label
                                            htmlFor="Indoor">{t("myAccount:tabSettings.alerts.features.options.indoor")}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="fifty">

                                </div>
                            </div>

                            <div className="sep-40"/>

                            <div className="wrap clearfix">
                                <div className="fifty">
                                    <div>
                                        <div className="checkboxFive">
                                            <div>
                                                <input type="checkbox" id="WithCovers"
                                                       checked={this.state.features1 === 'withCovers'}
                                                       onChange={(e) => this.changeStateRadioLevelOne('features1', 'withCovers')}/>
                                                <label htmlFor="WithCovers"></label>
                                            </div>
                                            <label
                                            htmlFor="WithCovers">{t("myAccount:tabSettings.alerts.features.options.withCovers")}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="fifty">

                                </div>
                            </div>

                            <div className="sep-40"/>

                            <div className="wrap clearfix">
                                <div className="full">
                                    <div>
                                        <div className="checkboxFive">
                                            <div>
                                                <input type="checkbox" id="NoCovers"
                                                       checked={this.state.features1 === 'noCovers'}
                                                       onChange={(e) => this.changeStateRadioLevelOne('features1', 'noCovers')}
                                                />
                                                <label htmlFor="NoCovers"></label>
                                            </div>
                                            <label
                                            htmlFor="NoCovers">{t("myAccount:tabSettings.alerts.features.options.noCovers")}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="sep-40"/>

                            <div className="wrap clearfix">
                                <div className="fifty">
                                    <div>
                                        <div className="checkboxFive">
                                            <div>
                                                <input type="checkbox" id="EV"
                                                       checked={this.state.features2.ev}
                                                       onChange={(e) => this.changeStateLevelTwo('features2', 'ev', !this.state.features2.ev)}
                                                />
                                                <label htmlFor="EV"></label>
                                            </div>
                                            <label
                                            htmlFor="EV">{t("myAccount:tabSettings.alerts.features.options.ev")}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="fifty">

                                </div>
                            </div>

                            <div className="sep-40"/>

                            <div className="wrap clearfix">
                                <div className="fifty">
                                    <div>
                                        <div className="checkboxFive">
                                            <div>
                                                <input type="checkbox" id="Headroom"
                                                       checked={this.state.features2.headroom}
                                                       onChange={(e) => this.changeStateLevelTwo('features2', 'headroom', !this.state.features2.headroom)}
                                                />
                                                <label htmlFor="Headroom"></label>
                                            </div>
                                            <label
                                            htmlFor="Headroom">{t("myAccount:tabSettings.alerts.features.options.headroom")}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="fifty">
                                    <div>
                                        <select
                                        name="headroomHigh"
                                        value={values.headroomHigh}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        >
                                            <option
                                            value=''>{t("listASpace:stepOne.spaceSuitable.pleaseSelect")}</option>
                                            <option
                                            value='1.7'>1.7{t("myAccount:tabSettings.alerts.features.m")}</option>
                                            <option
                                            value='1.8'>1.8{t("myAccount:tabSettings.alerts.features.m")}</option>
                                            <option
                                            value='1.9'>1.9{t("myAccount:tabSettings.alerts.features.m")}</option>
                                            <option
                                            value='2.0'>2.0{t("myAccount:tabSettings.alerts.features.m")}</option>
                                            <option
                                            value='2.1'>2.1{t("myAccount:tabSettings.alerts.features.m")}</option>
                                            <option
                                            value='2.2'>2.2{t("myAccount:tabSettings.alerts.features.m")}</option>
                                            <option
                                            value='2.3'>2.3{t("myAccount:tabSettings.alerts.features.m")}</option>
                                            <option
                                            value='2.4'>2.4{t("myAccount:tabSettings.alerts.features.above")}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </div>

                    <div className="sep-40"/>

                    <div>
                        <dt>
                            <h4 className="title_font_size">{t("search:filterView.filter.walkingDistance.title")}</h4>
                        </dt>
                        <dd>
                            <div className="inputRangeDiv" style={{maxWidth: "300px"}}>
                                <InputRange
                                maxValue={30}
                                minValue={0}
                                value={this.state.filter.distance}
                                formatLabel={value => `${value} ` + t("search:filterView.filter.walkingDistance.mins")}
                                onChange={value => this.handleFilterRange('distance', value)}/>
                            </div>
                        </dd>

                    </div>

                    <div className="sep-40"/>

                    <div>
                        <dt>
                            <h4 className="title_font_size">{t("search:filterView.filter.price.title")}</h4>
                        </dt>
                        <dd>
                            <div className="inputRangeDiv" style={{maxWidth: "300px"}}>
                                <InputRange
                                maxValue={this.state.filter.initPrice.max}
                                minValue={this.state.filter.initPrice.min}
                                value={this.state.filter.price}
                                formatLabel={value => `$${value}`}
                                onChange={value => this.handleFilterRange('price', value)}/>
                            </div>
                        </dd>
                    </div>

                    <div className="sep-40"/>

                    <div>
                        <dt>
                            <h4>{t("myAccount:tabSettings.alerts.frequency.title")}</h4>
                        </dt>
                        <dd>
                            <select
                            name="frequency"
                            value={values.frequency}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            >
                                <option
                                value="weekly">{t("myAccount:tabSettings.alerts.frequency.options.weekly")}</option>
                                <option
                                value="daily">{t("myAccount:tabSettings.alerts.frequency.options.daily")}</option>
                            </select>
                        </dd>
                    </div>

                    {/*<div className="sep-40"/>*/}

                    {/*<div>*/}
                    {/*    <dt>*/}
                    {/*        <h4>{t("myAccount:tabSettings.alerts.delivery.title")}</h4>*/}
                    {/*    </dt>*/}
                    {/*    <dd>*/}
                    {/*        <div className="wrap clearfix">*/}
                    {/*            <div className="fifty">*/}
                    {/*                <div>*/}
                    {/*                    <div className="checkboxFive">*/}
                    {/*                        <div>*/}
                    {/*                            <Field name="email" type="checkbox" id="Email"*/}
                    {/*                                   checked={values.email}*/}
                    {/*                            />*/}
                    {/*                            <label htmlFor="Email"></label>*/}
                    {/*                        </div>*/}
                    {/*                        <label*/}
                    {/*                            htmlFor="Email">{t("myAccount:tabSettings.alerts.delivery.email")}</label>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="fifty">*/}
                    {/*                <div>*/}
                    {/*                    <div className="checkboxFive">*/}
                    {/*                        <div>*/}
                    {/*                            <Field name="pushNotification" type="checkbox"*/}
                    {/*                                   id="pushNotification"*/}
                    {/*                                   checked={values.pushNotification}*/}
                    {/*                            />*/}
                    {/*                            <label htmlFor="pushNotification"></label>*/}
                    {/*                        </div>*/}
                    {/*                        <label*/}
                    {/*                            htmlFor="pushNotification">{t("myAccount:tabSettings.alerts.delivery.notification")}</label>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </dd>*/}
                    {/*</div>*/}
                </dl>

                <div className="sep-25"/>
                <div className="sep-25"/>

                <div className="full left align-center">
                    {errors.nameOfAlert ?
                    <ErrorMessage message={t(errors.nameOfAlert)}/> : null}
                    {errors.address ?
                    <ErrorMessage message={t(errors.address)}/> : null}
                    {errors.carType ?
                    <ErrorMessage message={t(errors.carType)}/> : null}
                    {errors.features ?
                    <ErrorMessage message={t(errors.features)}/> : null}
                    {errors.method ?
                    <ErrorMessage message={t(errors.method)}/> : null}
                    <div className="sep-25"/>
                    <div>
                        <input type="submit" className="button" value={t("myAccount:save")}/>
                    </div>

                    {this.state.saved &&
                    <div>
                        <div className="sep-20"/>
                        {t("myAccount:updated")}
                    </div>
                    }
                </div>
            </div>
        </Form>
        )
    }

    handleSubmit=(values, {setFieldError}) =>
    {

        let success=true;

        if (values.nameOfAlert === '')
        {
            setFieldError('nameOfAlert', this.props.t('myAccount:errors.alert.name'));
            success=false;
        }

        if (this.state.address === '' || !this.state.lng || !this.state.lat)
        {
            setFieldError('address', this.props.t('myAccount:errors.alert.address'));
            success=false;
        }

        if (
        !this.state.spaceSuitable.privateCar &&
        !this.state.spaceSuitable.van &&
        !this.state.spaceSuitable.motorcycle &&
        !this.state.spaceSuitable.lorry &&
        !this.state.spaceSuitable.other)
        {
            setFieldError('carType', this.props.t('myAccount:errors.alert.carType'));
            success=false;
        }
        if (
        this.state.features1 === '' &&
        !this.state.features2.ev &&
        !this.state.features2.headroom)
        {
            setFieldError('features', this.props.t('myAccount:errors.alert.features'));
            success=false;
        }
        // if(
        //     !values.email &&
        //     !values.pushNotification){
        //     setFieldError('method', this.props.t('myAccount:errors.alert.method'));
        //     success = false;
        // }

        if (success)
        {
            let cartTypes=[];

            if (this.state.spaceSuitable.privateCar)
                cartTypes.push('PrivateCar');
            if (this.state.spaceSuitable.van)
                cartTypes.push('Van');
            if (this.state.spaceSuitable.motorcycle)
                cartTypes.push('Motorcycle');
            if (this.state.spaceSuitable.lorry)
                cartTypes.push('Lorry');
            if (this.state.spaceSuitable.other)
                cartTypes.push('Others');

            let features=[];

            if (this.state.features1 === 'indoor')
                features.push('Indoor');
            if (this.state.features1 === 'withCovers')
                features.push('WithCovers');
            if (this.state.features1 === 'noCovers')
                features.push('NoCovers');
            if (this.state.features2.ev)
                features.push('EV');
            if (this.state.features2.headroom)
                features.push('HeadRoom');

            let methods=['email'];

            // if (values.email)
            //     methods.push('email');
            // if (values.pushNotification)
            //     methods.push('push');

            let body={
                alert: {
                    name: values.nameOfAlert,
                    address: this.state.address,
                    type: this.state.feeType,
                    cartypes: cartTypes,
                    features: features,
                    headroom: values.headroomHigh,
                    distance: this.state.filter.distance,
                    price_start: this.state.filter.price.min,
                    price_end: this.state.filter.price.max,
                    feq: values.frequency,
                    methods: methods,
                    lng: this.state.lng,
                    lat: this.state.lat,
                }
            }

            let callback=() =>
            {
                this.setState({
                    ...this.state,
                    saved: true,
                })

                setTimeout(() =>
                {
                    this.setState({
                        ...this.state,
                        saved: false,
                    })
                }, 2000);
            }

            UserApi.updateAlert(this.props.members.Login.userToken, body, callback);
        }
    }

    ApiGetMe=(cb) =>
    {
        let callback=(res) =>
        {
            console.log("This is Get ME");
            console.log(res.body);
            if (typeof (res.body) !== "undefined")
            {

                let me=res.body;

                let max=20000;

                if(!me.alert.length)
                {
                    if (typeof (cb) !== "undefined")
                       return  cb();

                    return true;
                }

                if (me.alert[0].type === 'hourly')
                {
                    max=200;
                }

                let spaceSuitable={
                    privateCar: false,
                    van: false,
                    motorcycle: false,
                    lorry: false,
                    other: false,
                };

                me.alert[0].cartypes.map((value, key) =>
                {
                    if (value === 'PrivateCar')
                        spaceSuitable['privateCar']=true;
                    if (value === 'Van')
                        spaceSuitable['van']=true;
                    if (value === 'Motorcycle')
                        spaceSuitable['motorcycle']=true;
                    if (value === 'Lorry')
                        spaceSuitable['lorry']=true;
                    if (value === 'Others')
                        spaceSuitable['other']=true;
                    return null;
                });

                let feature1='';

                let feature2={
                    ev: false,
                    headroom: false,
                    headroomHigh: '',
                };

                me.alert[0].features.map((value, key) =>
                {
                    if (value === 'Indoor')
                    {
                        feature1='indoor';
                    }
                    else if (value === 'WithCovers')
                    {
                        feature1='withCovers';
                    }
                    else if (value === 'NoCovers')
                    {
                        feature1='noCovers';
                    }
                    else if (value === 'EV')
                    {
                        feature2.ev=true;
                    }
                    else if (value === 'HeadRoom')
                    {
                        feature2.headroom=true;
                        feature2.headroomHigh=me.alert[0].headroom;
                    }
                    return null;
                });

                let email=false, pushNotification=false;

                me.alert[0].methods.map((value, key) =>
                {
                    if (value === 'email')
                    {
                        email=true;
                    }
                    else if (value === 'push')
                    {
                        pushNotification=true;
                    }
                    return null;
                });

                this.setState({
                    ...this.state,
                    nameOfAlert: me.alert[0].name,
                    address: me.alert[0].address,
                    feeType: me.alert[0].type,
                    spaceSuitable: spaceSuitable,
                    features1: feature1,
                    features2: feature2,
                    filter: {
                        distance: 25,
                        price: {
                            min: me.alert[0].price_start,
                            max: me.alert[0].price_end,
                        },
                        initPrice: {
                            min: 0,
                            max: max,
                        },
                        defaultPrice: {
                            hourly: {
                                min: 0,
                                max: 200,
                            },
                            monthly: {
                                min: 0,
                                max: 20000,
                            },
                            timeshare: {
                                min: 0,
                                max: 20000,
                            },
                        },
                        pricingMaxValue: 200,
                    },
                    frequency: me.alert[0].feq,
                    email: email,
                    pushNotification: pushNotification,
                    lng: me.alert[0].lng,
                    lat: me.alert[0].lat,
                }, () =>
                {
                    console.log(this.state);
                    if (typeof (cb) !== "undefined")
                        cb();
                });
            }
        }

        UserApi.getMe(this.props.members.Login.userToken, callback);
    }

    render()
    {

        // const {
        //     t
        // } = this.props;

        // Form Validation
        this.schema=Yup.object().shape({});

        return (
        <Formik
        initialValues={{
            nameOfAlert: this.state.nameOfAlert,
            headroomHigh: this.state.features2.headroomHigh,
            frequency: this.state.frequency,
            email: this.state.email,
            pushNotification: this.state.pushNotification,
        }}
        validationSchema={this.schema}
        enableReinitialize={true}
        onSubmit={this.handleSubmit}
        component={this.form}
        />
        );
    }
}

const mapStateToProps=(state) => ({
    members: state.members
}
);

export default withTranslation()(connect(mapStateToProps)(MemberSettingsAlert));
