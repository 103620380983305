import React, {Component} from 'react';

import PlacesAutocomplete, {
    // geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import {GoogleApiWrapper} from 'google-maps-react';

import {withTranslation} from 'react-i18next';
import * as Config from "../../config";

class LocationSearchInput extends Component {
    constructor(props) {
        super(props)

        this.state = {
            timeout: null,
        }
    }

    geocodeByAddress = (address) => {
        var geocoder = new window.google.maps.Geocoder();
        var OK = window.google.maps.GeocoderStatus.OK;

        return new Promise(function (resolve, reject) {
            geocoder.geocode({address: address, region: "HK"}, function (results, status) {
                if (status !== OK) {
                    reject(status);
                }
                resolve(results);
            });
        });
    };

    handleChange = (address) => {
        this.props.handleChange(address)

        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
        }

        let timeout = setTimeout(() => {
            this.geocodeByAddress(address)
                .then(results => getLatLng(results[0]))
                .then(latLng => {
                    // console.log(address, latLng);
                    this.props.latLngHandler(latLng, address);
                }, (reason) => {
                    // console.log(reason);
                    if (typeof (this.props.handleMapNoResult) !== "undefined")
                        this.props.handleMapNoResult(address, reason);
                });
        }, 500);

        this.setState({
            ...this.state,
            timeout: timeout,
        })
    }

    searchOptions = {
        componentRestrictions: {
            country: 'hk',
        },
        //types: ['address']
    }


    render() {
        const {
            t,
            // i18n
        } = this.props;

        return (
            <PlacesAutocomplete
                className={this.props.className}
                placeholder=""
                value={this.props.address}
                onChange={this.handleChange}
                onSelect={this.handleChange}
                onError={() => {
                    return null
                }}
                searchOptions={this.searchOptions}
                debounce={500}
                highlightFirstSuggestion={true}
            >
                {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                    <div className="location-search-input">
                        <input
                            {...getInputProps({
                                placeholder: t("search:enterAddress"),
                                className: (typeof (this.props.className) !== "undefined" ? this.props.className : "") + ' location-search-input',
                            })}
                        />
                        <div className="autocomplete-dropdown-container">
                            {loading && <div>{t("common:Loading")}</div>}
                            {suggestions.map((suggestion, i) => {
                                const className = suggestion.active
                                    ? 'suggestion-item suggestion-item--active'
                                    : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                                    : {backgroundColor: '#ffffff', cursor: 'pointer'};
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                        key={i}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        );
    }
}

export default withTranslation()(GoogleApiWrapper({
    apiKey: Config.UAT_GOOGLE_MAP_KEY,
    language: 'en-us'
})(LocationSearchInput));