import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import ErrorMessage from "../103Parts/ErrorMessage";
import * as Yup from 'yup';
import {Formik, Form, Field} from 'formik';
import {formSubmit} from "../../utils/04Api/Newsletter";
import ReactPixel from 'react-facebook-pixel';

class newsletter extends Component {

    constructor(props) {

        super(props);

        this.state = {
            saved: false,
        };
    }

    handleSubmit = (values, {setFieldError, resetForm}) => {

        ReactPixel.track('Subscribe', {value: 0.00, currency: 'HKD', predicted_ltv: '0.00'});

        let body = {
            name: values.name,
            email: values.email,
        };

        const cb = (obj) => {
            if (obj.status === 200) {
                resetForm();

                this.setState({
                    ...this.state,
                    saved: true,
                });

                setTimeout(() => {
                    this.setState({
                        ...this.state,
                        saved: false,
                    })
                }, 2000);
            } else {
                let err;
                if (obj.body.msg === "User registrated in ENews!")
                    err = this.props.t("login:errors.duplicate");
                else
                    err = this.props.t("login:errors.enewsfail");

                setFieldError('email', err);
            }
        }

        const eCb = () => {
            setFieldError('name', this.props.t("login:errors.enewsfail"));
        }

        formSubmit(body, cb, eCb);
    };

    form = ({errors, touched}) => {
        const {t} = this.props;

        // console.log(errors);

        return (
            <Form>
                <h3>{t("homepage:eNews.title")}</h3>

                <Field type="text" name="email" placeholder={t("homepage:eNews.email")}/>
                {errors.email && touched.email ? <ErrorMessage message={errors.email}/> : null}

                <Field type="text" name="name" placeholder={t("homepage:eNews.name")}/>
                {errors.name && touched.name ? <ErrorMessage message={errors.name}/> : null}

                <div className="sep-0"/>
                <input type="submit" className="button right" value={t("homepage:eNews.submit")}/>

                {this.state.saved &&
                <div>{t("contact:successSubmit")}</div>
                }
            </Form>
        );
    };

    render() {
        const {
            t,
            // i18n
        } = this.props;

        const schema = Yup.object().shape({
            name: Yup.string()
                .required(t("contact:errors.name")),
            email: Yup.string()
                .required(t("contact:errors.email"))
                .email(t("contact:errors.email")),
        });

        return (
            <div className="enews-subscription">
                <Formik
                    initialValues={{
                        name: "",
                        email: "",
                    }}
                    validationSchema={schema}
                    onSubmit={this.handleSubmit}
                    component={this.form}
                />
            </div>
        );
    }
}

//
// function newsletter(props) {
//     const {t} = props;
//     return (
//         <div className="enews-subscription">
//             <h3>{t("homepage:eNews.title")}</h3>
//             <input type="text" placeholder={t("homepage:eNews.name")} value={props.name}
//                    onChange={props.name_changeStateLevelOne}/>
//             {Object.keys(props.Errors).length > 0 ? <ErrorMessage message={props.Errors.name}/> : null}
//             <input type="text" placeholder={t("homepage:eNews.email")} value={props.email}
//                    onChange={props.email_changeStateLevelOne}/>
//             {Object.keys(props.Errors).length > 0 ? <ErrorMessage message={props.Errors.email}/> : null}
//             <div className="sep-0"/>
//             <input type="submit" className="button right" value={t("homepage:eNews.submit")}
//                    onClick={props.eNewsSubmit}/>
//         </div>
//     )
// }

export default withTranslation()(newsletter);