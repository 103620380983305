import React, {Component} from 'react';

class LicenseAgreement_zh_HK extends Component {
    render() {
        return (
            <div className="disclaimer" dangerouslySetInnerHTML={{__html: this.props.content}}/>
            // <div className="licenseAgreement">
            //     <p>本《特許協議》（「本《特許協議》」）規限通過平台獲得泊車位的事宜，本《特許協議》須受確認書和本文件中規定的條款及條件所限。</p>
            //
            //     <ol>
            //         <li><strong>定義及釋義</strong>
            //             <ol>
            //                 <li>在本《特許協議》中，除非上下文另有要求，否則下列字詞具有以下含義：－
            //                     <ul>
            //                         <li>「進出卡」指車主需要用以取用相關區域及／或泊車位的任何進出卡或卡；</li>
            //                         <li>「確認書」指管理人根據《平台條款及條件》第1(b)段或第9.2(d)段發出的確認書，《平台條款及條件》中規定了特許的某些條款；</li>
            //                         <li>「按金」指本特許的按金（如適用）；</li>
            //                         <li>「特許」指業主授予車主根據本《特許協議》中規定的條款使用泊車位的權利；</li>
            //                         <li>「預訂時段」指如確認書所述，業主向車主授予特許，准予車主使用泊車位的持續時間，為免生疑問，如果車主只是獲准在泊車位停泊一天內的部分時間或一周內的某些日子，預訂時段不包括車主未獲准停泊在泊車位的期間；</li>
            //                         <li>「泊車費用」指車主須就特許支付給業主的泊車費用，如確認書所述；</li>
            //                         <li>「管理人」指在香港註冊成立的有限責任公司－泊車易(香港)有限公司；</li>
            //                         <li>「業主」指授予特許的人；</li>
            //                         <li>「車主」指從業主獲得特許的人；</li>
            //                         <li>「泊車位」指確認書中所述的泊車位；</li>
            //                         <li>「雙方」指業主和車主，而「一方」指其中任何一方；</li>
            //                         <li>「平台」指由管理人擁有及經營的「泊車易」網站 <a
            //                             style={{"textDecoration":"underline"}}
            //                             href="http://www.letspark.com.hk">www.letspark.com.hk</a> 或手機應用程式；
            //                         </li>
            //                         <li>「《平台條款及條件》」指平台的使用條款及條件，以及在確認書日期時，在管理人的網站上公佈的由管理人提供的服務；</li>
            //                         <li>「相關區域」指該車輛進入泊車位時必須途經的所有區域、通道、道路、升降機、樓梯等，以及該車輛的司機及乘客取用該車輛時必須途經的所有區域、通道、道路、升降機、樓梯等；</li>
            //                         <li>「暫停事件」指第9.1條所述的任何事件；</li>
            //                         <li>「該車輛」指由車主通知管理人及由管理人通知業主（不時修訂，如適用）將會佔用泊車位的車輛。</li>
            //                     </ul>
            //                 </li>
            //                 <li>在本《特許協議》中，除非上下文另有要求，否則：
            //                     <ul>
            //                         <li>凡提述條款，指本《特許協議》的條款；</li>
            //                         <li>某一性別字詞亦包括所有其他性別；</li>
            //                         <li>單數字詞亦包括複數，反之亦然；及</li>
            //                         <li>「人士」包括個人、合夥、公司或非法人團體及政府部門；</li>
            //                         <li>凡提述由管理人不時公佈的程序及條款，指當時管理人在其網站<a
            //                             style={{"textDecoration":"underline"}}
            //                             href="https://www.letspark.com.hk">www.letspark.com.hk</a>發布的相關程序及條款。
            //                         </li>
            //                     </ul>
            //                 </li>
            //                 <li>在本《特許協議》中，「營業日」指除星期六、星期日和公眾假期以外的日子。</li>
            //                 <li>如果本《特許協議》的任何條款與確認書的條款有任何歧義，須以確認書的條款為準。如果本《特許協議》的條款與《平台條款及條件》有任何歧義，須以本《特許協議》的條款及條件為準。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>特許</strong>
            //             <ol>
            //                 <li>業主特此授予車主在預訂時段內根據本《特許協議》的條款將該車輛停泊在泊車位的特許。</li>
            //                 <li>特許本身以及車主使用泊車位或相關區域或其任何部分，均不得解釋為對泊車位、相關區域、泊車位及相關區域所處於的建築物訂立任何租約或租賃。車主只可以特許持有人身分使用泊車位和相關區域。</li>
            //                 <li>雙方同意並承認特許僅介乎車主和業主之間，而無論管理人就特許發揮如何的作用，管理人都不是特許或本《特許協議》的一方。</li>
            //                 <li>車主可以根據管理人所公佈的程序，將該車輛更改為車主擁有的另一輛車輛，以供業主批准，業主可以全權絕對酌情決定給予或拒絕給予批准，惟前提是如果業主拒絕上述請求或該車輛在更改之後無法進入泊車位或相關區域及／或該車輛因任何原因無法停泊在泊車位，車主無權終止特許。</li>
            //                 <li>特許不可轉讓，並且車主不得把特許再授予他人，或允許任何第三方付費或免費佔用泊車位。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>付款</strong>
            //             <ol>
            //                 <li>泊車費用須是確認書中列出的金額，並須由車主根據平台上的說明向管理人支付。</li>
            //                 <li>泊車費用須被視為包括差餉地租及管理費，並且除了泊車費用以外，除非明確規定，否則車主毋須向業主支付與特許有關的任何性質的金額。</li>
            //                 <li>如果需要支付按金或業主保留要求支付按金的權利，而業主行使此權利：－
            //                     <ul>
            //                         <li>車主須按照平台所載的指示，向管理人支付平台所載的按金金額；</li>
            //                         <li>業主可指示管理人在特許存續期間的任何時間以及在特許終止後最多2個營業日內，從按金中扣除車主就特許應向業主支付的任何款項；</li>
            //                         <li>在預訂時段屆滿或終止後，業主須在第3(b)條所述款項被扣除後，向車主退還管理人持有的按金或任何按金餘額（如有），退款不計利息。</li>
            //                     </ul>
            //                 </li>
            //                 <li>除第1、9.2和9.3條規定的金額外，車主向業主支付的泊車費用均不予退還。</li>
            //                 <li>如果已經取得車主的信用卡的預先授權，業主便有權（但沒有義務）指示管理人從信用卡收取車主根據本《特許協議》應付給業主的任何金額，惟前提是即使因為任何原因而導致業主沒有從上述預先授權中收取款項，也不影響車主在本《特許協議》下支付該費用的責任。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>泊車保障政策</strong>
            //             <ol>
            //                 <li>在不損害車主在本《特許協議》和法律下的權利但符合第2條的情況下，如果平台上泊車位的描述不準確，以致管理人認為實質地影響車主使用泊車位，車主有權終止特許並獲退還車主為泊車位支付的金額，退款不計利息。</li>
            //                 <li>第4.1條規定的車主的權利受以下條件限制：－
            //                     <ul>
            //                         <li>車主在預訂時段開始後48小時內，根據管理人發布的程序通知管理人第4.1條所述的不準確之處的詳情，並提供有關證據；及</li>
            //                         <li>管理人以專家而非仲裁員身分確認該不準確之處將在很大程度上合理影響車主使用泊車位。</li>
            //                     </ul>
            //                 </li>
            //                 <li>業主和車主同意管理人根據第4.2(b)條作出的任何決定均為最終決定，對雙方具有約束力</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>業主的保證及契諾</strong>
            //             <ol>
            //                 <li>業主向車主保證
            //                     <ul>
            //                         <li>業主是泊車位的擁有人或獲業主授權管理泊車位；</li>
            //                         <li>業主有權訂立本《特許協議》並按照本《特許協議》的條款授予特許；</li>
            //                         <li>平台所載的泊車位的描述是正確的，並在特許延續期間繼續在很大程度上是正確的；及</li>
            //                         <li>業主已採取一切必要行動並獲得所有相關方的一切必要同意，以允許該車輛使用泊車位，並允許該車輛、其司機和乘客在特許延續期間繼續取用泊車位和相關區域。</li>
            //                     </ul>
            //                 </li>
            //                 <li>業主向車主契諾
            //                     <ul>
            //                         <li>泊車位可供車主在預訂時段內停泊該車輛；</li>
            //                         <li>在預訂時段開始時，泊車位乾淨整潔，沒有垃圾；</li>
            //                         <li>在預訂時段內，該車輛、其司機和乘客只可為了取用該車輛的唯一目的，進入泊車位及相關區域；</li>
            //                         <li>業主須協助車主在預訂時段開始時或之前找到並進入泊車位，並須向車主提供操作正常的進出卡（如適用）。</li>
            //                     </ul>
            //                 </li>
            //                 <li>除非由於違反第1和5.2條，否則業主毋須因為以下理由導致車主或任何其他人士本身或其財產可能遭受的任何性質的損失、損害或傷害，對車主或任何其他人士承擔任何責任，而車主根據本《特許協議》應付的任何款項也不得因以下原因而減少或停止支付：
            //                     <ul>
            //                         <li>可能提供給泊車位或相關區域的任何服務，包括但不限於電力和保安服務的任何缺陷、失靈、故障或中斷；</li>
            //                         <li>泊車位及／或相關區域的租戶及其他佔用人及其各自的受僱人、代理人或特許持有人的行為、疏忽或失責；</li>
            //                         <li>泊車位及相關區域或其任何部分的狀況。</li>
            //                     </ul>
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>車主的保證及契諾</strong>
            //             <ol>
            //                 <li>車主向業主保證
            //                     <ul>
            //                         <li>車主是該車輛的擁有人；及</li>
            //                         <li>車主有權訂立本《特許協議》，以根據本《特許協議》中的條款獲取特許。</li>
            //                     </ul>
            //                 </li>
            //                 <li>車主向業主契諾，車主須
            //                     <ul>
            //                         <li>僅將泊車位用於停泊該車輛，而不得將泊車位用作任何其他用途，包括但不限於任何形式的商業活動或修理或維修該車輛或任何其他車輛的用途；</li>
            //                         <li>不在預訂時段之外佔用泊車位；</li>
            //                         <li>不在泊車位停泊該車輛以外的任何車輛；</li>
            //                         <li>保持泊車位及相關區域乾淨整潔，無垃圾；</li>
            //                         <li>不在泊車位及相關區域進行任何非法或不道德的活動；</li>
            //                         <li>將車輛停放在泊車位的周邊範圍內，而不妨礙毗鄰及圍繞相關區域及泊車位的地方的其他使用者；</li>
            //                         <li>如因為該車輛、該車輛的司機或乘客而導致泊車位及相關區域及當中或其附近的任何財產受到任何損壞，須通知業主；</li>
            //                         <li>不在泊車位、相關區域及毗鄰泊車位的地方及附近地區，作出任何可能對業主或相關區域及毗鄰泊車位的地方及附近地區的其他使用者造成滋擾、煩擾、不便或騷擾的事情，也不允許其他人作出此類事情；</li>
            //                         <li>遵守業主向車主所通知的與泊車位及相關區域的使用和佔用有關的任何法律或規則及政府或其他主管當局的要求，並且就本條而言，在緊接預訂泊車位之前於平台上的泊車位描述列出的所有事項均須被視為已由業主通知車主；</li>
            //                         <li>對該車輛及該車輛內的財產的安全負責，而不依賴業主或相關區域的擁有人或佔用人或管理人所採取的保安措施；</li>
            //                         <li>帶着合理的謹慎進出及／或駕駛至相關區域，並遵守泊車位及相關區域的控制員或管理人的所有交通標誌和指示；</li>
            //                         <li>禮貌地對待業主和相關區域及毗鄰泊車位的地方及附近地區的其他使用者；</li>
            //                         <li>確保該車輛在預訂時段內按照香港法律宜於道路上使用、獲發特許且受保；及</li>
            //                         <li>促使只有該車輛的司機和乘客才能取用泊車位及相關區域，並且只能為了取用該車輛的目的取用泊車位及相關區域。</li>
            //                     </ul>
            //                 </li>
            //                 <li>如果必須使用進出卡，車主須：
            //                     <ul>
            //                         <li>除了為了取用泊車位及／或相關區域外，不將進出卡用於其他用途；</li>
            //                         <li>負責安全地保管進出卡，如進出卡遺失或故障，須立即通知業主；及</li>
            //                         <li>在預訂時段結束時將狀況（正常損耗除外）及工作狀態良好的進出卡歸還給業主。</li>
            //                     </ul>
            //                 </li>
            //                 <li>車主須促使該車輛的司機和乘客遵守本特許的規定以及本《特許協議》中適用於他們的條款及條件，如該車輛的司機或乘客作出任何行為、違規、疏忽或遺漏，須被視為車主的任何行為、違規、疏忽或遺漏。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>逾時停泊</strong>
            //             <ol>
            //                 <li>在不損害業主在法律下及本《特許協議》下的任何其他權利的情況下，如果車主未能在預訂時段或當中其獲分配使用該車輛的期間結束時或之前離開泊車位：
            //                     <ul>
            //                         <li>車主須每小時或不足一小時的部分支付港幣100元作為違約賠償金；及</li>
            //                         <li>業主有權在兩小時後將佔用泊車位的該車輛或車主的任何其他車輛拖離泊車位，費用由車主承擔。業主不對因為其行使其在本條款下的權利而導致車主可能遭受的任何損失和損害或該車輛蒙受的任何損害，承擔任何責任。</li>
            //                     </ul>
            //                 </li>
            //                 <li>為了讓業主行使第1條規定的權利，業主須向車主和管理人提供逾時停泊的證據，而泊於泊車位的該車輛的圖像連同時間戳須被視為充分證據。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>管理人</strong>
            //             <ol>
            //                 <li>業主特此確認，其已授權管理人為及代表業主，收取車主在本《特許協議》下應支付給業主的任何性質的所有款項，並進一步確認，車主向管理人支付任何款項，須充分解除車主在本《特許協議》下支付該筆款項的責任。車主特此同意向管理人支付其在本《特許協議》下應付給業主的所有費用。</li>
            //                 <li>如果根據本《特許協議》，業主須向車主支付泊車費用或任何其他金額的任何退款，那麼在不影響業主向車主支付此類款項的責任的情況下，車主同意並接受管理人代表業主支付該筆款項，將充分解除業主支付該筆款項的責任。</li>
            //                 <li>業主和車主同意受管理人在本《特許協議》中確定的事實所約束，並進一步同意不就管理人的任何決定對管理人提出任何索賠。</li>
            //                 <li>儘管有第1和8.2條或本《特許協議》的任何其他規定，業主和車主同意並承認：－
            //                     <ul>
            //                         <li>管理人不是本《特許協議》的一方，因此管理人及其高級人員、僱員、代理人或承包商均毋須就特許或本《特許協議》向任何一方負責；及</li>
            //                         <li>在不損害管理人代表業主作出的任何付款的有效性的情況下，業主在本《特許協議》下的任何支付義務須是業主的唯一義務，而即使業主沒有付款，管理人也毋須向車主承擔任何責任。</li>
            //                     </ul>
            //                 </li>
            //                 <li>業主和車主同意並確認儘管只有他們是特許的唯一雙方，但他們同意並向管理人承諾，未經管理人明確同意（可按管理人的全權絕對酌情決定是否同意），他們不得同意修改本《特許協議》。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>暫停</strong><strong>特許</strong>
            //             <ol>
            //                 <li>本條款適用於由於超出雙方合理控制範圍的情況而導致無法使用或無法取用泊車位的情況，包括但不限於工業行為、火災、洪水、法律更改或其他政府或適用法規更改、天災、爆炸、騷動、民間騷亂、風暴、颱風、沉降等。</li>
            //                 <li>如果發生暫停事件：－
            //                     <ul>
            //                         <li>在暫停活動期間須暫停特許；</li>
            //                         <li>在暫停活動期間不得支付泊車費用，而在暫停活動期間支付的任何泊車費用將退還給車主，除非特許隨後被終止，在這種情況下，則第3和12.2條適用；及</li>
            //                         <li>預訂時段不得因任何暫停而延長。</li>
            //                     </ul>
            //                 </li>
            //                 <li>如果暫停活動持續超過5天，任何一方均可根據管理人不時公佈的程序，通知另一方和管理人其擬終止特許，然後特許將在管理人和另一方收到上述通知後終止，而車主在終止日期後就使用泊車位而支付的泊車費用須予退還。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>彌償</strong>
            //             <ol>
            //                 <li>車主須在所有時候均就以下項目確保令業主獲得彌償：由於車主違反本《特許協議》，或車主使用泊車位，或任何進出卡（如適用）的遺失或損壞而導致業主可能蒙受、負責或產生或與之相關的任何性質的訴訟、程序、索賠、要求、成本、損失、損害和開支。車主特此同意，如果因為違反本《特許協議》而導致特許被終止，業主的損失須包括業主根據《平台條款及條件》在剩餘預訂時段內須支付給管理人的任何服務費用。</li>
            //                 <li>業主須在所有時候均就以下項目確保令車主獲得彌償：由於業主違反本《特許協議》，或車主使用泊車位，而導致車主可能蒙受、負責或產生或與之相關的任何性質的訴訟、程序、索賠、要求、成本、損失、損害和開支。</li>
            //                 <li>雙方須在所有時候均就以下項目確保令管理人、其高級職員和僱員以及代理人獲得彌償：由於任何一方違反本《特許協議》，或任何一方的任何行為或遺漏而導致任何一方可能蒙受、負責或產生或與之相關的任何性質的訴訟、程序、索賠、要求、成本、損失、稅項、損害賠償和開支。假如該損失是因為雙方的任何行為或遺漏或違反本《特許協議》而導致，雙方須根據第3條對管理人負上共同及各別的責任。</li>
            //                 <li>第10條規定的彌償在特許終止後繼續有效。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>終止特許</strong>
            //             <ol>
            //                 <li>在不損害法律或本《特許協議》條款下的任何終止的情況下，特許將在預訂時段屆滿時終止。</li>
            //                 <li>在不損害業主對車主可能擁有的任何其他權利的情況下，如果車主未按照本《特許協議》支付泊車費用的任何部分，特許將終止。</li>
            //                 <li>除非本《特許協議》中明確規定或因為另一方違反本《特許協議》，否則未經另一方和管理人同意，任何一方不得在預訂時段屆滿前終止特許。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>終止特許的後果</strong>
            //             <ol>
            //                 <li>當特許因任何原因終止後：－
            //                     <ul>
            //                         <li>車主須停止進入泊車位及相關區域；</li>
            //                         <li>車主須歸還進出卡（如有）給業主；</li>
            //                         <li>車主須大致上按預訂時段間開始時泊車位的狀態清空泊車位；及</li>
            //                         <li>如管理人根據第3條代表業主持有按金，則業主須在特許終止後根據第3.3(c)條在扣除款項後，退回按金或其餘額給車主，款項不帶利息。</li>
            //                     </ul>
            //                 </li>
            //                 <li>如果根據本《特許協議》的規定，泊車費用或其任何部分須在預訂時段終止後退還，業主（或代表業主的管理人）須在終止後把在預訂時段內的任何泊車費用在扣款後退還給車主。</li>
            //                 <li>如果本《特許協議》是由於車主違反而終止，那麼在不損害業主根據第10.1條或法律規定可對車主提出的任何其他權利的情況下，車主已支付的泊車費用須被沒收。</li>
            //                 <li>預訂時段或本《特許協議》的終止不應影響：－
            //                     <ul>
            //                         <li>在預訂時段或本《特許協議》終止後繼續適用的任何條文，包括但不限於第10、12及14條；及</li>
            //                         <li>在本《特許協議》沒有被終止的情況下，管理人理應根據《平台條款及條件》在預訂時段的剩餘期限內獲得的服務費用。</li>
            //                     </ul>
            //                 </li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>雙方之間的爭議</strong>
            //             <ol>
            //                 <li>雙方同意，如果雙方就特許的任何條款產生任何爭議，須首先通過平台與另一方直接溝通來嘗試解決爭議。</li>
            //                 <li>雙方須通知管理人雙方達成的任何協議，管理人獲授權收取任何一方（如適用）的付款，以使該協議生效。</li>
            //                 <li>管理人可酌情決定就雙方的任何爭議進行調解，而業主與車主同意受管理人作出的任何事實決定所約束。</li>
            //                 <li>除非本《特許協議》中另有明確提及，否則任何一方均不得就因為特許或其任何條款而以任何方式引起（包括疏忽）的任何利潤損失、數據遺失、購買替代服務的費用，或任何相應而生、特殊、間接或懲罰性損害賠償，對另一方承擔任何責任。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>第三方的權利</strong>
            //             <ol>
            //                 <li>雙方同意，第3、8.5和10.3條旨在向管理人提供利益，而管理人可根據《合同（第三方權利）條例》（香港法例第623章）針對一方或雙方強制執行第8.3、8.5及／或10.3條。</li>
            //                 <li>除第1條明確提及外，除雙方以外的任何人均不享有本《特許協議》下的任何權利，並且本《特許協議》也不得由雙方以外的任何人根據《合同（第三方權利）條例》強制執行。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>通訊</strong>
            //             <ol>
            //                 <li>除非本《特許協議》另有規定，否則業主與車主之間的任何通信均須通過平台進行，不得直接在平台之外進行。</li>
            //                 <li>任何一方通過平台向另一方發出的任何通知，在通過平台發出時須被視為已被另一方收到。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>其他</strong>
            //             <ol>
            //                 <li>如果本《特許協議》的任何條款在任何方面在任何法律下屬於或成為無效、非法或不可強制執行，其餘條款的有效性、合法性和可強制執行性不得以任何方式受到影響或受到損害，其餘條款和條件仍須具有十足的效力和作用。</li>
            //                 <li>在本《特許協議》中明確列出的有關一方的違約行為的任何補救措施或權利，須不影響並附加於非違約方根據法律或本《特許協議》的其他部分享有的其他權利或補救措施。</li>
            //                 <li>雙方在本《特許協議》下的權利和責任不能轉讓給任何其他人。</li>
            //                 <li>任何一方如果對另一方違反本《特許協議》作出任何棄權，此棄權須僅在該情況適用並為該目的適用，並且任何一方沒有或延遲行使或強制執行本《特許協議》下的任何權利，不得被視作棄權。</li>
            //                 <li>如果本《特許協議》的中英文版本有任何不符，概以英文版本為準。</li>
            //             </ol>
            //         </li>
            //
            //         <li><strong>管限法律</strong>
            //             <ol>
            //                 <li>本《特許協議》受香港法律管限並按其解釋。</li>
            //                 <li>雙方同意服從香港法院的非專屬管轄權。</li>
            //             </ol>
            //        </li>
            //    </ol>
            // </div>*
        );
    }
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default LicenseAgreement_zh_HK;
