import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import Slider from "react-slick";

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as InviteeActionCreators from '../actions/invitee';

import data from '../../data/homeSlider';

import {getWhatshot} from "../../utils/04Api/Whatshot";
import {getCustomerSaid} from "../../utils/04Api/Customersaid";
import {getUIContent} from "../../utils/04Api/UIContent";
import OurPartners from "./OurPartners";

class HomeMiddle extends Component {
    constructor(props) {
        super(props);

        this.state = {
            whatsHot: null,
            customerSaid: null,
            benefits: {
                'en-US': {
                    parker: [],
                    owner: [],
                    common: []

                },
                'zh-HK': {
                    parker: [],
                    owner: [],
                    common: []
                }
            },
            howItWorks: {
                videoId: null,
            },
        }

        // react slick settings
        this.settingsHot = {
            arrows: false,
            dots: true,
            dotsClass: "slick-dots slick-thumb",
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        this.settingsCustomer = {
            arrows: true,
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1,
            rtl: true
        };

        this.data = data;
    }

    componentDidMount() {

        this.ApiGetWhatsHot(this.ApiGetWhatsHot);

        this.ApiGetCustomerSaid(this.ApiGetCustomerSaid);

        this.ApiGetHowItWorks(this.ApiGetHowItWorks);

        this.ApiGetBenefits(this.ApiGetBenefits);
    }

    ApiGetWhatsHot = (errorCallBack) => {

        let callback = (res) => {

            if (res.status === 200) {

                this.setState({
                    ...this.state,
                    whatsHot: res.body
                });
            }
        }

        getWhatshot(callback, errorCallBack);
    }

    ApiGetCustomerSaid = (errorCallBack) => {

        let callback = (res) => {

            if (res.status === 200) {

                this.setState({
                    ...this.state,
                    customerSaid: res.body
                });
            }
        }

        getCustomerSaid(callback, errorCallBack);
    }

    ApiGetBenefits = (errorCallBack) => {

        let callback = (res) => {

            if (res.status === 200) {

                // console.log(res.body);

                let benefits = {
                    'en-US': {
                        parker: [],
                        owner: [],
                        common: []

                    },
                    'zh-HK': {
                        parker: [],
                        owner: [],
                        common: []
                    }
                };

                Object.keys(res.body).forEach(key => {

                    let row_data = res.body[key];

                    benefits[row_data.lang][row_data.data.benefit_type].push(row_data);
                });

                Object.keys(benefits).forEach(key => {

                    let benefits_lang = key;

                    let row_data_lang = benefits[key];

                    Object.keys(row_data_lang).forEach(key => {

                        let benefits_type = key;

                        let row_data_type = row_data_lang[key];

                        row_data_type.sort((a, b) => a.data.priority - b.data.priority);

                        benefits[benefits_lang][benefits_type] = row_data_type;
                    });
                });

                this.setState({
                    ...this.state,
                    benefits: benefits,
                });
            }
        }

        getUIContent("benefit", callback, errorCallBack);
    }

    ApiGetHowItWorks = (errorCallBack) => {

        let callback = (res) => {

            if (res.status === 200) {

                let en_video_id = '', tc_video_id = '';

                Object.keys(res.body).forEach(key => {

                    let row_data = res.body[key];

                    if (row_data.lang === 'en-US' && en_video_id === '' && row_data.data.status === 1) {

                        en_video_id = row_data.data.video;
                    } else if (row_data.lang === 'zh-HK' && tc_video_id === '' && row_data.data.status === 1) {

                        tc_video_id = row_data.data.video;
                    }
                });

                this.setState({
                    ...this.state,
                    howItWorks: {
                        videoId: {
                            "en-US": en_video_id,
                            "zh-HK": tc_video_id,
                        },
                    }
                });
            }
        }

        getUIContent("home_video", callback, errorCallBack);
    }

    render() {
        const {t, i18n} = this.props;

        // console.log(this.state.benefits);

        return (
            <div className="wrapper-homeMiddle">
                <div className="wrapper-one">
                    <div className="one clearfix">
                        <div>
                            {this.state.benefits[i18n.language].parker &&
                            <div className="wrapper-benefitsOf">

                                <h3>{t("benefits:BenefitsofParkers.title-1")}
                                    <div className="sep-0"/>
                                    {t("benefits:BenefitsofParkers.title-2")}</h3>
                                <ul className="benefitsOf clearfix">
                                    {(this.state.benefits[i18n.language].parker).slice(0, 3).map((value, key) => {

                                        return (
                                            <div className="oneThird" key={key}>
                                                <li>
                                                    <img className="icon" src={value.data.image} alt=""/>
                                                    <h5>{value.data.title}</h5>
                                                    <p>{value.data.desc}</p>
                                                </li>
                                            </div>
                                        );
                                    })}
                                    <div className="full">
                                        <li>
                                            <Link to={"/" + i18n.language + "/benefits"} className="learnMore">
                                                {t("homepage:learnMore")}
                                            </Link>
                                        </li>
                                    </div>
                                </ul>
                            </div>
                            }

                            {this.state.benefits[i18n.language].owner &&
                            <div className="wrapper-benefitsOf">
                                <h3>{t("benefits:BenefitsofOwners.title-1")}
                                    <div className="sep-0"/>
                                    {t("benefits:BenefitsofOwners.title-2")}</h3>
                                <ul className="benefitsOf clearfix">
                                    {(this.state.benefits[i18n.language].owner).slice(0, 3).map((value, key) => {

                                        return (
                                            <div className="oneThird" key={key}>
                                                <li>
                                                    <img className="icon" src={value.data.image} alt=""/>
                                                    <h5>{value.data.title}</h5>
                                                    <p>{value.data.desc}</p>
                                                </li>
                                            </div>
                                        );
                                    })}
                                    <div className="full">
                                        <li>
                                            <Link to={"/" + i18n.language + "/benefits"} className="learnMore">
                                                {t("homepage:learnMore")}
                                            </Link>
                                        </li>
                                    </div>
                                </ul>
                            </div>
                            }

                            <OurPartners/>

                            {(this.state.howItWorks.videoId && this.state.howItWorks.videoId[i18n.language]) &&
                            <div className="wrapper-howItWorks">
                                <h3 className="title">{t("homepage:howItWorks.title1")}
                                    <div className="sep-0"/>
                                    {t("homepage:howItWorks.title2")}
                                </h3>
                                <div className="howItWorks">
                                    {/*<img src={require('../../images/m_homepage_how.jpg')} alt="" className="android"/>*/}
                                    <iframe className="youtubeFrame" title="youtubeFrame"
                                            src={"https://www.youtube.com/embed/" + this.state.howItWorks.videoId[i18n.language] + "?controls=0"}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen></iframe>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="wrapper-two">
                    <div className="two clearfix">
                        <div>
                            <div className="wrapper-whatsHot align-right">
                                <h3 className="color-white">{t("homepage:whatHot.title1")}
                                    <div className="sep-0"/>
                                    {t("homepage:whatHot.title2")}
                                </h3>
                                <div className="whatsHot align-center">
                                    {this.state.whatsHot !== null &&
                                    <Slider {...this.settingsHot}>
                                        {Object.keys(this.state.whatsHot).map(key => {

                                            let data = this.state.whatsHot[key];

                                            if (data.lang === null || data.lang === (i18n.language).toString().toLowerCase()) {
                                                return (
                                                    <div key={key}>
                                                        <a target={data.target} href={data.link}>
                                                            <img
                                                                src={data.image}
                                                                alt={data.name}/>
                                                        </a>
                                                    </div>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </Slider>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrapper-three">
                    <div className="three clearfix">
                        <div>
                            <div className="wrapper-ourCustomers align-right">
                                <h3 className="color-white">{t("homepage:customersSay.title1")}
                                    <div className="sep-0"/>
                                    {t("homepage:customersSay.title2")}
                                </h3>
                                <div className="ourCustomers align-center">
                                    {this.state.customerSaid !== null &&
                                    <Slider {...this.settingsCustomer}>
                                        {Object.keys(this.state.customerSaid).map(key => {

                                            let data = this.state.customerSaid[key];

                                            if (data.lang === null || data.lang === (i18n.language).toString().toLowerCase()) {

                                                return (
                                                    <div key={key}>
                                                        <img
                                                            src={data.image}
                                                            alt=""/>
                                                        <h4>{data.name}</h4>
                                                        <p>{data.content}</p>
                                                        <h5>{data.location}</h5>
                                                    </div>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </Slider>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(HomeMiddle);
