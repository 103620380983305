import React, {Component} from 'react';

class ParkerProtectionGuarantee_zh_HK extends Component {
    render() {
        return (
            <div className="cancellation" dangerouslySetInnerHTML={{__html: this.props.content}}/>
            // <div className="cancellation">
            //     <p>每個預訂均享有100％泊車費用退還之「泊車保障政策」。車主需於預訂時段開始後的48小時內通知我們有關事宜，例如 :</p>
            //     <ul>
            //         <li>你預訂的車位與平台上的描術不符，導致實質上你無法使用該泊車位，或</li>
            //  {/*<li>在預訂時段的日期和時間，泊車位無法使用。</li>*/}
            // {/*    </ul>*/}
            // {/*    <p>請電郵至<a style={{"textDecoration":"underline"}} href="mailto:info@letspark.com.hk">info@letspark.com.hk</a>向我們詳細描述問題，並提供相片證據以協助調查。詳情請參閱特許協議。</p>*/}
            // {/*</div>*/}
        );
    }
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default ParkerProtectionGuarantee_zh_HK;
