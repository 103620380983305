import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {getTnc} from "../../utils/04Api/Tnc";

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as InviteeActionCreators from '../actions/invitee';

class Discover extends Component {

    constructor(props) {
        super(props);

        this.state = {
            discover: null,
        }
    }

    componentDidMount() {

        this.ApiGetDiscover(this.ApiGetDiscover);
    }

    ApiGetDiscover = (errorCallback) => {

        let callback = (res) => {

            if (res.status === 200) {

                this.setState({
                    ...this.state,
                    discover: res.body
                })
            }
        }

        getTnc('discover', callback, errorCallback);
    }

    render() {

        const {
            t,
            i18n,
        } = this.props;

        // console.log(this.state.discover);

        return (
            <div className="wrapper-container discover">

                <div className="page-title"><h2>{t('discover:title')}</h2></div>

                <div className="sep-0"/>

                <div className="containerMain">
                    <div className="content clearfix">

                        <div className="sep-20"/>

                        <div className="thirty right">
                            <div style={{padding: "0 1rem"}}>
                                <img src={require('../../images/mobile/General/dummy-img/img_milestone2.jpg')} alt=""/>

                                <div className="sep-20"/>

                                <img src={require('../../images/mobile/General/menu-and-footer/logo_letspark.png')}
                                     alt=""
                                     className="original"/>

                                <div className="sep-20"/>
                            </div>
                        </div>

                        <div className="seventy left">
                            {this.state.discover != null &&
                            <div>
                                {i18n.language === 'en-US' &&
                                <div
                                    dangerouslySetInnerHTML={{__html: this.state.discover['en-us'].discover_content_eng.value}}/>
                                    // <div>
                                    //     <p>We are a team of passionate people who have rallied around a shared mission to make
                                    //         parking more efficient and seamless in the communities. Our novel digital parking
                                    //         solutions meant to take forward Hong Kong&rsquo;s smart mobility journey through
                                    //         improving use of limited parking real estate, increasing parking convenience, and
                                    //         raising additional revenue.</p>
                                    //     <p>At Let&rsquo;s Park, we believe that parking should be much easier. Here in Hong Kong
                                    //         if you want to look for a parking space, you probably need to navigate across
                                    //         different applications, online pre-booking most of the time is not available. Think
                                    //         of last time how you booked your hotel, or even ordered your groceries, it probably
                                    //         take just a few taps on your mobile phone. Parking in Hong Kong is time for a
                                    //         boost.</p>
                                    //     <p>Let&rsquo;s Park is here to cultivate a new digital parking lifestyle for maximum
                                    //         utilization of the parking resources, by connecting space owners to parkers and move
                                    //         towards a new economy of potential.</p>
                                    //     <p>At Let&rsquo;s Park, we believe Parkers should be able to search and book a parking
                                    //         space easier, be it hourly, monthly or our new signature <em>Timeshare</em> mode -
                                    //         bnb of parking spaces. Our marketplace adopts a parker-centered strategy to ease the
                                    //         congestion and stress involved in searching and paying for parking and helping
                                    //         Parkers identify the most economical and time-efficient parking options.</p>
                                    //     <p>Let&rsquo;s Park combine technology and human innovation in an effort to empower
                                    //         space Owners to license their idle resources to the community, to achieve faster,
                                    //         easier and denser parking of vehicles, to realize the full value of your asset with
                                    //         new source of income streams. Our intelligent solutions cover the needs of all sorts
                                    //         of ownerships, from strata-title to commercial car park operators, public or private
                                    //         bodies.</p>
                                    //     <p>Shortage of parking spaces is getting worse &ndash; circuling around the blocks,
                                    //         mid-night queueing for securing a monthly spot in some areas is no new news to Hong
                                    //         Kong drivers. Our solutions emphasized on strong community collaboration will give
                                    //         an immediate boost to a brand new supply of parking spaces. Act now, join our
                                    //         Let&rsquo;s Park community!</p>
                                    // </div>
                                }

                                {i18n.language === 'zh-HK' &&
                                <div
                                    dangerouslySetInnerHTML={{__html: this.state.discover['zh-hk'].discover_content_chi.value}}/>
                                    // <div>
                                    //     <p>我們是一個充滿創意的團隊，喜歡嘗試新的事物。Let&rsquo;s Park是一個嶄新的電子泊車平台，提倡全新共享概念「車位矜貴
                                    //         吾好浪費亅，為香港車主及業主發掘閒置的泊車資源，提升車位回報，與大家一起開展香港智慧出行的新旅程。</p>
                                    // //     <p>泊車應該是一件簡單輕鬆的事情！目前於香港，假如您想尋找泊車資訊，難免要瀏覽多個不同的應用程序，當中更絕少提供網上預約服務。試想上一次你如何預訂酒店，或是訂購日常用品，大概都只需透過手機平台作幾個簡單步驟即可，香港的泊車服務亦理應如此簡單方便。現在起，無論是傳統的時租月租，還是我們全新推出的Timeshare共享車位方案，Let&rsquo;s
                                    // {/*        Park都能夠幫助車主輕鬆找到最合乎成本效益的泊車服務；由搜尋、預訂到付款，安全可靠地照顧每一步。</p>*/}
                                    // {/*    <p>結合科技與創新的意念，業主可以透過Let&rsquo;s*/}
                                    // {/*        Park將閒置的車位資源公佈予網上社區，讓有需要的車主預約使用，善用資產賺取新的收入。無論是公共或私人機構，個人或公司的車位業權，我們都能一一照顧。</p>*/}
                                    // {/*    <p>車位短缺的問題日益嚴重，多年來車主每天都面對重覆的問題 - 塞車，昂貴的泊車費用，甚或至有時一位難求，需在半夜時分出動排隊輪候月租車位。</p>*/}
                                    // {/*    <p>Let&rsquo;s Park全新智慧出行平台強調社區各持份者携手合作，有效率地連接業主及車主，善用區内車位資源，一同邁進智慧泊車新時代。</p>*/}
                                    // {/*    <p>立即行動，成為Let&rsquo;s Park新社區的一份子！</p>*/}
                                    // {/*</div>*/}
                                }
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default withTranslation()(Discover);
// connect(mapStateToProps)(About);