import {apiFetch, apiPost} from "./General";

export const updateBookingPayment = (accessToken, booking_id, payment_id, cb, ecb) => {
    let body;
    body = {
        payment_id: payment_id,
    }
    apiPost('booking/updateBookingPayment/' + booking_id + '?access_token=' + accessToken, body, cb, ecb);
}

export const bookASpace = (accessToken, sid, data, cb, ecb) => {
    let body;

    body = {
        vehicle_id: data.vehicleID,
        octcard_id: data.octcardID,
        from: data.startDate.utcOffset(8).format("YYYY-MM-DDTHH:mm"),
        to: data.endDate.utcOffset(8).format("YYYY-MM-DDTHH:mm"),
        type: data.type,
        tac: 1,
        payment_id: data.paymentID,
        bcode: {
            enabled: data.bcode || false,
            userAgent: 'web',
            earlyInTime: data.earlyInTime || 0,
            gracePeriod: data.gracePeriod || 0,
            preBookBlocker: data.preBookBlocker || 0,
            postBookBlocker: data.postBookBlocker || 0,
            overstayFine: data.overstayFine || 0,
            extendPrice: data.extendPrice || 0,
        }
    }

    if (typeof (data.calculation.promotion) !== "undefined") {
        body.promotion_id = data.calculation.promotion._id;
    }

    // if (data.type === "hourly") {
    // body = {
    //     vehicle_id: data.vehicleID,
    //     octcard_id: data.octcardID,
    //     from: data.startDate.utcOffset(8).format("YYYY-MM-DDTHH:mm"),
    //     to: data.endDate.utcOffset(8).format("YYYY-MM-DDTHH:mm"),
    //     // cost: data.total,
    //     type: data.type,
    //     tac: 1,
    //     payment_id: data.paymentID,
    //     // transactions: {
    //     //     status: "pending",
    //     //     amount: data.total,
    //     // }
    // }
    //
    // if (typeof(data.calculation.promotion) !== "undefined") {
    //     body.promotion_id = data.calculation.promotion._id;
    // }
    // } else {
    //     body = {
    //         vehicle_id: data.vehicleID,
    //         octcard_id: data.octcardID,
    //         from: data.startDate.utcOffset(8).format("YYYY-MM-DDTHH:mm"),
    //         to: data.endDate.utcOffset(8).format("YYYY-MM-DDTHH:mm"),
    //         // costfirst: data.calculation.cost.first,
    //         // cost: data.calculation.totalcost,
    //         type: data.type,
    //         tac: 1,
    //         // month: data.calculation.months,
    //         payment_id: data.paymentID,
    //         // transactions: {
    //         //     status: "pending",
    //         //     amount: data.total,
    //         // }
    //     }
    // }

    // console.log(body);
    // return;
    apiPost('booking/' + sid + '?access_token=' + accessToken, body, cb, ecb);
}

// export const updateBookingStatus = (accessToken, bid, status, callback, errorCallback) => {
//     const body = {
//         status: status,
//     }
//     apiPost("booking/updateStatus/" + bid + "?access_token=" + accessToken, body, callback, errorCallback);
// }

export const getBookingById = (accessToken, bid, cb, eCb) => {
    const params = {
        access_token: accessToken
    }
    apiFetch('booking/findById/' + bid, params, cb, eCb);
}

export const getInboxBookings = (access_token, booking_ids, callback, errorCallback) => {
    const data = {
        booking_ids: booking_ids
    };
    apiPost('booking/inbox/msg?access_token=' + access_token, data, callback, errorCallback);
}

export const updateBookingById = (accessToken, bid, body, cb, eCb) => {
    apiPost("booking/update/" + bid + "?access_token=" + accessToken, body, cb, eCb);
}

export const updateRegistrationMark = (accessToken, bid, carplate, cb, eCb) => {
    let body = {
        carplate: carplate
    }
    apiPost("booking/updateRegistrationMark/" + bid + "?access_token=" + accessToken, body, cb, eCb);
}

export const getMyBookings = (accessToken, cb, eCb) => {
    const params = {
        access_token: accessToken
    }
    apiFetch('booking', params, cb, eCb);
}

export const getSpaceBookings = (accessToken, space_id, cb, eCb) => {
    const params = {
        access_token: accessToken
    }
    apiFetch('booking/' + space_id, params, cb, eCb);
}

export const sendMsg = (data) => {
    const cb = (res) => {
        // console.log("sendMsg", res);
    }

    apiPost('web/sendMsg', {data: data}, cb, cb);
}

export const sendM8Email = (accessToken, spaceID, senderID, receiverID) => {
    const params = {
        access_token: accessToken,
        space_id: spaceID,
        sender_id: senderID,
        receiver_id: receiverID,
    }

    const cb = (res) => {

        console.log(senderID);
        console.log(receiverID);
    }

    const eCb = (res) => {
        console.log(res);
        console.log('M8Error');
    }

    apiFetch('web/sendM8Email', params, cb, eCb);
}

export const acceptBooking = (accessToken, bid, callback, errorCallback) => {
    const body = {}
    apiPost("booking/accept/" + bid + "?access_token=" + accessToken, body, callback, errorCallback);
}

export const rejectBooking = (accessToken, bid, callback, errorCallback) => {
    const body = {}
    apiPost("booking/reject/" + bid + "?access_token=" + accessToken, body, callback, errorCallback);
}
export const acceptBookerBooking = (accessToken, bid, callback, errorCallback) => {
    const body = {}
    apiPost("booking/acceptBooker/" + bid + "?access_token=" + accessToken, body, callback, errorCallback);
}

export const rejectBookerBooking = (accessToken, bid, callback, errorCallback) => {
    const body = {}
    apiPost("booking/rejectBooker/" + bid + "?access_token=" + accessToken, body, callback, errorCallback);
}

