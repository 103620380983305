import React, {Component} from 'react';
import {Redirect} from 'react-router';
import {Tabs, Tab} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';

import MemberMenuDesktop from '../../../components/02Members/MemberMenuDesktop';
import MemberMenuMobile from '../../../components/02Members/MemberMenuMobile';

import MemberRenewalService from "./MemberRenewalService";
import MemberRenewalInvoice from "./MemberRenewalInvoice";

import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
import * as UserApi from "../../../utils/04Api/User";
import * as MembersActionCreators from "../../../actions/members";
import * as RenewalApi from "../../../utils/04Api/Renewal";

import Cookies from 'universal-cookie';
import {withTokenLogin} from "../../../utils/02Members/useTokenLogin";
import {withRouter} from "react-router-dom";

const cookies = new Cookies();

// import * as MembersActionCreators from '../../actions/members';

class MemberRenewal extends Component {
    constructor(props) {
        super(props);

        let logined = this.props.members.Login.length !== 0;

        // console.log(this.props.members.Login);

        // DISPATCH TO ACTIONS
        const {dispatch} = props;
        this.updateMe = bindActionCreators(MembersActionCreators.updateMe, dispatch);

        this.state = {
            logined: logined,
            loading: true,
            TabInitDisplay: 1,
            me: null,
            merchants: [],
            merchant: null
        }

        this.getMe = this.getMe.bind(this);
    }

    componentDidMount = () => {
        if (this.state.logined) {
            // get updated user info
            this.getMe(this.setTabsDefault);
            this.getMerchants();
        }
    }

    goToLogin = () => {
        // console.log("gotologin");
        this.setState = ({
            ...this.state,
            logined: false
        })
    }

    getMe = (cb) => {
        if (typeof(cb) !== "function")
            cb = () => {};

        let callback = (res) => {
            if (typeof (res.body) !== "undefined") {
                let me = res.body;

                // update redux
                this.updateMe(me);

                // console.log('me', me);

                // update state
                this.setState({
                    ...this.state,
                    me: me,
                    loading: false,
                }, cb);

                // console.log('me', me);

            }
        }

        UserApi.getMe(this.props.members.Login.userToken, callback, this.goToLogin);
    }

    getMerchants () {
        const { t } = this.props;
        RenewalApi.getEnterpriseToken(cookies.get('aggregator_token'),
          (response) => {
              if (response.body.msg === 'success') {
                  cookies.set('enterprise_token', response.body.data.token, { path: '/'});
                  RenewalApi.getEnterpriseMerchants(response.body.data.token,
                    (response) => {
                        // console.log(response)
                        if (response.body.msg === 'success') {
                            let merchants = response.body.data.accounts.map((account) => account.merchant)
                            this.setState({merchants, merchant: merchants[0]})
                        } else {
                            alert(t("myAccount:tabRenewal.getMerchantError"))
                        }
                    })
              } else {
                  cookies.set('enterprise_token', '', { path: '/'});
              }
          }, (error) => {
              console.log(error)
          })
    }

    setMerchant = (e) => {
        let merchant = this.state.merchants.find((merchant) => merchant._id === e.target.value)
        this.setState({merchant});
    }

    setTabsDefault = () => {
        let keys = Object.keys(this.getUrlVars());
        for (let i = 0; i < keys.length; i++) {
            let key = (keys[i]);
            if (this.getUrlVars()[key].session === 'parkers') {
                this.setState({
                    TabInitDisplay: 1,
                });
                break;
            } else if (this.getUrlVars()[key].session === 'owners') {
                this.setState({
                    TabInitDisplay: 2,
                });
                break;
            } else {
                this.setState({
                    TabInitDisplay: 1,
                });
            }
        }
    }

    getUrlVars = () => {
        let vars = [];
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            let each = {};
            each[key] = value;
            vars.push(each);
        });
        return vars;
    }

    handleTabSelect = (data) => {
        this.setState({
            TabInitDisplay: data
        })
    }

    render() {
        const {
            t,
            i18n,
            params,
        } = this.props;

        if (!this.state.logined && !(params && params.token))
            return <Redirect to={"/" + i18n.language + "/login"} />;

        if (this.state.loading) {
            return (
                <div className="wrapper-container memberRenewal">
                    <MemberMenuMobile/>
                    <div className="containerMain">
                        <MemberMenuDesktop/>

                        <div className="content clearfix">
                            <div className="loading">{t("common:Loading")}</div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="wrapper-container memberRenewal">
                <MemberMenuMobile/>
                <div className="containerMain">
                    <MemberMenuDesktop/>

                    <div className="content clearfix">
                        <div className="wrapper-tabs clearfix">
                            {
                                this.state.merchants.length > 0 ?
                                    <div style={{display: "inline-block", alignItems: 'center', marginBottom: 20}}>
                                        <h4 style={{margin: "0 0 20px 0", float: "left"}}>{t("myAccount:tabRenewal.merchant")}</h4>
                                        <select
                                            onChange={this.setMerchant}
                                            style={{float: "left"}}
                                        >
                                            {
                                                this.state.merchants.map((m) => {
                                                    return (
                                                        <option value={m._id}>
                                                            {i18n.language === 'en-US' ? m.title : m.title_tc}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div> : null
                            }
                            <Tabs defaultActiveKey={this.state.TabInitDisplay} onSelect={(e) => this.handleTabSelect(e)}
                                  id="uncontrolled-tab-example">
                                <Tab eventKey={1} title={t("myAccount:tabRenewal.service.title")}>
                                    <MemberRenewalService merchant={this.state.merchant}/>
                                </Tab>
                                <Tab eventKey={2} title={t("myAccount:tabRenewal.invoice.title")}>
                                    <MemberRenewalInvoice merchant={this.state.merchant}/>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => (
    {
        members: state.members
    }
);

export default withTranslation()(connect(mapStateToProps)(withRouter(withTokenLogin(MemberRenewal))));
