import React from 'react';
import { withTranslation } from 'react-i18next';

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as InviteeActionCreators from '../actions/invitee';

function About(props) {
	const {
		// t,
		i18n
	} = props;

	return (
		<div className="wrapper-container contact">

			<div className="page-title"><h2>{i18n.t("about.title")}</h2></div>

			<div className="sep-0" />

			<div className="containerMain">

				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>

				<div className="content clearfix">
					Hello
				</div>
			</div>
		</div>
	);
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default withTranslation()(About);
// export default connect(mapStateToProps)(About);
