export const TO_LOGIN = 'members/TO_LOGIN';
export const TO_REGISTER = 'members/TO_REGISTER';
export const MobileVerification = 'members/MobileVerification';
export const ResetRegistration = 'members/ResetRegistration';
export const TO_LOGOUT = 'members/TO_LOGOUT';

export const TOGGLE_WISHLIST = 'member/TOGGLE_WISHLIST';
export const UPDATE_WISHLIST = 'member/UPDATE_WISHLIST';

export const UPDATE_ME = 'member/UPDATE_ME';
