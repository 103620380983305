import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

class MemberMenuMobile extends Component {

    componentDidMount() {
        $(function () {
            $('.scroll').scrollLeft(9999);
        })
    }

    render() {
        const {
            t,
            i18n
        } = this.props;

        let urlFull = this.props.route.location.pathname;
        let urlArray = urlFull.split("/");
        // console.log(urlArray[2]);

        return (
            <div className={"wrapperMemberMenuMobile " + (
                urlArray[2] === "memberProfile"
                    || urlArray[2] === "memberCredit"
                    || urlArray[2] === "settings"
                    ? 'scroll' : '')}>
                <ul className="memberMenuMobile clearfix">
                    <li>
                        <Link to={"/" + i18n.language + "/memberWishlist"} className={(urlArray[2] === "memberWishlist") ? 'active' : ''}>
                            <span className="wishlist"></span>
                            <div className="sep-0" />
                            <h5>{t("myAccount:tabWishList.title")}</h5>
                        </Link>
                    </li>
                    <li>
                        <Link to={"/" + i18n.language + "/memberBookings"} className={(urlArray[2] === "memberBookings") ? 'active' : ''}>
                            <span className="bookings"></span>
                            <div className="sep-0" />
                            <h5>{t("myAccount:tabBookings.title")}</h5>
                        </Link>
                    </li>
                    <li>
                        <Link to={"/" + i18n.language + "/memberListedSpaces"} className={(urlArray[2] === "memberListedSpaces") ? 'active' : ''}>
                            <span className="listings"></span>
                            <div className="sep-0" />
                            <h5>{t("myAccount:tabListings.title")}</h5>
                        </Link>
                    </li>
                    {/*<li>*/}
                    {/*    <Link to={"/" + i18n.language + "/memberHistory"} className={(urlArray[2] === "memberHistory") ? 'active' : ''}>*/}
                    {/*        <span className="history"></span>*/}
                    {/*        <div className="sep-0" />*/}
                    {/*        <h5>History</h5>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    <li>
                        <Link to={"/" + i18n.language + "/memberProfile"} className={(urlArray[2] === "memberProfile") ? 'active' : ''}>
                            <span className="profile"></span>
                            <div className="sep-0" />
                            <h5>{t("myAccount:tabProfile.title")}</h5>
                        </Link>
                    </li>
                    {/*<li>*/}
                    {/*    <Link to={"/" + i18n.language + "/memberCredit"} className={(urlArray[2] === "memberCredit") ? 'active' : ''}>*/}
                    {/*        <span className="credit"></span>*/}
                    {/*        <div className="sep-0" />*/}
                    {/*        <h5>Credit</h5>*/}
                    {/*    </Link>*/}
                    {/*</li >*/}
                    <li>
                        <Link to={"/" + i18n.language + "/memberRenewal"} className={(urlArray[2] === "memberRenewal") ? 'active' : ''}>
                            <span className="renewal"></span>
                            <div className="sep-0" />
                            <h5>{t("myAccount:tabRenewal.title")}</h5>
                        </Link>
                    </li>
                    <li>
                        <Link to={"/" + i18n.language + "/settings"} className={(urlArray[2] === "settings") ? 'active' : ''}>
                            <span className="setting"></span>
                            <div className="sep-0" />
                            <h5>{t("myAccount:tabSettings.title")}</h5>
                        </Link>
                    </li>
                </ul>
            </div>
        )
    }
}

const mapStateToProps = (state) => (
    {
        route: state.router,
    }
);

export default withTranslation()(connect(mapStateToProps)(MemberMenuMobile));