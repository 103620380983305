import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Redirect} from 'react-router';
import {withTranslation} from 'react-i18next';

import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';

// import * as Config from '../../../config';
// import * as Helper from '../../utils/02Members/Validations';
import ErrorMessage from '../../../components/103Parts/ErrorMessage';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as MembersActionCreators from "../../../actions/members";
import * as UserApi from "../../../utils/04Api/User";
import * as WishListApi from "../../../utils/04Api/WishList";
import * as Config from "../../../config";
import {SECRET, WIL_REGISTER_URL} from "../../../config";

import Cookies from 'universal-cookie';
import ReactPixel from "react-facebook-pixel";
import * as RenewalApi from "../../../utils/04Api/Renewal";
import {parseUrl} from "../../../utils/00General/Helpers";

const cookies = new Cookies();

class MemberLogin extends Component {
    constructor(props) {
        super(props);

        const {dispatch} = props;

        // DISPATCH TO ACTIONS
        this.toLogin = bindActionCreators(MembersActionCreators.toLogin, dispatch);

        let logined = this.props.members.Login.length !== 0;

        this.state = {
            logined: logined,
            ref: typeof (props.params) !== "undefined" && props.params !== null && typeof (props.params.ref) !== "undefined" ? props.params.ref : null,
            type: "email",
            errors: null,
            smsDelay: 0,
            WishList: []
        }
    }

    componentWillMount() {
        const cookies = new Cookies();

        if (this.state.ref === 'fb' || this.state.ref === 'google') {

            let token = cookies.get('token');

            UserApi.getMe(token, (obj) => {

                if (obj.status === 200) {
                    const postData = {
                        userToken: token,
                        userId: obj.body._id,
                        user: obj.body,
                        userWishList: []
                    }

                    WishListApi.getWishList(token, (obj) => {
                        if (obj.status === 200) {
                            obj.body.map((record, index) => {
                                postData['userWishList'].push(record.space._id);
                                return null;
                            });

                            // console.log(postData);

                            this.toLogin(postData);
                        }
                    })
                }
            });
        }
    }

    componentDidMount() {
        ReactPixel.trackCustom('Login');
        const { route, i18n } = this.props;
        const { location } = route || {};
        const { pathname = "" } = location || {};
        const lang = pathname.split('/')[1];
        if (lang === "zh-hk") i18n.changeLanguage('zh-HK');
    }

    switchType = () => {
        const type = this.state.type === 'email' ? 'phone' : 'email';
        this.setState({
            type: type
        })
    }

    handleSMSCountdown = () => {
        // Set countdown to 120
        this.setState({
            ...this.state,
            smsDelay: 120,
        }, () => {
            // start count down
            const countdown = () => {
                setTimeout(() => {
                    let smsDelay = this.state.smsDelay - 1;
                    this.setState({
                        ...this.state,
                        smsDelay: smsDelay
                    }, () => {
                        if (this.state.smsDelay > 0)
                            countdown();
                    })
                }, 1000)
            }
            countdown();
        })
    }

    handleSMS = (values, setErrors, setFieldTouched) => {
        const {t} = this.props;

        if (this.state.smsDelay === 0) {
            const yupPhone = Yup.object().shape({
                phone: Yup.string()
                    .required()
                    .matches(/^\d{8,20}$/),
            })

            yupPhone.isValid({
                phone: values.phone,
            }).then((valid) => {
                if (valid) {
                    // SEND VERIFICATION CODE
                    const cb = (obj) => {
                        if (obj.status === 200) {
                            // console.log(obj.body.token.token);
                            this.handleSMSCountdown();
                        } else if (obj.status === 400) {

                            if (obj.body.msg === '10 times sms limit reach, you will be blocked for 24 hours' || obj.msg === 'You IP blocked for 24 hours') {
                                setFieldTouched("token", true)
                                setErrors({phone: t("login:errors.smsLimitation")})
                            }
                        } else {
                            // console.log("smsLoginGetToken success", obj.body);
                        }
                    }
                    const eCb = (obj) => {
                        // console.log("smsLoginGetToken failed", obj);
                    }
                    UserApi.smsLoginGetToken(values.phone, cb, eCb);
                } else {
                    setFieldTouched("phone", true)
                    setErrors({phone: t("Please enter a valid Mobile Number")})
                }
            });
        }
    }

    emailForm = ({values, errors, touched, handleChange}) => {
        const {t, i18n} = this.props;

        return (
            <Form>
                <div className="sep-25"/>

                <div>
                    <dt/>
                    <dd>
                        <Field name="email" type="email" placeholder={t("login:email")} maxLength="100"/>
                        {errors.email && touched.email ? <ErrorMessage message={errors.email}/> : null}
                    </dd>
                </div>

                <div className="sep-25"/>

                <div>
                    <dt/>
                    <dd>
                        <Field name="password" type="password" placeholder={t("login:password")} maxLength="30"/>
                        {errors.password && touched.password ? <ErrorMessage message={errors.password}/> : null}
                    </dd>
                </div>

                <div className="sep-10"/>

                <div>
                    <dt/>
                    <dd className="align-right">
                        <Link to={"/" + i18n.language + '/memberForgetPassword'}>{t("login:forgotPassword")}</Link>
                    </dd>
                </div>

                <div className="sep-25"/>

                <div>
                    <dt/>
                    <dd>
                        <div className="checkboxFive">
                            <div>
                                <input type="checkbox" id="rememberMe" onChange={handleChange}
                                       checked={values.rememberMe}/>
                                <label htmlFor="rememberMe"/>
                            </div>
                            <label htmlFor="rememberMe">{t("login:rememberMe")}</label>
                        </div>
                        {errors.rememberMe && touched.rememberMe ? <ErrorMessage message={errors.rememberMe}/> : null}
                    </dd>
                </div>


                <div className="sep-25"/>

                <div>
                    {errors.emailGeneral ? <ErrorMessage message={errors.emailGeneral}/> : null}
                </div>

                <div className="sep-25"/>

                <div className="full left align-center">
                    <div>
                        <button type="submit" className="button">{t("login:login")}</button>
                        <div className="sep-10"/>
                        <Link to={"/" + i18n.language + "/register"}>{t("login:dontHaveAccount")}</Link>
                    </div>
                </div>
            </Form>
        )
    }

    phoneForm = ({values, errors, touched, setErrors, setFieldTouched}) => {
        const {t, i18n} = this.props;

        return (
            <Form>
                <div className="sep-25"/>

                <div>
                    <dt/>
                    <dd>
                        <Field name="phone" type="text" placeholder={t("login:phone")} maxLength="20"/>
                        {errors.phone && touched.phone ? <ErrorMessage message={errors.phone}/> : null}
                    </dd>
                </div>

                <div className="sep-25"/>

                <div>
                    <dt/>
                    <dd>
                        <Field name="token" type="text" placeholder={t("login:verificationCode")} maxLength="6"/>
                        {errors.token && touched.token ? <ErrorMessage message={errors.token}/> : null}
                    </dd>
                </div>

                <div className="sep-25"/>

                <div>
                    <dt/>
                    <dd className="align-center">
                        <div>
                            {t("login:sendVerificationCode")}
                            &nbsp;
                            <button type="button" className="button" onClick={() => {
                                this.handleSMS(values, setErrors, setFieldTouched);
                            }}>{t(this.state.smsDelay > 0 ? t("login:resend") + this.state.smsDelay + t("login:second") : t("login:send"))}</button>
                        </div>
                    </dd>
                </div>


                <div className="sep-25"/>

                <div>
                    {errors.phoneGeneral ? <ErrorMessage message={errors.phoneGeneral}/> : null}
                </div>

                <div className="sep-25"/>

                <div className="full left align-center">
                    <div>
                        <button type="submit" className="button">{t("login:login")}</button>
                        <div className="sep-10"/>
                        <Link to={"/" + i18n.language + "/register"}>{t("login:dontHaveAccount")}</Link>
                    </div>
                </div>
            </Form>
        )
    }

    getRedirectUrl = (token) => {
        const { params, i18n } = this.props;
        const { from } = params || {};
        const lang = i18n.language.toLowerCase() === 'zh-hk' ? 'zh' : 'en';
        const url = `${WIL_REGISTER_URL}&lang=${lang}`;
        if (from === "wil") return parseUrl(url, {token, timestamp: Date.now()}, SECRET)
        return null;
    }

    loginSuccessCallback = (obj, setFieldError, errName) => {
        const token = obj.body.token;

        UserApi.getMe(token, (obj) => {

            if (obj.status === 200) {
                const postData = {
                    userToken: token,
                    userId: obj.body._id,
                    user: obj.body,
                    userWishList: []
                }

                WishListApi.getWishList(token, (obj) => {
                    if (obj.status === 200) {
                        obj.body.map((record, index) => {
                            postData['userWishList'].push(record.space._id);
                            return null;
                        })

                        // console.log(postData);

                        this.toLogin(postData);

                    } else {
                        setFieldError(errName, obj.body.msg);
                    }
                })

                RenewalApi.getEnterpriseToken(token,
                  (response) => {
                      if (response.body.msg === 'success') {
                          cookies.set('enterprise_token', response.body.data.token, { path: '/'});
                          cookies.set('aggregator_token', token, { path: '/' });
                      }
                  }, (error) => {
                      console.log(error)
                  })

                const redirectUrl = this.getRedirectUrl(token);
                if (redirectUrl) window.location.href = redirectUrl;

            } else {
                setFieldError(errName, obj.body.msg);
            }
        });
    }

    handleEmailSubmit = (values, setFieldError) => {
        const callback = (obj) => {
            if (obj.status === 200) {
                this.loginSuccessCallback(obj, setFieldError, 'emailGeneral');
            } else if (obj.status === 400) {
                setFieldError('emailGeneral', this.props.t("login:errors.invalidEmailPassword"));
            }
        }

        UserApi.emailLogin(values.email, values.password, callback);
    }

    handlePhoneSubmit = (values, setFieldError) => {
        const callback = (obj) => {
            if (obj.status === 200) {
                this.loginSuccessCallback(obj, setFieldError, 'phoneGeneral');
            } else if (obj.status === 400) {
                setFieldError('phoneGeneral', this.props.t("login:errors.invalidMobilePassword"));
            }
        }
        UserApi.smsLoginValidate(values.phone, values.token, callback);
    }

    handleSubmit = (values, {setFieldError}) => {
        if (this.state.type === "email")
            this.handleEmailSubmit(values, setFieldError);
        else
            this.handlePhoneSubmit(values, setFieldError);
    }

    schema = () => {
        // Form Validation
        let yupShape;
        if (this.state.type === "email") {
            yupShape = {
                email: Yup.string()
                    .required('login:errors.requireEmail')
                    .email('login:errors.invalidEmail'),
                password: Yup.string()
                    .required('login:errors.requirePassword'),
            }
        } else {
            yupShape = {
                phone: Yup.string()
                    .required('login:errors.requireMobile')
                    .min(8, 'login:errors.invalidMobile'),
                token: Yup.string()
                    .required('login:errors.requireCode')
                    .length(6, 'login:errors.invalidCode'),
            }
        }

        return Yup.object().shape(yupShape);
    }

    render() {
        const {
            t,
            i18n
        } = this.props;

        const token = this.props.members.Login.userToken;
        const redirectUrl = this.getRedirectUrl(token);
        if (redirectUrl && token) {
            window.location.href = redirectUrl;
            return;
        }

        if (this.state.logined)
            return <Redirect to={"/" + i18n.language + "/"}/>;

        if (this.props.members.Login && this.props.members.Login.length !== 0) {
            //return <Redirect to={"/" + i18n.language + "/stepOne"} />;
            return <Redirect to={"/" + i18n.language + ""}/>;
        }


        return (
            <div className="wrapper-container memberLogin padding">
                <div className="containerMain borderTop">
                    <div className="content clearfix">
                        <div className="full left align-center">
                            <span className="door"/>
                            <h2>{t("login:login")}</h2>
                        </div>

                        <div className="sep-20"/>

                        <dl className="generalForm clearfix">
                            <div>
                                <dt/>
                                <dd>
                                    <a href={Config.API_URL + "auth/facebook"} className="button facebook">
                                        <div className="register_icon">
                                            <img
                                                src={require('../../../images/mobile/02_membership/00_signin/facebook.png')}
                                                alt=""/>
                                        </div>
                                        {t("login:loginFacebook")}
                                    </a>
                                </dd>
                            </div>

                            <div className="sep-25"/>

                            <div>
                                <dt/>
                                <dd>
                                    <a href={Config.API_URL + "auth/google"} className="button googlePlus">
                                        <div className="register_icon">
                                            <img
                                                src={require('../../../images/mobile/02_membership/00_signin/google.png')}
                                                alt=""/>
                                        </div>
                                        {t("login:loginGoogle")}
                                    </a>
                                </dd>
                            </div>

                            {this.state.type === 'email' &&
                            <div>
                                <div className="sep-25"/>
                                <div className="button registerWithEmail" onClick={this.switchType}>
                                    <div className="register_icon">
                                        <img
                                            src={require('../../../images/mobile/02_membership/00_signin/mobile.png')}
                                            alt=""/>
                                    </div>
                                    {t("login:loginPhone")}
                                </div>
                            </div>
                            }
                            {this.state.type === 'phone' &&
                            <div>
                                <div className="sep-25"/>
                                <div className="button registerWithEmail" onClick={this.switchType}>
                                    <div className="register_icon">
                                        <img
                                            src={require('../../../images/mobile/02_membership/00_signin/email.png')}
                                            alt=""/>
                                    </div>
                                    {t("login:loginEmail")}
                                </div>
                            </div>
                            }

                            <div className="sep-25"/>

                            <div>
                                <dt/>
                                <dd className="align-center">
                                    {t("OR")}
                                </dd>
                            </div>

                            <Formik
                                initialValues={{
                                    email: '',
                                    password: '',
                                    phone: '',
                                    token: '',
                                    rememberMe: '',
                                }}
                                validationSchema={this.schema}
                                onSubmit={this.handleSubmit}
                                component={this.state.type === 'email' ? this.emailForm : this.phoneForm}
                            />
                        </dl>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {
        members: state.members,
        route: state.router
    }
);

export default withTranslation()(connect(mapStateToProps)(MemberLogin));
