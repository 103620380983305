import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {Redirect} from 'react-router';
import {connect} from 'react-redux';

import Landlord from '../../components/05Messages/Landlord';
import {getSpaceDetail} from "../../utils/04Api/Space";

import {sendCard} from '../../socket/connect';

import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import ErrorMessage from "../../components/103Parts/ErrorMessage";

// import {Formik, Form, Field} from 'formik';
// import * as Yup from 'yup';

class ContactOwner extends Component {

    constructor(props) {
        super(props);

        let redirect = true,
            myId = '';
        if (this.props.members.Login.length !== 0) {
            redirect = false;
            myId = props.members.Login.user._id;
        }

        this.state = {
            myId: myId,
            space: null,
            supported_types: [],
            redirect: redirect,
            defaultQuestion: '',
            submitted: false,
        }
    }

    componentDidMount() {
        if (!this.state.redirect) {
            this.getSpace();
        }
    }

    changeDefaultQuestion = (field, data1, value) => {

        if (this.state.defaultQuestion === field) {
            this.setState({
                [data1]: ''
            });
        } else {
            this.setState({
                [data1]: value
            });
        }
    }

    getSpace = (cb) => {
        let callback = (obj) => {
            if (obj.status === 200) {
                // console.log(obj.body);

                let supported_types = [];
                if (typeof (obj.body.hourly.from) !== "undefined")
                    supported_types.push("hourly");
                if (typeof (obj.body.monthly.from) !== "undefined")
                    supported_types.push("monthly");
                if (typeof (obj.body.timeshare.from) !== "undefined")
                    supported_types.push("timeshare");

                this.setState({
                    ...this.state,
                    space: obj.body,
                    supported_types: supported_types,
                }, () => {
                    if (typeof (cb) === "function")
                        cb();
                })
            } else
                this.setRedirect();
        }
        getSpaceDetail(this.props.space_id, callback, this.setRedirect);
    }

    setRedirect() {
        this.setState({
            ...this.state,
            redirect: true
        })
    }

    handleSubmit = (values, {setErrors, setFieldTouched}) => {

        const filterRegex = /([0-9]{4}[\s]{0,}[0-9]{4}|\S+[\s]{0,}@[\s]{0,}\S+[\s]{0,}\.[\s]{0,}\S)/gm;

        if (this.state.defaultQuestion === '' && values.message === '') {
            setErrors({submit: this.props.t("contactOwner:errors.question")});
        } else if (values.message !== '' && filterRegex.test(values.message)) {
            setErrors({message: this.props.t("messenger:error.filter")});
        } else {
            const sender_id = this.state.myId,
                receiver_id = this.state.space.owner._id,
                rid = receiver_id + "-" + sender_id + "-" + this.props.space_id;

            let data = {
                booking_type: values.booking_type,
                message: values.message === '' ? "" : values.message,
                question_1: false,
                question_2: false,
            }

            if (this.state.defaultQuestion === 'wideLoad')
                data.question_1 = true;

            if (this.state.defaultQuestion === 'noviceDriver')
                data.question_2 = true;

            sendCard(rid, sender_id, receiver_id, "M7", data, values.message === '' ? "" : values.message, () => {
                this.setState({
                    ...this.state,
                    submitted: true
                });
            });
        }
    }

    form = ({values, errors, touched, handleChange, handleBlur, setErrors, setFieldTouched}) => {
        const {
            t
        } = this.props;

        return (
            <Form>
                <div className="formArea clearfix">
                    <div className="fifty left">
                        <div>
                            <h4>{t("contactOwner:bookingType.title")}</h4>

                            <div className="wrap clearfix">
                                <div className="full">
                                    <div>
                                        <select
                                            name="booking_type"
                                            id="booking_type"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.booking_type}
                                        >
                                            {this.state.supported_types.map((o, i) => {
                                                return <option
                                                    value={o}
                                                    key={i}
                                                >{t("contactOwner:bookingType.options." + o)}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="sep-20"/>

                            <h4>{t("contactOwner:suggestedQuestion.title")}</h4>

                            <div className="wrap clearfix">
                                <div className="full">
                                    <div>
                                        <div className="checkboxFive green">
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    id="question_1"
                                                    name="question_1"
                                                    checked={this.state.defaultQuestion === 'wideLoad'}
                                                    onChange={(e) => {
                                                        this.changeDefaultQuestion('wideLoad', 'defaultQuestion', 'wideLoad')
                                                    }}
                                                />
                                                <label htmlFor="question_1"/>
                                            </div>
                                            <label
                                                htmlFor="question_1">{t("contactOwner:suggestedQuestion.wideLoad")}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="wrap clearfix">
                                <div className="full">
                                    <div>
                                        <div className="checkboxFive green">
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    id="question_2"
                                                    name="question_2"
                                                    checked={this.state.defaultQuestion === 'noviceDriver'}
                                                    onChange={(e) => {
                                                        this.changeDefaultQuestion('noviceDriver', 'defaultQuestion', 'noviceDriver')
                                                    }}
                                                />
                                                <label htmlFor="question_2"/>
                                            </div>
                                            <label
                                                htmlFor="question_2">{t("contactOwner:suggestedQuestion.noviceDriver")}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="full left">
                        <div>
                            <div className="wrap clearfix">
                                <div className="full">
                                    <div>
                                        <textarea
                                            name="message"
                                            id="message"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={t("contactOwner:suggestedQuestion.additional")}
                                            value={values.messages}
                                        />

                                        {errors.message && touched.message ?
                                            <ErrorMessage message={errors.message}/> : null}

                                        <div className="sep-20"/>
                                    </div>
                                </div>
                            </div>

                            <div className="wrap clearfix">
                                <div className="full align-center">
                                    {errors.submit ?
                                        <div>
                                            <ErrorMessage message={errors.submit}/>
                                            <div className="sep-10"/>
                                        </div> : null}
                                    <button
                                        type="submit"
                                        className="button"
                                    >{t("contactOwner:send")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        )
    }

    schema = Yup.object().shape({
        message: Yup.string()
        // .required(this.props.t("Please enter a question"))
            .max(1000, this.props.t("Max 1000 characters")),
    })

    render() {
        const {
            i18n,
            // t,
            space_id
        } = this.props;

        if (this.props.members.Login.length === 0)
            return <Redirect to={"/" + i18n.language + "/login"}/>;

        if (this.state.space && this.state.myId === this.state.space.owner._id)
            return <Redirect to={"/" + i18n.language + "/space/" + space_id}/>;

        if (this.state.redirect)
            return <Redirect to={"/" + i18n.language + "/home"}/>;

        if (this.state.submitted)
            return <Redirect to={"/" + i18n.language + "/chat/" + space_id + "/" + this.state.myId}/>;

        return (
            <div className="wrapper-container messages">
                {this.state.space &&
                <div className="containerMain">
                    <Landlord
                        space={this.state.space}
                    />
                    <div className="wrapper-formArea">
                        <Formik
                            initialValues={{
                                booking_type: 'hourly',
                                question_1: false,
                                question_2: false,
                                message: '',
                            }}
                            validationSchema={this.schema}
                            onSubmit={this.handleSubmit}
                            component={this.form}
                        />
                    </div>
                </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    members: state.members,
});

export default withTranslation()(connect(mapStateToProps)(ContactOwner));